import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetByProductId(productId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductConsumption/GetByProductId?ProductId="+ productId)
    }
}

export async function Add(ProductConsumption) {
    const loginData = GetLoginInfo();

    if (loginData) {

        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductConsumption/Add", ProductConsumption)
        
    }
}
export async function Edit(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductConsumption/Edit?Id=" + param.id + "&ProductId=" + param.productId + "&ConsumeProductId=" + param.consumeProductId + "&Unit=" + param.unit + "&ConsumptionQuantity=" + param.consumptionQuantity)
    }
}
export async function Remove(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductConsumption/Remove?Id="+id)
    }
}