import React from 'react'

export function PaymentSuccess(props) {
    return (
        <div class="row center">
            <div class="col-xs-12">
                <i class="fa fa-check-circle green" style={{ fontSize: "112px" }}></i>
                <div class="space-6"></div>
                <h1>Payment Successfull !</h1>
                {props.children}
                <div class="space-6"></div>
                <h5>Click reload to refelect changes.</h5>
                <button class="btn btn-app btn-success" onClick={() => { window.location.replace("/"); }}>
                    <i class="ace-icon fa fa-refresh bigger-230"></i>
                    Reload
                </button>
            </div>
        </div>
    )
}
