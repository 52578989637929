
const GetCurrentDateForSearch = (day) => {
    const today = new Date()
    today.setDate(today.getDate() + day);
    const dd = String(today.getDate()).padStart(2, "0")
    const mm = String(today.getMonth() + 1).padStart(2, "0")
    const yyyy = today.getFullYear();
    //alert(yyyy + "-" + mm + "-" + dd)
    return yyyy + "-" + mm + "-" + dd
}

const GetCurrentDateTime = () => {
    const today = new Date()
    const dd = String(today.getDate()).padStart(2, "0")
    const mm = String(today.getMonth() + 1).padStart(2, "0")
    const yyyy = today.getFullYear();
    const hours = String(today.getHours()).padStart(2, "0")
    const minutes = String(today.getMinutes()).padStart(2, "0")
    //alert(yyyy + "-" + mm + "-" + dd)
    return yyyy + "-" + mm + "-" + dd + " " + hours + "-" + minutes
}
export default GetCurrentDateForSearch
export { GetCurrentDateTime }