import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { EmptyData } from '../../commonLib/EmptyData'
import Moment from 'react-moment';
import { Spinner } from '../../commonLib/Spinner';
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import *as initOrderForProductionCrud from '../../_crud/initOrderForProductionCrud'
import *as productionUnitCrud from '../../_crud/productionUnitCrud'
import DatePicker from "react-datepicker";
import GetCurrentDateForSearch from '../../commonLib/dateFormat'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { MyAlert } from '../../commonLib/MyAlert';
import { useParams } from 'react-router-dom';
import { PagingEmpty } from '../../commonLib/PagingEmpty';
import { UserView } from '../Global/Elements/UserView';
import { ProductView } from '../Global/Elements/ProductView';
import { StatusView } from '../../commonLib/StatusView';
import { GetEInitOrderStatusName } from '../../enumCollections/EInitOrderStatus';
import { GetEInitPriorityName } from '../../enumCollections/EInitPriority';
import ContactSelector from '../Global/ContactSelector';
import { ContactSelectorView } from '../Global/SelectorView/ContactSelectorView';
import { ProductionSelectorView } from '../Global/SelectorView/ProductionSelectorView';
import { loadingContext } from '../../commonLib/_lodingContext';
import { WarehouseView } from '../Global/Elements/WarehouseView';

const initSentToOrder = {
    AllotedToContactId: "",
    ToProductionUnitId: "",
    Data: []
}
export function ManageInitOrderForProductionContact() {
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [initOrder, setInitOrder] = useState([])
    const [isLoadingLocal, setIsLoadingLocal] = useState()
    const { id, productionUnitId } = useParams()
    const [selectedContactData, setSelectedContactData] = useState(undefined);
    const [production, setProduction] = useState([])
    const [sentToOrder, setSentToOrder] = useState(initSentToOrder)
    const [selectedProductionData, setSelectedProductionData] = useState(undefined);

    const GetDateFormate = (date) => {
        const today = new Date(date)
        const dd = String(today.getDate()).padStart(2, "0")
        const mm = String(today.getMonth() + 1).padStart(2, "0")
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd
    }

    const initValues = {
        forProductionUnitId: productionUnitId,
        allotedForContactId: id,
        Range: {
            fromDate: new Date(GetCurrentDateForSearch(-30)),
            toDate: new Date(GetCurrentDateForSearch(0))
        }
    }
    const [search, setSearch] = useState(initValues)

    function OnSearch() {
        getInitOrder()
        setSentToOrder({ ...sentToOrder, Data: [] })
    }
    useEffect(() => {
        getInitOrder()
    }, [])

    useEffect(() => {
        if (selectedContactData) {
            setSentToOrder({ ...sentToOrder, AllotedToContactId: selectedContactData.id })
            getProduction(selectedContactData.id)
        }
    }, [selectedContactData]);

    function getInitOrder() {
        setIsLoading(true);
        initOrderForProductionCrud.GetAllotedForContactId(search)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setInitOrder(hdata);
                        setIsLoading(false)
                    }
                    else {
                        setIsLoading(false)
                        setInitOrder([]);
                    }
                }
                else {
                    setIsLoading(false)
                    setInitOrder([]);
                    AppToaster('Network error', 'error')
                }
            })
            .catch(error => {
                setIsLoading(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        getInitOrder()
        setSentToOrder({ ...sentToOrder, Data: [] })
    }
    function OnSentToOrder() {
        setIsLoadingLocal(true);
        initOrderForProductionCrud.SentToOrder(sentToOrder)
            .then(res => {
                if (res.data) {
                    if (res.data.succeeded) {
                        AppToaster('Total ' + sentToOrder.Data.length + ' init orders has been sent to orders', 'success')
                        setSentToOrder(initSentToOrder);
                        OnSearch()
                        setIsLoadingLocal(false)
                    }
                    else {
                        setIsLoadingLocal(false)
                        alert(JSON.stringify(res))
                    }
                }
                else {
                    setIsLoadingLocal(false)
                    AppToaster('Network error', 'error')
                }
            })
            .catch(error => {
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    const DeleteItem = (id) => {
        setIsLoading(true)
        initOrderForProductionCrud.Remove(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoading(false)
                    AppToaster('Deleted successfully.', 'success')
                    getInitOrder()
                }
                else {
                    setIsLoading(false)
                    AppToaster('Using by someone,could not delete.', 'error')
                }

            }).catch(error => {
                setIsLoading(false)
                AppToaster('Network error', 'error')
            })
    }

    const checkDate = (date, status) => {
        var date1 = new Date(date);
        var date2 = new Date();
        if (date1 <= date2) {
            if (status == 0 || status == 2)
                return "red bolder"
            else
                return ""
        }
        else { return "" }
    }
    function getProduction(contactid) {
        setIsLoadingMini(true);
        productionUnitCrud.GetByContactId(contactid)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProduction(hdata);
                    }
                    else {
                        setProduction([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProduction([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    const OnOrderSelect = (item) => {
        let a = item.id
        setSentToOrder({ ...sentToOrder, Data: [...sentToOrder.Data, a] })
    }
    const OnOrderRemove = (item) => {
        let data = sentToOrder.Data
        data.forEach((e, index) => {
            if (e == item.id)
                data.splice(index, 1)
        })
        setSentToOrder({ ...sentToOrder, Data: data })
    }

    const OnSelectedChange = (e, item) => {
        const { checked } = e.target
        if (checked) {
            OnOrderSelect(item)
        } else {
            OnOrderRemove(item)
        }
    }
    const OnProductionChange = (e) => {
        const { value } = e.target;
        if (value) {
            production.forEach(element => {
                if (element.id == value) {
                    {
                        setSelectedProductionData(element)
                    }
                }
            });
        }
        else {
            setSelectedProductionData(undefined)
        }
    }

    useEffect(() => {
        if (selectedProductionData) {
            setSentToOrder({ ...sentToOrder, ToProductionUnitId: selectedProductionData.id })
        }
        else {
            setSentToOrder({ ...sentToOrder, ToProductionUnitId: "" })
        }
    }, [selectedProductionData])
    return (
        <>
            <Container title="Init Order" doRefresh={doRefresh} isBack="true" isContact="true" isProduction="true">
                <Link to={"/contact/dashboard/" + id + "/productionunit/orders/" + productionUnitId + "/initorderforproduction/add"} class="btn btn-sm btn-success " style={{ marginLeft: "0px" }}>
                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                    New
                </Link>
                <div class="hr dotted"></div>
                <div class="form-horizontal" >
                    <div className="row">
                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker selectsStart startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.fromDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, fromDate: date } })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker selectsEnd startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.toDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, toDate: date } })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-1">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <button onClick={OnSearch} to="/order/add" class="btn btn-sm btn-info pull-right">
                                    <i class="ace-icon fa fa-search bigger-110"></i>
                                    Run
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {sentToOrder.Data && sentToOrder.Data.length > 0 &&
                    <>
                        <div class="hr dotted"></div>
                        <div class="form-horizontal" >
                            <div className="row">
                                <div className="col-md-12">
                                    <h4>Select To Allot</h4>
                                </div>
                                <div className="col-md-5">
                                    <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                        <div class="col-xs-12">
                                            <label class="control-label no-padding-right">Contact</label>
                                            <ContactSelector searchtext="" setSelectedData={setSelectedContactData} />
                                        </div>
                                        <ContactSelectorView data={selectedContactData} />
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                        <div class="col-xs-12">
                                            <label class="control-label no-padding-right"> To Production Unit</label>
                                            <select className="form-control" name="ToProductionUnitId" value={sentToOrder.ToProductionUnitId} onChange={OnProductionChange}>
                                                <option value="" >Select...</option>
                                                {
                                                    production.map((data, index) =>
                                                        <option key={data.id} value={data.id}>{data.productionUnitName.toUpperCase()} ({data.id})</option>
                                                    )
                                                }
                                            </select>
                                            <ProductionSelectorView data={selectedProductionData} />

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1">
                                    <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                        <label class="control-label">&#160;</label>
                                        {isLoadingLocal ?
                                            <button class="btn btn-sm btn-success pull-right" disabled>
                                                <i class="ace-icon fa fa-search bigger-110"></i>
                                                Sending...
                                            </button>
                                            :
                                            <button onClick={OnSentToOrder} class="btn btn-sm btn-success pull-right" disabled={(sentToOrder.AllotedToContactId && sentToOrder.ToProductionUnitId) ? false : true}>
                                                <i class="ace-icon fa fa-search bigger-110"></i>
                                                Sent To Order
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                }
                <PagingEmpty text={"Total " + initOrder.length + " items."}>
                    {isLoading ?
                        <Spinner />
                        :
                        initOrder && initOrder.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th ></th>
                                            <th>Branch</th>
                                            <th>#Id</th>
                                            <th>On Date</th>
                                            <th>Due Date</th>
                                            <th>Product</th>
                                            <th>Configuration</th>
                                            <th>Quantity</th>
                                            <th>Status</th>
                                            <th>Priority</th>
                                            <th>Details</th>
                                            <th>By</th>
                                            <th style={{ width: "43px" }}></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {initOrder.map((item, index) =>
                                            <tr key={item.id} className={sentToOrder.Data.includes(item.id) ? "active" : ""} >
                                                <td>
                                                    <div class="checkbox">
                                                        <label>
                                                            <input name="form-field-checkbox" class="ace ace-checkbox-2" type="checkbox" onChange={(e) => OnSelectedChange(e, item)} />
                                                            <span class="lbl"></span>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td><WarehouseView item={item.warehouse} uid={"WH" + index} /></td>
                                                <td>{item.id}</td>
                                                <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.onDate}</Moment></div></td>
                                                <td><div style={{ width: "70px" }} className={checkDate(item.dueDate, item.status)}><Moment format="DD-MMM-YYYY">{item.dueDate}</Moment></div></td>
                                                <td><Link to={"/product/dashboard/" + item.product.id}>
                                                    <ProductView item={item.product} uid={"PP" + index} />
                                                </Link></td>
                                                <td>
                                                    {item.productionConfiguration ?
                                                        <Link to={"/productionunit/configuration/dashboard/" + item.productionConfiguration.id}>
                                                            {item.productionConfiguration.name}
                                                        </Link>
                                                        :
                                                        "NA"
                                                    }
                                                </td>
                                                <td>{item.quantity} {item.unit}</td>
                                                <td><StatusView status={item.status}>{GetEInitOrderStatusName(item.status)}</StatusView></td>
                                                <td><span class={item.priority == 1 ? "label label-sm label-info" : "label label-sm label-danger"}>{GetEInitPriorityName(item.priority)}</span></td>
                                                <td><div style={{ width: "200px" }}>{item.details}</div></td>
                                                <td>
                                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                            <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                        </MyAlert>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </PagingEmpty>
            </Container >
        </>
    )
}
