import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Container } from '../../base/Container'
import AppToaster from '../../commonLib/AppToaster'
import { EmptyData } from '../../commonLib/EmptyData'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext'
import { Paging } from '../../commonLib/Paging'
import { Spinner } from '../../commonLib/Spinner'
import { PAGING_PAGE_SIZE } from '../../config/GlobalSettings'
import { GetEBoolName } from '../../enumCollections/EBool'
import { ContactView } from '../Global/Elements/ContactView'
import { ProcessingView } from '../Global/Elements/ProcessingView'
import { ProductionUnitView } from '../Global/Elements/ProductionUnitView'
import { ProductView } from '../Global/Elements/ProductView'
import * as productProcessingStockCrud from './../../_crud/productProcessingStockCrud'
import * as processingCrud from './../../_crud/processingCrud'
import GetLoginInfo from '../../auth/_userContext/GetLoginInfo'
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater'


const initValues = {
  ProcessingId: "All",
  Search: {
    searchTerm: "",
    pageNo: 1,
    pageSize: PAGING_PAGE_SIZE
  }
}
export function ManageProcessingContactStocks() {
  const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
  const [search, setSearch] = useState(initValues)
  const [processingStock, setProcessingStock] = useState([])
  const [LastPage, setLastPage] = useState(1)
  const [pagingData, setPagingData] = useState()
  const [processing, setProcessing] = useState([])
  const userData = GetLoginInfo()

  useEffect(() => {
    getProcessing()
  }, [])

  useEffect(() => {
    OnSearch()
  }, [search.Search.pageNo])

  const OnPageChange = (e) => {
    const { value } = e.target
    setSearch({ ...search, Search: { ...search.Search, pageNo: value } });
  }

  function OnSearch() {
    if (userData.isWarehouse == 0) {
      if (search.ProcessingId == "All")
        getData(search.Search)
      else
        GetByProcessing(search)
    }
    else {
      if (search.ProcessingId == "All")
        getDataWarehouse(search.Search)
      else
        GetByProcessingWarehouse(search)
    }

  }

  function getData(value) {
    setIsLoadingMini(true);
    productProcessingStockCrud.GetAll(value)
      .then(res => {
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            let paging = res.data.paging
            setPagingData(paging)
            setLastPage(paging.pageCount)
            setProcessingStock(hdata);
            setIsLoadingMini(false)
          }
          else {
            setLastPage(1)
            setIsLoadingMini(false)
            setProcessingStock([]);
          }
        }
        else {
          setLastPage(1)
          setIsLoadingMini(false)
          setProcessingStock([]);
          AppToaster("Server Error / Invalid Try.", 'error')
        }
      })
      .catch(error => {
        setLastPage(1)
        setIsLoadingMini(false);
        AppToaster(error, 'error')
      })
  }
  function GetByProcessing(value) {
    setIsLoadingMini(true);
    productProcessingStockCrud.GetByProcessing(value)
      .then(res => {
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            let paging = res.data.paging
            setPagingData(paging)
            setLastPage(paging.pageCount)
            setProcessingStock(hdata);
            setIsLoadingMini(false)
          }
          else {
            setLastPage(1)
            setIsLoadingMini(false)
            setProcessingStock([]);
          }
        }
        else {
          setLastPage(1)
          setIsLoadingMini(false)
          setProcessingStock([]);
          AppToaster("Server Error / Invalid Try.", 'error')
        }
      })
      .catch(error => {
        setLastPage(1)
        setIsLoadingMini(false);
        AppToaster(error, 'error')
      })
  }
  function getDataWarehouse(value) {
    setIsLoadingMini(true);
    productProcessingStockCrud.GetAllWarehouse({ Search: { ...value }, WarehouseId: userData.warehouseId })
      .then(res => {
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            let paging = res.data.paging
            setPagingData(paging)
            setLastPage(paging.pageCount)
            setProcessingStock(hdata);
            setIsLoadingMini(false)
          }
          else {
            setLastPage(1)
            setIsLoadingMini(false)
            setProcessingStock([]);
          }
        }
        else {
          setLastPage(1)
          setIsLoadingMini(false)
          setProcessingStock([]);
          AppToaster("Server Error / Invalid Try.", 'error')
        }
      })
      .catch(error => {
        setLastPage(1)
        setIsLoadingMini(false);
        AppToaster(error, 'error')
      })
  }
  function GetByProcessingWarehouse(value) {
    setIsLoadingMini(true);
    productProcessingStockCrud.GetByProcessingWarehouse({ ...value, WarehouseId: userData.warehouseId })
      .then(res => {
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            let paging = res.data.paging
            setPagingData(paging)
            setLastPage(paging.pageCount)
            setProcessingStock(hdata);
            setIsLoadingMini(false)
          }
          else {
            setLastPage(1)
            setIsLoadingMini(false)
            setProcessingStock([]);
          }
        }
        else {
          setLastPage(1)
          setIsLoadingMini(false)
          setProcessingStock([]);
          AppToaster("Server Error / Invalid Try.", 'error')
        }
      })
      .catch(error => {
        setLastPage(1)
        setIsLoadingMini(false);
        AppToaster(error, 'error')
      })
  }


  function getProcessing() {
    setIsLoadingMini(true);
    processingCrud.GetAll()
      .then(res => {
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            setProcessing(hdata);
          }
          else {
            setProcessing([]);
          }
        }
        else {
          setProcessing([]);
        }
        setIsLoadingMini(false);
      })
      .catch(error => {
        setIsLoadingMini(false);
        AppToaster(error, 'error')
      })
  }
  function doRefresh() {
    OnSearch()
  }

  function SearchChange(e) {
    const value = e.target.value
    setSearch({ ...search, Search: { ...search.Search, searchTerm: value } })
  }

  return (
    <>
      <Container title={"Processing Contact Stock "} doRefresh={doRefresh}>
        <div class="form-horizontal" >
          <div className="row">
            <div className="col-md-2">
              <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                <select className="form-control" value={search.ProcessingId} onChange={(e) => setSearch({ ...search, ProcessingId: e.target.value })}>
                  <option value="All">All</option>
                  {
                    processing.map((item, index) =>
                      <option value={item.id}>{item.name}</option>
                    )
                  }
                </select>
              </div>
            </div>

            <div className="col-md-9">
              <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                <span class="input-icon" style={{ width: "100%" }}>
                  <TextBoxTranslater style={{paddingLeft: "24px"}} value={search.searchTerm} onChange={SearchChange} className="form-control" type="text" placeholder="Search Processing Stock.." />
                  <i class="ace-icon fa fa-search blue"></i>
                </span>
              </div>
            </div>

            <div className="col-md-1">
              <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                <button onClick={OnSearch} class="btn btn-sm btn-info pull-right">
                  <i class="ace-icon fa fa-search bigger-110"></i>
                  Run
                </button>
              </div>
            </div>
          </div>

        </div>
        <Paging
          pagingData={pagingData}
          LastPage={LastPage}
          CurrentPage={search.Search.pageNo}
          OnPageChange={OnPageChange} >
          {isLoadingMini ?
            <Spinner />
            :
            processingStock && processingStock.length > 0 ?
              <div className="tables-scroll">
                <table className="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th>#Id</th>
                      <th>Contact</th>
                      <th>Processing</th>
                      <th>Production</th>
                      <th>Product</th>
                      <th>Availabe</th>
                      <th>Processed</th>
                      <th>Total Given</th>
                    </tr>
                  </thead>

                  <tbody>
                    {processingStock.map((item, index) =>
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>
                          <Link to={"/contact/dashboard/" + item.contactId}>
                            <ContactView item={item.contact} uid={"C" + index} />
                          </Link>
                        </td>
                        <td>
                          <Link to={"/processing/dashboard/" + item.processingId}>
                            <ProcessingView item={item.processing} />
                          </Link>
                        </td>
                        <td>
                          {item.isProductionUnit == 1 ? (item.productionUnit ? <Link to={"/productionunit/dashboard/" + item.productionUnitId}>
                            <ProductionUnitView item={item.productionUnit} />
                          </Link> : "NA") : <span class="label label-sm label-danger">{GetEBoolName(item.isProductionUnit)}</span>}
                        </td>
                        <td>
                          <Link to={"/product/dashboard/" + item.productId}>
                            <ProductView item={item.product} uid={"PP" + index} />
                          </Link>
                        </td>
                        <td>{Number(item.stock).toFixed(2)} {item.unit}</td>
                        <td>{Number(item.globalStock - item.stock).toFixed(2)} {item.unit}</td>
                        <td>{Number(item.globalStock).toFixed(2)} {item.unit}</td>

                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              :
              <EmptyData>
                No records found.
                <br />
                <br />
              </EmptyData>
          }
        </Paging>
      </Container >
    </>
  )
}

