import React, { useContext, useEffect, useState } from 'react'
import AppToaster from '../../../commonLib/AppToaster';
import * as productDistributionCrud from '../../../_crud/productDistributionCrud';
import * as productionUnitCrud from '../../../_crud/productionUnitCrud';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import { Container } from '../../../base/Container';
import * as productCrud from '../../../_crud/productCrud';
import { LoadingButton } from '../../../commonLib/LoadingButton';
import ContactSelector from '../../Global/ContactSelector';
import { ContactSelectorView } from '../../Global/SelectorView/ContactSelectorView';
import { ProductSelectorView } from '../../Global/SelectorView/ProductSelectorView';
import { ProductionSelectorView } from '../../Global/SelectorView/ProductionSelectorView';
import * as productConsumptionCrud from '../../../_crud/productConsumptionCrud';
import * as orderCrud from '../../../_crud/orderCrud';
import { OrderSelector } from '../../Order/OrderSelector';
import DatePicker from "react-datepicker";
import GetCurrentDateForSearch from '../../../commonLib/dateFormat';
import GetLoginInfo from '../../../auth/_userContext/GetLoginInfo';
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater';



export function AddProductDistribution() {
  const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
  const [isLoadingLocal, setIsLoadingLocal] = useState(false)
  const [production, setProduction] = useState([])
  const history = useHistory();
  const [product, setProduct] = useState([])
  const [givenProduct, setGivenProduct] = useState([])
  const [selectedContactData, setSelectedContactData] = useState(undefined);
  const [selectedProductData, setSelectedProductData] = useState(undefined);
  const [selectedGivenProductData, setSelectedGivenProductData] = useState(undefined);
  const [selectedProductionData, setSelectedProductionData] = useState(undefined);
  const [allotedOrdersData, setAllotedOrdersData] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(undefined);
  const userData = GetLoginInfo()

  const initialValues = {
    contactId: "",
    onDate: new Date(GetCurrentDateForSearch(0)),
    productionUnitId: "",
    productId: "",
    givenProductId: "",
    unit: "",
    quantity: "",
    note: "",
    isOrder: 0,
    orderId: "",
    isWarehouse: userData.isWarehouse,
    warehouseId: userData.warehouseId,
  }
  const Schema = Yup.object().shape({
    contactId: Yup.string().required("Contact is required."),
    productionUnitId: Yup.string().required("Production Unit is required."),
    productId: Yup.string().required("Product is required."),
    givenProductId: Yup.string().required("Given Product is required."),
    unit: Yup.string().required("Unit is required."),
    quantity: Yup.string().required("Quantity is required."),
    isOrder: Yup.string().required("Is Order is required.")
  })

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values) => {
      Save(values);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });

  function setQuantity() {
    if (selectedGivenProductData) {
      if (formik.values.isOrder == 0) {
        if (formik.values.quantity > selectedGivenProductData.mainStock) {
          formik.setValues({ ...formik.values, quantity: selectedGivenProductData.mainStock })
        }
        else if (formik.values.quantity < 0) {
          formik.setValues({ ...formik.values, quantity: 0 })
        }
      }
    }
  }

  useEffect(() => {
    setQuantity()
  }, [formik.values.forStock, formik.values.quantity]);

  useEffect(() => {
    if (selectedContactData) {
      formik.setValues({ ...formik.values, contactId: selectedContactData.id })
      getProduction(selectedContactData.id)
      setAllotedOrdersData([]);
    }
  }, [selectedContactData]);

  useEffect(() => {
    if (selectedOrder) {
      formik.setValues({ ...formik.values, orderId: selectedOrder.id, quantity: selectedOrder.quantity })
    }
    else {
      formik.setValues({ ...formik.values, orderId: "", quantity: 0 })
    }
  }, [selectedOrder]);

  useEffect(() => {
    if (selectedProductionData) {
      formik.setValues({ ...formik.values, productionUnitId: selectedProductionData.id })
      getProducts(selectedProductionData.id)
      setAllotedOrdersData([]);
    }
    else {
      formik.setValues({ ...formik.values, productionUnitId: "" })
    }
  }, [selectedProductionData])

  useEffect(() => {
    if (selectedProductData) {
      formik.setValues({ ...formik.values, productId: selectedProductData.id })
      getGivenProducts(selectedProductData.id)
      setAllotedOrdersData([]);
    }
    else {
      formik.setValues({ ...formik.values, productId: "" })
    }
  }, [selectedProductData])

  useEffect(() => {
    if (selectedGivenProductData) {
      formik.setValues({ ...formik.values, givenProductId: selectedGivenProductData.id, unit: selectedGivenProductData.unit, quantity: 0 })
      getAllotedOrders({
        forProductionUnitId: formik.values.productionUnitId, allotedForContactId: formik.values.contactId, productId: selectedGivenProductData.id
      })
    }
    else {
      formik.setValues({ ...formik.values, givenProductId: "" })
    }
  }, [selectedGivenProductData])

  const OnProductionChange = (e) => {
    const { value } = e.target;
    if (value) {
      production.forEach(element => {
        if (element.id == value) {
          {
            setSelectedProductionData(element)
          }
        }
      });
    }
    else {
      setSelectedProductionData(undefined)
    }
  }

  const OnProductChange = (e) => {
    const { value } = e.target;
    if (value) {
      product.forEach(element => {
        if (element.id == value) {
          {
            setSelectedProductData(element)
          }
        }
      });
    }
    else {
      setSelectedProductData(undefined)
    }
  }

  const OnGivenProductChange = (e) => {
    const { value } = e.target;
    if (value) {
      givenProduct.forEach(element => {
        if (element.consumeProduct.id == value) {
          {
            setSelectedGivenProductData(element.consumeProduct)
          }
        }
      });
    }
    else {
      setSelectedGivenProductData(undefined)
    }
  }

  const Save = (value) => {
    if (selectedProductionData && selectedProductionData.productionCategory.type != 1)
      if (value.isOrder == 0 || value.orderId)
        if (value.quantity > 0) {
          setIsLoadingMini(true);
          setIsLoadingLocal(true)
          productDistributionCrud.Add(value)
            .then(res => {
              if (res.data.result.succeeded) {
                setIsLoadingMini(false)
                setIsLoadingLocal(false);
                AppToaster('Added successfully.', 'success')
                history.goBack()
              }
              else {
                setIsLoadingMini(false)
                setIsLoadingLocal(false);
                AppToaster("Server Error /Invalid Try.", 'error')
              }
            })
            .catch(error => {
              setIsLoadingMini(false);
              setIsLoadingLocal(false);
              AppToaster(error, 'error')
            })
        }
        else {
          AppToaster("Quantity should greater then zero.", 'error')
        }
      else {
        AppToaster("Please select order.", 'error')
      }
    else {
      AppToaster("You could not destribute for Trade production unit.", 'error')
    }
  }

  function getProduction(contactid) {
    setIsLoadingMini(true);
    setIsLoadingLocal(true)
    productionUnitCrud.GetByContactId(contactid)
      .then(res => {
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            setProduction(hdata);
          }
          else {
            setProduction([]);
            AppToaster("Server Error /Invalid Try.", 'error')
          }
        }
        else {
          setProduction([]);
        }
        setIsLoadingMini(false);
        setIsLoadingLocal(false)
      })
      .catch(error => {
        setIsLoadingMini(false);
        setIsLoadingLocal(false)
        AppToaster(error, 'error')
      })
  }

  function getProducts(productionUnitId) {
    setIsLoadingMini(true);
    setIsLoadingLocal(true)
    productCrud.GetByProductionUnit(productionUnitId)
      .then(res => {
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            setProduct(hdata);
          }
          else {
            setProduct([]);
            AppToaster("Server Error /Invalid Try.", 'error')
          }
        }
        else {
          setProduct([]);
        }
        setIsLoadingMini(false);
        setIsLoadingLocal(false)
      })
      .catch(error => {
        setProduct([]);
        setIsLoadingMini(false);
        setIsLoadingLocal(false)
        AppToaster(error, 'error')
      })
  }

  function getGivenProducts(productId) {
    setIsLoadingMini(true);
    setIsLoadingLocal(true)
    productConsumptionCrud.GetByProductId(productId)
      .then(res => {
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            setGivenProduct(hdata);
          }
          else {
            setGivenProduct([]);
            AppToaster("Server Error /Invalid Try.", 'error')
          }
        }
        else {
          setGivenProduct([]);
        }
        setIsLoadingMini(false);
        setIsLoadingLocal(false)
      })
      .catch(error => {
        setIsLoadingMini(false);
        setIsLoadingLocal(false)
        AppToaster(error, 'error')
      })
  }

  function getAllotedOrders(param) {
    setIsLoadingMini(true);
    setIsLoadingLocal(true)
    orderCrud.GetForProductionUnitIdContactProduct(param)
      .then(res => {
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            setAllotedOrdersData(hdata);
          }
          else {
            setAllotedOrdersData([]);
            AppToaster("Server Error /Invalid Try.", 'error')
          }
        }
        else {
          setAllotedOrdersData([]);
        }
        setIsLoadingMini(false);
        setIsLoadingLocal(false)

      })
      .catch(error => {
        setAllotedOrdersData([]);
        setIsLoadingMini(false);
        setIsLoadingLocal(false)
        AppToaster(error, 'error')
      })
  }

  return (
    <>
      <Container title="Add Product Distribution" isBack="true">
        <form onSubmit={formik.handleSubmit} class="form-horizontal">
          <div className="row">
            <div className="col-md-4">
              <div class="form-group">
                <div class="col-xs-12">
                  <label class="control-label no-padding-right">Contact</label>
                  <ContactSelector searchtext="" setSelectedData={setSelectedContactData} />
                  {
                    formik.touched.contactId &&
                      formik.errors.contactId ? (
                      <label className="text-danger" >
                        {formik.errors.contactId}
                      </label>
                    ) : null
                  }
                </div>
                <ContactSelectorView data={selectedContactData} />
              </div>
            </div>

            <div className="col-md-4">
              <div class="form-group">
                <div class="col-xs-12">
                  <label class="control-label no-padding-right">Production</label>
                  <select className="form-control" name="productionUnitId" value={formik.values.productionUnitId} onChange={OnProductionChange}>
                    <option value="" >Select...</option>
                    {
                      production.map((data, index) =>
                        <option key={data.id} value={data.id}>{data.productionUnitName.toUpperCase()} ({data.id})</option>
                      )
                    }
                  </select>
                  <ProductionSelectorView data={selectedProductionData} />
                  {
                    formik.touched.productionUnitId &&
                      formik.errors.productionUnitId ? (
                      <label className="text-danger" >
                        {formik.errors.productionUnitId}
                      </label>
                    ) : null
                  }
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div class="form-group">
                <div class="col-xs-12">
                  <label class="control-label no-padding-right">Product</label>
                  <select className="form-control" name="productId" value={formik.values.productId} onChange={OnProductChange}>
                    <option value="" >Select...</option>
                    {
                      product.map((data, index) =>
                        <option key={data.id} value={data.id}>{data.productName.toUpperCase()} ({data.id})</option>
                      )
                    }
                  </select>
                  <ProductSelectorView data={selectedProductData} />
                  {
                    formik.touched.productId &&
                      formik.errors.productId ? (
                      <label className="text-danger" >
                        {formik.errors.productId}
                      </label>
                    ) : null
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div class="form-group">
                <div class="col-xs-12">
                  <label class="control-label no-padding-right">Given Product</label>
                  <select className="form-control" name="givenProductId" value={formik.values.givenProductId} onChange={OnGivenProductChange}>
                    <option value="" >Select...</option>
                    {
                      givenProduct.map((data, index) =>
                        <option key={data.consumeProduct.id} value={data.consumeProduct.id}>{data.consumeProduct.productName.toUpperCase()} ({data.consumeProduct.id})</option>
                      )
                    }
                  </select>
                  <ProductSelectorView data={selectedGivenProductData} />
                  {
                    formik.touched.givenProductId &&
                      formik.errors.givenProductId ? (
                      <label className="text-danger" >
                        {formik.errors.givenProductId}
                      </label>
                    ) : null
                  }
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div class="form-group">
                <div class="col-xs-12">
                  <label class="control-label no-padding-right">Date</label>

                  <span class="input-icon" style={{ width: "100%" }}>
                    <DatePicker placeholder="onDate" name="onDate" className="form-control input-date-padding" selected={formik.values.onDate} onChange={(date) => formik.setValues({ ...formik.values, onDate: date })} />
                    <i class="ace-icon fa fa-calendar blue"></i>
                  </span>
                  {
                    formik.touched.onDate &&
                      formik.errors.onDate ? (
                      <label className="text-danger" >
                        {formik.errors.onDate}
                      </label>
                    ) : null
                  }
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div class="form-group">
                <div class="col-xs-12">
                  <label class="control-label no-padding-right">Quantity - {formik.values.unit}</label>
                  <input disabled={formik.values.isOrder == 0 ? false : true} className="form-control" type="number" placeholder="Quantity" name="quantity" {...formik.getFieldProps("quantity")} />
                  {
                    formik.touched.quantity &&
                      formik.errors.quantity ? (
                      <label className="text-danger" >
                        {formik.errors.quantity}
                      </label>
                    ) : null
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <div class="form-group">
                <div class="col-xs-12">
                  <label class="control-label no-padding-right"> Note</label>
                  <TextBoxTranslater className="form-control" type="text" placeholder="Note" name="note" value={formik.values.note} onChange={(e) => formik.setValues({ ...formik.values, note: e.target.value })} />
                  {
                    formik.touched.note &&
                      formik.errors.note ? (
                      <label className="text-danger" >
                        {formik.errors.note}
                      </label>
                    ) : null
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <div class="form-group">
                <div class="col-sm-12">
                  <label class="control-label no-padding-right">Is Order</label>
                  <div>
                    <input onChange={() => formik.setValues({ ...formik.values, isOrder: (formik.values.isOrder == 1 ? 0 : 1) })} checked={formik.values.isOrder == 1} type="checkbox" class="ace ace-switch ace-switch-5" />
                    <span class="lbl middle"></span>
                  </div>
                  {
                    formik.touched.isOrder &&
                      formik.errors.isOrder ? (
                      <label className="text-danger" >
                        {formik.errors.isOrder}
                      </label>
                    ) : null
                  }
                </div>
              </div>
            </div>
            {formik.values.isOrder == 1 &&
              <div className="col-xs-12">
                <OrderSelector data={allotedOrdersData} setOrderOnSelect={setSelectedOrder} selectedId={formik.values.orderId} />
              </div>
            }
          </div>

          <div class="clearfix form-actions">
            <div class="pull-right">
              {isLoadingLocal ?
                <LoadingButton />
                :
                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                  <i class="ace-icon fa fa-check bigger-110"></i>
                  Add
                </button>}
            </div>
          </div>

        </form>
      </Container >
    </>
  )
}
