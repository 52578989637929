import React, { useContext, useState } from 'react'
import AppToaster from '../../commonLib/AppToaster';
import * as globalCrud from '../../_crud/globalCrud';
import * as Yup from 'yup'
import { useFormik } from 'formik';

export function OrgRegistrationForm({ token, phone, setIsCreatedOrg }) {
    const initialValues = {
        passCode: "",
        userId: "",
        name: "",
        id: "",
        address: "",
        city: "Mau",
        state: "Uttar Pradesh",
        pin: "275101",
        email: "",
        phone: phone,
        password: "Abcde@12345",
    }

    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [isAgree, setIsAgree] = useState(0)

    const Schema = Yup.object().shape({
        id: Yup.string().min(6, "Must be 6 digit.").max(6, "Must be 6 digit.").required(" Organization Id is required.")
            .matches(
                /^([\d]{6})$/g,
                "Invalid organization id."
            ),
        name: Yup.string().required(" Full Name is required."),
        address: Yup.string().required("Address is required."),
        city: Yup.string().required("City is required."),
        state: Yup.string().required("State is required."),
        pin: Yup.string().required("Pin is required.")
            .matches(
                /^([\d]{6})$/g,
                "Invalid organization id."
            ),
        email: Yup.string().required("Email is required.").email("Email is not valid."),
        phone: Yup.string().required("Phone is required."),
        password: Yup.string().required("Password is required.").matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
        )
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    const Save = (value) => {
        setIsLoadingLocal(true)
        //For Dedicated server hosting
        //globalCrud.CreateNewOrgGlobal({
        globalCrud.CreateNewOrgGlobalRequest({
            Organization: {
                name: value.name,
                id: value.id,
                address: value.address,
                city: value.city,
                state: value.state,
                pin: value.pin,
                email: value.email,
                phone: value.phone
            },
            password: value.password,
            token: token
        }, value.id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingLocal(false);
                    formik.setValues(initialValues)
                    setIsCreatedOrg(true)
                    AppToaster('Registered successfully.', 'success')
                }
                else {
                    setIsLoadingLocal(false);
                    AppToaster(res.data.message, 'error')
                }
            })
            .catch(error => {
                alert(error)
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }


    return (
        <>
            <form onSubmit={formik.handleSubmit} class="form-horizontal" autocomplete="off">
                <div className="row">
                    <div className="col-md-3">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Choose Organization Id <small>(must be unique & digit only)</small></label>
                                <input className="form-control" type="text" placeholder="Organization Id" name="id" {...formik.getFieldProps("id")} />
                                {
                                    formik.touched.id &&
                                        formik.errors.id ? (
                                        <label className="text-danger" >
                                            {formik.errors.id}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">User Id <small>(you could not change, it is your first user id.)</small></label>
                                <input className="form-control" type="text" disabled value="administrator" placeholder="" />

                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Organization Name</label>
                                <input className="form-control" type="text" placeholder="Name" name="name" {...formik.getFieldProps("name")} />
                                {
                                    formik.touched.name &&
                                        formik.errors.name ? (
                                        <label className="text-danger" >
                                            {formik.errors.name}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Phone</label>
                                <input disabled className="form-control" type="text" placeholder="Phone" name="phone" {...formik.getFieldProps("phone")} />
                                {
                                    formik.touched.phone &&
                                        formik.errors.phone ? (
                                        <label className="text-danger" >
                                            {formik.errors.phone}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Address</label>
                                <input className="form-control" type="text" placeholder="Address" name="address" {...formik.getFieldProps("address")} />
                                {
                                    formik.touched.address &&
                                        formik.errors.address ? (
                                        <label className="text-danger" >
                                            {formik.errors.address}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="ontrol-label no-padding-right">Password <small>(Remember it)</small></label>
                                <input className="form-control" type="text" placeholder="Password" name="password" {...formik.getFieldProps("password")} />
                                {
                                    formik.touched.password &&
                                        formik.errors.password ? (
                                        <label className="text-danger" >
                                            {formik.errors.password}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div> */}

                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Email</label>
                                <input className="form-control" type="email" placeholder="Email" name="email" {...formik.getFieldProps("email")} />
                                {
                                    formik.touched.email &&
                                        formik.errors.email ? (
                                        <label className="text-danger" >
                                            {formik.errors.email}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">City</label>
                                <input className="form-control" type="text" placeholder="City" name="city" {...formik.getFieldProps("city")} />
                                {
                                    formik.touched.city &&
                                        formik.errors.city ? (
                                        <label className="text-danger" >
                                            {formik.errors.city}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">State</label>
                                <input className="form-control" type="text" placeholder="State" name="state" {...formik.getFieldProps("state")} />
                                {
                                    formik.touched.state &&
                                        formik.errors.state ? (
                                        <label className="text-danger" >
                                            {formik.errors.state}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Pin</label>
                                <input className="form-control" type="text" placeholder="Pin" name="pin" {...formik.getFieldProps("pin")} />
                                {
                                    formik.touched.pin &&
                                        formik.errors.pin ? (
                                        <label className="text-danger" >
                                            {formik.errors.pin}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-8"></div>
                    <div className="col-lg-4">
                        <label class="block">
                            <input onChange={() => setIsAgree(isAgree == 1 ? 0 : 1)} checked={isAgree == 1} type="checkbox" class="ace" />
                            <span class="lbl">
                                &nbsp;I accept the
                                <a href="https://baithka.com/useragreement" target="_blank">&nbsp;User Agreement</a>
                            </span>
                        </label>


                        <div class="clearfix">
                            {isLoadingLocal ?
                                <>
                                    <button disabled class="width-65 btn btn-sm btn-success">
                                        <span class="bigger-110">Please wait</span>
                                        <i class="ace-icon fa fa-arrow-right icon-on-right"></i>
                                    </button>
                                    <br />
                                    <small>Note: Organization will take 1 to 2 minutes for setting up and create.</small>
                                </>
                                :
                                <button disabled={!formik.isValid || isAgree == 0} class="width-65 btn btn-sm btn-success">
                                    <span class="bigger-110">Register</span>
                                    <i class="ace-icon fa fa-arrow-right icon-on-right"></i>
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}
