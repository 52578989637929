import React from 'react'
import { EmptyData } from '../../commonLib/EmptyData';
import { getPageMargins } from '../../config/GlobalSettings';
import { GetEGatePassProductsTypeName } from '../../enumCollections/EGatePassProductsType';
import { ContactViewS } from '../Global/Elements/ContactViewS';
import { ProductViewS } from '../Global/Elements/ProductViewS';
import { WarehouseViewS } from '../Global/Elements/WarehouseViewS';
import { PrintBranding } from './PrintBranding';

export const WarehouseTransferPrint = React.forwardRef((props, ref) => {
    const { warehouseTransfer, warehouseTransferProduct, warehouseTransferProductCount } = props
    return (
        <div ref={ref}>
            <style type="text/css">
                {getPageMargins()}
            </style>
            {
                warehouseTransfer && warehouseTransferProduct &&
                <PrintBranding printName="Warehouse Transfer" date={warehouseTransfer.onDate} id={warehouseTransfer.id}>
                    {
                        warehouseTransfer && warehouseTransferProduct &&
                        <div className="row">
                            <div className="col-xs-12">

                                <table style={{ width: "100%" }} className="table-bordered-dark">
                                    <tbody>
                                        <tr >
                                            <td>Contact</td>
                                            <td>
                                                <ContactViewS item={warehouseTransfer.contact} uid={"GC1"} />

                                            </td>
                                            <td>
                                                <span className="pull-right">
                                                    <WarehouseViewS item={warehouseTransfer.warehouse} />
                                                </span>
                                            </td>
                                        </tr>
                                        <tr >
                                            <td>Total</td>
                                            <td colSpan={2}>
                                                <b>{warehouseTransferProductCount.count}</b> Items with <b>{warehouseTransferProductCount.totalQuantity}</b> quantity.
                                            </td>
                                        </tr>
                                        <tr >
                                            <td>Note</td>
                                            <td colSpan={2}>{warehouseTransfer.note ? warehouseTransfer.note : "NA"}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    }
                    <br />

                    <div className="row">
                        <div className="col-xs-12">
                            <h5 className="text-center"> Transfered Product</h5>
                            {warehouseTransferProduct && warehouseTransferProduct.length > 0 ?
                                <table style={{ width: "100%" }} className="table-bordered-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Product</th>
                                            <th>Main</th>
                                            <th>Raw</th>
                                            <th>Defective</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {warehouseTransferProduct.map((item, index) =>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <ProductViewS item={item.product} uid={"PP" + index} />
                                                </td>
                                                <td className={item.mainStock != 0 && "bolder"}>{item.mainStock != 0 ? <>{item.mainStock} {item.product.unit}</> : "-"}</td>
                                                <td className={item.rawStock != 0 && "bolder"}>{item.rawStock != 0 ? <>{item.rawStock} {item.product.unit}</> : "-"}</td>
                                                <td className={item.defectiveStock != 0 && "bolder"}>{item.defectiveStock != 0 ? <>{item.defectiveStock} {item.product.unit}</> : "-"}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                :
                                <EmptyData>
                                    No Item Added.
                                    <br />
                                    <br />
                                </EmptyData>
                            }
                        </div>
                    </div>
                </PrintBranding>
            }
        </div>
    )
})