import React from 'react'

export function WarehouseViewS({ item, isOnlyCode = false }) {
    return (
        <>
            {isOnlyCode ?
                <span>
                    <i class="menu-icon fa fa-industry"></i> {item ? item.warehouseCode : "MAIN"}
                </span>
                :
                <span>
                    Branch: {item ? item.warehouseCode : "MAIN"} <i class="menu-icon fa fa-industry"></i>
                </span>
            }
        </>
    )
}
