import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { Container } from '../../../base/Container';
import { loadingContext } from '../../../commonLib/_lodingContext';
import * as processingWageVoucherCrud from '../../../_crud/vouchers/processingWageVoucherCrud'
import { ProcessingItemView } from './ProcessingItemView';
import { TimelineHistory } from '../../Global/TimelineHistory';
import { AdvanceView } from '../../Global/Elements/AdvanceView';
import { MyAlert } from '../../../commonLib/MyAlert';
import AppToaster from '../../../commonLib/AppToaster';
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import { PrintStarterDash } from '../../Prints/PrintStaterDash';
import { ProcessingVoucherPrint } from '../../Prints/ProcessingVoucherPrint';
import { DefectView } from '../../Global/Elements/DefectView';
import { getIsPrint } from '../../../config/GlobalSettings';

export function ProcessingVoucherDashBoard() {
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [processingVoucher, setProcessingVoucher] = useState()
    const [voucherPayment, setVoucherPayment] = useState([])
    const [voucherDetails, setVoucherDetails] = useState([])
    const [advanceData, setAdvanceData] = useState()
    const [defectData, setDefectData] = useState()
    const history = useHistory();
    const { id } = useParams()

    function doRefresh() {
        getProcessingVoucher()
    }

    useEffect(() => {
        getProcessingVoucher()
    }, []);

    function getProcessingVoucher() {
        setIsLoading(true);
        processingWageVoucherCrud.GetVoucherById(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let hpayment = res.data.data.payments
                        let hdetails = res.data.data.details
                        setVoucherPayment(hpayment)
                        setVoucherDetails(hdetails)
                        setProcessingVoucher(hdata)
                    }
                    else {
                    }
                }
                else {
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
            })
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        processingWageVoucherCrud.VoucherRemove(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    AppToaster('Deleted successfully.', 'success')
                    history.goBack()

                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by someone,could not delete.', 'error')
                }

            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }
    const printRef = useRef();
    return (
        <>
            {getIsPrint && <div style={{ display: "none" }}>
                <ProcessingVoucherPrint paid={processingVoucher && processingVoucher.paid} pending={processingVoucher && processingVoucher.pending} processingVoucher={processingVoucher && processingVoucher.voucher} voucherPayment={voucherPayment} voucherDetails={voucherDetails} ref={printRef} />
            </div>}
            <Container title="Processing Voucher Dashboard" doRefresh={doRefresh} isBack="true">
                {getIsPrint && processingVoucher && voucherPayment && voucherDetails &&
                    <div class="btn-group pull-right">
                        <PrintStarterDash printRef={printRef} />
                    </div>
                }
                <div class="btn-group">
                    <MyAlert confirmBtnText="Yes, delete it!" title="Delete" param={id} onConfirm={DeleteItem} >
                        <i class="ace-icon fa fa-trash-o bigger-160"></i>
                    </MyAlert>
                </div>
                <div class="hr dotted"></div>
                <div className="row">
                    <div className="col-lg-8">
                        <ProcessingItemView advance={advanceData} defect={defectData} paid={processingVoucher && processingVoucher.paid} pending={processingVoucher && processingVoucher.pending} processingVoucher={processingVoucher && processingVoucher.voucher} voucherPayment={voucherPayment} voucherDetails={voucherDetails} doRefresh={getProcessingVoucher} />
                    </div>

                    <div className="col-lg-4">
                        {processingVoucher && <AdvanceView advanceData={setAdvanceData} contactid={processingVoucher.voucher.contact.id} />}
                        <br />
                        {processingVoucher && <DefectView defectData={setDefectData} contactid={processingVoucher.voucher.contact.id} />}
                        <br />
                        <TimelineHistory id={id} type={1} pageSize={10} text="Processing Voucher Timeline" />
                    </div>

                </div>
            </Container>
        </>
    )
}
