import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container } from "../../../base/Container";
import AppToaster from "../../../commonLib/AppToaster";
import { loadingMiniContext } from "../../../commonLib/lodingMiniContext";
import { Spinner } from '../../../commonLib/Spinner'
import * as salaryVoucherCrud from '../../../_crud/vouchers/salaryVoucherCrud'
import { GetETransactionModeName } from '../../../enumCollections/ETransactionMode'
import { useParams } from "react-router-dom";
import { PagingEmpty } from "../../../commonLib/PagingEmpty";
import ReactTooltip from 'react-tooltip';
import Moment from 'react-moment';
import 'moment-timezone';
import { DEFAULT_USER_IMG } from "../../../config/GlobalSettings";
import { EmptyData } from "../../../commonLib/EmptyData";
import { MyAlert } from "../../../commonLib/MyAlert";
import { BASE_URL } from "../../../initApi/apiUrls";
import { UserView } from "../../Global/Elements/UserView";

export function ManageSalaryVoucherPayment() {

    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [salaryVoucherPayment, setSalaryVoucherPayment] = useState([])
    const { id } = useParams()

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoadingMini(true);
        salaryVoucherCrud.GetVoucherById(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data.payments
                        setSalaryVoucherPayment(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setIsLoadingMini(false)
                        setSalaryVoucherPayment([]);
                    }
                }
                else {
                    setIsLoadingMini(false)
                    setSalaryVoucherPayment([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        getData()
        setTimeout(() => {
            event.detail.complete();
        }, 1000);
    }

    const DeleteItem = (paymentId) => {
        setIsLoadingMini(true)
        salaryVoucherCrud.RemoveVoucherPayment(paymentId)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    AppToaster('Deleted successfully.', 'success')
                    getData()
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by someone,could not delete.', 'error')
                }

            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })

    }
    return (
        <Container title="Salary Voucher Payment" doRefresh={doRefresh} isBack="true">
            <Link to={"/salaryvoucher/dashBoard/" + id + "/salaryvoucherpayment/add"} class="btn btn-sm btn-success">
                <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                New
            </Link>
            <div class="hr dotted"></div>
            <PagingEmpty text={"Total " + salaryVoucherPayment.length + " items."}>
                {
                    isLoadingMini ?
                        <Spinner />
                        :
                        salaryVoucherPayment && salaryVoucherPayment.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Amount</th>
                                            <th>Transaction Mode</th>
                                            <th>Transaction Details</th>
                                            <th>Received Date</th>
                                            <th>Fiscal Year</th>
                                            <th>Other Charges</th>
                                            <th>Other Charges Ledger Id</th>
                                            <th>Note</th>
                                            <th>By</th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {salaryVoucherPayment.map((item, index) =>
                                            <tr key={item.id}>
                                                <td><b>Voucher: </b>{item.voucherId}, <b>Ref: </b>{item.refId}, <b>From Ledger: </b>{item.fromLedgerId}, <b>To Ledger: </b>{item.toLedgerId}</td>
                                                <td>{item.amount}</td>
                                                <td><span class={item.transactionMode == 1 ? "label label-sm label-info" : "label label-sm label-warning"}>{GetETransactionModeName(item.transactionMode)}</span></td>
                                                <td>{item.transactionDetails} </td>
                                                <td><Moment format="DD-MMM-YYYY">{item.receivedDate}</Moment></td>
                                                <td>{item.fiscalYear}</td>
                                                <td>{item.otherCharges}</td>
                                                <td>{item.otherChargesLedgerId}</td>
                                                <td>{item.note}</td>
                                                <td>
                                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                            <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                        </MyAlert>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                }
            </PagingEmpty>
        </Container >
    )
}