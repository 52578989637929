import React from 'react'

export function ContactViewS({ item }) {
    return (
        item &&
        <>
            {item.fullName} ({item.refId ? item.refId : item.id})
        </>
    )
}
