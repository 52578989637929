import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { OrgId } from "../config/GlobalSettings";
import { createAxios, createAxiosWithToken, createAxiosWithTokenFile, createAxiosWithTokenDownloadFile } from "../initApi/createAxios";

export async function SearchUser(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ApplicationUser/SearchUser?PageNo=" + param.pageNo + "&PageSize=" + param.pageSize + "&SearchTerm=" + param.searchTerm)
    }
}
export async function SearchUserContact(pageNo, pageSize, searchTerm) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ApplicationUser/SearchUserContact?PageNo=" + pageNo + "&PageSize=" + pageSize + "&SearchTerm=" + searchTerm)
    }
}

export async function GetById(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ApplicationUser/GetById?Id=" + id)
    }
}
export async function GetByUserName(userName) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ApplicationUser/GetByUserName?UserName=" + userName)
    }
}
export async function Update(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ApplicationUser/Update", param)
    }
}
export async function ChangePassword(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ApplicationUser/ChangePassword", param)
    }
}
export async function ResetPassword(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ApplicationUser/ResetPassword", param)
    }
}

export async function Add(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ApplicationUser/Add", param)
    }
}
export async function ForgotPassword(userName, oTP, newPass, confirmPassword, orgId) {
    return await createAxios(orgId).post("ApplicationUser/ForgotPassword?UserName=" + userName + "&OTP=" + oTP + "&NewPass=" + newPass + "&ConfirmPassword=" + confirmPassword)
}
export async function ForgotPasswordInitiate(userName, orgId) {
    return await createAxios(orgId).post("ApplicationUser/ForgotPasswordInitiate?UserName=" + userName)
}
export async function ForgotPasswordInitiateForContact(mobile) {
    return await createAxios(OrgId()).post("ApplicationUser/ForgotPasswordInitiateForContact?Mobile=" + mobile)
}
export async function UpdateStatus(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ApplicationUser/UpdateStatus", param)
    }
}

export async function UploadDP(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithTokenFile(loginData.token, OrgId()).post("ApplicationUser/UploadDP", param)
    }
}

export async function UnloadDP(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ApplicationUser/UnloadDP", param)
    }
}
export async function DownloadBackup() {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithTokenDownloadFile(loginData.token, OrgId()).get("ApplicationUser/DownloadBackup")
    }
}