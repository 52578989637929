import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Container } from '../../../base/Container';
import AppToaster from '../../../commonLib/AppToaster';
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import * as productReceivingCrud from '../../../_crud/productReceivingCrud'
import * as productionUnitCrud from '../../../_crud/productionUnitCrud';
import * as productCrud from '../../../_crud/productCrud';
import { LoadingButton } from '../../../commonLib/LoadingButton';
import ContactSelector from '../../Global/ContactSelector';
import { ContactSelectorView } from '../../Global/SelectorView/ContactSelectorView';
import { ProductSelectorView } from '../../Global/SelectorView/ProductSelectorView';
import { ProductionSelectorView } from '../../Global/SelectorView/ProductionSelectorView';
import GetCurrentDateForSearch from '../../../commonLib/dateFormat';
import DatePicker from "react-datepicker";
import * as contactProcessingAllotmentCrud from '../../../_crud/contactProcessingAllotmentCrud'
import GetLoginInfo from '../../../auth/_userContext/GetLoginInfo';
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater';

export function AddProductReceiving() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const history = useHistory()
    const [production, setProduction] = useState([])
    const [product, setProduct] = useState([])
    const [selectedContactData, setSelectedContactData] = useState(undefined);
    const [selectedProductData, setSelectedProductData] = useState(undefined);
    const [selectedProductionData, setSelectedProductionData] = useState(undefined);
    const [processing, setProcessing] = useState([])
    const userData = GetLoginInfo()

    const initialValues = {
        onDate: new Date(GetCurrentDateForSearch(0)),
        contactId: "",
        productionUnitId: "",
        productId: "",
        isVouchered: 1,
        voucherId: 2,
        paymentStatus: 2,
        freshQuantity: 0,
        isFreshQuantityToMain: 0,
        defectiveQuantity: 0,
        rejectedQuantity: 0,
        defectiveWagePercent: 0,
        unit: "",
        productWageId: 1,
        note: "",
        isLotClosed: 0,
        isProcessed: 0,
        processingId: 0,
        processedQuantity: 0,
        receivedWeight: 0,
        isWarehouse: userData.isWarehouse,
        warehouseId: userData.warehouseId,
    }

    const Schema = Yup.object().shape({
        onDate: Yup.string().required("On Date is required."),
        contactId: Yup.string().required("Contact is required."),
        productionUnitId: Yup.string().required("Production Unit is required."),
        productId: Yup.string().required("Product is required."),
        freshQuantity: Yup.string().required("Fresh Quantity is required."),
        isFreshQuantityToMain: Yup.string().required("Is Fresh Quantity To Main is required."),
        defectiveQuantity: Yup.string().required("Defective Quantity is required."),
        rejectedQuantity: Yup.string().required("Rejected Quantity is required."),
        defectiveWagePercent: Yup.string().required("Defective Wage Percent is required."),
        unit: Yup.string().required("Unit is required."),
        isLotClosed: Yup.string().required("Is Lot Closed is required."),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    function setQuantity() {
        if (selectedProductData) {
            if ((formik.values.freshQuantity + formik.values.defectiveQuantity + formik.values.rejectedQuantity) > selectedProductData.stock) {
                formik.setValues({ ...formik.values, freshQuantity: 0, defectiveQuantity: 0, rejectedQuantity: 0 })
            }
            else if ((formik.values.freshQuantity + formik.values.defectiveQuantity + formik.values.rejectedQuantity) < 0) {
                formik.setValues({ ...formik.values, freshQuantity: 0, defectiveQuantity: 0, rejectedQuantity: 0 })
            }
        }
    }

    useEffect(() => {
        setQuantity()
    }, [formik.values.freshQuantity, formik.values.defectiveQuantity, formik.values.rejectedQuantity]);

    useEffect(() => {
        if (selectedContactData) {
            formik.setValues({ ...formik.values, contactId: selectedContactData.id })
            getProduction(selectedContactData.id)
            getProcessing(selectedContactData.id)
        }
    }, [selectedContactData]);

    useEffect(() => {
        if (formik.values.productionUnitId)
            getProducts(formik.values.productionUnitId)
    }, [formik.values.productionUnitId])

    useEffect(() => {
        if (selectedProductData) {
            formik.setValues({ ...formik.values, productId: selectedProductData.id, unit: selectedProductData.unit })
        }
        else {
            formik.setValues({ ...formik.values, productId: "" })
        }
    }, [selectedProductData])

    useEffect(() => {
        if (selectedProductionData) {
            formik.setValues({ ...formik.values, productionUnitId: selectedProductionData.id })
        }
        else {
            formik.setValues({ ...formik.values, productionUnitId: "" })
        }
    }, [selectedProductionData])

    const OnProductChange = (e) => {
        const { value } = e.target;
        if (value) {
            product.forEach(element => {
                if (element.id == value) {
                    {
                        setSelectedProductData(element)
                    }
                }
            });
        }
        else {
            setSelectedProductData(undefined)
        }
    }

    const OnProductionChange = (e) => {
        const { value } = e.target;
        if (value) {
            production.forEach(element => {
                if (element.id == value) {
                    {
                        setSelectedProductionData(element)
                    }
                }
            });
        }
        else {
            setSelectedProductionData(undefined)
        }
    }

    const Save = (value) => {
        if (value.isProcessed == 1) {
            if (!(value.processingId && value.processedQuantity)) {
                AppToaster("Please fill all processing feilds.", 'error')
                return;
            }
        }
        if (selectedProductionData && selectedProductionData.productionCategory.type != 1)
            if ((value.freshQuantity + value.defectiveQuantity + value.rejectedQuantity) > 0) {
                setIsLoadingMini(true);
                setIsLoadingLocal(true)
                productReceivingCrud.Add(value)
                    .then(res => {
                        if (res.data.result.succeeded) {
                            setIsLoadingMini(false)
                            setIsLoadingLocal(false);
                            AppToaster('Added successfully.', 'success')
                            history.goBack()
                        }
                        else {
                            setIsLoadingMini(false)
                            setIsLoadingLocal(false);
                            AppToaster("Server Error /Invalid Try.", 'error')
                        }
                    })
                    .catch(error => {
                        setIsLoadingMini(false);
                        setIsLoadingLocal(false);
                        AppToaster(error, 'error')
                    })
            }
            else {
                AppToaster("Quantity should greater then zero.", 'error')
            }
        else {
            AppToaster("You could not receive from Trade production unit.", 'error')
        }
    }

    function getProduction(contactid) {
        setIsLoadingMini(true);
        productionUnitCrud.GetByContactId(contactid)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProduction(hdata);
                    }
                    else {
                        setProduction([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProduction([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function getProducts(productionUnitId) {
        setIsLoadingMini(true);
        productCrud.GetByProductionUnit(productionUnitId)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProduct(hdata);
                    }
                    else {
                        setProduct([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProduct([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }
    function getProcessing(contactid) {
        setIsLoadingMini(true);
        contactProcessingAllotmentCrud.GetByContactDdl(contactid)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProcessing(hdata);
                    }
                    else {
                        setProcessing([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProcessing([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }
    const OnProcessingChange = (e) => {
        const { value } = e.target;
        let ispunit = 0;

        if (value) {
            processing.forEach(element => {
                if (element.processing.id == value) {
                    {
                        if (element.processing.mode == 1)
                            ispunit = 1
                        else
                            ispunit = 0
                    }
                }
            });
        }
        formik.setValues({ ...formik.values, processingId: value })
    }
    return (
        <>
            <Container title="Add Product Receiving" isBack="true">
                <form onSubmit={formik.handleSubmit} class="form-horizontal">
                    <div className="row">
                        <div className="col-md-8">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Contact</label>
                                    <ContactSelector searchtext="" setSelectedData={setSelectedContactData} />
                                    {
                                        formik.touched.contactId &&
                                            formik.errors.contactId ? (
                                            <label className="text-danger" >
                                                {formik.errors.contactId}
                                            </label>
                                        ) : null
                                    }
                                    <ContactSelectorView data={selectedContactData} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Date</label>

                                    <span class="input-icon" style={{ width: "100%" }}>
                                        <DatePicker placeholder="onDate" name="onDate" className="form-control input-date-padding" selected={formik.values.onDate} onChange={(date) => formik.setValues({ ...formik.values, onDate: date })} />
                                        <i class="ace-icon fa fa-calendar blue"></i>
                                    </span>
                                    {
                                        formik.touched.onDate &&
                                            formik.errors.onDate ? (
                                            <label className="text-danger" >
                                                {formik.errors.onDate}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Production Unit</label>
                                    <select className="form-control" name="productionUnitId" value={formik.values.productionUnitId} onChange={OnProductionChange}>
                                        <option value="" >Select...</option>
                                        {
                                            production.map((data, index) =>
                                                <option key={data.id} value={data.id}>{data.productionUnitName.toUpperCase()} ({data.id})</option>
                                            )
                                        }
                                    </select>
                                    <ProductionSelectorView data={selectedProductionData} />
                                    {
                                        formik.touched.productionUnitId &&
                                            formik.errors.productionUnitId ? (
                                            <label className="text-danger" >
                                                {formik.errors.productionUnitId}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Product</label>
                                    <select className="form-control" name="productId" value={formik.values.productId} onChange={OnProductChange}>
                                        <option value="" >Select...</option>
                                        {
                                            product.map((data, index) =>
                                                <option key={data.id} value={data.id}>{data.productName.toUpperCase()} ({data.id})</option>
                                            )
                                        }
                                    </select>
                                    <ProductSelectorView data={selectedProductData} />
                                    {
                                        formik.touched.productId &&
                                            formik.errors.productId ? (
                                            <label className="text-danger" >
                                                {formik.errors.productId}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Is Lot Closed</label>

                                    <div>
                                        <input onChange={() => formik.setValues({ ...formik.values, isLotClosed: (formik.values.isLotClosed == 1 ? 0 : 1) })} checked={formik.values.isLotClosed == 1} type="checkbox" class="ace ace-switch ace-switch-5" />
                                        <span class="lbl middle"></span>
                                    </div>
                                    {
                                        formik.touched.isLotClosed &&
                                            formik.errors.isLotClosed ? (
                                            <label className="text-danger" >
                                                {formik.errors.isLotClosed}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {selectedProductData &&
                        <div className="row">
                            <div className="col-md-4">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <label class="control-label no-padding-right">Fresh- {formik.values.unit}</label>

                                        <input className="form-control" placeholder="Fresh Quantity" type="number" name="freshQuantity" {...formik.getFieldProps("freshQuantity")} />
                                        {
                                            formik.touched.freshQuantity &&
                                                formik.errors.freshQuantity ? (
                                                <label className="text-danger" >
                                                    {formik.errors.freshQuantity}
                                                </label>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <label class="control-label no-padding-right">Rejected- {formik.values.unit}</label>

                                        <input className="form-control" placeholder="Rejected Quantity" type="number" name="rejectedQuantity" {...formik.getFieldProps("rejectedQuantity")} />
                                        {
                                            formik.touched.rejectedQuantity &&
                                                formik.errors.rejectedQuantity ? (
                                                <label className="text-danger" >
                                                    {formik.errors.rejectedQuantity}
                                                </label>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <label class="control-label no-padding-right">Defective- {formik.values.unit}</label>

                                        <input className="form-control" placeholder="Defective Quantity" type="number" name="defectiveQuantity" {...formik.getFieldProps("defectiveQuantity")} />
                                        {
                                            formik.touched.defectiveQuantity &&
                                                formik.errors.defectiveQuantity ? (
                                                <label className="text-danger" >
                                                    {formik.errors.defectiveQuantity}
                                                </label>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    }
                    <div className="row">
                        <div className="col-md-3">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Is Fresh Quantity To Main</label>

                                    <div>
                                        <input onChange={() => formik.setValues({ ...formik.values, isFreshQuantityToMain: (formik.values.isFreshQuantityToMain == 1 ? 0 : 1) })} checked={formik.values.isFreshQuantityToMain == 1} type="checkbox" class="ace ace-switch ace-switch-5" />
                                        <span class="lbl middle"></span>
                                    </div>
                                    {
                                        formik.touched.isFreshQuantityToMain &&
                                            formik.errors.isFreshQuantityToMain ? (
                                            <label className="text-danger" >
                                                {formik.errors.isFreshQuantityToMain}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-9">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Note</label>

                                    <TextBoxTranslater className="form-control" placeholder="Note" type="text" name="note" value={formik.values.note} onChange={(e) => formik.setValues({ ...formik.values, note: e.target.value })} />
                                    {
                                        formik.touched.note &&
                                            formik.errors.note ? (
                                            <label className="text-danger" >
                                                {formik.errors.note}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Is Processed</label>
                                    <div>
                                        <input onChange={() => formik.setValues({ ...formik.values, isProcessed: (formik.values.isProcessed == 1 ? 0 : 1) })} checked={formik.values.isProcessed == 1} type="checkbox" class="ace ace-switch ace-switch-5" />
                                        <span class="lbl middle"></span>
                                    </div>
                                    {
                                        formik.touched.isProcessed &&
                                            formik.errors.isProcessed ? (
                                            <label className="text-danger" >
                                                {formik.errors.isProcessed}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {formik.values.isProcessed == 1 &&
                        <div className="row">
                            <div className="col-md-4">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <label class="control-label no-padding-right">Processings</label>
                                        <select className="form-control" name="processingId" value={formik.values.processingId} onChange={OnProcessingChange}>
                                            <option value="" >Select...</option>
                                            {
                                                processing.map((data, index) =>
                                                    <option key={data.processing.id} value={data.processing.id}>{data.processing.name.toUpperCase()} ({data.processing.id})</option>
                                                )
                                            }
                                        </select>
                                        {
                                            formik.touched.processingId &&
                                                formik.errors.processingId ? (
                                                <label className="text-danger" >
                                                    {formik.errors.processingId}
                                                </label>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <label class="control-label no-padding-right">Processed Quantity</label>
                                        <input className="form-control" placeholder="Processed Quantity" min="0" type="number" name="processedQuantity" {...formik.getFieldProps("processedQuantity")} />
                                        {
                                            formik.touched.processedQuantity &&
                                                formik.errors.processedQuantity ? (
                                                <label className="text-danger" >
                                                    {formik.errors.processedQuantity}
                                                </label>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div class="clearfix form-actions">
                        <div class="pull-right">
                            {isLoadingLocal ?
                                <LoadingButton />
                                :
                                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Add
                                </button>}
                        </div>
                    </div>
                </form>
            </Container >
        </>
    )
}
