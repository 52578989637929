const GetELedgerTypeName = (id) => {
    switch (id) {
        case 1: { return "Income" }
        case 2: { return "Expense" }
        case 3: { return "Libility" }
        case 4: { return "Asset" }
        case 5: { return "Current Asset" }
        case 10: { return "Equity" }
    }
}

const GetELedgerType = {
    ELedgerType: [
        { Id: 1, Name: "Income" },
        { Id: 2, Name: "Expense" },
        { Id: 3, Name: "Libility" },
        { Id: 4, Name: "Asset" },
        { Id: 5, Name: "Current Asset" },
        { Id: 10, Name: "Equity" }
    ]
}

export { GetELedgerTypeName, GetELedgerType }