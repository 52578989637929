import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../../../base/Container';
import AppToaster from '../../../commonLib/AppToaster';
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import { PagingEmpty } from '../../../commonLib/PagingEmpty';
import { Spinner } from '../../../commonLib/Spinner';
import *as reportCrud from '../../../_crud/reportCrud'
import *as warehouseCrud from '../../../_crud/warehouse/warehouseCrud'
import 'moment-timezone';
import { EmptyData } from '../../../commonLib/EmptyData';

export function ActiveProductReport() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [product, setProduct] = useState([])
    const initValues = {
        IsWarehouse: 0,
        WarehouseId: -1,
    }
    const [search, setSearch] = useState(initValues)
    const [warehouse, setWarehouse] = useState([])

    useEffect(() => {
        getWarehouse()
        getData()
    }, []);

    useEffect(() => {
        if (search.WarehouseId == -1) {
            setSearch({ ...search, IsWarehouse: 0 })
        }
        else {
            setSearch({ ...search, IsWarehouse: 1 })
        }
    }, [search.WarehouseId]);

    function getData() {
        setIsLoadingMini(true);
        reportCrud.GetActiveProductsByCategory(search.IsWarehouse, search.WarehouseId)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProduct(hdata);
                    }
                    else {
                        setProduct([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProduct([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }
    function getWarehouse() {
        setIsLoadingMini(true);
        warehouseCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setWarehouse(hdata);
                        //alert(JSON.stringify(hdata))
                    }
                    else {
                        setWarehouse([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setWarehouse([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }
    return (
        <>
            <Container title="Active Products (Category)" doRefresh={getData} isBack="true">
                <div class="form-horizontal" >
                    <div className="row">
                        <div className="col-md-4">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <select className="form-control" value={search.WarehouseId} onChange={(e) => setSearch({ ...search, WarehouseId: e.target.value })}>
                                    <option value="-1" >All</option>
                                    <option value="0" >MAIN Warehouse</option>
                                    {
                                        warehouse.map((data, index) =>
                                            <option key={data.id} value={data.id}>{data.name.toUpperCase()} ({data.warehouseCode.toUpperCase()})</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-1">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <button onClick={getData} to="/product/add" class="btn btn-sm btn-info pull-right">
                                    <i class="ace-icon fa fa-search bigger-110"></i>
                                    Run
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hr dotted"></div>
                <PagingEmpty text={"Total " + product.length + " items."}>
                    {isLoadingMini ?
                        <Spinner />
                        :
                        product && product.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "43px" }}>#Sr</th>
                                            <th>Category</th>
                                            <th>Active Products</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {product.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>{index + 1}</td>
                                                <td>{item.productCategory.catName} </td>
                                                <td><h3 style={{ margin: "0" }}>{item.count}</h3></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </PagingEmpty>
            </Container >
        </>
    )
}
