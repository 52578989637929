const GetEProductionUnitOwnershipName = (id) => {
    switch (id) {
        case 1: { return "By Contact" }
        case 2: { return "By Self" }
    }
}

const GetEProductionUnitOwnership = {
    EProductionUnitOwnership: [
        { Id: 1, Name: "By Contact" },
        { Id: 2, Name: "By Self" },
    ]
}

export { GetEProductionUnitOwnershipName, GetEProductionUnitOwnership }