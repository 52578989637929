import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import * as orderCrud from '../../_crud/orderCrud';
import AppToaster from '../../commonLib/AppToaster';
import { Container } from '../../base/Container';
import { LoadingButton } from '../../commonLib/LoadingButton';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { ProductSelectorView } from '../Global/SelectorView/ProductSelectorView';
import GetCurrentDateForSearch from '../../commonLib/dateFormat';
import DatePicker from "react-datepicker";
import { ProductionSelectorView } from '../Global/SelectorView/ProductionSelectorView';
import * as productionUnitCrud from '../../_crud/productionUnitCrud';
import * as productionConfigurationCrud from '../../_crud/productionConfigurationCrud';
import * as productCrud from '../../_crud/productCrud';
import { useParams } from 'react-router-dom';
import GetLoginInfo from '../../auth/_userContext/GetLoginInfo';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

export function AddContactOrders() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const history = useHistory();
    const [selectedProductData, setSelectedProductData] = useState(undefined);
    const [selectedProductionData, setSelectedProductionData] = useState(undefined);
    const [production, setProduction] = useState([])
    const [product, setProduct] = useState([])
    const [productionConfiguration, setProductionConfiguration] = useState([])
    const { id } = useParams()
    const userData = GetLoginInfo();

    const initialValues = {
        productId: "",
        productionConfigurationId: "",
        toProductionUnitId: "",
        quantity: 0,
        onDate: new Date(GetCurrentDateForSearch(0)),
        dueDate: new Date(GetCurrentDateForSearch(0)),
        status: 1,
        allotedToContactId: id,
        unit: "",
        details: "",
        isWarehouse: userData.isWarehouse,
        warehouseId: userData.warehouseId,
    }

    const Schema = Yup.object().shape({
        productId: Yup.string().required("Product is required."),
        toProductionUnitId: Yup.string().required("To Production Unit is required."),
        allotedToContactId: Yup.string().required("Is For Production Unit is required."),
        quantity: Yup.string().required("Quantity is required."),
        onDate: Yup.string().required("On Date is required."),
        dueDate: Yup.string().required("Due Date is required."),
        unit: Yup.string().required("Unit is required.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    function setQuantity() {
        if (selectedProductData) {
            if (formik.values.quantity < 0) {
                formik.setValues({ ...formik.values, quantity: 0 })
            }

        }
    }
    useEffect(() => {
        setQuantity()
    }, [formik.values.forStock, formik.values.quantity]);

    useEffect(() => {
        getProduction(id)
        getProductConfiguration()
    }, []);

    useEffect(() => {
        if (formik.values.toProductionUnitId)
            getProducts(formik.values.toProductionUnitId)
    }, [formik.values.toProductionUnitId])

    useEffect(() => {
        if (selectedProductData) {
            formik.setValues({ ...formik.values, productId: selectedProductData.id, unit: selectedProductData.unit, quantity: 0 })
        }
        else {
            formik.setValues({ ...formik.values, productId: "" })
        }
    }, [selectedProductData])

    useEffect(() => {
        if (selectedProductionData) {
            formik.setValues({ ...formik.values, toProductionUnitId: selectedProductionData.id })
        }
        else {
            formik.setValues({ ...formik.values, toProductionUnitId: "" })
        }
    }, [selectedProductionData])
    function getProductConfiguration() {
        setIsLoadingMini(true);
        productionConfigurationCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProductionConfiguration(hdata);
                    }
                    else {
                        setProductionConfiguration([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProductionConfiguration([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    const OnProductionChange = (e) => {
        const { value } = e.target;
        if (value) {
            production.forEach(element => {
                if (element.id == value) {
                    {
                        setSelectedProductionData(element)
                    }
                }
            });
        }
        else {
            setSelectedProductionData(undefined)
        }
    }

    const OnProductChange = (e) => {
        const { value } = e.target;
        if (value) {
            product.forEach(element => {
                if (element.id == value) {
                    {
                        setSelectedProductData(element)
                    }
                }
            });
        }
        else {
            setSelectedProductData(undefined)
        }
    }

    const Save = (value) => {
        if (selectedProductionData && selectedProductionData.productionCategory.type == 1)
            if (value.quantity > 0) {
                setIsLoadingMini(true);
                setIsLoadingLocal(true)
                orderCrud.Add(value)
                    .then(res => {
                        if (res.data.result.succeeded) {
                            setIsLoadingMini(false)
                            setIsLoadingLocal(false);
                            AppToaster('Added successfully.', 'success')
                            history.goBack()
                        }
                        else {
                            setIsLoadingMini(false)
                            setIsLoadingLocal(false);
                            AppToaster("Server Error /Invalid Try.", 'error')
                        }
                    })
                    .catch(error => {
                        setIsLoadingMini(false);
                        setIsLoadingLocal(false);
                        AppToaster(error, 'error')
                    })
            }
            else {
                AppToaster("Quantity should greater then zero.", 'error')
            }
        else {
            AppToaster("You could not create order to Non Trade production unit.", 'error')
        }
    }

    function getProduction(contactid) {
        setIsLoadingMini(true);
        productionUnitCrud.GetByContactId(contactid)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProduction(hdata);
                    }
                    else {
                        setProduction([]);
                        AppToaster(res.data.result.message, 'error')
                    }
                }
                else {
                    setProduction([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function getProducts(productionUnitId) {
        setIsLoadingMini(true);
        productCrud.GetByProductionUnitActive(productionUnitId)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProduct(hdata);
                    }
                    else {
                        setProduct([]);
                        AppToaster(res.data.result.message, 'error')
                    }
                }
                else {
                    setProduct([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    return (
        <Container title="Add Stock Order" isBack="true" isContact="true">
            <form onSubmit={formik.handleSubmit} class="form-horizontal">
                <div className="row">
                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right"> To Production Unit</label>
                                <select className="form-control" name="toProductionUnitId" value={formik.values.toProductionUnitId} onChange={OnProductionChange}>
                                    <option value="" >Select...</option>
                                    {
                                        production.map((data, index) =>
                                            <option key={data.id} value={data.id}>{data.productionUnitName.toUpperCase()} ({data.id})</option>
                                        )
                                    }
                                </select>
                                <ProductionSelectorView data={selectedProductionData} />
                                {
                                    formik.touched.toProductionUnitId &&
                                        formik.errors.toProductionUnitId ? (
                                        <label className="text-danger" >
                                            {formik.errors.toProductionUnitId}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Product</label>
                                <select className="form-control" name="productId" value={formik.values.productId} onChange={OnProductChange}>
                                    <option value="" >Select...</option>
                                    {
                                        product.map((data, index) =>
                                            <option key={data.id} value={data.id}>{data.productName.toUpperCase()} ({data.id})</option>
                                        )
                                    }
                                </select>
                                <ProductSelectorView data={selectedProductData} dataSetter={setSelectedProductData} isDataSetter={userData.isWarehouse == 1 ? true : false}/>
                                {
                                    formik.touched.productId &&
                                        formik.errors.productId ? (
                                        <label className="text-danger" >
                                            {formik.errors.productId}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right"> On Date</label>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker placeholder="onDate" name="onDate" className="form-control input-date-padding" selected={formik.values.onDate} onChange={(date) => formik.setValues({ ...formik.values, onDate: date })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                                {
                                    formik.touched.onDate &&
                                        formik.errors.onDate ? (
                                        <label className="text-danger" >
                                            {formik.errors.onDate}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right"> Due Date</label>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker placeholder="dueDate" name="dueDate" className="form-control input-date-padding" selected={formik.values.dueDate} onChange={(date) => formik.setValues({ ...formik.values, dueDate: date })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                                {
                                    formik.touched.dueDate &&
                                        formik.errors.dueDate ? (
                                        <label className="text-danger" >
                                            {formik.errors.dueDate}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    {selectedProductData &&
                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Quantity- {formik.values.unit}</label>
                                    <input className="form-control" type="number" placeholder="Quantity" name="quantity" {...formik.getFieldProps("quantity")} />
                                    {
                                        formik.touched.quantity &&
                                            formik.errors.quantity ? (
                                            <label className="text-danger" >
                                                {formik.errors.quantity}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right"> Production Configuration</label>
                                <select className="form-control" name="productionConfigurationId" value={formik.values.productionConfigurationId} onChange={(e) => formik.setValues({ ...formik.values, productionConfigurationId: e.target.value })}>
                                    <option value="" >Select...</option>
                                    {
                                        productionConfiguration.map((data, index) =>
                                            <option key={data.id} value={data.id}>{data.name.toUpperCase()}</option>
                                        )
                                    }
                                </select>
                                {
                                    formik.touched.productionConfigurationId &&
                                        formik.errors.productionConfigurationId ? (
                                        <label className="text-danger" >
                                            {formik.errors.productionConfigurationId}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right"> Details</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="eg. Invoice no, stock note etc." name="details" value={formik.values.details} onChange={(e) => formik.setValues({ ...formik.values, details: e.target.value })} />
                                {
                                    formik.touched.details &&
                                        formik.errors.details ? (
                                        <label className="text-danger" >
                                            {formik.errors.details}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div class="clearfix form-actions">
                    <div class="pull-right">
                        {isLoadingLocal ?
                            <LoadingButton />
                            :
                            <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                <i class="ace-icon fa fa-check bigger-110"></i>
                                Add
                            </button>}
                    </div>
                </div>

            </form>
        </Container >
    )
}

