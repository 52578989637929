import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Container } from '../../base/Container';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import * as productionUnitCrud from '../../_crud/productionUnitCrud'
import AppToaster from '../../commonLib/AppToaster';
import { LoadingButton } from '../../commonLib/LoadingButton';
import { GetEProductionUnitOwnership } from '../../enumCollections/EProductionUnitOwnership'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom';
import * as productionCategoryCrud from '../../_crud/productionCategoryCrud'
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

export function AddProductionUnitContact() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const history = useHistory();
    const [productionCategories, setProductionCategories] = useState([])
    const [selectedData, setSelectedData] = useState(undefined);
    const { id } = useParams();

    const initialValues = {
        productionCatId: "",
        ownerShip: 1,
        details: "",
        isMultiProduct: 0,
        productionUnitName: "",
        contactId: id
    }

    const Schema = Yup.object().shape({
        productionCatId: Yup.string().required("Production Category is required."),
        ownerShip: Yup.string().required("OwnerShip is required."),
        isMultiProduct: Yup.string().required("Is Multi Product is required."),
        productionUnitName: Yup.string().min(3, "Production Unit must be between 3 to 30 characters").max(30, "Unit Name must be between 3 to 30 characters").required("Unit Name is required."),
        contactId: Yup.string().required("Contact is required.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });
    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        productionUnitCrud.Add(value)
            .then(res => {
                if (res.data.result.succeeded) {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster('Added successfully.', 'success')
                    history.goBack()
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }
    useEffect(() => {
        getCategories()
    }, []);

    useEffect(() => {
        if (selectedData)
            formik.setValues({ ...formik.values, contactId: selectedData.id })
    }, [selectedData]);

    function getCategories() {
        setIsLoadingMini(true);
        productionCategoryCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProductionCategories(hdata);
                    }
                    else {
                        setProductionCategories([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProductionCategories([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    return (
        <Container title="Add Production Unit" isBack="true" isContact="true">
            <form onSubmit={formik.handleSubmit} class="form-horizontal">
                <div className="row">
                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right"> Production Unit</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="Production Unit Name" name="productionUnitName" value={formik.values.productionUnitName} onChange={(e) => formik.setValues({ ...formik.values, productionUnitName: e.target.value })} />
                                {
                                    formik.touched.productionUnitName &&
                                        formik.errors.productionUnitName ? (
                                        <label className="text-danger" >
                                            {formik.errors.productionUnitName}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Category</label>
                                <select className="form-control" name="productionCatId" value={formik.values.productionCatId} onChange={(e) => formik.setValues({ ...formik.values, productionCatId: e.target.value })}>
                                    <option value="" >Select...</option>
                                    {
                                        productionCategories.map((data, index) =>
                                            <option key={data.id} value={data.id}>{data.productionCatName.toUpperCase()}</option>
                                        )
                                    }
                                </select>
                                {
                                    formik.touched.productionCatId &&
                                        formik.errors.productionCatId ? (
                                        <label className="text-danger" >
                                            {formik.errors.productionCatId}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">OwnerShip</label>
                                <select className="form-control" name="ownerShip" value={formik.values.ownerShip} onChange={(e) => formik.setValues({ ...formik.values, ownerShip: e.target.value })}>
                                    {
                                        GetEProductionUnitOwnership.EProductionUnitOwnership.map((data, index) =>
                                            <option key={data.Id} value={data.Id}>{data.Name}</option>
                                        )
                                    }
                                </select>
                                {
                                    formik.touched.ownerShip &&
                                        formik.errors.ownerShip ? (
                                        <label className="text-danger" >
                                            {formik.errors.ownerShip}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Details</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="Details" name="details" value={formik.values.details} onChange={(e) => formik.setValues({ ...formik.values, details: e.target.value })} />
                                {
                                    formik.touched.details &&
                                        formik.errors.details ? (
                                        <label className="text-danger" >
                                            {formik.errors.details}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div class="form-group">
                            <label class="col-sm-3 control-label no-padding-right"> </label>
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Is Multi Product</label>
                                <div>
                                    <input onChange={() => formik.setValues({ ...formik.values, isMultiProduct: (formik.values.isMultiProduct == 1 ? 0 : 1) })} checked={formik.values.isMultiProduct == 1} type="checkbox" class="ace ace-switch ace-switch-5" />
                                    <span class="lbl middle"></span>
                                </div>
                                {
                                    formik.touched.isMultiProduct &&
                                        formik.errors.isMultiProduct ? (
                                        <label className="text-danger" >
                                            {formik.errors.isMultiProduct}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div class="clearfix form-actions">
                    <div class="pull-right">
                        {isLoadingLocal ?
                            <LoadingButton />
                            :
                            <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                <i class="ace-icon fa fa-check bigger-110"></i>
                                Add
                            </button>}
                    </div>
                </div>
            </form>
        </Container >
    )
}
