import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import AppToaster from '../../commonLib/AppToaster';
import * as Yup from 'yup';
import { loadingContext } from '../../commonLib/_lodingContext';
import * as orderCrud from '../../_crud/orderCrud';
import { Container } from '../../base/Container';
import { LoadingButton } from '../../commonLib/LoadingButton';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import GetCurrentDateForSearch from '../../commonLib/dateFormat';
import DatePicker from "react-datepicker";
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

const initialValues = {
    id: undefined,
    quantity: undefined,
    dueDate: new Date(GetCurrentDateForSearch(0)),
    details: undefined
}

export function EditOrder() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const { id } = useParams();

    const Schema = Yup.object().shape({
        quantity: Yup.string().required("Quantity is required."),
        dueDate: Yup.string().required("Due Date is required."),
        details: Yup.string().required("Details is required.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true);
        orderCrud.Edit(value)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false);
                    setIsLoadingLocal(false);
                    AppToaster('Updated successfully.', 'success')
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    useEffect(() => {
        getOrder()
    }, []);

    function getOrder() {
        setIsLoadingMini(true);
        setIsLoadingLocal(true);
        setIsLoading(true);
        orderCrud.GetById(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        formik.setValues({
                            id: hdata.id,
                            quantity: hdata.quantity,
                            dueDate: new Date(hdata.dueDate),
                            details: hdata.details,
                        })
                    }
                    else {
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                }
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                setIsLoading(false);
                AppToaster(error, 'error')
            })
    }

    return (
        <Container title="Edit Order" isBack="true" >
            <form onSubmit={formik.handleSubmit} class="form-horizontal">
                <div className="row">
                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right"> Quantity</label>
                                <input className="form-control" type="number" placeholder="Quantity" name="quantity" {...formik.getFieldProps("quantity")} />
                                {
                                    formik.touched.quantity &&
                                        formik.errors.quantity ? (
                                        <label className="text-danger" >
                                            {formik.errors.quantity}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right"> Due Date</label>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker placeholder="Due Date" name="dueDate" className="form-control input-date-padding" selected={formik.values.dueDate} onChange={(date) => formik.setValues({ ...formik.values, dueDate: date })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                                {
                                    formik.touched.dueDate &&
                                        formik.errors.dueDate ? (
                                        <label className="text-danger" >
                                            {formik.errors.dueDate}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>                                                                                                                                                                           
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right"> Details</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="Details" name="details" value={formik.values.details} onChange={(e) => formik.setValues({ ...formik.values, details: e.target.value })} />
                                {
                                    formik.touched.details &&
                                        formik.errors.details ? (
                                        <label className="text-danger" >
                                            {formik.errors.details}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div class="clearfix form-actions">
                    <div class="pull-right">
                        {isLoadingLocal ?
                            <LoadingButton />
                            :
                            <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                Update
                            </button>}
                    </div>
                </div>
            </form>
        </Container >
    )
}
