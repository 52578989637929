import React, { useContext, useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom'
import { ADMINISTRATOR_OPERATION, ADMINISTRATOR, OPERATION } from './auth/RouteRoles';
import { ProtectedRoute } from './../components/auth/ProtectedRoute';
import Home from './pages/Home';
import { AddContact } from './pages/Contacts/AddContact';
import ManageContact from './pages/Contacts/ManageContact';
import { EditContact } from './pages/Contacts/EditContact';
import { ManageProcessing } from './pages/Processing/ManageProcessing';
import { AddProcessing } from './pages/Processing/AddProcessing';
import { ManageGatePass } from './pages/GatePass/ManageGatePass';
import { AddGatePass } from './pages/GatePass/AddGatePass';
import { ManageProduct } from './pages/Products/ManageProduct';
import { AddProduct } from './pages/Products/AddProduct';
import { EditProduct } from './pages/Products/EditProduct';
import { AddOrder } from './pages/Order/AddOrder';
import { ManageOrder } from './pages/Order/ManageOrder';
import { EditOrder } from './pages/Order/EditOrder';
import { ManageCategories } from './pages/Products/ManageCategories'
import { AddProductCategory } from './pages/Products/AddProductCategory';
import { ProductDashboard } from './pages/Products/ProductDashboard';
import { ManageProcessingDistribution } from './pages/Processing/ProcessingDistribution/ManageProcessingDistribution';
import { AddProcessingDistribution } from './pages/Processing/ProcessingDistribution/AddProcessingDistribution';
import { ManageConsumption } from './pages/Products/ManageConsumption';
import { ManageConfiguration } from './pages/Products/ManageConfiguration';
import { AddConfiguration } from './pages/Products/AddConfiguration';
import { AddProductConsumption } from './pages/Products/AddProductConsumption';
import { ManageProductDistribution } from './pages/Products/ProductDistribution/ManageProductDistribution';
import { AddProductDistribution } from './pages/Products/ProductDistribution/AddProductDistribution';
import { ManageLedger } from './pages/Ledger/ManageLedger';
import { AddProductionUnit } from './pages/ProductionUnit/AddProductionUnit';
import { ManageProductionUnit } from './pages/ProductionUnit/ManageProductionUnit';
import { EditProductionUnit } from './pages/ProductionUnit/EditProductionUnit';
import { AddCashBankCashVoucher } from './pages/Vouchers/CashBankCahVoucher/AddCashBankCashVoucher';
import { ManageCashBankCashVoucher } from './pages/Vouchers/CashBankCahVoucher/ManageCashBankCashVoucher';
import { AddVoucherPayment } from './pages/Vouchers/CashBankCahVoucher/AddVoucherPayment';
import ManageVoucherPayment from './pages/Vouchers/CashBankCahVoucher/ManageVoucherPayment';
import { ProductionUnitDashboard } from './pages/ProductionUnit/ProductionUnitDashboard';
import { CashBankCashVoucherDashboard } from './pages/Vouchers/CashBankCahVoucher/CashBankCashVoucherDashBoard';
import { AddSalaryVoucher } from './pages/Vouchers/SalaryVoucher/AddSalaryVoucher';
import { ManageSalaryVoucher } from './pages/Vouchers/SalaryVoucher/ManageSalaryVoucher';
import { SalaryVoucherDashBoard, ViewSalaryVoucher } from './pages/Vouchers/SalaryVoucher/SalaryVoucherDashBoard';
import { AddSalaryVoucherPayment } from './pages/Vouchers/SalaryVoucher/AddSalaryVoucherPayment';
import { ManageSalaryVoucherPayment } from './pages/Vouchers/SalaryVoucher/ManageSalaryVoucherPayment';
import { AddOpeningBalanceVoucher } from './pages/Vouchers/OpeningBalanceVoucher/AddOpeningBalanceVoucher';
import { ManageOpeningBalanceVoucher } from './pages/Vouchers/OpeningBalanceVoucher/ManageOpeningBalanceVoucher';
import { AddAdvanceVoucher } from './pages/Vouchers/AdvanceVoucher/AddAdvanceVoucher';
import { ManageAdvanceVoucher } from './pages/Vouchers/AdvanceVoucher/ManageAdvanceVoucher';
import { AddAdvanceVoucherPayment } from './pages/Vouchers/AdvanceVoucher/AddAdvanceVoucherPayment';
import { AdvanceVoucherDashBoard } from './pages/Vouchers/AdvanceVoucher/AdvanceVoucherDashBoard';
import { ManageAdvanceVoucherPayment } from './pages/Vouchers/AdvanceVoucher/ManageAdvanceVoucherPayment';
import { ManageProductAllotment } from './pages/ProductionUnit/ManageProductAllotment';
import { AddProductAllotment } from './pages/ProductionUnit/AddProductAllotment';
import { ManageCategory } from './pages/ProductionUnit/ManageCategory';
import { AddCategory } from './pages/ProductionUnit/AddCategory';
import { ManageProcessingAllotment } from './pages/ProcessingAllotment/ManageProcessingAllotment';
import { AddProcessingAllotment } from './pages/ProcessingAllotment/AddProcessingAllotment';
import { EditProcessingAllotment } from './pages/ProcessingAllotment/EditProcessingAllotment';
import { ContactDashboard } from './pages/Contacts/ContactDashboard';
import { EditCategory } from './pages/ProductionUnit/EditCategory';
import { ChangeContacts } from './pages/ProductionUnit/ChangeContacts';
import { AddProductionUnitAllotment } from './pages/Contacts/AddProductionUnitAllotment';
import { AddProcessingReceiving } from './pages/Processing/ProcessingReceiving/AddProcessingReceiving';
import { ManageProcessingReceiving } from './pages/Processing/ProcessingReceiving/ManageProcessingReceiving';
import { AddProductReceiving } from './pages/Products/ProductRecieving/AddProductRecieving';
import { ManageProductReceiving } from './pages/Products/ProductRecieving/ManageProductReceiving';
import { ProcessingDashBoard } from './pages/Processing/ProcessingDashBoard';
import { EditProcessing } from './pages/Processing/EditProcessing';
import { ManageProductWage } from './pages/Products/ManageProductWage';
import { AddProductWage } from './pages/Products/AddProductWage';
import { AddProcessingWage } from './pages/Processing/AddProcessingWage';
import { ManageProcessingWage } from './pages/Processing/ManageProcessingWage';
import { OrderDashboard } from './pages/Order/OrderDashBoard';
import { ManageProductionUnitContact } from './pages/Contacts/ManageProductionUnitContact';
import { AddProductionUnitContact } from './pages/Contacts/AddProductionUnitContact';
import { AddProductionUnitOrderContact } from './pages/Contacts/AddProductionUnitOrderContact';
import { ManageProductionUnitOrderContact } from './pages/Contacts/ManageProductionUnitOrderContact';
import { ManageContactOrders } from './pages/Contacts/ManageContactOrders';
import { ManageProductDistributionContact } from './pages/Contacts/ManageProductDistributionContact';
import { AddProductDistributionContact } from './pages/Contacts/AddProductDistributionContact';
import { AddContactOrders } from './pages/Contacts/AddContactOrders';
import { ManageProductReceivingContact } from './pages/Contacts/ManageProductReceivingContact';
import { AddProductReceivingContact } from './pages/Contacts/AddProductReceivingContact';
import { ManageProcessingReceivingContact } from './pages/Contacts/ManageProcessingReceivingContact';
import { ManageProcessingDistributionContact } from './pages/Contacts/ManageProcessingDistributionContact';
import { AddProcessingReceivingContact } from './pages/Contacts/AddProcessingReceivingContact';
import { AddProcessingDistributionContact } from './pages/Contacts/AddProcessingDistributionContact';
import { ResetPassword } from './pages/Contacts/ResetPassword';
import ChangePassword from './pages/Settings/ChangePassword';
import { ManageUsers } from './pages/Settings/ManageUsers';
import { AddUsers } from './pages/Settings/AddUsers';
import { ContactPhoto } from './pages/Contacts/ContactPhoto';
import { ManageProductReturn } from './pages/Products/ProductReturn/ManageProductReturn';
import { ManageProcessingReturn } from './pages/Processing/ProcessingReturn/ManageProcessingReturn';
import { AddProductReturn } from './pages/Products/ProductReturn/AddProductReturn';
import { AddProcessingReturn } from './pages/Processing/ProcessingReturn/AddProcessingReturn';
import { ManageProductionUnitProductReturnContact } from './pages/Contacts/ManageProductionUnitProductReturnContact';
import { AddProductionUnitProductReturnContact } from './pages/Contacts/AddProductionUnitProductReturnContact';
import { ManageProcessingReturnContact } from './pages/Contacts/ManageProcessingReturnContact';
import { AddProcessingReturnContact } from './pages/Contacts/AddProcessingReturnContact';
import { OrganizationDashboard } from './pages/Organization/OrganizationDashboard';
import { GatePassDashboard } from './pages/GatePass/GatePassDashboard';
import { ProductPhoto } from './pages/Products/ProductPhoto';
import { UploadDP } from './pages/Settings/UploadDP';
import { ContactDocument } from './pages/Contacts/ContactDocument';
import { AddExpenseLedger } from './pages/Ledger/AddExpenseLedger';
import { Error404 } from './pages/others/404';
import { ManageHistory } from './pages/History/ManageHistory';
import { RemoveRangeHistory } from './pages/History/RemoveRangeHistory';
import { ManageExpenseVoucher } from './pages/Vouchers/ExpenseVoucher/ManageExpenseVoucher';
import { AddExpenseVoucher } from './pages/Vouchers/ExpenseVoucher/AddExpenseVoucher';
import { ManageContactAdvanceVoucher } from './pages/Contacts/Voucher/AdvanceVoucher/ManageContactAdvanceVoucher';
import { AddContactAdvanceVoucher } from './pages/Contacts/Voucher/AdvanceVoucher/AddContactAdvanceVoucher';
import { ManageContactExpenseVoucher } from './pages/Contacts/Voucher/ExpenseVoucher/ManageContactExpenseVoucher';
import { AddContactExpenseVoucher } from './pages/Contacts/Voucher/ExpenseVoucher/AddContactExpenseVoucher';
import { ManageContactSalaryVoucher } from './pages/Contacts/Voucher/SalaryVoucher/ManageContactSalaryVoucher';
import { AddContactSalaryVoucher } from './pages/Contacts/Voucher/SalaryVoucher/AddContactSalaryVoucher';
import { ManageProcessingVoucher } from './pages/Vouchers/ProcessingVoucher/ManageProcessingVoucher';
import { AddProcessingVoucher } from './pages/Vouchers/ProcessingVoucher/AddProcessingVoucher';
import { ProcessingVoucherDashBoard } from './pages/Vouchers/ProcessingVoucher/ProcessingVoucherDashBoard';
import { ManageProductionVoucher } from './pages/Vouchers/ProductionVoucher/ManageProductionVoucher';
import { AddProductionVoucher } from './pages/Vouchers/ProductionVoucher/AddProductionVoucher';
import { ProductionVoucherDashboard } from './pages/Vouchers/ProductionVoucher/ProductionVoucherDashboard';
import { AddLocStates } from './pages/Settings/locState/AddLocStates';
import { ManageLocState } from './pages/Settings/locState/ManageLocState';
import { ManageContactProcessingVoucher } from './pages/Contacts/Voucher/ProcessingVoucher/ManageContactProcessingVoucher';
import { AddContactProcessingVoucher } from './pages/Contacts/Voucher/ProcessingVoucher/AddContactProcessingVoucher';
import { ManageContactProductionVoucher } from './pages/Contacts/Voucher/ProductionVoucher/ManageContactProductionVoucher';
import { AddContactProductionVoucher } from './pages/Contacts/Voucher/ProductionVoucher/AddContactProductionVoucher';
import { ProductDistributionDashBoard } from './pages/Products/ProductDistribution/ProductDistributionDashBoard';
import { ProductReceivingDashBoard } from './pages/Products/ProductRecieving/ProductReceivingDashBoard';
import { ProductReturnDashBoard } from './pages/Products/ProductReturn/ProductReturnDashBoard';
import { ProcessingReturnDashBoard } from './pages/Processing/ProcessingReturn/ProcessingReturnDashBoard';
import { ProcessingReceivingDashBoard } from './pages/Processing/ProcessingReceiving/ProcessingReceivingDashBoard';
import { ProcessingDistributionDashBoard } from './pages/Processing/ProcessingDistribution/ProcessingDistributionDashBoard';
import { ManageContactProductAllotment } from './pages/Contacts/ProductAllotment/ManageContactProductAllotment';
import { AddContactProductAllotment } from './pages/Contacts/ProductAllotment/AddContactProductAllotment';
import { AddCategoryWage } from './pages/Products/AddCategoryWage';
import { ManageProductContactStock } from './pages/Contacts/Stocks/ManageProductContactStock';
import { ManageProcessingContacStock } from './pages/Contacts/Stocks/ManageProcessingContacStock';
import { ManageLots } from './pages/Contacts/Stocks/ManageLots';
import { ManageFiscalYearClosing } from './pages/Settings/FiscalYear/ManageFiscalYearClosing';
import { AddFiscalYearClosing } from './pages/Settings/FiscalYear/AddFiscalYearClosing';
import { ManageProcessingContactStocks } from './pages/Stocks/ManageProcessingContactStocks';
import { ManageProductStocks } from './pages/Stocks/ManageProductStocks';
import { ManageProductReceivingLot } from './pages/Stocks/ManageProductReceivingLot';
import { LedgerSummary } from './pages/Global/Reports/LedgerSummary';
import { CashBankFlow } from './pages/Global/Reports/CashBankFlow';
import { ManageLoginLogs } from './pages/History/ManageLoginLogs';
import { LoginLogsByUser } from './pages/History/LoginLogsByUser';
import { EditProductCategory } from './pages/Products/EditProductCategory';
import { ManageRechargeHistory } from './pages/History/ManageRechargeHistory';
import { AddBank } from './pages/Cheque/Bank/AddBank';
import { ManageBank } from './pages/Cheque/Bank/ManageBank';
import { ManageTransaction } from './pages/Cheque/Transaction/ManageTransaction';
import { AddTransaction } from './pages/Cheque/Transaction/AddTransaction';
import { EditBank } from './pages/Cheque/Bank/EditBank';
import { BankReports } from './pages/Cheque/Transaction/BankReports';
import { TransactionDashboard } from './pages/Cheque/Transaction/TransactionDashboard';
import { AddProductionConfiguration } from './pages/ProductionUnit/Configuration/AddProductionConfiguration';
import { ManageProductionConfiguration } from './pages/ProductionUnit/Configuration/ManageProductionConfiguration';
import { EditProductionConfiguration } from './pages/ProductionUnit/Configuration/EditProductionConfiguration';
import { ProductionConfigurationDashboard } from './pages/ProductionUnit/Configuration/ProductionConfigurationDashboard';
import { ManageInitOrderForProduction } from './pages/Order/ManageInitOrderForProduction';
import { ManageInitOrderForProductionContact } from './pages/Contacts/ManageInitOrderForProductionContact';
import { AddInitOrderForProductionContact } from './pages/Contacts/AddInitOrderForProductionContact';
import { ManageDefectManagement } from './pages/DefectManagement/ManageDefectManagement';
import { AddDefectManagementContact } from './pages/Contacts/AddDefectManagementContact';
import { ManageDefectManagementContact } from './pages/Contacts/ManageDefectManagementContact';
import { ManageBankTransferReport } from './pages/BankTransfer/ManageBankTransferReport';
import { OrderPrintProcessing } from './pages/Order/OrderPrintProcessing';
import { AdvanceContactList } from './pages/Global/Reports/AdvanceContactList';
import { ProductionUnitDocument } from './pages/ProductionUnit/ProductionUnitDocument';
import { DefectsContactList } from './pages/Global/Reports/DefectsContactList';
import GetLoginInfo from './auth/_userContext/GetLoginInfo';
import { OperationHome } from './pages/OperationHome';
import { ManageWarehouse } from './pages/Warehouse/ManageWarehouse';
import { AddWarehouse } from './pages/Warehouse/AddWarehouse';
import { EditWarehouse } from './pages/Warehouse/EditWarehouse';
import { WarehouseDashboard } from './pages/Warehouse/WarehouseDashboard';
import { ManageWarehouseTransfer } from './pages/Warehouse/WarehouseTransfers/ManageWarehouseTransfer';
import { AddWarehouseTransfer } from './pages/Warehouse/WarehouseTransfers/AddWarehouseTransfer';
import { AllWarehouseTransfer } from './pages/Warehouse/WarehouseTransfers/AllWarehouseTransfer';
import { WarehouseTransferDashboard } from './pages/Warehouse/WarehouseTransfers/WarehouseTransferDashboard';
import { LastActiveContacts } from './pages/Global/Reports/LastActiveContacts';
import { DonloadMediaFiles } from './pages/Organization/DonloadMediaFiles';
import { RechargePlansDashboard } from './pages/RechargePlans/RechargePlansDashboard';
import { ManageProductDesign } from './pages/Products/ProductDesign/ManageProductDesign';
import { AddProductDesign } from './pages/Products/ProductDesign/AddProductDesign';
import { EditProductDesign } from './pages/Products/ProductDesign/EditProductDesign';
import { ProductDesignDashboard } from './pages/Products/ProductDesign/ProductDesignDashboard';
import { AddProductDesignItem } from './pages/Products/ProductDesign/AddProductDesignItem';
import { ManageProductDesignDesignerContact } from './pages/Contacts/ManageProductDesignDesignerContact';
import { ManageGlobalProductDesign } from './pages/Products/ProductDesign/ManageGlobalProductDesign';
import { ActiveProductReport } from './pages/Global/Reports/ActiveProductReport';
import { AllActiveProducts } from './pages/Global/Reports/AllActiveProducts';
import { ProductDailyReport } from './pages/Global/Reports/ProductDailyReport';
import { ProcessingDailyReport } from './pages/Global/Reports/ProcessingDailyReport';
import { ManageStockOrders } from './pages/Order/ManageStockOrders';
import { SareeDrapingCatalogue } from './pages/SareeDraper/SareeDrapingCatalogue';
import { ManageSareeDraping } from './pages/SareeDraper/ManageSareeDraping';
import { SareeDrapingDashboard } from './pages/SareeDraper/SareeDrapingDashboard';
import { SareeDrapingEdit } from './pages/SareeDraper/SareeDrapingEdit';
import { CatalogueEdit } from './pages/SareeDraper/CatalogueEdit';
import { CatalogueDashboard } from './pages/SareeDraper/CatalogueDashboard';
import { ProductPriceCalculator } from './pages/Products/ProductPriceCalculator';
import { ManageProductProcessingAllotment } from './pages/Products/ProductProcessingAllotment/ManageProductProcessingAllotment';
import { AddProductProcessingAllotment } from './pages/Products/ProductProcessingAllotment/AddProductProcessingAllotment';
import { Drapingo } from './pages/SareeDraper/Drapingo';

export function RouteContainer() {
    const location = useLocation();
    const userData = GetLoginInfo()

    return (
        <>
            <Redirect to={location.pathname == "/login" || location.pathname == "/" ? "/home" : location.pathname} />
            {userData && userData.role == ADMINISTRATOR ?
                <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/home" component={Home} />
                :
                <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/home" component={OperationHome} />
            }

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact" exact component={ManageContact} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/add" component={AddContact} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/edit/:id" component={EditContact} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id" exact component={ContactDashboard} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:contactId/processingallotment" exact component={ManageProcessingAllotment} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:contactId/processingallotment/add" component={AddProcessingAllotment} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:contactId/processingallotment/edit/:id" component={EditProcessingAllotment} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/productionunitallotment/add" component={AddProductionUnitAllotment} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/productionunit" exact component={ManageProductionUnitContact} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/productionunit/add" component={AddProductionUnitContact} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/productionunit/lots/:productionUnitId" exact component={ManageLots} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/productionunit/orders/:productionUnitId" exact component={ManageProductionUnitOrderContact} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/productionunit/orders/:productionUnitId/add" component={AddProductionUnitOrderContact} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/productionunit/orders/:productionUnitId/initorderforproduction" exact component={ManageInitOrderForProductionContact} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/productionunit/orders/:productionUnitId/initorderforproduction/add" component={AddInitOrderForProductionContact} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/orders" exact component={ManageContactOrders} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/orders/add" component={AddContactOrders} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/processingreceiving" exact component={ManageProcessingReceivingContact} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/processingreceiving/add" component={AddProcessingReceivingContact} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/processingreturn" exact component={ManageProcessingReturnContact} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/processingreturn/add" component={AddProcessingReturnContact} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/processingdistribution" exact component={ManageProcessingDistributionContact} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/processingdistribution/add" component={AddProcessingDistributionContact} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/resetpassword" exact component={ResetPassword} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/photo" exact component={ContactPhoto} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/docs" exact component={ContactDocument} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/defectmanagement" exact component={ManageDefectManagementContact} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/defectmanagement/add" component={AddDefectManagementContact} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/productallotment" exact component={ManageContactProductAllotment} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/productallotment/add" component={AddContactProductAllotment} />


            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/productionunit/productdistribution/:productionUnitId" exact component={ManageProductDistributionContact} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/productionunit/productdistribution/:productionUnitId/add" component={AddProductDistributionContact} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/productionunit/productreceiving/:productionUnitId" exact component={ManageProductReceivingContact} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/productionunit/productreceiving/:productionUnitId/add" component={AddProductReceivingContact} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/productionunit/productreturn/:productionUnitId" exact component={ManageProductionUnitProductReturnContact} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/productionunit/productreturn/:productionUnitId/add" component={AddProductionUnitProductReturnContact} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/advancevoucher" exact component={ManageContactAdvanceVoucher} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/advancevoucher/add" component={AddContactAdvanceVoucher} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/expensevoucher" exact component={ManageContactExpenseVoucher} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/expensevoucher/add" component={AddContactExpenseVoucher} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/salaryvoucher" exact component={ManageContactSalaryVoucher} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/salaryvoucher/add" component={AddContactSalaryVoucher} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/processingvoucher" exact component={ManageContactProcessingVoucher} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/processingvoucher/add" component={AddContactProcessingVoucher} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/productionvoucher" exact component={ManageContactProductionVoucher} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/productionvoucher/add" component={AddContactProductionVoucher} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/productstock" component={ManageProductContactStock} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/processingstock" component={ManageProcessingContacStock} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/contact/dashboard/:id/productdesign" component={ManageProductDesignDesignerContact} />


            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/product" exact component={ManageProduct} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/product/add" component={AddProduct} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/product/edit/:id" component={EditProduct} />
            <ProtectedRoute role={ADMINISTRATOR} path="/product/categories" exact component={ManageCategories} />
            <ProtectedRoute role={ADMINISTRATOR} path="/product/categories/add" component={AddProductCategory} />
            <ProtectedRoute role={ADMINISTRATOR} path="/product/categories/edit/:id" component={EditProductCategory} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/product/dashboard/:id" exact component={ProductDashboard} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/product/dashboard/:id/consumption" exact component={ManageConsumption} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/product/dashboard/:id/consumption/add" component={AddProductConsumption} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/product/dashboard/:id/processingallotment" exact component={ManageProductProcessingAllotment} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/product/dashboard/:id/processingallotment/add" component={AddProductProcessingAllotment} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/product/dashboard/:id/configuration" exact component={ManageConfiguration} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/product/dashboard/:id/configuration/add" component={AddConfiguration} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/product/dashboard/:id/wage" exact component={ManageProductWage} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/product/dashboard/:id/wage/add" component={AddProductWage} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/product/dashboard/:id/photo" exact component={ProductPhoto} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/product/wage/:id/:cn" exact component={AddCategoryWage} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/product/design/:id" exact component={ManageProductDesign} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/product/design/:id/add" exact component={AddProductDesign} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/product/design/:id/:did/edit" exact component={EditProductDesign} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/product/design/:id/dashboard/:did" exact component={ProductDesignDashboard} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/product/design/:id/dashboard/:did/AddItem" exact component={AddProductDesignItem} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/report/productdesign" exact component={ManageGlobalProductDesign} />
            <ProtectedRoute role={ADMINISTRATOR} path="/report/activeproducts" exact component={ActiveProductReport} />
            <ProtectedRoute role={ADMINISTRATOR} path="/report/activeproduct" exact component={AllActiveProducts} />
            <ProtectedRoute role={ADMINISTRATOR} path="/report/productdailyReoprt" exact component={ProductDailyReport} />
            <ProtectedRoute role={ADMINISTRATOR} path="/report/processingdailyReoprt" exact component={ProcessingDailyReport} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/productdistribution" component={ManageProductDistribution} exact />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/productdistribution/add" component={AddProductDistribution} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/productdistribution/dashboard/:id" exact component={ProductDistributionDashBoard} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/productreceiving/add" component={AddProductReceiving} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/productreceiving" component={ManageProductReceiving} exact />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/productreceiving/dashboard/:id" exact component={ProductReceivingDashBoard} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/productreturn" component={ManageProductReturn} exact />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/productreturn/add" component={AddProductReturn} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/productreturn/dashboard/:id" exact component={ProductReturnDashBoard} />

            <ProtectedRoute role={ADMINISTRATOR} path="/processing" exact component={ManageProcessing} />
            <ProtectedRoute role={ADMINISTRATOR} path="/processing/add" component={AddProcessing} />
            <ProtectedRoute role={ADMINISTRATOR} path="/processing/dashboard/:id" exact component={ProcessingDashBoard} />
            <ProtectedRoute role={ADMINISTRATOR} path="/processing/edit/:id" component={EditProcessing} />
            <ProtectedRoute role={ADMINISTRATOR} path="/processing/dashboard/:id/wage/add" component={AddProcessingWage} />
            <ProtectedRoute role={ADMINISTRATOR} path="/processing/dashboard/:id/wage" exact component={ManageProcessingWage} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/processingdistribution" component={ManageProcessingDistribution} exact />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/processingdistribution/add" component={AddProcessingDistribution} exact />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/processingdistribution/dashboard/:id" exact component={ProcessingDistributionDashBoard} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/processingreceiving/add" component={AddProcessingReceiving} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/processingreceiving" component={ManageProcessingReceiving} exact />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/processingreceiving/dashboard/:id" exact component={ProcessingReceivingDashBoard} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/processingreturn" component={ManageProcessingReturn} exact />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/processingreturn/add" component={AddProcessingReturn} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/processingreturn/dashboard/:id" exact component={ProcessingReturnDashBoard} />


            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/gatepass" exact component={ManageGatePass} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/gatepass/add" component={AddGatePass} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/gatepass/dashboard/:id" exact component={GatePassDashboard} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/order" exact component={ManageOrder} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/stockorder" exact component={ManageStockOrders} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/order/add" component={AddOrder} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/order/edit/:id" component={EditOrder} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/order/dashboard/:id" exact component={OrderDashboard} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/initorderforproduction" exact component={ManageInitOrderForProduction} />


            <ProtectedRoute role={ADMINISTRATOR} path="/ledger" exact component={ManageLedger} />
            <ProtectedRoute role={ADMINISTRATOR} path="/ledger/add" component={AddExpenseLedger} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/productionunit" exact component={ManageProductionUnit} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/productionunit/add" component={AddProductionUnit} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/productionunit/edit/:id" component={EditProductionUnit} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/productionunit/dashboard/:id/changecontact" component={ChangeContacts} />
            <ProtectedRoute role={ADMINISTRATOR} path="/productionunit/category" exact component={ManageCategory} />
            <ProtectedRoute role={ADMINISTRATOR} path="/productionunit/category/add" component={AddCategory} />
            <ProtectedRoute role={ADMINISTRATOR} path="/productionunit/category/edit/:id" component={EditCategory} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/productionunit/dashboard/:id" exact component={ProductionUnitDashboard} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/productionunit/dashboard/:id/document" exact component={ProductionUnitDocument} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/productionunit/dashboard/:id/productallotment" exact component={ManageProductAllotment} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/productionunit/dashboard/:id/productallotment/add" component={AddProductAllotment} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/productionunit/configuration/add" component={AddProductionConfiguration} />
            <ProtectedRoute role={ADMINISTRATOR} path="/productionunit/configuration" exact component={ManageProductionConfiguration} />
            <ProtectedRoute role={ADMINISTRATOR} path="/productionunit/configuration/edit/:id" component={EditProductionConfiguration} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/productionunit/configuration/dashboard/:id" exact component={ProductionConfigurationDashboard} />


            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/cashbankcashvoucher" exact component={ManageCashBankCashVoucher} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/cashbankcashvoucher/add" component={AddCashBankCashVoucher} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/advancevoucher" exact component={ManageAdvanceVoucher} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/advancevoucher/add" component={AddAdvanceVoucher} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/advancevoucher/dashboard/:id" exact component={AdvanceVoucherDashBoard} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/advancevoucher/dashboard/:id/voucherPayment/add" component={AddAdvanceVoucherPayment} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/advancevoucher/dashboard/:id/voucherPayment" exact component={ManageAdvanceVoucherPayment} />


            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/cashbankcashvoucher/dashboard/:id/voucherpayment" exact component={ManageVoucherPayment} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/cashbankcashvoucher/dashboard/:id/voucherpayment/add" component={AddVoucherPayment} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/cashbankcashvoucher/dashboard/:id" exact component={CashBankCashVoucherDashboard} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/salaryvoucher" exact component={ManageSalaryVoucher} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/salaryvoucher/add" component={AddSalaryVoucher} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/salaryvoucher/dashboard/:id" exact component={SalaryVoucherDashBoard} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/salaryvoucher/dashboard/:id/salaryvoucherpayment/add" component={AddSalaryVoucherPayment} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/salaryvoucher/dashboard/:id/salaryvoucherpayment" exact component={ManageSalaryVoucherPayment} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/expensevoucher" exact component={ManageExpenseVoucher} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/expensevoucher/add" component={AddExpenseVoucher} />


            <ProtectedRoute role={ADMINISTRATOR} path="/openingbalancevoucher/add" component={AddOpeningBalanceVoucher} />
            <ProtectedRoute role={ADMINISTRATOR} path="/openingbalancevoucher" exact component={ManageOpeningBalanceVoucher} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/processingvoucher" exact component={ManageProcessingVoucher} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/processingvoucher/add" component={AddProcessingVoucher} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/processingvoucher/dashboard/:id" exact component={ProcessingVoucherDashBoard} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/productionvoucher" exact component={ManageProductionVoucher} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/productionvoucher/add" component={AddProductionVoucher} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/productionvoucher/dashboard/:id" exact component={ProductionVoucherDashboard} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/changepassword" exact component={ChangePassword} />
            <ProtectedRoute role={ADMINISTRATOR} path="/users" exact component={ManageUsers} />
            <ProtectedRoute role={ADMINISTRATOR} path="/users/add" component={AddUsers} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/profile/dp" component={UploadDP} />
            <ProtectedRoute role={ADMINISTRATOR} path="/organization/profile" component={OrganizationDashboard} />

            <ProtectedRoute role={ADMINISTRATOR} path="/history" exact component={ManageHistory} />
            <ProtectedRoute role={ADMINISTRATOR} path="/removehistory" exact component={RemoveRangeHistory} />

            <ProtectedRoute role={ADMINISTRATOR} path="/location" exact component={ManageLocState} />
            <ProtectedRoute role={ADMINISTRATOR} path="/location/add" component={AddLocStates} />

            <ProtectedRoute role={ADMINISTRATOR} path="/fiscalyearclosing" exact component={ManageFiscalYearClosing} />
            <ProtectedRoute role={ADMINISTRATOR} path="/fiscalyearclosing/add" component={AddFiscalYearClosing} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/processingcontactstock" exact component={ManageProcessingContactStocks} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/productstock" exact component={ManageProductStocks} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/productreceivinglot" exact component={ManageProductReceivingLot} />

            <ProtectedRoute role={ADMINISTRATOR} path="/ledgersummary" exact component={LedgerSummary} />
            <ProtectedRoute role={ADMINISTRATOR} path="/cashflow" exact component={CashBankFlow} />
            <ProtectedRoute role={ADMINISTRATOR} path="/loginlogs" exact component={ManageLoginLogs} />
            <ProtectedRoute role={ADMINISTRATOR} path="/loginlogs/user/:id" exact component={LoginLogsByUser} />
            <ProtectedRoute role={ADMINISTRATOR} path="/rechargehistory" exact component={ManageRechargeHistory} />

            <ProtectedRoute role={ADMINISTRATOR} path="/bank/add" component={AddBank} />
            <ProtectedRoute role={ADMINISTRATOR} path="/bank/edit/:id" component={EditBank} />
            <ProtectedRoute role={ADMINISTRATOR} path="/bank" exact component={ManageBank} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/transaction" exact component={ManageTransaction} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/transaction/add" component={AddTransaction} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/transaction/dashboard/:id" component={TransactionDashboard} />
            <ProtectedRoute role={ADMINISTRATOR} path="/bankreports" component={BankReports} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/defectmanagement" exact component={ManageDefectManagement} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/banktransfer" exact component={ManageBankTransferReport} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/orderpeocessingprint" exact component={OrderPrintProcessing} />

            <ProtectedRoute role={ADMINISTRATOR} path="/advancecontactlist" exact component={AdvanceContactList} />
            <ProtectedRoute role={ADMINISTRATOR} path="/defectscontactlist" exact component={DefectsContactList} />

            <ProtectedRoute role={ADMINISTRATOR} path="/warehouse" exact component={ManageWarehouse} />
            <ProtectedRoute role={ADMINISTRATOR} path="/warehouse/add" component={AddWarehouse} />
            <ProtectedRoute role={ADMINISTRATOR} path="/warehouse/edit/:id" component={EditWarehouse} />
            {/* <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/warehouse/dashboard/:id" component={WarehouseDashboard} /> */}

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/warehousetransfer" exact component={ManageWarehouseTransfer} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/warehousetransfer/add" component={AddWarehouseTransfer} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/warehousetransfer/dashboard/:id" exact component={WarehouseTransferDashboard} />
            <ProtectedRoute role={ADMINISTRATOR} path="/allwarehousetransfer" exact component={AllWarehouseTransfer} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/lastactivecontacts" exact component={LastActiveContacts} />

            <ProtectedRoute role={ADMINISTRATOR} path="/downloadbackup" exact component={DonloadMediaFiles} />

            <ProtectedRoute role={ADMINISTRATOR} path="/rechargeplans" exact component={RechargePlansDashboard} />

            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/draping/new" exact component={Drapingo} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/draping" exact component={ManageSareeDraping} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/draping/edit/:id" exact component={SareeDrapingEdit} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/draping/dashboard/:id" exact component={SareeDrapingDashboard} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/catalogue" exact component={SareeDrapingCatalogue} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/catalogue/edit/:id" exact component={CatalogueEdit} />
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/catalogue/dashboard/:id" exact component={CatalogueDashboard} />
            
            <ProtectedRoute role={ADMINISTRATOR_OPERATION} path="/product/calculator" exact component={ProductPriceCalculator} />

            <Route path="/loading">
                <></>
            </Route>


            {/* <Route path="/404">
                <Error404 />
            </Route>
            <Redirect from='*' to='/404' /> */}

        </>
    );
}