import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Container } from './../../../base/Container';
import AppToaster from './../../../commonLib/AppToaster';
import { loadingMiniContext } from './../../../commonLib/lodingMiniContext';
import { Spinner } from './../../../commonLib/Spinner';
import * as productionConfigurationCrud from './../../../_crud/productionConfigurationCrud'
import { PagingEmpty } from './../../../commonLib/PagingEmpty';
import { EmptyData } from './../../../commonLib/EmptyData';
import { MyAlert } from './../../../commonLib/MyAlert';
import { UserView } from './../../Global/Elements/UserView';

export function ManageProductionConfiguration() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [productionConfiguration, setProductionConfiguration] = useState([])

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoadingMini(true);
        productionConfigurationCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProductionConfiguration(hdata);
                    }
                    else {
                        setProductionConfiguration([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProductionConfiguration([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        getData()
        setTimeout(() => {
            event.detail.complete();
        }, 1000);
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        productionConfigurationCrud.RemoveProductionConfigurationMaster(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    AppToaster('Deleted successfully.', 'success')
                    getData()
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by someone, could not delete.', 'error')
                }

            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network errror', 'error')
            })
    }

    return (
        <>
            <Container title="Production Configuration" doRefresh={doRefresh} isBack="true">
                <Link to="/productionunit/configuration/add" class="btn btn-sm btn-success">
                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                    New
                </Link>
                <div class="hr dotted"></div>
                <PagingEmpty text={"Total " + productionConfiguration.length + " items."}>
                    {isLoadingMini ?
                        <Spinner />
                        :
                        productionConfiguration && productionConfiguration.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "43px" }}></th>
                                            <th style={{ width: "43px" }}>#Id</th>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th style={{ width: "43px" }}>By</th>
                                            <th style={{ width: "83px" }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productionConfiguration.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link className="btn btn-xs btn-info" to={"/productionunit/configuration/dashboard/" + item.id} title="View dashboard" ><i class="ace-icon fa fa-tachometer"></i></Link>
                                                    </div>
                                                </td>
                                                <td>{index + 1}</td>
                                                <td>{item.name}</td>
                                                <td>{item.description}</td>
                                                <td>
                                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                </td>
                                                <td>
                                                    <div style={{ width: "73px" }}>
                                                        <div class="btn-group">
                                                            <Link to={"/productionunit/configuration/edit/" + item.id} class="btn btn-xs btn-success">
                                                                <i class="ace-icon fa fa-pencil bigger-120"></i>
                                                            </Link>&nbsp;
                                                            <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                                <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                            </MyAlert>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </PagingEmpty>
            </Container >
        </>
    )
}
