import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import * as warehouseCrud from '../../../_crud/warehouse/warehouseCrud'
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import GetLoginInfo from '../../../auth/_userContext/GetLoginInfo';

export function ProductProcessingStockSelectorView({ data, ishide = true, dataSetter, isDataSetter = false }) {
    const [hide, setHide] = useState(ishide);
    const [dataId, setDataId] = useState("");
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const userData = GetLoginInfo()

    useEffect(() => {
        if (data && isDataSetter && dataId) {
            //alert("getfunction")
            getProducts(data.product.id)
        }
    }, [dataId])

    useEffect(() => {
        if (data && isDataSetter) {
            //alert(data.id)
            setDataId(data.id)
        }
        else {
            if (isDataSetter) {
                setDataId("")
            }
        }
    }, [data])

    function getProducts(id) {
        setIsLoadingMini(true);
        warehouseCrud.GetByProductId(id, userData.warehouseId)
            .then(res => {
                if (res.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        dataSetter({ ...data, product: { ...hdata } });
                    }
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
            })
    }

    return (
        <>
            {data &&
                <div className="row center">
                    {
                        hide ?
                            <a style={{ cursor: "pointer" }} onClick={() => setHide(false)}><i class="ace-icon fa fa-eye blue"></i> View product details</a>
                            :
                            <a style={{ cursor: "pointer" }} onClick={() => setHide(true)}><i class="ace-icon fa fa-eye-slash blue"></i> Hide product details</a>
                    }
                </div>
            }
            {
                data && !hide &&
                <>
                    <div class="col-xs-12">
                        <div className="widget-box">
                            <div class="widget-header widget-header-flat widget-header-small">
                                <h5 class="widget-title">
                                    Contact Processing Details
                                </h5>
                            </div>

                            <div class="row">
                                <div class="profile-user-info profile-user-info-striped">
                                    <div class="profile-info-row">
                                        <div class="profile-info-name">Selected Product </div>

                                        <div class="profile-info-value">
                                            <span class="editable editable-click"><b><Link to={"/product/dashboard/" + data.product.id}>{data.product.productName} ({data.product.id})</Link></b></span>
                                        </div>
                                    </div>

                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Category </div>

                                        <div class="profile-info-value">
                                            <span >{data.product.productCategory.catName}</span>
                                        </div>
                                    </div>
                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Stock - {data.product.unit} </div>

                                        <div class="profile-info-value">
                                            <span>Main:{Number(data.product.mainStock).toFixed(2)}, Raw:{Number(data.product.rawStock).toFixed(2)}, Defective:{Number(data.product.defectiveStock).toFixed(2)}, Rejected:{Number(data.product.rejectedStock).toFixed(2)}, Processing:{Number(data.product.processingStock).toFixed(2)}, Global:{Number(data.product.globalStock).toFixed(2)}</span>
                                        </div>
                                    </div>
                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Processing Stock for <b>{data.contact.fullName}</b> </div>

                                        <div class="profile-info-value">
                                            <span>Remaining:<b> {Number(data.stock).toFixed(2)} {data.product.unit}</b>, Processed: {Number(parseFloat(data.globalStock) - parseFloat(data.stock)).toFixed(2)} {data.product.unit}, Global: {Number(data.globalStock).toFixed(2)} {data.product.unit}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
