import React, { useEffect, useState } from 'react'
import { useReactToPrint } from 'react-to-print';

export function PrintStarter({ item, setPrintData, printRef }) {
    const [printCount, setPrintCount] = useState(0)

    useEffect(() => {
        if (printCount > 0 && item) {
            handleProcessingDistribution()
        }
    }, [printCount])
    const handleProcessingDistribution = useReactToPrint({
        content: () => printRef.current,
    });
    const initPrint = () => { setPrintData(item); setPrintCount(printCount + 1) }
    return (
        <botton onClick={initPrint} className="btn btn-xs btn-success" ><i class="ace-icon fa fa-print"></i></botton>
    )
}
