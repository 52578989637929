import React, { useState } from 'react'

export const intervalContext = React.createContext();

const Interaval = (props) => {
    const [intervalId, setIntervalId] = useState(0);
    return (
        <intervalContext.Provider value={[intervalId, setIntervalId]}>
            {props.children}
        </intervalContext.Provider>
    );
}

export default Interaval;
