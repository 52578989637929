import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

// export async function GetAll(param) {
//     const loginData = GetLoginInfo();
//     if (loginData) {
//         return await createAxiosWithToken(loginData.token, OrgId()).get("Voucher/GetAll?PageNo=" + param.pageNo  + "&PageSize=" + param.pageSize +  "&FromDate=" + param.fromDate + "&ToDate=" + param.toDate)
//         return await createAxiosWithToken(loginData.token, OrgId()).post("Voucher/GetAll?PageNo=" + param.pageNumber  + "&PageSize=" + param.pageSize +  "&FromDate=" + param.fromDate + "&ToDate=" + param.toDate)
//     }
// }

export async function GetAll(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Voucher/GetAll", param)
    }
}
export async function GetByVoucherType(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Voucher/GetByVoucherType", param)
    }
}

export async function GetByVoucherTypeAndStatus(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Voucher/GetByVoucherTypeAndStatus", param)
    }
}
export async function GetByVoucherTypeWarehouse(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Voucher/GetByVoucherTypeWarehouse", param)
    }
}

export async function GetByVoucherTypeAndStatusWarehouse(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Voucher/GetByVoucherTypeAndStatusWarehouse", param)
    }
}



export async function GetByVoucherTypeAndPaymentStatus(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Voucher/GetByVoucherTypeAndPaymentStatus", param)
    }
}
export async function GetVoucherByTypeAndLinkedId(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Voucher/GetVoucherByTypeAndLinkedId", param)
    }
}

export async function GetVoucherByTypeAndContact(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Voucher/GetVoucherByTypeAndContact", param)
    }
}

export async function GetVoucherByTypeAndContactAndStatus(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Voucher/GetVoucherByTypeAndContactAndStatus", param)
    }
}


export async function GetVoucherByTypeAndLinkedIdWithPaymentStatus(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Voucher/GetVoucherByTypeAndLinkedIdWithPaymentStatus", param)
    }
}

export async function GetFiscalYears(count) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Voucher/GetFiscalYears?Count=" + count)
    }
}
export async function GetCurrentFiscalYears() {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Voucher/GetCurrentFiscalYears")
    }
}
export async function GetByVoucherPayment(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Voucher/GetByVoucherPayment", param)
    }
}
export async function UpdatePaymentTransaction(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Voucher/UpdatePaymentTransaction", param)
    }
}