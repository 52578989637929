import GetLoginInfo from "../../auth/_userContext/GetLoginInfo";
import { OrgId } from "../../config/GlobalSettings";
import { createAxiosWithToken } from "../../initApi/createAxios";

export async function GetAll() {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Cheque/Bank/GetAll")
    }
}

export async function Add(bank) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Cheque/Bank/Add", bank)
    }
}

export async function GetById(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Cheque/Bank/GetById?Id=" + id)
    }
}

export async function Remove(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Cheque/Bank/Remove?Id="+id)
    }
}
export async function Edit(bank) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Cheque/Bank/Edit", bank)
    }
}
