import React from 'react'
import Moment from 'react-moment';
import { EmptyData } from '../../commonLib/EmptyData';
import { getPageMargins } from '../../config/GlobalSettings';
import { GetELedgerAssetSalaryName } from '../../enumCollections/ELedgerAssetSalary';
import { GetEPaymentStatusName } from '../../enumCollections/EPaymentStatus';
import { ContactViewS } from '../Global/Elements/ContactViewS';
import { PrintBranding } from './PrintBranding';

export const SalaryVoucherPrint = React.forwardRef((props, ref) => {
    const { paid, pending, salaryVoucher, voucherPayment } = props
    return (
        <div ref={ref}>
            <style type="text/css">
                {getPageMargins()}
            </style>
            {
                salaryVoucher &&
                <PrintBranding printName="Salary Voucher" id={salaryVoucher.id} date={salaryVoucher.voucherDate}>

                    {
                        salaryVoucher &&
                        <div className="row">
                            <div className="col-xs-12">

                                <table style={{ width: "100%" }} className="table-bordered-dark">
                                    <tbody>
                                        <tr >
                                            <td>For Contact</td>
                                            <td>
                                                <ContactViewS item={salaryVoucher.contact} uid={"CXV1"} />
                                            </td>
                                        </tr>
                                        <tr >
                                            <td>Phone</td>
                                            <td>{salaryVoucher.contact.phone}</td>
                                        </tr>
                                        <tr >
                                            <td>Address</td>
                                            <td>{salaryVoucher.contact.address}</td>
                                        </tr>
                                        <tr >
                                            <td>Email</td>
                                            <td>{salaryVoucher.contact.email ? salaryVoucher.contact.email : "NA"}</td>
                                        </tr>
                                        <tr >
                                            <td>Amount</td>
                                            <td><b>{Number(salaryVoucher.totalAmount).toFixed(2)}</b></td>
                                        </tr>
                                        <tr >
                                            <td><b>Voucher Balance</b></td>
                                            <td><span>Paid: <b>{Number(paid).toFixed(2)}</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pending: <b>{Number(pending).toFixed(2)}</b> </span></td>
                                        </tr>
                                        <tr >
                                            <td>Status</td>
                                            <td>{GetEPaymentStatusName(salaryVoucher.paymentStatus)}</td>
                                        </tr>
                                        <tr >
                                            <td>Created By</td>
                                            <td>{salaryVoucher.createdByUser.fullName} ({salaryVoucher.createdByUser.userName})</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    }
                    <br />

                    <div className="row">
                        <div className="col-xs-12">
                            <h5 className="text-center">Payments</h5>
                            {voucherPayment && voucherPayment.length > 0 ?
                                <table style={{ width: "100%" }} className="table-bordered-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Date</th>
                                            <th>Amount</th>
                                            <th>By</th>
                                            <th>Note</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {voucherPayment.map((item, index) =>
                                            <tr key={item.Id}>
                                                <td>{index + 1}</td>
                                                <td><Moment format="DD-MMM-YYYY">{item.receivedDate}</Moment></td>
                                                <td><b>₹ {item.amount}</b></td>
                                                <td>{GetELedgerAssetSalaryName(parseInt(item.fromLedgerId))}</td>
                                                <td>{item.note}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                :
                                <EmptyData>
                                    No payment made.
                                    <br />
                                    <br />
                                </EmptyData>
                            }
                        </div>
                    </div>
                </PrintBranding>
            }
        </div >
    )
})