import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import * as productCrud from '../../_crud/productCrud'
import * as warehouseCrud from '../../_crud/warehouse/warehouseCrud'
import { GetEBoolName } from '../../enumCollections/EBool';
import { Paging } from '../../commonLib/Paging';
import { Link } from 'react-router-dom';
import { DEFAULT_USER_IMG, PAGING_PAGE_SIZE } from '../../config/GlobalSettings';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { Spinner } from '../../commonLib/Spinner';
import { GetEWageTypeName } from '../../enumCollections/EWageType'
import ReactTooltip from 'react-tooltip';
import 'moment-timezone';
import { EmptyData } from '../../commonLib/EmptyData';
import { MyAlert } from '../../commonLib/MyAlert';
import { dashboardContext } from '../Global/dashboardContext';
import { BASE_URL } from '../../initApi/apiUrls';
import { UserView } from '../Global/Elements/UserView';
import GetLoginInfo from '../../auth/_userContext/GetLoginInfo';
import { ImgLazyload } from '../../commonLib/ImgLazyload';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

const initValues = {
    searchTerm: "",
    pageNo: 1,
    pageSize: PAGING_PAGE_SIZE
}

export function ManageProduct() {

    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [search, setSearch] = useState(initValues)
    const [products, setProducts] = useState([])
    const [LastPage, setLastPage] = useState(1)
    const [pagingData, setPagingData] = useState()
    const [dashboardData, setDashboardData] = useContext(dashboardContext)
    const userData = GetLoginInfo()

    const OnPageChange = (e) => {
        const { value } = e.target
        setSearch({ ...search, pageNo: value });
        getProAny({
            searchTerm: search.searchTerm,
            pageNo: value,
            pageSize: search.pageSize
        })
    }
    useEffect(() => {
        getProAny({ searchTerm: search.searchTerm, pageNo: 1, pageSize: search.pageSize })
    }, [])


    function onSearch() {
        getProAny({ searchTerm: search.searchTerm, pageNo: 1, pageSize: search.pageSize })
    }

    function getProAny(searchparam) {
        if (userData.isWarehouse == 0) {
            getData(searchparam)
        }
        else {
            getDataFromWarehouse(searchparam)
        }
    }

    function getData(searchparam) {
        setIsLoadingMini(true);
        productCrud.GetAll(searchparam)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setProducts(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoadingMini(false)
                        setProducts([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoadingMini(false)
                    setProducts([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }
    function getDataFromWarehouse(searchparam) {
        setIsLoadingMini(true);
        warehouseCrud.GetAllProduct({ Search: { ...searchparam }, WarehouseId: userData.warehouseId })
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setProducts(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoadingMini(false)
                        setProducts([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoadingMini(false)
                    setProducts([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        setSearch({ ...search, pageNo: 1 })
        getProAny({ searchTerm: search.searchTerm, pageNo: 1, pageSize: search.pageSize })
        setTimeout(() => {
            event.target.complete();
        }, 1000);
    }

    function SearchChange(e) {
        const value = e.target.value
        setSearch({ ...search, searchTerm: value })
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        productCrud.Remove(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    AppToaster('Deleted successfully.', 'success')
                    getData()
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by someone,could not delete.', 'error')
                }

            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }

    return (
        <Container title="Products" doRefresh={doRefresh} >
            <Link to="/product/add" class="btn btn-sm btn-success ">
                <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                New
            </Link>
            <Link to="/product/categories" style={{ marginLeft: "5px" }} class="btn btn-sm btn-primary ">
                <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                Manage Categories
            </Link>
            <div class="hr dotted"></div>
            <div class="form-horizontal" >
                <div className="row">
                    <div className="col-md-11">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <span class="input-icon" style={{ width: "100%" }}>
                                <TextBoxTranslater style={{paddingLeft: "24px"}} value={search.searchTerm} onChange={(e) => setSearch({ ...search, searchTerm: e.target.value })} className="form-control" type="text" placeholder="Search Product" />
                                <i class="ace-icon fa fa-search blue"></i>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <button onClick={onSearch} to="/product/add" class="btn btn-sm btn-info pull-right">
                                <i class="ace-icon fa fa-search bigger-110"></i>
                                Run
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Paging
                pagingData={pagingData}
                LastPage={LastPage}
                CurrentPage={search.pageNo}
                OnPageChange={OnPageChange} >
                {isLoadingMini ?
                    <Spinner />
                    :
                    products && products.length > 0 ?
                        <div className="tables-scroll">
                            <table className="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th style={{ width: "43px" }}></th>
                                        <th></th>
                                        <th>#Id</th>
                                        <th>Name</th>
                                        <th>Category</th>
                                        <th>Main</th>
                                        <th>Raw</th>
                                        <th>Processing</th>
                                        <th>Defective</th>
                                        <th>Rejected</th>
                                        <th>Global</th>
                                        <th>Cost</th>
                                        <th>Production</th>
                                        <th>Wage as </th>
                                        <th>By</th>
                                        <th style={{ width: "72px" }}></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {products.map((item, index) =>
                                        <tr key={item.id}>
                                            <td>
                                                <div class="btn-group">
                                                    <Link className="btn btn-xs btn-info" to={"/product/dashboard/" + item.id} title="View dashboard" ><i class="ace-icon fa fa-tachometer"></i></Link>
                                                </div>
                                            </td>
                                            <td>
                                                <a data-tip data-for={"PP" + index}>
                                                    <ImgLazyload height="35px" width="35px" className="img-round pull-left" src={item.isPhoto == 1 ? (BASE_URL + "/" + item.photoUrl) : DEFAULT_USER_IMG} />
                                                </a>
                                                <ReactTooltip delayShow={500} arrowColor="gray" borderColor="#9b9b9b" className="opaque" id={"PP" + index} type="light">
                                                    <ImgLazyload height="200px" width="200px" style={{ borderRadius: "0" }} className="img-round" src={item.isPhoto == 1 ? (BASE_URL + "/" + item.photoUrl) : DEFAULT_USER_IMG} />
                                                </ReactTooltip>
                                            </td>
                                            <td>{item.id}</td>
                                            <td>{item.productName}</td>
                                            <td>{item.productCategory.catName}</td>
                                            <td><b className="green">{Number(item.mainStock).toFixed(2)} {item.unit}</b></td>
                                            <td><b className="blue">{Number(item.rawStock).toFixed(2)} {item.unit}</b></td>
                                            <td><b className="purple"> {Number(item.processingStock).toFixed(2)} {item.unit}</b></td>
                                            <td>{Number(item.defectiveStock).toFixed(2)} {item.unit}</td>
                                            <td>{Number(item.rejectedStock).toFixed(2)} {item.unit}</td>
                                            <td>{Number(item.globalStock).toFixed(2)} {item.unit}</td>
                                            <td>₹ {Number(item.cost).toFixed(2)}</td>
                                            <td><span class={item.isProduction == 1 ? "label label-sm label-success" : "label label-sm label-danger"}>{GetEBoolName(item.isProduction)}</span></td>
                                            <td><span class={item.wageType == 1 ? "label label-sm label-info" : "label label-sm label-default"}>{GetEWageTypeName(item.wageType)}</span></td>
                                            <td>
                                                <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                            </td>

                                            <td>
                                                <div style={{ width: "73px" }}>
                                                    <div class="btn-group">
                                                        <Link to={"/product/edit/" + item.id} onClick={() => setDashboardData({ ...dashboardData, productdata: item })} class="btn btn-xs btn-success">
                                                            <i class="ace-icon fa fa-pencil bigger-120"></i>
                                                        </Link>

                                                        <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                            <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                        </MyAlert>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        :
                        <EmptyData>
                            No records found.
                            <br />
                            <br />
                        </EmptyData>
                }
            </Paging>
        </Container >
    )
}
