import GetLoginInfo from "../../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../../initApi/createAxios";
import { OrgId } from "../../config/GlobalSettings";

export async function GetAll(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("WarehouseTransfer/GetAll", param)
    }
}
export async function GetAllItems(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("WarehouseTransfer/GetAllItems", param)
    }
}
export async function GetByWarehouseId(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("WarehouseTransfer/GetByWarehouseId", param )
    }
}

export async function GetByWarehouseFrom(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("WarehouseTransfer/GetByWarehouseFrom", param )
    }
}

export async function GetByWarehouseTo(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("WarehouseTransfer/GetByWarehouseTo", param )
    }
}


export async function GetById(Id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("WarehouseTransfer/GetById?Id=" + Id)
    }
}

export async function Add(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("WarehouseTransfer/Add", param)
    }
}


export async function AddWarehouseTransferProduct(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("WarehouseTransfer/AddWarehouseTransferProduct", param)
        
    }
}

export async function RemoveWarehouseTransferProduct(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("WarehouseTransfer/RemoveWarehouseTransferProduct?Id=" + id )
    }
}


