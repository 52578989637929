import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetAll() {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("LocStates/GetAll")
    }
}

export async function Add(locStates) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("LocStates/Add", locStates)
    }
}

export async function Remove(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("LocStates/Remove?Id="+id)
    }
}