import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import * as contactCrud from '../../_crud/contactCrud'
import { GetEBoolName } from '../../enumCollections/EBool';
import { Paging } from '../../commonLib/Paging';
import { Link } from 'react-router-dom';
import { DEFAULT_USER_IMG, ERROR_IMG, PAGING_PAGE_SIZE } from '../../config/GlobalSettings';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { Spinner } from '../../commonLib/Spinner';
import ReactTooltip from 'react-tooltip';
import 'moment-timezone';
import { EmptyData } from '../../commonLib/EmptyData';
import { dashboardContext } from '../Global/dashboardContext';
import { GetEStatusName } from '../../enumCollections/EStatus';
import { BASE_URL } from '../../initApi/apiUrls';
import { UserView } from './../Global/Elements/UserView';
import { WarehouseView } from '../Global/Elements/WarehouseView';
import GetLoginInfo from '../../auth/_userContext/GetLoginInfo';
import { ImgLazyload } from '../../commonLib/ImgLazyload';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

const initValues = {
    searchTerm: "",
    pageNo: 1,
    pageSize: PAGING_PAGE_SIZE
}

export default function ManageContact() {

    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [search, setSearch] = useState(initValues)
    const [contacts, setcontacts] = useState([])
    const [LastPage, setLastPage] = useState(1)
    const [pagingData, setPagingData] = useState()
    const [dashboardData, setDashboardData] = useContext(dashboardContext)
    const userDate = GetLoginInfo()

    const OnPageChange = (e) => {
        const { value } = e.target
        setSearch({ ...search, pageNo: value });
        onSearchNew({
            searchTerm: search.searchTerm,
            pageNo: value,
            pageSize: search.pageSize
        })
    }
    useEffect(() => {
        onSearchNew({ searchTerm: search.searchTerm, pageNo: 1, pageSize: search.pageSize })
    }, [])

    function onSearch() {
        onSearchNew({ searchTerm: search.searchTerm, pageNo: 1, pageSize: search.pageSize })
    }
    function onSearchNew(search) {
        if (userDate.isWarehouse == 0)
            getData(search)
        else
            getDataWarehouse(search)
    }

    function getData(searchparam) {
        setIsLoadingMini(true);
        contactCrud.GetAll(searchparam)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setcontacts(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoadingMini(false)
                        setcontacts([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoadingMini(false)
                    setcontacts([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }
    function getDataWarehouse(searchparam) {
        setIsLoadingMini(true);
        contactCrud.GetAllWarehouse({ Search: { ...searchparam }, WarehouseId: userDate.warehouseId })
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setcontacts(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoadingMini(false)
                        setcontacts([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoadingMini(false)
                    setcontacts([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        setSearch({ ...search, pageNo: 1 })
        onSearchNew({ searchTerm: search.searchTerm, pageNo: 1, pageSize: search.pageSize })
        setTimeout(() => {
            event.target.complete();
        }, 1000);
    }

    return (
        <Container title="Contacts" doRefresh={doRefresh}>
            <Link to="/contact/add" class="btn btn-sm btn-success">
                <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                New
            </Link>
            <div class="hr dotted"></div>
            <div class="form-horizontal" >
                <div className="row">
                    <div className="col-md-11">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <span class="input-icon" style={{ width: "100%" }}>
                                <TextBoxTranslater style={{paddingLeft: "24px"}} value={search.searchTerm} onChange={(e) => setSearch({ ...search, searchTerm: e.target.value })} className="form-control" type="text" id="form-field-icon-1" placeholder="Search eg. id, phone etc" />
                                <i class="ace-icon fa fa-search blue"></i>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <button onClick={onSearch} to="/contact/add" class="btn btn-sm btn-info pull-right">
                                <i class="ace-icon fa fa-search bigger-110"></i>
                                Run
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Paging
                pagingData={pagingData}
                LastPage={LastPage}
                CurrentPage={search.pageNo}
                OnPageChange={OnPageChange} >

                {isLoadingMini ?
                    <Spinner />
                    :
                    contacts && contacts.length > 0 ?
                        <div className="tables-scroll">
                            <table className="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th ></th>
                                        <th></th>
                                        <th>Branch</th>
                                        <th>#Id</th>
                                        <th>Full Name</th>
                                        <th>Category</th>
                                        <th>Address</th>
                                        <th>Phone</th>
                                        <th>Email</th>
                                        <th>City</th>
                                        <th>State</th>
                                        <th>Pin Code</th>
                                        <th>Is Document</th>
                                        <th>Status</th>
                                        {/* <th>Note</th> */}
                                        <th>By</th>
                                        <th></th>
                                    </tr>
                                </thead>


                                <tbody>
                                    {
                                        contacts.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link className="btn btn-xs btn-info" to={"/contact/dashboard/" + item.id} title="View dashboard" ><i class="ace-icon fa fa-tachometer"></i></Link>
                                                    </div>
                                                </td>
                                                <td>
                                                    <a data-tip data-for={"P" + index}>
                                                        <ImgLazyload height="35px" width="35px" className="img-round pull-left" src={item.isPhoto == 1 ? (BASE_URL + "/" + item.photoUrl) : DEFAULT_USER_IMG}
                                                            onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
                                                    </a>
                                                    <ReactTooltip delayShow={500} arrowColor="gray" borderColor="#9b9b9b" className="opaque" id={"P" + index} type="light">
                                                        <ImgLazyload height="200px" width="100%" className="img-round-tooltip" src={item.isPhoto == 1 ? (BASE_URL + "/" + item.photoUrl) : DEFAULT_USER_IMG}
                                                            onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
                                                    </ReactTooltip>
                                                </td>
                                                <td>
                                                    <WarehouseView item={item.warehouse} uid={"wh" + index} />
                                                </td>
                                                <td>{item.id}</td>
                                                <td><div style={{ width: "150px" }}>{item.fullName}</div></td>
                                                <td>{item.productionCategory ? item.productionCategory.productionCatName : "NA"}</td>
                                                <td><div style={{ width: "200px" }}>{item.address}</div></td>
                                                <td>{item.phone}</td>
                                                <td>{item.email}</td>
                                                <td>{item.city}</td>
                                                <td><div style={{ width: "100px" }}>{item.state}</div></td>
                                                <td>{item.pinCode}</td>
                                                <td><span class={item.isDocument == 1 ? "label label-sm label-success" : "label label-sm label-danger"}>{GetEBoolName(item.isDocument)}</span></td>
                                                <td><span class={item.status == 1 ? "label label-sm label-success" : "label label-sm label-danger"}>{GetEStatusName(item.status)}</span></td>
                                                {/* <td><div style={{ width: "150px" }}>{item.note}</div></td> */}
                                                <td>
                                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link to={"/contact/edit/" + item.id} onClick={() => setDashboardData({ ...dashboardData, contactdata: item })} class="btn btn-xs btn-success">
                                                            <i class="ace-icon fa fa-pencil bigger-120"></i>
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        )

                                    }
                                </tbody>


                            </table>
                        </div>
                        :
                        <EmptyData>
                            No records found.
                            <br />
                            <br />
                        </EmptyData>
                }
            </Paging>
        </Container >
    )
}
