import React, { useContext, useState, useEffect } from 'react'
import AppToaster from '../../../commonLib/AppToaster';
import *as productDesignCrud from '../../../_crud/productDesignCrud'
import *as productConsumptionCrud from '../../../_crud/productConsumptionCrud'
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { Container } from '../../../base/Container';
import { LoadingButton } from '../../../commonLib/LoadingButton';
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater';
import { FileUploader } from '../../../commonLib/FileUploader'
import GetLoginInfo from '../../../auth/_userContext/GetLoginInfo';
import { ProductSelectorView } from '../../Global/SelectorView/ProductSelectorView';

export function AddProductDesignItem() {
    const [file, setFile] = useState()

    const history = useHistory()
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [refProduct, setRefProduct] = useState([])
    const [selectedRefProductData, setSelectedRefProductData] = useState(undefined);
    const { id, did } = useParams()
    const userData = GetLoginInfo()

    const initialValues = {
        SerialNo: "",
        ProductDesignId: did,
        Name: "",
        Value: 0,
        Text: "",
        IsPrintInProduction: 0,
        IsPrintInProcessing: 0,
        RefProductId: 0,
        File: undefined
    }

    const Schema = Yup.object().shape({
        Name: Yup.string().min(3, "Name must between 3 to 50 characters").max(50, "Name must between 3 to 50 characters").required("Name is required."),
        Value: Yup.string().required("Value is required/ keep 0 if not required."),
        Text: Yup.string().required("Text is required."),
        SerialNo: Yup.string().required("Serial No is required."),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });
    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)

        const formData = new FormData()
        formData.append('serialNo', value.SerialNo)
        formData.append('productDesignId', value.ProductDesignId)
        formData.append('name', value.Name)
        formData.append('value', value.Value)
        formData.append('text', value.Text)
        formData.append('isPrintInProduction', value.IsPrintInProduction)
        formData.append('isPrintInProcessing', value.IsPrintInProcessing)
        formData.append('refProductId', value.RefProductId)
        if (file)
            formData.append('file', file)

        productDesignCrud.AddItem(formData)
            .then(res => {
                setIsLoadingMini(false)
                setIsLoadingLocal(false);
                AppToaster('Added successfully.', 'success')
                //AppToaster(res.data.message)
                history.push("/product/design/" + id + "/dashboard/" + did)

            }).catch(error => {
                setIsLoadingMini(false)
                setIsLoadingLocal(false);
                alert(error)
                AppToaster(error, 'error')
            })
    }

    useEffect(() => {
        getRefProducts()
    }, [])

    function getRefProducts() {
        setIsLoadingMini(true);
        productConsumptionCrud.GetByProductId(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setRefProduct(hdata);
                    }
                    else {
                        setRefProduct([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setRefProduct([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }
    useEffect(() => {
        if (selectedRefProductData) {
            formik.setValues({ ...formik.values, RefProductId: selectedRefProductData.id})
        }
        else {
            formik.setValues({ ...formik.values, RefProductId: 0 })
        }
    }, [selectedRefProductData])

    const OnRefProductChange = (e) => {
        const { value } = e.target;
        if (value) {
            refProduct.forEach(element => {
                if (element.consumeProduct.id == value) {
                    {
                        setSelectedRefProductData(element.consumeProduct)
                    }
                }
            });
        }
        else {
            setSelectedRefProductData(undefined)
        }
    }

    return (
        <>
            <Container title="Add Product Design" isProduct={true} isBack="true">
                <form onSubmit={formik.handleSubmit} class="form-horizontal">
                    <div className="row">
                        <div className="col-md-2">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Sr. No</label>
                                    <input className="form-control" type="number" placeholder="Sr. no" name="SerialNo" value={formik.values.SerialNo} onChange={(e) => formik.setValues({ ...formik.values, SerialNo: e.target.value })} />
                                    {
                                        formik.touched.SerialNo &&
                                            formik.errors.SerialNo ? (
                                            <label className="text-danger" >
                                                {formik.errors.SerialNo}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Name</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Name" name="Name" value={formik.values.Name} onChange={(e) => formik.setValues({ ...formik.values, Name: e.target.value })} />
                                    {
                                        formik.touched.Name &&
                                            formik.errors.Name ? (
                                            <label className="text-danger" >
                                                {formik.errors.Name}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Value</label>
                                    <input className="form-control" type="number" placeholder="Value" name="Value" value={formik.values.Value} onChange={(e) => formik.setValues({ ...formik.values, Value: e.target.value })} />
                                    {
                                        formik.touched.Value &&
                                            formik.errors.Value ? (
                                            <label className="text-danger" >
                                                {formik.errors.Value}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-12">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right"> Text </label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Text" name="Text" value={formik.values.Text} onChange={(e) => formik.setValues({ ...formik.values, Text: e.target.value })} />
                                    {
                                        formik.touched.Text &&
                                            formik.errors.Text ? (
                                            <label className="text-danger" >
                                                {formik.errors.Text}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-2">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Is Print in Production</label>
                                    <div>
                                        <input onChange={() => formik.setValues({ ...formik.values, IsPrintInProduction: (formik.values.IsPrintInProduction == 1 ? 0 : 1) })} checked={formik.values.IsPrintInProduction == 1} type="checkbox" class="ace ace-switch ace-switch-5" />
                                        <span class="lbl middle"></span>
                                    </div>
                                    {
                                        formik.touched.IsPrintInProduction &&
                                            formik.errors.IsPrintInProduction ? (
                                            <label className="text-danger" >
                                                {formik.errors.IsPrintInProduction}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Is Print in Processing</label>
                                    <div>
                                        <input onChange={() => formik.setValues({ ...formik.values, IsPrintInProcessing: (formik.values.IsPrintInProcessing == 1 ? 0 : 1) })} checked={formik.values.IsPrintInProcessing == 1} type="checkbox" class="ace ace-switch ace-switch-5" />
                                        <span class="lbl middle"></span>
                                    </div>
                                    {
                                        formik.touched.IsPrintInProcessing &&
                                            formik.errors.IsPrintInProcessing ? (
                                            <label className="text-danger" >
                                                {formik.errors.IsPrintInProcessing}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Ref Product</label>
                                    <select className="form-control" name="RefProductId" value={formik.values.RefProductId} onChange={OnRefProductChange}>
                                        <option value="0" >Select...</option>
                                        {
                                            refProduct.map((data, index) =>
                                                <option key={data.consumeProduct.id} value={data.consumeProduct.id}>{data.consumeProduct.productName.toUpperCase()} ({data.consumeProduct.id})</option>
                                            )
                                        }
                                    </select>
                                    <ProductSelectorView data={selectedRefProductData} dataSetter={setSelectedRefProductData} isDataSetter={userData.isWarehouse == 1 ? true : false} />
                                    {
                                        formik.touched.RefProductId &&
                                            formik.errors.RefProductId ? (
                                            <label className="text-danger" >
                                                {formik.errors.RefProductId}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Select attachment (max size 1mb)</label>
                                    <FileUploader setFile={setFile} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="clearfix form-actions">
                        <div class="pull-right">
                            {isLoadingLocal ?
                                <LoadingButton />
                                :
                                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Add
                                </button>}
                        </div>
                    </div>
                </form>
            </Container >
        </>
    )
}