import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import * as applicationUserCrud from '../../_crud/applicationUserCrud'
import { Paging } from '../../commonLib/Paging';
import { Link } from 'react-router-dom';
import { DEFAULT_USER_IMG, ERROR_IMG, PAGING_PAGE_SIZE } from '../../config/GlobalSettings';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { Spinner } from '../../commonLib/Spinner';
import Moment from 'react-moment';
import 'moment-timezone';
import { EmptyData } from '../../commonLib/EmptyData';
import { GetEStatus, GetEStatusName } from '../../enumCollections/EStatus';
import { loadingContext } from '../../commonLib/_lodingContext';
import { BASE_URL } from '../../initApi/apiUrls';
import ReactTooltip from 'react-tooltip';
import { ImgLazyload } from '../../commonLib/ImgLazyload';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

const initValues = {
  searchTerm: "",
  pageNo: 1,
  pageSize: PAGING_PAGE_SIZE
}

const initEdit = {
  id: 0,
  isEdit: false,
  value: undefined
}

export function ManageUsers() {
  const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
  const [search, setSearch] = useState(initValues)
  const [users, setUsers] = useState([])
  const [LastPage, setLastPage] = useState(1)
  const [pagingData, setPagingData] = useState()
  const [edit, setEdit] = useState(initEdit)
  const [editStatus, setEditStatus] = useState(initEdit)
  const [isLoading, setIsLoading] = useContext(loadingContext)

  const OnPageChange = (e) => {
    const { value } = e.target
    setSearch({ ...search, pageNo: value });
    getData({
      searchTerm: search.searchTerm,
      pageNo: value,
      pageSize: search.pageSize
    })
  }
  useEffect(() => {
    getData({ searchTerm: search.searchTerm, pageNo: 1, pageSize: search.pageSize })
  }, [])

  function onSearch() {
    getData({ searchTerm: search.searchTerm, pageNo: 1, pageSize: search.pageSize })
  }

  function getData(param) {
    setIsLoadingMini(true);
    applicationUserCrud.SearchUser(param)
      .then(res => {
        //alert(JSON.stringify(res))
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            let paging = res.data.paging
            setPagingData(paging)
            setLastPage(paging.pageCount)
            setUsers(hdata);
            setIsLoadingMini(false)
          }
          else {
            setLastPage(1)
            setIsLoadingMini(false)
            setUsers([]);
          }
        }
        else {
          setLastPage(1)
          setIsLoadingMini(false)
          setUsers([]);
          AppToaster("Server Error /Invalid Try.", 'error')
        }
      })
      .catch(error => {
        setLastPage(1)
        setIsLoadingMini(false);
        AppToaster(error, 'error')
      })
  }

  function doRefresh(event) {
    setSearch({ ...search, pageNo: 1 })
    getData({ searchTerm: search.searchTerm, pageNo: 1, pageSize: search.pageSize })
    setTimeout(() => {
      event.target.complete();
    }, 1000);
  }

  const UpdateName = () => {
    setIsLoading(true);
    applicationUserCrud.Update({ userId: edit.value.id, fullName: edit.value.fullName })
      .then(res => {
        if (res.data.succeeded) {
          setEdit(initEdit)
          doRefresh()
          AppToaster('Updated successfully.', 'success')
          setIsLoading(false);
        }
        else {
          AppToaster("Server Error /Invalid Try.", 'error')
          setIsLoading(false);
        }

      }).catch(error => {
        setEdit(initEdit)
        AppToaster(error, 'error')
        setIsLoading(false);
      })
  }

  const UpdateStatusChange = () => {
    setIsLoading(true);
    applicationUserCrud.UpdateStatus({ userId: editStatus.value.id, userStatus: editStatus.value.status })
      .then(res => {
        if (res.data.succeeded) {
          setEditStatus(initEdit)
          doRefresh()
          AppToaster('Updated successfully.', 'success')
          setIsLoading(false);
        }
        else {
          AppToaster("Server Error /Invalid Try.", 'error')
          setIsLoading(false);
        }

      }).catch(error => {
        AppToaster(error, 'error')
        setIsLoading(false);
      })
  }

  return (
    <Container title="Users" doRefresh={doRefresh}>
      <Link to="/users/add" class="btn btn-sm btn-success ">
        <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
        New
      </Link>
      <div class="hr dotted"></div>
      <div class="form-horizontal" >
        <div className="row">
          <div className="col-md-11">
            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
              <span class="input-icon" style={{ width: "100%" }}>
                <TextBoxTranslater style={{ paddingLeft: "24px" }} value={search.searchTerm} onChange={(e) => setSearch({ ...search, searchTerm: e.target.value })} className="form-control" type="text" placeholder="Search eg. id, phone etc" />
                <i class="ace-icon fa fa-search blue"></i>
              </span>
            </div>
          </div>
          <div className="col-md-1">
            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
              <button onClick={onSearch} to="/contact/add" class="btn btn-sm btn-info pull-right">
                <i class="ace-icon fa fa-search bigger-110"></i>
                Run
              </button>
            </div>
          </div>
        </div>
      </div>
      <Paging
        pagingData={pagingData}
        LastPage={LastPage}
        CurrentPage={search.pageNo}
        OnPageChange={OnPageChange} >

        {isLoadingMini ?
          <Spinner />
          :
          users && users.length > 0 ?
            <div className="tables-scroll">
              <table className="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th style={{ width: "50px" }}></th>
                    <th>Branch</th>
                    <th>User Name</th>
                    <th>Full Name</th>
                    <th>Phone Number</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Status</th>
                    <th>Created On</th>
                    <th style={{ width: "50px" }}></th>
                  </tr>
                </thead>

                <tbody>
                  {
                    users.map((item, index) =>
                      <tr key={item.Id}>
                        <td>
                          <a data-tip data-for={"P" + index}>
                            <ImgLazyload height="35px" width="35px" className="img-round pull-left" src={item.isDP == 1 ? BASE_URL + "/" + item.dpUrl : DEFAULT_USER_IMG}
                              onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
                          </a>
                          <ReactTooltip delayShow={500} arrowColor="gray" borderColor="#9b9b9b" className="opaque" id={"P" + index} type="light">
                            <ImgLazyload height="200px" width="100%" className="img-round-tooltip" src={item.isDP == 1 ? BASE_URL + "/" + item.dpUrl : DEFAULT_USER_IMG}
                              onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
                          </ReactTooltip>
                        </td>
                        <td>
                          <span
                            class={item.warehouseCode ? "label label-sm label-pink bolder" : "label label-sm label-info bolder"}
                            style={{
                              //fontWeight: "bold",
                              // textShadow: "1px 2px 2px #434343"
                            }}>
                            <i class="menu-icon fa fa-industry"></i> {item.warehouseCode ? item.warehouseCode : "MAIN"}
                          </span>
                        </td>
                        <td>{item.userName}</td>
                        <td>{edit.isEdit && item.id == edit.id ?
                          <input className="form-control" type="text" placeholder="Full Name" name="fullName" value={edit.value.fullName} onChange={(e) => setEdit({ ...edit, value: { ...edit.value, fullName: e.target.value } })} />
                          :
                          <span>{item.fullName}</span>
                        }

                          <div class="btn-group">
                            {edit.isEdit && item.id == edit.id ?
                              isLoading ?
                                <button disabled class="btn btn-xs btn-info">
                                  <i class="ace-icon fa fa-pencil bigger-120"></i> Updating...
                                </button>
                                :
                                <>
                                  <button onClick={UpdateName} class="btn btn-xs btn-info">
                                    <i class="ace-icon fa fa-pencil bigger-120"></i> Update
                                  </button>
                                  <button onClick={() => setEdit(initEdit)} class="btn btn-xs btn-default">
                                    Cancel
                                  </button>
                                </>
                              :
                              <a style={{ marginLeft: "8px" }} onClick={() => setEdit({ id: item.id, value: item, isEdit: true })} >
                                <i class="ace-icon fa fa-pencil bigger-120"></i>
                              </a>
                            }
                          </div>
                        </td>
                        <td>{item.phoneNumber}</td>
                        <td>{item.email}</td>
                        <td><span class={item.roleName == "Administrator" ? "label label-sm label-info" : "label label-sm label-warning"}>{item.roleName}</span></td>
                        <td>

                          {editStatus.isEdit && item.id == editStatus.id ?
                            <select className="form-control" name="userStatus" value={editStatus.value.status} onChange={(e) => setEditStatus({ ...editStatus, value: { ...editStatus.value, status: e.target.value } })}>
                              {
                                GetEStatus.EStatus.map((data, index) =>
                                  <option value={data.Id}>{data.Name}</option>
                                )
                              }
                            </select>
                            :
                            <span class={item.status == 1 ? "label label-sm label-success" : "label label-sm label-danger"}>{GetEStatusName(item.status)}</span>
                          }
                          <div class="btn-group">
                            {editStatus.isEdit && item.id == editStatus.id ?
                              isLoading ?
                                <button disabled class="btn btn-xs btn-info">
                                  <i class="ace-icon fa fa-pencil bigger-120"></i> Updating...
                                </button>
                                :
                                <>
                                  <button onClick={UpdateStatusChange} class="btn btn-xs btn-info">
                                    <i class="ace-icon fa fa-pencil bigger-120"></i> Update
                                  </button>
                                  <button onClick={() => setEditStatus(initEdit)} class="btn btn-xs btn-default">
                                    Cancel
                                  </button>
                                </>
                              :
                              <a style={{ marginLeft: "8px" }} onClick={() => setEditStatus({ id: item.id, value: item, isEdit: true })} >
                                <i class="ace-icon fa fa-pencil bigger-120"></i>
                              </a>
                            }
                          </div>
                        </td>
                        <td>
                          <Moment format="DD-MMM-YYYY HH:mm">{item.createdOn}</Moment>
                        </td>
                        <td>
                          <div class="btn-group">
                            <Link to={"/loginlogs/user/" + item.userName} title="View Login Logs" class="btn btn-xs btn-info">
                              <i class="ace-icon fa fa-braille bigger-120"></i>
                            </Link>
                          </div></td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
            :
            <EmptyData>
              No records found.
              <br />
              <br />
            </EmptyData>
        }
      </Paging>
    </Container >
  )
}