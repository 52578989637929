import React, { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { ReactTransliterate, TriggerKeys } from "react-transliterate";
import "react-transliterate/dist/index.css";
import { languageContext } from "./languageContext";

export function TextBoxTranslater(props) {
    const [text, setText] = useState(props.value);
    const [enabled, setEnabled] = useState(true);
    const [language, setLanguage] = useContext(languageContext);

    useEffect(() => {
        if (language == "en") {
            setEnabled(false)
        }
        else {
            setEnabled(true)
        }
    }, [language])

    useEffect(() => {
        //alert(text)
        setText(props.value)
    }, [props.value])

    return (
        <>
            <ReactTransliterate
                {...props}
                value={text}
                onChangeText={(text) => {
                    setText(text);
                }}
                lang={language}
                enabled={enabled}
            // triggerKeys={[
            //     TriggerKeys.KEY_ENTER
            //   ]}
            />
        </>
    )
}
