const GetEStockTypeName = (id) => {
    switch (id) {
        case 1: { return "Raw Stock" }
        case 2: { return "Main Stock" }
        case 3: { return "Defective Stock" }
        case 4: { return "Rejected Stock" }
    }
}

const GetEStockType = {
    EStockType: [
        { Id: 1, Name: "Raw Stock" },
        { Id: 2, Name: "Main Stock" },
        { Id: 3, Name: "Defective Stock" },
        { Id: 4, Name: "Rejected Stock" }
    ]
}

export { GetEStockTypeName, GetEStockType }