import React, { useState } from 'react'
import { Chart } from "react-google-charts";
import * as reportCrud from '../../../_crud/reportCrud'
import { EmptyData } from '../../../commonLib/EmptyData'
import { Spinner } from '../../../commonLib/Spinner'
import { useEffect } from 'react';
import GetLoginInfo from '../../../auth/_userContext/GetLoginInfo';
import { IsMobile } from '../../../initApi/apiUrls';
import { ContactCategoryReportMobile } from './ContactCategoryReportMobile';

export function ContactCategoryReport() {
    const [contactData, setContactData] = useState([])
    const [isLocalLoading, setIsLocalLoading] = useState(false)
    const [data, setData] = useState([["Contact", "Category Count"]])
    const userData = GetLoginInfo()

    useEffect(() => {
        doRefresh()
    }, [])

    const doRefresh = () => {
        if (userData.isWarehouse == 0)
            getData()
        else
            getDataWarehouse()
    }

    useEffect(() => {

        if (contactData && contactData.length > 0) {
            let pdata = [["Contact", "Category Count"]]
            contactData.forEach(element => {
                let cat = element.productionCategory ? (element.productionCategory.productionCatName + " - " + element.count) : "N/A - " + element.count
                pdata = [...pdata, [cat, element.count]]
            });
            setData([...pdata])
        }
    }, [contactData])

    function getData() {
        setIsLocalLoading(true);
        reportCrud.GetContactByCategory()
            .then(res => {
                if (res.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setContactData(hdata);
                        setIsLocalLoading(false)
                    }
                    else {
                        setIsLocalLoading(false)
                        setContactData([]);
                    }
                }
                else {
                    setIsLocalLoading(false)
                    setContactData([]);
                }
            })
            .catch(error => {
                setIsLocalLoading(false);
            })
    }


    function getDataWarehouse() {
        setIsLocalLoading(true);
        reportCrud.GetContactByCategoryByWarehouse(userData.warehouseId)
            .then(res => {
                if (res.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setContactData(hdata);
                        setIsLocalLoading(false)
                    }
                    else {
                        setIsLocalLoading(false)
                        setContactData([]);
                    }
                }
                else {
                    setIsLocalLoading(false)
                    setContactData([]);
                }
            })
            .catch(error => {
                setIsLocalLoading(false);
            })
    }
    return (
        <>
            <div className="widget-box">
                <div class="widget-header widget-header-flat widget-header-small">
                    <h5 class="widget-title">
                        Category Wise Contact
                    </h5>
                    <div class="widget-toolbar">
                        <a onClick={doRefresh} title="Reload">
                            <i class="ace-icon fa fa-refresh"></i>
                        </a>
                    </div>
                </div>
                <div className="row">
                    {isLocalLoading ?
                        <Spinner />
                        :
                        contactData && contactData.length > 0 ?
                            <div class="profile-user-info profile-user-info-striped">
                                {IsMobile ?
                                    <ContactCategoryReportMobile contactData={contactData} />
                                    :
                                    <Chart
                                        chartType="PieChart"
                                        data={data}
                                        width={"100%"}
                                        height={"100%"}
                                    />
                                }

                                <div id="accordion" class="accordion-style1">

                                    <div class="panel panel-default" style={{ marginBottom: "0" }}>
                                        <div class="panel-heading">
                                            <h4 class="panel-title">
                                                <a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
                                                    <i class="ace-icon fa fa-angle-right bigger-110" data-icon-hide="ace-icon fa fa-angle-down" data-icon-show="ace-icon fa fa-angle-right"></i>
                                                    &nbsp;Show details
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="panel-collapse collapse" id="collapseTwo">
                                            <table class="table table-bordered table-striped">
                                                <thead class="thin-border-bottom">
                                                    <tr>
                                                        <th>
                                                            Category
                                                        </th>

                                                        <th>
                                                            Count
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {contactData.map((item, index) =>
                                                        <tr>
                                                            <td>{item.productionCategory ? (item.productionCategory.productionCatName) : "N/A"}</td>

                                                            <td>
                                                                <b class="green">{item.count}</b>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            :
                            <EmptyData>
                                <h1>No Data</h1>
                                <br />
                            </EmptyData>
                    }
                </div>
            </div>
        </>
    )
}
