import React, { useContext, useEffect, useState } from 'react'
import * as voucherCrud from '../../../_crud/voucherCrud'
import * as reportCrud from '../../../_crud/reportCrud'
import { EmptyData } from '../../../commonLib/EmptyData'
import { GetELedgerTypeName } from '../../../enumCollections/ELedgerType'
import { GetELedgerGroupName } from '../../../enumCollections/ELedgerGroup'
import { Container } from '../../../base/Container'
import { PagingEmpty } from '../../../commonLib/PagingEmpty'
import { Spinner } from '../../../commonLib/Spinner'
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext'
import AppToaster from '../../../commonLib/AppToaster'


export function LedgerSummary() {
  const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
  const [fiscalYearSelected, setFiscalYearSelected] = useState()
  const [fiscalYear, setFiscalYear] = useState([])
  const [ledgerSummary, setLedgerSummary] = useState([])


  useEffect(() => {
    GetFiscalYears()
  }, []);

  useEffect(() => {
    if (fiscalYear && fiscalYear.length > 0) {
      GetLedgerSummary(fiscalYear[0])
      setFiscalYearSelected(fiscalYear[0])
    }
  }, [fiscalYear]);

  function GetFiscalYears() {
    setIsLoadingMini(true);
    voucherCrud.GetFiscalYears(10)
      .then(res => {
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            setFiscalYear(hdata);
          }
          else {
            setFiscalYear([]);
            AppToaster("Server Error /Invalid Try.", 'error')
          }
        }
        else {
          setFiscalYear([]);
        }
        setIsLoadingMini(false);
      })
      .catch(error => {
        setIsLoadingMini(false);
        AppToaster(error, 'error')
      })
  }

  function GetLedgerSummary(fy) {
    setIsLoadingMini(true);
    reportCrud.GetLedgerSummary(fy)
      .then(res => {
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            setLedgerSummary(hdata);
          }
          else {
            setLedgerSummary([]);
            AppToaster("Server Error /Invalid Try.", 'error')
          }
        }
        else {
          setLedgerSummary([]);
        }
        setIsLoadingMini(false);
      })
      .catch(error => {
        setIsLoadingMini(false);
        AppToaster(error, 'error')
      })
  }

  function doRefresh() {
    GetLedgerSummary(fiscalYearSelected)
  }

  return (
    <>
      <Container title="Ledger Summary" doRefresh={doRefresh}>

        <div class="form-horizontal" >
          <div className="row">

            <div className="col-md-2">
              <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                <select className="form-control" value={fiscalYearSelected} onChange={(e) => setFiscalYearSelected(e.target.value)}>
                  {
                    fiscalYear.map((item, index) =>
                      <option key={index} value={item}>{item}</option>
                    )}
                </select>
              </div>
            </div>

            <div className="col-md-1">
              <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                <button onClick={doRefresh} to="/order/add" class="btn btn-sm btn-info pull-right">
                  <i class="ace-icon fa fa-search bigger-110"></i>
                  Run
                </button>
              </div>
            </div>
          </div>
        </div>

        <PagingEmpty text={"Total " + ledgerSummary.length + " items."}>
          {isLoadingMini ?
            <Spinner />
            :
            ledgerSummary && ledgerSummary.length > 0 ?
              <div className="tables-scroll">
                <table className="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Ledger Name</th>
                      <th>Type</th>
                      <th>Group</th>
                      <th>Cr</th>
                      <th>Dr</th>
                      <th>Balance</th>

                    </tr>
                  </thead>

                  <tbody>
                    {ledgerSummary.map((item, index) =>
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item.ledger.ledgerName.toUpperCase()}</td>
                        <td> {GetELedgerTypeName(item.ledger.type)}</td>
                        <td> {GetELedgerGroupName(item.ledger.group)}</td>
                        <td> {Number(item.cr).toFixed(2)}</td>
                        <td> {Number(item.dr).toFixed(2)}</td>
                        <td> {item.cr - item.dr < 0 ? <b className="green">{Number(item.dr - item.cr).toFixed(2)} Dr</b> : <b className="blue">{Number(item.cr - item.dr).toFixed(2)} Cr</b>}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              :
              <EmptyData>
                No records found.
                <br />
                <br />
              </EmptyData>
          }
        </PagingEmpty>
      </Container >
    </>
  )
}
