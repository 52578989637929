import React from 'react'
import Moment from 'react-moment'
import { getPageMargins } from '../../config/GlobalSettings'
import { GetEBoolName } from '../../enumCollections/EBool'
import { GetEOrderStatusName } from '../../enumCollections/EOrderStatus'
import { ContactViewS } from '../Global/Elements/ContactViewS'
import { ProductionUnitViewS } from '../Global/Elements/ProductionUnitViewS'
import { ProductViewS } from '../Global/Elements/ProductViewS'
import { WarehouseViewS } from '../Global/Elements/WarehouseViewS'
import { PrintBranding } from './PrintBranding'

export const OrderPrint = React.forwardRef((props, ref) => {
    const data = props.printData
    return (
        <div ref={ref}>
            <style type="text/css">
                {getPageMargins()}
            </style>
            {
                data &&
                <PrintBranding printName="Order" date={data.onDate} id={data.id}>
                    {
                        data &&
                        <div className="row">
                            <div className="col-xs-12">

                                <table style={{ width: "100%" }} className="table-bordered-dark">
                                    <tbody>
                                        <tr >
                                            <td>Due Date</td>
                                            <td>
                                                <Moment format="DD-MMM-YYYY">{data.dueDate}</Moment>
                                            </td>
                                            <td>
                                                <span className="pull-right">
                                                    <WarehouseViewS item={data.warehouse} />
                                                </span>
                                            </td>
                                        </tr>
                                        <tr >
                                            <td>Alloted To</td>
                                            <td colspan="2">
                                                <ContactViewS item={data.toContact} />
                                            </td>
                                        </tr>
                                        <tr >
                                            <td>Production</td>
                                            <td colspan="2">
                                                <ProductionUnitViewS item={data.toProductionUnit} />
                                            </td>
                                        </tr>
                                        <tr >
                                            <td>Product</td>
                                            <td colspan="2">
                                                <ProductViewS item={data.product} />
                                            </td>
                                        </tr>
                                        <tr >
                                            <td>Quantity</td>
                                            <td colspan="2">{data.quantity} {data.unit}</td>
                                        </tr>
                                        <tr >
                                            <td>Alloted For</td>
                                            <td colspan="2">
                                                {data.isForProductionUnit == 1 && data.contact ?
                                                    <ContactViewS item={data.contact} />
                                                    :
                                                    "NA"
                                                }
                                            </td>
                                        </tr>
                                        <tr >
                                            <td>Production</td>
                                            <td colspan="2">
                                                {data.isForProductionUnit == 1 ? (data.forProductionUnitData ?
                                                    <ProductionUnitViewS item={data.forProductionUnitData} />
                                                    : "NA") : <span class="label label-sm label-danger">{GetEBoolName(data.isForProductionUnit)}</span>}
                                            </td>
                                        </tr>

                                        <tr >
                                            <td>Status</td>
                                            <td colspan="2">
                                                {GetEOrderStatusName(data.status)}
                                            </td>
                                        </tr>
                                        {data.processingDistribution &&
                                            <tr >
                                                <td>Distribution</td>
                                                <td colspan="2">
                                                    Id: <b>{data.processingDistribution.id}</b>, To Contact: <b>{data.processingDistribution.contact.fullName}({data.processingDistribution.contact.id})</b>, For Processing: <b>{data.processingDistribution.processing.name}</b>
                                                </td>
                                            </tr>
                                        }

                                        <tr >
                                            <td>Details</td>
                                            <td colspan="2">
                                                {data.details ? data.details : "NA"}
                                            </td>
                                        </tr>
                                        {data.designItems && data.designItems.length > 0 &&
                                            <>
                                                {data.designItems.map((d, i) =>
                                                    <tr >
                                                        <td>{d.name}</td>
                                                        <td colspan="2">
                                                            {d.value} - {d.text && <small>{d.text}</small>}
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        }
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    }
                </PrintBranding>
            }
        </div >
    )
})