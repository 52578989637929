import React, { useContext, useState } from 'react'
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { GetEBoolName } from '../../../enumCollections/EBool';
import { ContactView } from '../../Global/Elements/ContactView';
import { ProcessingView } from '../../Global/Elements/ProcessingView';
import { ProductionUnitView } from '../../Global/Elements/ProductionUnitView';
import { ProductView } from '../../Global/Elements/ProductView';
import { UserView } from '../../Global/Elements/UserView';

export function ProcessingReturnItemView({ processingReturn }) {

    return (
        <>
            <div className="widget-box">
                <div class="widget-header widget-header-flat widget-header-small">
                    <h5 class="widget-title">
                        Details
                    </h5>
                </div>
                {
                    processingReturn &&
                    <div className="row">
                        <div class="profile-user-info profile-user-info-striped">
                            <div class="profile-info-row">
                                <div class="profile-info-name"> Return Id</div>

                                <div class="profile-info-value">
                                    <span ><b>{processingReturn.id}</b></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> On Date</div>

                                <div class="profile-info-value">
                                    <span ><b><Moment format="DD-MMM-YYYY">{processingReturn.onDate}</Moment></b></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Contact </div>

                                <div class="profile-info-value">
                                    <Link to={"/contact/dashboard/" + processingReturn.contactId}>
                                        <ContactView item={processingReturn.contact} uid={"GC1"} />
                                    </Link>
                                </div>
                            </div>


                            <div class="profile-info-row">
                                <div class="profile-info-name"> Processing </div>

                                <div class="profile-info-value">
                                    <Link to={"/processing/dashboard/" + processingReturn.processingId}>
                                        <ProcessingView item={processingReturn.processing} />
                                    </Link>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Production </div>

                                <div class="profile-info-value">
                                    {processingReturn.isProductionUnit == 1 ? (processingReturn.productionUnit ? <Link to={"/productionunit/dashboard/" + processingReturn.productionUnitId}>
                                        <ProductionUnitView item={processingReturn.productionUnit} />
                                    </Link> : "NA") : <span class="label label-sm label-danger">{GetEBoolName(processingReturn.isProductionUnit)}</span>}
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Product </div>

                                <div class="profile-info-value">
                                    <Link to={"/product/dashboard/" + processingReturn.product.id}>
                                        <ProductView item={processingReturn.product} />
                                    </Link>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Fresh </div>

                                <div class="profile-info-value">
                                    <span >{processingReturn.freshQuantity} {processingReturn.unit}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Defective </div>

                                <div class="profile-info-value">
                                    <span >{processingReturn.defectiveQuantity} {processingReturn.unit}</span>
                                </div>
                            </div>


                            <div class="profile-info-row">
                                <div class="profile-info-name"> Rejected </div>

                                <div class="profile-info-value">
                                    <span >{processingReturn.rejectedQuantity} {processingReturn.unit}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Note </div>

                                <div class="profile-info-value">
                                    <span >{processingReturn.note}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Created By </div>
                                <div class="profile-info-value">
                                    <span><UserView item={processingReturn.createdByUser} uid={"GD1"} createdOn={processingReturn.createdOn} /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

