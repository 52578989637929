import React, { useContext, useEffect, useState } from 'react'
import { StatusView } from '../../../commonLib/StatusView'
import { UserView } from '../../Global/Elements/UserView'
import { GetEPaymentStatusName } from '../../../enumCollections/EPaymentStatus'
import { ContactView } from '../../Global/Elements/ContactView'
import { Link, useParams } from 'react-router-dom'
import DatePicker from "react-datepicker";
import GetCurrentDateForSearch from '../../../commonLib/dateFormat'
import { GetELedgerAsset, GetELedgerAssetName } from '../../../enumCollections/ELedgerAsset'
import * as advanceVoucherCrud from '../../../_crud/vouchers/advanceVoucherCrud';
import { PagingEmpty } from '../../../commonLib/PagingEmpty'
import { MyAlert } from '../../../commonLib/MyAlert'
import AppToaster from '../../../commonLib/AppToaster'
import { EmptyData } from '../../../commonLib/EmptyData'
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext'
import Moment from 'react-moment'
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater'

export function AdvanceItemView({ paid, pending, advanceVoucher, voucherPayment, doRefresh }) {
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const { id } = useParams()
    const [empty, setEmpty] = useState(false);

    const initItems = {
        voucherId: id,
        receivedDate: new Date(GetCurrentDateForSearch(0)),
        amount: 0,
        fiscalYear: "a",
        fromLedgerId: 6,
        note: ""
    }
    const [advanceItem, setAdvanceItem] = useState(initItems);

    useEffect(() => {
        setEmpty(false)
    }, [empty]);

    function setAmount() {
        if (advanceItem.amount) {
            if (advanceItem.amount > pending) {
                setAdvanceItem({ ...advanceItem, amount: pending })
            }
            else if (advanceItem.amount < 0) {
                setAdvanceItem({ ...advanceItem, amount: 0 })
            }
        }
    }

    useEffect(() => {
        setAmount()
    }, [advanceItem.amount]);

    const AddItem = () => {
        if (advanceItem.amount > 0) {
            setIsLoadingMini(true);
            setIsLoadingLocal(true)
            advanceVoucherCrud.AddVoucherPayment(advanceItem)
                .then(res => {
                    if (res.data.succeeded) {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false);
                        AppToaster('Added successfully.', 'success')
                        doRefresh()
                    }
                    else {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                })
                .catch(error => {
                    setIsLoadingMini(false);
                    setIsLoadingLocal(false);
                    AppToaster(error, 'error')
                })
        }
        else {
            AppToaster("Amount should be greater than zero.", 'error')
        }
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        advanceVoucherCrud.RemoveVoucherPayment(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    doRefresh()
                    AppToaster('Deleted successfully.', 'success')
                    doRefresh()
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by someone,could not delete.', 'error')
                }
            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }

    return (
        <>
            <div className="widget-box">
                <div class="widget-header widget-header-flat widget-header-small">
                    <h5 class="widget-title">
                        Details
                    </h5>
                </div>
                {
                    advanceVoucher &&
                    <div className="row">
                        <div class="profile-user-info profile-user-info-striped">
                            <div class="profile-info-row">
                                <div class="profile-info-name"> Voucher Id</div>

                                <div class="profile-info-value">
                                    <span ><b>{advanceVoucher.id}</b></span>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name"> Voucher Date</div>

                                <div class="profile-info-value">
                                    <Moment format="DD-MMM-YYYY">{advanceVoucher.voucherDate}</Moment>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name"> For Contact </div>

                                <div class="profile-info-value">
                                    <Link to={"/contact/dashboard/" + advanceVoucher.contact.id}>
                                        <ContactView item={advanceVoucher.contact} uid={"CXV1"} />
                                    </Link>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name">Phone</div>

                                <div class="profile-info-value">
                                    <span >{advanceVoucher.contact.phone}</span>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name">Address</div>

                                <div class="profile-info-value">
                                    <span >{advanceVoucher.contact.address}</span>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name">Email</div>

                                <div class="profile-info-value">
                                    <span >{advanceVoucher.contact.email}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name">Amount</div>
                                <div class="profile-info-value">
                                    <span ><b>₹{Number(advanceVoucher.totalAmount).toFixed(2)}</b></span>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name"> <b>Voucher Balance</b> </div>

                                <div class="profile-info-value">
                                    <span>Paid: <b className="green">₹{Number(paid).toFixed(2)}</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pending: <b className="red">₹{Number(pending).toFixed(2)}</b> </span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name">Status </div>

                                <div class="profile-info-value">
                                    <span><StatusView status={advanceVoucher.paymentStatus}>{GetEPaymentStatusName(advanceVoucher.paymentStatus)}</StatusView></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Created By </div>

                                <div class="profile-info-value">
                                    <span><UserView item={advanceVoucher.createdByUser} uid={"GD1"} createdOn={advanceVoucher.createdOn} /></span>
                                </div>
                            </div>

                        </div>
                    </div>
                }
            </div>
            <br />
            <div className="widget-box">
                <div class="widget-header widget-header-flat widget-header-small">
                    <h5 class="widget-title">
                        Voucher Payments
                    </h5>
                </div>
                <div className="row" >
                    <div class="col-xs-12">
                        <div className="col-md-3">
                            <div class="form-group">
                                <label class="control-label no-padding-right">Date</label>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker placeholder="Received Date" name="receivedDate" className="form-control input-date-padding" selected={advanceItem.receivedDate} onChange={(date) => setAdvanceItem({ ...advanceItem, receivedDate: date })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div class="form-group">
                                <label class="control-label no-padding-right"> Amount</label>
                                <input className="form-control" type="number" placeholder="Amount" value={advanceItem.amount} onChange={(e) => setAdvanceItem({ ...advanceItem, amount: e.target.value })} />
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div class="form-group">
                                <label class="ontrol-label no-padding-right">By</label>
                                <select className="form-control" value={advanceItem.fromLedgerId} onChange={(e) => setAdvanceItem({ ...advanceItem, fromLedgerId: e.target.value })}>
                                    {
                                        GetELedgerAsset.ELedgerAsset.map((data, index) =>
                                            <option key={data.Id} value={data.Id}>{data.Name}</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div class="form-group">
                                <label class="ontrol-label no-padding-right">Note</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="Note" value={advanceItem.note} onChange={(e) => setAdvanceItem({ ...advanceItem, note: e.target.value })} />
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div class="form-group"><br />
                                <label class="control-label">&#160;</label>
                                {isLoadingLocal ?
                                    <button class="btn btn-xs btn-success" type="submit" expand="block" disabled>
                                        <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i> Wait...
                                    </button>
                                    :
                                    <button class="btn btn-xs btn-success" type="submit" expand="block" onClick={AddItem}>
                                        <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>Pay
                                    </button>
                                }
                            </div>
                        </div>
                    </div>

                </div>
                <br />
                <div className="row">
                    <div className="col-xs-12">
                        <PagingEmpty text={"Total " + voucherPayment.length + " items."}>
                            {voucherPayment && voucherPayment.length > 0 ?
                                <div className="tables-scroll">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>Amount</th>
                                                <th>By</th>
                                                <th>Note</th>
                                                <th></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {voucherPayment.map((item, index) =>
                                                <tr key={item.Id}>
                                                    <td>{index + 1}</td>
                                                    <td><Moment format="DD-MMM-YYYY">{item.receivedDate}</Moment></td>
                                                    <td><b>₹ {item.amount}</b></td>
                                                    <td>{GetELedgerAssetName(parseInt(item.fromLedgerId))}</td>
                                                    <td>{item.note}</td>
                                                    <td>
                                                        <div class="btn-group">
                                                            <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                                <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                            </MyAlert>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <EmptyData>
                                    No Item Added.
                                    <br />
                                    <br />
                                </EmptyData>
                            }
                        </PagingEmpty>
                    </div>
                </div>
            </div>
        </>
    )
}
