import React from 'react'
import { Container } from '../../base/Container'
import { OrganizationEdit } from './OrganizationEdit'
import { OrganizationLogo } from './OrganizationLogo'
import { OrgId } from '../../config/GlobalSettings'

export function OrganizationDashboard() {
    return (
        <Container title={"Organization Profile" + " (OrgId: " + OrgId() + ")"} >
            <div class="tabbable">
                <ul class="nav nav-tabs" id="myTab">
                    <li class="active">
                        <a data-toggle="tab" href="#home">
                            Profile
                        </a>
                    </li>

                    <li>
                        <a data-toggle="tab" href="#messages">
                            Logo
                        </a>
                    </li>

                </ul>

                <div class="tab-content">
                    <div id="home" class="tab-pane fade in active">
                        <OrganizationEdit />
                    </div>

                    <div id="messages" class="tab-pane fade">
                        <OrganizationLogo />
                    </div>
                </div>
            </div>
        </Container>
    )
}
