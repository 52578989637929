import React, { useContext, useEffect, useRef, useState } from 'react'
import AppToaster from '../../../commonLib/AppToaster';
import *as reportCrud from '../../../_crud/reportCrud'
import *as warehouseCrud from '../../../_crud/warehouse/warehouseCrud'
import { Spinner } from '../../../commonLib/Spinner';
import { Link } from 'react-router-dom';
import { Container } from '../../../base/Container';
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import { PAGING_PAGE_SIZE } from '../../../config/GlobalSettings';
import { Paging } from '../../../commonLib/Paging';
import { EmptyData } from '../../../commonLib/EmptyData';
import DatePicker from "react-datepicker";
import GetCurrentDateForSearch from '../../../commonLib/dateFormat';
import { ProductView } from '../Elements/ProductView';
import { ProcessingView } from '../Elements/ProcessingView';

const initValues = {
    Type: 1,
    IsWarehouse: 0,
    WarehouseId: -1,
    Search: {
        SearchTerm: "",
        PageNo: 1,
        PageSize: PAGING_PAGE_SIZE
    },
    Range: {
        fromDate: new Date(GetCurrentDateForSearch(0)),
        toDate: new Date(GetCurrentDateForSearch(0))
    }
}

export function ProcessingDailyReport() {
    const [search, setSearch] = useState(initValues)
    const [product, setProduct] = useState([])
    const [LastPage, setLastPage] = useState(1)
    const [pagingData, setPagingData] = useState()
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [warehouse, setWarehouse] = useState([])

    useEffect(() => {
        getWarehouse()
    }, [])

    useEffect(() => {
        getData()
    }, [search.Search.PageNo])

    useEffect(() => {
        if (search.WarehouseId == -1) {
            setSearch({ ...search, IsWarehouse: 0 })
        }
        else {
            setSearch({ ...search, IsWarehouse: 1 })
        }
    }, [search.WarehouseId]);

    const OnPageChange = (e) => {
        const { value } = e.target
        setSearch({ ...search, Search: { ...search.Search, PageNo: value } });
    }

    function OnSearch() {
        getData()
    }

    function getData() {
        setIsLoadingMini(true);
        reportCrud.GetDailyProcessingReport(search)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setProduct(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoadingMini(false)
                        setProduct([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoadingMini(false)
                    setProduct([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        setSearch({ ...search, Search: { ...search.Search, PageNo: 1 } })
        getData()
        setTimeout(() => {
            event.target.complete();
        }, 1000);
    }

    function getWarehouse() {
        setIsLoadingMini(true);
        warehouseCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setWarehouse(hdata);
                        //alert(JSON.stringify(hdata))
                    }
                    else {
                        setWarehouse([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setWarehouse([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }
    return (
        <>
            <Container title="Processing Daily Report" doRefresh={doRefresh}>

                <div class="form-horizontal" >
                    <div className="row">
                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker selectsStart startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.fromDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, fromDate: date } })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker selectsEnd startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.toDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, toDate: date } })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <select className="form-control" value={search.Type} onChange={(e) => setSearch({ ...search, Type: e.target.value })}>
                                    <option value="1" >Distribution</option>
                                    <option value="2" >Receiving</option>
                                    <option value="3" >Return</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <select className="form-control" value={search.WarehouseId} onChange={(e) => setSearch({ ...search, WarehouseId: e.target.value })}>
                                    <option value="-1" >All</option>
                                    <option value="0" >MAIN Warehouse</option>
                                    {
                                        warehouse.map((data, index) =>
                                            <option key={data.id} value={data.id}>{data.name.toUpperCase()} ({data.warehouseCode.toUpperCase()})</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="col-md-1">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <button onClick={OnSearch} class="btn btn-sm btn-info pull-right">
                                    <i class="ace-icon fa fa-search bigger-110"></i>
                                    Run
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
                <Paging
                    pagingData={pagingData}
                    LastPage={LastPage}
                    CurrentPage={search.Search.PageNo}
                    OnPageChange={OnPageChange} >
                    {isLoadingMini ?
                        <Spinner />
                        :
                        product && product.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th>#Id</th>
                                            <th>Product</th>
                                            <th>Processing</th>
                                            <th>Count</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {product.map((item, index) =>
                                            <tr key={item.productId}>
                                                <td>{item.productId}</td>
                                                <td>
                                                    <Link to={"/product/dashboard/" + item.productId}>
                                                        <ProductView item={item.product} uid={"PP2" + index} />
                                                    </Link>
                                                </td>
                                                <td>
                                                    <Link to={"/processing/dashboard/" + item.processingId} >
                                                        <ProcessingView item={item.processing} />
                                                    </Link>
                                                </td>
                                                <td><h3 style={{ margin: "0" }}>{Number(item.count).toFixed(2)} {item.product.unit}</h3>{item.note && item.note}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </Paging>
            </Container >
        </>
    )
}
