import React, { useContext, useEffect, useState } from 'react'
import Autocomplete from "react-autocomplete";
import './../Global/AutoComplete.css';
import * as contactCrud from '../../_crud/contactCrud'
import useDebounce from './useDebounce';
import { useHistory } from 'react-router-dom';
import GridLoader from "react-spinners/GridLoader";
import { DEFAULT_IMG, ERROR_IMG } from '../../config/GlobalSettings';
import { BASE_URL } from '../../initApi/apiUrls';
import { Form } from 'react-bootstrap';
import AppToaster from '../../commonLib/AppToaster';

export function OrderSearch() {
    const [orderId, setOrderId] = useState("");
    const history = useHistory()

    useEffect(() => {

    }, []);
    const OnSubmit = (e) => {
        e.preventDefault();
        if (orderId) {
            setOrderId("")
            history.replace("/order/dashboard/" + orderId)
            history.push('/loading');
            history.goBack();
        }
        else
            AppToaster("Please enter order no.", "error")
    }
    return (
        <>
            <div class="nav-search">
                <Form onSubmit={OnSubmit} class="form-search">
                    <span class="input-icon pull-right" style={{ width: "118px" }}>
                        <input type="text" value={orderId} onChange={(e) => setOrderId(e.target.value)} class="form-control" placeholder="Type order no" style={{ height: "29px" }} />
                        <i class="ace-icon fa fa-level-up blue "></i>
                    </span>
                </Form>
            </div>
        </>
    )
}