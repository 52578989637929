import React, { useContext } from 'react'
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { organizationContext } from '../pages/Organization/organizationContext';

export function ExpireMessage() {
    const [organization, setOrganization] = useContext(organizationContext);
    return (
        <div class="well">
            <h1 class="grey lighter smaller">
                <span class="red bigger-125">
                    <i class="ace-icon fa fa-random"></i> Expired
                </span>
            </h1>

            <hr />
            <h3 class="lighter smaller red">
                Your plan has been valid till <b><Moment format="DD-MMM-YYYY">{organization.validTillDate}</Moment></b>

            </h3>

            <div class="space"></div>

            <div>
                <h4 class="lighter smaller">Meanwhile, try one of the following for renewal:</h4>

                <ul class="list-unstyled spaced inline bigger-110 margin-15">
                    <li>
                        <i class="ace-icon fa fa-hand-o-right blue"></i> Contact with Baithka team or seller.
                    </li>
                    <li>
                        <i class="ace-icon fa fa-hand-o-right blue"></i> Or click to visit recharge center <Link to="/rechargeplans">Recharge Now</Link>.
                    </li>
                    <li>
                        <i class="ace-icon fa fa-hand-o-right blue"></i> Visit to <a href="https://baithka.com" target="_blank">www.baithka.com</a> or <a href="https://cubicle8.com" target="_blank">www.cubicle8.com</a>.
                    </li>
                </ul>
            </div>
        </div>
    )
}
