import GetLoginInfo from "./GetLoginInfo";
import Keys from "./Keys";
var CryptoJS = require("crypto-js");

const SetOrganizationId = (id) => {
    var ciphertext = CryptoJS.AES.encrypt(id, Keys).toString();
    localStorage.setItem('ot', ciphertext);
}

const GetOrganizationId = () => {
    let userData = GetLoginInfo()
    try {
        if (userData) {
            return userData.orgId
        }
        else {
            let data;
            data = localStorage.getItem('ot');
            var bytes = CryptoJS.AES.decrypt(data, Keys);
            var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedData;
        }
    } catch (error) {
        return null;
    }
}

export { SetOrganizationId, GetOrganizationId }