import React from 'react'

export function PagingEmpty(props) {

    const { text } = props;

    return (
        <>
            <div className="message-footer clearfix" style={{ padding: "0px 16px" }}>
                <div className="pull-left">
                    <h5 style={{ marginTop: "12px" }}>
                        {text &&
                            text
                        }
                    </h5>
                </div>


            </div>

            {props.children}

            <div className="message-footer clearfix" style={{ padding: "0px 16px", marginTop: "-20px" }}>
                <div className="pull-left">
                    <h5 style={{ marginTop: "12px" }}>
                        {text &&
                            text
                        }
                    </h5>
                </div>


            </div>
        </>


    )
}
