import React, { useContext, useState } from 'react'
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { GetEBoolName } from '../../../enumCollections/EBool';
import { ContactView } from '../../Global/Elements/ContactView';
import { ProcessingView } from '../../Global/Elements/ProcessingView';
import { ProductionUnitView } from '../../Global/Elements/ProductionUnitView';
import { ProductView } from '../../Global/Elements/ProductView';
import { UserView } from '../../Global/Elements/UserView';

export function ProductReturnItemView({ productReturn }) {

    return (
        <>
            <div className="widget-box">
                <div class="widget-header widget-header-flat widget-header-small">
                    <h5 class="widget-title">
                        Details
                    </h5>
                </div>
                {
                    productReturn &&
                    <div className="row">
                        <div class="profile-user-info profile-user-info-striped">
                            <div class="profile-info-row">
                                <div class="profile-info-name"> Return Id</div>

                                <div class="profile-info-value">
                                    <span ><b>{productReturn.id}</b></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> On Date</div>

                                <div class="profile-info-value">
                                    <span ><b><Moment format="DD-MMM-YYYY">{productReturn.onDate}</Moment></b></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Contact </div>

                                <div class="profile-info-value">
                                    <Link to={"/contact/dashboard/" + productReturn.contactId}>
                                        <ContactView item={productReturn.contact} uid={"GC1"} />
                                    </Link>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Production </div>

                                <div class="profile-info-value">
                                    <Link to={"/productionunit/dashboard/" + productReturn.productionUnitId}>
                                        <ProductionUnitView item={productReturn.productionUnit} />
                                    </Link>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Product </div>

                                <div class="profile-info-value">
                                    <Link to={"/product/dashboard/" + productReturn.product.id}>
                                        <ProductView item={productReturn.product} />
                                    </Link>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Fresh </div>

                                <div class="profile-info-value">
                                    <span >{productReturn.freshQuantity} {productReturn.unit}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Defective </div>

                                <div class="profile-info-value">
                                    <span >{productReturn.defectiveQuantity} {productReturn.unit}</span>
                                </div>
                            </div>


                            <div class="profile-info-row">
                                <div class="profile-info-name"> Rejected </div>

                                <div class="profile-info-value">
                                    <span >{productReturn.rejectedQuantity} {productReturn.unit}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Fresh To Main </div>

                                <div class="profile-info-value">
                                    <span class={productReturn.isFreshQuantityToMain == 1 ? "label label-sm label-success" : "label label-sm label-danger"}>{GetEBoolName(productReturn.isFreshQuantityToMain)}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Note </div>

                                <div class="profile-info-value">
                                    <span >{productReturn.note}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Created By </div>
                                <div class="profile-info-value">
                                    <span><UserView item={productReturn.createdByUser} uid={"GD1"} createdOn={productReturn.createdOn} /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}


