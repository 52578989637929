import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Container } from '../../../base/Container'
import AppToaster from '../../../commonLib/AppToaster'
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext'
import { TimelineHistory } from '../../Global/TimelineHistory'
import *as productReceivingCrud from '../../../_crud/productReceivingCrud'
import { ProductReceivingItemView } from './ProductReceivingItemView'
import { loadingContext } from '../../../commonLib/_lodingContext'

export function ProductReceivingDashBoard() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [productReceiving, setProductReceiving] = useState()
    const { id } = useParams()

    function doRefresh() {
        getData()
    }

    useEffect(() => {
        getData()
    }, []);


    function getData() {
        setIsLoading(true);
        setIsLoadingMini(true);
        productReceivingCrud.GetById(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProductReceiving(hdata);
                        setIsLoading(false)
                        setIsLoadingMini(false)
                    }
                    else {
                        setIsLoading(false)
                        setIsLoadingMini(false)
                        setProductReceiving([]);
                    }
                }
                else {
                    setIsLoading(false)
                    setIsLoadingMini(false)
                    setProductReceiving([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoading(false);
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }
    return (
        <Container title="Product Receiving Dashboard" doRefresh={doRefresh} isBack="true" >


            <div className="row">
            <div className="col-lg-8">
                <ProductReceivingItemView productReceiving={productReceiving} />
                </div>
                <div className="col-lg-4">
                    <TimelineHistory id={id} type={3} pageSize={10} text="Product Receiving Timeline" />
                </div>

            </div>
        </Container >
    )
}

