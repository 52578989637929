import React, { useEffect, useState } from 'react'
import AppToaster from '../../../commonLib/AppToaster';
import { EmptyData } from '../../../commonLib/EmptyData';
import { Spinner } from '../../../commonLib/Spinner';
import * as reportCrud from './../../../_crud/reportCrud';

export function PayableForAll() {
    const [data, setData] = useState()
    const [cash, setCash] = useState()
    const [isLocalLoading, setIsLocalLoading] = useState(false)

    useEffect(() => {
        getPayable()
    }, [])

    function getPayable() {
        setIsLocalLoading(true);
        reportCrud.GetPayableAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setData(hdata);
                        let hcash = res.data.cash
                        setCash(hcash)
                    }
                    else {
                        setData();
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setData();
                }
                setIsLocalLoading(false);
            })
            .catch(error => {
                setData();
                setIsLocalLoading(false);
                AppToaster(error, 'error')
            })
    }

    return (

        <>
            <div className="widget-box">
                <div class="widget-header widget-header-flat widget-header-small">
                    <h5 class="widget-title">
                        Payable <small>(current fiscal year)</small>
                    </h5>
                    <div class="widget-toolbar">
                        <a onClick={getPayable} title="Reload">
                            <i class="ace-icon fa fa-refresh"></i>
                        </a>
                    </div>
                </div>
                <div className="row">
                    {isLocalLoading ?
                        <Spinner />
                        :
                        data ?
                            <div class="profile-user-info profile-user-info-striped">
                                <div class="btn btn-success" style={{ width: "50%" }}>

                                    <div class="infobox-data">
                                        <div class="infobox-content">Paid</div>
                                        <div class="infobox-content">₹ {Number(data.dr).toFixed(2)}</div>
                                    </div>
                                </div>

                                <div class="btn btn-warning" style={{ width: "50%" }}>
                                    <div class="infobox-data">
                                        <div class="infobox-content">Pending</div>
                                        <div class="infobox-content">₹ {Number(data.cr - data.dr).toFixed(2)}</div>
                                    </div>
                                </div>
                                <div class="btn btn-info" style={{ width: "50%" }}>
                                    <div class="infobox-data">
                                        <div class="infobox-content">Total</div>
                                        <div class="infobox-content">₹ {Number(data.cr).toFixed(2)}</div>
                                    </div>
                                </div>
                                {cash &&
                                    <div class="infobox infobox-blue2" style={{ width: "50%", textAlign: "center", height: "63px", minWidth: "50%" }}>
                                        <div class="infobox-data">
                                            <span class="infobox-data-number">₹ {Number(cash.dr - cash.cr).toFixed(2)}</span>
                                            <div class="infobox-content"><b>CASH IN HAND</b></div>
                                        </div>
                                    </div>
                                }

                            </div>
                            :
                            <EmptyData>
                                <h1>No Data</h1>
                            </EmptyData>
                    }
                </div>
            </div>
        </>
    )
}
