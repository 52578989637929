import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Container } from '../../../base/Container';
import AppToaster from '../../../commonLib/AppToaster';
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import { Spinner } from '../../../commonLib/Spinner';
import * as productionUnitCrud from '../../../_crud/productionUnitCrud'
import { GetEProductReceivingLotStatusName } from '../../../enumCollections/EProductReceivingLotStatus'
import { EmptyData } from '../../../commonLib/EmptyData';
import { ProductView } from '../../Global/Elements/ProductView';
import { PAGING_PAGE_SIZE } from '../../../config/GlobalSettings';
import { Paging } from '../../../commonLib/Paging';

const initValues = {
    searchTerm: "",
    pageNo: 1,
    pageSize: PAGING_PAGE_SIZE
}
export function ManageLots() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [lots, setLots] = useState([])
    const [search, setSearch] = useState(initValues)
    const [LastPage, setLastPage] = useState(1)
    const [pagingData, setPagingData] = useState()
    const { id, productionUnitId } = useParams()

    useEffect(() => {
        getData({ searchTerm: search.searchTerm, pageNo: 1, pageSize: search.pageSize })
    }, []);
    const OnPageChange = (e) => {
        const { value } = e.target
        setSearch({ ...search, pageNo: value });
        getData({
            searchTerm: search.searchTerm,
            pageNo: value,
            pageSize: search.pageSize
        })
    }
    function getData(searchparam) {
        setIsLoadingMini(true);
        productionUnitCrud.GetProductionLotsByContact({
            Search: {
                SearchTerm: searchparam.searchTerm,
                PageNo: searchparam.pageNo,
                PageSize: searchparam.pageSize
            },
            ContactId: id,
            ProducationUnitId: productionUnitId
        }).then(res => {
            if (res.data.data) {
                if (res.data.result.succeeded) {
                    let hdata = res.data.data
                    let paging = res.data.paging
                    setPagingData(paging)
                    setLastPage(paging.pageCount)
                    setLots(hdata);
                }
                else {
                    setLots([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            }
            else {
                setLots([]);
            }
            setIsLoadingMini(false);
        })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        getData()
        setTimeout(() => {
            event.detail.complete();
        }, 1000);
    }

    return (
        <>
            <Container title="Lots For Production" doRefresh={doRefresh} isBack="true" isContact="true" isProduction="true">
                <Paging
                    pagingData={pagingData}
                    LastPage={LastPage}
                    CurrentPage={search.pageNo}
                    OnPageChange={OnPageChange} >
                    {isLoadingMini ?
                        <Spinner />
                        :
                        lots && lots.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "43px" }}>#Id</th>
                                            <th>Product</th>
                                            <th>Fresh</th>
                                            <th>Defective</th>
                                            <th>Rejected</th>
                                            <th>Total</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {lots.map((item, index) =>
                                            <tr key={index + 1}>
                                                <td>{item.id}</td>
                                                <td>
                                                    <Link to={"/product/dashboard/" + item.productId}>
                                                        <ProductView item={item.product} uid={"PP" + index} />
                                                    </Link>
                                                </td>
                                                <td className="green">{Number(item.freshQuantity).toFixed(2)} {item.unit}</td>
                                                <td>{Number(item.defectiveQuantity).toFixed(2)} {item.unit}</td>
                                                <td className="red">{Number(item.rejectedQuantity).toFixed(2)} {item.unit}</td>
                                                <td className="bolder">{Number(item.freshQuantity + item.defectiveQuantity + item.rejectedQuantity).toFixed(2)} {item.unit}</td>
                                                <td><span class={item.forStock == 1 ? "label label-sm label-danger" : "label label-sm label-success"}>{GetEProductReceivingLotStatusName(item.status)}</span></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </Paging>
            </Container >
        </>
    )
}
