import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetAll(productProcessingDistribution) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingDistribution/GetAll", productProcessingDistribution)
    }
}
export async function GetByProduct(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingDistribution/GetByProduct", param)
    }
}

export async function GetByContact(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingDistribution/GetByContact", param)
    }
}

export async function GetByProductionUnit(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingDistribution/GetByProductionUnit", param)
    }
}

export async function GetByProductionUnitContact(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingDistribution/GetByProductionUnitContact", param)
    }
}

export async function GetById(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductProcessingDistribution/GetById?Id=" + id)
    }
}

export async function Add(productProcessingDistribution) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingDistribution/Add", productProcessingDistribution)
    }
}

export async function Remove(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingDistribution/Remove?Id=" + id)
    }
}
