import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../../base/Container'
import AppToaster from '../../commonLib/AppToaster';
import { loadingContext } from '../../commonLib/_lodingContext';
import * as productCrud from '../../_crud/productCrud';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { Link } from 'react-router-dom';
import { ProductView } from '../Global/Elements/ProductView';
import { ProcessingView } from '../Global/Elements/ProcessingView';
import GetLoginInfo from '../../auth/_userContext/GetLoginInfo';
import ProductSelector from '../Global/ProductSelector';
import { ProductSelectorView } from '../Global/SelectorView/ProductSelectorView';
import { Spinner } from '../../commonLib/Spinner';
import { EmptyData } from '../../commonLib/EmptyData';

export function ProductPriceCalculator() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [calculator, setCalculator] = useState()
    const [total, setTotal] = useState({ total: 0, material: 0, otherCharge: 0 })

    const [otherValue, setOtherValue] = useState({ lotUnits: 75, otherCharges: 0, mode: "flat" })

    const [selectedProductId, setSelectedProductId] = useState(undefined)
    const [selectedProductData, setSelectedProductData] = useState(undefined);
    const [empty, setEmpty] = useState(false);
    const userData = GetLoginInfo()

    useEffect(() => {
        if (selectedProductData) {
            setSelectedProductId(selectedProductData.id)
        }
    }, [selectedProductData]);

    useEffect(() => {
        if (selectedProductId)
            getProduct()
    }, [selectedProductId]);
    function getProduct() {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        productCrud.GetProductCalculatorData(selectedProductId)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setCalculator(hdata)
                    }
                    else {
                        AppToaster("Server Error /Invalid Try.", 'error')
                        setCalculator()
                        setTotal({ total: 0, material: 0, otherCharge: 0 })
                    }
                }
                else {
                    setCalculator()
                    setTotal({ total: 0, material: 0, otherCharge: 0 })
                }
                setIsLoadingMini(false)
                setIsLoadingLocal(false);
            })
            .catch(error => {
                setIsLoadingMini(false)
                setIsLoadingLocal(false);
                setCalculator()
                setTotal({ total: 0, material: 0, otherCharge: 0 })
                AppToaster(error, 'error')
            })
    }

    const Reset = () => {
        if (selectedProductId)
            getProduct()
        setOtherValue({ lotUnits: 75, otherCharges: 0, mode: "flat" })
    }
    const onChangeCunsumtionL1Consume = (e) => {
        const { id, value } = e.target
        let items = { ...calculator };
        let consumption = [...items.consumption]
        consumption[id].product.consumptionQuantity = value

        setCalculator({ ...items, consumption: [...consumption] })
    }

    const onChangeCunsumtionL1Cost = (e) => {
        const { id, value } = e.target
        let items = { ...calculator };
        let consumption = [...items.consumption]
        consumption[id].product.consumeProduct.cost = value

        setCalculator({ ...items, consumption: [...consumption] })
    }

    const onChangeCunsumtionL1Wage = (e) => {
        const { id, value } = e.target
        let items = { ...calculator };
        let consumption = [...items.consumption]
        consumption[id].product.product.wage = value

        setCalculator({ ...items, consumption: [...consumption] })
    }
    const onChangeProcessingL1 = (e) => {
        const { id, value } = e.target
        let items = { ...calculator };
        let processing = [...items.processing]

        processing[id].processing.defaultWage = value

        setCalculator({ ...items, processing: [...processing] })
    }

    const onChangeCunsumtionL2Consume = (e) => {
        const { id, value } = e.target
        let ids = id.split("|")
        let items = { ...calculator };
        let consumption = [...items.consumption]
        consumption[parseInt(ids[0])].consumption[parseInt(ids[1])].consumptionQuantity = value

        setCalculator({ ...items, consumption: [...consumption] })
    }
    const onChangeCunsumtionL2Cost = (e) => {
        const { id, value } = e.target
        let ids = id.split("|")
        let items = { ...calculator };
        let consumption = [...items.consumption]
        consumption[parseInt(ids[0])].consumption[parseInt(ids[1])].consumeProduct.cost = value

        setCalculator({ ...items, consumption: [...consumption] })
    }

    const onChangeProcessingL2Cost = (e) => {
        const { id, value } = e.target
        let ids = id.split("|")
        let items = { ...calculator };
        let consumption = [...items.consumption]
        consumption[parseInt(ids[0])].processing[parseInt(ids[1])].processing.defaultWage = value

        setCalculator({ ...items, consumption: [...consumption] })
    }
    const onChangeProductWage = (e) => {
        const { value } = e.target
        let items = { ...calculator };
        items.product.wage = value

        setCalculator({ ...items })
    }
    useEffect(() => {
        if (calculator) {

            let tol = 0
            if (calculator.consumption && calculator.consumption.length > 0)
                calculator.consumption.forEach(item => {
                    if (!(item.consumption && item.consumption.length > 0)) {
                        if (item.product.isPerUnitConsume == 0) {
                            tol = tol + ((parseFloat(item.product.consumeProduct.cost) * parseFloat(item.product.consumptionQuantity)) / parseFloat(otherValue.lotUnits))
                        }
                        else {
                            tol = tol + (parseFloat(item.product.consumeProduct.cost) * parseFloat(item.product.consumptionQuantity))
                        }
                    }
                    else {
                        let lv2 = 0
                        if (item.consumption && item.consumption.length > 0) {
                            item.consumption.forEach(itemL2 => {
                                lv2 = lv2 + (parseFloat(itemL2.consumptionQuantity) * parseFloat(itemL2.consumeProduct.cost))
                            })
                        }

                        let lv2processing = 0
                        if (item.processing && item.processing.length > 0) {
                            item.processing.forEach(itemL2 => {
                                lv2processing = lv2processing + parseFloat(itemL2.processing.defaultWage)
                            })
                        }

                        if (item.product.isPerUnitConsume == 0) {
                            tol = tol + ((parseFloat(item.product.product.wage) * parseFloat(item.product.consumptionQuantity)) / parseFloat(otherValue.lotUnits))
                            tol = tol + (lv2 / parseFloat(otherValue.lotUnits))
                            tol = tol + (lv2processing / parseFloat(otherValue.lotUnits))
                        }
                        else {
                            tol = tol + (parseFloat(item.product.product.wage) * parseFloat(item.product.consumptionQuantity))
                            tol = tol + lv2
                            tol = tol + lv2processing
                        }

                    }


                });

            let lv1processing = 0
            if (calculator.processing && calculator.processing.length > 0) {
                calculator.processing.forEach(item => {
                    lv1processing = lv1processing + parseFloat(item.processing.defaultWage)

                })
            }
            let all = tol + lv1processing
            let OtherCharge = 0;
            if (otherValue.mode == "%") {
                OtherCharge = (((all + parseFloat(calculator.product.wage)) * parseFloat(otherValue.otherCharges)) / 100)
            }
            else {
                OtherCharge = parseFloat(otherValue.otherCharges)
            }

            setTotal({ total: (all + OtherCharge + parseFloat(calculator.product.wage)), material: all, otherCharge: OtherCharge })
        }
    }, [calculator, otherValue]);
    return (
        <Container title="Cost Calculator" isBack={true}>
            {/* {calculator && JSON.stringify(calculator)} */}

            <div class="row">
                <div class="col-xs-12">
                    <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col-sm-6">
                            <label>Select Product</label>
                            <ProductSelector setEmpty={empty} searchtext="" setSelectedData={setSelectedProductData} isWarehouse={userData.isWarehouse == 0 ? false : true} />

                            <ProductSelectorView data={selectedProductData} />
                        </div>
                    </div>
                    {selectedProductId ?
                        <>
                            {isLoadingLocal ?
                                <Spinner />
                                :
                                <>
                                    {calculator ?
                                        <div class="row">
                                            <div class="col-sm-3"></div>
                                            <div class="col-sm-6">
                                                {calculator && calculator.product &&
                                                    <>
                                                        <h1>
                                                            {calculator.product.productName} <a title='Reset' style={{ cursor: "pointer", marginLeft: "5px" }} onClick={Reset}><i class="ace-icon fa fa-refresh green"></i></a>
                                                            <br />
                                                            <small>{calculator.product.productCategory.catName}</small>
                                                        </h1>
                                                    </>
                                                }
                                                <div class="dd dd-draghandle">
                                                    <ol class="dd-list">
                                                        <li class="dd-item dd2-item dd-colored" data-id="17">
                                                            <div class="dd2-content btn-info no-hover" style={{ minHeight: "125px" }}>
                                                                <div class="col-md-4">
                                                                    <small>Cost Price</small>
                                                                    <h1 style={{ margin: "0" }}>₹ {Number(total.total).toFixed(2)}</h1>
                                                                    <span>Material & Processing Cost: ₹ {Number(total.material).toFixed(2)}</span><br />
                                                                    <span>Other charges: ₹ {Number(total.otherCharge).toFixed(2)}</span>
                                                                    <br />
                                                                </div>
                                                                {calculator && calculator.product &&
                                                                    <div class="col-md-3">
                                                                        <small>₹ Wage</small>
                                                                        <input className="form-control" type="number" style={{ height: "27px" }} placeholder="Wage" value={calculator.product.wage} onChange={onChangeProductWage} />
                                                                    </div>}
                                                                <div class="col-md-3">
                                                                    <small>Lot units/ saree per tani</small>
                                                                    <input className="form-control" type="number" style={{ height: "27px" }} placeholder="Lot units" value={otherValue.lotUnits} onChange={(e) => { setOtherValue({ ...otherValue, lotUnits: e.target.value }) }} />
                                                                </div>
                                                                <div class="col-md-3">
                                                                    <small>₹ Other charges</small>
                                                                    <input className="form-control" type="number" style={{ height: "27px" }} placeholder="Other charges" value={otherValue.otherCharges} onChange={(e) => { setOtherValue({ ...otherValue, otherCharges: e.target.value }) }} />
                                                                </div>
                                                                <div class="col-md-3">
                                                                    <small>Mode</small>
                                                                    <select className="form-control" style={{ height: "27px" }} value={otherValue.mode} onChange={(e) => { setOtherValue({ ...otherValue, mode: e.target.value }) }}>
                                                                        <option value="%">%</option>
                                                                        <option value="flat">flat</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        {calculator && calculator.consumption && calculator.consumption.length > 0 &&
                                                            calculator.consumption.map((item, index) =>
                                                                <li class="dd-item dd2-item" data-id="15">
                                                                    <div class="dd-handle dd2-handle">
                                                                        <i class="normal-icon ace-icon fa fa-cube blue bigger-130"></i>

                                                                        <i class="drag-icon ace-icon fa fa-arrows bigger-125"></i>
                                                                    </div>
                                                                    <div class="dd2-content">
                                                                        <div class="col-md-4">
                                                                            <Link to={"/product/dashboard/" + item.product.consumeProductId}>
                                                                                <ProductView isUint={true} item={item.product.consumeProduct} uid={"PP" + index} />
                                                                            </Link>
                                                                        </div>
                                                                        <div class="col-md-2">
                                                                            {item.product.isPerUnitConsume == 0 ? <span className='red'>per lot</span> : <span className='green'>per unit</span>}
                                                                        </div>
                                                                        <div class="col-md-3">
                                                                            <small>Consume</small>
                                                                            <input className="form-control" type="number" style={{ height: "27px" }} placeholder="Wage/Unit" value={calculator.consumption[index].product.consumptionQuantity} id={index} onChange={onChangeCunsumtionL1Consume} />
                                                                        </div>
                                                                        {!(item.consumption && item.consumption.length > 0) ?
                                                                            <div class="col-md-3">
                                                                                <small>₹ Cost</small>
                                                                                <input className="form-control" type="number" style={{ height: "27px" }} placeholder="Cost/Unit" value={calculator.consumption[index].product.consumeProduct.cost} id={index} onChange={onChangeCunsumtionL1Cost} />
                                                                            </div>
                                                                            :
                                                                            <div class="col-md-3">
                                                                                <small>₹ Wage</small>
                                                                                <input className="form-control" type="number" style={{ height: "27px" }} placeholder="Cost/Unit" value={calculator.consumption[index].product.product.wage} id={index} onChange={onChangeCunsumtionL1Wage} />
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <ol class="dd-list">


                                                                        {item.consumption && item.consumption.length > 0 &&
                                                                            item.consumption.map((itemL2, indexL2) =>
                                                                                <li class="dd-item dd2-item" data-id="16">
                                                                                    <div class="dd-handle dd2-handle">
                                                                                        <i class="normal-icon ace-icon fa fa-cube green bigger-130"></i>
                                                                                        <i class="drag-icon ace-icon fa fa-arrows bigger-125"></i>
                                                                                    </div>
                                                                                    <div class="dd2-content">
                                                                                        <div class="col-md-4">
                                                                                            <Link to={"/product/dashboard/" + itemL2.consumeProductId}>
                                                                                                <ProductView isUint={true} item={itemL2.consumeProduct} uid={indexL2 + "PP1" + index} />
                                                                                            </Link>
                                                                                        </div>
                                                                                        <div class="col-md-2">
                                                                                            {itemL2.isPerUnitConsume == 0 ? <span className='red'>per lot</span> : <span className='green'>per unit</span>}
                                                                                        </div>
                                                                                        <div class="col-md-3">
                                                                                            <small>Consume</small>
                                                                                            <input className="form-control" type="number" style={{ height: "27px" }} placeholder="Wage/Unit" value={calculator.consumption[index].consumption[indexL2].consumptionQuantity} id={index + "|" + indexL2} onChange={onChangeCunsumtionL2Consume} />
                                                                                        </div>
                                                                                        <div class="col-md-3">
                                                                                            <small>₹ Cost</small>
                                                                                            <input className="form-control" type="number" style={{ height: "27px" }} placeholder="Cost/Unit" value={calculator.consumption[index].consumption[indexL2].consumeProduct.cost} id={index + "|" + indexL2} onChange={onChangeCunsumtionL2Cost} />
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            )}

                                                                        {item.processing && item.processing.length > 0 &&
                                                                            item.processing.map((itemL2, indexL2) =>
                                                                                <li class="dd-item dd2-item dd-colored" data-id="17">
                                                                                    <div class="dd-handle dd2-handle btn-info">
                                                                                        <i class="normal-icon ace-icon fa fa-recycle bigger-130"></i>

                                                                                        <i class="drag-icon ace-icon fa fa-arrows bigger-125"></i>
                                                                                    </div>
                                                                                    <div class="dd2-content btn-default no-hover">
                                                                                        <div class="col-md-6">
                                                                                            <Link to={"/processing/dashboard/" + itemL2.processingId} >
                                                                                                <ProcessingView item={itemL2.processing} />
                                                                                            </Link>
                                                                                        </div>
                                                                                        <div class="col-md-6">
                                                                                            <small className='grey'>₹ Wage</small>
                                                                                            <input className="form-control" type="number" style={{ height: "27px" }} placeholder="Wage/Unit" value={calculator.consumption[index].processing[indexL2].processing.defaultWage} id={index + "|" + indexL2} onChange={onChangeProcessingL2Cost} />
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            )}
                                                                    </ol>
                                                                </li>
                                                            )
                                                        }



                                                        {calculator && calculator.processing && calculator.processing.length > 0 &&
                                                            calculator.processing.map((item, index) =>
                                                                <li class="dd-item dd2-item dd-colored" data-id="17">
                                                                    <div class="dd-handle dd2-handle btn-info">
                                                                        <i class="normal-icon ace-icon fa fa-recycle bigger-130"></i>

                                                                        <i class="drag-icon ace-icon fa fa-arrows bigger-125"></i>
                                                                    </div>
                                                                    <div class="dd2-content btn-default no-hover">
                                                                        <div class="col-md-6">
                                                                            <Link to={"/processing/dashboard/" + item.processingId} >
                                                                                <ProcessingView item={item.processing} />
                                                                            </Link>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <small className='grey'>₹ Wage/Unit</small>
                                                                            <input className="form-control" type="number" style={{ height: "27px" }} placeholder="Wage/Unit" value={calculator.processing[index].processing.defaultWage} id={index} onChange={onChangeProcessingL1} />
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )}

                                                        <li class="dd-item dd2-item dd-colored" data-id="17">
                                                            <div class="dd2-content btn-info no-hover" style={{ minHeight: "125px" }}>
                                                                <div class="col-md-4">
                                                                    <small>Cost Price</small>
                                                                    <h1 style={{ margin: "0" }}>₹ {Number(total.total).toFixed(2)}</h1>
                                                                    <span>Material & Processing Cost: ₹ {Number(total.material).toFixed(2)}</span><br />
                                                                    <span>Other charges: ₹ {Number(total.otherCharge).toFixed(2)}</span>
                                                                    <br />
                                                                </div>
                                                                {calculator && calculator.product &&
                                                                    <div class="col-md-3">
                                                                        <small>₹ Wage</small>
                                                                        <input className="form-control" type="number" style={{ height: "27px" }} placeholder="Wage" value={calculator.product.wage} onChange={onChangeProductWage} />
                                                                    </div>}
                                                                <div class="col-md-3">
                                                                    <small>Lot units/ saree per tani</small>
                                                                    <input className="form-control" type="number" style={{ height: "27px" }} placeholder="Lot units" value={otherValue.lotUnits} onChange={(e) => { setOtherValue({ ...otherValue, lotUnits: e.target.value }) }} />
                                                                </div>
                                                                <div class="col-md-3">
                                                                    <small>₹ Other charges</small>
                                                                    <input className="form-control" type="number" style={{ height: "27px" }} placeholder="Other charges" value={otherValue.otherCharges} onChange={(e) => { setOtherValue({ ...otherValue, otherCharges: e.target.value }) }} />
                                                                </div>
                                                                <div class="col-md-3">
                                                                    <small>Mode</small>
                                                                    <select className="form-control" style={{ height: "27px" }} value={otherValue.mode} onChange={(e) => { setOtherValue({ ...otherValue, mode: e.target.value }) }}>
                                                                        <option value="%">%</option>
                                                                        <option value="flat">flat</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <EmptyData>
                                            <br />
                                            No Results Found
                                            <br />
                                            <br />
                                            <br />
                                        </EmptyData>
                                    }

                                </>
                            }
                        </>
                        :
                        <>
                            <br />
                            <br />
                            <br />
                        </>
                    }
                </div>
            </div>
        </Container>
    )
}
