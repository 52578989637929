import React, { useContext, useState, useEffect, useRef } from 'react'
import { Container } from '../../base/Container'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext'
import { organizationContext } from '../Organization/organizationContext'
import { DrapingView } from './DrapingView'
import *as sareeDrapingCrud from '../../_crud/sareeDrapingCrud'
import { Link, useParams } from 'react-router-dom'
import { Spinner } from '../../commonLib/Spinner'
import AppToaster from '../../commonLib/AppToaster'
import { PrintStarterDash } from '../Prints/PrintStaterDash'
import { DrapingPrint } from '../Prints/DrapingPrint'
import { ERROR_IMG, getIsPrint } from '../../config/GlobalSettings'
import { BASE_URL } from '../../initApi/apiUrls'
import { GetBGPrintColor } from './BGPrintColors'

export function SareeDrapingDashboard() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [organization, setOrganization] = useContext(organizationContext);
    const [bgColor, setBgColor] = useState("default");
    const [data, setData] = useState(undefined)
    const { id } = useParams()

    useEffect(() => {
        getData()
    }, [])

    function getData() {
        setIsLoadingMini(true);
        sareeDrapingCrud.GetById(id)
            .then(res => {
                if (res.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setData(hdata)
                        setIsLoadingMini(false)
                    }
                    else {
                        setIsLoadingMini(false)
                        setData();
                    }
                }
                else {
                    setIsLoadingMini(false)
                    setData();
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        getData()
    }
    const printRef = useRef();
    return (
        <Container title="Saree Draping" isBack={true} doRefresh={doRefresh}>
            {getIsPrint && <div style={{ display: "none" }}>
                {data &&
                    <DrapingPrint bgColor={bgColor} FinalDraper={data.drapingFrontUrl ? BASE_URL + "/" + data.drapingFrontUrl : ERROR_IMG} FinalDraperBack={data.drapingBackUrl ? BASE_URL + "/" + data.drapingBackUrl : ERROR_IMG} itemName={data.itemName} itemCode={data.itemCode} organization={organization} ref={printRef} />
                }
            </div>}
            <div class="btn-group">
                <Link to={"/draping/edit/" + id} class="btn btn-xs btn-success bigger-120" style={{ marginRight: "5px" }}>
                    <i class="ace-icon fa fa-pencil bigger-120"></i> Edit
                </Link>

                {getIsPrint && <PrintStarterDash printRef={printRef} />}
                {/* <select className="form-control" style={{ inlineSize: "auto", height: "31px" }} name="wageType" value={bgColor} onChange={(e) => setBgColor(e.target.value)}>
                    {
                        GetBGPrintColor.BGPrintColor.map((data, index) =>
                            <option key={data.Id} value={data.Id}>{data.Name}</option>
                        )
                    }
                </select> */}
            </div>
            <div class="hr dotted"></div>
            {isLoadingMini ?
                <Spinner />
                :
                data &&
                <DrapingView FinalDraper={data.drapingFrontUrl ? BASE_URL + "/" + data.drapingFrontUrl : ERROR_IMG} FinalDraperBack={data.drapingBackUrl ? BASE_URL + "/" + data.drapingBackUrl : ERROR_IMG} itemName={data.itemName} itemCode={data.itemCode} organization={organization} />
            }
        </Container>
    )
}
