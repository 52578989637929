import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken, createAxiosWithTokenFile } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetAll(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductDesign/GetAll", param)
    }
}
export async function GetAllByProduct(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductDesign/GetAllByProduct", param)
    }
}
export async function GetAllByContactDesigner(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductDesign/GetAllByContactDesigner", param)
    }
}
export async function GetById(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductDesign/GetById?Id=" + id)
    }
}

export async function Add(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductDesign/Add", param)
    }
}


export async function Edit(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductDesign/Edit", param)

    }
}

export async function Remove(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductDesign/Remove?Id=" + id)
    }
}

export async function UpdateStatus(id, status) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductDesign/UpdateStatus?Id=" + id + "&Status=" + status)
    }
}

export async function CloneDesign(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductDesign/CloneDesign?Id=" + id)
    }
}

//Items
export async function GetItemById(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductDesign/GetItemById?Id=" + id)
    }
}
export async function AddItem(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithTokenFile(loginData.token, OrgId()).post("ProductDesign/AddItem", param)
    }
}

export async function EditItem(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithTokenFile(loginData.token, OrgId()).post("ProductDesign/EditItem", param)

    }
}
export async function RemoveItem(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductDesign/RemoveItem?Id=" + id)
    }
}
