import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Container } from '../../../base/Container';
import AppToaster from '../../../commonLib/AppToaster';
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import { PagingEmpty } from '../../../commonLib/PagingEmpty';
import { Spinner } from '../../../commonLib/Spinner';
import *as productDesignCrud from '../../../_crud/productDesignCrud'
import 'moment-timezone';
import { EmptyData } from '../../../commonLib/EmptyData';
import { MyAlert } from '../../../commonLib/MyAlert';
import { dashboardContext } from '../../Global/dashboardContext';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { FindFileExtention } from '../../../commonLib/FindFileExtention';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { ERROR_IMG, getIsPrint } from '../../../config/GlobalSettings';
import { BASE_URL, BASE_URL_API } from '../../../initApi/apiUrls';
import { ProductView } from '../../Global/Elements/ProductView';
import { TimelineHistory } from '../../Global/TimelineHistory';
import { ProductionUnitView } from '../../Global/Elements/ProductionUnitView';
import { ContactView } from '../../Global/Elements/ContactView';
import { EditProductDesignItem } from './EditProductDesignItem';
import { StatusView } from '../../../commonLib/StatusView';
import { GetEProductDesignStatus, GetEProductDesignStatusName } from '../../../enumCollections/EProductDesignStatus';
import { LoadingButton } from '../../../commonLib/LoadingButton';
import { PrintStarterDash } from '../../Prints/PrintStaterDash';
import { ProducrDesignPrint } from '../../Prints/ProducrDesignPrint';

export function ProductDesignDashboard() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [productDesign, setProductDesign] = useState()
    const [productDesignItems, setProductDesignItems] = useState([])
    const [dashboardData, setDashboardData] = useContext(dashboardContext)
    const [status, setStatus] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [editId, setEditId] = useState("")

    const { id, did } = useParams()

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoadingMini(true);
        productDesignCrud.GetById(did)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let hitems = res.data.items
                        setProductDesign(hdata);
                        setProductDesignItems(hitems);
                        setStatus(hdata.status)
                        setDashboardData({ ...dashboardData, productdata: hdata.product })
                    }
                    else {
                        setProductDesign();
                        setProductDesignItems([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProductDesign();
                    setProductDesignItems([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh() {
        getData()
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        productDesignCrud.RemoveItem(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    AppToaster('Deleted successfully.', 'success')
                    getData()
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by someone,could not delete.', 'error')
                }

            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }
    const UpdateStatus = () => {
        setIsLoadingLocal(true)
        productDesignCrud.UpdateStatus(did, status)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingLocal(false);
                    AppToaster('Updated successfully.', 'success')
                    doRefresh()
                }
                else {
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }
    const printRef = useRef();
    return (
        <>
            {getIsPrint && <div style={{ display: "none" }}>
                <ProducrDesignPrint design={productDesign && productDesign} designItems={productDesignItems && productDesignItems} ref={printRef} />
            </div>}
            <Container title="Product Design Dashboard" doRefresh={doRefresh} isProduct={true} isBack="true">
                <Link style={{ marginRight: "5px" }} to={"/product/design/" + id + "/dashboard/" + did + "/AddItem"} class="btn btn-sm btn-success">
                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                    Add Item
                </Link>
                <Link to={"/product/design/" + id + "/" + did + "/edit"} class="btn btn-sm btn-info ">
                    <i class="ace-icon fa fa-pencil bigger-110"></i>
                    Edit
                </Link>
                {getIsPrint && productDesign &&
                    <div class="btn-group pull-right">
                        <PrintStarterDash printRef={printRef} />
                    </div>
                }
                <div class="hr dotted"></div>
                <div className="row">
                    <div className="col-sm-8">
                        {productDesign &&
                            <>
                                <h1>{productDesign.id}) {productDesign.name} <StatusView status={productDesign.status} type={0}>{GetEProductDesignStatusName(productDesign.status)}</StatusView></h1>
                                {productDesign.note && <small>{productDesign.note}</small>}

                                <div className="row">
                                    {productDesign.designerContact &&
                                        <>
                                            <hr />
                                            <div className="col-sm-3">
                                                Designer Contact (Grapher):
                                                <br />
                                                <Link to={"/contact/dashboard/" + productDesign.designerContactId}>
                                                    <ContactView item={productDesign.designerContact} uid={"CSX183"} />
                                                </Link>
                                            </div>
                                        </>
                                    }
                                    {productDesign.contact &&
                                        <>
                                            <div className="col-sm-3">
                                                Alloted Contact:
                                                <br />
                                                <Link to={"/contact/dashboard/" + productDesign.contactId}>
                                                    <ContactView item={productDesign.contact} uid={"CSX123"} />
                                                </Link>
                                            </div>
                                            <div className="col-sm-3">
                                                Production Unit:
                                                <br />
                                                <Link to={"/productionunit/dashboard/" + productDesign.productionUnitId}>
                                                    <ProductionUnitView item={productDesign.productionUnit} />
                                                </Link>
                                            </div>
                                        </>
                                    }
                                </div>
                            </>
                        }
                        <div class="hr dotted"></div>

                        {isEdit ?
                            <EditProductDesignItem itemId={editId} setIsEdit={setIsEdit} refresh={getData} />
                            :
                            <div class="timeline-container">
                                <div class="timeline-label">
                                    <span class="label label-primary arrowed-in-right label-lg">
                                        <b>{"Total " + productDesignItems.length + " items"}</b>
                                    </span>
                                </div>
                                {isLoadingMini ?
                                    <Spinner />
                                    :
                                    <div class="timeline-items">

                                        {productDesignItems && productDesignItems.length > 0 ?
                                            <>
                                                {productDesignItems.map((item, index) =>
                                                    <div class="timeline-item clearfix">
                                                        <div class="timeline-info">
                                                            <i class="timeline-indicator ace-icon btn btn-success no-hover"><small>{item.serialNo}</small></i>
                                                        </div>

                                                        <div class="widget-box transparent">
                                                            <div class="widget-header widget-header-small">
                                                                <h5 class="widget-title smaller">
                                                                    <span class="bolder">{item.name}</span>
                                                                </h5>
                                                            </div>

                                                            <div class="widget-body">
                                                                <div class="widget-main">
                                                                    {item.text}
                                                                    {item.product &&
                                                                        <>
                                                                            <br />
                                                                            <br />
                                                                            Ref Product:
                                                                            <br />
                                                                            <b className='custom-infobox-data' style={{ borderRadius: "unset", marginLeft: "0", paddingLeft: "0", backgroundColor: "rgb(234 235 248)" }}>
                                                                                <Link to={"/product/dashboard/" + item.product.id}>
                                                                                    <ProductView item={item.product} uid={"PPD" + index} />
                                                                                </Link>
                                                                            </b>
                                                                        </>
                                                                    }
                                                                    {item.attachmentUrl &&
                                                                        <div style={{ marginTop: "12px" }}>
                                                                            {(FindFileExtention(item.attachmentUrl).toLowerCase() == "jpeg" ||
                                                                                FindFileExtention(item.attachmentUrl).toLowerCase() == "jpg" ||
                                                                                FindFileExtention(item.attachmentUrl).toLowerCase() == "png" ||
                                                                                FindFileExtention(item.attachmentUrl).toLowerCase() == "gif") ?
                                                                                <Zoom>
                                                                                    <img src={BASE_URL + "/" + item.attachmentUrl} width="200px" className="card-img-top" onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
                                                                                </Zoom>
                                                                                :
                                                                                <a target="_blank" href={BASE_URL + "/" + item.attachmentUrl} title="click to view/ download filr"><div style={{ width: "40px" }}><FileIcon extension={FindFileExtention(item.attachmentUrl)} {...defaultStyles[FindFileExtention(item.attachmentUrl)]} /></div></a>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    <br />
                                                                    <br />
                                                                    {item.isPrintInProduction == 0 ? <i class="fa fa-times-circle red"></i> : <i class="fa fa-check-circle green"></i>} Is print in Production<br />
                                                                    {item.isPrintInProcessing == 0 ? <i class="fa fa-times-circle red"></i> : <i class="fa fa-check-circle green"></i>} Is print in Processing
                                                                    <div class="space-6"></div>

                                                                    <div class="widget-toolbox clearfix">
                                                                        <div class="pull-left">
                                                                            <h5>Value: {item.value}</h5>
                                                                        </div>

                                                                        <div class="pull-right action-buttons">
                                                                            <a onClick={() => { setEditId(item.id); setIsEdit(true); }} class="btn btn-xs btn-success">
                                                                                <i class="ace-icon fa fa-pencil bigger-120"></i>
                                                                            </a>
                                                                            <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                                                <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                                            </MyAlert>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                            :
                                            <EmptyData>
                                                No records found.
                                                <br />
                                                <br />
                                            </EmptyData>
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div className="col-sm-4">
                        <div className="widget-box">
                            <div class="widget-header widget-header-flat widget-header-small">
                                <h5 class="widget-title">
                                    Change Status
                                </h5>
                            </div>
                            <div className="row">

                                <div className="col-xs-12">
                                    <div class="form-group">
                                        <div class="col-xs-12">
                                            <label class="control-label no-padding-right"> Select Status</label>
                                            <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
                                                {
                                                    GetEProductDesignStatus.EProductDesignStatus.map((data, index) =>
                                                        <option key={data.Id} value={data.Id}>{data.Name}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-12">
                                    <div class="form-group">
                                        <div class="col-xs-12">
                                            <br />
                                            {isLoadingLocal ?
                                                <LoadingButton />
                                                :
                                                <button class="btn btn-info" onClick={UpdateStatus}>
                                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                                    Update
                                                </button>}
                                        </div>
                                        <small> &nbsp;</small>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <br />

                        <TimelineHistory id={did} type={12} pageSize={10} text="Design Timeline" />
                    </div>
                </div>
            </Container >
        </>
    )
}
