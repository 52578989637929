import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Container } from '../../../base/Container'
import AppToaster from '../../../commonLib/AppToaster'
import { Paging } from '../../../commonLib/Paging'
import { Spinner } from '../../../commonLib/Spinner'
import *as warehouseTransferCrud from './../../../_crud/warehouse/warehouseTransferCrud'
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext'
import Moment from 'react-moment';
import 'moment-timezone';
import { EmptyData } from '../../../commonLib/EmptyData'
import DatePicker from "react-datepicker";
import GetCurrentDateForSearch from '../../../commonLib/dateFormat'
import { UserView } from '../../Global/Elements/UserView'
import { ContactView } from '../../Global/Elements/ContactView'
import GetLoginInfo from '../../../auth/_userContext/GetLoginInfo'
import { WarehouseView } from '../../Global/Elements/WarehouseView'
import { ProductView } from '../../Global/Elements/ProductView'
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater'

const initValues = {
    Search: {
        SearchTerm: "",
        pageNo: 1,
        pageSize: 10
    },
    Range: {
        fromDate: new Date(GetCurrentDateForSearch(-30)),
        toDate: new Date(GetCurrentDateForSearch(0))
    }
}

export function AllWarehouseTransfer() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [search, setSearch] = useState(initValues)
    const [warehouse, setWarehouse] = useState([])
    const [LastPage, setLastPage] = useState(1)
    const [pagingData, setPagingData] = useState()
    const userData = GetLoginInfo()

    useEffect(() => {
        onSearch()
    }, [search.Search.pageNo])

    const OnPageChange = (e) => {
        const { value } = e.target
        setSearch({ ...search, Search: { ...search.Search, pageNo: value } });
    }

    function onSearch() {
        getData()
    }

    function getData() {
        setIsLoadingMini(true);
        warehouseTransferCrud.GetAllItems(search)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setWarehouse(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoadingMini(false)
                        setWarehouse([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoadingMini(false)
                    setWarehouse([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        setSearch({ ...search, Search: { ...search.Search, pageNo: 1 } })
        onSearch()
        setTimeout(() => {
            event.detail.complete();
        }, 1000);
    }

    function SearchChange(e) {
        const value = e.target.value
        setSearch({ ...search, Search: { ...search.Search, SearchTerm: value } })
    }

    return (
        <>
            <Container title="Warehouse Transfer Items" doRefresh={doRefresh}>
                <div class="form-horizontal" >
                    <div className="row">
                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker selectsStart startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.fromDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, fromDate: date } })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker selectsEnd startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.toDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, toDate: date } })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-7">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <TextBoxTranslater style={{paddingLeft: "24px"}} value={search.Search.SearchTerm} onChange={SearchChange} className="form-control" type="text" placeholder="Search GatePass.." />
                                    <i class="ace-icon fa fa-search blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-1">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <button onClick={onSearch} to="/gatepass/add" class="btn btn-sm btn-info pull-right">
                                    <i class="ace-icon fa fa-search bigger-110"></i>
                                    Run
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

                <Paging
                    pagingData={pagingData}
                    LastPage={LastPage}
                    CurrentPage={search.Search.pageNo}
                    OnPageChange={OnPageChange} >
                    {isLoadingMini ?
                        <Spinner />
                        :
                        warehouse && warehouse.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "43px" }}></th>
                                            <th>#Id</th>
                                            <th>On Date</th>
                                            <th>From</th>
                                            <th>To</th>
                                            <th>Product</th>
                                            <th>Main</th>
                                            <th>Raw</th>
                                            <th>Defective</th>
                                            <th>Total</th>
                                            <th>Contact</th>
                                            <th style={{ width: "43px" }}>By</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {warehouse.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link className="btn btn-xs btn-info" to={"/warehousetransfer/dashboard/" + item.warehouseTransfer.id} title="View dashboard" ><i class="ace-icon fa fa-tachometer"></i></Link>
                                                    </div>
                                                </td>
                                                <td>{item.warehouseTransfer.id}</td>
                                                <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.warehouseTransfer.onDate}</Moment></div></td>
                                                <td>
                                                    <WarehouseView item={item.warehouseTransfer.warehouseFrom} uid={"WHT1" + index} />
                                                </td>
                                                <td>
                                                    <WarehouseView item={item.warehouseTransfer.warehouseTo} uid={"WHT2" + index} />
                                                </td>
                                                <td>
                                                    <Link to={"/product/dashboard/" + item.productId}>
                                                        <ProductView item={item.product} uid={"PP2" + index} />
                                                    </Link>
                                                </td>
                                                <td>
                                                    {item.mainStock} {item.product.unit}
                                                </td>
                                                <td>
                                                    {item.rawStock} {item.product.unit}
                                                </td>
                                                <td>
                                                    {item.defectiveStock} {item.product.unit}
                                                </td>
                                                <td>
                                                    <b>{Number(item.mainStock + item.rawStock + item.defectiveStock).toFixed(2)} {item.product.unit}</b>
                                                </td>
                                                <td>
                                                    <Link to={"/contact/dashboard/" + item.warehouseTransfer.contactId}>
                                                        <ContactView item={item.warehouseTransfer.contact} uid={"C" + index} />
                                                    </Link>
                                                </td>
                                                <td>
                                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </Paging>
            </Container >
        </>
    )
}
