import React, { useEffect, useState } from 'react'
import * as defectManagementCrud from '../../../_crud/defectManagementCrud'
import * as voucherCrud from '../../../_crud/voucherCrud'
import { EmptyData } from '../../../commonLib/EmptyData'
import { Spinner } from '../../../commonLib/Spinner'

export function DefectView({ contactid, defectData }) {
    const [advanceVoucher, setAdvanceVoucher] = useState()
    const [currentFiscalYear, setCurrentFiscalYear] = useState("")
    const [isLocalLoading, setIsLocalLoading] = useState(false)

    useEffect(() => {
        getCurrentFiscalYear()
    }, [])
    useEffect(() => {
        try {
            defectData(advanceVoucher)
        }
        catch { }
    }, [advanceVoucher])

    useEffect(() => {
        if (contactid && currentFiscalYear) {
            getData()
        }
    }, [currentFiscalYear, contactid])

    function getData() {
        setIsLocalLoading(true);
        defectManagementCrud.GetDefectsByContactId({ contactId: contactid, FiscalYear: currentFiscalYear })
            .then(res => {
                if (res.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data
                        setAdvanceVoucher(hdata);
                        setIsLocalLoading(false)
                    }
                    else {
                        setIsLocalLoading(false)
                        setAdvanceVoucher();
                    }
                }
                else {
                    setIsLocalLoading(false)
                    setAdvanceVoucher();
                }
            })
            .catch(error => {
                setIsLocalLoading(false);
            })
    }

    function getCurrentFiscalYear() {
        setIsLocalLoading(true);
        voucherCrud.GetCurrentFiscalYears()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setCurrentFiscalYear(hdata);
                        setIsLocalLoading(false)
                    }
                    else {
                        setIsLocalLoading(false)
                        setCurrentFiscalYear("");
                    }
                }
                else {
                    setIsLocalLoading(false)
                    setCurrentFiscalYear("");
                }
            })
            .catch(error => {
                setIsLocalLoading(false);
            })
    }

    return (

        <>
            <div className="widget-box">
                <div class="widget-header widget-header-flat widget-header-small">
                    <h5 class="widget-title">
                        Penalty & Defects
                    </h5>
                    <div class="widget-toolbar">
                    <a onClick={getData} title="Reload">
                        <i class="ace-icon fa fa-refresh"></i>
                    </a>
                </div>
                </div>
                <div className="row">
                    {isLocalLoading ?
                        <Spinner />
                        :
                        advanceVoucher ?
                            <div class="profile-user-info profile-user-info-striped">

                                <div class="profile-info-row">
                                    <div class="profile-info-name">
                                        <b>Fiscal year</b>
                                    </div>
                                    <div class="profile-info-value">
                                        <span > {advanceVoucher.fiscalYear}</span>
                                    </div>
                                </div>


                                <div class="profile-info-row">
                                    <div class="profile-info-name">Remaining</div>

                                    <div class="profile-info-value">
                                        <span className="red"><b>₹ {Number(advanceVoucher.remainingAdvance).toFixed(2)}</b></span>
                                    </div>
                                </div>
                                <div class="profile-info-row">
                                    <div class="profile-info-name">Received</div>

                                    <div class="profile-info-value">
                                        <span className="green">₹ {Number(advanceVoucher.recievedAdvance).toFixed(2)}</span>
                                    </div>
                                </div>
                                <div class="profile-info-row">
                                    <div class="profile-info-name">Total</div>

                                    <div class="profile-info-value">
                                        <span >₹ {Number(advanceVoucher.totalAdvance).toFixed(2)}</span>
                                    </div>
                                </div>

                            </div>
                            :
                            <EmptyData>
                                <h1>No Data</h1>
                                <br />
                            </EmptyData>
                    }
                </div>
            </div>
        </>
    )
}
