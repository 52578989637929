import React, { useState, useEffect } from "react";
import { getCanvas } from "react-lasso-select";
import mergeImages from 'merge-images';
import rotate from 'base64-rotate'
import { flip, swirl } from "./ImageEditFunction";
import { PoseBg } from "./PoseBg";

export function Saree5Pose({ img1, img2, bg, setIsCompleted, setDrapResult, status }) {

    const [isReadySari1, setIsReadySari1] = useState(false);
    const [isReadySari2, setIsReadySari2] = useState(false);
    const [isReadySari3, setIsReadySari3] = useState(false);

    //#region Saree1
    const MergeSaree = () => {
        try {
            let cbg = PoseBg(parseInt(bg))
            //alert(cbg)
            mergeImages([
                { src: cbg, x: 0, y: 0 },
                { src: saree1, x: 337, y: 1077 },
                { src: saree2, x: 1482, y: 1101 },
                { src: saree3, x: 175, y: 1106 },
                { src: './assets/images/Draper/new/9.png', x: 0, y: 0 },
            ])
                .then(b64 => {
                    setDrapResult(b64)
                    //alert("sareedrap")
                    setIsCompleted("completed")
                }).catch(e => {
                    setDrapResult()
                    setIsCompleted("failed")
                    alert(e)
                });
        } catch (e) {
            setDrapResult()
            setIsCompleted("failed")
            alert(e)
        }
    }
    ///Saree item 1
    const [saree1, setSaree1] = useState("");
    const GetSaree1 = () => {
        setIsReadySari1(false);
        let initPP = "406,1600 144,717 269,682 342,656 432,640 477,634 502,634 506,634 486,707 477,787 470,861 477,925 493,995 522,1053 544,1075 573,1101 602,1117 630,1130 653,1136 698,1152 726,1155 752,1165 784,1165 858,1165 893,1162 928,1149 960,1139 1014,1120 1046,1101 1078,1069 1101,1034 1120,989 1142,922 1142,858 1136,794 1126,714 1114,634 1142,634 1197,643 1293,666 1440,707 1376,1027 1213,1565 1206,1597"
            .split(" ")
            .map((c) => c.split(",").map(Number))
            .map(([x, y]) => ({ x, y }));
        getCanvas(img2, initPP, (err, canvas) => {
            if (!err) {
                setSaree1(canvas.toDataURL());
                setIsReadySari1(true)
            }
        });
    }
    ///Saree item 2
    const [saree2, setSaree2] = useState("");
    const GetSaree2 = () => {
        setIsReadySari2(false)
        rotate(img2, 179).then(newsrc => {
            rotate(newsrc, 112).then(newsrc => {
                let initPP = "1586,1492 1548,1450 1522,1408 1509,1378 1505,1331 1501,1285 1501,1221 1501,1170 1501,1111 1849,1221 1777,1416 1747,1480 1726,1522 1700,1522 1683,1514 1658,1509 1628,1505 1598,1497"
                    .split(" ")
                    .map((c) => c.split(",").map(Number))
                    .map(([x, y]) => ({ x, y }));

                getCanvas(newsrc, initPP, (err, canvas) => {
                    if (!err) {
                        setSaree2(canvas.toDataURL());
                        setIsReadySari2(true)
                    }
                });
            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///Saree item 3
    const [saree3, setSaree3] = useState("");
    const GetSaree3 = () => {
        setIsReadySari3(false)
        rotate(img2, 66).then(newsrc => {
            let initPP = "359,1462 380,1462 406,1458 431,1454 461,1450 490,1437 511,1420 537,1395 554,1365 566,1335 575,1302 579,1264 583,1175 583,1133 583,1086 583,1061 241,1166 275,1259"
                .split(" ")
                .map((c) => c.split(",").map(Number))
                .map(([x, y]) => ({ x, y }));

            getCanvas(newsrc, initPP, (err, canvas) => {
                if (!err) {
                    setSaree3(canvas.toDataURL());
                    setIsReadySari3(true)
                }
            });
        }).catch(err => {
        })
    }
    ///////////////////////////////////////////
    //#endregion
    useEffect(() => {
        if (isReadySari1 && isReadySari2 && isReadySari3) {
            MergeSaree()
            setIsCompleted("Merging Images")
            setIsReadySari1(false)
            setIsReadySari2(false)
            setIsReadySari3(false)
        }
    }, [isReadySari1, isReadySari2, isReadySari3])

    useEffect(() => {
        if (img1 && img2 && status == "init") {
            //alert("allimageloaded")
            setIsCompleted("Processing")
            setDrapResult()
            GetSaree1()
            GetSaree2()
            GetSaree3()
        }
    }, [img1, img2, status])

    return (
        <></>
    )
}
