import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Container } from '../../base/Container'
import AppToaster from '../../commonLib/AppToaster'
import { Paging } from '../../commonLib/Paging'
import { Spinner } from '../../commonLib/Spinner'
import *as gatePassCrud from '../../_crud/gatePassCrud'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext'
import Moment from 'react-moment';
import 'moment-timezone';
import { EmptyData } from '../../commonLib/EmptyData'
import DatePicker from "react-datepicker";
import GetCurrentDateForSearch from '../../commonLib/dateFormat'
import { UserView } from '../Global/Elements/UserView'
import { ContactView } from '../Global/Elements/ContactView'
import GetLoginInfo from '../../auth/_userContext/GetLoginInfo'
import { WarehouseView } from '../Global/Elements/WarehouseView'
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater'
import { PAGING_PAGE_SIZE } from '../../config/GlobalSettings'

const initValues = {
  Search: {
    SearchTerm: "",
    pageNo: 1,
    pageSize: PAGING_PAGE_SIZE
  },
  Range: {
    fromDate: new Date(GetCurrentDateForSearch(-30)),
    toDate: new Date(GetCurrentDateForSearch(0))
  }
}

export function ManageGatePass() {
  const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
  const [search, setSearch] = useState(initValues)
  const [gatePass, setGatePass] = useState([])
  const [LastPage, setLastPage] = useState(1)
  const [pagingData, setPagingData] = useState()
  const userData = GetLoginInfo()

  useEffect(() => {
    onSearch()
  }, [search.Search.pageNo])

  const OnPageChange = (e) => {
    const { value } = e.target
    setSearch({ ...search, Search: { ...search.Search, pageNo: value } });
  }

  function onSearch() {
    if (userData.isWarehouse == 0)
      getData()
    else
      getDataByWarehouse()
  }

  function getData() {
    setIsLoadingMini(true);
    gatePassCrud.GetAll(search)
      .then(res => {
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            let paging = res.data.paging
            setPagingData(paging)
            setLastPage(paging.pageCount)
            setGatePass(hdata);
            setIsLoadingMini(false)
          }
          else {
            setLastPage(1)
            setIsLoadingMini(false)
            setGatePass([]);
          }
        }
        else {
          setLastPage(1)
          setIsLoadingMini(false)
          setGatePass([]);
          AppToaster("Server Error /Invalid Try.", 'error')
        }
      })
      .catch(error => {
        setLastPage(1)
        setIsLoadingMini(false);
        AppToaster(error, 'error')
      })
  }
  function getDataByWarehouse() {
    setIsLoadingMini(true);
    gatePassCrud.GetAllByWarehouse({ ...search, WarehouseId: userData.warehouseId })
      .then(res => {
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            let paging = res.data.paging
            setPagingData(paging)
            setLastPage(paging.pageCount)
            setGatePass(hdata);
            setIsLoadingMini(false)
          }
          else {
            setLastPage(1)
            setIsLoadingMini(false)
            setGatePass([]);
          }
        }
        else {
          setLastPage(1)
          setIsLoadingMini(false)
          setGatePass([]);
          AppToaster("Server Error /Invalid Try.", 'error')
        }
      })
      .catch(error => {
        setLastPage(1)
        setIsLoadingMini(false);
        AppToaster(error, 'error')
      })
  }
  function doRefresh(event) {
    setSearch({ ...search, Search: { ...search.Search, pageNo: 1 } })
    onSearch()
    setTimeout(() => {
      event.detail.complete();
    }, 1000);
  }

  function SearchChange(e) {
    const value = e.target.value
    setSearch({ ...search, Search: { ...search.Search, SearchTerm: value } })
  }

  return (
    <>
      <Container title="GatePass" doRefresh={doRefresh}>
        <Link to="/gatepass/add" class="btn btn-sm btn-success ">
          <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
          New
        </Link>
        <div class="hr dotted"></div>
        <div class="form-horizontal" >
          <div className="row">
            <div className="col-md-2">
              <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                <span class="input-icon" style={{ width: "100%" }}>
                  <DatePicker selectsStart startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.fromDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, fromDate: date } })} />
                  <i class="ace-icon fa fa-calendar blue"></i>
                </span>
              </div>
            </div>

            <div className="col-md-2">
              <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                <span class="input-icon" style={{ width: "100%" }}>
                  <DatePicker selectsEnd startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.toDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, toDate: date } })} />
                  <i class="ace-icon fa fa-calendar blue"></i>
                </span>
              </div>
            </div>

            <div className="col-md-7">
              <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                <span class="input-icon" style={{ width: "100%" }}>
                  <TextBoxTranslater style={{paddingLeft: "24px"}} value={search.Search.SearchTerm} onChange={SearchChange} className="form-control" type="text" placeholder="Search GatePass.." />
                  <i class="ace-icon fa fa-search blue"></i>
                </span>
              </div>
            </div>

            <div className="col-md-1">
              <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                <button onClick={onSearch} to="/gatepass/add" class="btn btn-sm btn-info pull-right">
                  <i class="ace-icon fa fa-search bigger-110"></i>
                  Run
                </button>
              </div>
            </div>

          </div>
        </div>

        <Paging
          pagingData={pagingData}
          LastPage={LastPage}
          CurrentPage={search.Search.pageNo}
          OnPageChange={OnPageChange} >
          {isLoadingMini ?
            <Spinner />
            :
            gatePass && gatePass.length > 0 ?
              <div className="tables-scroll">
                <table className="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th style={{ width: "43px" }}></th>
                      <th>Branch</th>
                      <th>#Id</th>
                      <th>On Date</th>
                      <th>Contact</th>
                      <th>Note</th>
                      <th style={{ width: "43px" }}>By</th>
                    </tr>
                  </thead>

                  <tbody>
                    {gatePass.map((item, index) =>
                      <tr key={item.id}>
                        <td>
                          <div class="btn-group">
                            <Link className="btn btn-xs btn-info" to={"/gatepass/dashboard/" + item.id} title="View dashboard" ><i class="ace-icon fa fa-tachometer"></i></Link>
                          </div>
                        </td>
                        <td><WarehouseView item={item.warehouse} uid={"WH" + index} /></td>
                        <td>{item.id}</td>
                        <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.onDate}</Moment></div></td>
                        <td>
                          <Link to={"/contact/dashboard/" + item.refContactId}>
                            <ContactView item={item.contact} uid={"C" + index} />
                          </Link>
                        </td>
                        <td><div style={{ width: "150px" }}>{item.note}</div></td>
                        <td>
                          <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              :
              <EmptyData>
                No records found.
                <br />
                <br />
              </EmptyData>
          }
        </Paging>
      </Container >
    </>
  )
}
