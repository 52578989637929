import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetAll() {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductionConfiguration/GetAll")
    }
}

export async function GetById(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductionConfiguration/GetById?Id=" + id)
    }
}

export async function Add(productionConfigurationMaster) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductionConfiguration/Add", productionConfigurationMaster)
    }
}

export async function AddProductionConfigurationList(productionConfigurationList) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductionConfiguration/AddProductionConfigurationList", productionConfigurationList)
    }
}

export async function Edit(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductionConfiguration/Edit", param)
    }
}

export async function RemoveProductionConfigurationMaster(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductionConfiguration/RemoveProductionConfigurationMaster?Id=" + id)
    }
}

export async function RemoveProductionConfigurationList(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductionConfiguration/RemoveProductionConfigurationList?Id=" + id)
    }
}