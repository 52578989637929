import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Container } from '../../base/Container';
import { loadingContext } from '../../commonLib/_lodingContext';
import { dashboardContext } from '../Global/dashboardContext';
import * as contactCrud from '../../_crud/contactCrud'
import { AdvanceView } from '../Global/Elements/AdvanceView';
import { TimelineHistoryContact } from '../Global/TimelineHistoryContact';
import { TopBar } from './../../base/TopBar'
import { ContactProfile } from './ContactProfile';
import { PayableForContact } from '../Global/Elements/PayableForContact';
import { DefectView } from '../Global/Elements/DefectView';

export function ContactDashboard() {
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [dashboardData, setDashboardData] = useContext(dashboardContext)
    const [contactData, setContactData] = useState()
    const { id } = useParams()

    function doRefresh(event) {
        getContact()
    }

    useEffect(() => {
        getContact()
    }, []);

    function getContact() {
        setIsLoading(true);
        contactCrud.GetById(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setDashboardData({ ...dashboardData, contactdata: hdata })
                        setContactData(hdata)
                    }
                    else {
                    }
                }
                else {
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
            })
    }

    return (
        <Container title="Contact Dashboard" doRefresh={doRefresh} isBack="true" isContact="true">

            <TopBar />
            <div class="clearfix">
                <br />
                <div className="row">
                    <div className="col-lg-8">
                        <ContactProfile item={contactData} />
                    </div>

                    <div className="col-lg-4">
                        <PayableForContact contactid={id} />
                        <br />
                        <AdvanceView contactid={id} />
                        <br />
                        <DefectView contactid={id} />
                        <br />
                        <TimelineHistoryContact id={id} pageSize={10} text="Contact Timeline" />
                    </div>

                </div>
            </div>
        </Container >
    )
}
