import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import AppToaster from '../../commonLib/AppToaster';
import { loadingContext } from '../../commonLib/_lodingContext';
import * as processingCrud from '../../_crud/processingCrud';
import * as Yup from 'yup';
import { Container } from '../../base/Container';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { LoadingButton } from '../../commonLib/LoadingButton';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

const initialValues = {
    id: undefined,
    name: undefined,
    note: undefined,
}

export function EditProcessing() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const history = useHistory()
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const { id } = useParams()

    const Schema = Yup.object().shape({
        name: Yup.string().min(3, "Full Name must between 3 to 20 characters").max(50, "Full Name must between 3 to 50 characters").required("Processing Name is required.")
        //.matches(/^[aA-zZ\s]+$/, " Only alphabets, space and underscore are allowed for this field "),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true);
        processingCrud.Edit(value)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false);
                    setIsLoadingLocal(false);
                    AppToaster('Updated successfully.', 'success')
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    useEffect(() => {
        getProcessing()
    }, []);

    function getProcessing() {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        setIsLoading(true);
        processingCrud.GetById(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        formik.setValues({
                            id: hdata.id,
                            name: hdata.name,
                            note: hdata.note,
                        })
                    }
                    else {
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                }
                setIsLoadingMini(false)
                setIsLoadingLocal(false);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoadingMini(false)
                setIsLoadingLocal(false);
                setIsLoading(false);
                AppToaster(error, 'error')
            })
    }

    return (
        <Container title="Edit Processing" isBack="true" isProcessing="true">
            <form onSubmit={formik.handleSubmit} class="form-horizontal">
                <div className="row">
                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                            <label class="control-label no-padding-right">Name</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="Name" name="name" value={formik.values.name} onChange={(e) => formik.setValues({ ...formik.values, name: e.target.value })} />
                                {
                                    formik.touched.name &&
                                        formik.errors.name ? (
                                        <label className="text-danger" >
                                            {formik.errors.name}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                            <label class="control-label no-padding-right"> Note</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="Note" name="note" value={formik.values.note} onChange={(e) => formik.setValues({ ...formik.values, note: e.target.value })} />
                                {
                                    formik.touched.note &&
                                        formik.errors.note ? (
                                        <label className="text-danger" >
                                            {formik.errors.note}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix form-actions">
                    <div class="pull-right">
                        {isLoadingLocal ?
                            <LoadingButton />
                            :
                            <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                Update
                            </button>}
                    </div>
                </div>
            </form>
        </Container>
    )
}
