import React, { useContext, useState } from 'react'
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { GetEBoolName } from '../../../enumCollections/EBool';
import { GetEStockTypeName } from '../../../enumCollections/EStockType';
import { ProcessingView } from '../../Global/Elements/ProcessingView';
import { ContactView } from '../../Global/Elements/ContactView';
import { ProductionUnitView } from '../../Global/Elements/ProductionUnitView';
import { ProductView } from '../../Global/Elements/ProductView';
import { UserView } from '../../Global/Elements/UserView';

export function ProcessingDistributionItemView({ processingDistribution }) {

    return (
        <>
            <div className="widget-box">
                <div class="widget-header widget-header-flat widget-header-small">
                    <h5 class="widget-title">
                        Details
                    </h5>
                </div>
                {
                    processingDistribution &&
                    <div className="row">
                        <div class="profile-user-info profile-user-info-striped">
                            <div class="profile-info-row">
                                <div class="profile-info-name"> Distribution Id</div>

                                <div class="profile-info-value">
                                    <span ><b>{processingDistribution.id}</b></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> On Date</div>

                                <div class="profile-info-value">
                                    <span ><b><Moment format="DD-MMM-YYYY">{processingDistribution.onDate}</Moment></b></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Contact </div>

                                <div class="profile-info-value">
                                    <Link to={"/contact/dashboard/" + processingDistribution.contactId}>
                                        <ContactView item={processingDistribution.contact} uid={"GC1"} />
                                    </Link>
                                </div>
                            </div>


                            <div class="profile-info-row">
                                <div class="profile-info-name"> Processing </div>

                                <div class="profile-info-value">
                                    <Link to={"/processing/dashboard/" + processingDistribution.processingId}>
                                        <ProcessingView item={processingDistribution.processing} />
                                    </Link>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Production </div>

                                <div class="profile-info-value">
                                    {processingDistribution.isProductionUnit == 1 ? (processingDistribution.productionUnit ? <Link to={"/productionunit/dashboard/" + processingDistribution.productionUnitId}>
                                        <ProductionUnitView item={processingDistribution.productionUnit} />
                                    </Link> : "NA") : <span class="label label-sm label-danger">{GetEBoolName(processingDistribution.isProductionUnit)}</span>}
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Product </div>

                                <div class="profile-info-value">
                                    <Link to={"/product/dashboard/" + processingDistribution.product.id}>
                                        <ProductView item={processingDistribution.product} />
                                    </Link>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Quantity </div>

                                <div class="profile-info-value">
                                    <span ><b>{processingDistribution.quantity} {processingDistribution.unit}</b></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Stock Type </div>

                                <div class="profile-info-value">
                                    <span class={processingDistribution.forStock == 1 ? "label label-sm label-primary" : "label label-sm label-success"}>{GetEStockTypeName(processingDistribution.forStock)}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Note </div>

                                <div class="profile-info-value">
                                    <span >{processingDistribution.note}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Created By </div>
                                <div class="profile-info-value">
                                    <span><UserView item={processingDistribution.createdByUser} uid={"GD1"} createdOn={processingDistribution.createdOn} /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}
