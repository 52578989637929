import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { MMSUser, GetIP } from '../_crud/authenticationCrud';
import SetLoginInfo from './_userContext/SetLoginInfo';
import * as Yup from 'yup';
import { authContext } from './_userContext/authContext';
import AppToaster from '../commonLib/AppToaster';
import { Button, Form } from 'react-bootstrap';
import { loadingContext } from '../commonLib/_lodingContext';
import { GetOrganizationId, SetOrganizationId } from './_userContext/OrganizationID';
import { MyCaptcha, validateCaptcha } from '../commonLib/MyCaptcha';
import { IsUat } from '../initApi/apiUrls';
import { Link } from 'react-router-dom';

const orgid = GetOrganizationId()
const initialValues = {
    UserName: "",
    Password: "",
    OrgId: orgid ? orgid : "",
}

export function Login(props) {
    const [isLogin, setIsLogin] = useContext(authContext)
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [ipDetails, setIpDetails] = useState();
    const [ctext, setCtext] = useState("");
    const [errorCount, setErrorCount] = useState(0);

    const schema = Yup.object().shape({
        UserName: Yup.string().required("User name is required."),
        Password: Yup.string().required("Password is required."),
        OrgId: Yup.string().required("Organizaion id is required.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: (values) => {
            checkLogin(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        }
    });
    useEffect(() => {
        if (formik.values.OrgId)
            SetOrganizationId(formik.values.OrgId)
    }, [formik.values.OrgId])
    useEffect(() => {
        document.title = "Login - BAITHKA - A TEXTILE MANUFACTURING"
        MyIP()
    }, [])

    function checkLogin(val) {
        if (IsUat) {
            if (val.OrgId == "cubicle" || val.OrgId == "cubiclex") {

            }
            else {
                AppToaster("Invalid Organization for UAT Application!", "error")
                return
            }
        }
        else if (val.OrgId == "cubiclex") {
            AppToaster("Invalid Organization, UserName or Password!", "error")
            return
        }
        //////////////////////////////////
        let flag = true;
        if (errorCount > 2)
            flag = validateCaptcha(ctext)

        if (flag) {
            setIsLoading(true)
            let logs = undefined;
            if (ipDetails) {
                logs = {
                    ip: ipDetails.ip,
                    city: ipDetails.city,
                    state: ipDetails.region,
                    lat: ipDetails.latitude,
                    long: ipDetails.longitude,
                    country: ipDetails.country_name,
                }
            }

            MMSUser(val.UserName, val.Password, true, logs)
                .then(res => {
                    if (res.data.result.succeeded) {
                        let d = res.data
                        //d = { ...d, orgId: val.OrgId }
                        SetLoginInfo(d)
                        //setIsLogin(true);
                        //setIsLoading(false);
                        window.location.replace("/");
                    }
                    else {
                        setIsLoading(false);
                        if (errorCount > 5)
                            window.location.replace("https://baithka.com");
                        setErrorCount(errorCount + 1)
                        AppToaster("Invalid Organization, UserName or Password!", "error")
                    }
                })
                .catch(err => {
                    alert("Network issue.");
                    window.location.replace("/");
                    setIsLoading(false)
                    AppToaster(err, "error")
                });
        }
        else {
            setErrorCount(errorCount + 1)
            AppToaster("Invalid captcha text!", "error")
            if (errorCount > 5)
                window.location.replace("https://baithka.com");
        }
    }

    function MyIP() {
        setIsLoading(true)
        GetIP()
            .then(res => {
                setIpDetails(res.data)
                setIsLoading(false)
            })
            .catch(err => {
                setIsLoading(false)
            });
    }

    return (
        <>
            <div class="login-layout light-login">
                <div class="main-container">
                    <div class="main-content">
                        <div class="row">
                            <div class="col-sm-10 col-sm-offset-1">
                                <div class="login-container">
                                    <div class="center">
                                        <br />
                                        <br />
                                        <a href="https://baithka.com" target="_blank">
                                            <img src="images/Baithka-64.gif"
                                                onError={(e) => { e.target.onerror = null; e.target.src = "images/Baithka-64.gif" }} />
                                        </a>
                                        <div class="social-or-login center">
                                            <span class="bigger-110">&nbsp;&nbsp;textile manufacturing&nbsp;&nbsp;</span>
                                        </div>
                                    </div>

                                    <div class="space-6"></div>

                                    <div class="position-relative">
                                        <div id="login-box" class="login-box visible widget-box no-border">
                                            <div class="widget-body">
                                                <div class="widget-main">
                                                    <h4 class="header blue lighter bigger">
                                                        <i class="ace-icon fa fa-lock green"></i>
                                                        &nbsp;Please Enter Your Information
                                                    </h4>

                                                    <div class="space-6"></div>

                                                    <Form onSubmit={formik.handleSubmit}>
                                                        <fieldset>
                                                            <label class="block clearfix">
                                                                <span class="block input-icon input-icon-right">
                                                                    <input type="text" name="OrgId" value={formik.values.OrgId} {...formik.getFieldProps("OrgId")} class="form-control" placeholder="Organization Id" />
                                                                    <i class="ace-icon fa fa-building"></i>
                                                                </span>
                                                                {formik.touched.OrgId &&
                                                                    formik.errors.OrgId ? (
                                                                    <label className="text-danger">
                                                                        {formik.errors.OrgId}
                                                                    </label>)
                                                                    : null
                                                                }
                                                            </label>

                                                            <label class="block clearfix">
                                                                <span class="block input-icon input-icon-right">
                                                                    <input type="text" name="UserName" value={formik.values.UserName} {...formik.getFieldProps("UserName")} class="form-control" placeholder="Username" />
                                                                    <i class="ace-icon fa fa-user"></i>
                                                                </span>
                                                                {formik.touched.UserName &&
                                                                    formik.errors.UserName ? (
                                                                    <label className="text-danger">
                                                                        {formik.errors.UserName}
                                                                    </label>)
                                                                    : null
                                                                }
                                                            </label>

                                                            <label class="block clearfix">
                                                                <span class="block input-icon input-icon-right">
                                                                    <input type="password" name="Password" value={formik.values.Password} {...formik.getFieldProps("Password")} class="form-control" placeholder="Password" />
                                                                    <i class="ace-icon fa fa-key"></i>
                                                                </span>
                                                                {formik.touched.Password &&
                                                                    formik.errors.Password ? (
                                                                    <label className="text-danger">
                                                                        {formik.errors.Password}
                                                                    </label>)
                                                                    : null
                                                                }
                                                            </label>
                                                            {errorCount > 2 &&
                                                                <label class="block clearfix" style={{ textAlign: "right" }}>
                                                                    <MyCaptcha setResult={setCtext} />
                                                                </label>
                                                            }
                                                            <div class="space"></div>

                                                            <div class="clearfix">
                                                                {
                                                                    isLoading ?
                                                                        <button class="width-35 pull-right btn btn-sm btn-primary" disabled>
                                                                            <i class="ace-icon fa fa-sign-in"></i>
                                                                            <span class="bigger-110">Wait..</span>
                                                                        </button>
                                                                        :
                                                                        <>
                                                                            <Link to="/forgot" class="pull-left" >
                                                                                <span class="bigger-110">
                                                                                    I forgot my password
                                                                                </span>
                                                                            </Link>
                                                                            <button class="width-35 pull-right btn btn-sm btn-primary" type="submit" disabled={!formik.isValid || !(errorCount > 2 ? ctext : "aa")}>
                                                                                <i class="ace-icon fa fa-sign-in"></i>
                                                                                <span class="bigger-110">Login</span>
                                                                            </button>
                                                                        </>
                                                                }
                                                            </div>

                                                            <div class="space-4"></div>
                                                        </fieldset>
                                                    </Form>
                                                    <div className='right' >
                                                        <a href='https://www.youtube.com/playlist?list=PLFqp5yCewzQrI0KgXGt0nLpy46Ongz78H' target='_blank'><i class="ace-icon fa fa-youtube red"></i> Video Tutorials &nbsp;&nbsp;<i class="ace-icon fa fa-external-link"></i></a>
                                                    </div>
                                                    <div class="social-or-login center">
                                                        <span class="bigger-110">Powered by</span>
                                                    </div>

                                                    <div class="space-6"></div>

                                                    <div class="social-login center">
                                                        <a href="https://cubicle8.com" target="_blank">
                                                            <img height="40px" src="images/cubiclelogo.gif" />
                                                        </a>
                                                    </div>
                                                </div>

                                                <div class="toolbar clearfix" >
                                                    <div style={{ width: "100%", textAlign: "center" }}>
                                                        <span class="white" style={{ verticalAlign: "bottom" }}><Link to="/signup" class="white" >Register?</Link> | <a class="white" href="https://baithka.com/about" target="_blank">About</a> | <a class="white" href="https://baithka.com/term" target="_blank">Term & Conditions</a></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}