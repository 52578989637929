const GetEOrderStatusName = (id) => {
    switch (id) {
        case 3: { return "Supplied" }
        case 1: { return "Completed" }
        case 2: { return "Processing" }
        case 0: { return "Pending" }
        case 4: { return "Sent To Processing" }
    }
}

const GetEOrderStatus = {
    EOrderStatus: [
        { Id: 3, Name: "Supplied" },
        { Id: 1, Name: "Completed" },
        { Id: 2, Name: "Processing" },
        { Id: 0, Name: "Pending" },
        { Id: 4, Name: "Sent To Processing" }
    ]
}

export { GetEOrderStatusName, GetEOrderStatus }