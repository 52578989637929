const GetEHistoryTypeName = (id) => {
    switch (id) {
        case 1: { return "Voucher" }
        case 2: { return "Product Distribution" }
        case 3: { return "Product Receiving" }
        case 4: { return "Processing Distribution" }
        case 5: { return "Processing Receiving" }
        case 6: { return "Order" }
        case 7: { return "GatePass" }
        case 8: { return "Product Return" }
        case 9: { return "Processing Return" }
        case 10: { return "Cheque" }
        case 11: { return "Warehouse" }
        case 12: { return "Product Design" }
    }
}

const GetEHistoryType = {
    EHistoryType: [
        { Id: 1, Name: "Voucher" },
        { Id: 2, Name: "Product Distribution" },
        { Id: 3, Name: "Product Receiving" },
        { Id: 8, Name: "Product Return" },
        { Id: 4, Name: "Processing Distribution" },
        { Id: 5, Name: "Processing Receiving" },
        { Id: 9, Name: "Processing Return" },
        { Id: 6, Name: "Order" },
        { Id: 7, Name: "GatePass" },
        { Id: 10, Name: "Cheque" },
        { Id: 11, Name: "Warehouse" },
        { Id: 12, Name: "Product Design" },
    ]
}

export { GetEHistoryTypeName, GetEHistoryType }