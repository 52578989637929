import React, { useState, useEffect } from "react";
import { getCanvas } from "react-lasso-select";
import mergeImages from 'merge-images';
import rotate from 'base64-rotate'
import { flip, swirl } from "./ImageEditFunction";
import { PoseBg } from "./PoseBg";

export function Saree4Pose({ img1, img2, bg, setIsCompleted, setDrapResult, status }) {

    const [isReadySari1, setIsReadySari1] = useState(false);
    const [isReadySari2, setIsReadySari2] = useState(false);
    const [isReadySari3, setIsReadySari3] = useState(false);
    const [isReadySari4, setIsReadySari4] = useState(false);
    const [isReadySari5, setIsReadySari5] = useState(false);
    const [isReadySari6, setIsReadySari6] = useState(false);
    const [isReadySari7, setIsReadySari7] = useState(false);
    const [isReadySari8, setIsReadySari8] = useState(false);

    //#region Saree1
    const MergeSaree = () => {
        try {
            let cbg = PoseBg(parseInt(bg))
            //alert(cbg)
            mergeImages([
                { src: cbg, x: 0, y: 0 },
                { src: saree1, x: 436, y: 2050 },
                { src: saree2, x: 521, y: 1530 },
                { src: saree3, x: 573, y: 1512 },
                { src: saree4, x: 476, y: 889 },
                { src: saree5, x: 660, y: 619 },
                { src: saree6, x: 935, y: 986 },
                { src: saree7, x: 440, y: 500 },
                { src: saree8, x: 479, y: 532 },
                { src: './assets/images/Draper/new/7.png', x: 0, y: 0 },
                { src: './assets/images/Draper/new/7abody.png', x: 0, y: 0 },
            ])
                .then(b64 => {
                    setDrapResult(b64)
                    //alert("sareedrap")
                    setIsCompleted("completed")
                }).catch(e => {
                    setDrapResult()
                    setIsCompleted("failed")
                    alert(e)
                });
        } catch (e) {
            setDrapResult()
            setIsCompleted("failed")
            alert(e)
        }
    }
    ///Saree item 1
    const [saree1, setSaree1] = useState("");
    const GetSaree1 = () => {
        setIsReadySari1(false);
        rotate(img1, 10).then(newsrc1 => {
            swirl(newsrc1, 100, 1460, 1522, -1.5).then(newsrc => {
                let initPP = "200,1643 182,1676 146,1737 124,1780 140,1795 152,1807 161,1813 240,1855 346,1904 407,1925 465,1937 510,1950 537,1956 562,1956 619,1956 729,1947 817,1934 829,1931 829,1904 826,1874 826,1846 832,1822 829,1795 814,1725 802,1664 792,1613 786,1552 777,1506 774,1461 771,1409 756,1321 750,1254 741,1193 738,1136 729,1081 695,1096 668,1142 635,1181 595,1236 553,1291 443,1427 370,1482 285,1540 222,1582"
                    .split(" ")
                    .map((c) => c.split(",").map(Number))
                    .map(([x, y]) => ({ x, y }));
                getCanvas(newsrc, initPP, (err, canvas) => {
                    if (!err) {
                        setSaree1(canvas.toDataURL());
                        setIsReadySari1(true)
                    }
                });
            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///Saree item 2
    const [saree2, setSaree2] = useState("");
    const GetSaree2 = () => {
        setIsReadySari2(false)
        swirl(img1, 1900, 1060, 1822, -1.5).then(newsrc1 => {
            rotate(newsrc1, 179).then(newsrc2 => {
                rotate(newsrc2, 152).then(newsrc => {
                    let initPP = "802,2758 831,2748 854,2735 897,2705 965,2653 1040,2598 1090,2558 1178,2476 1237,2411 1270,2369 1292,2319 1325,2247 1355,2143 1351,2012 1342,1865 1322,1691 1230,1711 1227,1897 1155,2077 1057,2251 897,2486 861,2594 838,2620 821,2643 815,2663"
                        .split(" ")
                        .map((c) => c.split(",").map(Number))
                        .map(([x, y]) => ({ x, y }));

                    getCanvas(newsrc, initPP, (err, canvas) => {
                        if (!err) {
                            setSaree2(canvas.toDataURL());
                            setIsReadySari2(true)
                        }
                    });
                }).catch(err => {
                })
            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///Saree item 3
    const [saree3, setSaree3] = useState("");
    const GetSaree3 = () => {
        setIsReadySari3(false)
        swirl(img1, 1900, 1060, 1822, -1.5).then(newsrc1 => {
            rotate(newsrc1, 179).then(newsrc2 => {
                rotate(newsrc2, 152).then(newsrc => {
                    let initPP = "661,2339 707,2280 746,2227 818,2135 890,2018 942,1926 995,1815 1027,1729 1034,1644 1034,1520 1014,1402 975,1421 942,1467 936,1520 910,1592 870,1664 818,1729 785,1788 753,1854 720,1893 661,1919 661,1965"
                        .split(" ")
                        .map((c) => c.split(",").map(Number))
                        .map(([x, y]) => ({ x, y }));

                    getCanvas(newsrc, initPP, (err, canvas) => {
                        if (!err) {
                            setSaree3(canvas.toDataURL());
                            setIsReadySari3(true)
                        }
                    });
                }).catch(err => {
                })
            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///Saree item 4
    const [saree4, setSaree4] = useState("");
    const GetSaree4 = () => {
        setIsReadySari4(false)
        swirl(img1, 1900, 1060, 1822, -1.5).then(newsrc1 => {
            rotate(newsrc1, 179).then(newsrc2 => {
                rotate(newsrc2, 146).then(newsrc => {
                    let initPP = "552,2368 575,2381 601,2381 627,2364 663,2338 696,2293 719,2227 748,2175 807,2084 853,2005 876,1927 889,1839 879,1698 784,1561 673,1339 588,1231 539,1267 487,1306 480,1319 497,1359 477,1388 470,1411 464,1443 470,1466 461,1515 454,1555 461,1600 477,1685 484,1747 500,1799 575,1995 585,2028 588,2061 588,2152 585,2188 572,2237 562,2289 555,2338"
                        .split(" ")
                        .map((c) => c.split(",").map(Number))
                        .map(([x, y]) => ({ x, y }));

                    getCanvas(newsrc, initPP, (err, canvas) => {
                        if (!err) {
                            setSaree4(canvas.toDataURL());
                            setIsReadySari4(true)
                        }
                    });
                }).catch(err => {
                })
            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///Saree item 5
    const [saree5, setSaree5] = useState("");
    const GetSaree5 = () => {
        setIsReadySari5(false)
        swirl(img1, 400, 260, 1022, -1.9).then(newsrc1 => {
            rotate(newsrc1, 179).then(newsrc2 => {
                rotate(newsrc2, 120).then(newsrc => {
                    let initPP = "859,2637 853,2529 847,2444 842,2370 830,2296 853,2245 870,2205 870,2143 859,2109 847,2080 830,2063 819,2052 830,2012 830,1989 830,1961 819,1938 813,1933 790,1904 773,1876 762,1876 739,1842 694,1773 688,1842 688,1876 671,1910 643,1933 620,1967 552,2029 660,2279 728,2410 824,2575"
                        .split(" ")
                        .map((c) => c.split(",").map(Number))
                        .map(([x, y]) => ({ x, y }));

                    getCanvas(newsrc, initPP, (err, canvas) => {
                        if (!err) {
                            setSaree5(canvas.toDataURL());
                            setIsReadySari5(true)
                        }
                    });

                }).catch(err => {
                })
            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///Saree item 6
    const [saree6, setSaree6] = useState("");
    const GetSaree6 = () => {
        setIsReadySari6(false)
        swirl(img1, 1800, 1486, 1056, 0.4).then(newsrc4 => {
            flip(newsrc4, 2800, 1600).then(newsrc1 => {
                rotate(newsrc1, 179).then(newsrc2 => {
                    rotate(newsrc2, 45).then(newsrc => {
                        let initPP = "2270,2716 2258,2668 2234,2513 2222,2401 2210,2270 2198,2202 2194,2111 2174,1923 2139,1740 2127,1601 2099,1561 2103,1410 2103,1374 2099,1322 2095,1250 2079,1187 2087,1123 2095,1075 2127,996 2127,996 2139,1019 2147,1071 2150,1083 2170,1111 2234,1179 2314,1270 2417,1378 2497,1458 2632,1585 2668,1625 2831,1760 2907,1828 2983,1900 3051,1959 2895,2178 2772,2350 2696,2441 2648,2493 2573,2549 2497,2601 2429,2648 2401,2664 2330,2692"
                            .split(" ")
                            .map((c) => c.split(",").map(Number))
                            .map(([x, y]) => ({ x, y }));

                        getCanvas(newsrc, initPP, (err, canvas) => {
                            if (!err) {
                                setSaree6(canvas.toDataURL());
                                setIsReadySari6(true)
                            }
                        });
                    }).catch(err => {
                    })
                }).catch(err => {
                })
            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///Saree item 7
    const [saree7, setSaree7] = useState("");
    const GetSaree7 = () => {
        setIsReadySari7(false)
        let initPP = "475,1470 482,1518 448,1549 419,1573 394,1598 378,1562 370,1534 371,1507 370,1486 358,1462 350,1435 347,1403 346,1365 346,1341 336,1318 331,1301 331,1293 352,1275 358,1245 366,1221 379,1190 392,1171 414,1147 438,1133 467,1122 491,1122 523,1126 566,1149 595,1190 694,1278 698,1317 698,1338 696,1354 680,1378 642,1411 616,1434 560,1446 536,1458"
            .split(" ")
            .map((c) => c.split(",").map(Number))
            .map(([x, y]) => ({ x, y }));
        getCanvas(img2, initPP, (err, canvas) => {
            if (!err) {
                setSaree7(canvas.toDataURL());
                setIsReadySari7(true)
            }
        });
    }
    ///Saree item 8
    const [saree8, setSaree8] = useState("");
    const GetSaree8 = () => {
        setIsReadySari8(false)
        swirl(img2, 200, 1400, 650, 1.3).then(newsrc => {
            let initPP = "307,1446 269,1376 250,1341 256,1302 272,1251 294,1222 314,1194 342,1171 371,1165 406,1165 435,1181 454,1210 477,1254 486,1286 490,1325 490,1360 490,1424 490,1437 442,1411 413,1395 368,1398 330,1414"
                .split(" ")
                .map((c) => c.split(",").map(Number))
                .map(([x, y]) => ({ x, y }));

            getCanvas(newsrc, initPP, (err, canvas) => {
                if (!err) {
                    setSaree8(canvas.toDataURL());
                    setIsReadySari8(true)
                }
            });
        }).catch(err => {
        })
    }
    ///////////////////////////////////////////
    //#endregion
    useEffect(() => {
        if (isReadySari1 && isReadySari2 && isReadySari3 && isReadySari4 && isReadySari5 && isReadySari6
            && isReadySari7 && isReadySari8) {
            MergeSaree()
            setIsCompleted("Merging Images")
            setIsReadySari1(false)
            setIsReadySari2(false)
            setIsReadySari3(false)
            setIsReadySari4(false)
            setIsReadySari5(false)
            setIsReadySari6(false)
            setIsReadySari7(false)
            setIsReadySari8(false)
        }
    }, [isReadySari1, isReadySari2, isReadySari3, isReadySari4, isReadySari5, isReadySari6
        , isReadySari7, isReadySari8])

    useEffect(() => {
        if (img1 && img2 && status == "init") {
            //alert("allimageloaded")
            setIsCompleted("Processing")
            setDrapResult()
            GetSaree1()
            GetSaree2()
            GetSaree3()
            GetSaree4()
            GetSaree5()
            GetSaree6()
            GetSaree7()
            GetSaree8()
        }
    }, [img1, img2, status])

    return (
        <></>
    )
}
