import React from 'react'
import { useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import AppToaster from '../../commonLib/AppToaster';
import { RazorKey } from './RazorpaySecrets';

export function RazerpayPayNow({ data, SuccessCall, FailedCall }) {
    const [alertConfirm, setAlertConfirm] = useState({ alert: null })
    function showAlert() {
        setAlertConfirm({
            alert: (
                <SweetAlert
                    info
                    showCancel
                    confirmBtnText="Ok"
                    confirmBtnBsStyle="danger"
                    title="No Data"
                    onConfirm={hideAlert}
                    onCancel={hideAlert}
                >
                    Please difine all valid data for payment
                </SweetAlert>
            )
        });
    }
    const hideAlert = () => {
        setAlertConfirm({
            alert: null
        });
    }
    const PayNow = () => {
        if (data) {
            var options = {
                "key": RazorKey,
                "amount": (data.amount * 100), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                "currency": "INR",
                "name": "Baithka",
                "description": data.description,
                "image": "/images/cubicleBaithka.png",
                "order_id": data.order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                "handler": function (response) {
                    SuccessCall(response.razorpay_payment_id, response.razorpay_order_id, response.razorpay_signature)
                    AppToaster("Please wait your payment is processing, Do not back, refresh or reload page.")
                },
                "prefill": {
                    "name": data.customer_name,
                    "email": data.customer_email,
                    "contact": data.customer_contact
                },
                "notes": {
                    "address": data.customer_address
                },
                "theme": {
                    "color": "#438EB9",
                }
            };
            var rzp1 = new window.Razorpay(options);
            rzp1.on('payment.failed', function (response) {
                // alert(response.error.code);
                // alert(response.error.description);
                // alert(response.error.source);
                // alert(response.error.step);
                // alert(response.error.reason);
                // alert(response.error.metadata.order_id);
                // alert(response.error.metadata.payment_id);
                FailedCall(response.error.description, response.error.metadata.payment_id)
                AppToaster("Please wait your payment is processing, Do not back, refresh or reload page.")
            });
            rzp1.open();
        }
        else {
            showAlert()
        }
    }

    return (
        <>
            {alertConfirm.alert}
            <button class="btn btn-sm btn-success" onClick={PayNow}>
                <i class="ace-icon fa fa-check"></i>
                Pay Now
            </button>
        </>
    )
}
