import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import * as voucherCrud from '../../_crud/voucherCrud'
import { Paging } from '../../commonLib/Paging';
import { PAGING_PAGE_SIZE } from '../../config/GlobalSettings';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { Spinner } from '../../commonLib/Spinner';
import 'moment-timezone';
import { EmptyData } from '../../commonLib/EmptyData';
import { loadingContext } from '../../commonLib/_lodingContext';
import { UserView } from '../Global/Elements/UserView';
import { GetETransactionModeName } from '../../enumCollections/ETransactionMode';
import { GetEVoucherTypeName } from '../../enumCollections/EVoucherType';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { ContactView } from '../Global/Elements/ContactView';

const initValues = {
    pageNo: 1,
    pageSize: PAGING_PAGE_SIZE
}

const initEdit = {
    id: 0,
    isEdit: false,
    value: undefined
}
export function ManageBankTransferReport() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [search, setSearch] = useState(initValues)
    const [bankTransfer, setBankTransfer] = useState([])
    const [LastPage, setLastPage] = useState(1)
    const [pagingData, setPagingData] = useState()
    const [edit, setEdit] = useState(initEdit)
    const [isLoading, setIsLoading] = useContext(loadingContext)

    const OnPageChange = (e) => {
        const { value } = e.target
        setSearch({ ...search, pageNo: value });
        getData({
            pageNo: value,
            pageSize: search.pageSize
        })
    }
    useEffect(() => {
        getData({ pageNo: 1, pageSize: search.pageSize })
    }, [])

    function getData(param) {
        setIsLoadingMini(true);
        voucherCrud.GetByVoucherPayment(param)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setBankTransfer(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoadingMini(false)
                        setBankTransfer([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoadingMini(false)
                    setBankTransfer([]);
                    AppToaster("Server Error / Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        setSearch({ ...search, pageNo: 1 })
        getData({ pageNo: 1, pageSize: search.pageSize })
        setTimeout(() => {
            event.target.complete();
        }, 1000);
    }

    const UpdateTransactionDetails = () => {
        setIsLoading(true);
        let param = { id: edit.id, transactionDetails: edit.value.transactionDetails }
        voucherCrud.UpdatePaymentTransaction(param)
            .then(res => {
                if (res.data.succeeded) {
                    setEdit(initEdit)
                    doRefresh()
                    AppToaster('Updated successfully.', 'success')
                    setIsLoading(false);
                }
                else {
                    AppToaster("Server Error /Invalid Try.", 'error')
                    setIsLoading(false);
                }

            }).catch(error => {
                AppToaster(error, 'error')
                setIsLoading(false);
            })
    }

    return (
        <Container title="Bank Transfer" doRefresh={doRefresh}>
            <Paging
                pagingData={pagingData}
                LastPage={LastPage}
                CurrentPage={search.pageNo}
                OnPageChange={OnPageChange} >

                {isLoadingMini ?
                    <Spinner />
                    :
                    bankTransfer && bankTransfer.length > 0 ?
                        <div className="tables-scroll">
                            <table className="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>#Id</th>
                                        <th>Received Date</th>
                                        <th>Voucher Id</th>
                                        <th>Type</th>
                                        <th>Contact</th>
                                        <th>Amount</th>
                                        <th>Mode</th>
                                        <th>Transaction Details</th>
                                        <th>Note</th>
                                        <th style={{ width: "43px" }}>By</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        bankTransfer.map((item, index) =>
                                            <tr key={item.Id}>
                                                <td>{item.id}</td>
                                                <td><Moment format="DD-MMM-YYYY">{item.receivedDate}</Moment></td>
                                                <td>
                                                    {item.voucher.voucherType == 1 &&
                                                        <Link to={"/advancevoucher/dashboard/" + item.voucherId} >{item.voucherId}
                                                        </Link>
                                                    }
                                                    {item.voucher.voucherType == 2 &&
                                                        <Link to={"/salaryvoucher/dashboard/" + item.voucherId} >{item.voucherId}
                                                        </Link>
                                                    }
                                                    {item.voucher.voucherType == 3 &&
                                                        <Link to={"/processingvoucher/dashboard/" + item.voucherId} >{item.voucherId}
                                                        </Link>
                                                    }
                                                    {item.voucher.voucherType == 4 &&
                                                        <Link to={"/productionvoucher/dashboard/" + item.voucherId} >{item.voucherId}
                                                        </Link>
                                                    }
                                                </td>
                                                <td>{GetEVoucherTypeName(item.voucher.voucherType)}</td>
                                                <td>
                                                    <Link to={"/contact/dashboard/" + item.voucher.contact.id}>
                                                        <ContactView item={item.voucher.contact} uid={"C" + index} />
                                                    </Link>
                                                </td>
                                                <td className='bolder'>₹ {item.amount}</td>
                                                <td>{GetETransactionModeName(item.transactionMode)}</td>
                                                <td>{edit.isEdit && item.id == edit.id ?
                                                    <input className="form-control" type="text" placeholder="Transaction Details" name="transactionDetails" value={edit.value.transactionDetails} onChange={(e) => setEdit({ ...edit, value: { ...edit.value, transactionDetails: e.target.value } })} />
                                                    :
                                                    <span>{item.transactionDetails}</span>
                                                }
                                                    &nbsp;&nbsp;&nbsp;
                                                    <div class="btn-group">
                                                        {edit.isEdit && item.id == edit.id ?
                                                            isLoading ?
                                                                <button disabled class="btn btn-xs btn-info">
                                                                    <i class="ace-icon fa fa-pencil bigger-120"></i> Updating...
                                                                </button>
                                                                :
                                                                <>
                                                                    <button onClick={UpdateTransactionDetails} class="btn btn-xs btn-info">
                                                                        <i class="ace-icon fa fa-pencil bigger-120"></i> Update
                                                                    </button>
                                                                    <button onClick={() => setEdit(initEdit)} class="btn btn-xs btn-default">
                                                                        Cancel
                                                                    </button>
                                                                </>
                                                            :
                                                            <a onClick={() => setEdit({ id: item.id, value: item, isEdit: true })} >
                                                                <i class="ace-icon fa fa-pencil bigger-120"></i>
                                                            </a>
                                                        }
                                                    </div>
                                                </td>
                                                <td>{item.note}</td>
                                                <td>
                                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                        :
                        <EmptyData>
                            No records found.
                            <br />
                            <br />
                        </EmptyData>
                }
            </Paging>
        </Container >
    )
}
