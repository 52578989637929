import React from 'react'
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react'
import GetLoginInfo from '../../auth/_userContext/GetLoginInfo';
import AppToaster from '../../commonLib/AppToaster';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { organizationContext } from '../Organization/organizationContext';
import { RazerpayPayNow } from '../PaymentGateway/RazerpayPayNow';
import * as rechargeHistoryCrud from './../../_crud/rechargeHistoryCrud'
import { PaymentFailed } from './PaymentFailed';
import { PaymentSuccess } from './PaymentSuccess';

export function AddSpaceRecharge({ setRechargePlan, data }) {
    const [organization, setOrganization] = useContext(organizationContext);
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [item, setItem] = useState(data)
    const [customerData, setCustomerData] = useState()
    const [isPaymentDone, setIsPaymentDone] = useState(false)
    const [isPaymentSuccess, setIsPaymentSuccess] = useState(true)
    const userData = GetLoginInfo()

    const GoToPlan = () => {
        setRechargePlan(0)
    }
    useEffect(() => {
        InitRecharge()
    }, [])
    function InitRecharge() {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        rechargeHistoryCrud.InitIncreaseSpace(item.increaseSpaceGB)
            .then(res => {
                if (res.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data
                        setCustomerData({
                            amount: hdata.totalAmount,
                            description: "Payment For Increase Space plan " + hdata.increaseSpaceGB + " Gb for " + hdata.months + " Months",
                            order_id: hdata.orderId,
                            customer_name: organization.name,
                            customer_email: organization.email,
                            customer_contact: organization.phone,
                            customer_address: organization.address + ", " + organization.city + ", " + organization.state,
                            rechargeId: hdata.rechargeId
                        })
                        setItem(hdata);
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false)
                    }
                    else {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false)
                        setItem();
                    }
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false)
                    setItem();
                    AppToaster(res.data.result.message, 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false)
                AppToaster(error, 'error')
            })
    }

    const SuccessCall = (paymentid, orderid, signature) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        rechargeHistoryCrud.AfterIncreaseSpace(item.rechargeId, paymentid, signature)
            .then(res => {
                if (res.data) {
                    if (res.data.succeeded) {
                        setIsPaymentDone(true)
                        setIsPaymentSuccess(true)
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false)
                    }
                    else {
                        setIsPaymentDone(true)
                        setIsPaymentSuccess(false)
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false)
                    }
                }
                else {
                    setIsPaymentDone(true)
                    setIsPaymentSuccess(false)
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false)
                    AppToaster(res.data.result.message, 'error')
                }
            })
            .catch(error => {
                setIsPaymentDone(true)
                setIsPaymentSuccess(false)
                setIsLoadingMini(false);
                setIsLoadingLocal(false)
                AppToaster(error, 'error')
            })
    }
    const FailedCall = (message, paymentid) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        rechargeHistoryCrud.FailedIncreaseSpace(item.rechargeId, paymentid, message)
            .then(res => {
                if (res.data) {
                    if (res.data.succeeded) {
                        setIsPaymentDone(true)
                        setIsPaymentSuccess(false)
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false)
                    }
                    else {
                        setIsPaymentDone(true)
                        setIsPaymentSuccess(false)
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false)
                    }
                }
                else {
                    setIsPaymentDone(true)
                    setIsPaymentSuccess(false)
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false)
                    AppToaster(res.data.result.message, 'error')
                }
            })
            .catch(error => {
                setIsPaymentDone(true)
                setIsPaymentSuccess(false)
                setIsLoadingMini(false);
                setIsLoadingLocal(false)
                AppToaster(error, 'error')
            })
    }

    return (
        <>
            {isPaymentDone ?
                <>
                    {isPaymentSuccess ?
                        <PaymentSuccess>
                            <h2>Add Space <b>{item.increaseSpaceGB} gb</b> for <b>{item.months} months</b> has been successfull done.</h2>
                        </PaymentSuccess>
                        :
                        <PaymentFailed setRechargePlan={setRechargePlan}>
                            <h2>Add Space <b>{item.increaseSpaceGB} gb</b> for <b>{item.months} months</b> has been failed.</h2>
                        </PaymentFailed>
                    }
                </>
                :
                <>
                    {item &&
                        <div class="row">
                            <div class="col-xs-12">
                                <div class="space-6"></div>

                                <div class="row">
                                    <div class="col-sm-10 col-sm-offset-1">
                                        <div class="widget-box transparent">
                                            <div class="widget-header widget-header-large">
                                                <h3 class="widget-title grey lighter">
                                                    <a style={{ cursor: "pointer" }} onClick={GoToPlan}><i class="menu-icon fa fa-arrow-left"></i> Back To Plan </a>
                                                    | Add Space <b>{item.increaseSpaceGB} gb</b> for <b>{item.months} months</b>
                                                </h3>

                                                <div class="widget-toolbar hidden-480">
                                                    <span class="invoice-info-label">Receipt:</span>
                                                    <span class="green">#{userData.orgId}_{item.rechargeId}</span>
                                                </div>
                                            </div>

                                            <div class="widget-body">
                                                <div class="widget-main padding-24">
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <div class="row">
                                                                <div class="col-xs-11 label label-lg label-info arrowed-in arrowed-right">
                                                                    <b>Company Info</b>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <ul class="list-unstyled spaced">
                                                                    <li className="bolder">
                                                                        <i class="ace-icon fa fa-caret-right blue"></i>CUBICLE8 (Cubicle Eight)
                                                                    </li>

                                                                    <li>
                                                                        <i class="ace-icon fa fa-caret-right blue"></i>8, Amjum Manzil, Mau-275101
                                                                    </li>

                                                                    <li>
                                                                        <i class="ace-icon fa fa-caret-right blue"></i>Uttar Pradesh, India
                                                                    </li>

                                                                    <li>
                                                                        <i class="ace-icon fa fa-caret-right blue"></i>
                                                                        Phone: <b class="red">+91 95599 52123</b>
                                                                    </li>
                                                                    <li>
                                                                        <i class="ace-icon fa fa-caret-right blue"></i>
                                                                        GSTN: <b class="red">09AVBPA6995Q1ZS</b>
                                                                    </li>

                                                                    <li class="divider"></li>

                                                                    <li>
                                                                        <i class="ace-icon fa fa-caret-right blue"></i>
                                                                        Paymant Info
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>

                                                        <div class="col-sm-6">
                                                            <div class="row">
                                                                <div class="col-xs-11 label label-lg label-success arrowed-in arrowed-right">
                                                                    <b>Customer Info</b>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <ul class="list-unstyled  spaced">
                                                                    <li className="bolder">
                                                                        <i class="ace-icon fa fa-caret-right green"></i>{organization.name}
                                                                    </li>

                                                                    <li>
                                                                        <i class="ace-icon fa fa-caret-right green"></i>{organization.address}, {organization.city}-{organization.pin}
                                                                    </li>

                                                                    <li>
                                                                        <i class="ace-icon fa fa-caret-right green"></i>{organization.state}
                                                                    </li>
                                                                    <li>
                                                                        <i class="ace-icon fa fa-caret-right blue"></i>
                                                                        Phone: <b class="red">{organization.phone}</b>
                                                                    </li>
                                                                    <li>
                                                                        <i class="ace-icon fa fa-caret-right blue"></i>
                                                                        GSTN: <b class="red">{organization.gstn ? organization.gstn : "N/A"}</b>
                                                                    </li>
                                                                    <li class="divider"></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="space"></div>

                                                    <div>
                                                        <table class="table table-striped table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th class="center">#</th>
                                                                    <th>Description</th>
                                                                    <th>Total</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                <tr>
                                                                    <td class="center">1</td>
                                                                    <td>
                                                                        Monthly Rate <small>(inclusive gst)</small>
                                                                    </td>
                                                                    <td>₹{Number(item.monthlyRate).toFixed(2)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="center">2</td>

                                                                    <td>
                                                                        Net Amount
                                                                    </td>
                                                                    <td>₹{Number(item.netAmount).toFixed(2)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="center">3</td>

                                                                    <td>
                                                                        GST %
                                                                    </td>
                                                                    <td>{item.gstSlab}%</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="center">4</td>

                                                                    <td>
                                                                        GST Amount
                                                                    </td>
                                                                    <td>₹{Number(item.gstAmount).toFixed(2)}</td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div class="hr hr8 hr-double hr-dotted"></div>

                                                    <div class="row">
                                                        <div class="col-sm-5 pull-right">
                                                            <h4 class="pull-right">
                                                                Total amount :
                                                                <span class="red">₹{Number(item.totalAmount).toFixed(2)}</span>
                                                            </h4>
                                                        </div>
                                                        <div class="col-sm-7 pull-left"> Extra Information<br />
                                                            Note: Clicking on pay now will automatically accepts <a href="https://baithka.com/term" target="_blank">Term &amp; Conditions</a>
                                                        </div>
                                                    </div>

                                                    <div class="space-6"></div>
                                                    <div class="row well">
                                                        <div className="col-sm-8">
                                                            Thank you for choosing Baithka Powered by Cubicle Eight products.
                                                            We believe you will be satisfied by our services.
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <span className="pull-right">
                                                                {isLoadingLocal ?
                                                                    <button class="btn btn-sm btn-success" disabled>
                                                                        <i class="ace-icon fa fa-check"></i>
                                                                        Please wait
                                                                    </button>
                                                                    :
                                                                    <RazerpayPayNow SuccessCall={SuccessCall} FailedCall={FailedCall} data={customerData} />
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
        </>
    )
}
