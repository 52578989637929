import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { AddProduct } from '../../Products/AddProduct';
import * as warehouseCrud from '../../../_crud/warehouse/warehouseCrud'
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import GetLoginInfo from '../../../auth/_userContext/GetLoginInfo';

export function ProductSelectorView({ data = null, ishide = true, dataSetter, isDataSetter = false }) {
    const [hide, setHide] = useState(ishide);
    const [dataId, setDataId] = useState("");
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const userData = GetLoginInfo()

    useEffect(() => {
        if (data && isDataSetter && dataId) {
            //alert("getfunction")
            getProducts(data.id)
        }
    }, [dataId])

    useEffect(() => {
        if (data && isDataSetter) {
            //alert(data.id)
            setDataId(data.id)
        }
        else {
            if (isDataSetter) {
                setDataId("")
            }
        }
    }, [data])

    function getProducts(id) {
        setIsLoadingMini(true);
        warehouseCrud.GetByProductId(id, userData.warehouseId)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        dataSetter(hdata);
                    }
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
            })
    }

    return (
        <>
            <div className="center">
                {data &&
                    (hide ?
                        <a style={{ cursor: "pointer" }} onClick={() => setHide(false)}><i class="ace-icon fa fa-eye blue"></i> View product details</a>
                        :
                        <a style={{ cursor: "pointer" }} onClick={() => setHide(true)}><i class="ace-icon fa fa-eye-slash blue"></i> Hide product details</a>
                    )
                }
                &nbsp;&nbsp;<a href="#newproduct-modal" data-toggle="modal" style={{ cursor: "pointer" }} ><i class="ace-icon fa fa-plus green"></i> Add New</a>
            </div>
            {
                data && !hide &&
                <>
                    <div class="col-xs-12">
                        <div className="widget-box">
                            <div class="widget-header widget-header-flat widget-header-small">
                                <h5 class="widget-title">
                                    Product Details
                                </h5>
                            </div>

                            <div class="row">
                                <div class="profile-user-info profile-user-info-striped">
                                    <div class="profile-info-row">
                                        <div class="profile-info-name">Selected Product </div>

                                        <div class="profile-info-value">
                                            <span class="editable editable-click"><b><Link to={"/product/dashboard/" + data.id}>{data.productName} ({data.id})</Link></b></span>
                                        </div>
                                    </div>

                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Category </div>

                                        <div class="profile-info-value">
                                            <span >{data.productCategory.catName}</span>
                                        </div>
                                    </div>

                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Stock - {data.unit} </div>

                                        <div class="profile-info-value">
                                            <span>Main:{Number(data.mainStock).toFixed(2)}, Raw:{Number(data.rawStock).toFixed(2)}, Defective:{Number(data.defectiveStock).toFixed(2)}, Rejected:{Number(data.rejectedStock).toFixed(2)}, Processing:{Number(data.processingStock).toFixed(2)}, Global:{Number(data.globalStock).toFixed(2)}</span>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            <div id="newproduct-modal" class="modal fade" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                            <h3 class="smaller lighter blue no-margin">Add Product</h3>
                        </div>

                        <div class="modal-body">
                            <AddProduct isModelView={true} />
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}
