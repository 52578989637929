import React, { useContext, useEffect } from 'react'
import { Route, useHistory } from 'react-router-dom';
import AppToaster from '../commonLib/AppToaster';
import { Error404 } from '../pages/others/404';
import { authContext } from './_userContext/authContext';
import ClearLoginInfo from './_userContext/ClearLoginInfo';
import GetLoginInfo from './_userContext/GetLoginInfo';

export const ProtectedRoute = ({ component: Component, role: Role, ...rest }) => {
    const history = useHistory();
    const [isLogin, setIsLogin] = useContext(authContext);
    return (
        <Route {...rest} render={
            props => {
                try {
                    let data = GetLoginInfo();
                    if (data) {
                        if (Role.includes(data.role)) {
                            return <Component {...props} />;
                        }
                        else {
                            return <Error404 />
                        }
                    }
                    else {
                        ClearLoginInfo();
                        setIsLogin(false);
                        history.push("/login")
                    }
                } catch (error) {
                    AppToaster(error + "Login expired, please login again.", "error");
                    ClearLoginInfo();
                    setIsLogin(false);
                    history.push("/login")
                }
            }
        } />
    );
}