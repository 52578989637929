import React, { useContext, useEffect, useRef, useState } from 'react'
import { GetEVoucherLinkedIdTypeName } from '../../../enumCollections/EVoucherLinkedIdType'
import { GetEPaymentStatusName } from '../../../enumCollections/EPaymentStatus'
import { GetEVoucherTypeName } from '../../../enumCollections/EVoucherType'
import { Spinner } from '../../../commonLib/Spinner'
import { Paging } from '../../../commonLib/Paging'
import { Container } from '../../../base/Container'
import { Link } from 'react-router-dom'
import AppToaster from '../../../commonLib/AppToaster'
import * as cashBankCashVoucherCrud from '../../../_crud/vouchers/cashBankCashVoucherCrud'
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext'
import * as voucherCrud from '../../../_crud/voucherCrud'
import { DEFAULT_USER_IMG, PAGING_PAGE_SIZE } from '../../../config/GlobalSettings'
import Moment from 'react-moment';
import 'moment-timezone';
import { EmptyData } from '../../../commonLib/EmptyData'
import DatePicker from "react-datepicker";
import GetCurrentDateForSearch from '../../../commonLib/dateFormat'
import { MyAlert } from '../../../commonLib/MyAlert'
import { UserView } from '../../Global/Elements/UserView'
import { ContactView } from '../../Global/Elements/ContactView'
import { PrintStarter } from '../../Prints/PrintStarter'
import { CashBankCashVoucherPrint } from '../../Prints/CashBankCashVoucherPrint'
import GetLoginInfo from '../../../auth/_userContext/GetLoginInfo'
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater'

const initValues = {
    searchTerm: "",
    pageNo: 1,
    pageSize: PAGING_PAGE_SIZE,
    fromDate: new Date(GetCurrentDateForSearch(-30)),
    toDate: new Date(GetCurrentDateForSearch(0)),
    type: 5
}

export function ManageCashBankCashVoucher() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [search, setSearch] = useState(initValues)
    const [cashBankCashVouchers, setCashBankCashVouchers] = useState([])
    const [LastPage, setLastPage] = useState(1)
    const [pagingData, setPagingData] = useState()
    const userData = GetLoginInfo()

    useEffect(() => {
        getData()
    }, [search.pageNo])

    const OnPageChange = (e) => {
        const { value } = e.target
        setSearch({ ...search, pageNo: value });
    }

    function onSearch() {
        if (userData.isWarehouse == 0)
            getData()
        else
            getDataWarehouse()
    }


    function getData() {
        setIsLoadingMini(true);
        voucherCrud.GetByVoucherType(search)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setCashBankCashVouchers(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoadingMini(false)
                        setCashBankCashVouchers([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoadingMini(false)
                    setCashBankCashVouchers([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }
    function getDataWarehouse() {
        setIsLoadingMini(true);
        voucherCrud.GetByVoucherTypeWarehouse({ ...search, warehouseId: userData.warehouseId })
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setCashBankCashVouchers(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoadingMini(false)
                        setCashBankCashVouchers([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoadingMini(false)
                    setCashBankCashVouchers([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        setSearch({ ...search, pageNo: 1 })
        onSearch()
        setTimeout(() => {
            event.target.complete();
        }, 1000);
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        cashBankCashVoucherCrud.VoucherRemove(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    AppToaster('Deleted successfully.', 'success')
                    onSearch()
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by someone,could not delete.', 'error')
                }

            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }
    const [printData, setPrintData] = useState()
    const printRef = useRef();

    return (
        <>
            <div style={{ display: "none" }}>
                <CashBankCashVoucherPrint printData={printData} ref={printRef} />
            </div>
            <Container title="Cash Bank Cash Vouchers" doRefresh={doRefresh} >

                <Link to="/cashbankcashvoucher/add" class="btn btn-sm btn-success">
                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                    New
                </Link>
                <div class="hr dotted"></div>
                <div class="form-horizontal">
                    <div className="row">
                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker selectsStart startDate={search.fromDate} endDate={search.toDate} className="form-control input-date-padding" selected={search.fromDate} onChange={(date) => setSearch({ ...search, fromDate: date })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker selectsEnd startDate={search.fromDate} endDate={search.toDate} className="form-control input-date-padding" selected={search.toDate} onChange={(date) => setSearch({ ...search, toDate: date })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-7">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <TextBoxTranslater style={{paddingLeft: "24px"}} value={search.searchTerm} onChange={(e) => setSearch({ ...search, searchTerm: e.target.value })} className="form-control" type="text" id="form-field-icon-1" placeholder="Search eg. id, contact etc" />
                                    <i class="ace-icon fa fa-search blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-1">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <button onClick={onSearch} to="/cashbankcashvoucher/add" class="btn btn-sm btn-info pull-right">
                                    <i class="ace-icon fa fa-search bigger-110"></i>
                                    Run
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <Paging
                    pagingData={pagingData}
                    LastPage={LastPage}
                    CurrentPage={search.pageNo}
                    OnPageChange={OnPageChange} >

                    {isLoadingMini ?
                        <Spinner />
                        :
                        cashBankCashVouchers && cashBankCashVouchers.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th style={{ width: "20px" }}>Voucher Id</th>
                                            <th>Voucher Date</th>
                                            <th>Contact</th>
                                            <th>Amount</th>
                                            <th>Details</th>
                                            <th>Note</th>
                                            <th>By</th>
                                            <th style={{ width: "43px" }}></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {cashBankCashVouchers.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>
                                                    <div class="btn-group">
                                                        <PrintStarter printRef={printRef} setPrintData={setPrintData} item={item} />
                                                    </div>
                                                </td>
                                                <td>{item.id}</td>
                                                <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.voucherDate}</Moment></div></td>
                                                <td>
                                                    <Link to={"/contact/dashboard/" + item.contactId}>
                                                        <ContactView item={item.contact} uid={"C" + index} />
                                                    </Link>
                                                </td>
                                                <td> <b> ₹ {Number(item.totalAmount).toFixed(2)} </b></td>
                                                <td><div dangerouslySetInnerHTML={{ __html: item.linkedDetails }} /></td>
                                                <td><div style={{ width: "150px" }}>{item.note}</div></td>
                                                <td>
                                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                            <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                        </MyAlert>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </Paging>
            </Container >
        </>
    )
}
