import React, { useContext, useEffect, useState } from 'react'
import * as voucherCrud from '../../../_crud/voucherCrud'
import * as reportCrud from '../../../_crud/reportCrud'
import { EmptyData } from '../../../commonLib/EmptyData'
import { GetELedgerTypeName } from '../../../enumCollections/ELedgerType'
import { GetELedgerGroupName } from '../../../enumCollections/ELedgerGroup'
import { Container } from '../../../base/Container'
import { PagingEmpty } from '../../../commonLib/PagingEmpty'
import { Spinner } from '../../../commonLib/Spinner'
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext'
import AppToaster from '../../../commonLib/AppToaster'
import { Link } from 'react-router-dom'
import { ContactView } from '../Elements/ContactView'


export function AdvanceContactList() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [advance, setAdvance] = useState([])


    useEffect(() => {
        GetData()
    }, []);

    function GetData() {
        setIsLoadingMini(true);
        reportCrud.GetAdvanceByContact()
            .then(res => {
                if (res.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.contact
                        setAdvance(hdata);
                    }
                    else {
                        setAdvance([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setAdvance([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh() {
        GetData()
    }

    return (
        <>
            <Container title="Remaining Advance By Contact" doRefresh={doRefresh}>
                <PagingEmpty text={"Total " + advance.length + " items."}>
                    {isLoadingMini ?
                        <Spinner />
                        :
                        advance && advance.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Contact</th>
                                            <th>Fiscal Year</th>
                                            <th>Remaining</th>
                                            <th>Received</th>
                                            <th>Total</th>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        {advance.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <Link to={"/contact/dashboard/" + item.contact.id} >
                                                        <ContactView item={item.contact} uid={"C" + index} />
                                                    </Link>
                                                </td>
                                                <td>{item.fiscalYear}</td>
                                                <td><span className="red"><b>₹ {Number(item.remainingAdvance).toFixed(2)}</b></span></td>
                                                <td>₹ {Number(item.recievedAdvance).toFixed(2)}</td>
                                                <td>₹ {Number(item.totalAdvance).toFixed(2)}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </PagingEmpty>
            </Container >
        </>
    )
}
