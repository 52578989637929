import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import * as Yup from 'yup'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { useFormik } from 'formik';
import { GetEProductionCategoryType } from '../../enumCollections/EProductionCategoryType';
import *as productionCategoryCrud from '../../_crud/productionCategoryCrud'
import { LoadingButton } from '../../commonLib/LoadingButton';
import { useHistory, useParams } from 'react-router-dom';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

const initialValues = {
    id: undefined,
    productionCatName: undefined,
    type: undefined
}
export function EditCategory() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const { id } = useParams()
    const history = useHistory()

    const Schema = Yup.object().shape({
        productionCatName: Yup.string().min(3, "Category Name must between 3 to 15 characters").max(15, "Category Name must between 3 to 15 characters").required("Category Name is required."),
        type: Yup.string().required("Type is required.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });
    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true);
        productionCategoryCrud.Edit(value)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false);
                    setIsLoadingLocal(false);
                    AppToaster('Updated successfully.', 'success')
                    history.goBack()
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }
    useEffect(() => {
        getProductionCategory()
    }, []);


    function getProductionCategory() {
        setIsLoadingLocal(true);
        productionCategoryCrud.GetById(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        formik.setValues({
                            id: hdata.id,
                            productionCatName: hdata.productionCatName,
                            type: hdata.type
                        })
                    }
                    else {
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                }
                setIsLoadingLocal(false);
            })
            .catch(error => {
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    return (
        <Container title="Edit Production Category" isBack="true">
            <form onSubmit={formik.handleSubmit} class="form-horizontal">
                <div className="row">
                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Production Category Name</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="Production Catrgory Name" name="productionCatName" value={formik.values.productionCatName} onChange={(e) => formik.setValues({ ...formik.values, productionCatName: e.target.value })} />
                                {
                                    formik.touched.productionCatName &&
                                        formik.errors.productionCatName ? (
                                        <label className="text-danger" >
                                            {formik.errors.productionCatName}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Type</label>
                                <select className="form-control" name="type" value={formik.values.type} onChange={(e) => formik.setValues({ ...formik.values, type: e.target.value })}>
                                    {
                                        GetEProductionCategoryType.EProductionCategoryType.map((data, index) =>
                                            <option key={data.Id} value={data.Id}>{data.Name}</option>
                                        )
                                    }
                                </select>
                                {
                                    formik.touched.type &&
                                        formik.errors.type ? (
                                        <label className="text-danger" >
                                            {formik.errors.type}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div class="clearfix form-actions">
                    <div class="pull-right">
                        {isLoadingLocal ?
                            <LoadingButton />
                            :
                            <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                <i class="ace-icon fa fa-check bigger-110"></i>
                                Update
                            </button>}
                    </div>
                </div>

            </form>
        </Container >
    )
}
