import React, { useContext } from 'react'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import GetLoginInfo from '../auth/_userContext/GetLoginInfo'
import SetLoginInfo from '../auth/_userContext/SetLoginInfo'
import { BackButton } from '../commonLib/BackButton'
import { DiskSpaceLowMessage } from '../commonLib/DiskSpaceLowMessage'
import { ExpireMessage } from '../commonLib/ExpireMessage'
import { languageContext } from '../commonLib/languageContext'
import { MyAlert } from '../commonLib/MyAlert'
import SetLanguage from '../commonLib/SetLanguage'
import { Spinner } from '../commonLib/Spinner'
import { loadingContext } from '../commonLib/_lodingContext'
import { diskSizeContext } from '../pages/Global/Elements/diskSizeContext'
import { organizationContext } from '../pages/Organization/organizationContext'
import { DashboardTitle } from './DashboardTitle'
import { Footer } from './Footer'
import Menu from './Menu'

export function Container({ title, doRefresh,
    isBack = false,
    isContact = false,
    isProduction = false,
    isProduct = false,
    isProcessing = false,
    isOrder = false,
    isVoucher = false,
    isModelView,
    isProtected = true,
    ...props }) {
    const history = useHistory()
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [organization, setOrganization] = useContext(organizationContext);
    const [language, setLanguage] = useContext(languageContext);
    const [diskSize, setDiskSize] = useContext(diskSizeContext);

    useEffect(() => {
        document.title = title + " - BAITHKA - A TEXTILE MANUFACTURING"
    }, [])
    const userData = GetLoginInfo()

    function revertToWarehouse() {
        let d = { ...userData, warehouseCode: "", warehouseId: "", isWarehouse: 0 }
        SetLoginInfo(d)
        window.location.replace("/");
    }

    const SelectLanguage = (lang) => {
        setLanguage(lang)
        SetLanguage(lang)
    }
    return (
        <>
            {
                isModelView ?
                    props.children
                    :
                    <div class="page-content">
                        <div class="page-header">
                            <h3 style={{ marginTop: "10px" }}>{isBack && <a style={{ cursor: "pointer" }} onClick={() => history.goBack()}><i class="menu-icon fa fa-arrow-left"></i></a>} {title} {doRefresh && <a onClick={doRefresh} title="Reload" style={{ marginLeft: "10px", cursor: "pointer" }}>
                                <i class="ace-icon fa fa-refresh green"></i>
                            </a>}
                                {
                                    <div class="btn-group pull-right">
                                        <button data-toggle="dropdown" title="Select Input Language" class="btn btn-xs btn-white" style={{ paddingBottom: "1px" }} aria-expanded="false">
                                            {language.toUpperCase()} <span class="ace-icon fa fa-caret-down icon-only"></span>
                                        </button>

                                        <ul class="dropdown-menu dropdown-info dropdown-menu-right">
                                            <li style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                                                <span>Input Language</span><br />
                                                <span><small>Not a page/app translater.</small></span>
                                            </li>
                                            <li class="divider"></li>
                                            <li>
                                                <a className={language == "en" && "mySelectedItem"} onClick={() => SelectLanguage("en")}>English</a>
                                            </li>

                                            <li>
                                                <a className={language == "hi" && "mySelectedItem"} onClick={() => SelectLanguage("hi")}>Hindi</a>
                                            </li>

                                            <li>
                                                <a className={language == "ur" && "mySelectedItem"} onClick={() => SelectLanguage("ur")}>Urdu</a>
                                            </li>
                                            <li>
                                                <a className={language == "gu" && "mySelectedItem"} onClick={() => SelectLanguage("gu")}>Gujarati</a>
                                            </li>
                                        </ul>
                                    </div>

                                }
                                {userData.role == "101" && userData.isWarehouse == 1 &&
                                    <MyAlert confirmBtnText={"Yes, Do it!"} title="Revert to MAIN Warehouse!" onConfirm={revertToWarehouse} isFormated={false}>
                                        <a class="btn btn-xs btn-yellow pull-right" style={{ paddingTop: "0px", paddingBottom: "0px" }} title="Revert To MAIN">
                                            <i class="ace-icon fa fa-compress"></i>
                                        </a>
                                    </MyAlert>
                                }
                                {userData.isWarehouse == 1 ?
                                    <span class="label label-lg label-pink arrowed pull-right bolder" style={{
                                        fontWeight: "bold",
                                        // textShadow: "1px 2px 2px #434343"
                                    }}>{userData.warehouseCode} <i class="menu-icon fa fa-industry"></i></span>
                                    :
                                    <span class="label label-lg label-info arrowed pull-right bolder" style={{
                                        fontWeight: "bold",
                                        // textShadow: "1px 2px 2px #434343"
                                    }}><i class="menu-icon fa fa-industry"></i> MAIN</span>
                                }

                            </h3>
                            <DashboardTitle isContact={isContact}
                                isProduction={isProduction}
                                isProduct={isProduct}
                                isProcessing={isProcessing}
                                isOrder={isOrder}
                                isVoucher={isVoucher} />
                        </div>
                        <div class="row">
                            {isProtected ?
                                <div class="col-xs-12">
                                    {(organization && organization.remainingDaysExpire <= 0) ?
                                        <ExpireMessage />
                                        :
                                        (organization && diskSize && diskSize.totalSize >= organization.allotedDiskSizeMB) ?
                                            <DiskSpaceLowMessage />
                                            :
                                            <>
                                                {isLoading ? <Spinner /> : props.children}
                                            </>
                                    }
                                </div>
                                :
                                <div class="col-xs-12">
                                    {isLoading ? <Spinner /> : props.children}
                                </div>
                            }
                        </div>

                        <br />
                        <br />
                        <Footer />
                    </div>
            }
        </>
    )
}
