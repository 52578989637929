import GetLoginInfo from "../../auth/_userContext/GetLoginInfo";
import { OrgId } from "../../config/GlobalSettings";
import { createAxiosWithToken, createAxiosWithTokenFile } from "../../initApi/createAxios";


export async function GetAll(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Cheque/BankTransaction/GetAll",param)
    }
}
export async function GetAllByBalance(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Cheque/BankTransaction/GetAllByBalance",param)
    }
}
export async function GetAllByStatus(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Cheque/BankTransaction/GetAllByStatus",param)
    }
}

export async function Add(transaction) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Cheque/BankTransaction/Add", transaction)
    }
}

export async function GetById(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Cheque/BankTransaction/GetById?Id=" + id)
    }
}

export async function ChangeStatus(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Cheque/BankTransaction/ChangeStatus",param)
    }
}

export async function UploadPhoto(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithTokenFile(loginData.token, OrgId()).post("Cheque/BankTransaction/UploadPhoto", param)
    }
}

export async function Remove(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Cheque/BankTransaction/Remove?Id="+id)
    }
}
