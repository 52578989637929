import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Container } from '../../base/Container';
import { loadingContext } from '../../commonLib/_lodingContext';
import { dashboardContext } from '../Global/dashboardContext';
import *as processingCrud from '../../_crud/processingCrud'
import *as reportCrud from '../../_crud/reportCrud'

export function ProcessingDashBoard() {
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [dashboardData, setDashboardData] = useContext(dashboardContext)
    const [report, setReport] = useState()
    const { id } = useParams()

    function doRefresh() {
        getProcessing()
    }

    useEffect(() => {
        getProcessing()
    }, []);

    function getProcessing() {
        setIsLoading(true);
        processingCrud.GetById(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setDashboardData({ ...dashboardData, processingdata: hdata })
                        GetReportByProcessing()
                    }
                    else {
                    }
                }
                else {
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
            })
    }

    function GetReportByProcessing() {
        setIsLoading(true);
        reportCrud.GetReportByProcessing(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setReport(hdata)
                    }
                    else {
                    }
                }
                else {
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
            })
    }

    return (
        <Container title="Processing Dashboard" doRefresh={doRefresh} isBack="true" isProcessing="true">
            <Link to={"/processing/dashboard/" + id + "/wage"} style={{ marginRight: "5px" }} class="btn btn-sm btn-purple">
                <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                Wage
            </Link>

            <Link to={"/processing/edit/" + id} class="btn btn-sm btn-info">
                <i class="ace-icon fa fa-pencil bigger-110"></i>
                Edit
            </Link>
            <div class="hr dotted"></div>
            <div class="clearfix">
                <br />
                {report &&
                    <>
                        <div className="row">
                            <div className="col-sm-2"></div>
                            <div class="col-sm-8 infobox-container">
                                <h4>Processing Stock</h4>


                                <div class="infobox infobox-orange">
                                    <div class="infobox-data">
                                        <span class="infobox-data-number">{report.stock} {report.unit} </span>
                                        <div class="infobox-content">Under Processing</div>
                                    </div>
                                </div>
                                <div class="infobox infobox-green">
                                    <div class="infobox-data">
                                        <span class="infobox-data-number">{report.globalStock - report.stock} {report.unit} </span>
                                        <div class="infobox-content">Processed</div>
                                    </div>
                                </div>
                                <div class="infobox infobox-blue">
                                    <div class="infobox-data">
                                        <span class="infobox-data-number">{report.globalStock} {report.unit} </span>
                                        <div class="infobox-content">Total</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </Container>
    )
}