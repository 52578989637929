import React from 'react'
import QRCode from 'react-qr-code';
import { ERROR_IMG } from '../../config/GlobalSettings';
import { BASE_URL } from '../../initApi/apiUrls';

export const DrapingListPrint = React.forwardRef((props, ref) => {
    const { catalogue, catalogueItems = [], organization } = props
    return (
        <div ref={ref}>
            {catalogue &&
                <>
                    <div className="row">
                        <div className="col-xs-12 center">
                            <div className="drapingPrintBg">
                                <div style={{ padding: "1px" }}>
                                    <div style={{ border: " #ffffff", borderStyle: "solid", borderWidth: "15px", margin: "20px", minHeight: "754px" }}>
                                        <br />
                                        <br />
                                        <br />
                                        {organization &&
                                            <>
                                                {organization.isLogo == 1 &&
                                                    <img style={{ height: "150px" }} src={BASE_URL + "/" + organization.logoUrl} onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
                                                }
                                                <h1>{organization.name}</h1>
                                            </>
                                        }
                                        <hr style={{width:"200px"}}/>
                                        <div style={{ width: "700px", display: "inline-block" }}>
                                            <h1>
                                                {catalogue.catalogueName}
                                            </h1>
                                        </div>
                                        <div style={{ width: "500px", display: "inline-block" }}>
                                            <h5 >{catalogue.note}</h5>
                                        </div>
                                        <h3>
                                            <QRCode size="60" value={catalogue.catalogueCode} />
                                            <br />
                                            {catalogue.catalogueCode}
                                        </h3>
                                        <br />
                                        <div class="widget-body" style={{ backgroundColor: "transparent" }}>
                                            <div class="col-xs-12" style={{ textAlign: "center", marginTop: "-4px" }}>
                                                <div class="footer-inner">
                                                    <div class="footer-content">
                                                        <p>
                                                            <span style={{ verticalAlign: "bottom", fontSize: "x-small" }}>Powered&nbsp;By </span>
                                                            <img style={{ marginBottom: "3px" }} height="15px" src="images/logo-small.png" />
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ pageBreakBefore: 'always' }}></div>
                </>
            }
            {catalogueItems && catalogueItems.length > 0 &&
                catalogueItems.map((item, index) =>
                    <>
                        <div className="row">
                            <div className="col-xs-6 center">
                                <div className="drapingPrintBg">
                                    <div style={{ padding: "1px" }}>
                                        <div style={{ border: " #ffffff", borderStyle: "solid", borderWidth: "15px", margin: "20px" }}>
                                            <h5 style={{ marginBottom: "0" }}>{item.sareeDraping.itemName} - {item.sareeDraping.itemCode}
                                            </h5>
                                            <img src={item.sareeDraping.drapingFrontUrl ? BASE_URL + "/" + item.sareeDraping.drapingFrontUrl : ERROR_IMG} width="400px" />
                                            {organization &&
                                                <div class="widget-body" style={{ backgroundColor: "transparent" }}>
                                                    <div class="row" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                                        <div class="col-xs-9 text-left">
                                                            <div>
                                                                <ul class="list-unstyled">
                                                                    {organization &&
                                                                        <>
                                                                            {organization.isLogo == 1 &&
                                                                                <li>
                                                                                    <img height="50px" src={BASE_URL + "/" + organization.logoUrl} onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
                                                                                </li>
                                                                            }
                                                                            <li>
                                                                                {organization.name}
                                                                            </li>
                                                                        </>
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="col-xs-3">
                                                            <div className="widget style1 pull-right text-center">
                                                                <br />
                                                                <QRCode size="50" value={item.sareeDraping.itemCode} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-12" style={{ textAlign: "center", marginTop: "-4px" }}>
                                                        <div class="footer-inner">
                                                            <div class="footer-content">
                                                                <p>
                                                                    <span style={{ verticalAlign: "bottom", fontSize: "x-small" }}>Powered&nbsp;By </span>
                                                                    <img style={{ marginBottom: "3px" }} height="15px" src="images/logo-small.png" />
                                                                </p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-6 center">
                                <div className="drapingPrintBg">
                                    <div style={{ padding: "1px" }}>
                                        <div style={{ border: " #ffffff", borderStyle: "solid", borderWidth: "15px", margin: "20px" }}>
                                            <h5 style={{ marginBottom: "0" }}>{item.sareeDraping.itemName} - {item.sareeDraping.itemCode}
                                            </h5>
                                            <img src={item.sareeDraping.drapingBackUrl ? BASE_URL + "/" + item.sareeDraping.drapingBackUrl : ERROR_IMG} width="370px" />
                                            {organization &&
                                                <div class="widget-body" style={{ backgroundColor: "transparent" }}>
                                                    <div class="row" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                                        <div class="col-xs-9 text-left">
                                                            <div>
                                                                <ul class="list-unstyled">
                                                                    {organization &&
                                                                        <>
                                                                            {organization.isLogo == 1 &&
                                                                                <li>
                                                                                    <img height="50px" src={BASE_URL + "/" + organization.logoUrl} onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
                                                                                </li>
                                                                            }
                                                                            <li>
                                                                                {organization.name}
                                                                            </li>
                                                                        </>
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="col-xs-3">
                                                            <div className="widget style1 pull-right text-center">
                                                                <br />
                                                                <QRCode size="50" value={item.sareeDraping.itemCode} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-12" style={{ textAlign: "center", marginTop: "-4px" }}>
                                                        <div class="footer-inner">
                                                            <div class="footer-content">
                                                                <p>
                                                                    <span style={{ verticalAlign: "bottom", fontSize: "x-small" }}>Powered&nbsp;By </span>
                                                                    <img style={{ marginBottom: "3px" }} height="15px" src="images/logo-small.png" />
                                                                </p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!(catalogueItems.length - 1 == index) &&
                            <div style={{ pageBreakBefore: 'always' }}></div>}
                    </>
                )
            }
            <br />
        </div >
    )
})