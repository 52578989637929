import React from 'react'

export function PaymentFailed({ setRechargePlan, ...props }) {

    const GoToPlan = () => {
        setRechargePlan(0)
    }

    return (
        <div class="row center">
            <div class="col-xs-12">
                <i class="fa fa-times-circle red" style={{ fontSize: "112px" }}></i>
                <div class="space-6"></div>
                <h1>Payment Failed !</h1>
                {props.children}
                <div class="space-6"></div>
                <br />
                <button class="btn btn-info" onClick={GoToPlan}>
                    <i class="ace-icon fa fa-arrow-left"></i>
                    Back to Plans
                </button>
            </div>
        </div>
    )
}
