import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../../../base/Container';
import * as cashBankCashVoucherCrud from '../../../_crud/vouchers/cashBankCashVoucherCrud'
import AppToaster from '../../../commonLib/AppToaster';
import { loadingContext } from '../../../commonLib/_lodingContext';
import { GetETransactionModeName } from '../../../enumCollections/ETransactionMode'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import { Spinner } from '../../../commonLib/Spinner';
import { PagingEmpty } from '../../../commonLib/PagingEmpty';
import ReactTooltip from 'react-tooltip';
import Moment from 'react-moment';
import 'moment-timezone';
import { DEFAULT_USER_IMG } from '../../../config/GlobalSettings';
import { EmptyData } from '../../../commonLib/EmptyData';
import { MyAlert } from '../../../commonLib/MyAlert';
import { BASE_URL } from '../../../initApi/apiUrls';
import { UserView } from '../../Global/Elements/UserView';

export default function ManageVoucherPayment() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [voucherPayment, setVoucherPayment] = useState([])
    const { id } = useParams()

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoadingMini(true);
        cashBankCashVoucherCrud.GetVoucherById(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data.payments
                        setVoucherPayment(hdata);
                    }
                    else {
                        setVoucherPayment([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setVoucherPayment([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }


    function doRefresh(event) {
        getData()
        setTimeout(() => {
            event.detail.complete();
        }, 1000);
    }

    return (
        <Container title={"Cash Bank Cash Voucher Payment(" + id + ")"} doRefresh={doRefresh} isBack="true">
            <Link to={"/cashbankcashvoucher/dashboard/" + id + "/voucherpayment/add"} class="btn btn-sm btn-success pull-right">
                <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                New
            </Link>
            <br />
            <br />
            <PagingEmpty text={"Total " + voucherPayment.length + " items."}>
                {isLoadingMini ?
                    <Spinner />
                    :
                    voucherPayment && voucherPayment.length > 0 ?
                        <div className="tables-scroll">
                            <table className="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Voucher Id</th>
                                        <th>Ref Id</th>
                                        <th>Amount</th>
                                        <th>Transaction Details</th>
                                        <th>Received Date</th>
                                        <th>Fiscal Year</th>
                                        <th>From Ledger Id</th>
                                        <th>To Ledger Id</th>
                                        <th>Other Charges Type</th>
                                        <th>Other Charges Ledger Id</th>
                                        <th>Transaction Mode</th>
                                        <th>Note</th>
                                        <th>By</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {voucherPayment.map((item, index) =>
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.voucherId}</td>
                                            <td>{item.refId}</td>
                                            <td>{item.amount}</td>
                                            <td>{item.transactionDetails} </td>
                                            <td><Moment format="DD-MMM-YYYY">{item.receivedDate}</Moment></td>
                                            <td>{item.fiscalYear}</td>
                                            <td>{item.fromLedgerId}</td>
                                            <td>{item.toLedgerId}</td>
                                            <td>{item.otherCharges}</td>
                                            <td>{item.otherChargesLedgerId}</td>
                                            <td><span class={item.transactionMode == 1 ? "label label-sm label-success" : "label label-sm label-danger"}>{GetETransactionModeName(item.transactionMode)}</span></td>
                                            <td>{item.note}</td>
                                            <td>
                                                <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                            </td>
                                            
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        :
                        <EmptyData>
                            No records found.
                            <br />
                            <br />
                        </EmptyData>
                }
            </PagingEmpty>
        </Container >
    )
}
