const BGPrintColor = (color) => {
    switch (color) {
        case "default": return "drapingPrintBg";
        case "red": return "drapingPrintBgRed";
        case "green": return "drapingPrintBgGreen";
        case "blue": return "drapingPrintBgBlue";
        case "purple": return "drapingPrintBgPurple";
        case "orange": return "drapingPrintBgOrange";
        case "none": return "drapingPrintBgNone";
        default: return "drapingPrintBg";
    }
}

const GetBGPrintColor = {
    BGPrintColor: [
        { Id: "default", Name: "Default" },
        { Id: "red", Name: "Red" },
        { Id: "green", Name: "Green" },
        { Id: "blue", Name: "Blue" },
        { Id: "purple", Name: "Purple" },
        { Id: "orange", Name: "Orange" },
        { Id: "none", Name: "None" },
    ]
}

export { BGPrintColor, GetBGPrintColor}
