import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetAll(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductContactStock/GetAll", param)
    }
}
export async function GetAllWarehouse(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductContactStock/GetAllWarehouse", param)
    }
}

export async function GetByProduct(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductContactStock/GetByProduct", param)
    }
}
export async function GetByContact(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductContactStock/GetByContact", param)
    }
}
export async function GetByContactSelector(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductContactStock/GetByContactSelector", param)
    }
}
export async function GetByProductionUnit(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductContactStock/GetByProductionUnit", param)
    }
}