import React from 'react'
import ReactTooltip from 'react-tooltip'
import { DEFAULT_IMG, DEFAULT_USER_IMG, ERROR_IMG } from '../../../config/GlobalSettings'
import { BASE_URL } from '../../../initApi/apiUrls'

export function ProductView({ item, uid, isUint = false }) {
    return (
        item &&
        <div class="infobox infobox-gray" style={{ border: "none", backgroundColor: "transparent", padding: "0", height: "auto", margin: "-1px" }}>

            {/* <div class="infobox-icon" style={{ verticalAlign: "middle" }}>
                <a data-tip data-for={uid}>
                    <img height="35px" width="35px" className="img-round pull-left" src={item.isPhoto == 1 ? (BASE_URL + "/" + item.photoUrl) : DEFAULT_IMG}
                        onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
                </a>
                <ReactTooltip border="true" borderColor="#6fb3e0" className="opaque" id={uid} type="light">
                    <img height="200px" width="200px" style={{ borderRadius: "0" }} className="img-round" src={item.isPhoto == 1 ? (BASE_URL + "/" + item.photoUrl) : DEFAULT_IMG} 
                    onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }}/>
                </ReactTooltip>
            </div> */}

            <div class="infobox-data">
                <span class="infobox-data-number" style={{ fontSize: "13px", margin: "0" }}>
                    {item.productName}
                </span>
                <div class="infobox-content">
                    <small><span className="label label-sm label-default">{item.productCategory.catName}</span></small>
                    {isUint && <span class="label label-sm label-success">per {item.unit}</span>}

                </div>
            </div>
        </div>
    )
}
