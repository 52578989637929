import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { Spinner } from '../../commonLib/Spinner';
import *as productDesignCrud from '../../_crud/productDesignCrud'
import 'moment-timezone';
import { EmptyData } from '../../commonLib/EmptyData';
import { MyAlert } from '../../commonLib/MyAlert';
import { dashboardContext } from '../Global/dashboardContext';
import { ContactView } from '../Global/Elements/ContactView';
import { ProductionUnitView } from '../Global/Elements/ProductionUnitView';
import { PAGING_PAGE_SIZE } from '../../config/GlobalSettings';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';
import { Paging } from '../../commonLib/Paging';
import { StatusView } from '../../commonLib/StatusView';
import { GetEProductDesignStatusName } from '../../enumCollections/EProductDesignStatus';
import { UserView } from '../Global/Elements/UserView';
import { ProductView } from '../Global/Elements/ProductView';

export function ManageProductDesignDesignerContact() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [productDesign, setProductDesign] = useState([])
    const [dashboardData, setDashboardData] = useContext(dashboardContext)
    const { id } = useParams()
    const history = useHistory()

    const initValues = {
        Search: {
            SearchTerm: "",
            PageNo: 1,
            PageSize: PAGING_PAGE_SIZE
        },
        ContactId: id
    }
    const [search, setSearch] = useState(initValues)
    const [LastPage, setLastPage] = useState(1)
    const [pagingData, setPagingData] = useState()



    useEffect(() => {
        getData()
    }, [search.Search.PageNo])

    const OnPageChange = (e) => {
        const { value } = e.target
        setSearch({ ...search, Search: { ...search.Search, PageNo: value } });
    }

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoadingMini(true);
        productDesignCrud.GetAllByContactDesigner(search)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProductDesign(hdata);
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                    }
                    else {
                        setProductDesign([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProductDesign([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        setSearch({ ...search, Search: { ...search.Search, PageNo: 1 } })
        getData()
        setTimeout(() => {
            event.target.complete();
        }, 1000);
    }

    function SearchChange(e) {
        const value = e.target.value
        setSearch({ ...search, Search: { ...search.Search, SearchTerm: value } })
    }
    function OnSearch() {
        getData()
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        productDesignCrud.Remove(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    AppToaster('Deleted successfully.', 'success')
                    getData()
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by someone,could not delete.', 'error')
                }

            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }
    return (
        <>
            <Container title="Product Design" doRefresh={doRefresh} isContact={true} isBack="true">
                {/* <Link to={"/product/design/" + id + "/add"} class="btn btn-sm btn-success">
                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                    New
                </Link>
                <div class="hr dotted"></div> */}
                <div class="form-horizontal" >
                    <div className="row">
                        <div className="col-md-11">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <TextBoxTranslater style={{ paddingLeft: "24px" }} value={search.Search.SearchTerm} onChange={SearchChange} className="form-control" type="text" placeholder="Search.." />
                                    <i class="ace-icon fa fa-search blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-1">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <button onClick={OnSearch} class="btn btn-sm btn-info pull-right">
                                    <i class="ace-icon fa fa-search bigger-110"></i>
                                    Run
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
                <Paging
                    pagingData={pagingData}
                    LastPage={LastPage}
                    CurrentPage={search.Search.PageNo}
                    OnPageChange={OnPageChange} >
                    {isLoadingMini ?
                        <Spinner />
                        :
                        productDesign && productDesign.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "43px" }}></th>
                                            <th style={{ width: "43px" }}>#Id</th>
                                            <th>Name</th>
                                            <th>Product</th>
                                            <th>Alloted Contact</th>
                                            <th>Production Unit</th>
                                            <th>Note</th>
                                            <th>Status</th>
                                            <th style={{ width: "43px" }}>By</th>
                                            <th style={{ width: "73px" }}></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {productDesign.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link className="btn btn-xs btn-info" to={"/product/design/" + id + "/dashboard/" + item.id} title="View dashboard" ><i class="ace-icon fa fa-tachometer"></i></Link>
                                                    </div>
                                                </td>
                                                <td>{item.id}</td>
                                                <td><div style={{ minWidth: "150px" }}>{item.name}</div></td>
                                                <td>
                                                    <Link to={"/product/dashboard/" + item.productId} >
                                                        <ProductView item={item.product} uid={"PP" + index} />
                                                    </Link>
                                                </td>
                                                <td>
                                                    {item.contact ?
                                                        <Link to={"/contact/dashboard/" + item.contactId}>
                                                            <ContactView item={item.contact} uid={"CS" + index} />
                                                        </Link>
                                                        :
                                                        "NA"
                                                    }
                                                </td>
                                                <td>
                                                    {item.productionUnit ?
                                                        <Link to={"/productionunit/dashboard/" + item.productionUnitId}>
                                                            <ProductionUnitView item={item.productionUnit} />
                                                        </Link> : "NA"
                                                    }
                                                </td>
                                                <td><div style={{ minWidth: "200px" }}>{item.note}</div></td>
                                                <td><StatusView status={item.status} type={0}>{GetEProductDesignStatusName(item.status)}</StatusView></td>
                                                <td>
                                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                </td>
                                                <td>
                                                    <div style={{ width: "73px" }}>
                                                        <Link to={"/product/design/" + id + "/" + item.id + "/edit"} class="btn btn-xs btn-success">
                                                            <i class="ace-icon fa fa-pencil bigger-120"></i>
                                                        </Link>&nbsp;
                                                        <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                            <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                        </MyAlert>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </Paging>
            </Container >
        </>
    )
}
