const GetETransactionModeName = (id) => {
    switch (id) {
        case 0: { return "Opening Balance" }
        case 1: { return "Bank" } 
        case 2: { return "Cash" }
        case 3: { return "Advance" }
        case 5: { return "Penalty & Defect" }
        case 4: { return "None" }
    }
}

const GetETransactionMode = {
    ETransactionMode: [
        { Id: 0, Name: "Opening Balance" },
        { Id: 1, Name: "Bank" },
        { Id: 2, Name: "Cash" },
        { Id: 3, Name: "Advance" },
        { Id: 5, Name: "Penalty & Defect" },
        { Id: 4, Name: "None" },
    ]
}

export { GetETransactionModeName, GetETransactionMode }