import React from 'react'
import { GetEProcessingModeName } from '../../../enumCollections/EProcessingMode'
import { GetEProcessingTypeName } from '../../../enumCollections/EProcessingType'

export function ProcessingView({ item }) {
    return (
        item &&
        <div class="infobox infobox-gray" style={{ border: "none", backgroundColor: "transparent", padding: "0", height: "auto", margin: "-1px" }}>

            {/* <div class="infobox-icon" style={{ verticalAlign: "super" }}>
                <i class="ace-icon fa fa-cube"></i>
            </div> */}

            <div class="infobox-data">
                <span class="infobox-data-number" style={{ fontSize: "13px", margin: "0" }}>
                    {item.name}
                </span>
                <div class="infobox-content">
                    <small>
                        <small><span class={item.type == 1 ? "label label-sm label-default" : "label label-sm label-primary"}>{GetEProcessingTypeName(item.type)}</span></small>
                        <span class={item.mode == 1 ? "label label-sm label-success arrowed-right" : "label label-sm label-warning arrowed-right"}>{GetEProcessingModeName(item.mode)}</span>

                    </small>
                </div>
            </div>
        </div>
    )
}
