import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Container } from '../../../base/Container';
import AppToaster from '../../../commonLib/AppToaster';
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import { loadingContext } from '../../../commonLib/_lodingContext';
import * as warehouseTransferCrud from '../../../_crud/warehouse/warehouseTransferCrud'
import { TimelineHistory } from '../../Global/TimelineHistory';
import { PrintStarter } from '../../Prints/PrintStarter';
import { PrintStarterDash } from '../../Prints/PrintStaterDash';
import { WarehouseTransferPrint } from '../../Prints/WarehouseTransferPrint';
import { WarehouseTransferItemView } from './WarehouseTransferItemView';
import { getIsPrint } from '../../../config/GlobalSettings';


export function WarehouseTransferDashboard() {
    const [warehouseTransfer, setWarehouseTransfer] = useState()
    const [warehouseTransferProduct, setWarehouseTransferProduct] = useState([])
    const [warehouseTransferProductCount, setWarehouseTransferProductCount] = useState({ count: 0, totalQuantity: 0 })
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const { id } = useParams()

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        setIsLoading(true);
        warehouseTransferCrud.GetById(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let hproduct = res.data.products
                        setWarehouseTransfer(hdata)
                        setWarehouseTransferProduct(hproduct)
                        if (hproduct) {
                            let c = 0
                            let q = 0
                            hproduct.forEach(e => {
                                c = c + 1
                                q = q + (e.rawStock + e.mainStock + e.defectiveStock)
                            });
                            setWarehouseTransferProductCount({ count: c, totalQuantity: q })
                        }
                    }
                    else {
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                }
                setIsLoadingMini(false)
                setIsLoadingLocal(false);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoadingMini(false)
                setIsLoadingLocal(false);
                setIsLoading(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        getData()
        setTimeout(() => {
            event.target.complete();
        }, 1000);
    }
    const printRef = useRef();
    return (
        <>
            {getIsPrint && <div style={{ display: "none" }}>
                <WarehouseTransferPrint warehouseTransfer={warehouseTransfer} warehouseTransferProductCount={warehouseTransferProductCount} warehouseTransferProduct={warehouseTransferProduct} ref={printRef} />
            </div>}
            <Container title={"Warehouse Transfer Dashboard"} doRefresh={doRefresh} isBack="true">
                {getIsPrint && warehouseTransfer && warehouseTransferProduct &&
                    <div class="btn-group">
                        <PrintStarterDash printRef={printRef} />
                    </div>
                }
                <div class="hr dotted"></div>

                <div className="row">
                    <div className="col-lg-8">
                        <WarehouseTransferItemView warehouseTransfer={warehouseTransfer} warehouseTransferProductCount={warehouseTransferProductCount} warehouseTransferProduct={warehouseTransferProduct} doRefresh={getData} />
                    </div>
                    <div className="col-lg-4">
                        <TimelineHistory id={id} type={11} pageSize={10} text="Transfer Timeline" />
                    </div>
                </div>
            </Container>
        </>
    )
}

