import React, { useContext, useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import { ImageUploader } from '../../commonLib/ImageUploader';
import { LoadingButton } from '../../commonLib/LoadingButton';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { BASE_URL } from '../../initApi/apiUrls';
import { dashboardContext } from './../Global/dashboardContext'
import * as productCrud from '../../_crud/productCrud';

export function ProductPhoto() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [dashboardData, setDashboardData] = useContext(dashboardContext)
    const history = useHistory();
    const { id } = useParams()

    const [photo, setPhoto] = useState("")

    useEffect(() => {
        if (!dashboardData.productdata)
            history.push("/product/dashboard/" + id)
    }, []);

    const SetPhoto = (image) => {
        setPhoto(image)
    }
    const SavePhoto = () => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true);
        const formData = new FormData()

        formData.append('id', id)
        formData.append('file', photo)
        productCrud.UploadPhoto(formData)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false);
                    setIsLoadingLocal(false);
                    AppToaster('Updated successfully.', 'success')
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Network Error.", 'error')
                }

            })
            .catch(error => {
                alert("image size is too large.")
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }
    return (
        <Container title="Upload Photo" isBack="true" isProduct="true">

            <div className="row">
                <div className="col-md-4">
                </div>
                <div className="col-md-4">
                    <div class="form-group">
                        <div class="col-xs-12">
                            <label class="control-label no-padding-right"> Upload Photo</label>
                            <ImageUploader initImage={dashboardData.productdata && dashboardData.productdata.isPhoto == 1 ? (BASE_URL + "/" + dashboardData.productdata.photoUrl) : undefined} SetImage={SetPhoto} />
                        </div>
                    </div>
                </div>
            </div>

            <div class="clearfix form-actions">
                <div class="pull-right">
                    {isLoadingLocal ?
                        <LoadingButton />
                        :
                        <button class="btn btn-info" type="submit" expand="block" onClick={SavePhoto} disabled={!photo}>
                            <i class="ace-icon fa fa-check bigger-110"></i>
                            Upload
                        </button>}
                </div>
            </div>

        </Container >
    )
}
