import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AppToaster from '../../../commonLib/AppToaster'
import GetCurrentDateForSearch from '../../../commonLib/dateFormat'
import { EmptyData } from '../../../commonLib/EmptyData'
import { UserView } from '../../Global/Elements/UserView'
import * as productionWageVoucherCrud from '../../../_crud/vouchers/productionWageVoucherCrud'
import { ContactView } from '../../Global/Elements/ContactView'
import { StatusView } from '../../../commonLib/StatusView'
import { GetEPaymentStatusName } from '../../../enumCollections/EPaymentStatus'
import Moment from 'react-moment'
import { MyAlert } from '../../../commonLib/MyAlert'
import { Spinner } from '../../../commonLib/Spinner'
import { ProductView } from '../../Global/Elements/ProductView'
import { ProductionUnitView } from '../../Global/Elements/ProductionUnitView'
import { GetEBoolName } from '../../../enumCollections/EBool'
import DatePicker from "react-datepicker";
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext'
import { GetELedgerAssetSalary, GetELedgerAssetSalaryName } from '../../../enumCollections/ELedgerAssetSalary'
import { PagingEmpty } from '../../../commonLib/PagingEmpty'
import { LotView } from '../../Global/Elements/LotView'
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater'

export default function ProductionItemView({ paid, pending, productionVoucher, voucherPayment, voucherDetails, doRefresh, advance, defect }) {
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const { id } = useParams()
    const [empty, setEmpty] = useState(false);
    const [detailsCount, setDetailsCount] = useState({ fresh: 0, defective: 0, rejected: 0 });

    const initItems = {
        voucherId: id,
        receivedDate: new Date(GetCurrentDateForSearch(0)),
        amount: 0,
        fiscalYear: "a",
        fromLedgerId: 6,
        note: ""
    }
    const [productionItem, setProductionItem] = useState(initItems);

    useEffect(() => {
        setEmpty(false)
    }, [empty]);

    useEffect(() => {
        if (voucherDetails && voucherDetails.length > 0) {
            let cnt = { fresh: 0, defective: 0, rejected: 0 }
            voucherDetails.forEach(element => {
                cnt.fresh = cnt.fresh + element.productReceiving.freshQuantity
                cnt.defective = cnt.defective + element.productReceiving.defectiveQuantity
                cnt.rejected = cnt.rejected + element.productReceiving.rejectedQuantity
            });

            setDetailsCount({ ...cnt })
        }
    }, [voucherDetails]);

    function setAmount() {
        if (productionItem.amount) {
            if (productionItem.fromLedgerId == 9) {
                if (advance) {
                    if (productionItem.amount > advance.remainingAdvance) {
                        if (advance.remainingAdvance > pending)
                            setProductionItem({ ...productionItem, amount: pending })
                        else
                            setProductionItem({ ...productionItem, amount: advance.remainingAdvance })
                    }
                    else if (productionItem.amount < 0) {
                        setProductionItem({ ...productionItem, amount: 0 })
                    }
                }
                else {
                    setProductionItem({ ...productionItem, amount: 0 })
                }
            }
            else if (productionItem.fromLedgerId == 45) {
                if (defect) {
                    if (productionItem.amount > defect.remainingAdvance) {
                        if (defect.remainingAdvance > pending)
                            setProductionItem({ ...productionItem, amount: pending })
                        else
                            setProductionItem({ ...productionItem, amount: defect.remainingAdvance })
                    }
                    else if (productionItem.amount < 0) {
                        setProductionItem({ ...productionItem, amount: 0 })
                    }
                }
                else {
                    setProductionItem({ ...productionItem, amount: 0 })
                }
            }
            else {
                if (productionItem.amount > pending) {
                    setProductionItem({ ...productionItem, amount: pending })
                }
                else if (productionItem.amount < 0) {
                    setProductionItem({ ...productionItem, amount: 0 })
                }
            }
        }
    }

    useEffect(() => {
        setAmount()
    }, [productionItem.amount, productionItem.fromLedgerId]);


    const AddItem = (type) => {
        if (productionItem.amount > 0) {
            setIsLoadingMini(true);
            setIsLoadingLocal(true)
            productionWageVoucherCrud.AddVoucherPayment(productionItem)
                .then(res => {
                    if (res.data.succeeded) {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false);
                        AppToaster('Added successfully.', 'success')
                        if (type) { SattleItem(productionItem) }
                        else { doRefresh() }
                    }
                    else {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                })
                .catch(error => {
                    setIsLoadingMini(false);
                    setIsLoadingLocal(false);
                    AppToaster(error, 'error')
                })
        }
        else {
            AppToaster("Amount should be greater than zero.", 'error')
        }
    }

    const SattleItem = (productionItem) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        productionWageVoucherCrud.SattleVoucher({ VoucherId: productionItem.voucherId, OnDate: productionItem.receivedDate })
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster('Satlle successfully.', 'success')
                    doRefresh()
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        productionWageVoucherCrud.RemoveVoucherPayment(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    AppToaster('Deleted successfully.', 'success')
                    doRefresh()
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by someone,could not delete.', 'error')
                }
            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }

    return (
        <>
            <div className="widget-box">
                <div class="widget-header widget-header-flat widget-header-small">
                    <h5 class="widget-title">
                        Details
                    </h5>
                </div>
                {
                    productionVoucher &&
                    <div className="row">
                        <div class="profile-user-info profile-user-info-striped">
                            <div class="profile-info-row">
                                <div class="profile-info-name"> Voucher Id</div>

                                <div class="profile-info-value">
                                    <span ><b>{productionVoucher.id}</b></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Voucher Date</div>

                                <div class="profile-info-value">
                                    <Moment format="DD-MMM-YYYY">{productionVoucher.voucherDate}</Moment>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> For Contact </div>

                                <div class="profile-info-value">
                                    <Link to={"/contact/dashboard/" + productionVoucher.contact.id}>
                                        <ContactView item={productionVoucher.contact} uid={"CXV1"} />
                                    </Link>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name">Phone</div>

                                <div class="profile-info-value">
                                    <span >{productionVoucher.contact.phone}</span>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name">Address</div>

                                <div class="profile-info-value">
                                    <span >{productionVoucher.contact.address}</span>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name">Email</div>

                                <div class="profile-info-value">
                                    <span >{productionVoucher.contact.email}</span>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name">Quantity</div>
                                <div class="profile-info-value">
                                    <span >
                                        Fresh: <b>{Number(detailsCount.fresh).toFixed(2)}</b>
                                        &nbsp;&nbsp;Defective: <b>{Number(detailsCount.defective).toFixed(2)}</b>
                                        &nbsp;&nbsp;Rejected: <b>{Number(detailsCount.rejected).toFixed(2)}</b>
                                        &nbsp;&nbsp;Total: <b>{Number(detailsCount.fresh + detailsCount.defective + detailsCount.rejected).toFixed(2)}</b>
                                    </span>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name">Details</div>
                                <div class="profile-info-value">
                                    <span >{productionVoucher.linkedDetails}</span>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name">Amount</div>
                                <div class="profile-info-value">
                                    <span ><b>₹{Number(productionVoucher.totalAmount).toFixed(2)}</b></span>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name"> <b>Voucher Balance</b> </div>

                                <div class="profile-info-value">
                                    <span>Paid: <b className="green">₹{Number(paid).toFixed(2)}</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pending: <b className="red">₹{Number(pending).toFixed(2)}</b> </span>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name">Note </div>

                                <div class="profile-info-value">
                                    <span>{productionVoucher.note ? productionVoucher.note : "NA"}</span>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name">Status </div>

                                <div class="profile-info-value">
                                    <span><StatusView status={productionVoucher.paymentStatus}>{GetEPaymentStatusName(productionVoucher.paymentStatus)}</StatusView></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Created By </div>

                                <div class="profile-info-value">
                                    <span><UserView item={productionVoucher.createdByUser} uid={"GD1"} createdOn={productionVoucher.createdOn} /></span>
                                </div>
                            </div>

                        </div>
                    </div>
                }
            </div>
            <br />

            <div className="widget-box">
                <div class="widget-header widget-header-flat widget-header-small">
                    <h5 class="widget-title">
                        Voucher Payments
                    </h5>
                </div>
                <div className="row" >
                    <div class="col-xs-12">

                        <div className="col-md-3">
                            <div class="form-group">
                                <label class="control-label no-padding-right">Date</label>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker placeholder="Received Date" name="receivedDate" className="form-control input-date-padding" selected={productionItem.receivedDate} onChange={(date) => setProductionItem({ ...productionItem, receivedDate: date })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div class="form-group">
                                <label class="control-label no-padding-right"> Amount</label>
                                <input className="form-control" type="number" placeholder="Amount" value={productionItem.amount} onChange={(e) => setProductionItem({ ...productionItem, amount: e.target.value })} />
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div class="form-group">
                                <label class="ontrol-label no-padding-right">By</label>
                                <select className="form-control" value={productionItem.fromLedgerId} onChange={(e) => setProductionItem({ ...productionItem, fromLedgerId: e.target.value })}>
                                    {
                                        GetELedgerAssetSalary.ELedgerAssetSalary.map((data, index) =>
                                            <option key={data.Id} value={data.Id}>{data.Name}</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div class="form-group">
                                <label class="ontrol-label no-padding-right">Note</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="Note" value={productionItem.note} onChange={(e) => setProductionItem({ ...productionItem, note: e.target.value })} />
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div class="form-group">
                                <label class="control-label no-padding-right">&nbsp;</label>
                                <div class="col-xs-12">
                                    {isLoadingLocal ?
                                        <button class="btn btn-xs btn-success" type="submit" expand="block" disabled>
                                            <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i> Wait...
                                        </button>
                                        :
                                        <>
                                            <div class="btn-group">
                                                <button class="btn btn-xs btn-success" expand="block" onClick={() => AddItem(false)}>
                                                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>Pay
                                                </button>
                                                <button data-toggle="dropdown" class="btn btn-xs btn-success dropdown-toggle" aria-expanded="false">
                                                    <span class="ace-icon fa fa-caret-down icon-only"></span>
                                                </button>

                                                <ul class="dropdown-menu dropdown-success">
                                                    <li>
                                                        <a expand="block" onClick={() => AddItem(true)}>
                                                            Pay & Sattle
                                                        </a>
                                                    </li>

                                                </ul>
                                            </div>

                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />

                <div className="row">
                    <div className="col-xs-12">
                        <PagingEmpty text={"Total " + voucherPayment.length + " items."}>
                            {voucherPayment && voucherPayment.length > 0 ?
                                <div className="tables-scroll">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>Amount</th>
                                                <th>By</th>
                                                <th>Note</th>
                                                <th></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {voucherPayment.map((item, index) =>
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td><Moment format="DD-MMM-YYYY">{item.receivedDate}</Moment></td>
                                                    <td><b>₹ {item.amount}</b></td>
                                                    <td>{GetELedgerAssetSalaryName(parseInt(item.fromLedgerId))}</td>
                                                    <td>{item.note}</td>
                                                    <td>
                                                        <div class="btn-group">
                                                            <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                                <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                            </MyAlert>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <EmptyData>
                                    No Item Added.
                                    <br />
                                    <br />
                                </EmptyData>
                            }
                        </PagingEmpty>
                    </div>
                </div>
            </div>
            <br />
            {
                voucherDetails &&
                <>
                    <div className="widget-box">
                        <div class="widget-header widget-header-flat widget-header-small">
                            <h5 class="widget-title">
                                Recevings for production vouchers
                            </h5>

                        </div>
                        <div className="row">
                            <div className="col-xs-12">
                                <PagingEmpty text={"Total " + voucherDetails.length + " items."}>
                                    {isLoadingMini ?
                                        <Spinner />
                                        :
                                        voucherDetails && voucherDetails.length > 0 ?
                                            <div className="tables-scroll">
                                                <table className="table table-hover table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>#Id</th>
                                                            <th>On Date</th>
                                                            <th>Production</th>
                                                            <th>Product</th>
                                                            <th>Wage/unit</th>
                                                            <th>Fresh</th>
                                                            <th>Defective</th>
                                                            <th>Rejected</th>
                                                            <th>Lot</th>
                                                            <th>Note</th>
                                                            <th>By</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {voucherDetails.map((item, index) =>
                                                            <tr key={item.id}>
                                                                <td>{item.productReceiving.id}</td>
                                                                <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.productReceiving.onDate}</Moment></div></td>
                                                                <td>
                                                                    <Link to={"/productionunit/dashboard/" + item.productReceiving.productionUnitId}>
                                                                        <ProductionUnitView item={item.productReceiving.productionUnit} />
                                                                    </Link>
                                                                </td>

                                                                <td>
                                                                    <Link to={"/product/dashboard/" + item.productReceiving.productId}>
                                                                        <ProductView item={item.productReceiving.product} uid={"PP" + index} />
                                                                    </Link>
                                                                </td>

                                                                <td>₹{Number(item.productReceiving.productWage.wage).toFixed(2)}</td>
                                                                <td>{item.productReceiving.freshQuantity} {item.productReceiving.unit}</td>
                                                                <td>{item.productReceiving.defectiveQuantity} {item.productReceiving.unit}</td>
                                                                <td>{item.productReceiving.rejectedQuantity} {item.productReceiving.unit}</td>
                                                                <td>
                                                                    <LotView item={item.productReceiving.lot} />
                                                                </td>
                                                                <td><div style={{ width: "150px" }}>{item.productReceiving.note}</div></td>

                                                                <td>
                                                                    <UserView item={item.productReceiving.createdByUser} uid={"D" + index} createdOn={item.productReceiving.createdOn} />
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                            :
                                            <EmptyData>
                                                No records found.
                                                <br />
                                                <br />
                                            </EmptyData>

                                    }
                                    <br />
                                </PagingEmpty>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
