import React, { useContext, useEffect, useState } from 'react'
import Moment from 'react-moment';
import AppToaster from '../../../commonLib/AppToaster';
import { EmptyData } from '../../../commonLib/EmptyData';
import { Spinner } from '../../../commonLib/Spinner';
import { organizationContext } from '../../Organization/organizationContext';
import * as reportCrud from './../../../_crud/reportCrud';
import { diskSizeContext } from './diskSizeContext';

export function DiskSize() {
    const [data, setData] = useState()
    const [isLocalLoading, setIsLocalLoading] = useState(false)
    const [organization, setOrganization] = useContext(organizationContext);
    const [diskSize, setDiskSize] = useContext(diskSizeContext);

    useEffect(() => {
        getSize()
    }, [])

    function getSize() {
        setIsLocalLoading(true);
        reportCrud.GetSizeUsed()
            .then(res => {
                if (res.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data
                        setData(hdata)
                        setDiskSize(hdata)
                    }
                    else {
                        setData();
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setData();
                }
                setIsLocalLoading(false);
            })
            .catch(error => {
                setData();
                setIsLocalLoading(false);
                AppToaster(error, 'error')
            })
    }

    return (

        <>
            <div className="widget-box">
                <div class="widget-header widget-header-flat widget-header-small">
                    <h5 class="widget-title">
                        Disk Uses
                    </h5> {organization && <samll className="green">(Expires after <Moment format="DD-MMM-YYYY">{organization.validTillDate}</Moment>)</samll>}
                    <div class="widget-toolbar">
                        <a onClick={getSize} title="Reload">
                            <i class="ace-icon fa fa-refresh"></i>
                        </a>
                    </div>
                </div>
                <div className="row">
                    {isLocalLoading ?
                        <Spinner />
                        :
                        data ?
                            <div class="profile-user-info profile-user-info-striped">
                                <div class="btn btn-pink" style={{ width: "50%" }}>
                                    <div class="infobox-data">
                                        <div class="infobox-content">Data</div>
                                        <div class="infobox-content bolder">{Number(data.dbSize).toFixed(2)} MB</div>
                                    </div>
                                </div>
                                <div class="btn btn-warning" style={{ width: "50%" }}>
                                    <div class="infobox-data">
                                        <div class="infobox-content">Media</div>
                                        <div class="infobox-content bolder">{Number(data.dirSize).toFixed(2)} MB</div>
                                    </div>
                                </div>
                                <div class="btn btn-info" style={{ width: "50%" }}>
                                    <div class="infobox-data">
                                        <div class="infobox-content">Total</div>
                                        <div class="infobox-content bolder">{Number(data.totalSize).toFixed(2)} MB</div>
                                    </div>
                                </div>
                                <div class="btn btn-success" style={{ width: "50%" }}>
                                    <div class="infobox-data">
                                        <div class="infobox-content">Alloted Space</div>
                                        <div class="infobox-content bolder">{Number(organization ? organization.allotedDiskSizeMB : 0).toFixed(2)} MB</div>
                                    </div>
                                </div>

                            </div>
                            :
                            <EmptyData>
                                <h1>No Data</h1>
                            </EmptyData>
                    }
                </div>
            </div>
        </>
    )
}
