import React, { useContext, useEffect, useState } from 'react'
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Container } from '../../../base/Container';
import AppToaster from '../../../commonLib/AppToaster';
import { EmptyData } from '../../../commonLib/EmptyData';
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import { MyAlert } from '../../../commonLib/MyAlert';
import { PagingEmpty } from '../../../commonLib/PagingEmpty';
import { Spinner } from '../../../commonLib/Spinner';
import { loadingContext } from '../../../commonLib/_lodingContext';
import { GetETransactionModeName } from '../../../enumCollections/ETransactionMode';
import * as advanceVoucherCrud from '../../../_crud/vouchers/advanceVoucherCrud';
import { UserView } from '../../Global/Elements/UserView';

export function ManageAdvanceVoucherPayment() {
  const [isLoading, setIsLoading] = useContext(loadingContext)
  const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
  const [voucherPayment, setVoucherPayment] = useState([])
  const { id } = useParams()

  useEffect(() => {
    getData()
  }, []);

  function getData() {
    setIsLoading(true);
    advanceVoucherCrud.GetVoucherById(id)
      .then(res => {
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data.payments
            setVoucherPayment(hdata);
            setIsLoading(false)
          }
          else {
            setIsLoading(false)
            setVoucherPayment([]);
          }
        }
        else {
          setIsLoading(false)
          setVoucherPayment([]);
          AppToaster("Server Error /Invalid Try.", 'error')
        }
      })
      .catch(error => {
        setIsLoading(false);
        AppToaster(error, 'error')
      })
  }

  function doRefresh(event) {
    getData()
    setTimeout(() => {
      event.detail.complete();
    }, 1000);
  }

  const DeleteItem = (paymentId) => {
    setIsLoadingMini(true)
    advanceVoucherCrud.RemoveVoucherPayment(paymentId)
      .then(res => {
        if (res.data.succeeded) {
          setIsLoadingMini(false)
          AppToaster('Deleted successfully.', 'success')
          getData()
        }
        else {
          setIsLoadingMini(false)
          AppToaster('Using by someone,could not delete', 'error')
        }

      }).catch(error => {
        setIsLoadingMini(false)
        AppToaster('Network error', 'error')
      })
  }

  return (
    <Container title={"Advance Voucher Payment(" + id + ")"} doRefresh={doRefresh} isBack="true">
      <Link to={"/advancevoucher/dashboard/" + id + "/voucherPayment/add"} class="btn btn-sm btn-success pull-right">
        <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
        New
      </Link>
      <br />
      <br />
      <PagingEmpty text={"Total " + voucherPayment.length + " items."}>
        {isLoading ?
          <Spinner />
          :
          voucherPayment && voucherPayment.length > 0 ?
            <div className="tables-scroll">
              <table className="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Amount</th>
                    <th>Transaction Mode</th>
                    <th>Transaction Details</th>
                    <th>Other Charges Type</th>
                    <th>Received Date</th>
                    <th>Fiscal Year</th>
                    <th>Note</th>
                    <th>By</th>
                    <th style={{ width: "43px" }}></th>
                  </tr>
                </thead>

                <tbody>
                  {voucherPayment.map((item, index) =>
                    <tr key={item.id}>
                      <td><b>Voucher: </b>{item.voucherId}, <b>Ref: </b>{item.refId}, <b>From Ledger: </b>{item.fromLedgerId}, <b>To Ledger: </b>{item.toLedgerId}</td>
                      <td>{item.amount}</td>
                      <td><span class={item.transactionMode == 1 ? "label label-sm label-warning" : "label label-sm label-info"}>{GetETransactionModeName(item.transactionMode)}</span></td>
                      <td>{item.transactionDetails} </td>
                      <td>{item.otherCharges}</td>
                      <td><Moment format="DD-MMM-YYYY">{item.receivedDate}</Moment></td>
                      <td>{item.fiscalYear}</td>
                      <td>{item.note}</td>
                      <td>
                        <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                      </td>
                      <td>
                        <div class="btn-group">
                          <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                            <i class="ace-icon fa fa-trash-o bigger-120"></i>
                          </MyAlert>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            :
            <EmptyData>
              No records found.
              <br />
              <br />
            </EmptyData>
        }
      </PagingEmpty>
    </Container >
  )
}
