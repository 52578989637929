import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { EmptyData } from './../../../commonLib/EmptyData'
import Moment from 'react-moment';
import { GetEChequeStatusName } from './../../../enumCollections/EChequeStatus'
import { Spinner } from './../../../commonLib/Spinner';
import { PagingEmpty } from './../../../commonLib/PagingEmpty';
import { Container } from './../../../base/Container';
import AppToaster from './../../../commonLib/AppToaster';
import *as bankTransactionCud from './../../../_crud/cheque/bankTransactionCrud'
import *as bankCrud from './../../../_crud/cheque/bankCrud'
import DatePicker from "react-datepicker";
import GetCurrentDateForSearch from './../../../commonLib/dateFormat'
import { UserView } from './../../Global/Elements/UserView';
import { StatusView } from './../../../commonLib/StatusView';
import { loadingContext } from './../../../commonLib/_lodingContext';
import { ContactView } from '../../Global/Elements/ContactView';
import { GetEAccountTypeName } from '../../../enumCollections/EAccountType';
import { PrintBankTransactionReport } from '../../Prints/PrintBankTransactionReport';
import { PrintStarterDash } from '../../Prints/PrintStaterDash';

const initValues = {
    Range: {
        fromDate: new Date(GetCurrentDateForSearch(-30)),
        toDate: new Date(GetCurrentDateForSearch(0))
    },
    BankId: "",
}

export function BankReports() {
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [search, setSearch] = useState(initValues)
    const [transaction, setTransaction] = useState([])
    const [balance, setBalance] = useState({ Dr: 0, Cr: 0 })
    const [bank, setBank] = useState([])

    useEffect(() => {
        getBank()
    }, [])

    useEffect(() => {
        if (bank && bank.length > 0) {
            setSearch({ ...search, BankId: bank[0].id })
            OnSearch(bank[0].id)
        }
    }, [bank])

    function OnSearch(data) {
        if (data) {
            getTransaction({ ...search, BankId: data })
        }
        else {
            getTransaction(search)
        }
    }

    function getTransaction(data) {
        setIsLoading(true);
        bankTransactionCud.GetAllByBalance(data)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setTransaction(hdata);
                        setBalance({ Dr: res.data.dr, Cr: res.data.cr })
                        setPrintData({ data: hdata, Dr: res.data.dr, Cr: res.data.cr, fromDate: search.Range.fromDate, toDate: search.Range.toDate })
                        setIsLoading(false)
                    }
                    else {
                        setIsLoading(false)
                        setTransaction([]);
                    }
                }
                else {
                    setIsLoading(false)
                    setTransaction([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoading(false);
                AppToaster(error, 'error')
            })
    }

    function getBank() {
        setIsLoading(true);
        bankCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setBank(hdata);
                    }
                    else {
                        setBank([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setBank([]);
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        OnSearch(undefined)
    }
    const [printData, setPrintData] = useState()
    const printRef = useRef();
    return (
        <>
            <Container title="Statement Report" doRefresh={doRefresh}>
                <div class="form-horizontal" >
                    <div className="row">
                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker selectsStart startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.fromDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, fromDate: date } })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker selectsEnd startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.toDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, toDate: date } })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <select className="form-control" value={search.BankId} onChange={(e) => setSearch({ ...search, BankId: e.target.value })}>
                                    {
                                        bank.map((item, index) =>
                                            <option value={item.id}>{item.accountHolderName} ({item.bankName}-{item.accountNo})</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-1">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <button onClick={doRefresh} to="/order/add" class="btn btn-sm btn-info pull-right">
                                    <i class="ace-icon fa fa-search bigger-110"></i>
                                    Run
                                </button>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <div style={{ display: "none" }}>
                                    <PrintBankTransactionReport printData={printData} ref={printRef} />
                                </div>

                                {printData &&
                                    <div class="btn-group pull-right">
                                    <PrintStarterDash printRef={printRef} setPrintData={setPrintData} />
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <PagingEmpty text={"Total " + transaction.length + " items."}>
                    {isLoading ?
                        <Spinner />
                        :
                        <div className="tables-scroll">
                            <table className="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th style={{ width: "43px" }}></th>
                                        <th>#I</th>
                                        <th>Cheque Date</th>
                                        <th>Contact</th>
                                        <th>For Bank</th>
                                        <th>Cheque/Trans No</th>
                                        <th>Cheque/Trans Given By</th>
                                        <th>Cheque/Trans Form Bank</th>
                                        <th>Cheque/Trans City</th>
                                        <th>Dr</th>
                                        <th>Cr</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>


                                <tbody>
                                    <tr style={{
                                        backgroundColor: "#ddd",
                                        border: "3px solid",
                                        borderTop: "0px solid",
                                        borderRight: "0px solid",
                                        borderLeft: "0px solid",
                                        borderColor: "#a9a6a6"
                                    }}>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><b>Balance</b></td>
                                        <td><b>₹{Number(balance.Cr - balance.Dr).toFixed(2)}</b></td>
                                    </tr>

                                    {transaction && transaction.length > 0 ?
                                        transaction.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link className="btn btn-xs btn-info" to={"/transaction/dashboard/" + item.id} title="View dashboard" ><i class="ace-icon fa fa-tachometer"></i></Link>
                                                    </div>
                                                </td>
                                                <td>{index + 1}</td>
                                                <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.chequeDate}</Moment></div></td>
                                                <td>
                                                    <Link to={"/contact/dashboard/" + item.contactId}>
                                                        <ContactView item={item.contact} uid={"C" + index} />
                                                    </Link>
                                                </td>
                                                <td>
                                                    <div style={{ width: "250px" }}>
                                                        {item.bank.accountHolderName} ({item.bank.accountNo} - {GetEAccountTypeName(item.bank.accountType)})
                                                        <br />
                                                        IFSC: {item.bank.ifscCode}, Branch: {item.bank.branch}, {item.bank.city}, {item.bank.state}
                                                    </div>
                                                </td>
                                                <td><b>{item.chequeNo}</b></td>
                                                <td>{item.chequeGivenByName}</td>
                                                <td>{item.chequeBankName}</td>
                                                <td>{item.chequeCity}</td>
                                                <td>{item.dr == 0 ? "-" : <b>₹{Number(item.dr).toFixed(2)}</b>}</td>
                                                <td>{item.cr == 0 ? "-" : <b>₹{Number(item.cr).toFixed(2)}</b>}</td>
                                                <td><StatusView status={item.status}>{GetEChequeStatusName(item.status)}</StatusView></td>
                                            </tr>
                                        )
                                        :
                                        <tr>
                                            <td colSpan="12">
                                                <EmptyData>
                                                    <h1>No transaction found</h1>
                                                    <small><Moment format="DD-MMM-YYYY">{search.Range.fromDate}</Moment> - <Moment format="DD-MMM-YYYY">{search.Range.toDate}</Moment></small>
                                                </EmptyData>
                                            </td>
                                        </tr>
                                    }
                                    <tr style={{
                                        backgroundColor: "#ddd",
                                        border: "3px solid",
                                        borderBottom: "0px solid",
                                        borderRight: "0px solid",
                                        borderLeft: "0px solid",
                                        borderColor: "#a9a6a6"
                                    }}>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><b>Balance</b></td>
                                        <td><b>₹{Number(balance.Cr - balance.Dr).toFixed(2)}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                </PagingEmpty>
            </Container >
        </>
    )
}
