import React from 'react'
import Moment from 'react-moment'
import QRCode from 'react-qr-code'
import { getPageMargins } from '../../config/GlobalSettings'
import { GetEBoolName } from '../../enumCollections/EBool'
import { GetEOrderStatusName } from '../../enumCollections/EOrderStatus'
import { ContactViewS } from '../Global/Elements/ContactViewS'
import { ProductionUnitViewS } from '../Global/Elements/ProductionUnitViewS'
import { ProductViewS } from '../Global/Elements/ProductViewS'
import { PrintBranding } from './PrintBranding'

export const PrintProductionUnitPassbook = React.forwardRef((props, ref) => {
    const data = props.printData
    return (
        <div ref={ref}>
            <style type="text/css">
                {getPageMargins()}
            </style>
            {
                data &&
                <div className="row" style={{ fontSize: "9px" }}>
                    <div className="col-xs-7">
                        <p className="bolder" style={{ borderBottom: "1px solid rgb(0, 0, 0)" }}><ContactViewS item={data.contact} /></p>
                        <table style={{ width: "100%" }} className="table-bordered-dark">
                            <tbody>
                                <tr>
                                    <td style={{ padding: "0px", border: 0, borderBottom: "1px solid rgb(0, 0, 0)" }}>
                                        {data.contact.address}, {data.contact.city} - {data.contact.pinCode}, {data.contact.state}
                                        <br/>
                                        Mobile: {data.contact.phone}
                                        <br/>
                                        Ref: {data.contact.refId? data.contact.refId: "NA"}
                                        <br/>
                                        Doc: {data.contact.documentName? data.contact.documentName + " - " + data.contact.documentName : "NA"}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="footer-inner">
                            <div class="footer-content text-right">
                                <p style={{ marginTop: "3px" }}>
                                    <span style={{ verticalAlign: "bottom", fontSize: "8px" }}>Powered&nbsp;By </span>
                                    <img style={{ marginBottom: "3px" }} height="12px" src="images/logo-small-dark.png" />
                                </p>

                            </div>
                        </div>
                    </div>
                    <div className="col-xs-5">
                        <table style={{ width: "100%" }} className="">
                            <tbody>
                                {data &&
                                    <tr className="text-center" >
                                        <td colspan="2" style={{ borderBottom: "1px solid rgb(0, 0, 0)" }}>
                                            <div style={{ borderBottom: "1px solid rgb(0, 0, 0)", marginBottom: "-5px" }}>
                                                {data.productionUnitName}
                                            </div>
                                            <br />
                                            <QRCode size="50" value={data.contact.id} />
                                            <div style={{ marginBottom: "0px" }}>
                                            </div>
                                        </td>
                                    </tr>}
                                <tr >
                                    <td>Date</td>
                                    <td className="text-right"><Moment format="DD-MMM-YYYY">{data.createdOn}</Moment></td>
                                </tr>

                            </tbody>
                        </table>

                    </div>
                </div>
            }
        </div >
    )
})