import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import * as contactProcessingAllotmentCrud from '../../_crud/contactProcessingAllotmentCrud';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { GetEStatus } from '../../enumCollections/EStatus';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';

const initialValues = {
    id: undefined,
    status: "",
}
export function EditProcessingAllotment() {
    const { contactId } = useParams()
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)

    const Schema = Yup.object().shape({
        status: Yup.string().required("Status is required.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });
    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true);
        contactProcessingAllotmentCrud.UpdateStatus(value)
        .then(res => {
            if (res.data.succeeded) {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster('Updated successfully.', 'success')
            }
            else {
                setIsLoadingMini(false)
                setIsLoadingLocal(false);
                AppToaster("Server Error /Invalid Try.", 'error')
            }
        })
        .catch(error => {
            setIsLoadingMini(false);
            setIsLoadingLocal(false);
            AppToaster(error, 'error')
        })
    }

    useEffect(() => {
        getProcessingAllotment()
    }, []);

    function getProcessingAllotment() {
        contactProcessingAllotmentCrud.GetByContact(contactId)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        formik.setValues({
                            id: hdata.id,
                            status: hdata.status,
                        })
                    }
                    else {
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    return (
        <Container title="Edit Processing Allotment" isBack="true">
            <form onSubmit={formik.handleSubmit} class="form-horizontal">
                <div className="row">
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label class="col-sm-2 control-label no-padding-right">Status</label>
                            <div class="col-sm-10">
                                <select className="form-control"  name="status" value={formik.values.status} onChange={(e) => formik.setValues({ ...formik.values, status: e.target.value })}>
                                    {
                                        GetEStatus.EStatus.map((data, index) =>
                                            <option key={data.Id} value={data.Id}>{data.Name}</option>
                                        )
                                    }
                                </select>
                                {
                                    formik.touched.status &&
                                        formik.errors.status ? (
                                        <label className="text-danger" >
                                            {formik.errors.status}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div class="clearfix form-actions">
                    <div class="col-md-offset-3 col-md-9">
                        <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                            <i class="ace-icon fa fa-check bigger-110"></i>
                            Update
                        </button>
                    </div>
                </div>

            </form>
        </Container >
    )
}
