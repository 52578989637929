import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../../../base/Container';
import AppToaster from '../../../commonLib/AppToaster';
import * as Yup from 'yup'
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import { useFormik } from 'formik';
import *as productDesignCrud from '../../../_crud/productDesignCrud'
import *as productionUnitCrud from '../../../_crud/productionUnitCrud'
import { LoadingButton } from '../../../commonLib/LoadingButton';
import { Link, useHistory, useParams } from 'react-router-dom';
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater';
import { ProductionSelectorView } from '../../Global/SelectorView/ProductionSelectorView';
import { ContactSelectorView } from '../../Global/SelectorView/ContactSelectorView';
import ContactSelector from '../../Global/ContactSelector';
import { ContactView } from '../../Global/Elements/ContactView';
import { ProductionUnitView } from '../../Global/Elements/ProductionUnitView';

export function EditProductDesign() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isEdit2, setIsEdit2] = useState(false)
    const [selectedDesignerData, setSelectedDesignerData] = useState(undefined);
    const [selectedContactData, setSelectedContactData] = useState(undefined);
    const [selectedProductionData, setSelectedProductionData] = useState(undefined);
    const [production, setProduction] = useState([])
    const { id, did } = useParams()
    const history = useHistory()

    const initialValues = {
        Id: did,
        Name: undefined,
        Note: undefined,
        DesignerContactId: 0,
        ContactId: 0,
        ProductionUnitId: 0
    }
    const Schema = Yup.object().shape({
        Name: Yup.string().min(3, "Name must between 3 to 50 characters").max(50, "Name must between 3 to 50 characters").required("Name is required."),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });
    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true);
        productDesignCrud.Edit(value)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false);
                    setIsLoadingLocal(false);
                    AppToaster('Updated successfully.', 'success')
                    history.goBack()
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }
    useEffect(() => {
        getDesign()
    }, []);


    function getDesign() {
        setIsLoadingLocal(true);
        productDesignCrud.GetById(did)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        formik.setValues({
                            Id: hdata.id,
                            Name: hdata.name,
                            Note: hdata.note,
                            ContactId: hdata.contactId,
                            ProductionUnitId: hdata.productionUnitId
                        })
                        setSelectedContactData(hdata.contact)
                        setSelectedProductionData(hdata.productionUnit)
                        setSelectedDesignerData(hdata.designerContact)
                    }
                    else {
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                }
                setIsLoadingLocal(false);
            })
            .catch(error => {
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }
    useEffect(() => {
        if (selectedContactData) {
            formik.setValues({ ...formik.values, ContactId: selectedContactData.id })
            getProduction(selectedContactData.id)
        }
    }, [selectedContactData]);

    useEffect(() => {
        if (selectedProductionData) {
            formik.setValues({ ...formik.values, ProductionUnitId: selectedProductionData.id })
        }
        else {
            formik.setValues({ ...formik.values, ProductionUnitId: "" })
        }
    }, [selectedProductionData])

    useEffect(() => {
        if (selectedDesignerData) {
            formik.setValues({ ...formik.values, DesignerContactId: selectedDesignerData.id })
        }
        else {
            formik.setValues({ ...formik.values, DesignerContactId: "" })
        }
    }, [selectedDesignerData])

    const OnProductionChange = (e) => {
        const { value } = e.target;
        if (value) {
            production.forEach(element => {
                if (element.id == value) {
                    {
                        setSelectedProductionData(element)
                    }
                }
            });
        }
        else {
            setSelectedProductionData(undefined)
        }
    }
    function getProduction(contactid) {
        setIsLoadingMini(true);
        productionUnitCrud.GetByContactId(contactid)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProduction(hdata);
                    }
                    else {
                        setProduction([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProduction([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }
    return (
        <Container title="Edit Product Design" isProduct={true} isBack="true">
            <form onSubmit={formik.handleSubmit} class="form-horizontal">
                <div className="row">
                    <div className="col-md-3">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Name</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="Name" name="Name" value={formik.values.Name} onChange={(e) => formik.setValues({ ...formik.values, Name: e.target.value })} />
                                {
                                    formik.touched.Name &&
                                        formik.errors.Name ? (
                                        <label className="text-danger" >
                                            {formik.errors.Name}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        {!isEdit2 ?
                            <>
                                <label class="control-label no-padding-right">Designer (Grapher) |<a onClick={() => { setIsEdit2(true); setSelectedDesignerData(undefined) }}>Clear & Change</a></label>
                                <br />
                                {selectedDesignerData ?
                                    <Link to={"/contact/dashboard/" + selectedDesignerData.id}>
                                        <ContactView item={selectedDesignerData} uid={"CS1"} />
                                    </Link>
                                    :
                                    "NA"
                                }
                            </>
                            :
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Designer (Grapher)</label>
                                    <ContactSelector searchtext="" setSelectedData={setSelectedDesignerData} />
                                </div>
                                <ContactSelectorView data={selectedDesignerData} />
                            </div>
                        }
                    </div>
                    <div className="col-md-3">
                        {!isEdit ?
                            <>
                                <label class="control-label no-padding-right">Contact | <a onClick={() => { setIsEdit(true); setSelectedContactData(undefined); setSelectedProductionData(undefined) }}>Clear & Change</a></label>
                                <br />
                                {selectedContactData ?
                                    <Link to={"/contact/dashboard/" + selectedContactData.id}>
                                        <ContactView item={selectedContactData} uid={"CS1"} />
                                    </Link>
                                    :
                                    "NA"
                                }
                            </>
                            :
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Contact</label>
                                    <ContactSelector searchtext="" setSelectedData={setSelectedContactData} />
                                </div>
                                <ContactSelectorView data={selectedContactData} />
                            </div>
                        }
                    </div>
                    <div className="col-md-3">
                        {!isEdit ?
                            <>
                                <label class="control-label no-padding-right">Production</label>
                                <br />
                                {selectedProductionData ?
                                    <Link to={"/productionunit/dashboard/" + selectedProductionData.id}>
                                        <ProductionUnitView item={selectedProductionData} />
                                    </Link> : "NA"
                                }
                            </>
                            :
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Production</label>
                                    <select className="form-control" name="toProductionUnitId" value={formik.values.toProductionUnitId} onChange={OnProductionChange}>
                                        <option value="" >Select...</option>
                                        {
                                            production.map((data, index) =>
                                                <option key={data.id} value={data.id}>{data.productionUnitName.toUpperCase()} ({data.id})</option>
                                            )
                                        }
                                    </select>
                                    <ProductionSelectorView data={selectedProductionData} />
                                    {
                                        formik.touched.toProductionUnitId &&
                                            formik.errors.toProductionUnitId ? (
                                            <label className="text-danger" >
                                                {formik.errors.toProductionUnitId}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        }
                    </div>

                </div>

                <div className="row">
                    <div className="col-xs-12">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right"> Note</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="Note" name="Note" value={formik.values.Note} onChange={(e) => formik.setValues({ ...formik.values, Note: e.target.value })} />
                                {
                                    formik.touched.Note &&
                                        formik.errors.Note ? (
                                        <label className="text-danger" >
                                            {formik.errors.Note}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div class="clearfix form-actions">
                    <div class="pull-right">
                        {isLoadingLocal ?
                            <LoadingButton />
                            :
                            <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                <i class="ace-icon fa fa-check bigger-110"></i>
                                Update
                            </button>}
                    </div>
                </div>

            </form>
        </Container >
    )
}
