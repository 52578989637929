import GetLoginInfo from "../../auth/_userContext/GetLoginInfo";
import { OrgId } from "../../config/GlobalSettings";
import { createAxios, createAxiosWithToken } from "../../initApi/createAxios";

export async function VoucherAdd(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/AdvanceVoucher/VoucherAdd", param)
    }
}

export async function VoucherRemove(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/AdvanceVoucher/VoucherRemove?Id=" + id)
    }
}

export async function GetVoucherById(voucherId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Vouchers/AdvanceVoucher/GetVoucherById?VoucherId=" + voucherId)
    }
}

export async function AddVoucherPayment(voucherPayments) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/AdvanceVoucher/AddVoucherPayment", voucherPayments)
    }
}

export async function RemoveVoucherPayment(paymentId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/AdvanceVoucher/RemoveVoucherPayment?PaymentId=" + paymentId)
    }
}

export async function GetAdvanceByContactId(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/AdvanceVoucher/GetAdvanceByContactId", param)
    }
}