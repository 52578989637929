import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Container } from '../../../base/Container';
import { loadingContext } from '../../../commonLib/_lodingContext';
import { TimelineHistory } from '../../Global/TimelineHistory';
import { SalaryItemView } from './SalaryItemView';
import * as salaryVoucherCrud from '../../../_crud/vouchers/salaryVoucherCrud'
import { AdvanceView } from '../../Global/Elements/AdvanceView';
import { PrintStarterDash } from '../../Prints/PrintStaterDash';
import { SalaryVoucherPrint } from '../../Prints/SalaryVoucherPrint';
import { DefectView } from '../../Global/Elements/DefectView';
import { getIsPrint } from '../../../config/GlobalSettings';


export function SalaryVoucherDashBoard() {
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [salaryVoucher, setSalaryVoucher] = useState()
    const [voucherPayment, setVoucherPayment] = useState([])
    const [advanceData, setAdvanceData] = useState()
    const [defectData, setDefectData] = useState()

    const { id } = useParams()

    function doRefresh() {
        getSalaryVoucher()
    }

    useEffect(() => {
        getSalaryVoucher()
    }, []);

    function getSalaryVoucher() {
        setIsLoading(true);
        salaryVoucherCrud.GetVoucherById(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let hpayment = res.data.data.payments
                        setSalaryVoucher(hdata)
                        setVoucherPayment(hpayment)
                    }
                    else {
                    }
                }
                else {
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
            })
    }
    const printRef = useRef();

    return (
        <>
            {getIsPrint && <div style={{ display: "none" }}>
                <SalaryVoucherPrint paid={salaryVoucher && salaryVoucher.paid} pending={salaryVoucher && salaryVoucher.pending} salaryVoucher={salaryVoucher && salaryVoucher.voucher} voucherPayment={voucherPayment} ref={printRef} />
            </div>}
            <Container title={"Salary Voucher"} doRefresh={doRefresh} isBack="true">
                {getIsPrint && salaryVoucher && salaryVoucher && voucherPayment &&
                    <div class="btn-group">
                        <PrintStarterDash printRef={printRef} />
                    </div>
                }
                <div class="hr dotted"></div>

                <div className="row">
                    <div className="col-lg-8">
                        <SalaryItemView advance={advanceData} defect={defectData} paid={salaryVoucher && salaryVoucher.paid} pending={salaryVoucher && salaryVoucher.pending} salaryVoucher={salaryVoucher && salaryVoucher.voucher} voucherPayment={voucherPayment} doRefresh={getSalaryVoucher} />
                    </div>

                    <div className="col-lg-4">
                        {salaryVoucher && <AdvanceView advanceData={setAdvanceData} contactid={salaryVoucher.voucher.contact.id} />}
                        <br />
                        {salaryVoucher && <DefectView defectData={setDefectData} contactid={salaryVoucher.voucher.contact.id} />}
                        <br />
                        <TimelineHistory id={id} type={1} pageSize={10} text="Salary Voucher Timeline" />
                    </div>

                </div>
            </Container>
        </>
    )
}
