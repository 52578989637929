import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Logout } from '../auth/Logout'
import GetLoginInfo from '../auth/_userContext/GetLoginInfo'
import { GlobalSpinner } from '../commonLib/GlobalSpinner'
import { DEFAULT_USER_IMG, ERROR_IMG } from '../config/GlobalSettings'
import { BASE_URL } from '../initApi/apiUrls'
import { ContactSearch } from '../pages/Global/ContactSearch'
import { organizationContext } from '../pages/Organization/organizationContext'

export function TopMenu({ handleToggleSidebar }) {
    const [organization, setOrganization] = useContext(organizationContext);
    const userData = GetLoginInfo()
    return (
        <>
            <div id="navbar" class="navbar navbar-default navbar-collapse h-navbar ace-save-state navbar-fixed-top">
                <button style={{ display: "block" }} type="button" class="navbar-toggle menu-toggler pull-left" onClick={() => handleToggleSidebar(true)}>
                    <span class="sr-only">Toggle sidebar</span>

                    <span class="icon-bar"></span>

                    <span class="icon-bar"></span>

                    <span class="icon-bar"></span>
                </button>
                <div class="navbar-container ace-save-state" id="navbar-container">
                    <div class="navbar-header pull-left">
                        <Link to="/home" class="navbar-brand">
                            <small>
                                {/* {(organization && organization.isLogo == 1) && <img style={{ filter: "brightness(0) invert(1)" }} height="25" src={BASE_URL + "/" + organization.logoUrl} onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />} */}
                                <img height="25px" src="images/logo-small-white.png" />
                            </small>
                        </Link>


                        {/* <img class="pull-right" style={{ filter: "brightness(0) invert(1)", margin: "5px", padding: "5px" }} height="35px" src="images/logo-min.png" /> */}
                        {/* <img height="35px" class="pull-right" style={{ margin: "5px", padding: "5px" }} src="images/logo-small-white.png" /> */}
                        {/* <img height="35px" width="35px" style={{ margin: "0" }} className="img-round pull-left" src={userData.isDP == 1 ? BASE_URL + "/" + userData.dpUrl : DEFAULT_USER_IMG}
                                onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }}
                            /> */}
                        {/* <div style={{ marginRight: "5px" }}>
                            <GlobalSpinner />
                        </div> */}
                        <div style={{ marginTop: "8px" }}>
                            <ContactSearch searchtext="" />
                        </div>

                    </div>

                    {/* <div class="navbar-buttons navbar-header pull-right  collapse navbar-collapse" role="navigation">
                        <ul class="nav ace-nav">
                            
                            <li class="light-blue dropdown-modal">
                                <a data-toggle="dropdown" href="#" class="dropdown-toggle">
                                    <img className="img-round" height="40px" width="40px" src={userData.isDP == 1 ? BASE_URL + "/" + userData.dpUrl : DEFAULT_USER_IMG}
                                        onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }}
                                    />
                                    <span class="user-info">
                                        <small>Welcome,</small>
                                        {userData.fullName} ({userData.userName})
                                    </span>

                                    <i class="ace-icon fa fa-caret-down"></i>
                                </a>

                                <ul class="user-menu dropdown-menu-right dropdown-menu dropdown-yellow dropdown-caret dropdown-close">

                                    <li>
                                        <Link to="/profile/dp">
                                            <i class="ace-icon fa fa-user"></i>
                                            Profile
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/changepassword">
                                            <i class="ace-icon fa fa-key"></i>
                                            Change Password
                                        </Link>
                                    </li>

                                    <li class="divider"></li>

                                    <li>
                                        <Logout />
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div> */}


                </div>
            </div>
        </>
    )
}
