import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetAll(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductReceivingLot/GetAll", param)
    }
}

export async function GetByStatus(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductReceivingLot/GetByStatus", param)
    }
}
export async function GetAllWarehouse(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductReceivingLot/GetAllWarehouse", param)
    }
}

export async function GetByStatusWarehouse(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductReceivingLot/GetByStatusWarehouse", param)
    }
}
export async function GetByProductId(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductReceivingLot/GetByProductId?PageNo=" + param.pageNumber + "&SearchTerm=" + param.searchText + "&PageSize=" + param.pageSize + "&ContactId=" + param.contactId + "&ProductId=" + param.productId + "&FromDate=" + param.fromDate + "&ToDate=" + param.toDate)
    }
}

export async function GetByProductIdOpenLot(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductReceivingLot/GetByProductIdOpenLot", param)
    }
}

export async function GetByContactId(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductReceivingLot/GetByContactId?PageNo=" + param.pageNumber + "&SearchTerm=" + param.searchText + "&PageSize=" + param.pageSize + "&ContactId=" + param.contactId + "&FromDate=" + param.fromDate + "&ToDate=" + param.toDate)
    }
}
export async function GetByProductionUnitId(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductReceivingLot/GetByProductionUnitId?PageNo=" + param.pageNumber + "&SearchTerm=" + param.searchText + "&PageSize=" + param.pageSize + "&ContactId=" + param.contactId + "&ProductionUnitId=" + param.productionUnitId + "&FromDate=" + param.fromDate + "&ToDate=" + param.toDate)
    }
}