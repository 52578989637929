import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetAll(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingStock/GetAll", param)
    }
}
export async function GetAllWarehouse(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingStock/GetAllWarehouse", param)
    }
}

export async function GetByProcessing(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingStock/GetByProcessing", param)
    }
}
export async function GetByProcessingWarehouse(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingStock/GetByProcessingWarehouse", param)
    }
}

export async function GetByProduct(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductProcessingStock/GetByProduct?PageNo=" + param.pageNumber + "&SearchTerm=" + param.searchText + "&PageSize=" + param.pageSize + "&ProductId=" + param.productId)
    }
}
export async function GetByContact(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingStock/GetByContact", param)
    }
}
export async function GetByContactSelector(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingStock/GetByContactSelector", param)
    }
}

export async function GetByProductionUnit(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductProcessingStock/GetByProductionUnit?PageNo=" + param.pageNumber + "&SearchTerm=" + param.searchText + "&PageSize=" + param.pageSize + "&ProductionUnitId=" + param.productionUnitId)
    }
}