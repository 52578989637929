import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../base/Container'
import { AdvanceViewAll } from './Global/Elements/AdvanceViewAll';
import { DiskSize } from './Global/Elements/DiskSize';
import { PayableForAll } from './Global/Elements/PayableForAll';
import { CashBankFlowChart } from './Global/Reports/CashBankFlowChart';
import { organizationContext } from './Organization/organizationContext';
import * as reportCrud from './../_crud/reportCrud'
import * as organizationCrud from './../_crud/organizationCrud'
import { DefectViewAll } from './Global/Elements/DefectViewAll';
import { ContactCategoryReport } from './Global/Reports/ContactCategoryReport';
// import { ModelCreatorDemo } from '../commonLib/ModelCreatorDemo';

export default function Home() {
    const [organization, setOrganization] = useContext(organizationContext);
    const [counts, setCounts] = useState()
    useEffect(() => {
        GetGlobalCountContact()
        getOrganization()
    }, []);

    function GetGlobalCountContact() {
        reportCrud.GetGlobalCount()
            .then(res => {
                if (res.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data
                        setCounts(hdata)
                    }
                    else {
                    }
                }
                else {
                }
            })
            .catch(error => {
            })
    }

    function getOrganization() {
        organizationCrud.GetOrganization()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setOrganization(hdata)
                    }
                    else {
                    }
                }
                else {
                }
            })
            .catch(error => {
            })
    }

    return (
        <Container title="Dashboard">
            {/* <ModelCreatorDemo/> */}
            <div class="clearfix">
                <div className="row">
                    <div className="col-xs-12">
                        <CashBankFlowChart />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-xs-12">
                        {counts &&
                            <div class="center">
                                <span class="btn btn-app btn-sm btn-light no-hover">
                                    <span class="line-height-1 bigger-170 blue"> {counts.productionUnit} </span>

                                    <br />
                                    <span class="line-height-1 smaller-90"> Productions </span>
                                </span>

                                <span class="btn btn-app btn-sm btn-yellow no-hover">
                                    <span class="line-height-1 bigger-170"> {counts.product} </span>

                                    <br />
                                    <span class="line-height-1 smaller-90"> Products </span>
                                </span>

                                <span class="btn btn-app btn-sm btn-pink no-hover">
                                    <span class="line-height-1 bigger-170"> {counts.processing} </span>

                                    <br />
                                    <span class="line-height-1 smaller-90"> Processings </span>
                                </span>

                                <span class="btn btn-app btn-sm btn-grey no-hover">
                                    <span class="line-height-1 bigger-170"> {counts.order} </span>

                                    <br />
                                    <span class="line-height-1 smaller-90"> Orders </span>
                                </span>

                                <span class="btn btn-app btn-sm btn-success no-hover">
                                    <span class="line-height-1 bigger-170"> {counts.activeProduct} </span>

                                    <br />
                                    <span class="line-height-1 smaller-90"><small>Active Products</small></span>
                                </span>
                                <span class="btn btn-app btn-sm btn-info no-hover">
                                    <span class="line-height-1 bigger-170"> {counts.lot} </span>

                                    <br />
                                    <span class="line-height-1 smaller-90"> Lots </span>
                                </span>
                            </div>
                        }
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-lg-6">
                                <AdvanceViewAll />
                            </div>
                            <div className="col-lg-6">
                                <DefectViewAll />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-lg-6">
                                <PayableForAll />
                            </div>
                            <div className="col-lg-6">
                                <DiskSize />
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-4">
                        <ContactCategoryReport />
                    </div>
                </div>
            </div>
        </Container>
    )
}
