import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import AppToaster from '../../commonLib/AppToaster'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext'
import * as productionUnitCrud from '../../_crud/productionUnitCrud'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { Container } from '../../base/Container'
import { LoadingButton } from '../../commonLib/LoadingButton'
import ContactSelector from '../Global/ContactSelector'
import { ContactSelectorView } from '../Global/SelectorView/ContactSelectorView'

export function ChangeContacts() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const { id } = useParams()
    const [selectedContactData, setSelectedContactData] = useState(undefined);

    useEffect(() => {
        if (selectedContactData) {
            formik.setValues({ ...formik.values, contactId: selectedContactData.id })
        }
    }, [selectedContactData]);

    const initialValues = {
        contactId: "",
        producationUnitId: id
    }
    const Schema = Yup.object().shape({
        contactId: Yup.string().required("Contact is required.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    const Save = (value) => {
        if (id != selectedContactData.id) {
            setIsLoadingMini(true);
            setIsLoadingLocal(true)
            productionUnitCrud.ChangeContact(value)
                .then(res => {
                    if (res.data.succeeded) {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false);
                        AppToaster('Alloted successfully.', 'success')
                    }
                    else {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                })
                .catch(error => {
                    setIsLoadingMini(false);
                    setIsLoadingLocal(false);
                    AppToaster(error, 'error')
                })
        }
        else {
            AppToaster("You could not allot contact to same production unit. ", 'error')
        }
    }

    return (
        <>
            <Container title="Change Contact" isBack="true" isProduction="true" isContact="true">
                <div className="col-xs-12">
                    <div class="form-group">
                        <div class="col-xs-12">
                            <label class="control-label no-padding-right">Contact</label>
                            <ContactSelector searchtext="" setSelectedData={setSelectedContactData} />
                            {
                                formik.touched.contactId &&
                                    formik.errors.contactId ? (
                                    <label className="text-danger" >
                                        {formik.errors.contactId}
                                    </label>
                                ) : null
                            }
                        </div>
                        <ContactSelectorView data={selectedContactData} />
                    </div>
                </div>

                <div class="col-xs-12">
                    <div className="space-4"></div>
                    <div class="col-xs-2"></div>
                    <form onSubmit={formik.handleSubmit} class="form-horizontal">
                        <div class="clearfix form-actions">
                            <div class="pull-right">
                                {isLoadingLocal ?
                                    <LoadingButton />
                                    :
                                    <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                        <i class="ace-icon fa fa-check bigger-110"></i>
                                        Allot
                                    </button>}
                            </div>
                        </div>

                    </form>
                </div>
            </Container >
        </>
    )
}
