import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetAll(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("InitOrderForProduction/GetAll", param)
    }
}
export async function GetAllWarehouse(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("InitOrderForProduction/GetAllWarehouse", param)
    }
}
export async function GetAllotedForContactId(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("InitOrderForProduction/GetAllotedForContactId", param)
    }
}
export async function GetAllotedForContactIdByStatus(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("InitOrderForProduction/GetAllotedForContactIdByStatus", param)
    }
}
export async function GetByStatus(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("InitOrderForProduction/GetByStatus", param)
    }
}
export async function GetById(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("InitOrderForProduction/GetById?Id=" + id)
    }
}
export async function Add(initOrderForProduction) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("InitOrderForProduction/Add", initOrderForProduction)
    }
}
export async function Remove(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("InitOrderForProduction/Remove?Id=" + id)
    }
}
export async function SentToOrder(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("InitOrderForProduction/SendToOrder",param)
    }
}