import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { EmptyData } from './../../../commonLib/EmptyData'
import Moment from 'react-moment';
import { GetEChequeStatus, GetEChequeStatusName } from './../../../enumCollections/EChequeStatus'
import { GetEBoolName } from './../../../enumCollections/EBool'
import { Spinner } from './../../../commonLib/Spinner';
import { Paging } from './../../../commonLib/Paging';
import { Container } from './../../../base/Container';
import AppToaster from './../../../commonLib/AppToaster';
import *as bankTransactionCud from './../../../_crud/cheque/bankTransactionCrud'
import *as bankCrud from './../../../_crud/cheque/bankCrud'
import DatePicker from "react-datepicker";
import GetCurrentDateForSearch from './../../../commonLib/dateFormat'
import { loadingMiniContext } from './../../../commonLib/lodingMiniContext';
import { MyAlert } from './../../../commonLib/MyAlert';
import { UserView } from './../../Global/Elements/UserView';
import { StatusView } from './../../../commonLib/StatusView';
import { loadingContext } from './../../../commonLib/_lodingContext';
import { PAGING_PAGE_SIZE } from '../../../config/GlobalSettings';
import { ContactView } from '../../Global/Elements/ContactView';
import { GetEAccountTypeName } from '../../../enumCollections/EAccountType';
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater';

const initValues = {
    Search: {
        searchTerm: "",
        pageNo: 1,
        pageSize: PAGING_PAGE_SIZE
    },
    Range: {
        fromDate: new Date(GetCurrentDateForSearch(-30)),
        toDate: new Date(GetCurrentDateForSearch(0))
    },
    BankId: "",
    Status: "All"
}

export function ManageTransaction() {
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [search, setSearch] = useState(initValues)
    const [transaction, setTransaction] = useState([])
    const [LastPage, setLastPage] = useState(1)
    const [searchType, setSearchType] = useState(0)
    const [pagingData, setPagingData] = useState()
    const [bank, setBank] = useState([])

    const OnPageChange = (e) => {
        const { value } = e.target
        setSearch({ ...search, Search: { ...search.Search, pageNo: value } });
    }
    useEffect(() => {
        getBank()
    }, [])

    useEffect(() => {
        if (bank && bank.length > 0) {
            setSearch({ ...search, BankId: bank[0].id })
            OnSearch(bank[0].id)
        }
    }, [bank])

    function OnSearch(data) {
        if (search.Status == "All") {
            if (data) {
                getTransaction({ ...search, BankId: data })
            }
            else
                getTransaction(search)
        }
        else
            getTransactionByStatus()
    }

    function getTransaction(data) {
        setIsLoading(true);
        bankTransactionCud.GetAll(data)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setTransaction(hdata);
                        setIsLoading(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoading(false)
                        setTransaction([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoading(false)
                    setTransaction([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoading(false);
                AppToaster(error, 'error')
            })
    }

    function getTransactionByStatus() {
        setIsLoading(true);
        bankTransactionCud.GetAllByStatus(search)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setTransaction(hdata);
                        setIsLoading(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoading(false)
                        setTransaction([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoading(false)
                    setTransaction([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoading(false);
                AppToaster(error, 'error')
            })
    }

    function getBank() {
        setIsLoading(true);
        bankCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setBank(hdata);
                    }
                    else {
                        setBank([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setBank([]);
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        setSearch({ ...search, Search: { ...search.Search, pageNo: 1 } })
        OnSearch(undefined)
        setTimeout(() => {
            event.target.complete();
        }, 1000);
    }

    function SearchChange(e) {
        const value = e.target.value
        setSearch({ ...search, Search: { ...search.Search, searchTerm: value } })
    }

    const DeleteItem = (id) => {
        setIsLoading(true)
        bankTransactionCud.Remove(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoading(false)
                    AppToaster('Deleted successfully.', 'success')
                    getTransaction()
                }
                else {
                    setIsLoading(false)
                    AppToaster('Using by someone,could not delete.', 'error')
                }

            }).catch(error => {
                setIsLoading(false)
                AppToaster('Network error', 'error')
            })
    }

    return (
        <>
            <Container title="Transactions" doRefresh={doRefresh}>
                <Link to="/transaction/add" class="btn btn-sm btn-success " style={{ marginLeft: "0px" }}>
                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                    New
                </Link>
                <div class="hr dotted"></div>
                <div class="form-horizontal" >
                    <div className="row">
                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker selectsStart startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.fromDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, fromDate: date } })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker selectsEnd startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.toDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, toDate: date } })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <select className="form-control" value={search.BankId} onChange={(e) => setSearch({ ...search, BankId: e.target.value })}>
                                    {
                                        bank.map((item, index) =>
                                            <option value={item.id}>{item.accountHolderName} ({item.bankName}-{item.accountNo})</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <select className="form-control" value={search.Status} onChange={(e) => setSearch({ ...search, Status: e.target.value })}>
                                    <option value="All">All</option>
                                    {
                                        GetEChequeStatus.EChequeStatus.map((data, index) =>
                                            <option key={data.Id} value={data.Id}>{data.Name}</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <TextBoxTranslater style={{paddingLeft: "24px"}} value={search.Search.searchTerm} onChange={SearchChange} className="form-control" type="text" placeholder="Search..." />
                                    <i class="ace-icon fa fa-search blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-1">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <button onClick={() => OnSearch(undefined)} to="/order/add" class="btn btn-sm btn-info pull-right">
                                    <i class="ace-icon fa fa-search bigger-110"></i>
                                    Run
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <Paging
                    pagingData={pagingData}
                    LastPage={LastPage}
                    CurrentPage={search.Search.pageNo}
                    OnPageChange={OnPageChange} >

                    {isLoading ?
                        <Spinner />
                        :
                        transaction && transaction.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "43px" }}></th>
                                            <th>#Id</th>
                                            <th>Cheque Date</th>
                                            <th>Contact</th>
                                            <th>For Bank</th>
                                            <th>Cheque/Trans No</th>
                                            <th>Cheque/Trans Given By</th>
                                            <th>Cheque/Trans Form Bank</th>
                                            <th>Cheque/Trans City</th>
                                            <th>Cheque/Trans Amount</th>
                                            <th>Status</th>
                                            <th>Note</th>
                                            <th style={{ width: "43px" }}></th>
                                            <th style={{ width: "43px" }}></th>
                                        </tr>
                                    </thead>


                                    <tbody>
                                        {transaction.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link className="btn btn-xs btn-info" to={"/transaction/dashboard/" + item.id} title="View dashboard" ><i class="ace-icon fa fa-tachometer"></i></Link>
                                                    </div>
                                                </td>
                                                <td>{item.id}</td>
                                                <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.chequeDate}</Moment></div></td>
                                                <td>
                                                    <Link to={"/contact/dashboard/" + item.contactId}>
                                                        <ContactView item={item.contact} uid={"C" + index} />
                                                    </Link>
                                                </td>
                                                <td>
                                                    <div style={{ width: "250px" }}>
                                                        {item.bank.accountHolderName} ({item.bank.accountNo} - {GetEAccountTypeName(item.bank.accountType)})
                                                        <br />
                                                        IFSC: {item.bank.ifscCode}, Branch: {item.bank.branch}, {item.bank.city}, {item.bank.state}
                                                    </div>
                                                </td>
                                                <td><b>{item.chequeNo}</b></td>
                                                <td>{item.chequeGivenByName}</td>
                                                <td>{item.chequeBankName}</td>
                                                <td>{item.chequeCity}</td>
                                                <td><b>₹{Number(item.chequeAmount).toFixed(2)}</b></td>
                                                <td><StatusView status={item.status}>{GetEChequeStatusName(item.status)}</StatusView></td>
                                                <td><div style={{ width: "150px" }}>{item.note}</div></td>
                                                <td>
                                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                            <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                        </MyAlert>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </Paging>
            </Container >
        </>
    )
}
