import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetByProcessing(queryParams) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ContactProcessingAllotment/GetByProcessing?PageNo=" + queryParams.pageNumber + "&PageSize=" + queryParams.pageSize + "&SearchTerm=" + queryParams.searchText + "&ProocessingId=" + queryParams.proocessingId + "&FromDate=" + queryParams.fromDate + "&ToDate=" + queryParams.toDate)
    }
}

export async function GetByContact(contactId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ContactProcessingAllotment/GetByContact?ContactId=" + contactId)
    }
}
export async function GetByContactDdl(contactId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ContactProcessingAllotment/GetByContactDdl?ContactId=" + contactId)
    }
}
export async function Add(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ContactProcessingAllotment/Add", param)
    }
}

export async function UpdateStatus(queryParams) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ContactProcessingAllotment/UpdateStatus", queryParams)
    }
}


