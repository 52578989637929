import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { PagingEmpty } from '../../commonLib/PagingEmpty';
import { Spinner } from '../../commonLib/Spinner';
import *as warehouseCrud from '../../_crud/warehouse/warehouseCrud'
import 'moment-timezone';
import { EmptyData } from '../../commonLib/EmptyData';
import { MyAlert } from '../../commonLib/MyAlert';
import { dashboardContext } from '../Global/dashboardContext';
import SetLoginInfo from '../../auth/_userContext/SetLoginInfo';
import GetLoginInfo from '../../auth/_userContext/GetLoginInfo';

export function ManageWarehouse() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [productCategories, setProductCategories] = useState([])
    const [dashboardData, setDashboardData] = useContext(dashboardContext)
    const userData = GetLoginInfo()

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoadingMini(true);
        warehouseCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProductCategories(hdata);
                    }
                    else {
                        setProductCategories([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProductCategories([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        getData()
        setTimeout(() => {
            event.detail.complete();
        }, 1000);
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        warehouseCrud.Remove(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    AppToaster('Deleted successfully.', 'success')
                    getData()
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by someone,could not delete.', 'error')
                }

            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }

    function convertToWarehouse(item) {
        let d = { ...userData, warehouseCode: item.warehouseCode, warehouseId: item.id, isWarehouse: 1 }
        SetLoginInfo(d)
        window.location.replace("/");
    }

    function revertToWarehouse() {
        let d = { ...userData, warehouseCode: "", warehouseId: "", isWarehouse: 0 }
        SetLoginInfo(d)
        window.location.replace("/");
    }
    return (
        <>
            <Container title="Warehouse" doRefresh={doRefresh} isBack="true">
                <Link to="/warehouse/add" class="btn btn-sm btn-success">
                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                    New
                </Link>
                {userData.role == "101" && userData.isWarehouse == 1 &&
                    <MyAlert confirmBtnText={"Yes, Do it!"} title="Revert to MAIN Warehouse!" onConfirm={revertToWarehouse} isFormated={false}>
                        <a class="btn btn-sm btn-yellow" style={{ marginLeft: "5px" }}>
                            <i class="ace-icon fa fa-compress bigger-110"></i>
                            Revert To MAIN
                        </a>
                    </MyAlert>
                }
                <div class="hr dotted"></div>
                <PagingEmpty text={"Total " + productCategories.length + " items."}>
                    {isLoadingMini ?
                        <Spinner />
                        :
                        productCategories && productCategories.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            {/* <th style={{ width: "43px" }}></th> */}
                                            <th style={{ width: "43px" }}></th>
                                            <th>Code</th>
                                            <th>Name</th>
                                            <th>City</th>
                                            <th>Address</th>
                                            <th>Details</th>
                                            <th style={{ width: "83px" }}></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {productCategories.map((item, index) =>
                                            <tr key={item.id}>
                                                {/* <td>
                                                    <div class="btn-group">
                                                        <Link className="btn btn-xs btn-info" to={"/warehouse/dashboard/" + item.id} title="View dashboard" ><i class="ace-icon fa fa-tachometer"></i></Link>
                                                    </div>
                                                </td> */}
                                                <td>
                                                    {userData.role == "101" && userData.isWarehouse == 1 && item.id == userData.warehouseId ?
                                                        null
                                                        :
                                                        userData.role == "101" &&
                                                        <MyAlert confirmBtnText={"Yes, Do it!"} title={"Convert to " + item.warehouseCode + " Warehouse!"} onConfirm={convertToWarehouse} param={item} isFormated={false}>
                                                            <div class="btn-group">
                                                                <a className="btn btn-xs btn-yellow" title={"Work as " + item.warehouseCode} >
                                                                    <i class="ace-icon fa fa-compress"></i>
                                                                </a>
                                                            </div>
                                                        </MyAlert>
                                                    }
                                                </td>
                                                <td className="bolder"><i class="menu-icon fa fa-industry"></i> {item.warehouseCode} </td>
                                                <td>{item.name} </td>
                                                <td>{item.city} </td>
                                                <td>{item.address} </td>
                                                <td>{item.details}</td>
                                                <td>
                                                    <div style={{ width: "73px" }}>
                                                        <Link to={"/warehouse/edit/" + item.id} class="btn btn-xs btn-success">
                                                            <i class="ace-icon fa fa-pencil bigger-120"></i>
                                                        </Link>&nbsp;
                                                        <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                            <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                        </MyAlert>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </PagingEmpty>
            </Container >
        </>
    )
}
