import GetLoginInfo from "../../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../../initApi/createAxios";
import { OrgId } from "../../config/GlobalSettings";

export async function VoucherAdd(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("vouchers/OpeningBalanceVoucher/VoucherAdd", param)
    }
}

// export async function VoucherRemove(param) {
//     alert(JSON.stringify(param))
//     const loginData = GetLoginInfo();
//     if (loginData) {
//         return await createAxiosWithToken(loginData.token, OrgId()).post("vouchers/OpeningBalanceVoucher/VoucherRemove?Id=" + param.id + "&CreatedBy=" + param.createdBy + "&Type=" + param.searchvalue)
//     }
// }

export async function VoucherRemove(Id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("vouchers/OpeningBalanceVoucher/VoucherRemove?Id=" + Id)
    }
}

export async function GetVoucherById(Id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("OpeningBalanceVoucher/GetVoucherById?Id=" + Id)
    }
}
