import React from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../../base/Footer';
import { MobileVerify } from './MobileVerify';
import { OrgRegistrationForm } from './OrgRegistrationForm';

export function SignUp() {
    const [readyOrg, setReadyOrg] = useState(false);
    const [isCreatedOrg, setIsCreatedOrg] = useState(false);
    const [token, setToken] = useState("");
    const [phone, setPhone] = useState("");
    return (
        <>
            <div class="login-layout light-login">
                <div class="main-container">
                    <div class="main-content">
                        <div class="row">
                            <div class="col-sm-10 col-sm-offset-1">
                                <div style={{ margin: "0 auto" }} >
                                    <div class="center">
                                        <br />
                                        <br />
                                        <a href="https://baithka.com" target="_blank">
                                            <img src="images/Baithka-64.gif"
                                                onError={(e) => { e.target.onerror = null; e.target.src = "images/Baithka-64.gif" }} />
                                        </a>
                                        <div class="social-or-login center">
                                            <span class="bigger-110">&nbsp;&nbsp;textile manufacturing&nbsp;&nbsp;</span>
                                        </div>
                                    </div>

                                    <div class="space-6"></div>

                                    <div class="position-relative">
                                        <div class="signup-box visible widget-box no-border">
                                            <div class="widget-body">
                                                <div class="widget-main">
                                                    <h4 class="header green lighter bigger">
                                                        <i class="ace-icon fa fa-users blue"></i> New Organization Registration <small>(15 days free trial)</small>
                                                    </h4>

                                                    <div class="space-6"></div>
                                                    {isCreatedOrg ?
                                                        <div className="row center">
                                                            <div className="col-lg12">
                                                                <i style={{ fontSize: "100px" }} class="ace-icon bigger fa fa-check-circle blue"></i>
                                                                <h1 className="green">Congratulations</h1>
                                                                <h1>New Organization has been created Successfully!</h1>
                                                                <h2 className="blue">Note: Your organization is under review by Baithka Team, it will active under 24 hours.</h2>
                                                                <h4>User Id: <b className="green">administrator</b> is your first power user for your organization,<br />use your organization id and password with <b className="green">administrator</b> user.</h4>
                                                                <br />
                                                                <br />
                                                            </div>
                                                        </div>
                                                        :
                                                        <>
                                                            {readyOrg ?
                                                                <OrgRegistrationForm token={token} phone={phone} setIsCreatedOrg={setIsCreatedOrg} />
                                                                :
                                                                <MobileVerify setToken={setToken} setReadyOrg={setReadyOrg} setPhone={setPhone} />
                                                            }
                                                        </>
                                                    }
                                                </div>

                                                <div class="toolbar blue center">
                                                    <Link to="/login" class="back-to-login-link">
                                                        <i class="ace-icon fa fa-arrow-left"></i> Back to login
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="space-6"></div>
                            <br />
                            <br />
                            <div class="col-xs-12" style={{ textAlign: "center" }}>
                                <div class="footer-inner">
                                    <div class="footer-content">
                                        <p>
                                            <a href="https://cubicle8.com" target="_blank">
                                                <img style={{ marginBottom: "3px" }} height="32px" src="images/cubiclelogo.gif" />
                                            </a>
                                            <br />
                                            <span class="blue" style={{ verticalAlign: "bottom" }}><a href="https://baithka.com/about" target="_blank">About</a> | <a href="https://baithka.com/term" target="_blank">Term & Conditions</a></span>

                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
