import React from 'react'

export function StatusView({ status, type = 1, ...props }) {

    const GetType = () => {
        if (type == 1) {
            switch (status) {
                case 0: return "danger";
                case 1: return "success";
                case 2: return "purple";
                case 3: return "primary";
                case 4: return "pink";
                default: return "default";
            }
        }
        else {
            switch (status) {
                case 0: return "warning";
                case 1: return "danger";
                case 2: return "success";
                case 3: return "primary";
                case 4: return "pink";
                default: return "default";
            }
        }
    }

    return (
        <span class={"label label-sm label-" + GetType()}>
            {props.children}
        </span>
    )
}
