import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from "yup";
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import * as productCrud from '../../_crud/productCrud';
import * as productCategoryCrud from '../../_crud/productCategoryCrud';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { GetEWageType } from '../../enumCollections/EWageType';
import { LoadingButton } from '../../commonLib/LoadingButton';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

const initialValues = {
    productName: "",
    productCatId: "",
    isProduction: 1,
    unit: "",
    wageType: 2,
    globalStock: 0,
    rawStock: 0,
    mainStock: 0,
    defectiveStock: 0,
    rejectedStock: 0,
    processingStock: 0,
    wage: 0,
    cost: 0,
    details: ""
}

export function AddProduct({ isModelView = false }) {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const history = useHistory();
    const [productCategories, setProductCategories] = useState([])

    const Schema = Yup.object().shape({
        productName: Yup.string().min(3, "Product Name must between 3 to 50 characters").max(50, "Product Name must between 3 to 50 characters").required("Product Name is required."),
        productCatId: Yup.string().required("Category is required."),
        isProduction: Yup.string().required("IsProduction is required."),
        unit: Yup.string().required("Unit is required."),
        wageType: Yup.string().required("WageType is required."),
        globalStock: Yup.string().required("Global Stock is required."),
        rawStock: Yup.string().required("Raw Stock is required."),
        mainStock: Yup.string().required("Main Stock is required."),
        defectiveStock: Yup.string().required("Defective Stock is required."),
        rejectedStock: Yup.string().required("Rejected Stock is required."),
        processingStock: Yup.string().required("Processing Stock is required."),
        wage: Yup.string().required("Set zero if not needed."),
        cost: Yup.string().required("Set zero if not needed.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });
    const Save = (value) => {
        if (isModelView)
            AppToaster('Adding please wait...', 'info')
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        productCrud.Add(value)
            .then(res => {
                if (res.data.result.succeeded) {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster('Added successfully.', 'success')
                    if (!isModelView) {
                        history.goBack()
                    }
                    else {
                        formik.setValues(initialValues)
                    }
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    useEffect(() => {
        getCategories()
    }, []);

    function getCategories() {
        setIsLoadingMini(true);
        productCategoryCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProductCategories(hdata);
                    }
                    else {
                        setProductCategories([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProductCategories([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    return (
        <>
            <Container title="Add Product" isBack="true" isModelView={isModelView}>
                <form onSubmit={formik.handleSubmit} class="form-horizontal">
                    <div className="row">
                        <div className="col-md-6">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right"> Product Name</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Product Name" name="productName" value={formik.values.productName} onChange={(e) => formik.setValues({ ...formik.values, productName: e.target.value })} />
                                    {
                                        formik.touched.productName &&
                                            formik.errors.productName ? (
                                            <label className="text-danger" >
                                                {formik.errors.productName}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Category</label>
                                    <select className="form-control" name="productCatId" value={formik.values.productCatId} onChange={(e) => formik.setValues({ ...formik.values, productCatId: e.target.value })}>
                                        <option value="" >Select...</option>
                                        {
                                            productCategories.map((data, index) =>
                                                <option key={data.id} value={data.id}>{data.catName.toUpperCase()}</option>
                                            )
                                        }
                                    </select>
                                    {
                                        formik.touched.productCatId &&
                                            formik.errors.productCatId ? (
                                            <label className="text-danger" >
                                                {formik.errors.productCatId}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Wage Type</label>
                                    <select className="form-control" name="wageType" value={formik.values.wageType} onChange={(e) => formik.setValues({ ...formik.values, wageType: e.target.value })}>
                                        {
                                            GetEWageType.EWageType.map((data, index) =>
                                                <option key={data.Id} value={data.Id}>{data.Name}</option>
                                            )
                                        }
                                    </select>
                                    {
                                        formik.touched.wageType &&
                                            formik.errors.wageType ? (
                                            <label className="text-danger" >
                                                {formik.errors.wageType}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Unit</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Unit" name="unit" value={formik.values.unit} onChange={(e) => formik.setValues({ ...formik.values, unit: e.target.value })} />
                                    {
                                        formik.touched.unit &&
                                            formik.errors.unit ? (
                                            <label className="text-danger" >
                                                {formik.errors.unit}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Raw Stock</label>
                                    <input className="form-control" placeholder="Raw Stock" type="number" name="rawStock" {...formik.getFieldProps("rawStock")} />
                                    {
                                        formik.touched.rawStock &&
                                            formik.errors.rawStock ? (
                                            <label className="text-danger" >
                                                {formik.errors.rawStock}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Main Stock</label>
                                    <input className="form-control" placeholder="Main Stock" type="number" name="mainStock" {...formik.getFieldProps("mainStock")} />
                                    {
                                        formik.touched.mainStock &&
                                            formik.errors.mainStock ? (
                                            <label className="text-danger" >
                                                {formik.errors.mainStock}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Defective Stock</label>
                                    <input className="form-control" placeholder="Defective Stock" type="number" name="defectiveStock" {...formik.getFieldProps("defectiveStock")} />
                                    {
                                        formik.touched.defectiveStock &&
                                            formik.errors.defectiveStock ? (
                                            <label className="text-danger" >
                                                {formik.errors.defectiveStock}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-2">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Cost Price</label>
                                    <input className="form-control" placeholder="Cost Price" type="number" name="cost" {...formik.getFieldProps("cost")} />
                                    {
                                        formik.touched.cost &&
                                            formik.errors.cost ? (
                                            <label className="text-danger" >
                                                {formik.errors.cost}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Wage</label>
                                    <input className="form-control" placeholder="Wage" type="number" name="wage" {...formik.getFieldProps("wage")} />
                                    {
                                        formik.touched.wage &&
                                            formik.errors.wage ? (
                                            <label className="text-danger" >
                                                {formik.errors.wage}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Details</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Details" name="details" value={formik.values.details} onChange={(e) => formik.setValues({ ...formik.values, details: e.target.value })} />
                                    {
                                        formik.touched.details &&
                                            formik.errors.details ? (
                                            <label className="text-danger" >
                                                {formik.errors.details}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-md-12">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Is Production</label>
                                    <div>
                                        <input onChange={() => formik.setValues({ ...formik.values, isProduction: (formik.values.isProduction == 1 ? 0 : 1) })} checked={formik.values.isProduction == 1} type="checkbox" class="ace ace-switch ace-switch-5" />
                                        <span class="lbl middle"></span>
                                    </div>
                                    {
                                        formik.touched.isProduction &&
                                            formik.errors.isProduction ? (
                                            <label className="text-danger" >
                                                {formik.errors.isProduction}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="clearfix form-actions">
                        <div class="pull-right">
                            {isLoadingLocal ?
                                <LoadingButton />
                                :
                                isModelView ?
                                    <button data-dismiss="modal" class="btn btn-info" disabled={!formik.isValid} onClick={formik.handleSubmit} >
                                        <i class="ace-icon fa fa-check bigger-110"></i>
                                        Add
                                    </button>
                                    :
                                    <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                        <i class="ace-icon fa fa-check bigger-110"></i>
                                        Add
                                    </button>

                            }
                        </div>
                    </div>


                </form>
            </Container >
        </>
    )
}
