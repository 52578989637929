import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Container } from '../../../../base/Container'
import AppToaster from '../../../../commonLib/AppToaster'
import { PAGING_PAGE_SIZE } from '../../../../config/GlobalSettings'
import * as voucherCrud from '../../../../_crud/voucherCrud'
import * as productionWageVoucherCrud from '../../../../_crud/vouchers/productionWageVoucherCrud'
import DatePicker from "react-datepicker";
import { Paging } from '../../../../commonLib/Paging'
import { Spinner } from '../../../../commonLib/Spinner'
import { StatusView } from '../../../../commonLib/StatusView'
import { UserView } from '../../../Global/Elements/UserView'
import { GetEPaymentStatus, GetEPaymentStatusName } from '../../../../enumCollections/EPaymentStatus'
import { EmptyData } from '../../../../commonLib/EmptyData'
import { loadingMiniContext } from '../../../../commonLib/lodingMiniContext'
import GetCurrentDateForSearch from '../../../../commonLib/dateFormat'
import Moment from 'react-moment'
import { TextBoxTranslater } from '../../../../commonLib/TextBoxTranslater'

export function ManageContactProductionVoucher() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [productionVoucher, setProductionVoucher] = useState([])
    const [LastPage, setLastPage] = useState(1)
    const [pagingData, setPagingData] = useState()
    const { id } = useParams()

    const initValues = {
        searchTerm: "",
        pageNo: 1,
        pageSize: PAGING_PAGE_SIZE,
        type: 4,
        fromDate: new Date(GetCurrentDateForSearch(-30)),
        toDate: new Date(GetCurrentDateForSearch(0)),
        contactId: id,
        paymentStatus: 0
    }
    const [search, setSearch] = useState(initValues)
    const [searchType, setSearchType] = useState("all")

    useEffect(() => {
        onSearch()
    }, [search.pageNo])

    useEffect(() => {
        if (searchType != "all")
            setSearch({ ...search, paymentStatus: searchType })
    }, [searchType])

    const OnPageChange = (e) => {
        const { value } = e.target
        setSearch({ ...search, pageNo: value });
    }

    function onSearch() {
        if (searchType == "all")
            getData()
        else
            getDataByStatus()
    }

    function getData() {
        setIsLoadingMini(true);
        voucherCrud.GetVoucherByTypeAndContact(search)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setProductionVoucher(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoadingMini(false)
                        setProductionVoucher([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoadingMini(false)
                    setProductionVoucher([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function getDataByStatus() {
        setIsLoadingMini(true);
        voucherCrud.GetVoucherByTypeAndContactAndStatus(search)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setProductionVoucher(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoadingMini(false)
                        setProductionVoucher([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoadingMini(false)
                    setProductionVoucher([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        setSearch({ ...search, pageNo: 1 })
        getData()
        setTimeout(() => {
            event.target.complete();
        }, 1000);
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        productionWageVoucherCrud.VoucherRemove(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    AppToaster('Deleted successfully.', 'success')
                    getData()
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by someone,could not delete.', 'error')
                }

            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }

    return (
        <Container title="Production Voucher" doRefresh={doRefresh} isBack="true" isContact="true">
            <Link to={"/contact/dashboard/" + id + "/productionvoucher/add"} class="btn btn-sm btn-success" >
                <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                New
            </Link>
            <div class="hr dotted"></div>
            <div class="form-horizontal">
                <div className="row">
                    <div className="col-md-2">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <span class="input-icon" style={{ width: "100%" }}>
                                <DatePicker selectsStart startDate={search.fromDate} endDate={search.toDate} className="form-control input-date-padding" selected={search.fromDate} onChange={(date) => setSearch({ ...search, fromDate: date })} />
                                <i class="ace-icon fa fa-calendar blue"></i>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <span class="input-icon" style={{ width: "100%" }}>
                                <DatePicker selectsEnd startDate={search.fromDate} endDate={search.toDate} className="form-control input-date-padding" selected={search.toDate} onChange={(date) => setSearch({ ...search, toDate: date })} />
                                <i class="ace-icon fa fa-calendar blue"></i>
                            </span>
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <select className="form-control" value={searchType} onChange={(e) => setSearchType(e.target.value)}>
                                <option value="all">All</option>
                                {
                                    GetEPaymentStatus.EPaymentStatus.map((data, index) =>
                                        <option value={data.Id}>{data.Name}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>

                    <div className="col-md-5">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <span class="input-icon" style={{ width: "100%" }}>
                                <TextBoxTranslater style={{paddingLeft: "24px"}} value={search.searchTerm} onChange={(e) => setSearch({ ...search, searchTerm: e.target.value })} className="form-control" type="text" id="form-field-icon-1" placeholder="Search eg. id, contact etc" />
                                <i class="ace-icon fa fa-search blue"></i>
                            </span>
                        </div>
                    </div>

                    <div className="col-md-1">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <button onClick={onSearch} to="/advanceVoucher/add" class="btn btn-sm btn-info pull-right">
                                <i class="ace-icon fa fa-search bigger-110"></i>
                                Run
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Paging
                pagingData={pagingData}
                LastPage={LastPage}
                CurrentPage={search.pageNo}
                OnPageChange={OnPageChange} >
                {isLoadingMini ?
                    <Spinner />
                    :
                    productionVoucher && productionVoucher.length > 0 ?
                        <div className="tables-scroll">
                            <table className="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th style={{ width: "43px" }} ></th>
                                        <th style={{ width: "20px" }}>Voucher Id</th>
                                        <th>Voucher Date</th>
                                        <th>Amount</th>
                                        <th>Payment Status</th>
                                        <th style={{ width: "72px" }}>By</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {productionVoucher.map((item, index) =>
                                        <tr key={item.id}>
                                            <td>
                                                <div class="btn-group">
                                                    <Link className="btn btn-xs btn-info" to={"/productionvoucher/dashboard/" + item.id} title="View dashboard" ><i class="ace-icon fa fa-tachometer"></i></Link>
                                                </div>
                                            </td>
                                            <td>{item.id}</td>
                                            <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.voucherDate}</Moment></div></td>
                                            <td><b>₹ {Number(item.totalAmount).toFixed(2)}</b></td>
                                            <td><StatusView status={item.paymentStatus}>{GetEPaymentStatusName(item.paymentStatus)}</StatusView></td>
                                            <td>
                                                <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        :
                        <EmptyData>
                            No records found.
                            <br />
                            <br />
                        </EmptyData>
                }
            </Paging>
        </Container >
    )
}

