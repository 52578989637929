import React from 'react'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import { GetEBoolName } from '../../../enumCollections/EBool'
import { ContactView } from '../../Global/Elements/ContactView'
import { ProcessingView } from '../../Global/Elements/ProcessingView'
import { ProductionUnitView } from '../../Global/Elements/ProductionUnitView'
import { ProductView } from '../../Global/Elements/ProductView'
import { UserView } from '../../Global/Elements/UserView';
import { GetEStockTypeName } from '../../../enumCollections/EStockType';

export function ProductDistributionItemView({ productDistribution }) {

    return (
        <>
            <div className="widget-box">
                <div class="widget-header widget-header-flat widget-header-small">
                    <h5 class="widget-title">
                        Details
                    </h5>
                </div>
                {
                    productDistribution &&
                    <div className="row">
                        <div class="profile-user-info profile-user-info-striped">
                            <div class="profile-info-row">
                                <div class="profile-info-name"> Distribution Id</div>

                                <div class="profile-info-value">
                                    <span ><b>{productDistribution.id}</b></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> On Date</div>

                                <div class="profile-info-value">
                                    <span ><b><Moment format="DD-MMM-YYYY">{productDistribution.onDate}</Moment></b></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Contact </div>

                                <div class="profile-info-value">
                                    <Link to={"/contact/dashboard/" + productDistribution.contactId}>
                                        <ContactView item={productDistribution.contact} uid={"GC1"} />
                                    </Link>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Production </div>

                                <div class="profile-info-value">
                                    <Link to={"/productionunit/dashboard/" + productDistribution.productionUnitId}>
                                        <ProductionUnitView item={productDistribution.productionUnit} />
                                    </Link>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Product </div>

                                <div class="profile-info-value">
                                    <Link to={"/product/dashboard/" + productDistribution.product.id}>
                                        <ProductView item={productDistribution.product} />
                                    </Link>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name">Given Product </div>

                                <div class="profile-info-value">
                                    <Link to={"/product/dashboard/" + productDistribution.givenProductId}>
                                        <ProductView item={productDistribution.givenProduct} uid={"PP"} />
                                    </Link>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Quantity </div>

                                <div class="profile-info-value">
                                    <span ><b>{productDistribution.quantity} {productDistribution.unit}</b></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Order </div>

                                <div class="profile-info-value">
                                    {productDistribution.isOrder == 1 ? (productDistribution.orderId ? <Link to={"/order/dashboard/" + productDistribution.orderId}>
                                        {productDistribution.orderId}
                                    </Link> : "NA") : <span class="label label-sm label-danger">{GetEBoolName(productDistribution.isOrder)}</span>}
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Note </div>

                                <div class="profile-info-value">
                                    <span >{productDistribution.note}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Created By </div>
                                <div class="profile-info-value">
                                    <span><UserView item={productDistribution.createdByUser} uid={"GD1"} createdOn={productDistribution.createdOn} /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

