const GetEAccountTypeName = (id) => {
    switch (id) {
        case 1: { return "Saving" }
        case 2: { return "Current" }
    }
}

const GetEAccountType = {
    EAccountType: [
        { Id: 1, Name: "Saving" },
        { Id: 2, Name: "Current" },
    ]
}

export { GetEAccountTypeName, GetEAccountType }