import React, { useContext, useState } from 'react'
import AppToaster from '../../commonLib/AppToaster';
import *as productCategoryCrud from '../../_crud/productCategoryCrud'
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { Container } from '../../base/Container';
import { LoadingButton } from '../../commonLib/LoadingButton';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

const initialValues = {
    CatName: "",
    DefaultWage: 0,
    Note: ""
}

export function AddProductCategory() {
    const history = useHistory()
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)

    const Schema = Yup.object().shape({
        CatName: Yup.string().min(3, "Category Name must between 3 to 50 characters").max(50, "Category Name must between 3 to 50 characters").required("Category Name is required."),
        DefaultWage: Yup.string().required("Default Wage is required.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });
    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        productCategoryCrud.Add(value)
            .then(res => {
                setIsLoadingMini(false)
                setIsLoadingLocal(false);
                AppToaster('Added successfully.', 'success')
                history.push('/product/categories')

            }).catch(error => {
                setIsLoadingMini(false)
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    return (
        <>
            <Container title="Add Product Category" isBack="true">
                <form onSubmit={formik.handleSubmit} class="form-horizontal">
                    <div className="row">
                        <div className="col-md-6">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Category</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Category Name" name="CatName" value={formik.values.CatName} onChange={(e) => formik.setValues({ ...formik.values, CatName: e.target.value })} />
                                    {
                                        formik.touched.CatName &&
                                            formik.errors.CatName ? (
                                            <label className="text-danger" >
                                                {formik.errors.CatName}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Default Wage ₹</label>
                                    <input className="form-control" type="number" placeholder="Default Wage" name="DefaultWage" {...formik.getFieldProps("DefaultWage")} />
                                    {
                                        formik.touched.DefaultWage &&
                                            formik.errors.DefaultWage ? (
                                            <label className="text-danger" >
                                                {formik.errors.DefaultWage}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-12">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right"> Note</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Note" name="Note" value={formik.values.Note} onChange={(e) => formik.setValues({ ...formik.values, Note: e.target.value })} />
                                    {
                                        formik.touched.Note &&
                                            formik.errors.Note ? (
                                            <label className="text-danger" >
                                                {formik.errors.Note}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="clearfix form-actions">
                        <div class="pull-right">
                            {isLoadingLocal ?
                                <LoadingButton />
                                :
                                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Add
                                </button>}
                        </div>
                    </div>
                </form>
            </Container >
        </>
    )
}
