const GetELedgerName = (id) => {
    switch (id) {
        case 2: { return "Salary Expense" }
        case 3: { return "Payable Libility" }
        case 4: { return "Receivable Asset" }
        case 5: { return "Claim Libility" }
        case 6: { return "Cash Asset" }
        case 7: { return "Ride Off Expense" }
        case 8: { return "Production Wage" }
        case 42: { return "Processing Wage" }
        case 9: { return "Advance Asset" }
        case 10: { return "Others Income" }
        case 11: { return "Others Expense" }
        case 12: { return "TDS Libility" }
        case 13: { return "TDS Expense" }
        case 14: { return "TDS Asset" }
        case 15: { return "GST Libility" }
        case 16: { return "Other Charges" }
        case 17: { return "Opening Balance" }
        case 18: { return "Round Off Expense" }
        case 19: { return "GST Sales" }
        case 20: { return "SGST Sales" }
        case 21: { return "CGST Sales" }
        case 22: { return "IGST Sales" }
        case 23: { return "GST Purchase" }
        case 24: { return "SGST Purchase" }
        case 25: { return "CGST Purchase" }
        case 26: { return "IGST Purchase" }
        case 27: { return "Bank Charges" }
        case 30: { return "Other Asset" }
        case 31: { return "Asset Sale" }
        case 34: { return "Lending Asset" }
        case 37: { return "TDS Income" }
        case 38: { return "Asset Purchase" }
        case 39: { return "Other Libility" }
        case 40: { return "Round Off Income" }
        case 41: { return "Ride Off Income" }
        case 43: { return "Advance Expense" }
        case 44: { return "Bank" }
        case 45: { return "Penalty & Defect" }
    }
}

const GetELedger = {
    EStatus: [
        { Id: 2, Name: "Salary Expense" },
        { Id: 3, Name: "Payable Libility" },
        { Id: 4, Name: "Receivable Asset" },
        { Id: 5, Name: "Claim Libility" },
        { Id: 6, Name: "Cash Asset" },
        { Id: 7, Name: "RideOff Expense" },
        { Id: 8, Name: "Production Wage" },
        { Id: 42, Name: "Processing Wage" },
        { Id: 9, Name: "Advance Asset" },
        { Id: 10, Name: "Others Income" },
        { Id: 11, Name: "Others Expense" },
        { Id:12, Name: "TDS Libility" },
        { Id: 13, Name: "TDS Expense" },
        { Id: 14, Name: "TDS Asset" },
        { Id: 15, Name: "GST Libility" },
        { Id: 16, Name: "Other Charges" },
        { Id: 17, Name: "Opening Balance" },
        { Id: 18, Name: "RoundOff Expense" },
        { Id: 19, Name: "GST Sales" },
        { Id: 20, Name: "SGST Sales" },
        { Id: 21, Name: "CGST Sales" },
        { Id: 22, Name: "IGST Sales" },
        { Id: 23, Name: "GST Purchase" },
        { Id: 24, Name: "SGST Purchase" },
        { Id: 25, Name: "CGST Purchase" },
        { Id: 26, Name: "IGST Purchase" },
        { Id: 27, Name: "Bank Charges" },
        { Id: 30, Name: "Other Asset" },
        { Id: 31, Name: "Asset Sale" },
        { Id: 34, Name: "Lending Asset" },
        { Id: 37, Name: "TDS Income" },
        { Id: 38, Name: "Asset Purchase" },
        { Id: 39, Name: "Other Libility" },
        { Id: 40, Name: "RoundOff Income" },
        { Id: 41, Name: "RideOff Income" },
        { Id: 43, Name: "Advance Expense" },
        { Id: 44, Name: "Bank" },
        { Id: 45, Name: "Penalty & Defect" }
    ]
}

export { GetELedgerName, GetELedger }