import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { PagingEmpty } from '../../commonLib/PagingEmpty';
import { Spinner } from '../../commonLib/Spinner';
import *as orderCrud from '../../_crud/orderCrud'
import 'moment-timezone';
import { EmptyData } from '../../commonLib/EmptyData';
import { MyAlert } from '../../commonLib/MyAlert';
import { dashboardContext } from '../Global/dashboardContext';
import { UserView } from '../Global/Elements/UserView';
import { StatusView } from '../../commonLib/StatusView';
import { ProductionUnitView } from '../Global/Elements/ProductionUnitView';
import { ContactView } from '../Global/Elements/ContactView';
import { ProductView } from '../Global/Elements/ProductView';
import { GetEBoolName } from '../../enumCollections/EBool';
import { GetEOrderStatusName } from '../../enumCollections/EOrderStatus';
import Moment from 'react-moment';
import { PrintStarterDash } from '../Prints/PrintStaterDash';
import { OrderProcessingMultiPrint } from '../Prints/OrderProcessingMultiPrint';
import { WarehouseView } from '../Global/Elements/WarehouseView';

export function OrderPrintProcessing() {
  const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
  const [isLoadingLocal, setIsLoadingLocal] = useState(false)
  const [orderId, setOrderId] = useState("")
  const [orderData, setOrderData] = useState([])
  const inputReference = useRef(null);

  const initialValues = {
    orderId: ""
  }
  const Schema = Yup.object().shape({
  })
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values) => {
      AddData();
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });

  function AddData() {
    setIsLoadingLocal(true);
    orderCrud.GetById(orderId)
      .then(res => {
        if (res.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            let pdata = res.data.product
            let cdata = res.data.config
            setOrderData([...orderData, { data: hdata, product: pdata, config: cdata }]);
            setOrderId("")
            inputReference.current.focus();
          }
          else {
            AppToaster("Server Error /Invalid Try.", 'error')
          }
        }
        else {
          AppToaster("Server Error /Invalid Try.", 'error')
        }
        setIsLoadingLocal(false);
      })
      .catch(error => {
        setIsLoadingLocal(false);
        AppToaster(error, 'error')
      })
  }
  const CrearData = () => {
    setOrderData([])
  }
  const OnOrderRemove = (id) => {
    let data = orderData
    data.forEach((e, index) => {
      if (e.data.id == id)
        data.splice(index, 1)
    })
    setOrderData([...data])
  }
  const printRef = useRef();
  return (
    <>
      <div style={{ display: "none" }}>
        <OrderProcessingMultiPrint printData={orderData} ref={printRef} />
      </div>
      <Container title="Order Processing Print" isBack="true">
        <form onSubmit={formik.handleSubmit} class="form-horizontal" >
          <div className="row">
            <div className="col-md-2">
              <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                <input ref={inputReference} value={orderId} onChange={(e) => setOrderId(e.target.value)} className="form-control" type="text" placeholder="Order id" />
              </div>
            </div>

            <div className="col-md-1">
              <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                {isLoadingLocal ?
                  <button disabled class="btn btn-sm btn-info">
                    <i class="ace-icon fa fa-search bigger-110"></i>
                    Adding...
                  </button>
                  :
                  <button type="submit" disabled={!orderId} class="btn btn-sm btn-info">
                    <i class="ace-icon fa fa-search bigger-110"></i>
                    Add
                  </button>
                }
              </div>
            </div>
            <div className="col-md-1">
              <button class="btn btn-sm btn-danger" onClick={CrearData}>
                <i class="ace-icon fa fa-search bigger-110"></i>
                Clear
              </button>
            </div>
            {orderData && orderData.length > 0 &&
              <div className="col-md-8">
                <div class="btn-group pull-right">
                  <PrintStarterDash printRef={printRef} />
                </div>
              </div>
            }

          </div>
        </form>



        <div class="hr dotted"></div>
        <PagingEmpty text={"Total " + orderData.length + " items."}>
          {isLoadingMini ?
            <Spinner />
            :
            orderData && orderData.length > 0 ?
              <div className="tables-scroll">
                <table className="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th></th>
                      <th style={{ width: "43px" }}></th>
                      <th>Branch</th>
                      <th>#Id</th>
                      <th>On Date</th>
                      <th>Due Date</th>
                      <th>Alloted To</th>
                      <th>To Production</th>
                      <th>Product</th>
                      <th>Configuration</th>
                      <th>Quantity</th>
                      <th>Alloted For</th>
                      <th>For Production</th>
                      <th>Status</th>
                      <th>Details</th>
                      <th>By</th>
                    </tr>
                  </thead>


                  <tbody>
                    {orderData.map((item, index) =>
                      <tr key={item.data.id}>
                        <td>
                          <div class="btn-group">
                            <Link className="btn btn-xs btn-info" to={"/order/dashboard/" + item.data.id} title="View dashboard" ><i class="ace-icon fa fa-tachometer"></i></Link>
                          </div>
                        </td>
                        <td>
                          <div class="btn-group">
                            <MyAlert confirmBtnText="Yes, delete it!" title="Delete form List" onConfirm={OnOrderRemove} param={item.data.id}>
                              <i class="ace-icon fa fa-trash-o bigger-120"></i>
                            </MyAlert>
                          </div>
                        </td>
                        <td><WarehouseView item={item.warehouse} uid={"WH" + index} /></td>
                        <td>{item.data.id}</td>
                        <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.data.onDate}</Moment></div></td>
                        <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.data.dueDate}</Moment></div></td>
                        <td>
                          <Link to={"/contact/dashboard/" + item.data.allotedToContactId}>
                            <ContactView item={item.data.toContact} uid={"C" + index} />
                          </Link>
                        </td>
                        <td><Link to={"/productionunit/dashboard/" + item.data.toProductionUnitId}>
                          <ProductionUnitView item={item.data.toProductionUnit} />
                        </Link></td>
                        <td><Link to={"/product/dashboard/" + item.data.product.id}>
                          <ProductView item={item.data.product} uid={"PP" + index} />
                        </Link></td>
                        <td>
                          {item.data.productionConfiguration ?
                            <Link to={"/productionunit/configuration/dashboard/" + item.data.productionConfiguration.id}>
                              {item.data.productionConfiguration.name}
                            </Link>
                            :
                            "NA"
                          }
                        </td>
                        <td>{item.data.quantity} {item.data.unit}</td>
                        <td>
                          {item.data.isForProductionUnit == 1 && item.data.contact ?
                            <Link to={"/contact/dashboard/" + item.data.allotedForContactId}>
                              <ContactView item={item.data.contact} uid={"CS" + index} />
                            </Link>
                            :
                            "NA"
                          }
                        </td>
                        <td>
                          {item.data.isForProductionUnit == 1 ? (item.data.forProductionUnitData ? <Link to={"/productionunit/dashboard/" + item.data.forProductionUnitData.id}>
                            <ProductionUnitView item={item.data.forProductionUnitData} />
                          </Link> : "NA") : <span class="label label-sm label-danger">{GetEBoolName(item.data.isForProductionUnit)}</span>}
                        </td>
                        <td><StatusView status={item.data.status}>{GetEOrderStatusName(item.data.status)}</StatusView></td>
                        <td><div style={{ width: "150px" }}>{item.data.details}</div></td>
                        <td>
                          <UserView item={item.data.createdByUser} uid={"D" + index} createdOn={item.data.createdOn} />
                        </td>

                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              :
              <EmptyData>
                No records found.
                <br />
                <br />
              </EmptyData>
          }
        </PagingEmpty>
      </Container >
    </>
  )
}
