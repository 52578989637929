import React from 'react';

export function Footer() {
    return (
        <>
            <div class="row">
                <div class="col-xs-12" style={{ textAlign: "center" }}>
                    <div class="footer-inner">
                        <div class="footer-content">
                            <p>
                                <a href="https://baithka.com" target="_blank">
                                    <img style={{ marginBottom: "3px" }} height="32px" src="images/Baithka-64.gif" />
                                </a>
                                <br/>
                                <span class="blue" style={{ verticalAlign: "bottom" }}><a href="https://baithka.com/about" target="_blank">About</a> | <a href="https://baithka.com/term" target="_blank">Term & Conditions</a></span>

                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}