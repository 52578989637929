import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from "yup";
import { Container } from '../../../base/Container';
import AppToaster from '../../../commonLib/AppToaster';
import * as productProcessingAllotmentCrud from '../../../_crud/productProcessingAllotmentCrud';
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import * as processingCrud from '../../../_crud/processingCrud'
import { LoadingButton } from '../../../commonLib/LoadingButton';


export function AddProductProcessingAllotment() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const history = useHistory()
    const [processing, setProcessing] = useState([])
    const { id } = useParams()
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)

    const Schema = Yup.object().shape({
        processingId: Yup.string().required("Processing is required."),
        productId: Yup.string().required("Product is required."),
    })
    const initialValues = {
        processingId: "",
        productId: id,
    }

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    useEffect(() => {
        getProcessing()
    }, []);

    function getProcessing() {
        setIsLoadingMini(true);
        processingCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProcessing(hdata);
                    }
                    else {
                        setProcessing([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProcessing([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        productProcessingAllotmentCrud.Add(value)
            .then(res => {
                if (res.data.result.succeeded) {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster('Added successfully.', 'success')
                    history.goBack()
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    return (
        <Container title="Add Product Processing Allotment" isBack="true" isProduct="true">
            <form onSubmit={formik.handleSubmit} class="form-horizontal">
                <div className="row">
                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                            <label class="control-label no-padding-right">Processing</label>
                                <select className="form-control" name="processingId" value={formik.values.processingId} onChange={(e) => formik.setValues({ ...formik.values, processingId: e.target.value })}>
                                    <option value="" >Select...</option>
                                    {
                                        processing.map((data, index) =>
                                            <option key={data.id} value={data.id}>{data.name.toUpperCase()} ({data.id})</option>
                                        )
                                    }
                                </select>
                                {
                                    formik.touched.processingId &&
                                        formik.errors.processingId ? (
                                        <label className="text-danger" >
                                            {formik.errors.processingId}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                </div>

                <div class="clearfix form-actions">
                    <div class="pull-right">
                        {isLoadingLocal ?
                            <LoadingButton />
                            :
                            <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                <i class="ace-icon fa fa-check bigger-110"></i>
                                Add
                            </button>}
                    </div>
                </div>
            </form>
        </Container >
    )
}
