import { useFormik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import * as Yup from "yup";
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';
import { FileUploader } from '../../commonLib/FileUploader';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { organizationContext } from '../Organization/organizationContext';
import { PrintStarterDash } from '../Prints/PrintStaterDash';
import { DrapingPrint } from '../Prints/DrapingPrint';
import { DrapingView } from './DrapingView';
import *as sareeDrapingCrud from './../../_crud/sareeDrapingCrud'
import { DrapingBalance } from './DrapingBalance';
import * as organizationCrud from './../../_crud/organizationCrud'
import { getIsPrint } from '../../config/GlobalSettings';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { Saree1Pose } from './Poses/Saree1Pose';
import { Saree2Pose } from './Poses/Saree2Pose';
import { Saree3Pose } from './Poses/Saree3Pose';
import { Saree4Pose } from './Poses/Saree4Pose';
import { Saree5Pose } from './Poses/Saree5Pose';
import { Saree6Pose } from './Poses/Saree6Pose';
import { resizeImage, vibrance } from './Poses/ImageEditFunction';
import { LazyLoadFull } from '../../commonLib/LazyLoadFull';
import { GetPoseBg } from './Poses/PoseBg';

export function Drapingo() {
    const [organization, setOrganization] = useContext(organizationContext);
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)

    const Schema = Yup.object().shape({
        itemName: Yup.string().min(3, "Item Name must between 3 to 50 characters").max(50, "Item Name must between 3 to 50 characters").required("Item Name is required."),
        itemCode: Yup.string().min(1, "Item Code must between 1 to 15 characters").max(15, "Item Code must between 1 to 15 characters").required("Item Code is required."),
    })

    const initialValues = {
        itemName: "",
        itemCode: "",
        note: "",
        vibrance: 0.1,
        bg: 1,
    }

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            StartDrap()
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    /////////////Draping////////////
    const [localLoading, setLocalLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [isDraped, setIsDraped] = useState(false);

    const [img1, setImg1] = useState("");
    const [img2, setImg2] = useState("");

    const [bg, setBg] = useState("1");
    const [vib, setVib] = useState("0");

    const [img1Drap, setImg1Drap] = useState("");
    const [img2Drap, setImg2Drap] = useState("");

    //none, init, Processing, Merging Images, completed, failed
    const [isreadySaree1, setIsreadySaree1] = useState("none");
    const [isreadySaree2, setIsreadySaree2] = useState("none");
    const [isreadySaree3, setIsreadySaree3] = useState("none");
    const [isreadySaree4, setIsreadySaree4] = useState("none");
    const [isreadySaree5, setIsreadySaree5] = useState("none");
    const [isreadySaree6, setIsreadySaree6] = useState("none");

    const [saree1Draper, setSaree1Draper] = useState();
    const [saree2Draper, setSaree2Draper] = useState();
    const [saree3Draper, setSaree3Draper] = useState();
    const [saree4Draper, setSaree4Draper] = useState();
    const [saree5Draper, setSaree5Draper] = useState();
    const [saree6Draper, setSaree6Draper] = useState();

    const StartDrap = () => {
        setLocalLoading(true)
        SetDrapingStatus("Getting Ready")
        vibrance(img1, formik.values.vibrance).then(vimg1 => {
            vibrance(img2, formik.values.vibrance).then(vimg2 => {
                if (vimg1 && vimg2) {
                    setLocalLoading(false)
                    VerifyBalance(vimg1, vimg2)
                }
                else {
                    setLocalLoading(false)
                    SetDrapingStatus("failed")
                    alert("Please Select both images.")
                }
            }).catch(err => {
                setLocalLoading(false)
                SetDrapingStatus("failed")
                alert(err)
            })
        }).catch(err => {
            setLocalLoading(false)
            SetDrapingStatus("failed")
            alert(err)
        })
    }
    const StartDrapOnly = () => {
        setLocalLoading(true)
        SetDrapingStatus("Getting Ready")
        vibrance(img1, formik.values.vibrance).then(vimg1 => {
            vibrance(img2, formik.values.vibrance).then(vimg2 => {
                if (vimg1 && vimg2) {
                    setLocalLoading(false)
                    setImg1Drap(vimg1)
                    setImg2Drap(vimg2)
                    setIsreadySaree1("init")
                    //SetDrapingStatus("init")
                }
                else {
                    setLocalLoading(false)
                    SetDrapingStatus("failed")
                    alert("Please Select both images.")
                }
            }).catch(err => {
                setLocalLoading(false)
                SetDrapingStatus("failed")
                alert(err)
            })
        }).catch(err => {
            setLocalLoading(false)
            SetDrapingStatus("failed")
            alert(err)
        })
    }
    const VerifyBalance = (pramimg1, paramimg2) => {
        setLocalLoading(true)
        organizationCrud.GetOrganization()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        if (hdata.drapingBalance < 1) {
                            AppToaster("Your draping balance is insufficient, please recharge and try again.", "error")
                            setLocalLoading(false)
                        }
                        else {
                            setLocalLoading(false)
                            DrapeSuccess(pramimg1, paramimg2)
                        }
                    }
                    else {
                        setLocalLoading(false)
                    }
                }
                else {
                    setLocalLoading(false)
                }
            })
            .catch(error => {
                setLocalLoading(false)
            })
    }
    const DrapeSuccess = (pramimg1, paramimg2) => {
        setLocalLoading(true)
        sareeDrapingCrud.DrapingSuccess()
            .then(res => {
                if (res.data.succeeded) {
                    setLocalLoading(false);
                    setIsDraped(true)
                    setImg1Drap(pramimg1)
                    setImg2Drap(paramimg2)
                    setIsreadySaree1("init")
                    //SetDrapingStatus("init")
                    AppToaster('Draping initiated, Please wait until drap.')
                }
                else {
                    setIsDraped(false)
                    setLocalLoading(false)
                    AppToaster(res.data.message, 'error')
                    alert(res.data.result.message)
                }

            }).catch(error => {
                setLocalLoading(false);
                setIsDraped(false)
                alert(error)
                AppToaster(error, 'error')
            })
    }

    const SetDrapingStatus = (status) => {
        //For All Drapings
        setIsreadySaree1(status)
        setIsreadySaree2(status)
        setIsreadySaree3(status)
        setIsreadySaree4(status)
        setIsreadySaree5(status)
        setIsreadySaree6(status)
    }
    useEffect(() => {
        if (isreadySaree1 == "completed" && isreadySaree2 == "Getting Ready") {
            setIsreadySaree2("init")
        }
        if (isreadySaree2 == "completed" && isreadySaree3 == "Getting Ready") {
            setIsreadySaree3("init")
        }
        if (isreadySaree3 == "completed" && isreadySaree4 == "Getting Ready") {
            setIsreadySaree4("init")
        }
        if (isreadySaree4 == "completed" && isreadySaree5 == "Getting Ready") {
            setIsreadySaree5("init")
        }
        if (isreadySaree5 == "completed" && isreadySaree6 == "Getting Ready") {
            setIsreadySaree6("init")
        }
    }, [isreadySaree1, isreadySaree2, isreadySaree3,
        isreadySaree4, isreadySaree5, isreadySaree6])

    function urltoFile(url, filename, mimeType) {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const DrapeUpload = () => {
        if (isDraped && saree2Draper && saree3Draper) {
            setLocalLoading(true)
            urltoFile(saree2Draper, "DrapedFront.png", "image/png").then(res1 => {
                urltoFile(saree3Draper, "DrapedBack.png", "image/png").then(res2 => {
                    resizeImage(saree2Draper, 200, 300).then(samll1 => {
                        resizeImage(saree3Draper, 200, 335).then(samll2 => {
                            urltoFile(samll1, "DrapedFrontSamll.png", "image/png").then(resSmall1 => {
                                urltoFile(samll2, "DrapedBackSamll.png", "image/png").then(resSmall2 => {
                                    setLocalLoading(false);
                                    DrapeSave(res1, res2, resSmall1, resSmall2)
                                })
                            })
                        }).catch(err => {
                            setLocalLoading(false);
                        })
                    }).catch(err => {
                        setLocalLoading(false);
                    })
                })
            })
        }
        else {
            AppToaster("Draping is incomplete.", 'error')
        }
    }

    const DrapeSave = (fileFront, fileBack, fileFrontSmall, fileBackSmall) => {
        setLocalLoading(true)
        const formData = new FormData()
        formData.append('itemName', formik.values.itemName)
        formData.append('itemCode', formik.values.itemCode)
        formData.append('note', formik.values.note)

        if (fileFront)
            formData.append('drapingFront', fileFront)
        if (fileBack)
            formData.append('drapingBack', fileBack)
        if (fileFrontSmall)
            formData.append('drapingFrontSmall', fileFrontSmall)
        if (fileBackSmall)
            formData.append('drapingBackSmall', fileBackSmall)

        sareeDrapingCrud.Add(formData)
            .then(res => {
                if (res.data.result.succeeded) {
                    setLocalLoading(false);
                    setIsSaved(true)
                    AppToaster('Uploaded and Added successfully.', 'success')
                }
                else {
                    setIsSaved(false)
                    AppToaster(res.data.result.message, 'error')
                    alert(res.data.result.message)
                }

            }).catch(error => {
                setIsSaved(false)
                setLocalLoading(false);
                alert(error)
                AppToaster(error, 'error')
            })
    }

    const DownloadZip = (saree1Draper, saree2Draper, saree3Draper, saree4Draper, saree5Draper, saree6Draper) => {
        setIsLoadingMini(true)
        var zip = new JSZip();
        zip.file("Drapingo_Saree1.png", saree1Draper.replace("data:image/png;base64,", ""), { base64: true });
        zip.file("Drapingo_Saree2.png", saree2Draper.replace("data:image/png;base64,", ""), { base64: true });
        zip.file("Drapingo_Saree3.png", saree3Draper.replace("data:image/png;base64,", ""), { base64: true });
        zip.file("Drapingo_Saree4.png", saree4Draper.replace("data:image/png;base64,", ""), { base64: true });
        zip.file("Drapingo_Saree5.png", saree5Draper.replace("data:image/png;base64,", ""), { base64: true });
        zip.file("Drapingo_Saree6.png", saree6Draper.replace("data:image/png;base64,", ""), { base64: true });

        zip.generateAsync({ type: "blob" }).then(content => {
            // see FileSaver.js
            saveAs(content, "Baithka Drapingo Draper.zip");
            setIsLoadingMini(false)
        }).catch(error => {
            setIsLoadingMini(false)
            alert(error)
        });
    }

    const printRef = useRef();
    return (
        <Container title="Drap Saree" isBack={true}>
            {isDraped ?
                ""
                :
                <>
                    <DrapingBalance />
                    <form onSubmit={formik.handleSubmit} class="form-horizontal">
                        <div className="row">

                            <div className="col-md-4">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <label class="control-label no-padding-right"><b className='red'>*</b>Item Name</label>
                                        <TextBoxTranslater className="form-control" type="text" placeholder="Item Name" name="itemName" value={formik.values.itemName} onChange={(e) => formik.setValues({ ...formik.values, itemName: e.target.value })} />
                                        {
                                            formik.touched.itemName &&
                                                formik.errors.itemName ? (
                                                <label className="text-danger" >
                                                    {formik.errors.itemName}
                                                </label>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <label class="control-label no-padding-right"><b className='red'>*</b>Item Code</label>
                                        <TextBoxTranslater className="form-control" type="text" placeholder="Item Code" name="itemCode" value={formik.values.itemCode} onChange={(e) => formik.setValues({ ...formik.values, itemCode: e.target.value })} />
                                        {
                                            formik.touched.itemCode &&
                                                formik.errors.itemCode ? (
                                                <label className="text-danger" >
                                                    {formik.errors.itemCode}
                                                </label>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <label class="control-label no-padding-right">Description</label>
                                        <TextBoxTranslater className="form-control" type="text" placeholder="Description" name="note" value={formik.values.note} onChange={(e) => formik.setValues({ ...formik.values, note: e.target.value })} />
                                        {
                                            formik.touched.note &&
                                                formik.errors.note ? (
                                                <label className="text-danger" >
                                                    {formik.errors.note}
                                                </label>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <label class="control-label no-padding-right">Vibrance</label>
                                        <select className="form-control" value={formik.values.vibrance} onChange={(e) => formik.setValues({ ...formik.values, vibrance: e.target.value })}>
                                            <option value="0">Orignial</option>
                                            <option value="0.1">Level 1</option>
                                            <option value="0.2">Level 2</option>
                                            <option value="0.3">Level 3</option>
                                            <option value="0.4">Level 4</option>
                                            <option value="0.5">Level 5</option>
                                            <option value="0.6">Level 6</option>
                                            <option value="0.7">Level 7</option>
                                            <option value="0.8">Level 8</option>
                                            <option value="0.9">Level 9</option>
                                            <option value="1">Level 10</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <label class="control-label no-padding-right"><b className='red'>*</b>Select background</label><br />
                                <label>
                                    <input type="radio" name="bg" value="1" checked={formik.values.bg == 1 ? true : false} onChange={() => formik.setValues({ ...formik.values, bg: 1 })} />
                                    <img width="50px" src="./assets/images/Draper/new/bgwhitexs.png" alt="White" />
                                </label>
                                <label>
                                    <input type="radio" name="bg" value="8" checked={formik.values.bg == 8 ? true : false} onChange={() => formik.setValues({ ...formik.values, bg: 8 })} />
                                    <img width="50px" src="./assets/images/Draper/new/bgtransparentxs.png" alt="Transparent" />
                                </label>
                                <label>
                                    <input type="radio" name="bg" value="2" checked={formik.values.bg == 2 ? true : false} onChange={() => formik.setValues({ ...formik.values, bg: 2 })} />
                                    <img width="50px" src="./assets/images/Draper/new/bggrayxs.png" alt="Transparent" />
                                </label>
                                <label>
                                    <input type="radio" name="bg" value="3" checked={formik.values.bg == 3 ? true : false} onChange={() => formik.setValues({ ...formik.values, bg: 3 })} />
                                    <img width="50px" src="./assets/images/Draper/new/bgbluexs.png" alt="Transparent" />
                                </label>
                                <label>
                                    <input type="radio" name="bg" value="4" checked={formik.values.bg == 4 ? true : false} onChange={() => formik.setValues({ ...formik.values, bg: 4 })} />
                                    <img width="50px" src="./assets/images/Draper/new/bgmaroonxs.png" alt="Transparent" />
                                </label>
                                <label>
                                    <input type="radio" name="bg" value="5" checked={formik.values.bg == 5 ? true : false} onChange={() => formik.setValues({ ...formik.values, bg: 5 })} />
                                    <img width="50px" src="./assets/images/Draper/new/bgrainbowxs.png" alt="Transparent" />
                                </label>
                                <label>
                                    <input type="radio" name="bg" value="6" checked={formik.values.bg == 6 ? true : false} onChange={() => formik.setValues({ ...formik.values, bg: 6 })} />
                                    <img width="50px" src="./assets/images/Draper/new/bgrainbowxxs.png" alt="Transparent" />
                                </label>
                                <label>
                                    <input type="radio" name="bg" value="7" checked={formik.values.bg == 7 ? true : false} onChange={() => formik.setValues({ ...formik.values, bg: 7 })} />
                                    <img width="50px" src="./assets/images/Draper/new/bgabstractxs.png" alt="Transparent" />
                                </label>
                                <label>
                                    <input type="radio" name="bg" value="9" checked={formik.values.bg == 9 ? true : false} onChange={() => formik.setValues({ ...formik.values, bg: 9 })} />
                                    <img width="50px" src="./assets/images/Draper/new/bgmatteblackxs.png" alt="Transparent" />
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <label class="control-label no-padding-right"><b className='red'>*</b>Saree with Pallu</label>
                                        <FileUploader setFile={setImg1} isBase64={true} width={2800} height={1600} />
                                        <span>
                                            for perfect result resolution should width:2800px | height:1600px
                                        </span>
                                        <span>
                                            <Zoom>
                                                <img style={{ height: "200px" }} src={img1 ? img1 : "./assets/images/Draper/new/d7.jpg"} className="img-responsive" />
                                            </Zoom>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <label class="control-label no-padding-right"><b className='red'>*</b>Saree Blouse</label>
                                        <FileUploader setFile={setImg2} isBase64={true} width={1600} height={1600} />
                                        <span>
                                            for perfect result resolution should width:1600px | height:1600px
                                        </span>
                                        <Zoom>
                                            <img style={{ height: "200px" }} src={img2 ? img2 : "./assets/images/Draper/new/d8.jpg"} className="img-responsive" />
                                        </Zoom>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="clearfix form-actions">
                            <div class="pull-right">
                                {localLoading ?
                                    <>
                                        <span>Draping will take 2 to 4 minutes.</span><br />
                                        <button class="btn btn-info" expand="block" disabled>
                                            <i class="ace-icon fa fa-check bigger-110"></i>
                                            Processing...
                                        </button>
                                    </>

                                    :
                                    <>
                                        <span>Draping will take 2 to 4 minutes.</span><br />
                                        <button class="btn btn-info" disabled={localLoading || !formik.isValid || !img1 || !img2}>
                                            <i class="ace-icon fa fa-check bigger-110"></i>
                                            Drap Now
                                        </button>
                                    </>
                                }
                            </div>
                        </div>

                    </form>
                </>
            }

            {!localLoading && isDraped ?
                <>
                    <div className="row">
                        {getIsPrint && <div style={{ display: "none" }}>
                            <DrapingPrint FinalDraper={saree2Draper} FinalDraperBack={saree3Draper} itemName={formik.values.itemName} itemCode={formik.values.itemCode} organization={organization} ref={printRef} />
                        </div>}
                        <div class="btn-group">
                            {(isDraped) &&
                                isLoadingMini ?
                                <button style={{ marginRight: "10px" }} class="btn btn-xs bigger-120 btn-info" disabled>
                                    <i class="ace-icon fa fa-upload"></i> Uploading to server...
                                </button>
                                :
                                !isSaved &&
                                <button style={{ marginRight: "10px" }} class="btn btn-xs bigger-120 btn-info" onClick={DrapeUpload}>
                                    <i class="ace-icon fa fa-upload"></i> Upload & Save Draping
                                </button>
                            }

                            {isLoadingMini ?
                                <button style={{ marginRight: "10px" }} class="btn btn-xs bigger-120 btn-success" disabled>
                                    <i class="ace-icon fa fa-download"></i> Downloading...
                                </button>
                                :
                                <button style={{ marginRight: "10px" }} class="btn btn-xs bigger-120 btn-success" onClick={() => { DownloadZip(saree1Draper, saree2Draper, saree3Draper, saree4Draper, saree5Draper, saree6Draper) }}>
                                    <i class="ace-icon fa fa-download"></i> Download Draping
                                </button>
                            }

                            {getIsPrint && <PrintStarterDash printRef={printRef} />}
                        </div>
                        <hr />
                        {img1Drap && img2Drap &&
                            <>
                                <Saree1Pose img1={img1Drap} img2={img2Drap} bg={formik.values.bg} setIsCompleted={setIsreadySaree1} status={isreadySaree1} setDrapResult={setSaree1Draper} />
                                <Saree2Pose img1={img1Drap} img2={img2Drap} bg={formik.values.bg} setIsCompleted={setIsreadySaree2} status={isreadySaree2} setDrapResult={setSaree2Draper} />
                                <Saree3Pose img1={img1Drap} img2={img2Drap} bg={formik.values.bg} setIsCompleted={setIsreadySaree3} status={isreadySaree3} setDrapResult={setSaree3Draper} />
                                <Saree4Pose img1={img1Drap} img2={img2Drap} bg={formik.values.bg} setIsCompleted={setIsreadySaree4} status={isreadySaree4} setDrapResult={setSaree4Draper} />
                                <Saree5Pose img1={img1Drap} img2={img2Drap} bg={formik.values.bg} setIsCompleted={setIsreadySaree5} status={isreadySaree5} setDrapResult={setSaree5Draper} />
                                <Saree6Pose img1={img1Drap} img2={img2Drap} bg={formik.values.bg} setIsCompleted={setIsreadySaree6} status={isreadySaree6} setDrapResult={setSaree6Draper} />
                            </>
                        }
                        <br />
                        <div className="row">
                            <div className="col-md-2">
                                {isreadySaree1 != "none" && isreadySaree1 != "completed" ?
                                    <LazyLoadFull text={isreadySaree1 == "init" ? "LOADING" : isreadySaree1.toUpperCase()} />
                                    :
                                    (isreadySaree1 == "completed" && saree1Draper) ?
                                        <Zoom>
                                            <img width="100%" src={saree1Draper} alt="Draped" />
                                        </Zoom>
                                        : "Faild to Drap Please load again."
                                }
                            </div>
                            <div className="col-md-2">
                                {isreadySaree2 != "none" && isreadySaree2 != "completed" ?
                                    <LazyLoadFull text={isreadySaree2 == "init" ? "LOADING" : isreadySaree2.toUpperCase()} />
                                    :
                                    (isreadySaree2 == "completed" && saree2Draper) ?
                                        <Zoom>
                                            <img width="100%" src={saree2Draper} alt="Draped" />
                                        </Zoom>
                                        : "Faild to Drap Please load again."
                                }
                            </div>
                            <div className="col-md-2">
                                {isreadySaree3 != "none" && isreadySaree3 != "completed" ?
                                    <LazyLoadFull text={isreadySaree3 == "init" ? "LOADING" : isreadySaree3.toUpperCase()} />
                                    :
                                    (isreadySaree3 == "completed" && saree3Draper) ?
                                        <Zoom>
                                            <img width="100%" src={saree3Draper} alt="Draped" />
                                        </Zoom>
                                        : "Faild to Drap Please load again."
                                }
                            </div>
                            <div className="col-md-2">
                                {isreadySaree4 != "none" && isreadySaree4 != "completed" ?
                                    <LazyLoadFull text={isreadySaree4 == "init" ? "LOADING" : isreadySaree4.toUpperCase()} />
                                    :
                                    (isreadySaree4 == "completed" && saree4Draper) ?
                                        <Zoom>
                                            <img width="100%" src={saree4Draper} alt="Draped" />
                                        </Zoom>
                                        : "Faild to Drap Please load again."
                                }
                            </div>
                            <div className="col-md-2">
                                {isreadySaree5 != "none" && isreadySaree5 != "completed" ?
                                    <LazyLoadFull text={isreadySaree5 == "init" ? "LOADING" : isreadySaree5.toUpperCase()} />
                                    :
                                    (isreadySaree5 == "completed" && saree5Draper) ?
                                        <Zoom>
                                            <img width="100%" src={saree5Draper} alt="Draped" />
                                        </Zoom>
                                        : "Faild to Drap Please load again."
                                }
                            </div>
                            <div className="col-md-2">
                                {isreadySaree6 != "none" && isreadySaree6 != "completed" ?
                                    <LazyLoadFull text={isreadySaree6 == "init" ? "LOADING" : isreadySaree6.toUpperCase()} />
                                    :
                                    (isreadySaree6 == "completed" && saree6Draper) ?
                                        <Zoom>
                                            <img width="100%" src={saree6Draper} alt="Draped" />
                                        </Zoom>
                                        : "Faild to Drap Please load again."
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <label class="control-label no-padding-right"><b className='red'>*</b>Select background</label><br />
                                <label>
                                    <input type="radio" name="bg" value="1" checked={formik.values.bg == 1 ? true : false} onChange={() => formik.setValues({ ...formik.values, bg: 1 })} />
                                    <img width="50px" src="./assets/images/Draper/new/bgwhitexs.png" alt="White" />
                                </label>
                                <label>
                                    <input type="radio" name="bg" value="8" checked={formik.values.bg == 8 ? true : false} onChange={() => formik.setValues({ ...formik.values, bg: 8 })} />
                                    <img width="50px" src="./assets/images/Draper/new/bgtransparentxs.png" alt="Transparent" />
                                </label>
                                <label>
                                    <input type="radio" name="bg" value="2" checked={formik.values.bg == 2 ? true : false} onChange={() => formik.setValues({ ...formik.values, bg: 2 })} />
                                    <img width="50px" src="./assets/images/Draper/new/bggrayxs.png" alt="Transparent" />
                                </label>
                                <label>
                                    <input type="radio" name="bg" value="3" checked={formik.values.bg == 3 ? true : false} onChange={() => formik.setValues({ ...formik.values, bg: 3 })} />
                                    <img width="50px" src="./assets/images/Draper/new/bgbluexs.png" alt="Transparent" />
                                </label>
                                <label>
                                    <input type="radio" name="bg" value="4" checked={formik.values.bg == 4 ? true : false} onChange={() => formik.setValues({ ...formik.values, bg: 4 })} />
                                    <img width="50px" src="./assets/images/Draper/new/bgmaroonxs.png" alt="Transparent" />
                                </label>
                                <label>
                                    <input type="radio" name="bg" value="5" checked={formik.values.bg == 5 ? true : false} onChange={() => formik.setValues({ ...formik.values, bg: 5 })} />
                                    <img width="50px" src="./assets/images/Draper/new/bgrainbowxs.png" alt="Transparent" />
                                </label>
                                <label>
                                    <input type="radio" name="bg" value="6" checked={formik.values.bg == 6 ? true : false} onChange={() => formik.setValues({ ...formik.values, bg: 6 })} />
                                    <img width="50px" src="./assets/images/Draper/new/bgrainbowxxs.png" alt="Transparent" />
                                </label>
                                <label>
                                    <input type="radio" name="bg" value="7" checked={formik.values.bg == 7 ? true : false} onChange={() => formik.setValues({ ...formik.values, bg: 7 })} />
                                    <img width="50px" src="./assets/images/Draper/new/bgabstractxs.png" alt="Transparent" />
                                </label>
                                <label>
                                    <input type="radio" name="bg" value="9" checked={formik.values.bg == 9 ? true : false} onChange={() => formik.setValues({ ...formik.values, bg: 9 })} />
                                    <img width="50px" src="./assets/images/Draper/new/bgmatteblackxs.png" alt="Transparent" />
                                </label>
                            </div>
                            <div className="col-md-1">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <label class="control-label no-padding-right">Vibrance</label>
                                        <select className="form-control" value={formik.values.vibrance} onChange={(e) => formik.setValues({ ...formik.values, vibrance: e.target.value })}>
                                            <option value="0">Orignial</option>
                                            <option value="0.1">Level 1</option>
                                            <option value="0.2">Level 2</option>
                                            <option value="0.3">Level 3</option>
                                            <option value="0.4">Level 4</option>
                                            <option value="0.5">Level 5</option>
                                            <option value="0.6">Level 6</option>
                                            <option value="0.7">Level 7</option>
                                            <option value="0.8">Level 8</option>
                                            <option value="0.9">Level 9</option>
                                            <option value="1">Level 10</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-1">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <button style={{ marginTop: "20px" }} class="btn btn-info" disabled={(!isreadySaree1 || !isreadySaree2 || !isreadySaree3 || !isreadySaree4 || !isreadySaree5 || !isreadySaree6)} onClick={() => { StartDrapOnly(); }}> Apply </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div style={{ marginLeft: "15px" }}>Note: Only saree image 2 and saree image 3 will be saved to database for future use ,
                            <br />but dounload draping will download all images.
                            <br />Changing backgroun and vibrance will not charged from draping balance.
                            <br />Draping will take 2 to 3 minutes.
                        </div>
                        {/* <DrapingView FinalDraper={saree2Draper} FinalDraperBack={saree3Draper} itemName={formik.values.itemName} itemCode={formik.values.itemCode} note={formik.values.note} organization={organization} /> */}
                    </div>
                </>
                :
                isDraped ?
                    <div className="row center">
                        <img src='./images/loader.gif' />
                        <h3 className='center'>Verifying images...</h3>
                        <br />
                    </div>
                    : ""
            }
        </Container >
    );
}
