import React, { useState } from 'react'
import GetLoginInfo from './GetLoginInfo';

export const authContext = React.createContext();

const Auth = (props) => {
    const [isLogin, setIsLogin] = useState(GetLoginInfo() ? true : false);
    return (
        <authContext.Provider value={[isLogin, setIsLogin]}>
            {props.children}
        </authContext.Provider>
    );
}

export default Auth;
