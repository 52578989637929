import React from 'react'
import { Container } from '../base/Container'
import { ContactCategoryReport } from './Global/Reports/ContactCategoryReport'
export function OperationHome() {
    return (
        <Container title="Dashboard">
            <div class="clearfix">
                <div className="row">
                    <div className="col-lg-4">
                        <ContactCategoryReport />
                    </div>
                    <div className="col-lg-8" style={{textAlign: "-webkit-center" }}>
                        <img className="img-responsive" src="/images/dashboard-bg.jpg" />
                    </div>
                </div>
            </div>
        </Container>
    )
}
