import React, { useContext, useState } from 'react'
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { GetEOrderStatusName } from '../../enumCollections/EOrderStatus';
import { ContactView } from '../Global/Elements/ContactView';
import { ProductionUnitView } from '../Global/Elements/ProductionUnitView';
import { ProductView } from '../Global/Elements/ProductView';
import { UserView } from '../Global/Elements/UserView';
import { WarehouseView } from '../Global/Elements/WarehouseView';

export function OrderItemView({ order }) {

    return (
        <>
            <div className="widget-box">
                <div class="widget-header widget-header-flat widget-header-small">
                    <h5 class="widget-title">
                        Details
                    </h5>
                </div>
                {
                    order &&
                    <div className="row">
                        <div class="profile-user-info profile-user-info-striped">
                            <div class="profile-info-row">
                                <div class="profile-info-name"> Order No.</div>

                                <div class="profile-info-value">
                                    <span ><b>{order.id}</b></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> On Date</div>

                                <div class="profile-info-value">
                                    <span ><b><Moment format="DD-MMM-YYYY">{order.onDate}</Moment></b></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Alloted To Contact </div>

                                <div class="profile-info-value">
                                    <Link to={"/contact/dashboard/" + order.allotedToContactId}>
                                        <ContactView item={order.toContact} uid={"GC1"} />
                                    </Link>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Production </div>

                                <div class="profile-info-value">
                                    <Link to={"/productionunit/dashboard/" + order.toProductionUnit.id}>
                                        <ProductionUnitView item={order.toProductionUnit} />
                                    </Link>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Product </div>

                                <div class="profile-info-value">
                                    <Link to={"/product/dashboard/" + order.product.id}>
                                        <ProductView item={order.product} />
                                    </Link>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Quantity </div>

                                <div class="profile-info-value">
                                    <span ><b>{order.quantity} {order.unit}</b></span>
                                </div>
                            </div>

                            {order.allotedForContactId &&
                                <>
                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Alloted For Contact </div>

                                        <div class="profile-info-value">
                                            <Link to={"/contact/dashboard/" + order.allotedForContactId}>
                                                <ContactView item={order.contact} uid={"C"} />
                                            </Link>
                                        </div>
                                    </div>
                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Alloted Production </div>

                                        <div class="profile-info-value">
                                            {order.forProductionUnitData &&
                                                <Link to={"/productionunit/dashboard/" + order.forProductionUnitData.id}>
                                                    <ProductionUnitView item={order.forProductionUnitData} />
                                                </Link>
                                            }
                                        </div>
                                    </div>
                                </>
                            }

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Due Date </div>

                                <div class="profile-info-value">
                                    <span ><b> <Moment format="DD-MMM-YYYY">{order.dueDate}</Moment></b></span>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name">Branch </div>

                                <div class="profile-info-value">
                                    <WarehouseView item={order.warehouse} uid={"WHD1"} />
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name">Status </div>

                                <div class="profile-info-value">
                                    <span class={order.status == 1 ? "label label-sm label-primary" : "label label-sm label-warning"}>{GetEOrderStatusName(order.status)}</span>
                                </div>
                            </div>

                            {order.processingDistribution &&
                                <div class="profile-info-row">
                                    <div class="profile-info-name"> Distribution </div>

                                    <div class="profile-info-value">
                                        <span >Id: <b>{order.processingDistribution.id}</b>, To Contact: <b>{order.processingDistribution.contact.fullName}({order.processingDistribution.contact.id})</b>, For Processing: <b>{order.processingDistribution.processing.name}</b></span>
                                    </div>
                                </div>
                            }

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Details </div>

                                <div class="profile-info-value">
                                    <span >{order.details}</span>
                                </div>
                            </div>
                            {order.designItems && order.designItems.length > 0 &&
                                <>
                                    {order.designItems.map((d, i) =>
                                        <div class="profile-info-row">
                                            <div class="profile-info-name"> {d.name} </div>

                                            <div class="profile-info-value">
                                                {d.value}
                                                {d.text && <small><br />{d.text}</small>}
                                            </div>
                                        </div>
                                    )}
                                </>
                            }
                            <div class="profile-info-row">
                                <div class="profile-info-name"> Created By </div>

                                <div class="profile-info-value">
                                    <span><UserView item={order.createdByUser} uid={"GD1"} createdOn={order.createdOn} /></span>
                                </div>
                            </div>


                        </div>
                    </div>
                }
            </div>
        </>
    )
}
