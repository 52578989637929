import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../../../base/Container'
import AppToaster from '../../../commonLib/AppToaster'
import * as openingBalanceVoucherCrud from '../../../_crud/vouchers/openingBalanceVoucherCrud'
import { useFormik } from 'formik'
import { GetEBalanceType } from '../../../enumCollections/EBalanceType'
import * as Yup from "yup"
import { useHistory } from 'react-router-dom'
import { LoadingButton } from '../../../commonLib/LoadingButton'
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext'
import GetCurrentDateForSearch from '../../../commonLib/dateFormat';
import DatePicker from "react-datepicker";
import * as ledgerCrud from '../../../_crud/ledgerCrud'
import * as voucherCrud from '../../../_crud/voucherCrud'
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater'

// const initialValues = {
//     voucher: {
//         voucherType: 0,
//         linkedIdType: 0,
//         paymentStatus: 0,
//         totalAmount: 0,
//         voucherDate: new Date(GetCurrentDateForSearch(0)),
//         note: ""
//     },
//     ledgerId: 6,
//     type: 2
// }

const initialValues = {
    voucherType: 0,
    linkedIdType: 0,
    paymentStatus: 0,
    totalAmount: 0,
    voucherDate: new Date(GetCurrentDateForSearch(0)),
    note: "",
    ledgerId: 6,
    type: 1
}

export function AddOpeningBalanceVoucher() {

    const history = useHistory();
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [ledger, setLedger] = useState([])
    const [fiscalYear, setFiscalYear] = useState([])


    const Schema = Yup.object().shape({
        totalAmount: Yup.string().required("Amount is required."),
        voucherDate: Yup.string().required("Voucher Date is required."),
        ledgerId: Yup.string().required("Ledger Id is required."),
        type: Yup.string().required("Type is required.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    useEffect(() => {
        getLedger()
    }, [])

    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        openingBalanceVoucherCrud.VoucherAdd({
            Voucher: {
                voucherType: value.voucherType,
                linkedIdType: value.linkedIdType,
                paymentStatus: value.paymentStatus,
                totalAmount: value.totalAmount,
                voucherDate: value.voucherDate,
                note: value.note
            },
            ledgerId: value.ledgerId,
            type: value.type
        })
            .then(res => {
                if (res.data.result.succeeded) {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster('Added successfully.', 'success')
                    history.goBack()
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }
    function getLedger() {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        ledgerCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setLedger(hdata);
                    }
                    else {
                        setLedger([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setLedger([]);
                }
                setIsLoadingMini(false);
                setIsLoadingLocal(false)
            })
            .catch(error => {
                setLedger([]);
                setIsLoadingMini(false);
                setIsLoadingLocal(false)
                AppToaster(error, 'error')
            })
    }

    return (
        <>
            <Container title="Add Opening Balance Voucher" isBack="true">
                <form onSubmit={formik.handleSubmit} class="form-horizontal">
                    <div className="row">
                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Ledger</label>
                                    <select className="form-control" name="ledgerId" value={formik.values.ledgerId} onChange={(e) => formik.setValues({ ...formik.values, ledgerId: e.target.value })}>
                                        <option value="" >Select...</option>
                                        {
                                            ledger.map((data, index) =>
                                                <option key={data.id} value={data.id}>{data.ledgerName.toUpperCase()}</option>
                                            )
                                        }
                                    </select>
                                    {
                                        formik.touched.ledgerId &&
                                            formik.errors.ledgerId ? (
                                            <label className="text-danger" >
                                                {formik.errors.ledgerId}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Voucher Date</label>
                                    <span class="input-icon" style={{ width: "100%" }}>
                                        <DatePicker placeholder="Voucher Date" name="voucherDate" className="form-control input-date-padding" selected={formik.values.voucherDate} onChange={(date) => formik.setValues({ ...formik.values, voucherDate: date })} />
                                        <i class="ace-icon fa fa-calendar blue"></i>
                                    </span>
                                    {
                                        formik.touched.voucherDate &&
                                            formik.errors.voucherDate ? (
                                            <label className="text-danger" >
                                                {formik.errors.voucherDate}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Total Amount</label>
                                    <input className="form-control" placeholder="Total Amount" name="totalAmount" type="number" value={formik.values.totalAmount} onChange={(e) => formik.setValues({ ...formik.values, totalAmount: e.target.value })} />
                                    {
                                        formik.touched.totalAmount &&
                                            formik.errors.totalAmount ? (
                                            <label className="text-danger" >
                                                {formik.errors.totalAmount}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Type</label>
                                    <select className="form-control" name="type" value={formik.values.type} onChange={(e) => formik.setValues({ ...formik.values, type: e.target.value })}>
                                        {
                                            GetEBalanceType.EBalanceType.map((data, index) =>
                                                <option key={data.Id} value={data.Id}>{data.Name}</option>
                                            )
                                        }
                                    </select>
                                    {
                                        formik.touched.type &&
                                            formik.errors.type ? (
                                            <label className="text-danger" >
                                                {formik.errors.type}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-12">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Note</label>
                                    <TextBoxTranslater className="form-control" placeholder="Note" type="text" value={formik.values.note} onChange={(e) => formik.setValues({ ...formik.values, note: e.target.value })} />
                                    {
                                        formik.touched.note &&
                                            formik.errors.note ? (
                                            <label className="text-danger" >
                                                {formik.errors.note}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="clearfix form-actions">
                        <div class="pull-right">
                            {isLoadingLocal ?
                                <LoadingButton />
                                :
                                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Add
                                </button>}
                        </div>
                    </div>
                </form>
            </Container >
        </>
    )
}
