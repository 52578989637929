import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../../../base/Container';
import AppToaster from '../../../commonLib/AppToaster';
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import { useHistory, useParams } from 'react-router-dom';
import { GetEStatus, GetEStatusName } from '../../../enumCollections/EStatus';
import * as productionUnitProductAllotmentCrud from '../../../_crud/productionUnitProductAllotmentCrud'
import * as productCrud from '../../../_crud/productCrud'
import * as productionUnitCrud from '../../../_crud/productionUnitCrud'
import { LoadingButton } from '../../../commonLib/LoadingButton';
import { ProductSelectorView } from '../../Global/SelectorView/ProductSelectorView';
import ProductSelector from '../../Global/ProductSelector';
import { useFormik } from 'formik';
import * as Yup from "yup"
import { ProductionSelectorView } from '../../Global/SelectorView/ProductionSelectorView';

export function AddContactProductAllotment() {
    const history = useHistory();
    const [productsSelect, setProductsSelect] = useState([])
    const { id } = useParams();
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [production, setProduction] = useState([])

    const [selectedData, setSelectedData] = useState(undefined);
    const [selectedProductionData, setSelectedProductionData] = useState(undefined);

    useEffect(() => {
        if (selectedData) {
            formik.setValues({ ...formik.values, productId: selectedData.id })
        }
    }, [selectedData]);

    useEffect(() => {
        if (selectedProductionData) {
            formik.setValues({ ...formik.values, productionUnitId: selectedProductionData.id })
        }
        else{
            formik.setValues({ ...formik.values, productionUnitId: "" })
        }
    }, [selectedProductionData])

    const initialValues = {
        productId: undefined,
        productionUnitId: "",
        status: 1,
    }

    const Schema = Yup.object().shape({
        productId: Yup.string().required("Product is required."),
        productionUnitId: Yup.string().required("Production Unit is required."),
        status: Yup.string().required("Status is required.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        productionUnitProductAllotmentCrud.Add(value)
            .then(res => {
                if (res.data.result.succeeded) {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster('Added successfully.', 'success')
                    history.goBack()
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    useEffect(() => {
        getProduction(id)
        getProducts()
    }, []);

    function getProducts() {
        setIsLoadingMini(true);
        productCrud.GetAllForSelect()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProductsSelect(hdata);
                    }
                    else {
                        setProductsSelect([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProductsSelect([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    const OnProductionChange = (e) => {
        const { value } = e.target;
        if (value) {
            production.forEach(element => {
                if (element.id == value) {
                    {
                        setSelectedProductionData(element)
                    }
                }
            });
        }
        else {
            setSelectedProductionData(undefined)
        }
    }

    function getProduction(contactid) {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        productionUnitCrud.GetByContactId(contactid)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProduction(hdata);
                    }
                    else {
                        setProduction([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProduction([]);
                }
                setIsLoadingMini(false);
                setIsLoadingLocal(false)
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false)
                AppToaster(error, 'error')
            })
    }

    return (
        <>
            <Container title="Add Product" isBack="true" isContact="true">
                <div class="col-sm-6">
                    <label class="col-sm-2 control-label no-padding-right"> Production</label>
                    <div class="col-sm-10">
                        <select className="form-control" name="productionUnitId" value={formik.values.productionUnitId} onChange={OnProductionChange}>
                            <option value="" >Select...</option>
                            {
                                production.map((data, index) =>
                                    <option key={data.id} value={data.id}>{data.productionUnitName.toUpperCase()} ({data.id})</option>
                                )
                            }
                        </select>
                        <ProductionSelectorView data={selectedProductionData} />
                    </div>
                </div>
                <div class="col-sm-6">
                    <label class="col-sm-2 control-label no-padding-right"> Product</label>
                    <div class="col-sm-10">
                        <ProductSelector searchtext="" setSelectedData={setSelectedData} />
                    </div>
                    <ProductSelectorView data={selectedData} />
                </div>
                {selectedData &&
                    <>
                        <div class="col-sm-6">
                            <div className="space-4"></div>
                            <label class="col-sm-2 control-label no-padding-right"> Status</label>
                            <div class="col-sm-10">
                                <select className="form-control" name="status" value={formik.values.status} onChange={(e) => formik.setValues({ ...formik.values, status: e.target.value })}>
                                    {
                                        GetEStatus.EStatus.map((data, index) =>
                                            <option key={data.Id} value={data.Id}>{data.Name}</option>
                                        )
                                    }
                                </select>
                                {
                                    formik.touched.status &&
                                        formik.errors.status ? (
                                        <label className="text-danger" >
                                            {formik.errors.status}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </>
                }
                <div class="col-xs-12">
                    <div className="space-4"></div>
                    <div class="col-sm-2"></div>
                    <form onSubmit={formik.handleSubmit} class="form-horizontal">
                        <div class="clearfix form-actions">
                            <div class="pull-right">
                                {isLoadingLocal ?
                                    <LoadingButton />
                                    :
                                    <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                        <i class="ace-icon fa fa-check bigger-110"></i>
                                        Add
                                    </button>}
                            </div>
                        </div>

                    </form>
                </div>
            </Container >
        </>
    )
}
