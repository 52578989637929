const GetEConnectedIdTypeName = (id) => {
    switch (id) {
        case 101: { return "Administrator" }
        case 102: { return "Operation" }
        case 103: { return "Processing" }
    }
}

const GetEConnectedIdType = {
    EConnectedIdType: [
        { Id: 101, Name: "Administrator" },
        { Id: 102, Name: "Operation" },
        // { Id: 103, Name: "Processing" }
    ]
}

export { GetEConnectedIdTypeName, GetEConnectedIdType }