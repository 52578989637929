import React, { useContext, useEffect, useRef, useState } from 'react'
import AppToaster from '../../commonLib/AppToaster';
import * as gatePassCrud from '../../_crud/gatePassCrud'
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { Container } from '../../base/Container'
import { LoadingButton } from '../../commonLib/LoadingButton';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import GetCurrentDateForSearch from '../../commonLib/dateFormat';
import DatePicker from "react-datepicker";
import { ContactSelectorView } from '../Global/SelectorView/ContactSelectorView';
import ContactSelector from '../Global/ContactSelector';
import { GetEGatePassProductsType, GetEGatePassProductsTypeName } from '../../enumCollections/EGatePassProductsType'
import ProductSelector from '../Global/ProductSelector';
import { ProductSelectorView } from '../Global/SelectorView/ProductSelectorView';
import { ProductView } from './../Global/Elements/ProductView'
import { EmptyData } from './../../commonLib/EmptyData'
import { PagingEmpty } from './../../commonLib/PagingEmpty'
import { MyAlert } from '../../commonLib/MyAlert';
import GetLoginInfo from '../../auth/_userContext/GetLoginInfo';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

const initialValues = {
  GatePass: {
    refContactId: "",
    onDate: new Date(GetCurrentDateForSearch(0)),
    note: ""
  },
  Products: []
}

const initItems = {
  xid: 0,
  gatePassId: 0,
  productId: "",
  productData: undefined,
  quantity: 0,
  type: "0",
  unit: ""
}

export function AddGatePass() {
  const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
  const [isLoadingLocal, setIsLoadingLocal] = useState(false)
  const history = useHistory();
  const [selectedContactData, setSelectedContactData] = useState(undefined);
  const [selectedProductData, setSelectedProductData] = useState(undefined);
  const [gatePassItem, setGatePassItem] = useState(initItems);
  const [itemButton, setItemButton] = useState(false);
  const [empty, setEmpty] = useState(false);
  const inputReference = useRef(null);
  const userData = GetLoginInfo()

  const Schema = Yup.object().shape({
    // refContactId: Yup.string().required("Ref Contact Id is required."),
    // onDate: Yup.string().required("On Date is required."),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values) => {
      Save(values);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });

  useEffect(() => {
    setEmpty(false)
  }, [empty]);

  useEffect(() => {
    if (gatePassItem.productId && gatePassItem.quantity > 0)
      setItemButton(true)
    else
      setItemButton(false)

  }, [gatePassItem]);

  useEffect(() => {
    if (selectedContactData) {
      formik.setValues({ ...formik.values, GatePass: { ...formik.values.GatePass, refContactId: selectedContactData.id } })
    }
  }, [selectedContactData]);

  useEffect(() => {
    if (selectedProductData) {
      setGatePassItem({ ...gatePassItem, productId: selectedProductData.id, unit: selectedProductData.unit, quantity: 0, productData: selectedProductData })
    }
  }, [selectedProductData]);

  const AddItem = () => {
    // let isvalid = true;
    // formik.values.Products.forEach((item, index) => {
    //   if (gatePassItem.productId == item.productId && gatePassItem.type == item.type)
    //     isvalid = false
    // })
    // if (isvalid) {
    formik.setValues({ ...formik.values, Products: [...formik.values.Products, gatePassItem] })
    setGatePassItem({
      ...gatePassItem,
      xid: gatePassItem.xid + 1,
      quantity: "",
    })
    inputReference.current.focus();
    // setGatePassItem({
    //   xid: gatePassItem.xid + 1,
    //   gatePassId: 0,
    //   productId: "",
    //   productData: undefined,
    //   quantity: 0,
    //   type: "0",
    //   unit: ""
    // })
    //setSelectedProductData(undefined)
    //setEmpty(true)
    AppToaster("Item added.", "success")
    // }
    // else
    //   AppToaster("You could not add same product and type twice.", "error")
  }

  const Save = (value) => {
    if (value.Products && value.Products.length > 0) {
      setIsLoadingMini(true);
      setIsLoadingLocal(true)
      gatePassCrud.Add(value)
        .then(res => {
          if (res.data.result.succeeded) {
            setIsLoadingMini(false)
            setIsLoadingLocal(false);
            AppToaster('Added successfully.', 'success')
            history.push("/gatepass/dashboard/" + res.data.data.id)
          }
          else {
            setIsLoadingMini(false)
            setIsLoadingLocal(false);
            AppToaster("Server Error /Invalid Try.", 'error')
          }
        })
        .catch(error => {
          setIsLoadingMini(false);
          setIsLoadingLocal(false);
          AppToaster(error, 'error')
        })
    }
    else {
      AppToaster("There is no items in gatepass please add items.", 'error')
    }
  }

  const DeleteItem = (xid) => {
    const sitem = formik.values.Products
    sitem.forEach((item, index) => {
      if (item.xid == xid) {
        sitem.splice(index, 1)
      }
    })
    formik.setValues({ ...formik.values, Products: sitem })
    AppToaster("Item removed.", 'error')
  }

  function setQuantity() {
    if (selectedProductData) {
      switch (gatePassItem.type) {
        case "0":
          {
            if (gatePassItem.quantity > selectedProductData.mainStock) {
              setGatePassItem({ ...gatePassItem, quantity: selectedProductData.mainStock })
            }
            else if (gatePassItem.quantity < 0) {
              setGatePassItem({ ...gatePassItem, quantity: 0 })
            }
            break;
          }
        case "1":
          {
            if (gatePassItem.quantity > selectedProductData.defectiveStock) {
              setGatePassItem({ ...gatePassItem, quantity: selectedProductData.defectiveStock })
            }
            else if (gatePassItem.quantity < 0) {
              setGatePassItem({ ...gatePassItem, quantity: 0 })
            }
            break;
          }
        case "2":
          {
            if (gatePassItem.quantity > selectedProductData.rejectedStock) {
              setGatePassItem({ ...gatePassItem, quantity: selectedProductData.rejectedStock })
            }
            else if (gatePassItem.quantity < 0) {
              setGatePassItem({ ...gatePassItem, quantity: 0 })
            }
            break;
          }
        case "3":
          {
            if (gatePassItem.quantity > selectedProductData.rawStock) {
              setGatePassItem({ ...gatePassItem, quantity: selectedProductData.rawStock })
            }
            else if (gatePassItem.quantity < 0) {
              setGatePassItem({ ...gatePassItem, quantity: 0 })
            }
            break;
          }
      }
    }

  }

  useEffect(() => {
    setQuantity()
  }, [gatePassItem.type, gatePassItem.quantity]);


  return (
    <>
      <Container title="Add GatePass" isBack="true">
        <form onSubmit={formik.handleSubmit} class="form-horizontal">
          <div className="row">
            <div className="col-md-6">
              <div class="form-group">
                <div class="col-xs-12">
                  <label class="control-label no-padding-right">Ref Contact</label>
                  <ContactSelector searchtext="" setSelectedData={setSelectedContactData} />
                  {
                    formik.touched.refContactId &&
                      formik.errors.refContactId ? (
                      <label className="text-danger" >
                        {formik.errors.refContactId}
                      </label>
                    ) : null
                  }
                </div>
                <ContactSelectorView data={selectedContactData} />
              </div>
            </div>

            <div className="col-md-2">
              <div class="form-group">
                <div class="col-xs-12">
                  <label class="control-label no-padding-right"> On Date</label>
                  <span class="input-icon" style={{ width: "100%" }}>
                    <DatePicker placeholder="onDate" name="onDate" className="form-control input-date-padding" selected={formik.values.GatePass.onDate} onChange={(date) => formik.setValues({ ...formik.values, GatePass: { ...formik.values.GatePass, onDate: date } })} />
                    <i class="ace-icon fa fa-calendar blue"></i>
                  </span>
                  {
                    formik.touched.onDate &&
                      formik.errors.onDate ? (
                      <label className="text-danger" >
                        {formik.errors.onDate}
                      </label>
                    ) : null
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <div class="form-group">
                <div class="col-xs-12">
                  <label class="control-label no-padding-right">Note</label>
                  <TextBoxTranslater className="form-control" type="text" value={formik.values.GatePass.note} placeholder="Note" name="note" onChange={(e) => formik.setValues({ ...formik.values, GatePass: { ...formik.values.GatePass, note: e.target.value } })} />
                </div>
              </div>
            </div>
          </div>

          <h4>Gatepass Items</h4>
          <div class="hr hr-double hr8"></div>

          <div className="row">
            <div className="col-md-7">
              <div class="form-group">
                <div class="col-xs-12">
                  <label class="control-label no-padding-right">Product</label>
                  <ProductSelector setEmpty={empty} searchtext="" setSelectedData={setSelectedProductData} isWarehouse={userData.isWarehouse == 0 ? false : true} />
                </div>
                <ProductSelectorView data={selectedProductData} />
              </div>
            </div>

            <div className="col-md-2">
              <div class="form-group">
                <div class="col-xs-12">
                  <label class="control-label no-padding-right"> Quantity {gatePassItem.unit && "- " + gatePassItem.unit}</label>
                  <input ref={inputReference} className="form-control" type="number" placeholder="Quantity" value={gatePassItem.quantity} onChange={(e) => setGatePassItem({ ...gatePassItem, quantity: e.target.value })} />
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div class="form-group">
                <div class="col-xs-12">
                  <label class="ontrol-label no-padding-right">Type</label>
                  <select className="form-control" value={gatePassItem.type} onChange={(e) => setGatePassItem({ ...gatePassItem, type: e.target.value })}>
                    {
                      GetEGatePassProductsType.EGatePassProductsType.map((data, index) =>
                        <option key={data.Id} value={data.Id}>{data.Name}</option>
                      )
                    }
                  </select>
                </div>
              </div>
            </div>

            <div className="col-md-1">
              <div class="form-group">
                <label class="control-label no-padding-right">&nbsp;</label>
                <div class="col-xs-12">
                  <button class="btn btn-xs btn-success" type="submit" expand="block" onClick={AddItem} disabled={!itemButton}>
                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <PagingEmpty text={"Total " + formik.values.Products.length + " items."}>
                {formik.values.Products && formik.values.Products.length > 0 ?
                  <div className="tables-scroll">
                    <table className="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Product</th>
                          <th>Quantity</th>
                          <th>Type</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {formik.values.Products.map((item, index) =>
                          <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td><ProductView item={item.productData} uid={"PP" + index} /></td>
                            <td>{item.quantity} {item.unit}</td>
                            <td>{GetEGatePassProductsTypeName(parseInt(item.type))}</td>
                            <td>
                              <div class="btn-group">
                                <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.xid}>
                                  <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                </MyAlert>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  :
                  <EmptyData>
                    No Item Added.
                    <br />
                    <br />
                  </EmptyData>
                }
              </PagingEmpty>
            </div>
          </div>


          <div class="clearfix form-actions">
            <div class="pull-right">
              {isLoadingLocal ?
                <LoadingButton />
                :
                <button class="btn btn-info" type="submit" expand="block" disabled={(formik.values.GatePass.refContactId && formik.values.GatePass.onDate) ? false : true}>
                  <i class="ace-icon fa fa-check bigger-110"></i>
                  Add
                </button>}
            </div>
          </div>

        </form>
      </Container >
    </>
  )
}
