import React from 'react'

export function ProductionUnitViewS({ item }) {
    return (
        item &&
        <>
            {item.productionUnitName} ({item.productionCategory.productionCatName})
        </>
    )
}
