import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import * as contactProcessingAllotmentCrud from '../../_crud/contactProcessingAllotmentCrud'
import * as contactCrud from '../../_crud/contactCrud'
import { Link } from 'react-router-dom';
import { GetEStatus, GetEStatusName } from '../../enumCollections/EStatus';
import { useParams } from 'react-router-dom';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { Spinner } from '../../commonLib/Spinner';
import { loadingContext } from '../../commonLib/_lodingContext';
import { PagingEmpty } from '../../commonLib/PagingEmpty';
import 'moment-timezone';
import { EmptyData } from '../../commonLib/EmptyData';
import { UserView } from '../Global/Elements/UserView';
import { ProcessingView } from '../Global/Elements/ProcessingView';

const initEdit = {
    id: 0,
    isEdit: false,
    value: undefined
}

export function ManageProcessingAllotment() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [processingAllotment, setProcessingAllotment] = useState([])
    const [contact, setContact] = useState()
    const [edit, setEdit] = useState(initEdit)
    const { contactId } = useParams()

    useEffect(() => {
        getData()
        getContact()
    }, []);

    function getContact() {
        setIsLoadingMini(true);
        contactCrud.GetById(contactId)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setContact(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setIsLoadingMini(false)
                        setContact();
                    }
                }
                else {
                    setIsLoadingMini(false)
                    setContact();
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function getData() {
        setIsLoadingMini(true);
        contactProcessingAllotmentCrud.GetByContact(contactId)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProcessingAllotment(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setIsLoadingMini(false)
                        setProcessingAllotment([]);
                    }
                }
                else {
                    setIsLoadingMini(false)
                    setProcessingAllotment([]);
                    AppToaster("Network Error", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        getData()
    }

    const Update = () => {
        setIsLoading(true);
        contactProcessingAllotmentCrud.UpdateStatus({ id: edit.value.id, status: edit.value.status })
            .then(res => {
                if (res.data.succeeded) {
                    setEdit(initEdit)
                    doRefresh()
                    AppToaster('Updated successfully.', 'success')
                    setIsLoading(false);
                }
                else {
                    AppToaster("Network Error", 'error')
                    setIsLoading(false);
                }

            }).catch(error => {
                setEdit(initEdit)
                AppToaster(error, 'error')
                setIsLoading(false);
            })
    }

    return (
        <Container title="Processing Allotment for contact" doRefresh={doRefresh} isBack="true" isContact="true">
            <Link to={"/contact/dashboard/" + contactId + "/processingallotment/add"} class="btn btn-sm btn-success">
                <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                New
            </Link>
            <div class="hr dotted"></div>
            <PagingEmpty text={"Total " + processingAllotment.length + " items."}>
                {isLoadingMini ?
                    <Spinner />
                    :
                    processingAllotment && processingAllotment.length > 0 ?
                        <div className="tables-scroll">
                            <table className="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Processing</th>
                                        <th>Status</th>
                                        <th style={{ width: "43px" }}></th>
                                        <th style={{ width: "43px" }}>By</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {processingAllotment.map((item, index) =>
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td><Link to={"/processing/dashboard/" + item.processingId} >
                                                <ProcessingView item={item.processing} />
                                            </Link></td>
                                            <td>{edit.isEdit && item.id == edit.id ?
                                                <select className="form-control" name="status" value={edit.value.status} onChange={(e) => setEdit({ ...edit, value: { ...edit.value, status: e.target.value } })}>
                                                    {
                                                        GetEStatus.EStatus.map((data, index) =>
                                                            <option value={data.Id}>{data.Name}</option>
                                                        )
                                                    }
                                                </select>
                                                :
                                                <span class={item.status == 1 ? "label label-sm label-success" : "label label-sm label-danger"}>{GetEStatusName(item.status)}</span>
                                            }
                                            </td>

                                            <td>
                                                <div class="btn-group">
                                                    {edit.isEdit && item.id == edit.id ?
                                                        isLoading ?
                                                            <button disabled class="btn btn-xs btn-info">
                                                                <i class="ace-icon fa fa-pencil bigger-120"></i> Updating...
                                                            </button>
                                                            :
                                                            <>
                                                                <button onClick={Update} class="btn btn-xs btn-info">
                                                                    <i class="ace-icon fa fa-pencil bigger-120"></i> Update
                                                                </button>
                                                                <button onClick={() => setEdit(initEdit)} class="btn btn-xs btn-default">
                                                                    Cancel
                                                                </button>
                                                            </>
                                                        :
                                                        <button onClick={() => setEdit({ id: item.id, value: item, isEdit: true })} class="btn btn-xs btn-info">
                                                            <i class="ace-icon fa fa-pencil bigger-120"></i>
                                                        </button>
                                                    }
                                                </div>
                                            </td>

                                            <td>
                                                <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        :
                        <EmptyData>
                            No records found.
                            <br />
                            <br />
                        </EmptyData>
                }
            </PagingEmpty>
        </Container >
    )
}
