import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken, createAxiosWithTokenFile } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetAll(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("SareeDraping/GetAll", param)
    }
}
export async function GetById(Id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("SareeDraping/GetById?Id=" + Id)
    }
}

export async function Add(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithTokenFile(loginData.token, OrgId()).post("SareeDraping/Add", param)

    }
}
export async function DrapingSuccess() {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("SareeDraping/DrapingSuccess")

    }
}
export async function Edit(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("SareeDraping/Edit", param)
    }
}

export async function Remove(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("SareeDraping/Remove?Id=" + id)
    }
}

/////CATALOGUE
export async function GetAllCatalogue(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("SareeDraping/GetAllCatalogue", param)
    }
}
export async function GetCatalogueById(Id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("SareeDraping/GetCatalogueById?Id=" + Id)
    }
}

export async function AddCatalogue(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("SareeDraping/AddCatalogue", param)

    }
}

export async function AddItemsExistingCatalogue(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("SareeDraping/AddItemsExistingCatalogue", param)

    }
}

export async function EditCatalogue(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("SareeDraping/EditCatalogue", param)
    }
}

export async function RemoveCatalogueItem(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("SareeDraping/RemoveCatalogueItem?Id=" + id)
    }
}

export async function RemoveCatalogue(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("SareeDraping/RemoveCatalogue?Id=" + id)
    }
}