import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Container } from '../../base/Container'
import AppToaster from '../../commonLib/AppToaster'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext'
import { Spinner } from '../../commonLib/Spinner'
import * as processingCrud from '../../_crud/processingCrud'
import { GetEProcessingTypeName } from '../../enumCollections/EProcessingType'
import { GetEProcessingModeName } from '../../enumCollections/EProcessingMode'
import { PagingEmpty } from '../../commonLib/PagingEmpty'
import { MyAlert } from '../../commonLib/MyAlert';
import { UserView } from '../Global/Elements/UserView'
import { EmptyData } from '../../commonLib/EmptyData'

export function ManageProcessing() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [processing, setProcessing] = useState([])

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoadingMini(true);
        processingCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProcessing(hdata);
                    }
                    else {
                        setProcessing([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProcessing([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        getData()
        setTimeout(() => {
            event.detail.complete();
        }, 1000);
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        processingCrud.Remove(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    AppToaster('Deleted successfully.', 'success')
                    getData()
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by someone, could not delete.', 'error')
                }

            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }

    return (
        <>
            <Container title="Processing" doRefresh={doRefresh}>
                <Link to="/processing/add" class="btn btn-sm btn-success">
                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                    New
                </Link>
                <div class="hr dotted"></div>
                <PagingEmpty text={"Total " + processing.length + " items."}>
                    {isLoadingMini ?
                        <Spinner />
                        :
                        processing && processing.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "43px" }}></th>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Default Wage</th>
                                            <th>Type</th>
                                            <th>Mode</th>
                                            <th>Note</th>
                                            <th style={{ width: "43px" }}>By</th>
                                            <th style={{ width: "43px" }}></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {processing.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link className="btn btn-xs btn-info" to={"/processing/dashboard/" + item.id} title="View dashboard" ><i class="ace-icon fa fa-tachometer"></i></Link>
                                                    </div>
                                                </td>
                                                <td>{index + 1}</td>
                                                <td>{item.name}</td>
                                                <td><b>₹ {item.defaultWage}</b> </td>
                                                <td><span class={item.type == 1 ? "label label-sm label-success" : "label label-sm label-primary"}>{GetEProcessingTypeName(item.type)}</span></td>
                                                <td><span class={item.mode == 1 ? "label label-sm label-warning" : "label label-sm label-info"}>{GetEProcessingModeName(item.mode)}</span></td>
                                                <td><div style={{ width: "150px" }}>{item.note}</div></td>
                                                <td>
                                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                            <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                        </MyAlert>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </PagingEmpty>
            </Container >
        </>
    )
}
