import React, { useContext, useEffect, useRef, useState } from 'react'
import { PAGING_PAGE_SIZE, getIsPrint } from '../../../config/GlobalSettings';
import AppToaster from '../../../commonLib/AppToaster';
import *as productProcessingDistributionCrud from '../../../_crud/productProcessingDistributionCrud'
import { Paging } from '../../../commonLib/Paging';
import { Spinner } from '../../../commonLib/Spinner';
import { Link } from 'react-router-dom';
import { Container } from '../../../base/Container';
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import { GetEStockTypeName } from '../../../enumCollections/EStockType'
import Moment from 'react-moment';
import { EmptyData } from '../../../commonLib/EmptyData';
import DatePicker from "react-datepicker";
import GetCurrentDateForSearch from '../../../commonLib/dateFormat';
import { MyAlert } from '../../../commonLib/MyAlert';
import { GetEBoolName } from '../../../enumCollections/EBool';
import { ProcessingView } from '../../Global/Elements/ProcessingView';
import { UserView } from '../../Global/Elements/UserView';
import { ProductView } from '../../Global/Elements/ProductView';
import { ContactView } from '../../Global/Elements/ContactView';
import { ProductionUnitView } from '../../Global/Elements/ProductionUnitView';
import { ProcessingDistributionPrint } from '../../Prints/ProcessingDistributionPrint';
import { PrintStarter } from '../../Prints/PrintStarter';
import { WarehouseView } from '../../Global/Elements/WarehouseView';
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater';

const initValues = {
    Search: {
        SearchTerm: "",
        PageNo: 1,
        PageSize: PAGING_PAGE_SIZE
    },
    Range: {
        FromDate: new Date(GetCurrentDateForSearch(-30)),
        ToDate: new Date(GetCurrentDateForSearch(0))
    }
}

export function ManageProcessingDistribution() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [search, setSearch] = useState(initValues)
    const [processingDistribution, setProcessingDistribution] = useState([])
    const [LastPage, setLastPage] = useState(1)
    const [pagingData, setPagingData] = useState()

    useEffect(() => {
        getData()
    }, [search.Search.PageNo])


    const OnPageChange = (e) => {
        const { value } = e.target
        setSearch({ ...search, Search: { ...search.Search, PageNo: value } });
    }

    function OnSearch() {
        getData()
    }

    function getData() {
        setIsLoadingMini(true);
        productProcessingDistributionCrud.GetAll(search)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setProcessingDistribution(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoadingMini(false)
                        setProcessingDistribution([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoadingMini(false)
                    setProcessingDistribution([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        setSearch({ ...search, Search: { ...search.Search, PageNo: 1 } })
        getData()
        setTimeout(() => {
            event.target.complete();
        }, 1000);
    }

    function SearchChange(e) {
        const value = e.target.value
        setSearch({ ...search, Search: { ...search.Search, SearchTerm: value } })
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true);
        productProcessingDistributionCrud.Remove(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    AppToaster('Deleted successfully.', 'success')
                    getData()
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by someone,could not delete', 'error')
                }

            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }

    const [printData, setPrintData] = useState()
    const printRef = useRef();
    return (
        <>
            {getIsPrint && <div style={{ display: "none" }}>
                <ProcessingDistributionPrint printData={printData} ref={printRef} />
            </div>}
            <Container title={"Processing Distribution "} doRefresh={doRefresh}>
                <div class="form-horizontal" >
                    <div className="row">
                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker selectsStart startDate={search.Range.FromDate} endDate={search.Range.ToDate} className="form-control input-date-padding" selected={search.Range.FromDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, FromDate: date } })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker selectsEnd startDate={search.Range.FromDate} endDate={search.Range.ToDate} className="form-control input-date-padding" selected={search.Range.ToDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, ToDate: date } })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-7">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <TextBoxTranslater style={{paddingLeft: "24px"}} value={search.Search.SearchTerm} onChange={SearchChange} className="form-control" type="text" placeholder="Search Processing Distribution.." />
                                    <i class="ace-icon fa fa-search blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-1">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <button onClick={OnSearch} to="/processingdistribution/add" class="btn btn-sm btn-info pull-right">
                                    <i class="ace-icon fa fa-search bigger-110"></i>
                                    Run
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
                <Paging
                    pagingData={pagingData}
                    LastPage={LastPage}
                    CurrentPage={search.Search.PageNo}
                    OnPageChange={OnPageChange} >
                    {isLoadingMini ?
                        <Spinner />
                        :
                        processingDistribution && processingDistribution.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            {getIsPrint && <th></th>}
                                            <th>Branch</th>
                                            <th>#Id</th>
                                            <th>On Date</th>
                                            <th>Contact</th>
                                            <th>Processing</th>
                                            <th>Product</th>
                                            <th>Quantity</th>
                                            <th>From</th>
                                            <th>Production</th>
                                            <th>Note</th>
                                            <th>By</th>
                                            <th style={{ width: "43px" }}></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {processingDistribution.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link className="btn btn-xs btn-info" to={"/processingdistribution/dashboard/" + item.id} title="View dashboard" ><i class="ace-icon fa fa-tachometer"></i></Link>
                                                    </div>
                                                </td>
                                                {getIsPrint && <td>
                                                    <div class="btn-group">
                                                        <PrintStarter printRef={printRef} setPrintData={setPrintData} item={item} />
                                                    </div>
                                                </td>}
                                                <td><WarehouseView item={item.warehouse} uid={"WH" + index} /></td>
                                                <td>{item.id}</td>
                                                <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.onDate}</Moment></div></td>
                                                <td>
                                                    <Link to={"/contact/dashboard/" + item.contactId} >
                                                        <ContactView item={item.contact} uid={"C" + index} />
                                                    </Link>
                                                </td>
                                                <td>
                                                    <Link to={"/processing/dashboard/" + item.processingId} >
                                                        <ProcessingView item={item.processing} />
                                                    </Link>
                                                </td>
                                                <td>
                                                    <Link to={"/product/dashboard/" + item.productId} >
                                                        <ProductView item={item.product} uid={"PP" + index} />
                                                    </Link>
                                                </td>
                                                <td>{item.quantity} {item.unit}</td>
                                                <td><span class={item.forStock == 1 ? "label label-sm label-primary" : "label label-sm label-success"}>{GetEStockTypeName(item.forStock)}</span></td>
                                                <td>
                                                    {item.isProductionUnit == 1 ? (item.productionUnit ? <Link to={"/productionunit/dashboard/" + item.productionUnitId} >
                                                        <ProductionUnitView item={item.productionUnit} />
                                                    </Link> : "NA") : <span class="label label-sm label-danger">{GetEBoolName(item.isProductionUnit)}</span>}
                                                </td>
                                                <td><div style={{ width: "200px" }}>{item.note}</div></td>
                                                <td>
                                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                            <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                        </MyAlert>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </Paging>
            </Container >
        </>
    )
}
