import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import AppToaster from '../../commonLib/AppToaster';
import * as productionUnitProductAllotmentCrud from '../../_crud/productionUnitProductAllotmentCrud'
import * as productCrud from '../../_crud/productCrud'
import { useFormik } from 'formik';
import * as Yup from "yup"
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { useHistory } from 'react-router-dom';
import { GetEStatus } from '../../enumCollections/EStatus';
import { Container } from '../../base/Container';
import { LoadingButton } from '../../commonLib/LoadingButton';
import ProductSelector from '../Global/ProductSelector';
import { ProductSelectorView } from '../Global/SelectorView/ProductSelectorView';

export function AddProductAllotment() {
    const history = useHistory();
    const [productsSelect, setProductsSelect] = useState([])
    const { id } = useParams();
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)

    const [selectedData, setSelectedData] = useState(undefined);
    useEffect(() => {
        if (selectedData) {
            formik.setValues({ ...formik.values, productId: selectedData.id })
        }
    }, [selectedData]);

    const initialValues = {
        productId: undefined,
        productionUnitId: id,
        status: 1
    }

    const Schema = Yup.object().shape({
        productId: Yup.string().required("Product is required."),
        productionUnitId: Yup.string().required("Production Unit is required."),
        status: Yup.string().required("Status is required.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });
    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        productionUnitProductAllotmentCrud.Add(value)
            .then(res => {
                if (res.data.result.succeeded) {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster('Added successfully.', 'success')
                    history.goBack()
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    useEffect(() => {
        getProducts()
    }, []);

    function getProducts() {
        setIsLoadingMini(true);
        productCrud.GetAllForSelect()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProductsSelect(hdata);
                    }
                    else {
                        setProductsSelect([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProductsSelect([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    return (
        <>
            <Container title="Add Product Allotment" isBack="true" isProduction="true" isContact="true">
                <div class="col-xs-12">
                    <label class="col-sm-2 control-label no-padding-right"> Select Product</label>
                    <div class="col-sm-10">
                        <ProductSelector searchtext="" setSelectedData={setSelectedData} />
                    </div>
                    <ProductSelectorView data={selectedData} />
                </div>
                {selectedData &&
                    <>
                        <div class="col-xs-12">
                            <div className="space-4"></div>
                            <label class="col-sm-2 control-label no-padding-right"> Status</label>
                            <div class="col-sm-10">
                                <select className="form-control" name="status" value={formik.values.status} onChange={(e) => formik.setValues({ ...formik.values, status: e.target.value })}>
                                    {
                                        GetEStatus.EStatus.map((data, index) =>
                                            <option key={data.Id} value={data.Id}>{data.Name}</option>
                                        )
                                    }
                                </select>
                                {
                                    formik.touched.status &&
                                        formik.errors.status ? (
                                        <label className="text-danger" >
                                            {formik.errors.status}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </>
                }
                <div class="col-xs-12">
                    <div className="space-4"></div>
                    <div class="col-sm-2"></div>
                    <form onSubmit={formik.handleSubmit} class="form-horizontal">
                        <div class="clearfix form-actions">
                            <div class="pull-right">
                                {isLoadingLocal ?
                                    <LoadingButton />
                                    :
                                    <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                        <i class="ace-icon fa fa-check bigger-110"></i>
                                        Add
                                    </button>}
                            </div>
                        </div>

                    </form>
                </div>
            </Container >
        </>
    )
}
