import React from 'react'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useState } from 'react';
import AppToaster from '../../commonLib/AppToaster';
import * as applicationUserCrud from '../../_crud/applicationUserCrud';

export function ResetNewPassword({ setIsConfirmReset, userData }) {
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)

    const initialValues = {
        otp: "",
        newPass: "",
        confirmPassword: ""
    }

    const Schema = Yup.object().shape({
        otp: Yup.string().required("OTP is required."),
        newPass: Yup.string()
            .required('Please Enter your new password')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
            ),
        confirmPassword: Yup.string()
            .required('Please Enter your confirm password')
            .oneOf([Yup.ref("newPass"), null], "Passwords must match"),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            //SendOTP();
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    const ResetPass = () => {
        setIsLoadingLocal(true);
        applicationUserCrud.ForgotPassword(userData.userName, formik.values.otp, formik.values.newPass, formik.values.confirmPassword, userData.organizationId)
            .then(res => {
                if (res.data.succeeded) {
                    setIsConfirmReset(true);
                    AppToaster('Password updated successfully.', 'success')
                }
                else {
                    setIsLoadingLocal(false);
                    AppToaster("Invalid Username or Organization", 'error')
                }
            })
            .catch(error => {
                setIsLoadingLocal(false);
                //alert(error)
                AppToaster("Invalid Username or Organization", 'error')
            })
    }
    return (
        <>
            <p>
                Enter OTP & reset with new password
            </p>

            <div>
                <fieldset>
                    <label class="block clearfix">
                        <span class="block input-icon input-icon-right">
                            <div class="form-control" >{userData.organizationId}</div>
                            <i class="ace-icon fa fa-university"></i>
                        </span>
                    </label>

                    <label class="block clearfix">
                        <span class="block input-icon input-icon-right">
                            <div class="form-control" >{userData.userName}</div>
                            <i class="ace-icon fa fa-user"></i>
                        </span>
                    </label>

                    <label class="block clearfix">
                        <span class="block input-icon input-icon-right">
                            <input type="password" class="form-control" placeholder="OTP" name="otp" {...formik.getFieldProps("otp")} />
                            <i class="ace-icon fa fa-user"></i>
                        </span>
                        {
                            formik.touched.otp &&
                                formik.errors.otp ? (
                                <label className="text-danger" >
                                    {formik.errors.otp}
                                </label>
                            ) : null
                        }
                    </label>
                    <label class="block clearfix">
                        <span class="block input-icon input-icon-right">
                            <input type="password" class="form-control" placeholder="New Password" name="newPass" {...formik.getFieldProps("newPass")} />
                            <i class="ace-icon fa fa-user"></i>
                        </span>
                        {
                            formik.touched.newPass &&
                                formik.errors.newPass ? (
                                <label className="text-danger" >
                                    {formik.errors.newPass}
                                </label>
                            ) : null
                        }
                    </label>
                    <label class="block clearfix">
                        <span class="block input-icon input-icon-right">
                            <input type="password" class="form-control" placeholder="Confirm Password" name="confirmPassword" {...formik.getFieldProps("confirmPassword")} />
                            <i class="ace-icon fa fa-user"></i>
                        </span>
                        {
                            formik.touched.confirmPassword &&
                                formik.errors.confirmPassword ? (
                                <label className="text-danger" >
                                    {formik.errors.confirmPassword}
                                </label>
                            ) : null
                        }
                    </label>
                    <div class="clearfix">
                        {isLoadingLocal ?
                            <button disabled class="width-35 pull-right btn btn-sm btn-danger">
                                <i class="ace-icon fa fa-lightbulb-o"></i>
                                <span class="bigger-110"> Wait...</span>
                            </button>
                            :
                            <button onClick={ResetPass} disabled={!formik.isValid} class="width-35 pull-right btn btn-sm btn-danger">
                                <i class="ace-icon fa fa-lightbulb-o"></i>
                                <span class="bigger-110"> Reset</span>
                            </button>
                        }
                    </div>
                </fieldset>
            </div>
        </>
    )
}
