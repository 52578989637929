import React, { useContext, useEffect, useState } from 'react'
import * as Yup from "yup";
import AppToaster from './../../../commonLib/AppToaster';
import { useFormik } from 'formik';
import *as bankCrud from './../../../_crud/cheque/bankCrud'
import *as bankTransactionCrud from './../../../_crud/cheque/bankTransactionCrud'
import { useHistory } from 'react-router-dom';
import { Container } from './../../../base/Container';
import { loadingMiniContext } from './../../../commonLib/lodingMiniContext';
import { LoadingButton } from './../../../commonLib/LoadingButton';
import { ContactSelectorView } from '../../Global/SelectorView/ContactSelectorView';
import ContactSelector from '../../Global/ContactSelector';
import { GetEChequeStatus } from '../../../enumCollections/EChequeStatus';
import DatePicker from "react-datepicker";
import GetCurrentDateForSearch from '../../../commonLib/dateFormat';
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater';

const initialValues = {
    ContactId: "",
    BankId: "",
    ChequeNo: "",
    ChequeDate: new Date(GetCurrentDateForSearch(0)),
    ChequeBankName: "",
    ChequeCity: "",
    ChequeGivenByName: "",
    ChequeAmount: 0,
    Status: 0,
    IsReplacedTransaction: 0,
    ReplacedTransactonId: "",
    Note: "",
}

export function AddTransaction() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [bank, setBank] = useState([])
    const [selectedContactData, setSelectedContactData] = useState(undefined);
    const history = useHistory()

    const Schema = Yup.object().shape({
        ContactId: Yup.string().required("Contact is required."),
        BankId: Yup.string().required("Bank is required."),
        ChequeNo: Yup.string().required("Cheque/Transaction No is required."),
        ChequeDate: Yup.string().required("Cheque/Transaction Date is required."),
        ChequeBankName: Yup.string().required("Cheque Bank Name is required."),
        ChequeCity: Yup.string().required("Cheque/Transaction City is required."),
        ChequeGivenByName: Yup.string().required("Cheque/Transaction Given By is required."),
        ChequeAmount: Yup.string().required("Cheque/Transaction Amount is required."),
        Status: Yup.string().required("Status is required."),
        IsReplacedTransaction: Yup.string().required("Is Replaced Transaction is required."),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    useEffect(() => {
        getBank()
    }, []);

    useEffect(() => {
        if (selectedContactData) {
            formik.setValues({ ...formik.values, ContactId: selectedContactData.id })
        }

    }, [selectedContactData]);

    function getBank() {
        setIsLoadingMini(true);
        bankCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setBank(hdata);
                    }
                    else {
                        setBank([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setBank([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }
    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        bankTransactionCrud.Add(value)
            .then(res => {
                if (res.data.result.succeeded) {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster('Added successfully.', 'success')
                    history.goBack()
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }


    return (
        <>
            <Container title="Add New Transaction" isBack="true">
                <form onSubmit={formik.handleSubmit} class="form-horizontal">
                    <div className="row">

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Cheque Date</label>
                                    <span class="input-icon" style={{ width: "100%" }}>
                                        <DatePicker placeholder="Cheque Date" name="ChequeDate" className="form-control input-date-padding" selected={formik.values.ChequeDate} onChange={(date) => formik.setValues({ ...formik.values, ChequeDate: date })} />
                                        <i class="ace-icon fa fa-calendar blue"></i>
                                    </span>
                                    {
                                        formik.touched.ChequeDate &&
                                            formik.errors.ChequeDate ? (
                                            <label className="text-danger" >
                                                {formik.errors.ChequeDate}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Contact</label>
                                    <ContactSelector searchtext="" setSelectedData={setSelectedContactData} />
                                    {
                                        formik.touched.ContactId &&
                                            formik.errors.ContactId ? (
                                            <label className="text-danger" >
                                                {formik.errors.ContactId}
                                            </label>
                                        ) : null
                                    }
                                </div>
                                <ContactSelectorView data={selectedContactData} />
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">For Bank</label>
                                    <select className="form-control" name="Bank Name" value={formik.values.BankId} onChange={(e) => formik.setValues({ ...formik.values, BankId: e.target.value })}>
                                        <option value="" >Select...</option>
                                        {
                                            bank.map((item, index) =>
                                            <option value={item.id}>{item.accountHolderName} ({item.bankName}-{item.accountNo})</option>
                                            )
                                        }
                                    </select>
                                    {
                                        formik.touched.BankId &&
                                            formik.errors.BankId ? (
                                            <label className="text-danger" >
                                                {formik.errors.BankId}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right"> Cheque/Transaction No</label>
                                    <input className="form-control" type="text" placeholder="Cheque/Transaction No" name="ChequeNo" {...formik.getFieldProps("ChequeNo")} />
                                    {
                                        formik.touched.ChequeNo &&
                                            formik.errors.ChequeNo ? (
                                            <label className="text-danger" >
                                                {formik.errors.ChequeNo}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right"> Cheque/Transaction Bank</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Cheque/Transaction Name" name="ChequeBankName" value={formik.values.ChequeBankName} onChange={(e) => formik.setValues({ ...formik.values, ChequeBankName: e.target.value })} />
                                    {
                                        formik.touched.ChequeBankName &&
                                            formik.errors.ChequeBankName ? (
                                            <label className="text-danger" >
                                                {formik.errors.ChequeBankName}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Cheque/Transaction City</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Cheque/Transaction City" name="ChequeCity" value={formik.values.ChequeCity} onChange={(e) => formik.setValues({ ...formik.values, ChequeCity: e.target.value })} />
                                    {
                                        formik.touched.ChequeCity &&
                                            formik.errors.ChequeCity ? (
                                            <label className="text-danger" >
                                                {formik.errors.ChequeCity}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right"> Cheque/Transaction Given By</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Cheque/Transaction Given By" name="ChequeGivenByName" value={formik.values.ChequeGivenByName} onChange={(e) => formik.setValues({ ...formik.values, ChequeGivenByName: e.target.value })} />
                                    {
                                        formik.touched.ChequeGivenByName &&
                                            formik.errors.ChequeGivenByName ? (
                                            <label className="text-danger" >
                                                {formik.errors.ChequeGivenByName}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right"> Cheque/Transaction Amount</label>
                                    <input className="form-control" type="number" placeholder="Cheque/Transaction Amount" name="ChequeAmount" {...formik.getFieldProps("ChequeAmount")} />
                                    {
                                        formik.touched.ChequeAmount &&
                                            formik.errors.ChequeAmount ? (
                                            <label className="text-danger" >
                                                {formik.errors.ChequeAmount}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Status</label>
                                    <select className="form-control" name="Status" value={formik.values.Status} onChange={(e) => formik.setValues({ ...formik.values, Status: e.target.value })}>
                                        <option value="" >Select...</option>
                                        {
                                            GetEChequeStatus.EChequeStatus.map((data, index) =>
                                                <option key={data.Id} value={data.Id}>{data.Name}</option>
                                            )
                                        }
                                    </select>
                                    {
                                        formik.touched.Status &&
                                            formik.errors.Status ? (
                                            <label className="text-danger" >
                                                {formik.errors.Status}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Note</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Note" name="Note" value={formik.values.Note} onChange={(e) => formik.setValues({ ...formik.values, Note: e.target.value })} />
                                    {
                                        formik.touched.Note &&
                                            formik.errors.Note ? (
                                            <label className="text-danger" >
                                                {formik.errors.Note}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="clearfix form-actions">
                        <div class="pull-right">
                            {isLoadingLocal ?
                                <LoadingButton />
                                :
                                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Add
                                </button>}
                        </div>
                    </div>

                </form>
            </Container >
        </>
    )
}
