const GetEProcessingModeName = (id) => {
    switch (id) {
        case 1: { return "Production Unit" }
        case 2: { return "Individual" }
    }
}

const GetEProcessingMode = {
    EProcessingMode: [
        { Id: 1, Name: "Production Unit" },
        { Id: 2, Name: "Individual" },
    ]
}

export { GetEProcessingModeName, GetEProcessingMode }