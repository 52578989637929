const GetELedgerAssetSalaryName = (id) => {
    switch (id) {
        case 6: { return "Cash" }
        case 9: { return "Advance" }
        case 45: { return "Penalty & Defect" }
        case 44: { return "Bank" }
        case 41: { return "Settle" }
    }
}

const GetELedgerAssetSalary = {
    ELedgerAssetSalary: [
        { Id: 6, Name: "Cash" },
        { Id: 44, Name: "Bank" },
        { Id: 9, Name: "Advance" },
        { Id: 45, Name: "Penalty & Defect" }
    ]
}

export { GetELedgerAssetSalary, GetELedgerAssetSalaryName }