import React, { useContext, useEffect, useState } from 'react'
import { EmptyData } from './../../../commonLib/EmptyData';
import { MyAlert } from './../../../commonLib/MyAlert';
import { PagingEmpty } from './../../../commonLib/PagingEmpty'
import * as productionConfigurationCrud from './../../../_crud/productionConfigurationCrud'
import AppToaster from './../../../commonLib/AppToaster';
import { UserView } from './../../Global/Elements/UserView';
import { loadingMiniContext } from './../../../commonLib/lodingMiniContext';
import { useParams } from 'react-router-dom';
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater';

export function ProductionConfigurationItemView({ configuration, configurationList, doRefresh }) {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [empty, setEmpty] = useState(false);
    const { id } = useParams()

    const initItems = {
        productionConfigurationId: id,
        serialNo: "",
        innerColor: undefined,
        outerColor: "",
        producedColor: "",
        optionalOuterColor: ""
    }
    const [configurationItem, setConfigurationItem] = useState(initItems);

    useEffect(() => {
        setEmpty(false)
    }, [empty]);

    const AddItem = () => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        productionConfigurationCrud.AddProductionConfigurationList(configurationItem)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster('Added successfully.', 'success')
                    setEmpty(true)
                    doRefresh()
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        productionConfigurationCrud.RemoveProductionConfigurationList(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    doRefresh()
                    AppToaster('Deleted successfully.', 'success')
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by someone,could not delete.', 'error')
                }
            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }
    return (
        <>
            <div className="widget-box">
                <div class="widget-header widget-header-flat widget-header-small">
                    <h5 class="widget-title">
                        Details
                    </h5>

                </div>
                {
                    configuration &&
                    <div className="row">
                        <div class="profile-user-info profile-user-info-striped">
                            <div class="profile-info-row">
                                <div class="profile-info-name"> Configuration Id</div>

                                <div class="profile-info-value">
                                    <span ><b>{configuration.id}</b></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Name</div>

                                <div class="profile-info-value">
                                    <span ><b>{configuration.name}</b></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name">Description </div>

                                <div class="profile-info-value">
                                    <span>{configuration.description}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Created By </div>

                                <div class="profile-info-value">
                                    <span><UserView item={configuration.createdByUser} uid={"GD1"} createdOn={configuration.createdOn} /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>

            <br />
            <div className="widget-box">
                <div class="widget-header widget-header-flat widget-header-small">
                    <h5 class="widget-title">
                        Configuration List
                    </h5>
                </div>

                <>
                    <div className="row" >
                        <div class="col-xs-12">
                            <div className="col-md-2">
                                <div class="form-group">
                                    <label class="control-label no-padding-right"> Serial No</label>
                                    <input className="form-control" type="number" placeholder="Serial No" value={configurationItem.serialNo} onChange={(e) => setConfigurationItem({ ...configurationItem, serialNo: e.target.value })} />
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div class="form-group">
                                    <label class="control-label no-padding-right"> Inner Color </label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Inner Color" value={configurationItem.innerColor} onChange={(e) => setConfigurationItem({ ...configurationItem, innerColor: e.target.value })} />
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div class="form-group">
                                    <label class="ontrol-label no-padding-right">Outer Color</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Outer Color" value={configurationItem.outerColor} onChange={(e) => setConfigurationItem({ ...configurationItem, outerColor: e.target.value })} />

                                </div>
                            </div>
                            <div className="col-md-2">
                                <div class="form-group">
                                    <label class="control-label no-padding-right">Produced Color </label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Produced Color" value={configurationItem.producedColor} onChange={(e) => setConfigurationItem({ ...configurationItem, producedColor: e.target.value })} />
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div class="form-group">
                                    <label class="ontrol-label no-padding-right">Optional Outer Color</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Optional Outer Color" value={configurationItem.optionalOuterColor} onChange={(e) => setConfigurationItem({ ...configurationItem, optionalOuterColor: e.target.value })} />

                                </div>
                            </div>

                            <div className="col-md-2">
                                <div class="form-group">
                                    <label class="control-label no-padding-right">&nbsp;</label>
                                    <div class="col-xs-12">
                                        {isLoadingLocal ?
                                            <button class="btn btn-xs btn-success" type="submit" expand="block" disabled>
                                                <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i> Wait...
                                            </button>
                                            :
                                            <button class="btn btn-xs btn-success" type="submit" expand="block" onClick={AddItem}>
                                                <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i> Add
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                </>

                <div className="row">
                    <div className="col-xs-12">
                        <PagingEmpty text={"Total " + configurationList.length + " items."}>
                            {configurationList && configurationList.length > 0 ?
                                <div className="tables-scroll">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                {/* <th>#</th> */}
                                                <th>Serial No</th>
                                                <th>Inner Color</th>
                                                <th>Outer Color</th>
                                                <th>Produced Color</th>
                                                <th>Optional Outer Color</th>
                                                {/* <th style={{ width: "43px" }}>By</th> */}
                                                <th>Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {configurationList.map((item, index) =>
                                                <tr key={item.id}>
                                                    {/* <td>{index + 1}</td> */}
                                                    <td>{item.serialNo}</td>
                                                    <td>{item.innerColor} </td>
                                                    <td>{item.outerColor}</td>
                                                    <td>{item.producedColor}</td>
                                                    <td>{item.optionalOuterColor}</td>
                                                    {/* <td>
                                                        <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                    </td> */}
                                                    <td>
                                                        <div class="btn-group">
                                                            <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                                <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                            </MyAlert>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>

                                :
                                <EmptyData>
                                    No Item Added.
                                    <br />
                                    <br />
                                </EmptyData>
                            }
                        </PagingEmpty>
                    </div>
                </div>
            </div>
        </>
    )
}
