import React from 'react'
import ReactTooltip from 'react-tooltip'
import { DEFAULT_IMG, DEFAULT_USER_IMG, ERROR_IMG } from '../../../config/GlobalSettings'
import { BASE_URL } from '../../../initApi/apiUrls'

export function ProductViewS({ item }) {
    return (
        item &&
        <>
            {item.productName} ({item.productCategory.catName})
        </>
    )
}
