import React, { useContext, useEffect, useState } from 'react'
import GetLoginInfo from '../auth/_userContext/GetLoginInfo'
import { BASE_URL } from '../initApi/apiUrls';
import { organizationContext } from '../pages/Organization/organizationContext';
import Moment from 'react-moment';
import { ERROR_IMG } from '../config/GlobalSettings';
import { ContactSearch } from '../pages/Global/ContactSearch';
import { diskSizeContext } from '../pages/Global/Elements/diskSizeContext';
import * as fiscalYearClosingCrud from './../_crud/fiscalYearClosingCrud'
import { ADMINISTRATOR } from '../auth/RouteRoles';
import { Link } from 'react-router-dom';
import { GlobalSpinner } from '../commonLib/GlobalSpinner';
import { OrderSearch } from '../pages/Global/OrderSearch';

export function Breadcrumbs() {
    const [organization, setOrganization] = useContext(organizationContext);
    const [diskSize, setDiskSize] = useContext(diskSizeContext);
    const [isClosing, setIsClosing] = useState(false);

    const userData = GetLoginInfo()

    useEffect(() => {
        if (userData.role == ADMINISTRATOR)
            getIsClosing()
    }, [])
    function getIsClosing() {
        fiscalYearClosingCrud.IsReadyForClosing()
            .then(res => {
                if (res) {
                    setIsClosing(res.data)
                }
                else {
                    setIsClosing(false)
                }
            })
            .catch(error => {
            })
    }
    return (
        <>
            {organization && organization.remainingDaysExpire <= 7 &&
                < p class="alert alert-danger" style={{ margin: "0" }}>
                    <b>EXPIRING:</b> Your plan has been valid till <b><Moment format="DD-MMM-YYYY">{organization.validTillDate}</Moment></b>, only <b>{Number((organization.remainingDaysExpire < 0 ? 0 : organization.remainingDaysExpire)).toFixed(0)}</b> days are remaining to expire, click to visit recharge center <Link to="/rechargeplans">Recharge Now</Link>..
                </p>
            }
            {organization && diskSize && diskSize.totalSize >= (organization.allotedDiskSizeMB - 100) &&
                < p class="alert alert-danger" style={{ margin: "0" }}>
                    <b>Warning:</b> Your disk space is running <b>low</b>, you are using <b>{Number(diskSize.totalSize).toFixed(0)} MB</b>, Alloated size is <b>{Number(organization.allotedDiskSizeMB).toFixed(0)} MB</b>, available space is <b>{Number(organization.allotedDiskSizeMB - diskSize.totalSize).toFixed(2)}</b>, click to visit recharge center <Link to="/rechargeplans">Recharge Now</Link>.
                </p>
            }
            {isClosing &&
                < p class="alert alert-warning" style={{ margin: "0" }}>
                    <b>Warning:</b> Alert for fiscal year closing, please close fiscal year between 21 March to 31 March. <Link to="/fiscalyearclosing/add">Click to close Fiscal Year</Link>
                </p>
            }
            <div class="breadcrumbs ace-save-state" id="breadcrumbs">
                <ul class="breadcrumb">
                    <li>
                        <span className="blue"><i class="ace-icon fa fa-star"></i>&nbsp;&nbsp;{organization && (organization.name.toUpperCase())} <GlobalSpinner color="#438EB9" /></span>
                    </li>
                </ul>
                <OrderSearch />
            </div>
        </>
    )
}
