import React, { useContext } from 'react'
import { organizationContext } from '../Organization/organizationContext';
import QRCode from "react-qr-code";
import Moment from 'react-moment';
import { ERROR_IMG } from '../../config/GlobalSettings';
import { BASE_URL } from '../../initApi/apiUrls';

export function PrintBranding({ printName, date, id, ...props }) {
    const [organization, setOrganization] = useContext(organizationContext);
    return (
        <div>
            <div class="row" style={{ fontSize: "10px" }}>
                <div class="col-sm-10 col-sm-offset-1">
                    <div class="widget-box transparent">
                        <div style={{ borderBottom: "1px solid #807878" }} class="widget-header widget-header-large">
                            {(organization && organization.isLogo == 1) ?
                                <>
                                    <img height="30px" src={BASE_URL + "/" + organization.logoUrl} onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
                                </>
                                :
                                <h3 class="widget-title">
                                    {organization && organization.name}
                                </h3>
                            }

                            <div class="widget-toolbar no-border invoice-info">
                                <span class="invoice-info-label">Doc No:&nbsp;</span>
                                <span>#{id}</span>

                                <br />
                                <span class="invoice-info-label">Date:&nbsp;</span>
                                <span>
                                    <Moment format="DD-MMM-YYYY">
                                        {date}
                                    </Moment></span>
                            </div>

                            <div class="widget-toolbar hidden-480">
                                <a>

                                </a>
                            </div>
                        </div>
                        {organization &&
                            <div class="widget-body">
                                <div class="row">
                                    <div class="col-xs-9">
                                        <div>
                                            <ul class="list-unstyled">
                                                {(organization && organization.isLogo == 1) &&
                                                    <li>
                                                        &nbsp;&nbsp;{organization.name}
                                                    </li>
                                                }
                                                <li>
                                                    &nbsp;&nbsp;{organization.address}, {organization.city} - {organization.pin}, {organization.state}
                                                </li>

                                                <li>
                                                    &nbsp;&nbsp;Phone: {organization.phone} &nbsp;&nbsp;Email: {organization.email}
                                                </li>

                                                {organization.gstn &&
                                                    <li>
                                                        &nbsp;&nbsp;GSTN: {organization.gstn}
                                                    </li>
                                                }

                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-xs-3">
                                        <div className="widget style1 pull-right text-center">
                                            <br />
                                            <QRCode size="50" value={id} />
                                        </div>
                                    </div>
                                </div>
                                <h6 className="text-center bolder">{printName}</h6>

                                {props.children}

                            </div>
                        }
                        <br />
                        <>
                            <div class="row">
                                <div class="col-xs-6">
                                    Signature ________________________
                                </div>
                                <div class="col-xs-6" style={{ textAlign: "end" }}>
                                    <div class="footer-inner">
                                        <div class="footer-content">
                                            <p>
                                                <span style={{ verticalAlign: "bottom" }}>Powered&nbsp;By </span>
                                                <img style={{ marginBottom: "3px" }} height="18px" src="images/logo-small.png" />
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            </div>
        </div>
    )
}
