import React, { useContext, useEffect, useState } from 'react'
import * as Yup from "yup";
import AppToaster from './../../../commonLib/AppToaster';
import { useFormik } from 'formik';
import *as bankCrud from './../../../_crud/cheque/bankCrud'
import *as locStatesCrud from './../../../_crud/locStatesCrud'
import { useHistory } from 'react-router-dom';
import { Container } from './../../../base/Container';
import { loadingMiniContext } from './../../../commonLib/lodingMiniContext';
import { LoadingButton } from './../../../commonLib/LoadingButton';
import { GetEAccountType } from './../../../enumCollections/EAccountType';
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater';

const initialValues = {
    BankName: "",
    Branch: "",
    City: "",
    State: "",
    Address: "",
    IFSCCode: "",
    AccountNo: "",
    AccountType: 1,
    AccountHolderName: ""
}

export function AddBank() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [locStates, setLocStates] = useState([])
    const history = useHistory()

    const Schema = Yup.object().shape({
        BankName: Yup.string().required("Bank Name is required."),
        Branch: Yup.string().required("Branch is required."),
        City: Yup.string().required("City is required."),
        State: Yup.string().required("State is required."),
        Address: Yup.string().required("Address is required."),
        IFSCCode: Yup.string().required("IFSC Code is required."),
        AccountNo: Yup.string().required("Account No is required."),
        AccountType: Yup.string().required("Account Type is required."),
        AccountHolderName: Yup.string().required("Account Holder Name is required.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    useEffect(() => {
        getLocStates()
    }, []);

    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        bankCrud.Add(value)
            .then(res => {
                if (res.data.result.succeeded) {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster('Added successfully.', 'success')
                    history.goBack()
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    function getLocStates() {
        setIsLoadingMini(true);
        locStatesCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setLocStates(hdata);
                    }
                    else {
                        setLocStates([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setLocStates([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    return (
        <>
            <Container title="Add New Bank Account" isBack="true">
                <form onSubmit={formik.handleSubmit} class="form-horizontal">
                    <div className="row">
                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Bank Name</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Bank Name" name="BankName" value={formik.values.BankName} onChange={(e) => formik.setValues({ ...formik.values, BankName: e.target.value })} />
                                    {
                                        formik.touched.BankName &&
                                            formik.errors.BankName ? (
                                            <label className="text-danger" >
                                                {formik.errors.BankName}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Branch</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Branch" name="Branch" value={formik.values.Branch} onChange={(e) => formik.setValues({ ...formik.values, Branch: e.target.value })} />
                                    {
                                        formik.touched.Branch &&
                                            formik.errors.Branch ? (
                                            <label className="text-danger" >
                                                {formik.errors.Branch}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right"> IFSC Code</label>
                                    <input className="form-control" type="text" placeholder="IFSC Code" name="IFSCCode" {...formik.getFieldProps("IFSCCode")} />
                                    {
                                        formik.touched.IFSCCode &&
                                            formik.errors.IFSCCode ? (
                                            <label className="text-danger" >
                                                {formik.errors.IFSCCode}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">City</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="City" name="City" value={formik.values.City} onChange={(e) => formik.setValues({ ...formik.values, City: e.target.value })} />
                                    {
                                        formik.touched.City &&
                                            formik.errors.City ? (
                                            <label className="text-danger" >
                                                {formik.errors.City}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right"> State</label>
                                    <select className="form-control" name="State" value={formik.values.State} onChange={(e) => formik.setValues({ ...formik.values, State: e.target.value })}>
                                        <option value="" >Select...</option>
                                        {
                                            locStates.map((data, index) =>
                                                <option key={data.id} value={data.name}>{data.name}</option>
                                            )
                                        }
                                    </select>
                                    {
                                        formik.touched.State &&
                                            formik.errors.State ? (
                                            <label className="text-danger" >
                                                {formik.errors.State}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Address</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Address" name="Address" value={formik.values.Address} onChange={(e) => formik.setValues({ ...formik.values, Address: e.target.value })} />
                                    {
                                        formik.touched.Address &&
                                            formik.errors.Address ? (
                                            <label className="text-danger" >
                                                {formik.errors.Address}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right"> Account Holder Name</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Account Holder Name" name="AccountHolderName" value={formik.values.AccountHolderName} onChange={(e) => formik.setValues({ ...formik.values, AccountHolderName: e.target.value })} />
                                    {
                                        formik.touched.AccountHolderName &&
                                            formik.errors.AccountHolderName ? (
                                            <label className="text-danger" >
                                                {formik.errors.AccountHolderName}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right"> Account No</label>
                                    <input className="form-control" type="text" placeholder="Account No" name="AccountNo" {...formik.getFieldProps("AccountNo")} />
                                    {
                                        formik.touched.AccountNo &&
                                            formik.errors.AccountNo ? (
                                            <label className="text-danger" >
                                                {formik.errors.AccountNo}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Account Type</label>
                                    <select className="form-control" name="AccountType" value={formik.values.AccountType} onChange={(e) => formik.setValues({ ...formik.values, AccountType: e.target.value })}>
                                        {
                                            GetEAccountType.EAccountType.map((data, index) =>
                                                <option key={data.Id} value={data.Id}>{data.Name}</option>
                                            )
                                        }
                                    </select>
                                    {
                                        formik.touched.AccountType &&
                                            formik.errors.AccountType ? (
                                            <label className="text-danger" >
                                                {formik.errors.AccountType}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="clearfix form-actions">
                        <div class="pull-right">
                            {isLoadingLocal ?
                                <LoadingButton />
                                :
                                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Add
                                </button>}
                        </div>
                    </div>

                </form>
            </Container >
        </>
    )
}
