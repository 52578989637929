import React, { useContext, useState, useEffect } from 'react'
import AppToaster from '../../commonLib/AppToaster';
import { ImageUploader } from '../../commonLib/ImageUploader';
import { LoadingButton } from '../../commonLib/LoadingButton';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { BASE_URL } from '../../initApi/apiUrls';
import * as organizationCrud from './../../_crud/organizationCrud';
import { Spinner } from '../../commonLib/Spinner';
import { organizationContext } from './organizationContext';

export function OrganizationLogo() {
  const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
  const [isLoadingLocal, setIsLoadingLocal] = useState(false)
  const [organization, setOrganization] = useContext(organizationContext);

  const [photo, setPhoto] = useState("")

  const SetPhoto = (image) => {
    setPhoto(image)
  }
  const SavePhoto = () => {
    setIsLoadingMini(true);
    setIsLoadingLocal(true);
    const formData = new FormData()

    formData.append('id', "NA")
    formData.append('file', photo)
    organizationCrud.UploadLogo(formData)
      .then(res => {
        if (res.data.succeeded) {
          setIsLoadingMini(false);
          setIsLoadingLocal(false);
          AppToaster('Updated successfully.', 'success')
          window.location.replace("/");
        }
        else {
          setIsLoadingMini(false)
          setIsLoadingLocal(false);
          AppToaster("Network Error.", 'error')
        }

      })
      .catch(error => {
        alert(error)
        setIsLoadingMini(false);
        setIsLoadingLocal(false);
        AppToaster(error, 'error')
      })
  }
  return (
    organization ?
      <>
        <div className="row">
          <div className="col-md-4">
          </div>
          <div className="col-md-4">
            <div class="form-group">
              <div class="col-xs-12">
                <label class="control-label no-padding-right"> Upload Logo</label>
                <ImageUploader initImage={organization && organization.isLogo == 1 ? (BASE_URL + "/" + organization.logoUrl) : undefined} SetImage={SetPhoto} />
              </div>
            </div>
          </div>
        </div>

        <div class="clearfix form-actions">
          <div class="pull-right">
            {isLoadingLocal ?
              <LoadingButton />
              :
              <button class="btn btn-info" type="submit" expand="block" onClick={SavePhoto} disabled={!photo}>
                <i class="ace-icon fa fa-check bigger-110"></i>
                Upload
              </button>}
          </div>
        </div>
      </>
      :
      <Spinner />
  )
}
