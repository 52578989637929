import React, { useContext, useEffect, useState } from 'react'
import Moment from 'react-moment';
import { Link, useHistory, useParams } from 'react-router-dom';
import AppToaster from '../../../../commonLib/AppToaster';
import { EmptyData } from '../../../../commonLib/EmptyData';
import { loadingMiniContext } from '../../../../commonLib/lodingMiniContext';
import { Spinner } from '../../../../commonLib/Spinner';
import { GetEBoolName } from '../../../../enumCollections/EBool';
import { ProcessingView } from '../../../Global/Elements/ProcessingView';
import { ProductionUnitView } from '../../../Global/Elements/ProductionUnitView';
import { ProductView } from '../../../Global/Elements/ProductView';
import { UserView } from '../../../Global/Elements/UserView';
import DatePicker from "react-datepicker";
import * as contactProcessingAllotmentCrud from '../../../../_crud/contactProcessingAllotmentCrud'
import * as processingWageVoucherCrud from '../../../../_crud/vouchers/processingWageVoucherCrud'
import { LoadingButton } from '../../../../commonLib/LoadingButton';
import { PagingEmpty } from '../../../../commonLib/PagingEmpty';
import { Container } from '../../../../base/Container';
import { useFormik } from 'formik'
import * as Yup from "yup"
import GetCurrentDateForSearch from '../../../../commonLib/dateFormat';
import { TextBoxTranslater } from '../../../../commonLib/TextBoxTranslater';

export function AddContactProcessingVoucher() {
    const history = useHistory();
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [selectedContactData, setSelectedContactData] = useState(undefined)
    const [processing, setProcessing] = useState([])
    const [processingReceiving, setProcessingReceving] = useState()
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const { id } = useParams()

    // const initialValues = {
    //     Voucher: {
    //         linkedId: id,
    //         contactId: id,
    //         voucherType: 3,
    //         linkedIdType: 0,
    //         paymentStatus: 0,
    //         totalAmount: 0,
    //         voucherDate: new Date(GetCurrentDateForSearch(0)),
    //         note: ""
    //     },
    //     processingId: "",
    //     defectiveDeductionPercent: 100
    // }

    const initialValues = {
        linkedId: id,
        contactId: id,
        voucherType: 3,
        linkedIdType: 0,
        paymentStatus: 0,
        totalAmount: 0,
        voucherDate: new Date(GetCurrentDateForSearch(0)),
        note: "",
        processingId: "",
        defectiveDeductionPercent: 0
    }

    const Schema = Yup.object().shape({
        //ledgerId: Yup.string().required("From is required."),
        linkedId: Yup.string().required("To is required."),
        contactId: Yup.string().required("Contact is required."),
        totalAmount: Yup.string().required("Amount is required."),
        voucherDate: Yup.string().required("Voucher Date is required."),
        processingId: Yup.string().required("Processing is required."),
        defectiveDeductionPercent: Yup.string().required("Defective Given Percent is required."),
    })
    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    useEffect(() => {
        getProcessing(id)
    }, []);

    useEffect(() => {
        if (formik.values.contactId && formik.values.processingId) {
            getProcessingReceivingVoucher()
        }
    }, [formik.values.contactId, formik.values.processingId]);

    const Save = (value) => {
        if (processingReceiving && processingReceiving.data.length > 0) {
            setIsLoadingMini(true);
            setIsLoadingLocal(true)
            processingWageVoucherCrud.VoucherAdd({
                Voucher: {
                    linkedId: value.linkedId,
                    contactId: value.contactId,
                    voucherType: value.voucherType,
                    linkedIdType: value.linkedIdType,
                    paymentStatus: value.paymentStatus,
                    totalAmount: value.totalAmount,
                    voucherDate: value.voucherDate,
                    note: value.note
                },
                processingId: value.processingId,
                defectiveDeductionPercent: value.defectiveDeductionPercent
            })
                .then(res => {
                    if (res.data.result.succeeded) {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false);
                        AppToaster('Added successfully.', 'success')
                        history.push("/processingvoucher/dashboard/" + res.data.data.voucher.id)
                    }
                    else {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                })
                .catch(error => {
                    setIsLoadingMini(false);
                    setIsLoadingLocal(false);
                    AppToaster(error, 'error')
                })
        }
        else {
            AppToaster("There are no receivings found to create voucher.", 'error')
        }
    }


    function getProcessing(contactid) {
        setIsLoadingMini(true);
        contactProcessingAllotmentCrud.GetByContactDdl(contactid)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProcessing(hdata);
                    }
                    else {
                        setProcessing([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProcessing([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function getProcessingReceivingVoucher() {
        setIsLoadingMini(true);
        processingWageVoucherCrud.GetRecevingsToVouchered({ contactid: formik.values.contactId, processingid: formik.values.processingId })
            .then(res => {
                if (res.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data
                        setProcessingReceving(hdata);
                    }
                    else {
                        setProcessingReceving();
                        AppToaster(res.data.result.message, 'error')
                    }
                }
                else {
                    setProcessingReceving();
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }


    return (
        <>
            <Container title="Add Processing Voucher" isBack="true" isContact="true">
                <form onSubmit={formik.handleSubmit} class="form-horizontal">
                    <div className="row">
                        <div className="col-md-8">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class=" control-label no-padding-right">Processing</label>
                                    <select className="form-control" name="processingId" value={formik.values.processingId} onChange={(e) => formik.setValues({ ...formik.values, processingId: e.target.value })}>
                                        <option value="" >Select...</option>
                                        {
                                            processing.map((data, index) =>
                                                <option key={data.processing.id} value={data.processing.id}>{data.processing.name.toUpperCase()} ({data.processing.id})</option>
                                            )
                                        }
                                    </select>
                                    {
                                        formik.touched.processingId &&
                                            formik.errors.processingId ? (
                                            <label className="text-danger" >
                                                {formik.errors.processingId}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Voucher Date</label>
                                    <span class="input-icon" style={{ width: "100%" }}>
                                        <DatePicker placeholder="Voucher Date" name="voucherDate" className="form-control input-date-padding" selected={formik.values.voucherDate} onChange={(e) => formik.setValues({ ...formik.values, voucherDate: e})} />
                                        <i class="ace-icon fa fa-calendar blue"></i>
                                    </span>
                                    {
                                        formik.touched.voucherDate &&
                                            formik.errors.voucherDate ? (
                                            <label className="text-danger" >
                                                {formik.errors.voucherDate}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-3">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Defective Given %</label>
                                    <input className="form-control" placeholder="Defective Given %" min="0" max="100" type="number" value={formik.values.defectiveDeductionPercent} onChange={(e) => formik.setValues({ ...formik.values, defectiveDeductionPercent: e.target.value })} />
                                    {
                                        formik.touched.defectiveDeductionPercent &&
                                            formik.errors.defectiveDeductionPercent ? (
                                            <label className="text-danger" >
                                                {formik.errors.defectiveDeductionPercent}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-9">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Note</label>
                                    <TextBoxTranslater className="form-control" placeholder="Note" type="text" value={formik.values.note} onChange={(e) => formik.setValues({ ...formik.values, note: e.target.value })} />
                                    {
                                        formik.touched.note &&
                                            formik.errors.note ? (
                                            <label className="text-danger" >
                                                {formik.errors.note}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="clearfix form-actions">
                        <div class="pull-right">
                            {isLoadingLocal ?
                                <LoadingButton />
                                :
                                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Add
                                </button>}
                        </div>
                    </div>

                </form>

                {
                    processingReceiving &&
                    <>
                        <div className="widget-box">
                            <div class="widget-header widget-header-flat widget-header-small">
                                <h5 class="widget-title">
                                    Recevings for processing vouchers
                                </h5>
                                <div class="widget-toolbar no-border">
                                    <button class="btn btn-minier btn-primary" onClick={getProcessingReceivingVoucher}>
                                        <i class="ace-icon fa fa-refresh"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                    <div class="clearfix">
                                        <div class="grid4">
                                            <span>
                                                <h5 class="bigger green">
                                                    Total Fresh Wage: <b>₹ {Number(processingReceiving.totalWage).toFixed(2)}</b>
                                                </h5>
                                            </span>
                                        </div>
                                        <div class="grid4">
                                            <span>
                                                <h5 class="bigger green">
                                                    Fresh Quantity: <b>{processingReceiving.totalFresh}</b></h5>
                                            </span>
                                        </div>

                                        <div class="grid4">
                                            <span>
                                                <h5 class="bigger orange">
                                                    Defective Quantity: <b>{processingReceiving.totalDefective}</b></h5>
                                            </span>
                                        </div>

                                        <div class="grid4">
                                            <span>
                                                <h5 class="bigger red">
                                                    Rejected Quantity: <b>{processingReceiving.totalRegected}</b></h5>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-xs-12">
                                    <PagingEmpty text={"Total " + processingReceiving.data.length + " items."}>
                                        {isLoadingMini ?
                                            <Spinner />
                                            :
                                            processingReceiving && processingReceiving.data.length > 0 ?
                                                <div className="tables-scroll">
                                                    <table className="table table-hover table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>#Id</th>
                                                                <th>On Date</th>
                                                                <th>Processing</th>
                                                                <th>Product</th>
                                                                <th>Wage/unit</th>
                                                                <th>Fresh</th>
                                                                <th>Defective</th>
                                                                <th>Rejected</th>
                                                                <th>Defective Wage</th>
                                                                <th>Production</th>
                                                                <th>Vouchered</th>
                                                                {/* <th>Payment Status</th> */}
                                                                <th>Note</th>
                                                                <th>By</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {processingReceiving.data.map((item, index) =>
                                                                <tr>
                                                                    <td>{item.id}</td>
                                                                    <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.onDate}</Moment></div></td>
                                                                    <td>
                                                                        <Link to={"/processing/dashboard/" + item.processingId}>
                                                                            <ProcessingView item={item.processing} />
                                                                        </Link>
                                                                    </td>

                                                                    <td>
                                                                        <Link to={"/product/dashboard/" + item.productId}>
                                                                            <ProductView item={item.product} uid={"PP" + index} />
                                                                        </Link>
                                                                    </td>
                                                                    <td>₹ {Number(item.processingWage.wage).toFixed(2)}</td>
                                                                    <td>{item.freshQuantity} {item.unit}</td>
                                                                    <td>{item.defectiveQuantity} {item.unit}</td>
                                                                    <td>{item.rejectedQuantity} {item.unit}</td>
                                                                    <td>{item.defectiveWagePercent}%</td>
                                                                    <td>
                                                                        {item.isProductionUnit == 1 ? (item.productionUnit ? <Link to={"/productionunit/dashboard/" + item.productionUnitId}>
                                                                            <ProductionUnitView item={item.productionUnit} />
                                                                        </Link> : "NA") : <span class="label label-sm label-danger">{GetEBoolName(item.isProductionUnit)}</span>}
                                                                    </td>
                                                                    <td><span class={item.isVouchered == 1 ? "label label-sm label-success" : "label label-sm label-danger"}>{GetEBoolName(item.isVouchered)}</span></td>
                                                                    {/* <td><span class={item.paymentStatus == 1 ? "label label-sm label-warning" : "label label-sm label-info"}>{GetEPaymentStatusName(item.paymentStatus)}</span></td> */}
                                                                    <td><div style={{ width: "150px" }}>{item.note}</div></td>

                                                                    <td>
                                                                        <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                :
                                                <EmptyData>
                                                    No records found.
                                                    <br />
                                                    <br />
                                                </EmptyData>

                                        }
                                        <br />
                                    </PagingEmpty>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </Container >
        </>
    )
}
