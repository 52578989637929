const GetEProductionCategoryTypeName = (id) => {
    switch (id) {
        case 1: { return "Trade" }
        case 2: { return "Machine" }
    }
}

const GetEProductionCategoryType = {
    EProductionCategoryType: [
        { Id: 1, Name: "Trade" },
        { Id: 2, Name: "Machine" },
    ]
}

export { GetEProductionCategoryTypeName, GetEProductionCategoryType }