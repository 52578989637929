import React from 'react'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useState } from 'react';
import AppToaster from '../../commonLib/AppToaster';
import * as applicationUserCrud from '../../_crud/applicationUserCrud';

export function InitForgotPassword({ setUserData, setChangeReady }) {
    const [otpData, setOtpData] = useState({ organizationId: "", userName: "" });
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)

    const initialValues = {
        organizationId: "",
        userName: ""
    }

    const Schema = Yup.object().shape({
        organizationId: Yup.string().required("Organization is required."),
        userName: Yup.string().required("User Name is required."),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            //SendOTP();
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    const SendOTP = () => {
        setIsLoadingLocal(true);
        applicationUserCrud.ForgotPasswordInitiate(formik.values.userName, formik.values.organizationId)
            .then(res => {
                if (res.data.result.succeeded) {
                    setUserData({ userName: res.data.userName, organizationId: formik.values.organizationId })
                    setChangeReady(true);
                    AppToaster('OTP sent on your mobile successfully.', 'success')
                }
                else {
                    setIsLoadingLocal(false);
                    AppToaster("Invalid Username or Organization", 'error')
                }
            })
            .catch(error => {
                setIsLoadingLocal(false);
                //alert(error)
                AppToaster("Invalid Username or Organization", 'error')
            })
    }
    return (
        <>
            <p>
                Enter your Organizaion & User to receive instructions
            </p>

            <div>
                <fieldset>
                    <label class="block clearfix">
                        <span class="block input-icon input-icon-right">
                            <input type="text" class="form-control" placeholder="Orgainzation Id" name="organizationId" {...formik.getFieldProps("organizationId")} />
                            <i class="ace-icon fa fa-university"></i>
                        </span>
                        {
                            formik.touched.organizationId &&
                                formik.errors.organizationId ? (
                                <label className="text-danger" >
                                    {formik.errors.organizationId}
                                </label>
                            ) : null
                        }
                    </label>

                    <label class="block clearfix">
                        <span class="block input-icon input-icon-right">
                            <input type="text" class="form-control" placeholder="Username" name="userName" {...formik.getFieldProps("userName")} />
                            <i class="ace-icon fa fa-user"></i>
                        </span>
                        {
                            formik.touched.userName &&
                                formik.errors.userName ? (
                                <label className="text-danger" >
                                    {formik.errors.userName}
                                </label>
                            ) : null
                        }
                    </label>
                    <p>
                        <small>Note: OTP will be send on registered mobile no for given user.</small>
                    </p>
                    <div class="clearfix">
                        {isLoadingLocal ?
                            <button disabled class="width-35 pull-right btn btn-sm btn-danger">
                                <i class="ace-icon fa fa-lightbulb-o"></i>
                                <span class="bigger-110"> Wait...</span>
                            </button>
                            :
                            <button onClick={SendOTP} disabled={!formik.isValid} class="width-35 pull-right btn btn-sm btn-danger">
                                <i class="ace-icon fa fa-lightbulb-o"></i>
                                <span class="bigger-110"> Send OTP!</span>
                            </button>
                        }
                    </div>
                </fieldset>
            </div>
        </>
    )
}
