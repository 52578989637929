import React from 'react'
import { ERROR_IMG, getPageMargins } from '../../config/GlobalSettings'
import { PrintBranding } from './PrintBranding'
import { date } from 'yup'
import { ContactViewS } from '../Global/Elements/ContactViewS'
import { ProductionUnitViewS } from '../Global/Elements/ProductionUnitViewS'
import { EmptyData } from '../../commonLib/EmptyData'
import { BASE_URL } from '../../initApi/apiUrls'
import { FindFileExtention } from '../../commonLib/FindFileExtention'
import { ProductViewS } from '../Global/Elements/ProductViewS'

export const ProducrDesignPrint = React.forwardRef((props, ref) => {
    const { design, designItems } = props
    return (
        <div ref={ref}>
            <style type="text/css">
                {getPageMargins()}
            </style>
            {
                design &&
                <PrintBranding printName="Product Design (Bewara)" id={design.id} date={new date()}>
                    <div className="row">
                        <div className="col-xs-12">
                            <table style={{ width: "100%" }} className="table-bordered-dark">
                                <tbody>
                                    <tr >
                                        <td>
                                            Design (Bewara) Name
                                        </td>
                                        <td>
                                            <b>{design.name}</b>
                                        </td>
                                    </tr>
                                    <tr >
                                        <td>
                                            Note
                                        </td>
                                        <td>
                                            {design.note}
                                        </td>
                                    </tr>
                                    <tr >
                                        <td>
                                            Designer (Grapher)
                                        </td>
                                        <td>
                                            {design.designerContact ?
                                                <ContactViewS item={design.designerContact} uid={"CX1X"} />
                                                :
                                                "N/A"
                                            }
                                        </td>
                                    </tr>
                                    <tr >
                                        <td>
                                            Contact Alloted
                                        </td>
                                        <td>
                                            {design.contact ?
                                                <>
                                                    <ContactViewS item={design.contact} uid={"CX2X"} />
                                                    <br />
                                                    <ProductionUnitViewS item={design.productionUnit} />
                                                </>
                                                :
                                                "N/A"
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-xs-12">
                            <h5 className="text-center">Bewara Items</h5>
                            {designItems && designItems.length > 0 ?
                                <table style={{ width: "100%" }} className="table-bordered-dark">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "33px" }}>#Id</th>
                                            <th>Name</th>
                                            <th width="460px">Details</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {designItems.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>{item.serialNo}</td>
                                                <td>{item.name}
                                                    <br />Value: {item.value}
                                                </td>
                                                <td>{item.text}
                                                    {item.product &&
                                                        <>
                                                            <br />
                                                            <small>Ref Product:</small>: <ProductViewS item={item.product} uid={"PPDX" + index} />
                                                        </>
                                                    }
                                                    {item.attachmentUrl &&
                                                        <div style={{ marginTop: "12px" }}>
                                                            {(FindFileExtention(item.attachmentUrl).toLowerCase() == "jpeg" ||
                                                                FindFileExtention(item.attachmentUrl).toLowerCase() == "jpg" ||
                                                                FindFileExtention(item.attachmentUrl).toLowerCase() == "png" ||
                                                                FindFileExtention(item.attachmentUrl).toLowerCase() == "gif") &&
                                                                <img src={BASE_URL + "/" + item.attachmentUrl} width="450px" onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
                                                            }
                                                        </div>
                                                    }
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                :
                                <EmptyData>
                                    No Items
                                    <br />
                                    <br />
                                </EmptyData>
                            }
                        </div>
                    </div>
                </PrintBranding>
            }
        </div >
    )
})
