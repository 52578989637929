import React from 'react'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Container } from '../../../base/Container';


export function CashBankCashVoucherDashboard() {
    const { id } = useParams()
    function doRefresh(event) {
        setTimeout(() => {
            event.target.complete();
        }, 1000);
    }

    return (
        <Container title={"Cash Bank Cash Voucher For Voucher Id " + id + ""} doRefresh={doRefresh} isBack="true">
            <Link to={"/cashbankcashvoucher/dashboard/" + id + "/voucherpayment"} class="btn btn-sm btn-info">
                <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                Voucher Payment
            </Link>
            <div class="hr dotted"></div>
        </Container>
    )
}
