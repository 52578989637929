import React from 'react'

export function ConfirmResetPassword() {
    return (
        <div className="row center">
            <div className="col-lg12">
                <i style={{ fontSize: "100px" }} class="ace-icon bigger fa fa-check-circle blue"></i>
                <h1 className="green">Congratulations</h1>
                <h1>Your password has been reset Successfully!</h1>
                <h4>Visit to login page to login with new password.</h4>
                <br />
                <br />
            </div>
        </div>
    )
}
