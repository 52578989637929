import React, { useContext, useEffect, useState } from 'react'
import Autocomplete from "react-autocomplete";
import './../Global/AutoComplete.css';
import * as productionUnitCrud from '../../_crud/productionUnitCrud'
import useDebounce from './useDebounce';

export default function ProductinUnitSelector(props) {
    const { searchtext, setSelectedData } = props
    const [isLocalLoading, setIsLocalLoading] = useState(false)
    const [searchTerm, setSearchTerm] = useState(searchtext);
    const [searchDebounce, setSearchDebounce] = useDebounce(searchtext, 500);
    const [data, setData] = useState([]);

    useEffect(() => {
        setSearchDebounce(searchTerm)
    }, [searchTerm]);
    useEffect(() => {
        getData()
    }, [searchDebounce]);

    function getData() {
        setIsLocalLoading(true);
        let a;
        a = searchTerm;
        if (!searchTerm) { a = '' }
        productionUnitCrud.GetAll({ searchTerm: a, pageNo: 1, pageSize: 10 })
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setData(hdata);
                        setIsLocalLoading(false)
                    }
                    else {
                        setIsLocalLoading(false)
                        setData([]);
                    }
                }
                else {
                    setIsLocalLoading(false)
                    setData([]);
                }
            })
            .catch(error => {
                setIsLocalLoading(false);
            })
    }

    function selectId(e) {
        setSearchTerm(e.split('|')[0])
        let sid = e.split('|')[1]

        data.forEach(d => {
            if (d.id == sid) {
                setSelectedData(d);
            }
        });
    }
    return (
        <span class="input-icon" style={{ width: "100%" }}>
            <div className="autocomplete-wrapper">
                <Autocomplete
                    inputProps={{ placeholder: "Type to search productionUnit" }}
                    value={searchTerm}
                    items={data}
                    getItemValue={item => item.productionUnitName + "|" + item.id}
                    renderMenu={item => (
                        <div className="dropdown">
                            {item}
                        </div>
                    )}
                    renderItem={(item, isHighlighted) =>
                        <div class={isHighlighted ? "profile-activity clearfix MyItem-selected-item" : "profile-activity clearfix"} style={{ cursor: "pointer" }}>
                            <div>
                                <i class="pull-left thumbicon fa fa-building btn-info no-hover"></i>
                                <a class="user">{item.productionUnitName}</a> ({item.id})
                                <br /><span>{item.productionCategory.productionCatName}</span>
                            </div>
                        </div>
                    }
                    onChange={(event, val) => setSearchTerm(val)}
                    onSelect={val => selectId(val)}
                />
            </div>
            {isLocalLoading ?
                <i class="ace-icon fa fa-spinner fa-spin blue"></i>
                :
                <i class="ace-icon fa fa-building blue"></i>
            }
        </span>
    )
}
