import fx from "glfx";


const resizeImage = (base64Str, width = 400, height = 350) => {
    return new Promise((resolve) => {
        let img = new Image()
        img.src = base64Str
        img.onload = () => {
            let canvas = document.createElement('canvas')
            canvas.width = width
            canvas.height = height
            let ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0, width, height)
            resolve(canvas.toDataURL())
        }
    })
}

const vibrance = (base64Str, level = 0.6) => {
    return new Promise((resolve) => {
        let img = new Image()
        img.crossOrigin = "Anonymous";
        img.src = base64Str
        img.onload = () => {
            var canvas = fx.canvas();
            var texture = canvas.texture(img);
            canvas.draw(texture).vibrance(level).update();
            resolve(canvas.toDataURL())
        }
    })
}
const bulgePinch = (base64Str, centerX = 270, centerY = 460, radius = 148, angle = -0.37) => {
    return new Promise((resolve) => {
        let img = new Image()
        img.crossOrigin = "Anonymous";
        img.src = base64Str
        img.onload = () => {
            var canvas = fx.canvas();
            var texture = canvas.texture(img);
            canvas.draw(texture).bulgePinch(centerX, centerY, radius, angle).update();
            resolve(canvas.toDataURL())
        }
    })
}

const swirl = (base64Str, centerX = 270, centerY = 460, radius = 148, angle = -0.37) => {
    return new Promise((resolve) => {
        let img = new Image()
        img.crossOrigin = "Anonymous";
        img.src = base64Str
        img.onload = () => {
            var canvas = fx.canvas();
            var texture = canvas.texture(img);
            canvas.draw(texture).swirl(centerX, centerY, radius, angle).update();
            resolve(canvas.toDataURL())
        }
    })
}

const flip = (base64Str, width = 400, height = 350) => {
    return new Promise((resolve) => {
        let img = new Image()
        img.src = base64Str
        img.height = height;
        img.width = width;
        img.onload = () => {
            let canvas = document.createElement('canvas')
            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext('2d')
            ctx.clearRect(0, 0, canvas.width, canvas.height)
            ctx.save();
            ctx.scale(-1, 1);
            ctx.drawImage(img, img.width * -1, 0, img.width, img.height);
            ctx.restore();
            resolve(canvas.toDataURL())
        }
    })
}

export { flip, swirl, bulgePinch, vibrance, resizeImage }