import React, { useState } from 'react'

export const loadingMiniContext = React.createContext();

const MiniLoading = (props) => {
    const [isLoadingMini, setIsLoadingMini] = useState(false);
    return (
        <loadingMiniContext.Provider value={[isLoadingMini, setIsLoadingMini]}>
            {props.children}
        </loadingMiniContext.Provider>
    );
}

export default MiniLoading;
