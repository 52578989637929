import React from 'react'
import { useState } from 'react';
import { ConfirmResetPassword } from './ConfirmResetPassword';
import { InitForgotPassword } from './InitForgotPassword';
import { ResetNewPassword } from './ResetNewPassword';

export function ForgotPassword() {
    const [userData, setUserData] = useState({ organizationId: "", userName: "" });
    const [changeReady, setChangeReady] = useState(false)
    const [isConfirmReset, setIsConfirmReset] = useState(false)

    return (
        <>
            <div class="login-layout light-login">
                <div class="main-container">
                    <div class="main-content">
                        <div class="row">
                            <div class="col-sm-10 col-sm-offset-1">
                                <div class="login-container">
                                    <div style={{ margin: "0 auto" }} >
                                        <div class="center">
                                            <br />
                                            <br />
                                            <a href="https://baithka.com" target="_blank">
                                                <img src="images/Baithka-64.gif"
                                                    onError={(e) => { e.target.onerror = null; e.target.src = "images/Baithka-64.gif" }} />
                                            </a>
                                            <div class="social-or-login center">
                                                <span class="bigger-110">&nbsp;&nbsp;textile manufacturing&nbsp;&nbsp;</span>
                                            </div>
                                        </div>

                                        <div class="space-6"></div>

                                        <div class="position-relative">
                                            <div class="forgot-box visible widget-box no-border">
                                                <div class="widget-body">
                                                    <div class="widget-main">
                                                        <h4 class="header red lighter bigger">
                                                            <i class="ace-icon fa fa-key"></i> Forgot Password
                                                        </h4>

                                                        <div class="space-6"></div>
                                                        {isConfirmReset ?
                                                            <ConfirmResetPassword />
                                                            :
                                                            <>
                                                                {changeReady ?
                                                                    <ResetNewPassword setIsConfirmReset={setIsConfirmReset} userData={userData} />
                                                                    :
                                                                    <InitForgotPassword setChangeReady={setChangeReady} setUserData={setUserData} />}
                                                            </>
                                                        }
                                                    </div>

                                                    <div class="toolbar center">
                                                        <a href="#" data-target="#login-box" class="back-to-login-link">
                                                            Back to login <i class="ace-icon fa fa-arrow-right"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="space-6"></div>
                            <br />
                            <br />
                            <div class="col-xs-12" style={{ textAlign: "center" }}>
                                <div class="footer-inner">
                                    <div class="footer-content">
                                        <p>
                                            <a href="https://cubicle8.com" target="_blank">
                                                <img style={{ marginBottom: "3px" }} height="32px" src="images/cubiclelogo.gif" />
                                            </a>
                                            <br />
                                            <span class="blue" style={{ verticalAlign: "bottom" }}><a href="https://baithka.com/about" target="_blank">About</a> | <a href="https://baithka.com/term" target="_blank">Term & Conditions</a></span>

                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
