import React from 'react'

export function ProcessingViewS({ item }) {
    return (
        item &&
        <>
            {item.name}
        </>
    )
}
