import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetHistoryByRefId(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("History/GetHistoryByRefId", param)
    }
}

export async function GetHistoryByTypeAndRefId(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("History/GetHistoryByTypeAndRefId", param)
    }
}

export async function GetHistoryBetweenDateAndHistoryType(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("History/GetHistoryBetweenDateAndHistoryType", param)
    }
}

export async function GetHistoryBetweenDateAndHistoryAction(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("History/GetHistoryBetweenDateAndHistoryAction", param)
    }
}

export async function RemoveAllHistoryBetweenDate(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("History/RemoveAllHistoryBetweenDate", param)
    }
}

export async function Add(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("History/Add", param)
    }
}