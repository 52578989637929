import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetByProductId(productId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductProcessingAllotment/GetByProductId?ProductId="+ productId)
    }
}

export async function Add(allotment) {
    const loginData = GetLoginInfo();

    if (loginData) {

        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingAllotment/Add", allotment)
        
    }
}
export async function Remove(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingAllotment/Remove?Id="+id)
    }
}