import React from 'react'
import { getPageMargins } from '../../config/GlobalSettings'
import { GetEBoolName } from '../../enumCollections/EBool'
import { GetEPaymentStatusName } from '../../enumCollections/EPaymentStatus'
import { ContactViewS } from '../Global/Elements/ContactViewS'
import { LotView } from '../Global/Elements/LotView'
import { ProductionUnitViewS } from '../Global/Elements/ProductionUnitViewS'
import { ProductViewS } from '../Global/Elements/ProductViewS'
import { WarehouseViewS } from '../Global/Elements/WarehouseViewS'
import { PrintBranding } from './PrintBranding'

export const ProductReceivingPrint = React.forwardRef((props, ref) => {
    const data = props.printData
    return (
        <div ref={ref}>
            <style type="text/css">
                {getPageMargins()}
            </style>

            {
                data &&
                <PrintBranding printName="Processing Receiving" date={data.onDate} id={data.id}>
                    {
                        data &&
                        <div className="row">
                            <div className="col-xs-12">

                                <table style={{ width: "100%" }} className="table-bordered-dark">
                                    <tbody>
                                        <tr >
                                            <td>Contact</td>
                                            <td>
                                                <ContactViewS item={data.contact} uid={"CX1"} />
                                            </td>
                                            <td>
                                                <span className="pull-right">
                                                    <WarehouseViewS item={data.warehouse} />
                                                </span>
                                            </td>
                                        </tr>

                                        <tr >
                                            <td>Product</td>
                                            <td colSpan={2}>
                                                <ProductViewS item={data.product} uid={"PPX1"} />

                                            </td>
                                        </tr>

                                        <tr >
                                            <td>Production</td>
                                            <td colSpan={2}> <ProductionUnitViewS item={data.productionUnit} /></td>
                                        </tr>
                                        <tr >
                                            <td>Wage/unit</td>
                                            <td colSpan={2}>₹{Number(data.productWage.wage).toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Fresh</td>
                                            <td colSpan={2}>
                                                {data.freshQuantity} {data.unit}
                                            </td>
                                        </tr>

                                        <tr >
                                            <td>Defective</td>
                                            <td colSpan={2}>
                                                {data.defectiveQuantity} {data.unit}
                                            </td>
                                        </tr>

                                        <tr >
                                            <td>Rejected</td>
                                            <td colSpan={2}>
                                                {data.rejectedQuantity} {data.unit}
                                            </td>
                                        </tr>

                                        <tr >
                                            <td>Received Weight</td>
                                            <td colSpan={2}>
                                                {data.receivedWeight} KG
                                            </td>
                                        </tr>
                                        <tr >
                                            <td>Fresh To Main</td>
                                            <td>
                                                <span class={data.isFreshQuantityToMain == 1 ? "label label-sm label-success" : "label label-sm label-danger"}>{GetEBoolName(data.isFreshQuantityToMain)}</span>
                                            </td>
                                            <td>
                                                Vouchered: {data.isVouchered ? data.voucherId : <span class="label label-sm label-danger">{GetEBoolName(data.isVouchered)}</span>}
                                            </td>
                                        </tr>

                                        <tr >
                                            <th>Lot</th>
                                            <td colSpan={2}>
                                                <LotView item={data.lot} />
                                            </td>
                                        </tr>
                                        <tr >
                                            <td>Note</td>
                                            <td colSpan={2}>
                                                {data.note ? data.note : "NA"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Payment Status</td>
                                            <td colSpan={2}><span class={data.paymentStatus == 1 ? "label label-sm label-warning" : "label label-sm label-default"}>{GetEPaymentStatusName(data.paymentStatus)}</span></td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    }
                </PrintBranding>
            }
        </div>
    )
})
