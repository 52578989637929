import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Container } from '../../../base/Container';
import AppToaster from '../../../commonLib/AppToaster';
import { EmptyData } from '../../../commonLib/EmptyData';
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import { MyAlert } from '../../../commonLib/MyAlert';
import { PagingEmpty } from '../../../commonLib/PagingEmpty';
import { Spinner } from '../../../commonLib/Spinner';
import *as locStatesCrud from '../../../_crud/locStatesCrud'

export function ManageLocState() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [locState, setLocState] = useState([])

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoadingMini(true);
        locStatesCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setLocState(hdata);
                    }
                    else {
                        setLocState([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setLocState([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh() {
        getData()
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        locStatesCrud.Remove(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    AppToaster('Deleted successfully.', 'success')
                    getData()
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by someone,could not delete.', 'error')
                }

            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }


    return (
        <>
            <Container title="States" doRefresh={doRefresh} >
                <Link to="/location/add" class="btn btn-sm btn-success">
                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                    New
                </Link>
                <div class="hr dotted"></div>
                <PagingEmpty text={"Total " + locState.length + " items."}>
                    {isLoadingMini ?
                        <Spinner />
                        :
                        locState && locState.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "43px" }}>#Id</th>
                                            <th>Name</th>
                                            <th>Code</th>
                                            <th>Priority</th>
                                            <th style={{ width: "43px" }}></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {locState.map((item, index) =>
                                            <tr key={item.Id}>
                                                <td>{item.id}</td>
                                                <td>{item.name} </td>
                                                <td>{item.code} </td>
                                                <td>{item.priority}</td>
                                                <td>
                                                    <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                        <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                    </MyAlert>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </PagingEmpty>
            </Container >
        </>
    )
}
