import React, { useContext, useState } from 'react'
import * as Yup from "yup";
import AppToaster from '../../commonLib/AppToaster';
import { useFormik } from 'formik';
import *as processingCrud from '../../_crud/processingCrud'
import { useHistory } from 'react-router-dom';
import { Container } from '../../base/Container';
import { GetEProcessingType } from '../../enumCollections/EProcessingType';
import { GetEProcessingMode } from '../../enumCollections/EProcessingMode';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { LoadingButton } from '../../commonLib/LoadingButton';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

const initialValues = {
    name: "",
    type: 2,
    defaultWage: 0,
    mode: 2,
    note: ""
}

export function AddProcessing() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const history = useHistory()

    const Schema = Yup.object().shape({
        name: Yup.string().min(3, " Name must between 3 to 50 characters").max(50, " Name must between 3 to 50 characters").required("Name is required."),
        //.matches(/^[aA-zZ\s]+$/, " Only alphabets, space and underscore are allowed for this field "),
        type: Yup.string().required("Type is required."),
        defaultWage: Yup.string().required("Default Wage is required."),
        mode: Yup.string().required("Mode is required."),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });
    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        processingCrud.Add(value)
            .then(res => {
                if (res.data.result.succeeded) {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster('Added successfully.', 'success')
                    history.goBack()
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    return (
        <>
            <Container title="Add Processing" isBack="true">
                <form onSubmit={formik.handleSubmit} class="form-horizontal">
                    <div className="row">
                        <div className="col-md-6">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Name</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Name" name="name" value={formik.values.name} onChange={(e) => formik.setValues({ ...formik.values, name: e.target.value })} />
                                    {
                                        formik.touched.name &&
                                            formik.errors.name ? (
                                            <label className="text-danger" >
                                                {formik.errors.name}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Default Wage ₹</label>
                                    <input className="form-control" type="number" placeholder="Default Wage" name="defaultWage" {...formik.getFieldProps("defaultWage")} />
                                    {
                                        formik.touched.defaultWage &&
                                            formik.errors.defaultWage ? (
                                            <label className="text-danger" >
                                                {formik.errors.defaultWage}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Mode</label>
                                    <select className="form-control" name="mode" value={formik.values.mode} onChange={(e) => formik.setValues({ ...formik.values, mode: e.target.value })}>
                                        {
                                            GetEProcessingMode.EProcessingMode.map((data, index) =>
                                                <option key={data.Id} value={data.Id}>{data.Name}</option>
                                            )
                                        }
                                    </select>
                                    {
                                        formik.touched.mode &&
                                            formik.errors.mode ? (
                                            <label className="text-danger" >
                                                {formik.errors.mode}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Type</label>
                                    <select className="form-control" name="type" value={formik.values.type} onChange={(e) => formik.setValues({ ...formik.values, type: e.target.value })}>
                                        {
                                            GetEProcessingType.EProcessingType.map((data, index) =>
                                                <option key={data.Id} value={data.Id}>{data.Name}</option>
                                            )
                                        }
                                    </select>
                                    {
                                        formik.touched.type &&
                                            formik.errors.type ? (
                                            <label className="text-danger" >
                                                {formik.errors.type}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right"> Note</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Note" name="note" value={formik.values.note} onChange={(e) => formik.setValues({ ...formik.values, note: e.target.value })} />
                                    {
                                        formik.touched.note &&
                                            formik.errors.note ? (
                                            <label className="text-danger" >
                                                {formik.errors.note}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="clearfix form-actions">
                        <div class="pull-right">
                            {isLoadingLocal ?
                                <LoadingButton />
                                :
                                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Add
                                </button>}
                        </div>
                    </div>

                </form>
            </Container >
        </>
    )
}
