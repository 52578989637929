import React from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ADMINISTRATOR } from '../auth/RouteRoles';
import GetLoginInfo from '../auth/_userContext/GetLoginInfo';

const Menu = () => {

  const location = useLocation();
  const userData = GetLoginInfo()
  return (
    <>
      <div id="sidebar" class="sidebar responsive ace-save-state">
        {/* <div id="sidebar" class="sidebar responsive menu-min"> */}
        <div class="sidebar-shortcuts" id="sidebar-shortcuts">
          <div class="sidebar-shortcuts-large" id="sidebar-shortcuts-large">
            <Link to="/contact/add" class="btn btn-success" title="New Contact">
              <i class="ace-icon fa fa-group"></i>
            </Link>

            <Link to="/product/add" class="btn btn-info" title="New Product">
              <i class="ace-icon fa fa-cubes"></i>
            </Link>

            <Link to="/processing/add" class="btn btn-warning" title="Add Processing">
              <i class="ace-icon fa fa-recycle"></i>
            </Link>

            <Link to="/productionunit/configuration/add" class="btn btn-danger" title="New Color Configration">
              <i class="ace-icon fa fa-paint-brush"></i>
            </Link>
          </div>

          <div class="sidebar-shortcuts-mini" id="sidebar-shortcuts-mini">
            <span class="btn btn-success"></span>

            <span class="btn btn-info"></span>

            <span class="btn btn-warning"></span>

            <span class="btn btn-danger"></span>
          </div>
        </div>

        <ul class="nav nav-list">
          <li class="">
            <Link to="/home">
              <i class="menu-icon fa fa-tachometer"></i>
              <span class="menu-text"> Dashboard </span>
            </Link>

            <b class="arrow"></b>
          </li>


          {userData.role == ADMINISTRATOR &&
            <li>
              <a class="dropdown-toggle">
                <i class="menu-icon fa fa-line-chart"></i>
                <span class="menu-text"> Reports  </span>
                <b class="arrow fa fa-angle-down"></b>
              </a>
              <b class="arrow"></b>

              <ul class="submenu">
                <li class="">
                  <a href="#" class="dropdown-toggle">
                    <i class="menu-icon fa fa-caret-right"></i>

                    <span class="menu-text"><i class="menu-icon fa fa-cube"></i> Product</span>
                    <b class="arrow fa fa-angle-down"></b>
                  </a>

                  <b class="arrow"></b>

                  <ul class="submenu">
                    <li class="">
                      <Link to="/productdistribution">
                        <i class="menu-icon fa fa-arrow-circle-o-up blue"></i>
                        Distribution
                      </Link>

                      <b class="arrow"></b>
                    </li>
                    <li class="">
                      <Link to="/productreceiving">
                        <i class="menu-icon fa fa-arrow-circle-o-down green"></i>
                        Receiving
                      </Link>

                      <b class="arrow"></b>
                    </li>
                    <li class="">
                      <Link to="/productreturn">
                        <i class="menu-icon fa fa-arrow-circle-o-left red"></i>
                        Return
                      </Link>

                      <b class="arrow"></b>
                    </li>

                  </ul>
                </li>

                <li class="">
                  <a href="#" class="dropdown-toggle">
                    <i class="menu-icon fa fa-caret-right"></i>

                    <span class="menu-text"><i class="menu-icon fa fa-recycle"></i> Processing</span>
                    <b class="arrow fa fa-angle-down"></b>
                  </a>

                  <b class="arrow"></b>

                  <ul class="submenu">
                    <li class="">
                      <Link to="/processingdistribution">
                        <i class="menu-icon fa fa-arrow-circle-o-up blue"></i>
                        Distribution
                      </Link>

                      <b class="arrow"></b>
                    </li>
                    <li class="">
                      <Link to="/processingreceiving">
                        <i class="menu-icon fa fa-arrow-circle-o-down green"></i>
                        Receiving
                      </Link>

                      <b class="arrow"></b>
                    </li>
                    <li class="">
                      <Link to="/processingreturn">
                        <i class="menu-icon fa fa-arrow-circle-o-left red"></i>
                        Return
                      </Link>

                      <b class="arrow"></b>
                    </li>

                  </ul>
                </li>

                <li class="">
                  <a href="#" class="dropdown-toggle">
                    <i class="menu-icon fa fa-caret-right"></i>

                    <span class="menu-text"><i class="menu-icon fa fa-inr"></i> Transactions</span>
                    <b class="arrow fa fa-angle-down"></b>
                  </a>

                  <b class="arrow"></b>

                  <ul class="submenu">
                    <li class="">
                      <Link to="/cashflow">
                        <i class="menu-icon fa fa-inr"></i> Cash & Bank Flow
                      </Link>
                      <b class="arrow"></b>
                    </li>
                    <li class="">
                      <Link to="/ledgersummary">
                        <i class="menu-icon fa fa-inr"></i> Ledger Summary
                      </Link>
                      <b class="arrow"></b>
                    </li>
                    <li class="">
                      <Link to="/defectmanagement">
                        <i class="menu-icon fa fa-inr"></i> Defect Management
                      </Link>
                      <b class="arrow"></b>
                    </li>
                    <li class="">
                      <Link to="/banktransfer">
                        <i class="menu-icon fa fa-inr"></i> Bank Transfer
                      </Link>
                      <b class="arrow"></b>
                    </li>
                    <li class="">
                      <Link to="/advancecontactlist">
                        <i class="menu-icon fa fa-inr"></i> Advance By Contact
                      </Link>
                      <b class="arrow"></b>
                    </li>
                    <li class="">
                      <Link to="/defectscontactlist">
                        <i class="menu-icon fa fa-inr"></i> Defects By Contact
                      </Link>
                      <b class="arrow"></b>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          }

          <li class="">
            <Link to="/gatepass">
              <i class="menu-icon fa fa-ticket"></i>
              <span class="menu-text">GatePass</span>
            </Link>
            <b class="arrow"></b>
          </li>

          <li>
            <a class="dropdown-toggle">
              <i class="menu-icon fa fa-level-up"></i>
              <span class="menu-text"> Order </span>
              <b class="arrow fa fa-angle-down"></b>
            </a>
            <b class="arrow"></b>

            <ul class="submenu">

              <li class="">
                <Link to="/order">
                  <i class="menu-icon fa fa-caret-right"></i>
                  <span class="menu-text"><i class="menu-icon fa fa-level-up"></i> Orders</span>
                </Link>
                <b class="arrow"></b>
              </li>

              <li class="">
                <Link to="/initorderforproduction">
                  <i class="menu-icon fa fa-caret-right"></i>
                  <span class="menu-text"><i class="menu-icon fa fa-level-up"></i> Init Orders</span>
                </Link>
                <b class="arrow"></b>
              </li>
              <li class="">
                <Link to="/orderpeocessingprint">
                  <i class="menu-icon fa fa-caret-right"></i>
                  <span class="menu-text"><i class="menu-icon fa fa-level-up"></i> Orders Processing Print</span>
                </Link>
                <b class="arrow"></b>
              </li>
            </ul>
          </li>

          <li>
            <a class="dropdown-toggle">
              <i class="menu-icon fa fa-inr"></i>
              <span class="menu-text"> Vouchers </span>
              <b class="arrow fa fa-angle-down"></b>
            </a>
            <b class="arrow"></b>

            <ul class="submenu">

              <li class="">
                <Link to="/processingvoucher">
                  <i class="menu-icon fa fa-caret-right"></i>
                  <span class="menu-text"><i class="menu-icon fa fa-inr"></i> Processing Voucher</span>
                </Link>
                <b class="arrow"></b>
              </li>

              <li class="">
                <Link to="/productionvoucher">
                  <i class="menu-icon fa fa-caret-right"></i>
                  <span class="menu-text"><i class="menu-icon fa fa-inr"></i> Production Voucher</span>
                </Link>
                <b class="arrow"></b>
              </li>

              <li class="">
                <Link to="/advancevoucher">
                  <i class="menu-icon fa fa-caret-right"></i>
                  <span class="menu-text"><i class="menu-icon fa fa-inr"></i> Advance Voucher</span>
                </Link>
                <b class="arrow"></b>
              </li>

              <li class="">
                <Link to="/salaryvoucher">
                  <i class="menu-icon fa fa-caret-right"></i>
                  <span class="menu-text"><i class="menu-icon fa fa-inr"></i> Salary Voucher</span>
                </Link>
                <b class="arrow"></b>
              </li>

              <li class="">
                <Link to="/expensevoucher">
                  <i class="menu-icon fa fa-caret-right"></i>
                  <span class="menu-text"><i class="menu-icon fa fa-inr"></i> Expense Voucher</span>
                </Link>
                <b class="arrow"></b>
              </li>

              <li class="">
                <Link to="/cashbankcashvoucher">
                  <i class="menu-icon fa fa-caret-right"></i>
                  <span class="menu-text"><i class="menu-icon fa fa-inr"></i> Cash Bank Voucher</span>
                </Link>
                <b class="arrow"></b>
              </li>

              {userData.role == ADMINISTRATOR &&
                <li class="">
                  <Link to="/openingbalancevoucher">
                    <i class="menu-icon fa fa-caret-right"></i>
                    <span class="menu-text"><i class="menu-icon fa fa-inr"></i> Opening Balance</span>
                  </Link>
                  <b class="arrow"></b>
                </li>
              }
            </ul>
          </li>
          <li>
            <a class="dropdown-toggle">
              <i class="menu-icon fa fa-money"></i>
              <span class="menu-text"> Cheque Management </span>
              <b class="arrow fa fa-angle-down"></b>
            </a>
            <b class="arrow"></b>

            <ul class="submenu">
              {userData.role == ADMINISTRATOR &&
                <li class="">
                  <Link to="/bankreports">
                    <i class="menu-icon fa fa-caret-right"></i>
                    <span class="menu-text"><i class="menu-icon fa fa-pie-chart"></i> Reports</span>
                  </Link>
                  <b class="arrow"></b>
                </li>
              }
              <li class="">
                <Link to="/transaction">
                  <i class="menu-icon fa fa-caret-right"></i>
                  <span class="menu-text"><i class="menu-icon fa fa-exchange"></i> Transaction</span>
                </Link>
                <b class="arrow"></b>
              </li>
              {userData.role == ADMINISTRATOR &&
                <li class="">
                  <Link to="/bank">
                    <i class="menu-icon fa fa-caret-right"></i>
                    <span class="menu-text"><i class="menu-icon fa fa-university"></i> Manange Bank Account</span>
                  </Link>
                  <b class="arrow"></b>
                </li>
              }
            </ul>
          </li>
          <li>
            <a class="dropdown-toggle">
              <i class="menu-icon fa fa-stack-overflow"></i>
              <span class="menu-text"> Stocks </span>
              <b class="arrow fa fa-angle-down"></b>
            </a>
            <b class="arrow"></b>

            <ul class="submenu">

              <li class="">
                <Link to="/processingcontactstock">
                  <i class="menu-icon fa fa-caret-right"></i>
                  <span class="menu-text"><i class="menu-icon fa fa-recycle"></i> Processing Contact</span>
                </Link>
                <b class="arrow"></b>
              </li>
              <li class="">
                <Link to="/productstock">
                  <i class="menu-icon fa fa-caret-right"></i>
                  <span class="menu-text"><i class="menu-icon fa fa-cube"></i> Products Contact</span>
                </Link>
                <b class="arrow"></b>
              </li>

              <li class="">
                <Link to="/productreceivinglot">
                  <i class="menu-icon fa fa-caret-right"></i>
                  <span class="menu-text"><i class="menu-icon fa fa-code-fork "></i> All Lots</span>
                </Link>
                <b class="arrow"></b>
              </li>
            </ul>
          </li>

          <li class="">
            <a class="dropdown-toggle">
              <i class="menu-icon fa fa-hashtag"></i>
              <span class="menu-text"> Masters </span>

              <b class="arrow fa fa-angle-down"></b>
            </a>

            <b class="arrow"></b>

            <ul class="submenu">
              <li class="">
                <Link to="/contact">
                  <i class="menu-icon fa fa-caret-right"></i>
                  <span class="menu-text"><i class="menu-icon fa fa-group"></i> Contact </span>
                </Link>
                <b class="arrow"></b>
              </li>

              <li class="">
                <Link to="/product">
                  <i class="menu-icon fa fa-caret-right"></i>
                  <span class="menu-text"><i class="menu-icon fa fa-cubes"></i> Products</span>
                </Link>
                <b class="arrow"></b>
              </li>
              <li class="">
                <Link to="/productionunit">
                  <i class="menu-icon fa fa-caret-right"></i>
                  <span class="menu-text"><i class="menu-icon fa fa-building"></i> Production Unit </span>
                </Link>
                <b class="arrow"></b>
              </li>
              {userData.role == ADMINISTRATOR &&
                <>
                  <li class="">
                    <Link to="/processing">
                      <i class="menu-icon fa fa-caret-right"></i>
                      <span class="menu-text"><i class="menu-icon fa fa-recycle"></i> Processing </span>
                    </Link>
                    <b class="arrow"></b>
                  </li>

                  <li class="">
                    <Link to="/ledger">
                      <i class="menu-icon fa fa-caret-right"></i>
                      <span class="menu-text"><i class="menu-icon fa fa-money"></i> Ledger </span>
                    </Link>
                    <b class="arrow"></b>
                  </li>
                </>
              }
            </ul>
          </li>
          {userData.role == ADMINISTRATOR &&
            <li>
              <a class="dropdown-toggle">
                <i class="menu-icon fa fa-history"></i>
                <span class="menu-text"> Histories </span>
                <b class="arrow fa fa-angle-down"></b>
              </a>
              <b class="arrow"></b>

              <ul class="submenu">

                <li class="">
                  <Link to="/history">
                    <i class="menu-icon fa fa-caret-right"></i>
                    <span class="menu-text"><i class="menu-icon fa fa-search"></i> View/ Find</span>
                  </Link>
                  <b class="arrow"></b>
                </li>
                <li class="">
                  <Link to="/removehistory">
                    <i class="menu-icon fa fa-caret-right"></i>
                    <span class="menu-text"><i class="menu-icon fa fa-trash"></i> Delete Range</span>
                  </Link>
                  <b class="arrow"></b>
                </li>
                <li class="">
                  <Link to="/loginlogs">
                    <i class="menu-icon fa fa-caret-right"></i>
                    <span class="menu-text"><i class="menu-icon fa fa-braille "></i> Login Logs</span>
                  </Link>
                  <b class="arrow"></b>
                </li>
                <li class="">
                  <Link to="/rechargehistory">
                    <i class="menu-icon fa fa-caret-right"></i>
                    <span class="menu-text"><i class="menu-icon fa fa-search"></i> Recharge History</span>
                  </Link>
                  <b class="arrow"></b>
                </li>
              </ul>
            </li>
          }
          <li class="">
            <a class="dropdown-toggle">
              <i class="menu-icon fa fa-cogs"></i>
              <span class="menu-text"> Settings </span>
              <b class="arrow fa fa-angle-down"></b>
            </a>

            <b class="arrow"></b>

            <ul class="submenu">
              <li class="">
                <Link to="/changepassword">
                  <i class="menu-icon fa fa-caret-right"></i>
                  <span class="menu-text"><i class="menu-icon fa fa-key"></i> Change Password </span>
                </Link>
                <b class="arrow"></b>
              </li>

              {userData.role == ADMINISTRATOR &&
                <>
                  <li class="">
                    <Link to="/organization/profile">
                      <i class="menu-icon fa fa-caret-right"></i>
                      <span class="menu-text"><i class="menu-icon fa fa-university"></i> Organization </span>
                    </Link>
                    <b class="arrow"></b>
                  </li>
                  <li class="">
                    <Link to="/users">
                      <i class="menu-icon fa fa-caret-right"></i>
                      <span class="menu-text"><i class="menu-icon glyphicon glyphicon-user"></i> Users </span>
                    </Link>
                    <b class="arrow"></b>
                  </li>
                  {/* <li class="">
                    <Link to="/backups">
                      <i class="menu-icon fa fa-caret-right"></i>
                      <span class="menu-text"><i class="menu-icon fa fa-hdd-o"></i> Backup Manager </span>
                    </Link>
                    <b class="arrow"></b>
                  </li> */}

                  <li class="">
                    <Link to="/fiscalyearclosing">
                      <i class="menu-icon fa fa-caret-right"></i>
                      <span class="menu-text"><i class="menu-icon fa fa-inr"></i> Fiscal Year Closing </span>
                    </Link>
                    <b class="arrow"></b>
                  </li>

                  <li class="">
                    <Link to="/location">
                      <i class="menu-icon fa fa-caret-right"></i>
                      <span class="menu-text"><i class="menu-icon fa fa-key"></i> Location </span>
                    </Link>
                    <b class="arrow"></b>
                  </li>
                </>
              }

            </ul>

          </li>

        </ul>


        {/* <div class="sidebar-toggle sidebar-collapse" id="sidebar-collapse">
          <i id="sidebar-toggle-icon" class="ace-icon fa fa-angle-double-right ace-save-state" data-icon1="ace-icon fa fa-angle-double-left" data-icon2="ace-icon fa fa-angle-double-right"></i>
        </div> */}
      </div>
    </>
  );
}

export default Menu;

