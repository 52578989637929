import React from 'react'
import { GetEProductReceivingLotStatusName } from '../../../enumCollections/EProductReceivingLotStatus'

export function LotView({ item }) {
    return (
        <>
            {
                item &&
                <div style={{width:"120px"}}>
                    <span class={item.status == 1 ? "label label-sm label-success" : "label label-sm label-danger"}>{GetEProductReceivingLotStatusName(item.status)}</span>
                    <br /><span className='green' title="Fresh">F: {item.freshQuantity}</span>&nbsp;&nbsp;
                    <span title="Defective">D: {item.defectiveQuantity}</span>&nbsp;&nbsp;
                    <span className="red" title="Rejected">R: {item.rejectedQuantity}</span>
                </div>
            }
        </>
    )
}
