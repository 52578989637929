const GetEWageStatusName = (id) => {
    switch (id) {
        case 1: { return "Normal" }
        case 2: { return "Reverse" }
    }
}

const GetEWageStatus = {
    EStatus: [
        { Id: 1, Name: "Normal" },
        { Id: 2, Name: "Reverse" },
    ]
}

export { GetEWageStatusName, GetEWageStatus }