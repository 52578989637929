import React, { useContext, useState } from 'react'
import GetCurrentDateForSearch from '../../commonLib/dateFormat'
import * as historyCrud from '../../_crud/historyCrud'
import { Container } from '../../base/Container'
import { MyAlert } from '../../commonLib/MyAlert'
import { LoadingButton } from '../../commonLib/LoadingButton'
import DatePicker from "react-datepicker";
import AppToaster from '../../commonLib/AppToaster'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext'
import * as Yup from "yup"
import { useFormik } from 'formik';

const initialValues = {
    fromDate: new Date(GetCurrentDateForSearch(-730)),
    toDate: new Date(GetCurrentDateForSearch(-365)),
    password: ""
}

export function RemoveRangeHistory() {
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)

    const Schema = Yup.object().shape({
        fromDate: Yup.string().required("From Date is required."),
        toDate: Yup.string().required("To Date is required."),
        password: Yup.string().required("Password is required.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            //DeleteItem(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    const DeleteItem = (e) => {
        if (formik.values.fromDate && formik.values.toDate && formik.values.password) {
            setIsLoadingMini(true)
            historyCrud.RemoveAllHistoryBetweenDate(formik.values)
                .then(res => {
                    if (res.data.succeeded) {
                        setIsLoadingMini(false)
                        AppToaster('Deleted successfully.', 'success')
                    }
                    else {
                        setIsLoadingMini(false)
                        //AppToaster(res.data.message, 'error')
                        AppToaster('No histories found to delete.', 'error')
                    }
                }).catch(error => {
                    setIsLoadingMini(false)
                    AppToaster('Network error', 'error')
                })
        }
        else {
            AppToaster('Please fill all the fields.', 'error')
        }
    }

    return (
        <Container title="Remove History" isBack="true">
            <div class="form-horizontal">
                <div className="row">
                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right"> From Date</label>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker placeholder="From Date" name="fromDate" className="form-control input-date-padding" selected={formik.values.fromDate} onChange={(date) => formik.setValues({ ...formik.values, fromDate: date })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                                {
                                    formik.touched.fromDate &&
                                        formik.errors.fromDate ? (
                                        <label className="text-danger" >
                                            {formik.errors.fromDate}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right"> To Date</label>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker placeholder="To Date" name="toDate" className="form-control input-date-padding" selected={formik.values.toDate} onChange={(date) => formik.setValues({ ...formik.values, toDate: date })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                                {
                                    formik.touched.toDate &&
                                        formik.errors.toDate ? (
                                        <label className="text-danger" >
                                            {formik.errors.toDate}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right"> Password <small>(administrator's password only)</small></label>
                                <input className="form-control" type="password" placeholder="Password" name="password" {...formik.getFieldProps("password")} />
                                {
                                    formik.touched.password &&
                                        formik.errors.password ? (
                                        <label className="text-danger" >
                                            {formik.errors.password}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div class="clearfix form-actions">
                    <div class="pull-right">
                        <MyAlert confirmBtnText="Yes, delete it!" title="Delete History" isFormated={false} onConfirm={DeleteItem} param={1}>
                            {isLoadingMini ?
                                <button style={{ marginLeft: "5px" }} class="btn btn-sm btn-success " disabled>
                                    <i class="ace-icon fa fa-trash bigger-110"></i>
                                    Deleting wait..
                                </button>
                                :
                                <button style={{ marginLeft: "5px" }} class="btn btn-sm btn-success " disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-trash bigger-110"></i>
                                    Remove
                                </button>
                            }
                        </MyAlert>
                    </div>
                </div>

            </div>
        </Container >
    )
}
