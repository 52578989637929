import React, { useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';

export function MyAlert({ confirmBtnText = "Yes, do it!",
    confirmBtnBsStyle = "danger", title = "Are you sure?",
    onConfirm, param, description = "Are you sure?", isFormated = true, children }) {
    const [alertConfirm, setAlertConfirm] = useState({ alert: null })
    function showAlert() {
        setAlertConfirm({
            alert: (
                <SweetAlert
                    info
                    showCancel
                    confirmBtnText={confirmBtnText}
                    confirmBtnBsStyle={confirmBtnBsStyle}
                    title={title}
                    onConfirm={() => { onConfirm(param); hideAlert(); }}
                    onCancel={hideAlert}
                    focusCancelBtn
                >
                    {description}
                </SweetAlert>
            )
        });
    }

    const hideAlert = () => {
        setAlertConfirm({
            alert: null
        });
    }
    return (
        <>
            {alertConfirm.alert}
            <a className={isFormated && ("btn btn-xs btn-" + confirmBtnBsStyle)} onClick={showAlert}>
                {children}
            </a>
        </>
    )
}


// confirmBtnBsStyle and Type: 'default'|'primary'|'link'|'info'|'success'|'warning'|'danger'|'secondary'|'outline-{variant}'
// Default: 'primary'
// props.customIcon
// Either a string url for an image to use as the icon, or JSX/ReactNode.

// Type: ReactNode|string
// Default: undefined