import React, { useContext, useEffect, useState } from 'react'
import Moment from 'react-moment'
import { Link, useParams } from 'react-router-dom'
import { DEFAULT_USER_IMG, ERROR_IMG } from '../../config/GlobalSettings'
import { GetEStatusName } from '../../enumCollections/EStatus'
import { BASE_URL } from '../../initApi/apiUrls'
import * as reportCrud from '../../_crud/reportCrud'
import { WarehouseView } from '../Global/Elements/WarehouseView'
import ContactOrderSupplied from './ContactOrderSupplied'

export function ContactProfile({ item }) {
    const [counts, setCounts] = useState()
    const { id } = useParams()
    useEffect(() => {
        GetGlobalCountContact()
    }, []);

    function GetGlobalCountContact() {
        reportCrud.GetGlobalCountContact(id)
            .then(res => {
                if (res.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data
                        setCounts(hdata)
                    }
                    else {
                    }
                }
                else {
                }
            })
            .catch(error => {
            })
    }
    return (
        <>
            {item &&
                <div id="user-profile-1" class="user-profile row">
                    <div class="col-xs-12 col-sm-3 center">
                        <div>
                            <span class="profile-picture" style={{ width: "100%" }}>
                                <img id="avatar" style={{ width: "100%" }} class="editable img-responsive" src={item.isPhoto == 1 ? (BASE_URL + "/" + item.photoUrl) : DEFAULT_USER_IMG}
                                    onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
                            </span>

                            <div class="space-4"></div>

                            <div class="width-80 label label-info label-xlg arrowed-in arrowed-in-right">
                                <div class="inline position-relative">
                                    <a class="user-title-label dropdown-toggle" data-toggle="dropdown">
                                        <i class={item.status == 1 ? "ace-icon fa fa-circle light-green" : "ace-icon fa fa-circle light-red"}></i>
                                        &nbsp;
                                        <span style={{ color: "white", top: "1px" }} class="user-info">{item.fullName}</span>
                                    </a>

                                </div>
                            </div>
                        </div>

                        <div class="space-6"></div>

                        <div class="profile-contact-info">
                            <div class="profile-contact-links align-left">
                                <Link to={"/contact/dashboard/" + item.id + "/photo"} class="btn btn-link">
                                    <i class="ace-icon fa fa-user bigger-120 green"></i>
                                    Upload Photo
                                </Link>

                                <Link to={"/contact/dashboard/" + item.id + "/docs"} class="btn btn-link">
                                    <i class="ace-icon fa fa-photo bigger-120 pink"></i>
                                    Document
                                </Link>

                                <a class="btn btn-link">
                                    <i class="ace-icon fa fa-phone bigger-125 blue"></i>
                                    {item.phone}
                                </a>
                            </div>

                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-9">
                        {counts &&
                            <div class="center">
                                <span class="btn btn-app btn-sm btn-light no-hover">
                                    <span class="line-height-1 bigger-170 blue"> {counts.productionUnit} </span>

                                    <br />
                                    <span class="line-height-1 smaller-90"> Productions </span>
                                </span>

                                <span class="btn btn-app btn-sm btn-yellow no-hover">
                                    <span class="line-height-1 bigger-170"> {counts.product} </span>

                                    <br />
                                    <span class="line-height-1 smaller-90"> Products </span>
                                </span>

                                <span class="btn btn-app btn-sm btn-pink no-hover">
                                    <span class="line-height-1 bigger-170"> {counts.processing} </span>

                                    <br />
                                    <span class="line-height-1 smaller-90"> Processings </span>
                                </span>

                                <span class="btn btn-app btn-sm btn-grey no-hover">
                                    <span class="line-height-1 bigger-170"> {counts.order} </span>

                                    <br />
                                    <span class="line-height-1 smaller-90"> Orders </span>
                                </span>

                                <span class="btn btn-app btn-sm btn-success no-hover">
                                    <span class="line-height-1 bigger-170"> {counts.myOrder} </span>

                                    <br />
                                    <span class="line-height-1 smaller-90"> My Orders </span>
                                </span>

                                <span class="btn btn-app btn-sm btn-info no-hover">
                                    <span class="line-height-1 bigger-170"> {counts.lot} </span>

                                    <br />
                                    <span class="line-height-1 smaller-90"> Lots </span>
                                </span>
                            </div>
                        }

                        <div class="space-12"></div>
                        <ContactOrderSupplied />
                        <div class="space-12"></div>

                        <div className="widget-box">
                            <div class="widget-header widget-header-flat widget-header-small">
                                <h5 class="widget-title">
                                    Details
                                </h5>
                            </div>
                            <div className="row">
                                <div class="profile-user-info profile-user-info-striped">
                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Name </div>

                                        <div class="profile-info-value">
                                            <span class="editable" id="country">{item.fullName}</span>
                                        </div>
                                    </div>
                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Location </div>

                                        <div class="profile-info-value">
                                            <i class="fa fa-map-marker light-orange bigger-110"></i>&nbsp;
                                            <span class="editable" id="country">{item.address}</span>
                                            <span class="editable" id="city">{item.city} - {item.pinCode}</span>
                                            <span class="editable" id="city">{item.state}</span>
                                        </div>
                                    </div>


                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Phone </div>

                                        <div class="profile-info-value">
                                            <span >{item.phone} {item.email && " Email: " + item.email}</span>
                                        </div>
                                    </div>
                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Ref </div>

                                        <div class="profile-info-value">
                                            <span >{item.refId ? item.refId : "NA"}</span>
                                        </div>
                                    </div>

                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Document Name </div>

                                        <div class="profile-info-value">
                                            <span >{item.documentName ? item.documentName : "NA"}</span>
                                        </div>
                                    </div>

                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Document No </div>

                                        <div class="profile-info-value">
                                            <span >{item.documentIdNo ? item.documentIdNo : "NA"}</span>
                                        </div>
                                    </div>
                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Location </div>

                                        <div class="profile-info-value">
                                            <span ><WarehouseView item={item.warehouse} uid="wh1x" /></span>
                                        </div>
                                    </div>
                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Catogery </div>

                                        <div class="profile-info-value">
                                            <span >{item.productionCategory ? item.productionCategory.productionCatName : "NA"}</span>
                                        </div>
                                    </div>
                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Bank </div>

                                        <div class="profile-info-value">
                                            <span >{item.bankName ? item.bankName : "NA"}</span>
                                        </div>
                                    </div>
                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Account No. </div>

                                        <div class="profile-info-value">
                                            <span >{item.bankAcountNo ? item.bankAcountNo : "NA"}</span>
                                        </div>
                                    </div>
                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Branch </div>

                                        <div class="profile-info-value">
                                            <span >{item.bankBaranch ? item.bankBaranch : "NA"}, IFSC: {item.ifscCode ? item.ifscCode : "NA"}</span>
                                        </div>
                                    </div>
                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Last activity </div>

                                        <div class="profile-info-value">
                                            <span >{item.lastActivity ? <Moment format="DD-MMM-YYYY HH:mm">{item.lastActivity}</Moment> : "NA"}</span>
                                        </div>
                                    </div>

                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Status </div>

                                        <div class="profile-info-value">
                                            <span class={item.status == 1 ? "label label-sm label-success" : "label label-sm label-danger"}>{GetEStatusName(item.status)}</span>
                                        </div>
                                    </div>

                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Note </div>

                                        <div class="profile-info-value">
                                            <span >{item.note ? item.note : "NA"}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
