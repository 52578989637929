import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Container } from '../../../base/Container'
import AppToaster from '../../../commonLib/AppToaster'
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext'
import { TimelineHistory } from '../../Global/TimelineHistory'
import *as productProcessingReturnCrud from '../../../_crud/productProcessingReturnCrud'
import { ProcessingReturnItemView } from './ProcessingReturnItemView'
import { loadingContext } from '../../../commonLib/_lodingContext'

export function ProcessingReturnDashBoard() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [processingReturn, setProcessingReturn] = useState()
    const { id } = useParams()

    function doRefresh() {
        getData()
    }

    useEffect(() => {
        getData()
    }, []);


    function getData() {
        setIsLoading(true);
        setIsLoadingMini(true);
        productProcessingReturnCrud.GetById(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProcessingReturn(hdata);
                        setIsLoading(false)
                        setIsLoadingMini(false)
                    }
                    else {
                        setIsLoading(false)
                        setIsLoadingMini(false)
                        setProcessingReturn([]);
                    }
                }
                else {
                    setIsLoading(false)
                    setIsLoadingMini(false)
                    setProcessingReturn([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoading(false);
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    return (
        <Container title="Processing Return Dashboard" doRefresh={doRefresh} isBack="true" >

            <div className="row">
                <div className="col-lg-8">
                <ProcessingReturnItemView processingReturn={processingReturn} />
                </div>
                <div className="col-lg-4">
                    <TimelineHistory id={id} type={9} pageSize={10} text="Processing Return Timeline" />
                </div>

            </div>
        </Container >
    )
}

