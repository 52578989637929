import React from 'react'
import QRCode from 'react-qr-code';
import { ERROR_IMG } from '../../config/GlobalSettings';
import { BASE_URL } from '../../initApi/apiUrls';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { BGPrintColor } from './BGPrintColors';

export const DrapingView = (props) => {
    const { FinalDraper, FinalDraperBack, itemName, itemCode, organization } = props
    return (
        <>

            {FinalDraper && FinalDraperBack &&
                <>
                    <div className="col-lg-6 center">
                        <div className="drapingPrintBg">
                            <div style={{ padding: "1px" }}>
                                <div style={{ border: " #ffffff", borderStyle: "solid", borderWidth: "15px", margin: "20px" }}>
                                    <h5 style={{ marginBottom: "0" }}>{itemName} - {itemCode}
                                    </h5>
                                    <Zoom>
                                        <img src={FinalDraper} width="70%" alt="Pose Front" />
                                    </Zoom>
                                    {organization &&
                                        <div class="widget-body" style={{ backgroundColor: "transparent" }}>
                                            <div class="row" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                                <div class="col-xs-9 text-left">
                                                    <div>
                                                        <ul class="list-unstyled">
                                                            {organization &&
                                                                <>
                                                                    {organization.isLogo == 1 &&
                                                                        <li>
                                                                            <img height="50px" src={BASE_URL + "/" + organization.logoUrl} onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
                                                                        </li>
                                                                    }
                                                                    <li>
                                                                        {organization.name}
                                                                    </li>
                                                                </>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="col-xs-3">
                                                    <div className="widget style1 pull-right text-center">
                                                        <br />
                                                        <QRCode size="50" value={itemCode} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xs-12" style={{ textAlign: "center", marginTop: "-4px" }}>
                                                <div class="footer-inner">
                                                    <div class="footer-content">
                                                        <p>
                                                            <span style={{ verticalAlign: "bottom", fontSize: "x-small" }}>Powered&nbsp;By </span>
                                                            <img style={{ marginBottom: "3px" }} height="15px" src="images/logo-small.png" />
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 center">
                        <div className="drapingPrintBg">
                            <div style={{ padding: "1px" }}>
                                <div style={{ border: " #ffffff", borderStyle: "solid", borderWidth: "15px", margin: "20px" }}>
                                    <h5 style={{ marginBottom: "0" }}>{itemName} - {itemCode}
                                    </h5>
                                    <Zoom>
                                        <img src={FinalDraperBack} width="70%" alt="Pose Back" />
                                    </Zoom>
                                    {organization &&
                                        <div class="widget-body" style={{ backgroundColor: "transparent" }}>
                                            <div class="row" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                                <div class="col-xs-9 text-left">
                                                    <div>
                                                        <ul class="list-unstyled">
                                                            {organization &&
                                                                <>
                                                                    {organization.isLogo == 1 &&
                                                                        <li>
                                                                            <img height="50px" src={BASE_URL + "/" + organization.logoUrl} onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
                                                                        </li>
                                                                    }
                                                                    <li>
                                                                        {organization.name}
                                                                    </li>
                                                                </>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="col-xs-3">
                                                    <div className="widget style1 pull-right text-center">
                                                        <br />
                                                        <QRCode size="50" value={itemCode} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xs-12" style={{ textAlign: "center", marginTop: "-4px" }}>
                                                <div class="footer-inner">
                                                    <div class="footer-content">
                                                        <p>
                                                            <span style={{ verticalAlign: "bottom", fontSize: "x-small" }}>Powered&nbsp;By </span>
                                                            <img style={{ marginBottom: "3px" }} height="15px" src="images/logo-small.png" />
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </ >
    )
}