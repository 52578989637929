import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxiosWithToken } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetAll(productProcessingReturn) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingReturn/GetAll", productProcessingReturn)
    }
}
export async function GetByProduct(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingReturn/GetByProduct", param)
    }
}
export async function GetByContact(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingReturn/GetByContact", param)
    }
}
export async function GetByProductionUnit(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingReturn/GetByProductionUnit", param)
    }
}
export async function GetByProcessing(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingReturn/GetByProcessing", param)
    }
}

export async function GetById(Id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductProcessingReturn/GetById?Id=" + Id)
    }
}
export async function Add(ProductProcessingReturn) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingReturn/Add", ProductProcessingReturn)
    }
}
export async function Remove(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingReturn/Remove?Id=" + id)
    }
}