const GetEWageTypeName = (id) => {
    switch (id) {
        case 1: { return "Salary" }
        case 2: { return "Production" }
    }
}

const GetEWageType = {
    EWageType: [
        { Id: 1, Name: "Salary" },
        { Id: 2, Name: "Production" },
    ]
}

export { GetEWageTypeName, GetEWageType }