import React, { useContext, useEffect, useState } from 'react'
import * as productionConfigurationCrud from './../../../_crud/productionConfigurationCrud'
import { useParams } from 'react-router-dom'
import { Container } from './../../../base/Container'
import { ProductionConfigurationItemView } from './ProductionConfigurationItemView'
import { loadingContext } from '../../../commonLib/_lodingContext'

export function ProductionConfigurationDashboard() {
    const { id } = useParams()
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [configuration, setConfiguration] = useState()
    const [configurationList, setConfigurationList] = useState([])

    function doRefresh() {
        getProductionConfiguration()
    }

    useEffect(() => {
        getProductionConfiguration()
    }, []);

    function getProductionConfiguration() {
        setIsLoading(true);
        productionConfigurationCrud.GetById(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let hlist = res.data.items
                        setConfiguration(hdata)
                        setConfigurationList(hlist)
                    }
                    else {
                    }
                }
                else {
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
            })
    }

    return (
        <Container title={"Production Configuration Dashboard"} doRefresh={doRefresh} isBack="true">
            <div className="row">
                <div className="col-lg-12">
                    <ProductionConfigurationItemView configuration={configuration} configurationList={configurationList} doRefresh={getProductionConfiguration} />
                </div>
            </div>
        </Container>
    )
}
