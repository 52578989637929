import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetAll(productionUnitId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductionUnitProductAllotment/GetAll?ProductionUnitId=" + productionUnitId)
    }
}

export async function GetAllProductsUnderContact(contactId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductionUnitProductAllotment/GetAllProductsUnderContact?ContactId=" + contactId)
    }
}
export async function GetByProduct(productId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductionUnitProductAllotment/GetByProduct?ProductId=" + productId)
    }
}
export async function GetByActiveProduct(productId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductionUnitProductAllotment/GetByActiveProduct?ProductId=" + productId)
    }
}
export async function Add(productionUnitProductAllotment) {
    const loginData = GetLoginInfo();

    if (loginData) {

        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductionUnitProductAllotment/Add", productionUnitProductAllotment)
        
    }
}
export async function UpdateStatus(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductionUnitProductAllotment/UpdateStatus", param )
    }
}