import React, { useState } from "react"

export const organizationContext = React.createContext()

const OrganizationProvider = (props) => {
    const [organization, setOrganization] = useState();
    return (
        <organizationContext.Provider value={[organization, setOrganization]}>
            {props.children}
        </organizationContext.Provider>
    )
}

export default OrganizationProvider;