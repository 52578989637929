import React, { useState, useEffect } from "react";
import { getCanvas } from "react-lasso-select";
import mergeImages from 'merge-images';
import rotate from 'base64-rotate'
import { flip, resizeImage, swirl } from "./ImageEditFunction";
import { PoseBg } from "./PoseBg";

export function Saree6Pose({ img1, img2, bg, setIsCompleted, setDrapResult, status }) {

    const [isReadySari1, setIsReadySari1] = useState(false);
    const [isReadySari2, setIsReadySari2] = useState(false);
    const [isReadySari3, setIsReadySari3] = useState(false);
    const [isReadySari4, setIsReadySari4] = useState(false);
    const [isReadySari5, setIsReadySari5] = useState(false);
    const [isReadySari6, setIsReadySari6] = useState(false);
    const [isReadySari7, setIsReadySari7] = useState(false);

    //#region Saree1
    const MergeSaree = () => {
        try {
            //alert(cbg)
            mergeImages([
                // { src: cbg, x: 0, y: 0 },
                { src: saree7, x: 0, y: 0 },
                { src: saree1, x: 150, y: 270 },
                { src: saree2, x: 150, y: 320 },
                { src: saree3, x: 150, y: 415 },
                { src: saree4, x: 150, y: 510 },
                { src: saree5, x: 150, y: 643 },
                { src: saree6, x: 150, y: 712 },
                { src: './assets/images/Draper/new/10.png', x: 0, y: 100 },
            ])
                .then(b64 => {
                    setDrapResult(b64)
                    //alert("sareedrap")
                    setIsCompleted("completed")
                }).catch(e => {
                    setDrapResult()
                    setIsCompleted("failed")
                    alert(e)
                });
        } catch (e) {
            setDrapResult()
            setIsCompleted("failed")
            alert(e)
        }
    }
    ///Saree item 1
    const [saree1, setSaree1] = useState("");
    const GetSaree1 = () => {
        setIsReadySari1(false);
        let initPP = "0,1600 1100,1600 1100,0 0,0"
            .split(" ")
            .map((c) => c.split(",").map(Number))
            .map(([x, y]) => ({ x, y }));
        getCanvas(img2, initPP, (err, canvas) => {
            if (!err) {
                setSaree1(canvas.toDataURL());
                setIsReadySari1(true)
            }
        });
    }
    ///Saree item 2
    const [saree2, setSaree2] = useState("");
    const GetSaree2 = () => {
        setIsReadySari2(false)
        rotate(img1, 179).then(newsrc1 => {
            rotate(newsrc1, 112).then(newsrc => {
                let initPP = "1729,590 2403,885 2413,895 2418,911 2413,932 2330,1154 1729,719"
                    .split(" ")
                    .map((c) => c.split(",").map(Number))
                    .map(([x, y]) => ({ x, y }));

                getCanvas(newsrc, initPP, (err, canvas) => {
                    if (!err) {
                        setSaree2(canvas.toDataURL());
                        setIsReadySari2(true)
                    }
                });
            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///Saree item 3
    const [saree3, setSaree3] = useState("");
    const GetSaree3 = () => {
        setIsReadySari3(false)
        rotate(img1, 179).then(newsrc1 => {
            rotate(newsrc1, 122).then(newsrc => {
                let initPP = "1188,2028 1843,2491 1849,2503 1855,2508 1849,2526 1704,2746 1188,2161"
                    .split(" ")
                    .map((c) => c.split(",").map(Number))
                    .map(([x, y]) => ({ x, y }));

                getCanvas(newsrc, initPP, (err, canvas) => {
                    if (!err) {
                        setSaree3(canvas.toDataURL());
                        setIsReadySari3(true)
                    }
                });
            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///Saree item 4
    const [saree4, setSaree4] = useState("");
    const GetSaree4 = () => {
        setIsReadySari4(false)
        rotate(img1, 179).then(newsrc1 => {
            rotate(newsrc1, 132).then(newsrc => {
                let initPP = "935,2408 935,2239 1503,2863 1509,2875 1503,2888 1472,2919 1310,3094"
                    .split(" ")
                    .map((c) => c.split(",").map(Number))
                    .map(([x, y]) => ({ x, y }));

                getCanvas(newsrc, initPP, (err, canvas) => {
                    if (!err) {
                        setSaree4(canvas.toDataURL());
                        setIsReadySari4(true)
                    }
                });
            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///Saree item 5
    const [saree5, setSaree5] = useState("");
    const GetSaree5 = () => {
        setIsReadySari5(false)
        rotate(img1, 179).then(newsrc1 => {
            rotate(newsrc1, 143).then(newsrc => {
                let initPP = "1060,2076 1060,1900 1482,2616 1482,2629 1482,2635 1242,2804"
                    .split(" ")
                    .map((c) => c.split(",").map(Number))
                    .map(([x, y]) => ({ x, y }));

                getCanvas(newsrc, initPP, (err, canvas) => {
                    if (!err) {
                        setSaree5(canvas.toDataURL());
                        setIsReadySari5(true)
                    }
                });

            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///Saree item 6
    const [saree6, setSaree6] = useState("");
    const GetSaree6 = () => {
        setIsReadySari6(false)
        rotate(img1, 179).then(newsrc1 => {
            rotate(newsrc1, 159).then(newsrc => {
                let initPP = "675,1577 675,2511 892,2433 905,2427 905,2417 898,2395"
                    .split(" ")
                    .map((c) => c.split(",").map(Number))
                    .map(([x, y]) => ({ x, y }));

                getCanvas(newsrc, initPP, (err, canvas) => {
                    if (!err) {
                        setSaree6(canvas.toDataURL());
                        setIsReadySari6(true)
                    }
                });
            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///Saree item 7
    const [saree7, setSaree7] = useState("");
    const GetSaree7 = () => {
        setIsReadySari7(false)
        let cbg = PoseBg(parseInt(bg))
        resizeImage(cbg, 1400, 2100).then(newsrc => {
            setSaree7(newsrc);
            setIsReadySari7(true)
        }).catch(err => {
        })
    }
    ///////////////////////////////////////////
    //#endregion
    useEffect(() => {
        if (isReadySari1 && isReadySari2 && isReadySari3 && isReadySari4 && isReadySari5 && isReadySari6 && isReadySari7) {
            MergeSaree()
            setIsCompleted("Merging Images")
            setIsReadySari1(false)
            setIsReadySari2(false)
            setIsReadySari3(false)
            setIsReadySari4(false)
            setIsReadySari5(false)
            setIsReadySari6(false)
            setIsReadySari7(false)
        }
    }, [isReadySari1, isReadySari2, isReadySari3, isReadySari4, isReadySari5, isReadySari6, isReadySari7])

    useEffect(() => {
        if (img1 && img2 && status == "init") {
            //alert("allimageloaded")
            setIsCompleted("Processing")
            setDrapResult()
            GetSaree1()
            GetSaree2()
            GetSaree3()
            GetSaree4()
            GetSaree5()
            GetSaree6()
            GetSaree7()
        }
    }, [img1, img2, status])

    return (
        <></>
    )
}
