import React from 'react'
import Moment from 'react-moment'
import { getPageMargins } from '../../config/GlobalSettings'
import { GetEBoolName } from '../../enumCollections/EBool'
import { ContactViewS } from '../Global/Elements/ContactViewS'
import { ProductionUnitViewS } from '../Global/Elements/ProductionUnitViewS'
import { ProductViewS } from '../Global/Elements/ProductViewS'
import { WarehouseViewS } from '../Global/Elements/WarehouseViewS'
import { PrintBranding } from './PrintBranding'

export const InitOrderPrintMulti = React.forwardRef((props, ref) => {
    const datas = props.printData
    return (
        <div ref={ref}>
            <style type="text/css">
                {getPageMargins()}
            </style>
            {
                datas && datas.length > 0 &&
                datas.map((data, index) =>
                    <>
                        <PrintBranding printName="Order" date={data.onDate} id={data.id}>
                            {
                                data &&
                                <div className="row">
                                    <div className="col-xs-12">

                                        <table style={{ width: "100%" }} className="table-bordered-dark">
                                            <tbody>
                                                <tr >
                                                    <td>Due Date</td>
                                                    <td>
                                                        <Moment format="DD-MMM-YYYY">{data.dueDate}</Moment>
                                                    </td>
                                                    <td>
                                                        <span className="pull-right">
                                                            <WarehouseViewS item={data.warehouse} />
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td>Product</td>
                                                    <td colspan="2">
                                                        <ProductViewS item={data.product} />
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td>Quantity</td>
                                                    <td colspan="2">{data.quantity} {data.unit}</td>
                                                </tr>
                                                <tr >
                                                    <td>Alloted For</td>
                                                    <td colspan="2">
                                                        <ContactViewS item={data.contact} />
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td>Production</td>
                                                    <td colspan="2">
                                                        <ProductionUnitViewS item={data.forProductionUnitData} />
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td>Details</td>
                                                    <td colspan="2">
                                                        {data.details ? data.details : "NA"}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            }
                        </PrintBranding>
                        <div style={{ pageBreakBefore: 'always' }}></div>
                    </>
                )
            }
        </div>
    )
})