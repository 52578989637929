import React, { useContext, useState } from 'react'
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { GetEBoolName } from '../../../enumCollections/EBool';
import { GetEPaymentStatusName } from '../../../enumCollections/EPaymentStatus';
import { ContactView } from '../../Global/Elements/ContactView';
import { LotView } from '../../Global/Elements/LotView';
import { ProcessingView } from '../../Global/Elements/ProcessingView';
import { ProductionUnitView } from '../../Global/Elements/ProductionUnitView';
import { ProductView } from '../../Global/Elements/ProductView';
import { UserView } from '../../Global/Elements/UserView';

export function ProductReceivingItemView({ productReceiving }) {

    return (
        <>
            <div className="widget-box">
                <div class="widget-header widget-header-flat widget-header-small">
                    <h5 class="widget-title">
                        Details
                    </h5>
                </div>
                {
                    productReceiving &&
                    <div className="row">
                        <div class="profile-user-info profile-user-info-striped">
                            <div class="profile-info-row">
                                <div class="profile-info-name"> Receiving Id</div>

                                <div class="profile-info-value">
                                    <span ><b>{productReceiving.id}</b></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> On Date</div>

                                <div class="profile-info-value">
                                    <span ><b><Moment format="DD-MMM-YYYY">{productReceiving.onDate}</Moment></b></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Contact </div>

                                <div class="profile-info-value">
                                    <Link to={"/contact/dashboard/" + productReceiving.contactId}>
                                        <ContactView item={productReceiving.contact} uid={"GC1"} />
                                    </Link>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Production </div>

                                <div class="profile-info-value">
                                    <Link to={"/productionunit/dashboard/" + productReceiving.productionUnitId}>
                                        <ProductionUnitView item={productReceiving.productionUnit} />
                                    </Link>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Product </div>

                                <div class="profile-info-value">
                                    <Link to={"/product/dashboard/" + productReceiving.product.id}>
                                        <ProductView item={productReceiving.product} />
                                    </Link>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Wage/Unit </div>

                                <div class="profile-info-value">
                                    <span >₹{Number(productReceiving.productWage.wage).toFixed(2)}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Fresh </div>

                                <div class="profile-info-value">
                                    <span >{productReceiving.freshQuantity} {productReceiving.unit}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Defective </div>

                                <div class="profile-info-value">
                                    <span >{productReceiving.defectiveQuantity} {productReceiving.unit}</span>
                                </div>
                            </div>


                            <div class="profile-info-row">
                                <div class="profile-info-name"> Rejected </div>

                                <div class="profile-info-value">
                                    <span >{productReceiving.rejectedQuantity} {productReceiving.unit}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Vouchered </div>

                                <div class="profile-info-value">
                                    <span>{productReceiving.isVouchered ? <Link to={"/productionvoucher/dashboard/" + productReceiving.voucherId}>{productReceiving.voucherId}</Link> : <span class="label label-sm label-danger">{GetEBoolName(productReceiving.isVouchered)}</span>}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Fresh To Main </div>

                                <div class="profile-info-value">
                                    <span class={productReceiving.isFreshQuantityToMain == 1 ? "label label-sm label-success" : "label label-sm label-danger"}>{GetEBoolName(productReceiving.isFreshQuantityToMain)}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Lot </div>

                                <div class="profile-info-value">
                                    <span > <LotView item={productReceiving.lot} /></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Lot Closed </div>

                                <div class="profile-info-value">
                                    <span class={productReceiving.isLotClosed == 1 ? "label label-sm label-success" : "label label-sm label-danger"}>{GetEBoolName(productReceiving.isLotClosed)}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Note </div>

                                <div class="profile-info-value">
                                    <span >{productReceiving.note}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Payment Status </div>

                                <div class="profile-info-value">
                                    <span class={productReceiving.paymentStatus == 1 ? "label label-sm label-warning" : "label label-sm label-default"}>{GetEPaymentStatusName(productReceiving.paymentStatus)}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Created By </div>
                                <div class="profile-info-value">
                                    <span><UserView item={productReceiving.createdByUser} uid={"GD1"} createdOn={productReceiving.createdOn} /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

