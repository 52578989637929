import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import AppToaster from '../../commonLib/AppToaster'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext'
import * as productionUnitCrud from '../../_crud/productionUnitCrud'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { Container } from '../../base/Container'
import { LoadingButton } from '../../commonLib/LoadingButton'
import ProductionUnitSelector from '../Global/ProductionUnitSelector';
import { Link } from 'react-router-dom'
import { ProductionSelectorView } from '../Global/SelectorView/ProductionSelectorView'

export function AddProductionUnitAllotment() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const { id } = useParams()

    const [selectedData, setSelectedData] = useState(undefined);
    useEffect(() => {
        if (selectedData) {
            formik.setValues({ ...formik.values, producationUnitId: selectedData.id })
        }
    }, [selectedData]);

    const initialValues = {
        contactId: id,
        producationUnitId: ""
    }
    const Schema = Yup.object().shape({
        producationUnitId: Yup.string().required("Production Unit is required.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    const Save = (value) => {
        if (id != selectedData.contact.id) {
            setIsLoadingMini(true);
            setIsLoadingLocal(true)
            productionUnitCrud.ChangeContact(value)
                .then(res => {
                    if (res.data.succeeded) {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false);
                        AppToaster('Alloted successfully.', 'success')
                    }
                    else {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                })
                .catch(error => {
                    setIsLoadingMini(false);
                    setIsLoadingLocal(false);
                    AppToaster(error, 'error')
                })
        }
        else {
            AppToaster("You could not allot production unit to same contact. ", 'error')
        }
    }

    return (

        <Container title="Allot Production Unit" isBack="true" isContact="true">
            <div class="col-sm-12">
                <label class="col-sm-2 control-label no-padding-right"> Select Production Unit</label>
                <div class="col-sm-10">
                    <ProductionUnitSelector searchtext="" setSelectedData={setSelectedData} />
                </div>
                <ProductionSelectorView data={selectedData} />
            </div>

          


            <div class="col-sm-12">
                <br />
                <form onSubmit={formik.handleSubmit} class="form-horizontal">
                    <div class="clearfix form-actions">
                        <div class="pull-right">
                            {isLoadingLocal ?
                                <LoadingButton />
                                :
                                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Allot
                                </button>}
                        </div>
                    </div>
                </form>
            </div>
        </Container>
    )
}
