import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken, createAxiosWithTokenFile } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetOrganization() {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxios(OrgId()).get("Organization/GetOrganization")
    }
}
export async function EditOrganization(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Organization/EditOrganization", param)
    }
}

export async function UploadLogo(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithTokenFile(loginData.token, OrgId()).post("Organization/UploadLogo", param)
    }
}