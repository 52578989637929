import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../../base/Container'
import AppToaster from '../../commonLib/AppToaster'
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { LoadingButton } from '../../commonLib/LoadingButton'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext'
import * as defectManagementCrud from '../../_crud/defectManagementCrud';
import { useParams } from 'react-router-dom';
import GetCurrentDateForSearch from '../../commonLib/dateFormat';
import DatePicker from "react-datepicker";
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

export function AddDefectManagementContact() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const history = useHistory();
    const { id } = useParams()

    const initialValues = {
        contactId: id,
        onDate: new Date(GetCurrentDateForSearch(0)),
        amountToDeduct: 0,
        note: ""
    }

    const Schema = Yup.object().shape({
        contactId: Yup.string().required("Contact is required."),
        onDate: Yup.string().required("on Date is required."),
        amountToDeduct: Yup.string().required("Amount To Deduct is required.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        defectManagementCrud.Add(value)
            .then(res => {
                if (res.data.result.succeeded) {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster('Added successfully.', 'success')
                    history.goBack()
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error / Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }
    return (
        <>
            <Container title="Add Defect" isBack="true" isContact="true">
                <form onSubmit={formik.handleSubmit} class="form-horizontal">
                    <div className="row">
                        <div className="col-md-3">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Date</label>
                                    <span class="input-icon" style={{ width: "100%" }}>
                                        <DatePicker placeholder="onDate" name="onDate" className="form-control input-date-padding" selected={formik.values.onDate} onChange={(date) => formik.setValues({ ...formik.values, onDate: date })} />
                                        <i class="ace-icon fa fa-calendar blue"></i>
                                    </span>
                                    {
                                        formik.touched.onDate &&
                                            formik.errors.onDate ? (
                                            <label className="text-danger" >
                                                {formik.errors.onDate}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Amount To Deduct</label>
                                    <input className="form-control" min="0" type="number" placeholder="Amount" name="amountToDeduct" {...formik.getFieldProps("amountToDeduct")} />
                                    {
                                        formik.touched.amountToDeduct &&
                                            formik.errors.amountToDeduct ? (
                                            <label className="text-danger" >
                                                {formik.errors.amountToDeduct}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right"> Note</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Note" name="note" value={formik.values.note} onChange={(e) => formik.setValues({ ...formik.values, note: e.target.value })} />
                                    {
                                        formik.touched.note &&
                                            formik.errors.note ? (
                                            <label className="text-danger" >
                                                {formik.errors.note}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix form-actions">
                        <div class="pull-right">
                            {isLoadingLocal ?
                                <LoadingButton />
                                :
                                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Add
                                </button>}
                        </div>
                    </div>

                </form>
            </Container >
        </>
    )
}

