import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Container } from '../../../base/Container'
import AppToaster from '../../../commonLib/AppToaster'
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext'
import { Spinner } from '../../../commonLib/Spinner'
import * as reportCrud from '../../../_crud/reportCrud'
import * as productionCategoryCrud from '../../../_crud/productionCategoryCrud'
import * as warehouseCrud from '../../../_crud/warehouse/warehouseCrud'
import { PagingEmpty } from '../../../commonLib/PagingEmpty'
import { UserView } from '../../Global/Elements/UserView'
import { EmptyData } from '../../../commonLib/EmptyData'
import * as Yup from "yup";
import { useFormik } from 'formik';
import GetLoginInfo from '../../../auth/_userContext/GetLoginInfo'
import Moment from 'react-moment'
import { WarehouseView } from '../Elements/WarehouseView'
import { ContactView } from '../Elements/ContactView'
import { StatusView } from '../../../commonLib/StatusView'

export function LastActiveContacts() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [contact, setContact] = useState([])
    const [warehouse, setWarehouse] = useState([])
    const [productionCategories, setProductionCategories] = useState([])
    const userData = GetLoginInfo()

    const initialValues = {
        warehouseId: userData.isWarehouse == 1 ? userData.warehouseId : 0,
        daysForInactive: 10,
        productionCatId: 0,
    }

    const Schema = Yup.object().shape({
        daysForInactive: Yup.string().required("Days For Inactive is required."),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            getData();
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    useEffect(() => {
        getWarehouse()
        getCategories()
        getData()
    }, []);

    function getData() {
        setIsLoadingMini(true);
        reportCrud.GetActivityByContact(formik.values)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setContact(hdata);
                    }
                    else {
                        setContact([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setContact([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        getData()
    }
    function getWarehouse() {
        warehouseCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setWarehouse(hdata);
                        //alert(JSON.stringify(hdata))
                    }
                    else {
                        setWarehouse([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setWarehouse([]);
                }
            })
            .catch(error => {
                AppToaster(error, 'error')
            })
    }
    function getCategories() {
        productionCategoryCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProductionCategories(hdata);
                    }
                    else {
                        setProductionCategories([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProductionCategories([]);
                }
            })
            .catch(error => {
                AppToaster(error, 'error')
            })
    }
    return (
        <>
            <Container title="Last Active Contacts" doRefresh={doRefresh}>
                <div class="form-horizontal" >
                    <div className="row">
                        {
                            userData.isWarehouse == 0 &&
                            <div className="col-md-3">
                                <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                    <label class="control-label no-padding-right">Warehouse</label>
                                    <select className="form-control" name="warehouseId" value={formik.values.warehouseId} onChange={(e) => formik.setValues({ ...formik.values, warehouseId: e.target.value })}>
                                        <option value="0" >All</option>
                                        {
                                            warehouse.map((data, index) =>
                                                <option key={data.id} value={data.id}>{data.name.toUpperCase()} ({data.warehouseCode.toUpperCase()})</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                        }
                        <div className="col-md-3">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <label class="control-label no-padding-right">Category</label>
                                <select className="form-control" name="productionCatId" value={formik.values.productionCatId} onChange={(e) => formik.setValues({ ...formik.values, productionCatId: e.target.value })}>
                                    <option value="0" >All</option>
                                    {
                                        productionCategories.map((data, index) =>
                                            <option key={data.id} value={data.id}>{data.productionCatName.toUpperCase()}</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <label class="control-label no-padding-right">Days for Inactive</label>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <input name="daysForInactive" value={formik.values.daysForInactive} onChange={(e) => formik.setValues({ ...formik.values, daysForInactive: e.target.value })} className="form-control" type="number" min={1} placeholder="Days For Inactive" />
                                    <i class="ace-icon fa fa-search blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-1">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <label class="control-label no-padding-right">Action</label>
                                <button onClick={doRefresh} class="form-control btn btn-sm btn-info" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-search bigger-110"></i>
                                    Run
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="hr dotted"></div>
                <PagingEmpty text={"Total " + contact.length + " items."}>
                    {isLoadingMini ?
                        <Spinner />
                        :
                        contact && contact.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Branch</th>
                                            <th>Contact</th>
                                            <th>Category</th>
                                            <th>Last Activity On</th>
                                            <th style={{ width: "43px" }}>By</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {contact.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>{index + 1}</td>
                                                <td><WarehouseView item={item.warehouse} uid={"WH" + index} /></td>
                                                <td>
                                                    <Link to={"/contact/dashboard/" + item.id} >
                                                        <ContactView item={item} uid={"C" + index} />
                                                    </Link>
                                                </td>
                                                <td>
                                                    {
                                                        item.productionCategory ?
                                                            <StatusView status={item.productionCategory.id}>
                                                                {item.productionCategory.productionCatName}
                                                            </StatusView>
                                                            : "NA"
                                                    }
                                                </td>
                                                <td><b><div style={{ width: "100px" }}><Moment format="DD-MMM-YYYY HH:mm">{item.lastActivity}</Moment></div></b> </td>
                                                <td>
                                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </PagingEmpty>
            </Container >
        </>
    )
}
