import React, { useState } from "react"

export const dashboardContext = React.createContext()

const init = {
    contactdata:undefined,
    productiondata:undefined,
    productdata:undefined,
    processingdata:undefined,
    orderdata:undefined,
    voucherdata:undefined,
}

const DashboardProvider = (props) => {
    const [dashboardData, setDashboardData] = useState(init);
    return (
        <dashboardContext.Provider value={[dashboardData, setDashboardData]}>
            {props.children}
        </dashboardContext.Provider>
    )
}

export default DashboardProvider;