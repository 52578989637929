import React, { useEffect, useState } from 'react'
import { IsMobile } from '../../../initApi/apiUrls';
import * as reportCrud from '../../../_crud/reportCrud'
import { BaithkaLineChart } from './BaithkaLineChart';
import { MobileChart } from './MobileChart';
const today = new Date()

export function CashBankFlowChart() {
    const [cash, setCash] = useState([])
    const [bank, setBank] = useState([])

    const [isDisplay, setIsDisplay] = useState(true)

    const [month, setMonth] = useState(today.getMonth() + 1)
    const [year, setYear] = useState(today.getFullYear())

    const [isLocalLoading, setIsLocalLoading] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    function getData() {
        setIsLocalLoading(true);
        reportCrud.GetCurrentMonthCashBankFlow(month, year)
            .then(res => {
                if (res.data) {
                    if (res.data.result.succeeded) {
                        let hcash = res.data.cash
                        let hbank = res.data.bank
                        if ((!hcash || hcash.length < 1) && (!hbank || hbank.length < 1))
                            setIsDisplay(false)
                        setCash(hcash);
                        setBank(hbank);
                        setIsLocalLoading(false)
                    }
                    else {
                        setIsLocalLoading(false)
                        setCash([]);
                        setBank([]);
                    }
                }
                else {
                    setIsLocalLoading(false)
                    setCash([]);
                    setBank([]);
                }
            })
            .catch(error => {
                setIsLocalLoading(false);
            })
    }

    return (

        <>
            {isDisplay &&
                <div className="widget-box">
                    <div class="widget-header widget-header-flat widget-header-small">
                        <h5 class="widget-title">
                            Chart Cash Bank Flow
                        </h5>
                        <div class="widget-toolbar">
                            <a onClick={getData} title="Reload">
                                <i class="ace-icon fa fa-refresh"></i>
                            </a>
                        </div>
                    </div>
                    {IsMobile ?
                        <div className="row">
                            <div className="col-md-12" style={{ width: "100%" }}>
                                <MobileChart month={month} year={year} loading={isLocalLoading} cash={cash} bank={bank} />
                            </div>
                        </div>
                        :
                        <div className="row" style={{ margin: "8px" }}>
                            <div className="col-md-12" style={{ width: "100%" }}>
                                <BaithkaLineChart loading={isLocalLoading} cash={cash} bank={bank} month={month} year={year} />
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    )
}
