import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import AppToaster from '../../commonLib/AppToaster';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import * as productConfigCrud from '../../_crud/productConfigCrud'
import { Spinner } from '../../commonLib/Spinner';
import { Container } from '../../base/Container';
import { PagingEmpty } from '../../commonLib/PagingEmpty';
import 'moment-timezone';
import { EmptyData } from '../../commonLib/EmptyData';
import { MyAlert } from '../../commonLib/MyAlert';
import { UserView } from '../Global/Elements/UserView';

export function ManageConfiguration() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [configuration, setConfiguration] = useState([])
    const { id } = useParams()

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoadingMini(true);
        productConfigCrud.GetByProductId(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setConfiguration(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setIsLoadingMini(false)
                        setConfiguration([]);
                    }
                }
                else {
                    setIsLoadingMini(false)
                    setConfiguration([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        getData()
        setTimeout(() => {
            event.detail.complete();
        }, 1000);
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        productConfigCrud.Remove(id)
            .then(res => {
                setIsLoadingMini(false)
                AppToaster('Deleted successfully.', 'success')
                getData()

            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster("Network error", 'error')
            })
    }

    return (
        <>
            <Container title="Product Configuration" doRefresh={doRefresh} isBack="true" isProduct="true">
                <Link to={"/product/dashboard/" + id + "/configuration/add"} class="btn btn-sm btn-success">
                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                    New
                </Link>
                <div class="hr dotted"></div>
                <PagingEmpty text={"Total " + configuration.length + " items."}>
                    {isLoadingMini ?
                        <Spinner />
                        :
                        configuration && configuration.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "43px" }}>#Id</th>
                                            <th>Name</th>
                                            <th>Type</th>
                                            <th>Value</th>
                                            <th style={{ width: "43px" }}>By</th>
                                            <th style={{ width: "43px" }}></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {configuration.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>{index + 1}</td>
                                                <td>{item.configName.toUpperCase()}</td>
                                                <td>{item.type}</td>
                                                <td> {item.value}</td>
                                                <td>
                                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                            <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                        </MyAlert>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </PagingEmpty>
            </Container >

        </>
    )
}
