import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import GetLoginInfo from '../../auth/_userContext/GetLoginInfo'
import { Container } from '../../base/Container'
import AppToaster from '../../commonLib/AppToaster'
import { EmptyData } from '../../commonLib/EmptyData'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext'
import { Paging } from '../../commonLib/Paging'
import { Spinner } from '../../commonLib/Spinner'
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater'
import { PAGING_PAGE_SIZE } from '../../config/GlobalSettings'
import { GetEBoolName } from '../../enumCollections/EBool'
import * as productContactStockCrud from '../../_crud/productContactStockCrud'
import { ContactView } from '../Global/Elements/ContactView'
import { ProductionUnitView } from '../Global/Elements/ProductionUnitView'
import { ProductView } from '../Global/Elements/ProductView'

const initValues = {
  searchTerm: "",
  pageNo: 1,
  pageSize: PAGING_PAGE_SIZE
}

export function ManageProductStocks() {

  const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
  const [search, setSearch] = useState(initValues)
  const [productStock, setProductStock] = useState([])
  const [LastPage, setLastPage] = useState(1)
  const [pagingData, setPagingData] = useState()
  const userData = GetLoginInfo()

  const OnPageChange = (e) => {
    const { value } = e.target
    setSearch({ ...search, pageNo: value });
  }

  useEffect(() => {
    onSearch()
  }, [search.pageNo])


  function onSearch() {
    if (userData.isWarehouse == 0)
      getData()
    else
      getDataWarehouse()
  }

  function getData() {
    setIsLoadingMini(true);
    productContactStockCrud.GetAll(search)
      .then(res => {
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            let paging = res.data.paging
            setPagingData(paging)
            setLastPage(paging.pageCount)
            setProductStock(hdata);
            setIsLoadingMini(false)
          }
          else {
            setLastPage(1)
            setIsLoadingMini(false)
            setProductStock([]);
          }
        }
        else {
          setLastPage(1)
          setIsLoadingMini(false)
          setProductStock([]);
          AppToaster("Server Error / Invalid Try.", 'error')
        }
      })
      .catch(error => {
        setLastPage(1)
        setIsLoadingMini(false);
        AppToaster(error, 'error')
      })
  }
  function getDataWarehouse() {
    setIsLoadingMini(true);
    productContactStockCrud.GetAllWarehouse({ Search: { ...search }, WarehouseId: userData.warehouseId })
      .then(res => {
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            let paging = res.data.paging
            setPagingData(paging)
            setLastPage(paging.pageCount)
            setProductStock(hdata);
            setIsLoadingMini(false)
          }
          else {
            setLastPage(1)
            setIsLoadingMini(false)
            setProductStock([]);
          }
        }
        else {
          setLastPage(1)
          setIsLoadingMini(false)
          setProductStock([]);
          AppToaster("Server Error / Invalid Try.", 'error')
        }
      })
      .catch(error => {
        setLastPage(1)
        setIsLoadingMini(false);
        AppToaster(error, 'error')
      })
  }
  function doRefresh() {
    onSearch()
  }

  return (
    <Container title="Product Contact Stock" doRefresh={doRefresh} >
      <div class="form-horizontal" >
        <div className="row">
          <div className="col-md-11">
            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
              <span class="input-icon" style={{ width: "100%" }}>
                <TextBoxTranslater style={{paddingLeft: "24px"}} value={search.searchTerm} onChange={(e) => setSearch({ ...search, searchTerm: e.target.value })} className="form-control" type="text" placeholder="Search Product" />
                <i class="ace-icon fa fa-search blue"></i>
              </span>
            </div>
          </div>
          <div className="col-md-1">
            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
              <button onClick={onSearch} to="/product/add" class="btn btn-sm btn-info pull-right">
                <i class="ace-icon fa fa-search bigger-110"></i>
                Run
              </button>
            </div>
          </div>
        </div>
      </div>
      <Paging
        pagingData={pagingData}
        LastPage={LastPage}
        CurrentPage={search.pageNo}
        OnPageChange={OnPageChange} >
        {isLoadingMini ?
          <Spinner />
          :
          productStock && productStock.length > 0 ?
            <div className="tables-scroll">
              <table className="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th>#Id</th>
                    <th>Contact</th>
                    <th>Production</th>
                    <th>Product</th>
                    <th>Availabe</th>
                    <th>Used</th>
                    <th>Total Given</th>
                  </tr>
                </thead>


                <tbody>
                  {productStock.map((item, index) =>
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>
                        <Link to={"/contact/dashboard/" + item.contactId}>
                          <ContactView item={item.contact} uid={"C" + index} />
                        </Link>
                      </td>
                      <td>
                        <Link to={"/productionunit/dashboard/" + item.productionUnitId}>
                          <ProductionUnitView item={item.productionUnit} />
                        </Link>
                      </td>
                      <td>
                        <Link to={"/product/dashboard/" + item.productId}>
                          <ProductView item={item.product} uid={"PP" + index} />
                        </Link>
                      </td>
                      <td>{Number(item.stock).toFixed(2)} {item.unit}</td>
                      <td>{Number(item.globalStock - item.stock).toFixed(2)} {item.unit}</td>
                      <td>{Number(item.globalStock).toFixed(2)} {item.unit}</td>

                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            :
            <EmptyData>
              No records found.
              <br />
              <br />
            </EmptyData>
        }
      </Paging>
    </Container >
  )
}