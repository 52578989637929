const GetEVoucherLinkedIdTypeName = (id) => {
    switch (id) {
        case 1: { return "Contact" }
        case 2: { return "Ledger" }
        case 3: { return "Voucher" }
        case 4: { return "None" }

    }
}

const GetEVoucherLinkedIdType = {
    EVoucherLinkedIdType: [
        { Id: 1, Name: "Contact" },
        { Id: 2, Name: "Ledger" },
        { Id: 3, Name: "Voucher" },
        { Id: 4, Name: "None" }

    ]
}

export { GetEVoucherLinkedIdTypeName, GetEVoucherLinkedIdType }