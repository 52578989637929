import React, { useContext, useEffect, useState } from 'react'
import AppToaster from '../../commonLib/AppToaster';
import *as Yup from "yup"
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import * as productConsumptionCrud from '../../_crud/productConsumptionCrud'
import * as productCrud from '../../_crud/productCrud'
import { Container } from '../../base/Container';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { LoadingButton } from '../../commonLib/LoadingButton';
import ProductSelector from '../Global/ProductSelector';
import { ProductSelectorView } from '../Global/SelectorView/ProductSelectorView';

export function AddProductConsumption() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const history = useHistory();
    const [productsSelect, setProductsSelect] = useState([])
    const { id } = useParams()
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [selectedProductData, setSelectedProductData] = useState(undefined);

    const initialValues = {
        productId: id,
        consumeProductId: undefined,
        consumptionQuantity: 0,
        unit: "",
        isPerUnitConsume:0
    }

    const Schema = Yup.object().shape({
        productId: Yup.string().required("Product is required."),
        consumeProductId: Yup.string().required("Product Consume is required."),
        unit: Yup.string().required("Unit is required."),
        consumptionQuantity: Yup.string().required("Consumption Quantity is required."),
        isPerUnitConsume: Yup.string().required("Is Per Unit Consume is required.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });


    useEffect(() => {
        if (selectedProductData) {
            formik.setValues({ ...formik.values, consumeProductId: selectedProductData.id, unit: selectedProductData.unit, quantity: 0 })
        }
    }, [selectedProductData])

    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        productConsumptionCrud.Add(value)
            .then(res => {
                if (res.data.result.succeeded) {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster('Added successfully.', 'success')
                    history.goBack()
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }
    useEffect(() => {
        getProducts()
    }, []);

    function getProducts() {
        setIsLoadingMini(true);
        productCrud.GetAllForSelect()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProductsSelect(hdata);
                    }
                    else {
                        setProductsSelect([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProductsSelect([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    return (
        <>
            <Container title="Add Consumption " isBack="true" isProduct="true">
                <form onSubmit={formik.handleSubmit} class="form-horizontal">
                    <div className="row">
                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Consume Product</label>
                                    <ProductSelector searchtext="" setSelectedData={setSelectedProductData} />
                                    {
                                        formik.touched.consumeProductId &&
                                            formik.errors.consumeProductId ? (
                                            <label className="text-danger" >
                                                {formik.errors.consumeProductId}
                                            </label>
                                        ) : null
                                    }
                                </div>
                                <ProductSelectorView data={selectedProductData} />
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Quantity- {formik.values.unit}</label>
                                    <input className="form-control" type="number" placeholder="Quantity" name="consumptionQuantity" {...formik.getFieldProps("consumptionQuantity")} />
                                    {
                                        formik.touched.consumptionQuantity &&
                                            formik.errors.consumptionQuantity ? (
                                            <label className="text-danger" >
                                                {formik.errors.consumptionQuantity}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-sm-9">
                                    <label class="control-label no-padding-right">Is Per Unit Consume</label>
                                    <div>
                                        <input onChange={() => formik.setValues({ ...formik.values, isPerUnitConsume: (formik.values.isPerUnitConsume == 1 ? 0 : 1) })} checked={formik.values.isPerUnitConsume == 1} type="checkbox" class="ace ace-switch ace-switch-5" />
                                        <span class="lbl middle"></span>
                                    </div>
                                    {
                                        formik.touched.isPerUnitConsume &&
                                            formik.errors.isPerUnitConsume ? (
                                            <label className="text-danger" >
                                                {formik.errors.isPerUnitConsume}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="clearfix form-actions">
                        <div class="pull-right">
                            {isLoadingLocal ?
                                <LoadingButton />
                                :
                                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Add
                                </button>}
                        </div>
                    </div>
                </form>
            </Container >
        </>
    )
}
