import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import { EmptyData } from '../../commonLib/EmptyData'
import { getPageMargins } from '../../config/GlobalSettings'
import { GetELedgerAssetSalaryName } from '../../enumCollections/ELedgerAssetSalary'
import { GetEPaymentStatusName } from '../../enumCollections/EPaymentStatus'
import { ContactViewS } from '../Global/Elements/ContactViewS'
import { ProductionUnitViewS } from '../Global/Elements/ProductionUnitViewS'
import { ProductViewS } from '../Global/Elements/ProductViewS'
import { PrintBranding } from './PrintBranding'

export const ProductionVoucherPrint = React.forwardRef((props, ref) => {
    const { paid, pending, productionVoucher, voucherPayment, voucherDetails } = props
    const [detailsCount, setDetailsCount] = useState({ fresh: 0, defective: 0, rejected: 0 });

    useEffect(() => {
        if (voucherDetails && voucherDetails.length > 0) {
            let cnt = { fresh: 0, defective: 0, rejected: 0 }
            voucherDetails.forEach(element => {
                cnt.fresh = cnt.fresh + element.productReceiving.freshQuantity
                cnt.defective = cnt.defective + element.productReceiving.defectiveQuantity
                cnt.rejected = cnt.rejected + element.productReceiving.rejectedQuantity
            });

            setDetailsCount({ ...cnt })
        }
    }, [voucherDetails]);

    return (
        <div ref={ref}>
            <style type="text/css">
                {getPageMargins()}
            </style>
            {
                productionVoucher &&
                <PrintBranding printName="Production Voucher" id={productionVoucher.id} date={productionVoucher.voucherDate}>

                    {
                        productionVoucher &&
                        <div className="row">
                            <div className="col-xs-12">

                                <table style={{ width: "100%" }} className="table-bordered-dark">
                                    <tbody>
                                        <tr >
                                            <td>For Contact</td>
                                            <td>
                                                <ContactViewS item={productionVoucher.contact} uid={"CXV1"} />
                                            </td>
                                        </tr>
                                        <tr >
                                            <td>Phone</td>
                                            <td>{productionVoucher.contact.phone}</td>
                                        </tr>
                                        <tr >
                                            <td>Address</td>
                                            <td>{productionVoucher.contact.address}</td>
                                        </tr>
                                        <tr >
                                            <td>Email</td>
                                            <td>{productionVoucher.contact.email ? productionVoucher.contact.email : "NA"}</td>
                                        </tr>
                                        <tr >
                                            <td>Quantity</td>
                                            <td>
                                                Fresh: <b>{Number(detailsCount.fresh).toFixed(2)}</b>
                                                &nbsp;&nbsp;Defective: <b>{Number(detailsCount.defective).toFixed(2)}</b>
                                                &nbsp;&nbsp;Rejected: <b>{Number(detailsCount.rejected).toFixed(2)}</b>
                                                &nbsp;&nbsp;Total: <b>{Number(detailsCount.fresh + detailsCount.defective + detailsCount.rejected).toFixed(2)}</b>
                                            </td>
                                        </tr>
                                        <tr >
                                            <td>Details</td>
                                            <td>{productionVoucher.linkedDetails}</td>
                                        </tr>
                                        <tr >
                                            <td>Amount</td>
                                            <td><b>{Number(productionVoucher.totalAmount).toFixed(2)}</b></td>
                                        </tr>
                                        <tr >
                                            <td><b>Voucher Balance</b></td>
                                            <td><span>Paid: <b>{Number(paid).toFixed(2)}</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pending: <b>{Number(pending).toFixed(2)}</b> </span></td>
                                        </tr>
                                        <tr >
                                            <td>Note</td>
                                            <td>{productionVoucher.note ? productionVoucher.note : "NA"}</td>
                                        </tr>
                                        <tr >
                                            <td>Status</td>
                                            <td>{GetEPaymentStatusName(productionVoucher.paymentStatus)}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    }
                    <br />

                    <div className="row">
                        <div className="col-xs-12">
                            <h5 className="text-center">Payments</h5>
                            {voucherPayment && voucherPayment.length > 0 ?
                                <table style={{ width: "100%" }} className="table-bordered-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Date</th>
                                            <th>Amount</th>
                                            <th>By</th>
                                            <th>Details</th>
                                            <th>Note</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {voucherPayment.map((item, index) =>
                                            <tr key={item.Id}>
                                                <td>{index + 1}</td>
                                                <td><Moment format="DD-MMM-YYYY">{item.receivedDate}</Moment></td>
                                                <td><b>₹ {item.amount}</b></td>
                                                <td>{GetELedgerAssetSalaryName(parseInt(item.fromLedgerId))}</td>
                                                <td>{item.transactionDetails}</td>
                                                <td>{item.note}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                :
                                <EmptyData>
                                    No payment made.
                                    <br />
                                    <br />
                                </EmptyData>
                            }
                        </div>
                    </div>
                    <br />


                </PrintBranding>

            }
            <div style={{ pageBreakBefore: 'always' }}></div>
            {productionVoucher && voucherDetails && voucherDetails.length > 0 &&
                <PrintBranding printName="Production Voucher Details" id={productionVoucher.id} date={productionVoucher.voucherDate}>
                    <div className="row">
                        <div className="col-xs-12">
                            {voucherDetails && voucherDetails.length > 0 ?
                                <table style={{ width: "100%" }} className="table-bordered-dark">
                                    <thead>
                                        <tr>
                                            <th>#Id</th>
                                            <th>On Date</th>
                                            <th>Production</th>
                                            <th>Product</th>
                                            <th>Wage/unit</th>
                                            <th>Fresh</th>
                                            <th>Defective</th>
                                            <th>Rejected</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {voucherDetails.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>{item.productReceiving.id}</td>
                                                <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.productReceiving.onDate}</Moment></div></td>
                                                <td>
                                                    <ProductionUnitViewS item={item.productReceiving.productionUnit} />
                                                </td>

                                                <td>
                                                    <ProductViewS item={item.productReceiving.product} uid={"PP" + index} />
                                                </td>

                                                <td>₹{Number(item.productReceiving.productWage.wage).toFixed(2)}</td>
                                                <td>{item.productReceiving.freshQuantity} {item.productReceiving.unit}</td>
                                                <td>{item.productReceiving.defectiveQuantity} {item.productReceiving.unit}</td>
                                                <td>{item.productReceiving.rejectedQuantity} {item.productReceiving.unit}</td>

                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                :
                                <EmptyData>
                                    No payment made.
                                    <br />
                                    <br />
                                </EmptyData>
                            }
                        </div>
                    </div>
                </PrintBranding>}
        </div >
    )
})
