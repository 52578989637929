import React, { useContext, useEffect, useState } from 'react'
import Autocomplete from "react-autocomplete";
import './../Global/AutoComplete.css';
import * as productCrud from '../../_crud/productCrud'
import * as warehouseCrud from '../../_crud/warehouse/warehouseCrud'
import useDebounce from './useDebounce';
import GetLoginInfo from '../../auth/_userContext/GetLoginInfo';

export default function ProductSelector({ setEmpty, searchtext, setSelectedData, isWarehouse = false }) {
    const [isLocalLoading, setIsLocalLoading] = useState(false)
    const [isSelected, setIsSelected] = useState(false)
    const [searchTerm, setSearchTerm] = useState(searchtext);
    const [searchDebounce, setSearchDebounce] = useDebounce(searchtext, 500);
    const [data, setData] = useState([]);
    const userData = GetLoginInfo()

    useEffect(() => {
        setSearchDebounce(searchTerm)
    }, [searchTerm]);

    useEffect(() => {
        if (setEmpty)
            setSearchTerm("")
    }, [setEmpty]);

    useEffect(() => {
        if (!isSelected) {
            if (isWarehouse)
                getDataFromWarehouse()
            else
                getData()
        }
        else {
            setIsSelected(false)
        }
    }, [searchDebounce]);

    function getData() {
        setIsLocalLoading(true);
        let a;
        a = searchTerm;
        if (!searchTerm) { a = '' }
        productCrud.GetAll({ searchTerm: a, pageNo: 1, pageSize: 10 })
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setData(hdata);
                        setIsLocalLoading(false)
                    }
                    else {
                        setIsLocalLoading(false)
                        setData([]);
                    }
                }
                else {
                    setIsLocalLoading(false)
                    setData([]);
                }
            })
            .catch(error => {
                setIsLocalLoading(false);
            })
    }
    function getDataFromWarehouse() {
        setIsLocalLoading(true);
        let a;
        a = searchTerm;
        if (!searchTerm) { a = '' }
        warehouseCrud.GetAllProduct({ Search: { searchTerm: a, pageNo: 1, pageSize: 10 }, WarehouseId: userData.warehouseId })
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setData(hdata);
                        setIsLocalLoading(false)
                    }
                    else {
                        setIsLocalLoading(false)
                        setData([]);
                    }
                }
                else {
                    setIsLocalLoading(false)
                    setData([]);
                }
            })
            .catch(error => {
                setIsLocalLoading(false);
            })
    }

    function selectId(e) {
        setSearchTerm(e.split('|')[0])
        let sid = e.split('|')[1]
        setIsSelected(true)
        data.forEach(d => {
            if (d.id == sid) {
                setSelectedData(d);
            }
        });
    }
    return (
        <>
            <span class="input-icon" style={{ width: "100%" }}>
                <div className="autocomplete-wrapper">
                    <Autocomplete
                        inputProps={{ placeholder: "Type to search product" }}
                        value={searchTerm}
                        items={data}
                        getItemValue={item => item.productName + "|" + item.id}
                        renderMenu={item => (
                            <div className="dropdown">
                                {item}
                            </div>
                        )}
                        renderItem={(item, isHighlighted) =>
                            <div class={isHighlighted ? "profile-activity clearfix MyItem-selected-item" : "profile-activity clearfix"} style={{ cursor: "pointer" }}>
                                <div>
                                    <i class="pull-left thumbicon fa fa-cube btn-info no-hover"></i>
                                    <a class="user">{item.productName}</a> ({item.id})
                                    <br /><span>{item.productCategory.catName}</span>
                                </div>
                            </div>
                        }
                        onChange={(event, val) => setSearchTerm(val)}
                        onSelect={val => selectId(val)}
                    />
                </div>
                {isLocalLoading ?
                    <i class="ace-icon fa fa-spinner fa-spin blue"></i>
                    :
                    <i class="ace-icon fa fa-cubes blue"></i>
                }
            </span>
        </>
    )
}
