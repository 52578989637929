import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../../base/Container'
import AppToaster from '../../commonLib/AppToaster'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext'
import * as rechargeHistoryCrud from './../../_crud/rechargeHistoryCrud'
import { AddSpaceRecharge } from './AddSpaceRecharge'
import { MonthlyRecharge } from './MonthlyRecharge'
import { UpgradeEnterpriseRecharge } from './UpgradeEnterpriseRecharge'
import { DrapingRecharge } from './DrapingRecharge'
import { organizationContext } from '../Organization/organizationContext'

export function RechargePlansDashboard() {
    const [organization, setOrganization] = useContext(organizationContext);
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [recharge, setRecharge] = useState()
    const [rechargeDraping, setRechargeDraping] = useState()
    const [enterprise, setEnterprise] = useState()
    const [addSpace, setAddSpace] = useState()
    const [selectedSlab, setSelectedSlab] = useState(3)
    const [selectedDrapingCount, setSelectedDrapingCount] = useState(100)
    const [selectedSpace, setSelectedSpace] = useState(1)
    const [rechargePlan, setRechargePlan] = useState(0)

    useEffect(() => {
        getEnterprise()
    }, [])

    useEffect(() => {
        getRecharge()
    }, [selectedSlab])

    useEffect(() => {
        getAddSpace()
    }, [selectedSpace])

    useEffect(() => {
        getRechargeDraping()
    }, [selectedDrapingCount])

    function getRecharge() {
        setIsLoadingMini(true);
        rechargeHistoryCrud.GetRechargePlanInfo(selectedSlab)
            .then(res => {
                if (res.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data
                        setRecharge(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setIsLoadingMini(false)
                        setRecharge();
                    }
                }
                else {
                    setIsLoadingMini(false)
                    setRecharge();
                    AppToaster(res.data.result.message, 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function getRechargeDraping() {
        setIsLoadingMini(true);
        rechargeHistoryCrud.GetRechargeDrapingPlanInfo(selectedDrapingCount)
            .then(res => {
                if (res.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data
                        setRechargeDraping(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setIsLoadingMini(false)
                        setRechargeDraping();
                    }
                }
                else {
                    setIsLoadingMini(false)
                    setRechargeDraping();
                    AppToaster(res.data.result.message, 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }
    function getEnterprise() {
        setIsLoadingMini(true);
        rechargeHistoryCrud.GetUpgradeEnterprisePlanInfo()
            .then(res => {
                if (res.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data
                        setEnterprise(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setIsLoadingMini(false)
                        setEnterprise();
                    }
                }
                else {
                    setIsLoadingMini(false)
                    setEnterprise();
                    AppToaster(res.data.result.message, 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function getAddSpace() {
        setIsLoadingMini(true);
        rechargeHistoryCrud.GetIncreaseSpacePlanInfo(selectedSpace)
            .then(res => {
                if (res.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data
                        setAddSpace(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setIsLoadingMini(false)
                        setAddSpace();
                    }
                }
                else {
                    setIsLoadingMini(false)
                    setAddSpace();
                    AppToaster(res.data.result.message, 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    const ProceedToPlan = (plan) => {
        if (plan == 1 && recharge) {
            if (recharge.totalAmount > 0)
                setRechargePlan(plan)
            else
                AppToaster("Invalid recharge amount.", "warning")
        }
        else if (plan == 2 && addSpace) {
            if (addSpace.totalAmount > 0)
                setRechargePlan(plan)
            else
                AppToaster("Your plan validity is expired or about to expire, please recharge first.", "warning")
        }
        else if (plan == 3 && enterprise) {
            if (enterprise.totalAmount > 0)
                setRechargePlan(plan)
            else
                AppToaster("Your plan validity is expired or about to expire, please recharge first", "warning")
        }
        else if (plan == 4 && rechargeDraping) {
            if (organization.remainingDaysExpire > 15) {
                if (rechargeDraping.totalAmount > 0)
                    setRechargePlan(plan)
                else
                    AppToaster("Invalid recharge amount.", "warning")
            }
            else {
                AppToaster("Draping recharge must need atleat 15+ days of your plan validity, Check and recharge first.", "warning")
            }
        }
        else {
            setRechargePlan(0)
        }
    }

    return (
        <Container isProtected={false} title="Recharge Plans" isModelView={false}>
            <br />
            {rechargePlan == 0 &&
                <>
                    <div class="row">
                        <div class="col-sm-2"></div>
                        <div class="col-sm-4 pricing-span-header">
                            <div class="widget-box transparent">
                                <div class="widget-header">
                                    <h5 class="widget-title bigger lighter">Plan Name</h5>
                                </div>

                                <div class="widget-body">
                                    <div class="widget-main no-padding">
                                        <ul class="list-unstyled list-striped pricing-table-header">
                                            <li>Plan</li>
                                            <li>Monthly Rate or Per Draping <small>(inclusive gst)</small></li>
                                            <li>Net Amount</li>
                                            <li>GST %</li>
                                            <li>GST Amount</li>
                                            <li>Total</li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-5 pricing-span-body">
                            {recharge &&
                                <div class="pricing-span">
                                    <div class="widget-box pricing-box-small widget-color-green">
                                        <div class="widget-header">
                                            <h5 class="widget-title bigger lighter">Recharge</h5>
                                        </div>

                                        <div class="widget-body">
                                            <div class="widget-main no-padding">
                                                <ul class="list-unstyled list-striped pricing-table">
                                                    <li style={{ padding: "0" }}>
                                                        <select style={{ height: "33px" }} className="form-control" value={selectedSlab} onChange={(e) => setSelectedSlab(e.target.value)}>
                                                            <option value="3" >3 Months</option>
                                                            <option value="6" >6 Months</option>
                                                            <option value="12" >1 Year</option>
                                                            <option value="24" >2 Years</option>
                                                        </select>
                                                    </li>
                                                    <li>₹ {Number(recharge.monthlyRate).toFixed(2)}</li>
                                                    <li>₹ {Number(recharge.netAmount).toFixed(2)}</li>
                                                    <li>{recharge.gstSlab}%</li>
                                                    <li>₹ {Number(recharge.gstAmount).toFixed(2)}</li>
                                                    <li className="bolder green">₹ {Number(recharge.totalAmount).toFixed(2)}</li>
                                                    <li className="bolder">{recharge.slab} Months<br /><small>Free {recharge.freeDrapings * recharge.slab} Drapings</small></li>
                                                </ul>
                                            </div>

                                            <div>
                                                <button class="btn btn-block btn-sm btn-success" onClick={() => ProceedToPlan(1)}>
                                                    <span>Buy</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {rechargeDraping &&
                                <div class="pricing-span">
                                    <div class="widget-box pricing-box-small widget-color-red">
                                        <div class="widget-header">
                                            <h5 class="widget-title bigger lighter">Draping</h5>
                                        </div>

                                        <div class="widget-body">
                                            <div class="widget-main no-padding">
                                                <ul class="list-unstyled list-striped pricing-table">
                                                    <li style={{ padding: "0" }}>
                                                        <select style={{ height: "33px" }} className="form-control" value={selectedDrapingCount} onChange={(e) => setSelectedDrapingCount(e.target.value)}>
                                                            <option value="100" >100 Drapings</option>
                                                            <option value="200" >200 Drapings</option>
                                                            <option value="300" >300 Drapings</option>
                                                            <option value="400" >400 Drapings</option>
                                                        </select>
                                                    </li>
                                                    <li>₹ {Number(rechargeDraping.perDrapingRate).toFixed(2)}</li>
                                                    <li>₹ {Number(rechargeDraping.netAmount).toFixed(2)}</li>
                                                    <li>{rechargeDraping.gstSlab}%</li>
                                                    <li>₹ {Number(rechargeDraping.gstAmount).toFixed(2)}</li>
                                                    <li className="bolder green">₹ {Number(rechargeDraping.totalAmount).toFixed(2)}</li>
                                                    <li className="bolder"><br />-</li>
                                                </ul>
                                            </div>

                                            <div>
                                                <button class="btn btn-block btn-sm btn-danger" onClick={() => ProceedToPlan(4)}>
                                                    <span>Buy</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {addSpace &&
                                <div class="pricing-span">
                                    <div class="widget-box pricing-box-small widget-color-blue">
                                        <div class="widget-header">
                                            <h5 class="widget-title bigger lighter">Add Space</h5>
                                        </div>

                                        <div class="widget-body">
                                            <div class="widget-main no-padding">
                                                <div class="widget-main no-padding">
                                                    <ul class="list-unstyled list-striped pricing-table">
                                                        <li style={{ padding: "0" }}>
                                                            <select style={{ height: "33px" }} className="form-control" value={selectedSpace} onChange={(e) => setSelectedSpace(e.target.value)}>
                                                                <option value="1" >1 gb</option>
                                                                <option value="2" >2 gb</option>
                                                                <option value="3" >3 gb</option>
                                                                <option value="4" >4 gb</option>
                                                                <option value="5" >5 gb</option>
                                                                <option value="6" >6 gb</option>
                                                                <option value="7" >7 gb</option>
                                                                <option value="8" >8 gb</option>
                                                                <option value="9" >9 gb</option>
                                                                <option value="10" >10 gb</option>
                                                            </select>
                                                        </li>
                                                        <li>₹ {Number(addSpace.monthlyRate).toFixed(2)}/ {addSpace.increaseSpaceGB} gb</li>
                                                        <li>₹ {Number(addSpace.netAmount).toFixed(2)}</li>
                                                        <li>{addSpace.gstSlab}%</li>
                                                        <li>₹ {Number(addSpace.gstAmount).toFixed(2)}</li>
                                                        <li className="bolder green">₹ {Number(addSpace.totalAmount).toFixed(2)}</li>
                                                        <li className="bolder"><br />{addSpace.months} Month(s)*</li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div>
                                                <button class="btn btn-block btn-sm btn-primary" onClick={() => ProceedToPlan(2)}>
                                                    <span>Buy</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {enterprise ?
                                <div class="pricing-span">
                                    <div class="widget-box pricing-box-small widget-color-orange">
                                        <div class="widget-header">
                                            <h5 class="widget-title bigger lighter">Enterprise</h5>
                                        </div>

                                        <div class="widget-body">
                                            <div class="widget-main no-padding">
                                                <ul class="list-unstyled list-striped pricing-table">
                                                    <li>
                                                        ∞ Warehouse
                                                    </li>
                                                    <li>₹ {Number(enterprise.differenceMonthlyRate).toFixed(2)}**</li>
                                                    <li>₹ {Number(enterprise.netAmount).toFixed(2)}</li>
                                                    <li>{enterprise.gstSlab}%</li>
                                                    <li>₹ {Number(enterprise.gstAmount).toFixed(2)}</li>
                                                    <li className="bolder green">₹ {Number(enterprise.totalAmount).toFixed(2)}</li>
                                                    <li className="bolder"><br />{enterprise.months} Month(s)*</li>
                                                </ul>
                                            </div>

                                            <div>
                                                <button class="btn btn-block btn-sm btn-warning" onClick={() => ProceedToPlan(3)}>
                                                    <span>Upgrade</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                !isLoadingMini &&
                                <div class="pricing-span">
                                    <div class="widget-box pricing-box-small widget-color-orange">
                                        <div class="widget-header">
                                            <h5 class="widget-title bigger lighter">Enterprise</h5>
                                        </div>

                                        <div class="widget-body">
                                            <div class="widget-main no-padding">
                                                <ul class="list-unstyled list-striped pricing-table">
                                                    <li>
                                                        ∞ Warehouse
                                                    </li>
                                                    <li className="bolder green">A</li>
                                                    <li className="bolder green">C</li>
                                                    <li className="bolder green">T</li>
                                                    <li className="bolder green">I</li>
                                                    <li className="bolder green">V</li>
                                                    <li className="bolder green">E</li>
                                                    <span>&nbsp;</span>
                                                </ul>
                                            </div>

                                            <div>
                                                <button class="btn btn-block btn-sm btn-warning" disabled>
                                                    <span>Upgrade</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* <div class="pricing-span">
                        <div class="widget-box pricing-box-small widget-color-green">
                            <div class="widget-header">
                                <h5 class="widget-title bigger lighter">Unlimited</h5>
                            </div>

                            <div class="widget-body">
                                <div class="widget-main no-padding">
                                    <ul class="list-unstyled list-striped pricing-table">
                                        <li> Unlimited </li>
                                        <li> Unlimited </li>
                                        <li> Unlimited </li>
                                        <li> Unlimited </li>
                                        <li> $50 </li>

                                        <li>
                                            <i class="ace-icon fa fa-check green"></i>
                                            2
                                        </li>
                                    </ul>

                                    <div class="price">
                                        <span class="label label-lg label-inverse arrowed-in arrowed-in-right">
                                            $25
                                            <small>/month</small>
                                        </span>
                                    </div>
                                </div>

                                <div>
                                    <a href="#" class="btn btn-block btn-sm btn-success">
                                        <span>Buy</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-10">
                            <dl id="dt-list-1">
                                <dt>Note</dt>
                                <dd>Enterprise plan and adding space plan will not downgrade and both will purchased only atleast 1 month of recharge validity.</dd>
                                <dd>Update your organization gstn then select plan for GSTR2.</dd>
                                <dd>* Months are calculated between current date to validity date.</dd>
                                <dd>** Monthly rate is calculates amount difference between current date to validity date.</dd>
                            </dl>
                        </div>
                    </div>
                </>
            }
            {rechargePlan == 1 &&
                <MonthlyRecharge setRechargePlan={setRechargePlan} data={recharge} />
            }
            {rechargePlan == 4 &&
                <DrapingRecharge setRechargePlan={setRechargePlan} data={rechargeDraping} />
            }
            {rechargePlan == 2 &&
                <AddSpaceRecharge setRechargePlan={setRechargePlan} data={addSpace} />
            }
            {rechargePlan == 3 &&
                <UpgradeEnterpriseRecharge setRechargePlan={setRechargePlan} data={enterprise} />
            }
        </Container>
    )
}
