import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { GetEBoolName } from '../../../enumCollections/EBool';
import { GetEProductionCategoryTypeName } from '../../../enumCollections/EProductionCategoryType';
import { GetEProductionUnitOwnershipName } from '../../../enumCollections/EProductionUnitOwnership';

export function ProductionSelectorView({ data, ishide = true }) {
    const [hide, setHide] = useState(ishide);
    return (
        <>
            {data &&
                <div className="row center">
                    {
                        hide ?
                            <a style={{ cursor: "pointer" }} onClick={() => setHide(false)}><i class="ace-icon fa fa-eye blue"></i> View Production details</a>
                            :
                            <a style={{ cursor: "pointer" }} onClick={() => setHide(true)}><i class="ace-icon fa fa-eye-slash blue"></i> Hide Production details</a>
                    }
                </div>
            }
            {
                data && !hide &&
                <>
                    <div class="col-xs-12">
                        <div className="widget-box">
                            <div class="widget-header widget-header-flat widget-header-small">
                                <h5 class="widget-title">
                                    Production Details
                                </h5>
                            </div>

                            <div class="row">
                                <div class="profile-user-info profile-user-info-striped">
                                    <div class="profile-info-row">
                                        <div class="profile-info-name"><b>Current Production</b> </div>

                                        <div class="profile-info-value">
                                            <span class="editable editable-click"><Link to={"/productionunit/dashboard/" + data.id}>{data.productionUnitName} ({data.id})</Link></span>
                                        </div>
                                    </div>

                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Category </div>

                                        <div class="profile-info-value">
                                            <span > {data.productionCategory.productionCatName}</span>
                                        </div>
                                    </div>
                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Type </div>

                                        <div class="profile-info-value">
                                            <span class={data.productionCategory.type == 1 ? "label label-sm label-info" : "label label-sm label-warning"}>{GetEProductionCategoryTypeName(data.productionCategory.type)}</span>
                                        </div>
                                    </div>
                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Ownership </div>

                                        <div class="profile-info-value">
                                            <span class={data.ownerShip == 1 ? "label label-sm label-info" : "label label-sm label-warning"}>{GetEProductionUnitOwnershipName(data.ownerShip)}</span>
                                        </div>
                                    </div>
                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Is Multi Product </div>

                                        <div class="profile-info-value">
                                            <span class={data.isMultiProduct == 1 ? "label label-sm label-success" : "label label-sm label-danger"}>{GetEBoolName(data.isMultiProduct)}</span>
                                        </div>
                                    </div>
                                    <div class="profile-info-row">
                                        <div class="profile-info-name">Details</div>

                                        <div class="profile-info-value">
                                            <span >{data.details}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
