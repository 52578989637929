import React, { useContext, useEffect, useState } from 'react'
import { GetEPaymentStatusName } from '../../../enumCollections/EPaymentStatus'
import { GetELedgerAssetSalary, GetELedgerAssetSalaryName } from '../../../enumCollections/ELedgerAssetSalary'
import { EmptyData } from '../../../commonLib/EmptyData'
import { MyAlert } from '../../../commonLib/MyAlert'
import Moment from 'react-moment'
import { PagingEmpty } from '../../../commonLib/PagingEmpty'
import { UserView } from '../../Global/Elements/UserView'
import { StatusView } from '../../../commonLib/StatusView'
import { ContactView } from '../../Global/Elements/ContactView'
import { Link, useParams } from 'react-router-dom'
import AppToaster from '../../../commonLib/AppToaster'
import * as processingWageVoucherCrud from '../../../_crud/vouchers/processingWageVoucherCrud'
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext'
import GetCurrentDateForSearch from '../../../commonLib/dateFormat'
import DatePicker from "react-datepicker";
import { ProductionUnitView } from '../../Global/Elements/ProductionUnitView'
import { ProductView } from '../../Global/Elements/ProductView'
import { ProcessingView } from '../../Global/Elements/ProcessingView'
import { Spinner } from '../../../commonLib/Spinner'
import { GetEBoolName } from '../../../enumCollections/EBool'
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater'

export function ProcessingItemView({ paid, pending, processingVoucher, voucherPayment, voucherDetails, doRefresh, advance, defect }) {
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const { id } = useParams()
    const [empty, setEmpty] = useState(false);
    const [detailsCount, setDetailsCount] = useState({ fresh: 0, defective: 0, rejected: 0 });

    const initItems = {
        voucherId: id,
        receivedDate: new Date(GetCurrentDateForSearch(0)),
        amount: 0,
        fiscalYear: "a",
        fromLedgerId: 6,
        note: ""
    }
    const [processingItem, setProcessingItem] = useState(initItems);

    useEffect(() => {
        setEmpty(false)
    }, [empty]);
    useEffect(() => {
        if (voucherDetails && voucherDetails.length > 0) {
            let cnt = { fresh: 0, defective: 0, rejected: 0 }
            voucherDetails.forEach(element => {
                cnt.fresh = cnt.fresh + element.productProcessingReceiving.freshQuantity
                cnt.defective = cnt.defective + element.productProcessingReceiving.defectiveQuantity
                cnt.rejected = cnt.rejected + element.productProcessingReceiving.rejectedQuantity
            });

            setDetailsCount({ ...cnt })
        }
    }, [voucherDetails]);

    function setAmount() {
        if (processingItem.amount) {
            if (processingItem.fromLedgerId == 9) {
                if (advance) {
                    if (processingItem.amount > advance.remainingAdvance) {
                        if (advance.remainingAdvance > pending)
                            setProcessingItem({ ...processingItem, amount: pending })
                        else
                            setProcessingItem({ ...processingItem, amount: advance.remainingAdvance })
                    }
                    else if (processingItem.amount < 0) {
                        setProcessingItem({ ...processingItem, amount: 0 })
                    }
                }
                else {
                    setProcessingItem({ ...processingItem, amount: 0 })
                }
            }
            else if (processingItem.fromLedgerId == 45) {
                if (defect) {
                    if (processingItem.amount > defect.remainingAdvance) {
                        if (defect.remainingAdvance > pending)
                            setProcessingItem({ ...processingItem, amount: pending })
                        else
                            setProcessingItem({ ...processingItem, amount: defect.remainingAdvance })
                    }
                    else if (processingItem.amount < 0) {
                        setProcessingItem({ ...processingItem, amount: 0 })
                    }
                }
                else {
                    setProcessingItem({ ...processingItem, amount: 0 })
                }
            }
            else {
                if (processingItem.amount > pending) {
                    setProcessingItem({ ...processingItem, amount: pending })
                }
                else if (processingItem.amount < 0) {
                    setProcessingItem({ ...processingItem, amount: 0 })
                }
            }
        }
    }

    useEffect(() => {
        setAmount()
    }, [processingItem.amount, processingItem.fromLedgerId]);


    const AddItem = (type) => {
        if (processingItem.amount > 0) {
            setIsLoadingMini(true);
            setIsLoadingLocal(true)
            processingWageVoucherCrud.AddVoucherPayment(processingItem)
                .then(res => {
                    if (res.data.succeeded) {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false);
                        AppToaster('Added successfully.', 'success')
                        if (type) { SattleItem(processingItem) }
                        else { doRefresh() }
                    }
                    else {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                })
                .catch(error => {
                    setIsLoadingMini(false);
                    setIsLoadingLocal(false);
                    AppToaster(error, 'error')
                })
        }
        else {
            AppToaster("Amount should be greater than zero.", 'error')
        }
    }

    const SattleItem = (processingItem) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        processingWageVoucherCrud.SattleVoucher({ VoucherId: processingItem.voucherId, OnDate: processingItem.receivedDate })
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster('Satlle successfully.', 'success')
                    doRefresh()
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        processingWageVoucherCrud.RemoveVoucherPayment(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    AppToaster('Deleted successfully.', 'success')
                    doRefresh()
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by someone,could not delete.', 'error')
                }
            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }

    return (
        <>
            <div className="widget-box">
                <div class="widget-header widget-header-flat widget-header-small">
                    <h5 class="widget-title">
                        Details
                    </h5>
                </div>
                {
                    processingVoucher &&
                    <div className="row">
                        <div class="profile-user-info profile-user-info-striped">
                            <div class="profile-info-row">
                                <div class="profile-info-name"> Voucher Id</div>

                                <div class="profile-info-value">
                                    <span ><b>{processingVoucher.id}</b></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Voucher Date</div>

                                <div class="profile-info-value">
                                    <Moment format="DD-MMM-YYYY">{processingVoucher.voucherDate}</Moment>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name"> For Contact </div>

                                <div class="profile-info-value">
                                    <Link to={"/contact/dashboard/" + processingVoucher.contact.id} >
                                        <ContactView item={processingVoucher.contact} uid={"CXV1"} />
                                    </Link>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name">Phone</div>

                                <div class="profile-info-value">
                                    <span >{processingVoucher.contact.phone}</span>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name">Address</div>

                                <div class="profile-info-value">
                                    <span >{processingVoucher.contact.address}</span>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name">Email</div>

                                <div class="profile-info-value">
                                    <span >{processingVoucher.contact.email}</span>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name">Quantity</div>
                                <div class="profile-info-value">
                                    <span >
                                        Fresh: <b>{Number(detailsCount.fresh).toFixed(2)}</b>
                                        &nbsp;&nbsp;Defective: <b>{Number(detailsCount.defective).toFixed(2)}</b>
                                        &nbsp;&nbsp;Rejected: <b>{Number(detailsCount.rejected).toFixed(2)}</b>
                                        &nbsp;&nbsp;Total: <b>{Number(detailsCount.fresh + detailsCount.defective + detailsCount.rejected).toFixed(2)}</b>
                                    </span>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name">Details</div>
                                <div class="profile-info-value">
                                    <span >{processingVoucher.linkedDetails}</span>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name">Amount</div>
                                <div class="profile-info-value">
                                    <span ><b>₹{Number(processingVoucher.totalAmount).toFixed(2)}</b></span>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name"> <b>Voucher Balance</b> </div>

                                <div class="profile-info-value">
                                    <span>Paid: <b className="green">₹{Number(paid).toFixed(2)}</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pending: <b className="red">₹{Number(pending).toFixed(2)}</b> </span>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name">Note </div>

                                <div class="profile-info-value">
                                    <span>{processingVoucher.note ? processingVoucher.note : "NA"}</span>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name">Status </div>

                                <div class="profile-info-value">
                                    <span><StatusView status={processingVoucher.paymentStatus}>{GetEPaymentStatusName(processingVoucher.paymentStatus)}</StatusView></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Created By </div>

                                <div class="profile-info-value">
                                    <span><UserView item={processingVoucher.createdByUser} uid={"GD1"} createdOn={processingVoucher.createdOn} /></span>
                                </div>
                            </div>

                        </div>
                    </div>
                }
            </div>
            <br />
            <div className="widget-box">
                <div class="widget-header widget-header-flat widget-header-small">
                    <h5 class="widget-title">
                        Voucher Payments
                    </h5>
                </div>
                <div className="row" >
                    <div class="col-xs-12">
                        <div className="col-md-3">
                            <div class="form-group">
                                <label class="control-label no-padding-right">Date</label>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker placeholder="Received Date" name="receivedDate" className="form-control input-date-padding" selected={processingItem.receivedDate} onChange={(date) => setProcessingItem({ ...processingItem, receivedDate: date })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div class="form-group">
                                <label class="control-label no-padding-right"> Amount</label>
                                <input className="form-control" type="number" placeholder="Amount" value={processingItem.amount} onChange={(e) => setProcessingItem({ ...processingItem, amount: e.target.value })} />
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div class="form-group">
                                <label class="ontrol-label no-padding-right">By</label>
                                <select className="form-control" value={processingItem.fromLedgerId} onChange={(e) => setProcessingItem({ ...processingItem, fromLedgerId: e.target.value })}>
                                    {
                                        GetELedgerAssetSalary.ELedgerAssetSalary.map((data, index) =>
                                            <option key={data.Id} value={data.Id}>{data.Name}</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div class="form-group">
                                <label class="ontrol-label no-padding-right">Note</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="Note" value={processingItem.note} onChange={(e) => setProcessingItem({ ...processingItem, note: e.target.value })} />
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div class="form-group">
                                <label class="control-label no-padding-right">&nbsp;</label>
                                <div class="col-xs-12">
                                    {isLoadingLocal ?
                                        <button class="btn btn-xs btn-success" type="submit" expand="block" disabled>
                                            <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i> Wait...
                                        </button>
                                        :
                                        <>
                                            <div class="btn-group">
                                                <button class="btn btn-xs btn-success" expand="block" onClick={() => AddItem(false)}>
                                                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>Pay
                                                </button>
                                                <button data-toggle="dropdown" class="btn btn-xs btn-success dropdown-toggle" aria-expanded="false">
                                                    <span class="ace-icon fa fa-caret-down icon-only"></span>
                                                </button>

                                                <ul class="dropdown-menu dropdown-success">
                                                    <li>
                                                        <a expand="block" onClick={() => AddItem(true)}>
                                                            Pay & Sattle
                                                        </a>
                                                    </li>

                                                </ul>
                                            </div>

                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-xs-12">
                        <PagingEmpty text={"Total " + voucherPayment.length + " items."}>
                            {voucherPayment && voucherPayment.length > 0 ?
                                <div className="tables-scroll">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>Amount</th>
                                                <th>By</th>
                                                <th>Note</th>
                                                <th></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {voucherPayment.map((item, index) =>
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td><Moment format="DD-MMM-YYYY">{item.receivedDate}</Moment></td>
                                                    <td><b>₹ {item.amount}</b></td>
                                                    <td>{GetELedgerAssetSalaryName(parseInt(item.fromLedgerId))}</td>
                                                    <td>{item.note}</td>
                                                    <td>
                                                        <div class="btn-group">

                                                            <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                                <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                            </MyAlert>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <EmptyData>
                                    No Item Added.
                                    <br />
                                    <br />
                                </EmptyData>
                            }
                        </PagingEmpty>
                    </div>
                </div>
            </div>
            <br />
            {
                voucherDetails &&
                <>
                    <div className="widget-box">
                        <div class="widget-header widget-header-flat widget-header-small">
                            <h5 class="widget-title">
                                Recevings for processing vouchers
                            </h5>

                        </div>
                        <div className="row">
                            {/* <div className="col-xs-12">
                                <div class="clearfix">
                                    <div class="grid4">
                                        <span>
                                            <h5 class="bigger green">
                                                Total Fresh Wage: <b>₹ {Number(processingReceiving.totalWage).toFixed(2)}</b>
                                            </h5>
                                        </span>
                                    </div>
                                    <div class="grid4">
                                        <span>
                                            <h5 class="bigger green">
                                                Fresh Quantity: <b>{processingReceiving.totalFresh}</b></h5>
                                        </span>
                                    </div>

                                    <div class="grid4">
                                        <span>
                                            <h5 class="bigger orange">
                                                Defective Quantity: <b>{processingReceiving.totalDefective}</b></h5>
                                        </span>
                                    </div>

                                    <div class="grid4">
                                        <span>
                                            <h5 class="bigger red">
                                                Rejected Quantity: <b>{processingReceiving.totalRegected}</b></h5>
                                        </span>
                                    </div>
                                </div>

                            </div> */}
                            <div className="col-xs-12">
                                <PagingEmpty text={"Total " + voucherDetails.length + " items."}>
                                    {isLoadingMini ?
                                        <Spinner />
                                        :
                                        voucherDetails && voucherDetails.length > 0 ?
                                            <div className="tables-scroll">
                                                <table className="table table-hover table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>#Id</th>
                                                            <th>On Date</th>
                                                            <th>Processing</th>
                                                            <th>Product</th>
                                                            <th>Wage/unit</th>
                                                            <th>Fresh</th>
                                                            <th>Defective</th>
                                                            <th>Rejected</th>
                                                            <th>Production</th>
                                                            {/* <th>Payment Status</th> */}
                                                            <th>Note</th>
                                                            <th>By</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {voucherDetails.map((item, index) =>
                                                            <tr key={item.id}>
                                                                <td>{item.productProcessingReceiving.id}</td>
                                                                <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.productProcessingReceiving.onDate}</Moment></div></td>
                                                                <td>
                                                                    <Link to={"/processing/dashboard/" + item.productProcessingReceiving.processingId} >
                                                                        <ProcessingView item={item.productProcessingReceiving.processing} />
                                                                    </Link>
                                                                </td>

                                                                <td>
                                                                    <Link to={"/product/dashboard/" + item.productProcessingReceiving.productId} >
                                                                        <ProductView item={item.productProcessingReceiving.product} uid={"PP" + index} />
                                                                    </Link>
                                                                </td>

                                                                <td>₹{Number(item.productProcessingReceiving.processingWage.wage).toFixed(2)}</td>
                                                                <td>{item.productProcessingReceiving.freshQuantity} {item.productProcessingReceiving.unit}</td>
                                                                <td>{item.productProcessingReceiving.defectiveQuantity} {item.productProcessingReceiving.unit}</td>
                                                                <td>{item.productProcessingReceiving.rejectedQuantity} {item.productProcessingReceiving.unit}</td>
                                                                <td>
                                                                    {item.productProcessingReceiving.isProductionUnit == 1 ? (item.productProcessingReceiving.productionUnit ? <Link to={"/productionunit/dashboard/" + item.productProcessingReceiving.productionUnitId} >
                                                                        <ProductionUnitView item={item.productProcessingReceiving.productionUnit} />
                                                                    </Link> : "NA") : <span class="label label-sm label-danger">{GetEBoolName(item.productProcessingReceiving.isProductionUnit)}</span>}
                                                                </td>
                                                                {/* <td><span class={item.paymentStatus == 1 ? "label label-sm label-warning" : "label label-sm label-info"}>{GetEPaymentStatusName(item.paymentStatus)}</span></td> */}
                                                                <td><div style={{ width: "150px" }}>{item.productProcessingReceiving.note}</div></td>

                                                                <td>
                                                                    <UserView item={item.productProcessingReceiving.createdByUser} uid={"D" + index} createdOn={item.productProcessingReceiving.createdOn} />
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                            :
                                            <EmptyData>
                                                No records found.
                                                <br />
                                                <br />
                                            </EmptyData>

                                    }
                                    <br />
                                </PagingEmpty>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
