import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Container } from '../../../base/Container'
import AppToaster from '../../../commonLib/AppToaster'
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext'
import { loadingContext } from '../../../commonLib/_lodingContext'
import *as productDistributionCrud from '../../../_crud/productDistributionCrud'
import { TimelineHistory } from '../../Global/TimelineHistory'
import { ProductDistributionItemView } from './ProductDistributionItemView'

export function ProductDistributionDashBoard() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [productDistribution, setProductDistribution] = useState()
    const { id } = useParams()

    function doRefresh() {
        getData()
    }

    useEffect(() => {
        getData()
    }, []);


    function getData() {
        setIsLoading(true);
        setIsLoadingMini(true);
        productDistributionCrud.GetById(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProductDistribution(hdata);
                        setIsLoading(false);
                        setIsLoadingMini(false)
                    }
                    else {
                        setIsLoading(false);
                        setIsLoadingMini(false)
                        setProductDistribution([]);
                    }
                }
                else {
                    setIsLoading(false);
                    setIsLoadingMini(false)
                    setProductDistribution([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoading(false);
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }
    return (
        <Container title="Product Distribution Dashboard" doRefresh={doRefresh} isBack="true" >

            <div className="row">
                <div className="col-lg-8">
                <ProductDistributionItemView productDistribution={productDistribution} />
                </div>
                <div className="col-lg-4">
                    <TimelineHistory id={id} type={2} pageSize={10} text="Product Distribution Timeline" />
                </div>

            </div>
        </Container >
    )
}
