const GetEProductDesignStatusName = (id) => {
    switch (id) {
        case 0: { return "Initiated" }
        case 1: { return "Processing" }
        case 2: { return "Completed" }
    }
}

const GetEProductDesignStatus = {
    EProductDesignStatus: [
        { Id: 0, Name: "Initiated" },
        { Id: 1, Name: "Processing" },
        { Id: 2, Name: "Completed" },
    ]
}

export { GetEProductDesignStatusName, GetEProductDesignStatus }