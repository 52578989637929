import React, { useContext, useEffect, useState } from 'react'
import { EmptyData } from '../../commonLib/EmptyData'
import { Paging } from '../../commonLib/Paging'
import { Spinner } from '../../commonLib/Spinner'
import { DEFAULT_USER_IMG, ERROR_IMG, PAGING_PAGE_SIZE } from '../../config/GlobalSettings'
import { BASE_URL } from '../../initApi/apiUrls'
import * as rechargeHistoryCrud from '../../_crud/rechargeHistoryCrud'
import Moment from 'react-moment';
import ReactTooltip from 'react-tooltip';
import { Container } from '../../base/Container'
import AppToaster from '../../commonLib/AppToaster'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext'
import GetCurrentDateForSearch from '../../commonLib/dateFormat'
import DatePicker from "react-datepicker";
import { StatusView } from '../../commonLib/StatusView'
import { GetERechargeStatusName } from '../../enumCollections/ERechargeStatus'

const initValues = {
    Search: {
        searchTerm: "",
        pageNo: 1,
        pageSize: PAGING_PAGE_SIZE
    },
    Range: {
        fromDate: new Date(GetCurrentDateForSearch(-30)),
        toDate: new Date(GetCurrentDateForSearch(0))
    }
}

export function ManageRechargeHistory() {

    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [search, setSearch] = useState(initValues)
    const [recharge, setRecharge] = useState([])
    const [LastPage, setLastPage] = useState(1)
    const [pagingData, setPagingData] = useState()


    const OnPageChange = (e) => {
        const { value } = e.target
        setSearch({ ...search, Search: { ...search.Search, pageNo: value } });
        getData({
            Search: { searchTerm: search.Search.searchTerm, pageNo: value, pageSize: search.Search.pageSize },
            Range: {
                fromDate: search.Range.fromDate,
                toDate: search.Range.toDate
            }
        })
    }
    useEffect(() => {
        getData(
            {
                Search: { searchTerm: "", pageNo: 1, pageSize: PAGING_PAGE_SIZE },
                Range: {
                    fromDate: search.Range.fromDate,
                    toDate: search.Range.toDate
                }
            })
    }, [])

    function onSearch() {
        getData({
            Search: { searchTerm: search.Search.searchTerm, pageNo: 1, pageSize: search.Search.pageSize },
            Range: {
                fromDate: search.Range.fromDate,
                toDate: search.Range.toDate
            }
        })
    }

    function getData(param) {
        setIsLoadingMini(true);
        rechargeHistoryCrud.GetAll(param)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setRecharge(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoadingMini(false)
                        setRecharge([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoadingMini(false)
                    setRecharge([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        setSearch({ ...search, Search: { ...search.Search, pageNo: 1 } })
        getData(
            {
                Search: { searchTerm: "", pageNo: 1, pageSize: PAGING_PAGE_SIZE },
                Range: {
                    fromDate: search.Range.fromDate,
                    toDate: search.Range.toDate
                }
            })
        setTimeout(() => {
            event.target.complete();
        }, 1000);
    }

    return (
        <Container title="Recharge History" doRefresh={doRefresh}>
            <div class="form-horizontal" >
                <div className="row">
                    <div className="col-md-2">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <span class="input-icon" style={{ width: "100%" }}>
                                <DatePicker selectsStart startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.fromDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, fromDate: date } })} />
                                <i class="ace-icon fa fa-calendar blue"></i>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <span class="input-icon" style={{ width: "100%" }}>
                                <DatePicker selectsEnd startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.toDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, toDate: date } })} />
                                <i class="ace-icon fa fa-calendar blue"></i>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <button onClick={onSearch} class="btn btn-sm btn-info pull-right">
                                <i class="ace-icon fa fa-search bigger-110"></i>
                                Run
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Paging
                pagingData={pagingData}
                LastPage={LastPage}
                CurrentPage={search.Search.pageNo}
                OnPageChange={OnPageChange} >

                {isLoadingMini ?
                    <Spinner />
                    :
                    recharge && recharge.length > 0 ?
                        <div className="tables-scroll">
                            <table className="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>#Id</th>
                                        <th style={{ width: "140px" }}>On Date</th>
                                        <th>Amount</th>
                                        <th>Months/Drapings</th>
                                        <th>Previous Validity</th>
                                        <th>New Validity</th>
                                        <th>Contact Name</th>
                                        <th>Contact No</th>
                                        <th>Status</th>
                                        <th>Note</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        recharge.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>{item.id}</td>
                                                <td className="bolder">
                                                    <div style={{ width: "73px" }}><Moment format="DD-MMM-YYYY hh:mm A">{item.createdOn}</Moment></div>
                                                </td>
                                                <td><div style={{ width: "90px" }}>₹{Number(item.amount).toFixed(2)}<br />
                                                    <small>inclusive 18% GST</small></div></td>
                                                <td><b>{item.rechargeDurationInMonths}</b></td>
                                                <td><div style={{ width: "73px" }}><Moment format="DD-MMM-YYYY hh:mm A">{item.previousValidDate}</Moment></div></td>
                                                <td><div style={{ width: "73px" }}><Moment format="DD-MMM-YYYY hh:mm A">{item.newValidDate}</Moment></div></td>
                                                <td><div style={{ width: "100px" }}>{item.rechargeContactName}</div></td>
                                                <td>{item.rechargeContactNo}</td>
                                                <td><StatusView type={2} status={item.status}>{GetERechargeStatusName(item.status)}</StatusView></td>
                                                <td><div style={{ minWidth: "200px" }}>{item.note}</div></td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                        :
                        <EmptyData>
                            No records found.
                            <br />
                            <br />
                        </EmptyData>
                }
            </Paging>
        </Container >
    )
}
