const GetEProcessingTypeName = (id) => {
    switch (id) {
        case 1: { return "Pre" }
        case 2: { return "Post" }
    }
}

const GetEProcessingType = {
    EProcessingType: [
        { Id: 1, Name: "Pre" },
        { Id: 2, Name: "Post" },
    ]
}

export { GetEProcessingTypeName, GetEProcessingType }