import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import * as Yup from 'yup'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { useFormik } from 'formik';
import *as sareeDrapingCrud from '../../_crud/sareeDrapingCrud'
import { LoadingButton } from '../../commonLib/LoadingButton';
import { useHistory, useParams } from 'react-router-dom';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

export function SareeDrapingEdit() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const { id } = useParams()
    const history = useHistory()

    const Schema = Yup.object().shape({
        itemName: Yup.string().min(3, "Item Name must between 3 to 50 characters").max(50, "Item Name must between 3 to 50 characters").required("Item Name is required."),
        itemCode: Yup.string().min(1, "Item Code must between 1 to 15 characters").max(15, "Item Code must between 1 to 15 characters").required("Item Code is required."),
    })

    const initialValues = {
        id: id,
        itemName: "",
        itemCode: "",
        note: "",
    }

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values)
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true);
        sareeDrapingCrud.Edit(value)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false);
                    setIsLoadingLocal(false);
                    AppToaster('Updated successfully.', 'success')
                    history.goBack()
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoadingMini(true);
        sareeDrapingCrud.GetById(id)
            .then(res => {
                if (res.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        formik.setValues({ ...formik.values, itemCode: hdata.itemCode, itemName: hdata.itemName, note: hdata.note })

                        setIsLoadingMini(false)
                    }
                    else {
                        setIsLoadingMini(false)
                    }
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }


    return (
        <Container title="Edit Saree Draping" isBack="true">
            <form onSubmit={formik.handleSubmit} class="form-horizontal">
                <div className="row">

                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Item Name</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="Item Name" name="itemName" value={formik.values.itemName} onChange={(e) => formik.setValues({ ...formik.values, itemName: e.target.value })} />
                                {
                                    formik.touched.itemName &&
                                        formik.errors.itemName ? (
                                        <label className="text-danger" >
                                            {formik.errors.itemName}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Item Code</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="Item Code" name="itemCode" value={formik.values.itemCode} onChange={(e) => formik.setValues({ ...formik.values, itemCode: e.target.value })} />
                                {
                                    formik.touched.itemCode &&
                                        formik.errors.itemCode ? (
                                        <label className="text-danger" >
                                            {formik.errors.itemCode}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Description</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="Description" name="note" value={formik.values.note} onChange={(e) => formik.setValues({ ...formik.values, note: e.target.value })} />
                                {
                                    formik.touched.note &&
                                        formik.errors.note ? (
                                        <label className="text-danger" >
                                            {formik.errors.note}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div class="clearfix form-actions">
                    <div class="pull-right">
                        {isLoadingLocal ?
                            <button class="btn btn-info" expand="block" disabled>
                                <i class="ace-icon fa fa-check bigger-110"></i>
                                Wait...
                            </button>

                            :
                            <>
                                <button class="btn btn-info" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Update
                                </button>
                            </>
                        }
                    </div>
                </div>

            </form>
        </Container >
    )
}
