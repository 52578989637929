import React, { useContext, useState } from 'react'
import { Container } from '../../../base/Container'
import AppToaster from '../../../commonLib/AppToaster'
import { LoadingButton } from '../../../commonLib/LoadingButton'
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext'
import *as locStatesCrud from '../../../_crud/locStatesCrud'
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom'
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater'

const initialValues = {
    name: "",
    code: "",
    priority: 0
}
export function AddLocStates() {
    const history = useHistory()
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)

    const Schema = Yup.object().shape({
        name: Yup.string().required("Name is required."),
        code: Yup.string().required("Code is required."),
        priority: Yup.string().required("Priority is required.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });
    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        locStatesCrud.Add(value)
            .then(res => {
                setIsLoadingMini(false)
                setIsLoadingLocal(false);
                AppToaster('Added successfully.', 'success')
                history.push('/location')

            }).catch(error => {
                setIsLoadingMini(false)
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    return (
        <>
            <Container title="Add States" isBack="true">
                <form onSubmit={formik.handleSubmit} class="form-horizontal">
                    <div className="row">
                        <div className="col-md-6">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Name</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Name" name="name" value={formik.values.name} onChange={(e) => formik.setValues({ ...formik.values, name: e.target.value })} />
                                    {
                                        formik.touched.name &&
                                            formik.errors.name ? (
                                            <label className="text-danger" >
                                                {formik.errors.name}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Code</label>
                                    <input className="form-control" type="text" placeholder="Code" name="code" {...formik.getFieldProps("code")} />
                                    {
                                        formik.touched.code &&
                                            formik.errors.code ? (
                                            <label className="text-danger" >
                                                {formik.errors.code}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right"> Priority</label>
                                    <input className="form-control" type="number" placeholder="Priority" name="priority" {...formik.getFieldProps("priority")} />
                                    {
                                        formik.touched.priority &&
                                            formik.errors.priority ? (
                                            <label className="text-danger" >
                                                {formik.errors.priority}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="clearfix form-actions">
                        <div class="pull-right">
                            {isLoadingLocal ?
                                <LoadingButton />
                                :
                                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Add
                                </button>}
                        </div>
                    </div>
                </form>
            </Container >
        </>
    )
}
