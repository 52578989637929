const GetEStatusName = (id) => {
    switch (id) {
        case 1: { return "Active" }
        case 2: { return "Deactive" }
        case 0: { return "Blocked" }
    }
}

const GetEStatus = {
    EStatus: [
        { Id: 1, Name: "Active" },
        { Id: 2, Name: "Deactive" },
        { Id: 0, Name: "Blocked" }
    ]
}

export { GetEStatusName, GetEStatus }