import GetLoginInfo from "../../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../../initApi/createAxios";
import { OrgId } from "../../config/GlobalSettings";

export async function VoucherAdd(productionWageVoucher) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/ProductionWageVoucher/VoucherAdd", productionWageVoucher)
    }
}

export async function VoucherRemove(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/ProductionWageVoucher/VoucherRemove?Id=" + id)
    }
}

export async function GetVoucherById(voucherId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Vouchers/ProductionWageVoucher/GetVoucherById?VoucherId=" + voucherId)
    }
}

export async function GetRecevingsToVouchered(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/ProductionWageVoucher/GetRecevingsToVouchered", param)
    }
}

export async function AddVoucherPayment(productionWageVoucher) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/ProductionWageVoucher/AddVoucherPayment", productionWageVoucher)
    }
}

export async function RemoveVoucherPayment(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/ProductionWageVoucher/RemoveVoucherPayment?PaymentId=" + id)
    }
}

export async function SattleVoucher(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/ProductionWageVoucher/SattleVoucher", param)
    }
}