import GetLoginInfo from "../../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../../initApi/createAxios";
import { OrgId } from "../../config/GlobalSettings";

export async function VoucherAdd(cashBankCashVoucher) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/CashBankCashVoucher/VoucherAdd", cashBankCashVoucher)
    }
}

export async function VoucherRemove(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/CashBankCashVoucher/VoucherRemove?Id=" + id)
    }
}

export async function GetVoucherById(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Vouchers/CashBankCashVoucher/GetVoucherById?Id=" + id)
    }
}


export async function AddVoucherPayment(cashBankCashVoucher) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/CashBankCashVoucher/AddVoucherPayment", cashBankCashVoucher)
    }
}

