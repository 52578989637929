import { useFormik } from 'formik'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom';
import AppToaster from '../../commonLib/AppToaster';
import * as productWageCrud from '../../_crud/productWageCrud';
import *as Yup from "yup"
import { Container } from '../../base/Container';
import { useParams } from 'react-router-dom';
import { LoadingButton } from '../../commonLib/LoadingButton';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import GetCurrentDateForSearch from '../../commonLib/dateFormat';
import DatePicker from "react-datepicker";
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

export function AddProductWage() {
    const history = useHistory();
    const { id } = useParams()
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)

    const initialValues = {
        productId: id,
        wage: 0,
        applyFromDate: new Date(GetCurrentDateForSearch(0)),
        status: 1,
        note: ""
    }
    const Schema = Yup.object().shape({
        productId: Yup.string().required("Product is required."),
        wage: Yup.string().required("Wage is required."),
        applyFromDate: Yup.string().required("Apply Date is required."),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values)
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    })

    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        productWageCrud.AddNormal(value)
            .then(res => {
                if (res.data.result.succeeded) {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster('Added successfully.', 'success')
                    history.goBack()
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    return (
        <Container title="Add Wage" isBack="true" isProduct="true">
            <form onSubmit={formik.handleSubmit} class="form-horizontal">
                <div className="row">
                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="ontrol-label no-padding-right">Wage</label>
                                <input className="form-control" type="number" placeholder="Wage" name="wage" {...formik.getFieldProps("wage")} />
                                {
                                    formik.touched.wage &&
                                        formik.errors.wage ? (
                                        <label className="text-danger" >
                                            {formik.errors.wage}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Apply From</label>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker placeholder="applyFromDate" name="applyFromDate" className="form-control input-date-padding" selected={formik.values.applyFromDate} onChange={(date) => formik.setValues({ ...formik.values, applyFromDate: date })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                                {
                                    formik.touched.applyFromDate &&
                                        formik.errors.applyFromDate ? (
                                        <label className="text-danger" >
                                            {formik.errors.applyFromDate}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Note</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="Note" name="note" value={formik.values.note} onChange={(e) => formik.setValues({ ...formik.values, note: e.target.value })} />
                                {
                                    formik.touched.note &&
                                        formik.errors.note ? (
                                        <label className="text-danger" >
                                            {formik.errors.note}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div class="clearfix form-actions">
                    <div class="pull-right">
                        {isLoadingLocal ?
                            <LoadingButton />
                            :
                            <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                <i class="ace-icon fa fa-check bigger-110"></i>
                                Add
                            </button>}
                    </div>
                </div>
            </form>
        </Container>
    )
}
