import React, { useState } from "react"

export const diskSizeContext = React.createContext()

const DiskSizeProvider = (props) => {
    const [diskSize, setDiskSize] = useState();
    return (
        <diskSizeContext.Provider value={[diskSize, setDiskSize]}>
            {props.children}
        </diskSizeContext.Provider>
    )
}

export default DiskSizeProvider;