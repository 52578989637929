const GetEInitOrderStatusName = (id) => {
    switch (id) {
        case 1: { return "Pending" }
        case 2: { return "Sent" }
    }
}

const GetEInitOrderStatus = {
    EInitOrderStatus: [
        { Id: 1, Name: "Pending" },
        { Id: 2, Name: "Sent" },
    ]
}

export { GetEInitOrderStatusName, GetEInitOrderStatus }