import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { loadingContext } from '../../commonLib/_lodingContext';
import * as gatePassCrud from '../../_crud/gatePassCrud'
import { TimelineHistory } from '../Global/TimelineHistory';
import { GatePassPrint } from '../Prints/GatePassPrint';
import { PrintStarter } from '../Prints/PrintStarter';
import { PrintStarterDash } from '../Prints/PrintStaterDash';
import { GatePassItemView } from './GatePassItemView';
import { getIsPrint } from '../../config/GlobalSettings';

export function GatePassDashboard() {
    const [gatePass, setGatePass] = useState()
    const [gatePassProduct, setGatePassProduct] = useState([])
    const [gatePassProductCount, setGatePassProductCount] = useState({ count: 0, totalQuantity: 0 })
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const { id } = useParams()

    useEffect(() => {
        getGatePass()
    }, []);

    function getGatePass() {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        setIsLoading(true);
        gatePassCrud.GetById(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let hproduct = res.data.products
                        setGatePass(hdata)
                        setGatePassProduct(hproduct)
                        if (hproduct) {
                            let c = 0
                            let q = 0
                            hproduct.forEach(e => {
                                c = c + 1
                                q = q + e.quantity
                            });
                            setGatePassProductCount({ count: c, totalQuantity: q })
                        }
                    }
                    else {
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                }
                setIsLoadingMini(false)
                setIsLoadingLocal(false);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoadingMini(false)
                setIsLoadingLocal(false);
                setIsLoading(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        getGatePass()
        setTimeout(() => {
            event.target.complete();
        }, 1000);
    }
    const printRef = useRef();
    return (
        <>
            {getIsPrint && <div style={{ display: "none" }}>
                <GatePassPrint gatePass={gatePass} gatePassProductCount={gatePassProductCount} gatePassProduct={gatePassProduct} ref={printRef} />
            </div>}
            <Container title={"GatePass Dashboard"} doRefresh={doRefresh} isBack="true">
                {getIsPrint && gatePass && gatePassProduct &&
                    <div class="btn-group">
                        <PrintStarterDash printRef={printRef} />
                    </div>
                }
                {getIsPrint && <div class="hr dotted"></div>
}
                <div className="row">
                    <div className="col-lg-8">
                        <GatePassItemView gatePass={gatePass} gatePassProductCount={gatePassProductCount} gatePassProduct={gatePassProduct} doRefresh={getGatePass} />
                    </div>
                    <div className="col-lg-4">
                        <TimelineHistory id={id} type={7} pageSize={10} text="Order Timeline" />
                    </div>
                </div>
            </Container>
        </>
    )
}

