import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { EmptyData } from '../../commonLib/EmptyData'
import Moment from 'react-moment';
import { GetEOrderStatusName } from '../../enumCollections/EOrderStatus'
import { GetEBoolName } from '../../enumCollections/EBool'
import { PAGING_PAGE_SIZE, getIsPrint } from '../../config/GlobalSettings';
import { Spinner } from '../../commonLib/Spinner';
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import *as orderCrud from '../../_crud/orderCrud'
import DatePicker from "react-datepicker";
import GetCurrentDateForSearch from '../../commonLib/dateFormat'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { MyAlert } from '../../commonLib/MyAlert';
import { useParams } from 'react-router-dom';
import { Paging } from '../../commonLib/Paging';
import { UserView } from '../Global/Elements/UserView';
import { ProductView } from '../Global/Elements/ProductView';
import { ContactView } from '../Global/Elements/ContactView';
import { ProductionUnitView } from '../Global/Elements/ProductionUnitView';
import { OrderPrint } from './../Prints/OrderPrint';
import { PrintStarter } from '../Prints/PrintStarter';
import { WarehouseView } from '../Global/Elements/WarehouseView';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

export function ManageProductionUnitOrderContact() {
    const [isLoading, setIsLoading] = useContext(loadingMiniContext)
    const [orders, setOrders] = useState([])
    const { id, productionUnitId } = useParams()
    const [LastPage, setLastPage] = useState(1)
    const [searchType, setSearchType] = useState(0)
    const [pagingData, setPagingData] = useState()

    const initValues = {
        Search: {
            SearchTerm: "",
            PageNo: 1,
            PageSize: PAGING_PAGE_SIZE
        },
        forProductionUnitId: productionUnitId,
        allotedForContactId: id,
        Range: {
            fromDate: new Date(GetCurrentDateForSearch(-30)),
            toDate: new Date(GetCurrentDateForSearch(0))
        }
    }
    const [search, setSearch] = useState(initValues)

    useEffect(() => {
        getOrderType()
    }, [search.Search.PageNo])

    const OnPageChange = (e) => {
        const { value } = e.target
        setSearch({ ...search, Search: { ...search.Search, PageNo: value } });
    }

    function OnSearch() {
        getOrderType()
    }

    function getOrder() {
        setIsLoading(true);
        orderCrud.GetForProductionUnitIdContact(search)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        //alert(JSON.stringify(hdata))
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setOrders(hdata);
                        setIsLoading(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoading(false)
                        setOrders([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoading(false)
                    setOrders([]);
                    AppToaster('Network error', 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoading(false);
                AppToaster(error, 'error')
            })
    }

    function getOrderPending() {
        setIsLoading(true);
        orderCrud.GetForProductionUnitIdContactPendings(search)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setOrders(hdata);
                        setIsLoading(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoading(false)
                        setOrders([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoading(false)
                    setOrders([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoading(false);
                AppToaster(error, 'error')
            })
    }

    const getOrderType = () => {
        if (searchType == 0) {
            getOrder()
        }
        else {
            getOrderPending()
        }
    }

    function doRefresh(event) {
        setSearch({ ...search, Search: { ...search.Search, pageNo: 1 } })
        getOrder()
        setTimeout(() => {
            event.target.complete();
        }, 1000);
    }

    function SearchChange(e) {
        const value = e.target.value
        setSearch({ ...search, Search: { ...search.Search, SearchTerm: value } })
    }

    const DeleteItem = (id) => {
        setIsLoading(true)
        orderCrud.Remove(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoading(false)
                    AppToaster('Deleted successfully.', 'success')
                    getOrder()
                }
                else {
                    setIsLoading(false)
                    AppToaster('Using by someone,could not delete.', 'error')
                }

            }).catch(error => {
                setIsLoading(false)
                AppToaster('Network error', 'error')
            })
    }

    const checkDate = (date, status) => {
        var date1 = new Date(date);
        var date2 = new Date();
        if (date1 <= date2) {
            if (status == 0 || status == 2)
                return "red bolder"
            else
                return ""
        }
        else { return "" }
    }
    const [printData, setPrintData] = useState()
    const printRef = useRef();

    return (
        <>
            {getIsPrint && <div style={{ display: "none" }}>
                <OrderPrint printData={printData} ref={printRef} />
            </div>}
            <Container title="Orders" doRefresh={doRefresh} isBack="true" isContact="true" isProduction="true">
                <Link to={"/contact/dashboard/" + id + "/productionunit/orders/" + productionUnitId + "/add"} class="btn btn-sm btn-success ">
                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                    New
                </Link>
                <Link to={"/contact/dashboard/" + id + "/productionunit/orders/" + productionUnitId + "/initorderforproduction"} class="btn btn-sm btn-warning " style={{ marginLeft: "5px" }}>
                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                    Initial Order
                </Link>
                <div class="hr dotted"></div>
                <div class="form-horizontal" >
                    <div className="row">
                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker selectsStart startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.fromDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, fromDate: date } })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker selectsEnd startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.toDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, toDate: date } })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <select className="form-control" value={searchType} onChange={(e) => setSearchType(e.target.value)}>
                                    <option value="0">All</option>
                                    <option value="1">Pending Due</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-md-5">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <TextBoxTranslater style={{paddingLeft: "24px"}} value={search.Search.SearchTerm} onChange={SearchChange} className="form-control" type="text" id="form-field-icon-1" placeholder="Search Init Orders.." />
                                    <i class="ace-icon fa fa-search blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-1">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <button onClick={OnSearch} to="/order/add" class="btn btn-sm btn-info pull-right">
                                    <i class="ace-icon fa fa-search bigger-110"></i>
                                    Run
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <Paging
                    pagingData={pagingData}
                    LastPage={LastPage}
                    CurrentPage={search.Search.PageNo}
                    OnPageChange={OnPageChange} >
                    {isLoading ?
                        <Spinner />
                        :
                        orders && orders.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            {getIsPrint && <th></th>}
                                            <th>Branch</th>
                                            <th>#Id</th>
                                            <th>On Date</th>
                                            <th>Due Date</th>
                                            <th>Alloted To</th>
                                            <th>To Production</th>
                                            <th>Product</th>
                                            <th>Configuration</th>
                                            <th>Quantity</th>
                                            <th>Status</th>
                                            <th>Details</th>
                                            <th>By</th>
                                            <th style={{ width: "43px" }}></th>
                                            <th style={{ width: "43px" }}></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {orders.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link className="btn btn-xs btn-info" to={"/order/dashboard/" + item.id} title="View dashboard" ><i class="ace-icon fa fa-tachometer"></i></Link>
                                                    </div>
                                                </td>
                                                {getIsPrint && <td>
                                                    <div class="btn-group">
                                                        <PrintStarter printRef={printRef} setPrintData={setPrintData} item={item} />
                                                    </div>
                                                </td>}
                                                <td><WarehouseView item={item.warehouse} uid={"WH" + index} /></td>
                                                <td>{item.id}</td>
                                                <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.onDate}</Moment></div></td>
                                                <td><div style={{ width: "70px" }} className={checkDate(item.dueDate, item.status)}><Moment format="DD-MMM-YYYY">{item.dueDate}</Moment></div></td>
                                                <td>
                                                    <Link to={"/contact/dashboard/" + item.allotedToContactId}>
                                                        <ContactView item={item.toContact} uid={"C" + index} />
                                                    </Link>
                                                </td>
                                                <td>
                                                    <Link to={"/productionunit/dashboard/" + item.toProductionUnitId}>
                                                        <ProductionUnitView item={item.toProductionUnit} /></Link>
                                                </td>
                                                <td>
                                                    {item.productId ? <Link to={"/product/dashboard/" + item.productId}>
                                                        <ProductView item={item.product} uid={"PP" + index} />
                                                    </Link> : "NA"}
                                                </td>
                                                <td>
                                                    {item.productionConfiguration ?
                                                        <Link to={"/productionunit/configuration/dashboard/" + item.productionConfiguration.id}>
                                                            {item.productionConfiguration.name}
                                                        </Link>
                                                        :
                                                        "NA"
                                                    }
                                                </td>
                                                <td>{item.quantity} {item.unit}</td>
                                                <td><span class={item.status == 1 ? "label label-sm label-primary" : "label label-sm label-warning"}>{GetEOrderStatusName(item.status)}</span></td>
                                                <td><div style={{ width: "200px" }}>{item.details}</div></td>
                                                <td>
                                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link to={"/order/edit/" + item.id} class="btn btn-xs btn-success">
                                                            <i class="ace-icon fa fa-pencil bigger-120"></i>
                                                        </Link>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div class="btn-group">
                                                        <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                            <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                        </MyAlert>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </Paging>
            </Container >
        </>
    )
}
