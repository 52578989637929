const GetEHistoryActionName = (id) => {
    switch (id) {
        case 1: { return "Add" }
        case 2: { return "Update" }
        case 3: { return "Delete" }
    }
}

const GetEHistoryAction = {
    EHistoryAction: [
        { Id: 1, Name: "Add" },
        { Id: 2, Name: "Update" },
        { Id: 3, Name: "Delete" }
    ]
}

export { GetEHistoryActionName, GetEHistoryAction }