import React from 'react'
import AppToaster from './AppToaster';

export function FileUploader({ setFile, isBase64 = false, height = 300, width = 300 }) {
    const FileSetter = (e) => {
        if (e.target.files && e.target.files[0]) {
            let imageFile = e.target.files[0]

            if (isBase64) {
                getBase64(imageFile, (result) => {
                    resizeImage(result, width, height).then(newsrc => {
                        setFile(newsrc);
                    }).catch(err => {
                    })
                });
            }
            else {
                const fileSizeKiloBytes = imageFile.size / 1024
                if (fileSizeKiloBytes <= 1024) {
                    setFile(imageFile)
                }
                else {
                    setFile()
                    AppToaster(Number(fileSizeKiloBytes / 1024).toFixed(2) + "MB file is to large, please select max 1MB file", "error")
                }
            }
        } else {
            setFile()
        }
    }
    const resizeImage = (base64Str, width = 400, height = 350) => {
        return new Promise((resolve) => {
            let img = new Image()
            img.src = base64Str
            img.onload = () => {
                let canvas = document.createElement('canvas')
                canvas.width = width
                canvas.height = height
                let ctx = canvas.getContext('2d')
                ctx.drawImage(img, 0, 0, width, height)
                resolve(canvas.toDataURL())
            }
        })
    }

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    return (
        <input type="file" className="form-control" onChange={FileSetter} />
    )
}