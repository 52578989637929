import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../../../base/Container'
import AppToaster from '../../../commonLib/AppToaster'
import * as cashBankCashVoucherCrud from '../../../_crud/vouchers/cashBankCashVoucherCrud'
import { useFormik } from 'formik'
import * as Yup from "yup"
import { useHistory } from 'react-router-dom'
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext'
import { LoadingButton } from '../../../commonLib/LoadingButton'
import ContactSelector from '../../Global/ContactSelector'
import { ContactSelectorView } from '../../Global/SelectorView/ContactSelectorView';
import GetCurrentDateForSearch from '../../../commonLib/dateFormat';
import DatePicker from "react-datepicker";
import GetLoginInfo from '../../../auth/_userContext/GetLoginInfo'
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater'

// const initialValues = {
//     ledgerId: "",
//     Voucher: {
//         linkedId: "",
//         contactId: "",
//         voucherType: 5,
//         linkedIdType: 0,
//         paymentStatus: 0,
//         totalAmount: 0,
//         voucherDate: new Date(GetCurrentDateForSearch(0)),
//         note: ""
//     }
// }
const initialValues = {
    ledgerId: "",
    linkedId: "",
    contactId: "",
    voucherType: 5,
    linkedIdType: 0,
    paymentStatus: 0,
    totalAmount: 0,
    voucherDate: new Date(GetCurrentDateForSearch(0)),
    note: ""
}
export function AddCashBankCashVoucher() {
    const history = useHistory();
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [selectedContactData, setSelectedContactData] = useState(undefined);
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const userData = GetLoginInfo()

    const Schema = Yup.object().shape({
        ledgerId: Yup.string().required("From is required."),
        linkedId: Yup.string().required("To is required."),
        contactId: Yup.string().required("Contact is required."),
        totalAmount: Yup.string().required("Amount is required."),
        voucherDate: Yup.string().required("Voucher Date is required."),
    })

    useEffect(() => {
        if (selectedContactData) {
            formik.setValues({ ...formik.values, contactId: selectedContactData.id })
        }
    }, [selectedContactData]);


    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    const Save = (value) => {
        if (value.quantity > 0) {
            if (value.ledgerId != value.linkedId && value.quantity > 0) {
                setIsLoadingMini(true);
                setIsLoadingLocal(true)
                cashBankCashVoucherCrud.VoucherAdd({
                    ledgerId: value.ledgerId,
                    Voucher: {
                        linkedId: value.linkedId,
                        contactId: value.contactId,
                        voucherType: value.voucherType,
                        linkedIdType: value.linkedIdType,
                        paymentStatus: value.paymentStatus,
                        totalAmount: value.totalAmount,
                        voucherDate: value.voucherDate,
                        note: value.note
                    }
                })
                    .then(res => {
                        if (res.data.result.succeeded) {
                            setIsLoadingMini(false)
                            setIsLoadingLocal(false);
                            AppToaster('Added successfully.', 'success')
                            history.goBack()
                        }
                        else {
                            setIsLoadingMini(false)
                            setIsLoadingLocal(false);
                            AppToaster("Server Error /Invalid Try.", 'error')
                        }
                    })
                    .catch(error => {
                        setIsLoadingMini(false);
                        setIsLoadingLocal(false);
                        AppToaster(error, 'error')
                    })
            }
            else {
                AppToaster("From and To could not be same.", "error")
            }
        }
        else {
            AppToaster("Quantity should not be zero.", "error")
        }
    }

    return (
        <>
            <Container title="Add Cash Bank Cash Voucher" isBack="true">
                <form onSubmit={formik.handleSubmit} class="form-horizontal">
                    <div className="row">
                        <div className="col-md-6">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Contact</label>
                                    <ContactSelector searchtext="" setSelectedData={setSelectedContactData} isWarehouse={userData.isWarehouse == 0 ? false : true}/>
                                    {
                                        formik.touched.contactId &&
                                            formik.errors.contactId ? (
                                            <label className="text-danger" >
                                                {formik.errors.contactId}
                                            </label>
                                        ) : null
                                    }
                                </div>
                                <ContactSelectorView data={selectedContactData} />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Voucher Date</label>
                                    <span class="input-icon" style={{ width: "100%" }}>
                                        <DatePicker placeholder="Voucher Date" name="voucherDate" className="form-control input-date-padding" selected={formik.values.voucherDate} onChange={(e) => formik.setValues({ ...formik.values, voucherDate: e })} />
                                        <i class="ace-icon fa fa-calendar blue"></i>
                                    </span>
                                    {
                                        formik.touched.voucherDate &&
                                            formik.errors.voucherDate ? (
                                            <label className="text-danger" >
                                                {formik.errors.voucherDate}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">From</label>
                                    <select className="form-control" name="ledgerId" value={formik.values.ledgerId} onChange={(e) => formik.setValues({ ...formik.values, ledgerId: e.target.value })}>
                                        <option value="" >Select...</option>
                                        <option value="44">Bank</option>
                                        <option value="6">Cash</option>
                                    </select>
                                    {
                                        formik.touched.ledgerId &&
                                            formik.errors.ledgerId ? (
                                            <label className="text-danger" >
                                                {formik.errors.ledgerId}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">To</label>
                                    <select className="form-control" name="linkedId" value={formik.values.linkedId} onChange={(e) => formik.setValues({ ...formik.values, linkedId: e.target.value })} >
                                        <option value="" >Select...</option>
                                        <option value="6">Cash</option>
                                        <option value="44">Bank</option>
                                    </select>
                                    {
                                        formik.touched.linkedId &&
                                            formik.errors.linkedId ? (
                                            <label className="text-danger" >
                                                {formik.errors.linkedId}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Total Amount</label>
                                    <input className="form-control" placeholder="Total Amount" type="number" value={formik.values.totalAmount} onChange={(e) => formik.setValues({ ...formik.values, totalAmount: e.target.value })} />
                                    {
                                        formik.touched.totalAmount &&
                                            formik.errors.totalAmount ? (
                                            <label className="text-danger" >
                                                {formik.errors.totalAmount}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-12">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Note</label>
                                    <TextBoxTranslater className="form-control" placeholder="Note" type="text" value={formik.values.note} onChange={(e) => formik.setValues({ ...formik.values, note: e.target.value })} />
                                    {
                                        formik.touched.note &&
                                            formik.errors.note ? (
                                            <label className="text-danger" >
                                                {formik.errors.note}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="clearfix form-actions">
                        <div class="pull-right">
                            {isLoadingLocal ?
                                <LoadingButton />
                                :
                                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Add
                                </button>}
                        </div>
                    </div>

                </form>
            </Container >
        </>
    )
}
