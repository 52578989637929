import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { EmptyData } from '../../commonLib/EmptyData'
import Moment from 'react-moment';
import { GetEOrderStatusName } from '../../enumCollections/EOrderStatus'
import { GetEBoolName } from '../../enumCollections/EBool'
import { PAGING_PAGE_SIZE, getIsPrint } from '../../config/GlobalSettings';
import { Spinner } from '../../commonLib/Spinner';
import { Paging } from '../../commonLib/Paging';
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import *as orderCrud from '../../_crud/orderCrud'
import *as contactProcessingAllotmentCrud from '../../_crud/contactProcessingAllotmentCrud'
import *as productionUnitCrud from '../../_crud/productionUnitCrud'
import DatePicker from "react-datepicker";
import GetCurrentDateForSearch from '../../commonLib/dateFormat'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { MyAlert } from '../../commonLib/MyAlert';
import { UserView } from '../Global/Elements/UserView';
import { ContactView } from '../Global/Elements/ContactView';
import { ProductionUnitView } from '../Global/Elements/ProductionUnitView';
import { ProductView } from '../Global/Elements/ProductView';
import { StatusView } from '../../commonLib/StatusView';
import { OrderPrint } from '../Prints/OrderPrint';
import { PrintStarter } from '../Prints/PrintStarter';
import { loadingContext } from '../../commonLib/_lodingContext';
import { ProductionSelectorView } from '../Global/SelectorView/ProductionSelectorView';
import { ContactSelectorView } from '../Global/SelectorView/ContactSelectorView';
import ContactSelector from '../Global/ContactSelector';
import GetLoginInfo from '../../auth/_userContext/GetLoginInfo';
import { WarehouseView } from './../Global/Elements/WarehouseView'
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

const initValues = {
    Search: {
        searchTerm: "",
        pageNo: 1,
        pageSize: PAGING_PAGE_SIZE
    },
    Range: {
        fromDate: new Date(GetCurrentDateForSearch(-30)),
        toDate: new Date(GetCurrentDateForSearch(0))
    },
    IsForUnit:0
}
export function ManageStockOrders() {
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [search, setSearch] = useState(initValues)
    const [orders, setOrders] = useState([])
    const [LastPage, setLastPage] = useState(1)
    const [searchType, setSearchType] = useState(0)
    const [pagingData, setPagingData] = useState()
    const userData = GetLoginInfo()

    const init = {
        id: undefined,
        contactId: "",
        processingId: "",
        isProductionUnit: 0,
        productionUnitId: ""
    }
    const [toProcessing, setToProcessing] = useState(init)
    const [processing, setProcessing] = useState([])
    const [production, setProduction] = useState([])
    const [selectedContactData, setSelectedContactData] = useState(undefined);
    const [selectedProductionData, setSelectedProductionData] = useState(undefined);

    const OnProductionChange = (e) => {
        const { value } = e.target;
        if (value) {
            production.forEach(element => {
                if (element.id == value) {
                    {
                        setSelectedProductionData(element)
                    }
                }
            });
        }
        else {
            setSelectedProductionData(undefined)
        }
    }
    const OnProcessingChange = (e) => {
        const { value } = e.target;
        let ispunit = 0;

        if (value) {
            processing.forEach(element => {
                if (element.processing.id == value) {
                    {
                        if (element.processing.mode == 1)
                            ispunit = 1
                        else
                            ispunit = 0
                    }
                }
            });
        }
        setToProcessing({ ...toProcessing, processingId: value, isProductionUnit: ispunit, productionUnitId: (ispunit == 0 ? 1 : "") })
    }
    function getProcessing(contactid) {
        setIsLoadingMini(true);
        contactProcessingAllotmentCrud.GetByContactDdl(contactid)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProcessing(hdata);
                    }
                    else {
                        setProcessing([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProcessing([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }
    function getProduction(contactid) {
        setIsLoadingMini(true);
        productionUnitCrud.GetByContactId(contactid)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProduction(hdata);
                    }
                    else {
                        setProduction([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProduction([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }
    useEffect(() => {
        if (selectedContactData) {
            setToProcessing({ ...toProcessing, contactId: selectedContactData.id })
            getProcessing(selectedContactData.id)
            getProduction(selectedContactData.id)
        }
        if (selectedProductionData) {
            setToProcessing({ ...toProcessing, productionUnitId: selectedProductionData.id })
        }

    }, [selectedContactData, selectedProductionData]);

    useEffect(() => {
        getOrderType()
    }, [search.Search.pageNo])


    const OnPageChange = (e) => {
        const { value } = e.target
        setSearch({ ...search, Search: { ...search.Search, pageNo: value } });
    }

    function OnSearch() {
        getOrderType()
    }

    function getOrder() {
        setIsLoading(true);
        orderCrud.GetAll(search)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setOrders(hdata);
                        setIsLoading(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoading(false)
                        setOrders([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoading(false)
                    setOrders([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoading(false);
                AppToaster(error, 'error')
            })
    }

    function getOrderPending() {
        setIsLoading(true);
        orderCrud.GetAllPendings(search)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setOrders(hdata);
                        setIsLoading(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoading(false)
                        setOrders([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoading(false)
                    setOrders([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoading(false);
                AppToaster(error, 'error')
            })
    }

    function getOrderWarehouse() {
        setIsLoading(true);
        orderCrud.GetAllWarehouse({ ...search, WarehouseId: userData.warehouseId })
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setOrders(hdata);
                        setIsLoading(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoading(false)
                        setOrders([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoading(false)
                    setOrders([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoading(false);
                AppToaster(error, 'error')
            })
    }

    function getOrderPendingWarehouse() {
        setIsLoading(true);
        orderCrud.GetAllPendingsWarehouse({ ...search, WarehouseId: userData.warehouseId })
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setOrders(hdata);
                        setIsLoading(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoading(false)
                        setOrders([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoading(false)
                    setOrders([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoading(false);
                AppToaster(error, 'error')
            })
    }

    const getOrderType = () => {
        if (userData.isWarehouse == 0) {
            if (searchType == 0) {
                getOrder()
            }
            else {
                getOrderPending()
            }
        }
        else {
            if (searchType == 0) {
                getOrderWarehouse()
            }
            else {
                getOrderPendingWarehouse()
            }
        }
    }

    function doRefresh(event) {
        setSearch({ ...search, Search: { ...search.Search, pageNo: 1 } })
        getOrderType()
        setTimeout(() => {
            event.target.complete();
        }, 1000);
    }

    function SearchChange(e) {
        const value = e.target.value
        setSearch({ ...search, Search: { ...search.Search, searchTerm: value } })
    }

    const DeleteItem = (id) => {
        setIsLoading(true)
        orderCrud.Remove(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoading(false)
                    AppToaster('Deleted successfully.', 'success')
                    getOrder()
                }
                else {
                    setIsLoading(false)
                    AppToaster('Using by someone,could not delete.', 'error')
                }

            }).catch(error => {
                setIsLoading(false)
                AppToaster('Network error', 'error')
            })
    }

    const checkDate = (date, status) => {
        var date1 = new Date(date);
        var date2 = new Date();
        if (date1 <= date2) {
            if (status == 0 || status == 2)
                return "red bolder"
            else
                return ""
        }
        else { return "" }
    }
    const [printData, setPrintData] = useState()
    const printRef = useRef();
    return (
        <>
            {getIsPrint && <div style={{ display: "none" }}>
                <OrderPrint printData={printData} ref={printRef} />
            </div>}
            <Container title="Stock Orders" doRefresh={doRefresh}>
                <Link to="/order/add" class="btn btn-sm btn-success " style={{ marginLeft: "0px" }}>
                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                    New
                </Link>
                <div class="hr dotted"></div>
                <div class="form-horizontal" >
                    <div className="row">
                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker selectsStart startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.fromDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, fromDate: date } })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker selectsEnd startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.toDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, toDate: date } })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <select className="form-control" value={searchType} onChange={(e) => setSearchType(e.target.value)}>
                                    <option value="0">All</option>
                                    <option value="1">Pending Due</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-md-5">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <TextBoxTranslater style={{paddingLeft: "24px"}} value={search.Search.searchTerm} onChange={SearchChange} className="form-control" type="text" placeholder="Search Orders..." />
                                    <i class="ace-icon fa fa-search blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-1">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <button onClick={OnSearch} to="/order/add" class="btn btn-sm btn-info pull-right">
                                    <i class="ace-icon fa fa-search bigger-110"></i>
                                    Run
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <Paging
                    pagingData={pagingData}
                    LastPage={LastPage}
                    CurrentPage={search.Search.pageNo}
                    OnPageChange={OnPageChange} >

                    {isLoading ?
                        <Spinner />
                        :
                        orders && orders.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            {getIsPrint && <th></th>}
                                            <th>Branch</th>
                                            <th>#Id</th>
                                            <th>On Date</th>
                                            <th>Due Date</th>
                                            <th>Alloted To</th>
                                            <th>To Production</th>
                                            <th>Product</th>
                                            <th>Configuration</th>
                                            <th>Quantity</th>
                                            <th>Status</th>
                                            <th>Details</th>
                                            <th>By</th>
                                            <th style={{ width: "43px" }}></th>
                                            <th style={{ width: "43px" }}></th>
                                        </tr>
                                    </thead>


                                    <tbody>
                                        {orders.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link className="btn btn-xs btn-info" to={"/order/dashboard/" + item.id} title="View dashboard" ><i class="ace-icon fa fa-tachometer"></i></Link>
                                                    </div>
                                                </td>
                                                {getIsPrint && <td>
                                                    <div class="btn-group">
                                                        <PrintStarter printRef={printRef} setPrintData={setPrintData} item={item} />
                                                    </div>
                                                </td>}
                                                <td><WarehouseView item={item.warehouse} uid={"WH" + index} /></td>
                                                <td>{item.id}</td>
                                                <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.onDate}</Moment></div></td>
                                                <td><div style={{ width: "70px" }} className={checkDate(item.dueDate, item.status)}><Moment format="DD-MMM-YYYY">{item.dueDate}</Moment></div></td>
                                                <td>
                                                    <Link to={"/contact/dashboard/" + item.allotedToContactId}>
                                                        <ContactView item={item.toContact} uid={"C" + index} />
                                                    </Link>
                                                </td>
                                                <td><Link to={"/productionunit/dashboard/" + item.toProductionUnitId}>
                                                    <ProductionUnitView item={item.toProductionUnit} />
                                                </Link></td>
                                                <td><Link to={"/product/dashboard/" + item.product.id}>
                                                    <ProductView item={item.product} uid={"PP" + index} />
                                                </Link></td>
                                                <td>
                                                    {item.productionConfiguration ?
                                                        <Link to={"/productionunit/configuration/dashboard/" + item.productionConfiguration.id}>
                                                            {item.productionConfiguration.name}
                                                        </Link>
                                                        :
                                                        "NA"
                                                    }
                                                </td>
                                                <td>{item.quantity} {item.unit}</td>
                                                
                                                <td><StatusView status={item.status}>{GetEOrderStatusName(item.status)}</StatusView></td>
                                                <td><div style={{ width: "200px" }}>{item.details}</div></td>
                                                <td>
                                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link to={"/order/edit/" + item.id} class="btn btn-xs btn-success">
                                                            <i class="ace-icon fa fa-pencil bigger-120"></i>
                                                        </Link>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div class="btn-group">
                                                        <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                            <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                        </MyAlert>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </Paging>



                <div id="orderdash-modal" class="modal fade" tabindex="-1">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                                <h3 class="smaller lighter blue no-margin">Send To Processing</h3>
                            </div>

                            <div class="modal-body">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div class="form-group">
                                            <div class="col-xs-12">
                                                <label class="control-label no-padding-right">Contact</label>
                                                <ContactSelector searchtext="" setSelectedData={setSelectedContactData} />
                                            </div>
                                            <ContactSelectorView data={selectedContactData} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div class="form-group">
                                            <div class="col-xs-12">
                                                <label class=" control-label no-padding-right">Processing</label>
                                                <select className="form-control" name="processingId" value={toProcessing.processingId} onChange={OnProcessingChange}>
                                                    <option value="" >Select...</option>
                                                    {
                                                        processing.map((data, index) =>
                                                            <option key={data.processing.id} value={data.processing.id}>{data.processing.name.toUpperCase()} ({data.processing.id})</option>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {toProcessing.isProductionUnit == 1 &&
                                        <div className="col-md-6">
                                            <div class="form-group">
                                                <div class="col-xs-12">
                                                    <label class="control-label no-padding-right">Production</label>
                                                    <select className="form-control" name="productionUnitId" value={toProcessing.productionUnitId} onChange={OnProductionChange}>
                                                        <option value="" >Select...</option>
                                                        {
                                                            production.map((data, index) =>
                                                                <option key={data.id} value={data.id}>{data.productionUnitName.toUpperCase()} ({data.id})</option>
                                                            )
                                                        }
                                                    </select>
                                                    <ProductionSelectorView data={selectedProductionData} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div class="modal-footer">
                                <button data-dismiss="modal" class="btn btn-info">
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Done
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Container >
        </>
    )
}
