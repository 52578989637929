import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AppToaster = (content, type) => {
  switch (type) {
    case 'success': { toast.success(content, { position: toast.POSITION.BOTTOM_RIGHT }); break; }
    case 'error': { toast.error(content, { position: toast.POSITION.BOTTOM_RIGHT, }); break; }
    case 'warn': { toast.warn(content, { position: toast.POSITION.BOTTOM_RIGHT, }); break; }
    case 'info': { toast.info(content, { position: toast.POSITION.BOTTOM_RIGHT }); break; }
    default: { toast(content, { position: toast.POSITION.BOTTOM_RIGHT, }); break; }
  }
}
export default AppToaster;



