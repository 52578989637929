import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetPayableContact(contactId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Report/GetPayableContact?ContactId=" + contactId)
    }
}

export async function GetPayableAll() {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Report/GetPayableAll")
    }
}

export async function GetAdvanceAll() {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Report/GetAdvanceAll")
    }
}

export async function GetDefectsAll() {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Report/GetDefectsAll")
    }
}

export async function GetCurrentMonthCashBankFlow(month, year) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Report/GetCurrentMonthCashBankFlow?Month=" + month + "&Year=" + year)
    }
}

export async function GetSizeUsed() {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Report/GetSizeUsed")
    }
}

export async function GetProductReportByProcessing(productId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Report/GetProductReportByProcessing?ProductId=" + productId)
    }
}

export async function GetReportByProcessing(processingId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Report/GetReportByProcessing?ProcessingId=" + processingId)
    }
}

export async function GetReportStockByProductContact(ProductId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Report/GetReportStockByProductContact?ProductId=" + ProductId)
    }
}

export async function GetGlobalCountContact(contactId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Report/GetGlobalCountContact?ContactId=" + contactId)
    }
}

export async function GetGlobalCount() {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Report/GetGlobalCount")
    }
}

export async function GetLedgerSummary(FiscalYear) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Report/GetLedgerSummary?FiscalYear=" + FiscalYear)
    }
}

export async function GetAdvanceByContact() {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Report/GetAdvanceByContact")
    }
}

export async function GetDefectsByContact() {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Report/GetDefectsByContact")
    }
}

export async function GetActivityByContact(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Report/GetActivityByContact", param)
    }
}

export async function GetContactByCategory() {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Report/GetContactByCategory")
    }
}

export async function GetContactByCategoryByWarehouse(WarehouseId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Report/GetContactByCategoryByWarehouse?WarehouseId=" + WarehouseId)
    }
}

export async function GetActiveProductsByCategory(IsWarehouse, WarehouseId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Report/GetActiveProductsByCategory?IsWarehouse=" + IsWarehouse + "&WarehouseId=" + WarehouseId)
    }
}

export async function GetActiveProducts(IsWarehouse, WarehouseId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Report/GetActiveProducts?IsWarehouse=" + IsWarehouse + "&WarehouseId=" + WarehouseId)
    }
}

export async function GetDailyProductReport(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Report/GetDailyProductReport", param)
    }
}

export async function GetDailyProcessingReport(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Report/GetDailyProcessingReport", param)
    }
}