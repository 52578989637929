import React, { useContext, useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import { LoadingButton } from '../../commonLib/LoadingButton';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import AppToaster from '../../commonLib/AppToaster';
import * as organizationCrud from '../../_crud/organizationCrud';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

const initialValues = {
  name: "",
  address: "",
  city: "",
  state: "",
  pin: "",
  phone: "",
  email: "",
  website: "",
  whatsApp: "",
  gstn: "",
  termAndCondition: "",
}

export function OrganizationEdit() {
  const [isLoadingLocal, setIsLoadingLocal] = useState(false)
  const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)

  const Schema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    address: Yup.string().required("Address is required."),
    phone: Yup.string().required("Phone is required."),
    city: Yup.string().required("City is required."),
    state: Yup.string().required("State is required."),
    pin: Yup.string().required("Pin is required."),
    email: Yup.string().required("Email is required.")
  })
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values) => {
      Save(values);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });

  const Save = (value) => {
    setIsLoadingMini(true);
    setIsLoadingLocal(true);
    organizationCrud.EditOrganization(value)
      .then(res => {
        if (res.data.succeeded) {
          setIsLoadingMini(false);
          setIsLoadingLocal(false);
          AppToaster('Updated successfully.', 'success')
          window.location.replace("/");
        }
        else {
          setIsLoadingMini(false)
          setIsLoadingLocal(false);
          AppToaster("Server Error /Invalid Try.", 'error')
        }

      })
      .catch(error => {
        setIsLoadingMini(false);
        setIsLoadingLocal(false);
        AppToaster(error, 'error')
      })
  }

  useEffect(() => {
    getOrganization()
  }, []);

  function getOrganization() {
    setIsLoadingMini(true);
    setIsLoadingLocal(true)
    organizationCrud.GetOrganization()
      .then(res => {
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            formik.setValues({
              id: hdata.id,
              name: hdata.name,
              address: hdata.address,
              email: hdata.email,
              phone: hdata.phone,
              city: hdata.city,
              state: hdata.state,
              pin: hdata.pin,
              website: hdata.website,
              whatsApp: hdata.whatsApp,
              gstn: hdata.gstn,
              termAndCondition: hdata.termAndCondition,
            })
          }
          else {
            AppToaster("Server Error /Invalid Try.", 'error')
          }
        }
        else {
        }
        setIsLoadingMini(false)
        setIsLoadingLocal(false);
      })
      .catch(error => {
        setIsLoadingMini(false)
        setIsLoadingLocal(false);
        AppToaster(error, 'error')
      })
  }

  return (
    <form onSubmit={formik.handleSubmit} class="form-horizontal">
      <div className="row">
        <div className="col-md-4">
          <div class="form-group">
            <div class="col-xs-12">
              <label class="control-label no-padding-right">Name</label>
              <TextBoxTranslater className="form-control" type="text" placeholder="Name" name="name" value={formik.values.name} onChange={(e) => formik.setValues({ ...formik.values, name: e.target.value })} />
              {
                formik.touched.name &&
                  formik.errors.name ? (
                  <label className="text-danger" >
                    {formik.errors.name}
                  </label>
                ) : null
              }
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div class="form-group">
            <div class="col-xs-12">
              <label class="control-label no-padding-right"> Phone</label>
              <input className="form-control" type="text" placeholder="Phone" name="phone" {...formik.getFieldProps("phone")} />
              {
                formik.touched.phone &&
                  formik.errors.phone ? (
                  <label className="text-danger" >
                    {formik.errors.phone}
                  </label>
                ) : null
              }
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div class="form-group">
            <div class="col-xs-12">
              <label class="control-label no-padding-right"> Email</label>
              <input className="form-control" type="email" placeholder="Email" name="email" {...formik.getFieldProps("email")} />
              {
                formik.touched.email &&
                  formik.errors.email ? (
                  <label className="text-danger" >
                    {formik.errors.email}
                  </label>
                ) : null
              }
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div class="form-group">
            <div class="col-xs-12">
              <label class="control-label no-padding-right"> City</label>
              <TextBoxTranslater className="form-control" type="text" placeholder="City" name="city" value={formik.values.city} onChange={(e) => formik.setValues({ ...formik.values, city: e.target.value })} />
              {
                formik.touched.city &&
                  formik.errors.city ? (
                  <label className="text-danger" >
                    {formik.errors.city}
                  </label>
                ) : null
              }
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div class="form-group">
            <div class="col-xs-12">
              <label class="control-label no-padding-right"> State</label>
              <TextBoxTranslater className="form-control" type="text" placeholder="State" name="state" value={formik.values.state} onChange={(e) => formik.setValues({ ...formik.values, state: e.target.value })} />
              {
                formik.touched.state &&
                  formik.errors.state ? (
                  <label className="text-danger" >
                    {formik.errors.state}
                  </label>
                ) : null
              }
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div class="form-group">
            <div class="col-xs-12">
              <label class="control-label no-padding-right"> Pin</label>
              <input className="form-control" type="text" placeholder="Pin" name="pin" {...formik.getFieldProps("pin")} />
              {
                formik.touched.pin &&
                  formik.errors.pin ? (
                  <label className="text-danger" >
                    {formik.errors.pin}
                  </label>
                ) : null
              }
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div class="form-group">
            <div class="col-xs-12">
              <label class="control-label no-padding-right"> Address</label>
              <TextBoxTranslater className="form-control" type="text" placeholder="Address" name="address" value={formik.values.address} onChange={(e) => formik.setValues({ ...formik.values, address: e.target.value })} />
              {
                formik.touched.address &&
                  formik.errors.address ? (
                  <label className="text-danger" >
                    {formik.errors.address}
                  </label>
                ) : null
              }
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div class="form-group">
            <div class="col-xs-12">
              <label class="control-label no-padding-right">Website</label>
              <input className="form-control" type="text" placeholder="Website" name="website" {...formik.getFieldProps("website")} />
              {
                formik.touched.website &&
                  formik.errors.website ? (
                  <label className="text-danger" >
                    {formik.errors.website}
                  </label>
                ) : null
              }
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div class="form-group">
            <div class="col-xs-12">
              <label class="control-label no-padding-right">WhatsApp</label>
              <input className="form-control" type="text" placeholder="WhatsApp" name="whatsApp" {...formik.getFieldProps("whatsapp")} />
              {
                formik.touched.whatsApp &&
                  formik.errors.whatsApp ? (
                  <label className="text-danger" >
                    {formik.errors.whatsApp}
                  </label>
                ) : null
              }
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div class="form-group">
            <div class="col-xs-12">
              <label class="ontrol-label no-padding-right"> Gstn</label>
              <input className="form-control" type="text" placeholder="Gstn" name="gstn" {...formik.getFieldProps("gstn")} />
              {
                formik.touched.gstn &&
                  formik.errors.gstn ? (
                  <label className="text-danger" >
                    {formik.errors.gstn}
                  </label>
                ) : null
              }
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div class="form-group">
            <div class="col-xs-12">
              <label class="ontrol-label no-padding-right"> Term And Condition</label>
              <TextBoxTranslater className="form-control" type="text" placeholder="Term And Condition" name="termAndCondition" value={formik.values.termAndCondition} onChange={(e) => formik.setValues({ ...formik.values, termAndCondition: e.target.value })} />
              {
                formik.touched.termAndCondition &&
                  formik.errors.termAndCondition ? (
                  <label className="text-danger" >
                    {formik.errors.termAndCondition}
                  </label>
                ) : null
              }
            </div>
          </div>
        </div>
      </div>

      <div class="clearfix form-actions">
        <div class="pull-right">
          {isLoadingLocal ?
            <LoadingButton />
            :
            <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
              <i class="ace-icon fa fa-check bigger-110"></i>
              Update
            </button>}
        </div>
      </div>

    </form>
  )
}
