import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Container } from '../../base/Container';
import { loadingContext } from '../../commonLib/_lodingContext';
import * as productCrud from '../../_crud/productCrud'
import * as warehouseCrud from '../../_crud/warehouse/warehouseCrud'
import * as reportCrud from '../../_crud/reportCrud'
import { dashboardContext } from '../Global/dashboardContext';
import { PrintStarterDash } from '../Prints/PrintStaterDash';
import { ProductPrint } from '../Prints/ProductPrint';
import GetLoginInfo from '../../auth/_userContext/GetLoginInfo';
import { WarehouseView } from '../Global/Elements/WarehouseView';
import { getIsPrint } from '../../config/GlobalSettings';

export function ProductDashboard() {
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [dashboardData, setDashboardData] = useContext(dashboardContext)
    const [productData, setProductData] = useState()
    const [productDataWarehouse, setProductDataWarehouse] = useState([])
    const [productReport, setProductReport] = useState([])
    const [productStockReport, setProductStockReport] = useState()
    const { id } = useParams()
    const userData = GetLoginInfo()

    function doRefresh() {
        if (userData.isWarehouse == 0) {
            getProduct()
            getProductAllWarehouse()
        }
        else
            getProductWarehouse()
    }

    useEffect(() => {
        doRefresh()
    }, []);

    function getProduct() {
        setIsLoading(true);
        productCrud.GetById(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setDashboardData({ ...dashboardData, productdata: hdata })
                        setProductData(res.data)
                        GetProductReportByProcessing()
                        GetReportStockByProductContact()
                    }
                    else {
                    }
                }
                else {
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
            })
    }

    function getProductWarehouse() {
        setIsLoading(true);
        warehouseCrud.GetByProductId(id, userData.warehouseId)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setDashboardData({ ...dashboardData, productdata: hdata })
                        setProductData(res.data)
                        GetProductReportByProcessing()
                        //GetReportStockByProductContact()
                    }
                    else {
                    }
                }
                else {
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
            })
    }

    function getProductAllWarehouse() {
        setIsLoading(true);
        warehouseCrud.GetByProductForAllWarehouse(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProductDataWarehouse(hdata)
                    }
                    else {
                        setProductDataWarehouse([])
                    }
                }
                else {
                    setProductDataWarehouse([])
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
            })
    }
    function GetProductReportByProcessing() {
        setIsLoading(true);
        reportCrud.GetProductReportByProcessing(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProductReport(hdata)
                    }
                    else {
                    }
                }
                else {
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
            })
    }

    function GetReportStockByProductContact() {
        setIsLoading(true);
        reportCrud.GetReportStockByProductContact(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProductStockReport(hdata)
                    }
                    else {
                    }
                }
                else {
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
            })
    }

    const printRef = useRef();
    return (
        <>
            {getIsPrint && <div style={{ display: "none" }}>
                <ProductPrint product={productData && productData} ref={printRef} />
            </div>}
            <Container title="Product Dashboard" doRefresh={doRefresh} isBack="true" isProduct="true">
                <p>
                    <Link to={"/product/dashboard/" + id + "/wage"} style={{ marginRight: "5px" }} class="btn btn-sm btn-purple ">
                        <i class="ace-icon fa fa-pagelines bigger-110"></i>
                        Wage
                    </Link>
                    <Link to={"/product/design/" + id} style={{ marginRight: "5px" }} class="btn btn-sm btn-yellow ">
                        <i class="ace-icon fa fa-shopping-basket bigger-110"></i>
                        Design (Bewara)
                    </Link>
                    <Link to={"/product/dashboard/" + id + "/consumption"} style={{ marginRight: "5px" }} class="btn btn-sm btn-success ">
                        <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                        Consumption
                    </Link>
                    <Link to={"/product/dashboard/" + id + "/processingallotment"} style={{ marginRight: "5px" }} class="btn btn-sm btn-warning ">
                        <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                        Processings
                    </Link>
                    <Link to={"/product/dashboard/" + id + "/photo"} style={{ marginRight: "5px" }} class="btn btn-sm btn-pink ">
                        <i class="ace-icon fa fa-camera bigger-110"></i>
                        Photo
                    </Link>
                    <Link to={"/product/edit/" + id} class="btn btn-sm btn-info ">
                        <i class="ace-icon fa fa-pencil bigger-110"></i>
                        Edit
                    </Link>
                    {getIsPrint && productData &&
                        <div class="btn-group pull-right">
                            <PrintStarterDash printRef={printRef} />
                        </div>
                    }
                </p>

                <div class="hr dotted"></div>
                <div class="clearfix">
                    <br />
                    <div className="row">
                        <div className="col-md-6">

                            <div className="row">
                                {productData && productData.data &&
                                    <div class="col-sm-12 infobox-container">
                                        <h4>{userData.isWarehouse == 0 ? "MAIN Branch" : userData.warehouseCode} Stock</h4>
                                        <div class="infobox infobox-green">
                                            <div class="infobox-data">
                                                <span class="infobox-data-number">{productData.data.mainStock} {productData.data.unit} </span>
                                                <div class="infobox-content">Main Stock</div>
                                            </div>
                                        </div>

                                        <div class="infobox infobox-blue">
                                            <div class="infobox-data">
                                                <span class="infobox-data-number">{productData.data.rawStock} {productData.data.unit}</span>
                                                <div class="infobox-content">Raw Stock</div>
                                            </div>
                                        </div>

                                        <div class="infobox infobox-pink">
                                            <div class="infobox-data">
                                                <span class="infobox-data-number">{productData.data.processingStock} {productData.data.unit}</span>
                                                <div class="infobox-content">Processing Stock</div>
                                            </div>
                                        </div>

                                        <div class="infobox infobox-red">

                                            <div class="infobox-data">
                                                <span class="infobox-data-number">{productData.data.defectiveStock} {productData.data.unit}</span>
                                                <div class="infobox-content">Defective Stock</div>
                                            </div>
                                        </div>

                                        <div class="infobox infobox-orange2">
                                            <div class="infobox-data">
                                                <span class="infobox-data-number">{productData.data.rejectedStock} {productData.data.unit}</span>
                                                <div class="infobox-content">Rejected Stock</div>
                                            </div>

                                        </div>

                                        <div class="infobox infobox-blue2">
                                            <div class="infobox-data">
                                                <span class="infobox-data-number"> {productData.data.globalStock} {productData.data.unit}</span>
                                                <div class="infobox-content">Global Stock</div>
                                            </div>

                                        </div>

                                    </div>}

                                {productStockReport &&
                                    <>
                                        <br />
                                        <div className="row">
                                            <div class="col-sm-12 infobox-container">
                                                <h4>Contact Stock</h4>
                                                <div class="infobox infobox-green">
                                                    <div class="infobox-data">
                                                        <span class="infobox-data-number">{productStockReport.stock} {productStockReport.unit} </span>
                                                        <div class="infobox-content">Available</div>
                                                    </div>
                                                </div>
                                                <div class="infobox infobox-orange">
                                                    <div class="infobox-data">
                                                        <span class="infobox-data-number">{productStockReport.globalStock - productStockReport.stock} {productStockReport.unit} </span>
                                                        <div class="infobox-content">Used</div>
                                                    </div>
                                                </div>
                                                <div class="infobox infobox-blue">
                                                    <div class="infobox-data">
                                                        <span class="infobox-data-number">{productStockReport.globalStock} {productStockReport.unit} </span>
                                                        <div class="infobox-content">Total Given</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                                {productReport && productReport.length > 0 &&
                                    <>
                                        <br />
                                        <div className="row">
                                            <div class="col-sm-12 infobox-container">
                                                <h4>Processing Stock</h4>

                                                {productReport.map((item, index) =>
                                                    <div class="infobox infobox-green">
                                                        <div class="infobox-data">
                                                            <span class="infobox-data-number">{item.stock} {item.unit} </span>
                                                            <div class="infobox-content">{item.processing.name}</div>
                                                        </div>
                                                        <div class="badge badge-success" title='Processed'>
                                                            {item.globalStock - item.stock} {item.unit}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="col-md-6">

                            {productDataWarehouse && productDataWarehouse.length > 0 &&
                                productDataWarehouse.map((item, index) =>
                                    <div class="col-sm-12 infobox-container">
                                        <h4><WarehouseView item={item.warehouse} uid={"WHP" + index} /></h4>
                                        <div class="infobox infobox-green">
                                            <div class="infobox-data">
                                                <span class="infobox-data-number">{item.mainStock} {item.unit} </span>
                                                <div class="infobox-content">Main Stock</div>
                                            </div>
                                        </div>

                                        <div class="infobox infobox-blue">
                                            <div class="infobox-data">
                                                <span class="infobox-data-number">{item.rawStock} {item.unit}</span>
                                                <div class="infobox-content">Raw Stock</div>
                                            </div>
                                        </div>

                                        <div class="infobox infobox-pink">
                                            <div class="infobox-data">
                                                <span class="infobox-data-number">{item.processingStock} {item.unit}</span>
                                                <div class="infobox-content">Processing Stock</div>
                                            </div>
                                        </div>

                                        <div class="infobox infobox-red">

                                            <div class="infobox-data">
                                                <span class="infobox-data-number">{item.defectiveStock} {item.unit}</span>
                                                <div class="infobox-content">Defective Stock</div>
                                            </div>
                                        </div>

                                        <div class="infobox infobox-orange2">
                                            <div class="infobox-data">
                                                <span class="infobox-data-number">{item.rejectedStock} {item.unit}</span>
                                                <div class="infobox-content">Rejected Stock</div>
                                            </div>

                                        </div>

                                        <div class="infobox infobox-blue2">
                                            <div class="infobox-data">
                                                <span class="infobox-data-number"> {item.globalStock} {item.unit}</span>
                                                <div class="infobox-content">Global Stock</div>
                                            </div>

                                        </div>

                                    </div>
                                )}
                        </div>
                    </div>


                </div>
            </Container>
        </>
    )
}
