import React, { useEffect } from 'react'
import Moment from 'react-moment'
import ReactTooltip from 'react-tooltip'
import { ImgLazyload } from '../../../commonLib/ImgLazyload'
import { DEFAULT_IMG, DEFAULT_USER_IMG, ERROR_IMG } from '../../../config/GlobalSettings'
import { GetEBoolName } from '../../../enumCollections/EBool'
import { GetEStatusName } from '../../../enumCollections/EStatus'
import { BASE_URL } from '../../../initApi/apiUrls'

export function ContactView({ item, uid }) {
    return (
        item &&
        <>
            <a data-tip data-for={uid}>

                <div class="infobox infobox-gray" style={{ border: "none", backgroundColor: "transparent", padding: "0", height: "auto" }}>
                    <div class="infobox-icon" style={{ marginTop: "11px", marginRight: "-11px" }}>
                        <ImgLazyload height="39px" width="39px" className="img-round-contact pull-left" src={item.isPhoto == 1 ? (BASE_URL + "/" + item.photoUrl) : DEFAULT_USER_IMG}
                            onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />

                    </div>

                    <div class="custom-infobox-data">
                        <span class="infobox-data-number" style={{ fontSize: "13px", margin: "0" }}>
                            {item.fullName}
                        </span>
                        <div class="infobox-content">
                            <span class="label label-sm label-success">#{item.id}</span>
                            <small>
                                <span className="label label-sm label-default arrowed-right">{item.productionCategory ? item.productionCategory.productionCatName : "NA"} </span></small>
                        </div>
                    </div>

                    {/* <div class={item.status == 1 ? "badge badge-success" : "badge badge-danger"}>
                {GetEStatusName(item.status)}
            </div> */}
                </div>
            </a>
            <ReactTooltip delayShow={500} arrowColor="gray" borderColor="#9b9b9b" className="opaque" id={uid} type="light">
                <div style={{ width: "100%", textAlign: "center" }}>
                    <ImgLazyload height="200px" width="100%" className="img-round-tooltip" src={item.isPhoto == 1 ? (BASE_URL + "/" + item.photoUrl) : DEFAULT_USER_IMG}
                        onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
                </div>
                <div style={{ padding: "10px" }}>
                    Address: {item.address}
                    <br />{item.city} - {item.pinCode}
                    <br />Phone: {item.phone}
                    {item.email && <><br />Email: {item.email}</>}
                    <br />Is Document <span class={item.isDocument == 1 ? "label label-sm label-success" : "label label-sm label-danger"}>{GetEBoolName(item.isDocument)}</span>
                    &nbsp;&nbsp;Status <span class={item.status == 1 ? "label label-sm label-success" : "label label-sm label-danger"}>{GetEStatusName(item.status)}</span>
                    <br />Category: {item.productionCategory ? item.productionCategory.productionCatName : "NA"}
                    {/* <br />Bank: {item.bankName ? item.bankName : "NA"}
         <br />Account: {item.bankAcountNo ? item.bankAcountNo : "NA"}
         <br />Branch: {item.bankBaranch ? item.bankBaranch : "NA"}, IFSC: {item.ifscCode ? item.ifscCode : "NA"} */}
                    <br />Last activity: {item.lastActivity ? <Moment format="DD-MMM-YYYY HH:mm">{item.lastActivity}</Moment> : "NA"}
                </div>
            </ReactTooltip>
        </>

    )
}
