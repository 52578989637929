import React, { useState } from 'react'
import Moment from 'react-moment'
import { EmptyData } from '../../commonLib/EmptyData'
import { StatusView } from '../../commonLib/StatusView'
import { getPageMargins } from '../../config/GlobalSettings'
import { GetEAccountTypeName } from '../../enumCollections/EAccountType'
import { GetEChequeStatusName } from '../../enumCollections/EChequeStatus'
import { ContactViewS } from '../Global/Elements/ContactViewS'
import { PrintBranding } from './PrintBranding'

export const PrintBankTransactionReport = React.forwardRef((props, ref) => {
    const data = props.printData

    return (
        <div ref={ref}>
            <style type="text/css">
                {getPageMargins()}
            </style>
            {
                data &&
                <PrintBranding printName="Bank Transaction Report" date={new Date()} id="T1">
                    {<>
                        <h4 style={{ textAlign: "center" }}>Date: <Moment format="DD-MMM-YYYY">{data.fromDate}</Moment> - <Moment format="DD-MMM-YYYY">{data.toDate}</Moment></h4>

                        <table className="table table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Cheque Date</th>
                                    <th>Contact</th>
                                    <th>For Bank</th>
                                    <th>Cheque/Trans No</th>
                                    <th>Cheque/Trans Given By</th>
                                    <th>Cheque/Trans Form Bank</th>
                                    <th>Cheque/Trans City</th>
                                    <th>Dr</th>
                                    <th>Cr</th>
                                    <th>Status</th>
                                </tr>
                            </thead>


                            <tbody>
                                <tr style={{
                                    backgroundColor: "#ddd",
                                    border: "3px solid",
                                    borderTop: "0px solid",
                                    borderRight: "0px solid",
                                    borderLeft: "0px solid",
                                    borderColor: "#a9a6a6"
                                }}>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><b>Balance</b></td>
                                    <td><b>₹{Number(data.Cr - data.Dr).toFixed(2)}</b></td>
                                </tr>

                                {data.data && data.data.length > 0 ?
                                    data.data.map((item, index) =>
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.chequeDate}</Moment></div></td>
                                            <td>
                                                <ContactViewS item={item.contact} uid={"C" + index} />
                                            </td>
                                            <td>
                                                <div style={{ width: "250px" }}>
                                                    {item.bank.accountHolderName} ({item.bank.accountNo} - {GetEAccountTypeName(item.bank.accountType)})
                                                    <br />
                                                    IFSC: {item.bank.ifscCode}, Branch: {item.bank.branch}, {item.bank.city}, {item.bank.state}
                                                </div>
                                            </td>
                                            <td><b>{item.chequeNo}</b></td>
                                            <td>{item.chequeGivenByName}</td>
                                            <td>{item.chequeBankName}</td>
                                            <td>{item.chequeCity}</td>
                                            <td>{item.dr == 0 ? "-" : <b>₹{Number(item.dr).toFixed(2)}</b>}</td>
                                            <td>{item.cr == 0 ? "-" : <b>₹{Number(item.cr).toFixed(2)}</b>}</td>
                                            <td><StatusView status={item.status}>{GetEChequeStatusName(item.status)}</StatusView></td>
                                        </tr>
                                    )
                                    :
                                    <tr>
                                        <td colSpan="12">
                                            <EmptyData>
                                                <h1>No transaction found</h1>
                                            </EmptyData>
                                        </td>
                                    </tr>
                                }
                                <tr style={{
                                    backgroundColor: "#ddd",
                                    border: "3px solid",
                                    borderBottom: "0px solid",
                                    borderRight: "0px solid",
                                    borderLeft: "0px solid",
                                    borderColor: "#a9a6a6"
                                }}>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><b>Balance</b></td>
                                    <td><b>₹{Number(data.Cr - data.Dr).toFixed(2)}</b></td>
                                </tr>
                            </tbody>
                        </table>
                    </>

                    }
                </PrintBranding>
            }
        </div>
    )
})