import React from 'react'
import { date } from 'yup'
import { EmptyData } from '../../commonLib/EmptyData'
import { DEFAULT_IMG, ERROR_IMG, getPageMargins } from '../../config/GlobalSettings'
import { GetEBoolName } from '../../enumCollections/EBool'
import { BASE_URL } from '../../initApi/apiUrls'
import { ProductViewS } from '../Global/Elements/ProductViewS'
import { PrintBranding } from './PrintBranding'

export const ProductPrint = React.forwardRef((props, ref) => {
    const { product } = props

    return (
        <div ref={ref}>
            <style type="text/css">
                {getPageMargins()}
            </style>
            {
                product &&
                <PrintBranding printName="Product" id={product.data.id} date={new date()}>

                    {
                        product.data &&
                        <div className="row">
                            <div className="col-xs-12">

                                <table style={{ width: "100%" }} className="table-bordered-dark">
                                    <tbody>
                                        <tr >
                                            <td style={{ width: "200px" }}>
                                                <img height="200px" src={product.data.isPhoto == 1 ? (BASE_URL + "/" + product.data.photoUrl) : DEFAULT_IMG}
                                                    onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
                                            </td>
                                            <td>
                                                <ProductViewS item={product.data} uid={"PPX1"} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    }
                    <br />

                    <div className="row">
                        <div className="col-xs-12">
                            <h4 className="text-center">Configuration</h4>
                            {product.config && product.config.length > 0 ?
                                <table style={{ width: "100%" }} className="table-bordered-dark">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "43px" }}>#Id</th>
                                            <th>Name</th>
                                            <th>Type</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {product.config.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>{index + 1}</td>
                                                <td>{item.configName.toUpperCase()}</td>
                                                <td>{item.type}</td>
                                                <td> {item.value}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                :
                                <EmptyData>
                                    No Configuration
                                    <br />
                                    <br />
                                </EmptyData>
                            }
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-xs-12">
                            <h5 className="text-center">Consumption</h5>
                            {product.consumption && product.consumption.length > 0 ?
                                <table style={{ width: "100%" }} className="table-bordered-dark">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "43px" }}>#Id</th>
                                            <th>Name</th>
                                            <th>It will consume </th>
                                            <th>Is Per Unit Consume</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {product.consumption.map((item, index) =>
                                            <tr key={item.id + "-" + index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <ProductViewS item={item.consumeProduct} uid={"PP" + index} />
                                                </td>
                                                <td><b>{item.consumptionQuantity} {item.consumeProduct.unit}{item.isPerUnitConsume == 1 && <>/{item.product.unit}</>}</b></td>
                                                <td>{GetEBoolName(item.isPerUnitConsume)}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                :
                                <EmptyData>
                                    No Consumption
                                    <br />
                                    <br />
                                </EmptyData>
                            }
                        </div>
                    </div>

                </PrintBranding>

            }
        </div >
    )
})
