import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import GetLoginInfo from '../../../auth/_userContext/GetLoginInfo';
import { AddContact } from './../../Contacts/AddContact'

export function ContactSelectorView({ data, ishide = true }) {
    const [hide, setHide] = useState(ishide);
    const userData = GetLoginInfo()

    return (
        <>
            <div className="center">
                {data &&
                    (hide ?
                        <a style={{ cursor: "pointer" }} onClick={() => setHide(false)}><i class="ace-icon fa fa-eye blue"></i> View contact details</a>
                        :
                        <a style={{ cursor: "pointer" }} onClick={() => setHide(true)}><i class="ace-icon fa fa-eye-slash blue"></i> Hide contact details</a>
                    )
                }
                &nbsp;&nbsp;<a href="#newcontact-modal" data-toggle="modal" style={{ cursor: "pointer" }} ><i class="ace-icon fa fa-plus green"></i> Add New</a>
            </div>

            {
                data && !hide &&
                <>
                    <div class="col-xs-12">
                        <div className="widget-box">
                            <div class="widget-header widget-header-flat widget-header-small">
                                <h5 class="widget-title">
                                    Contact Details
                                </h5>
                            </div>

                            <div class="row">
                                <div class="profile-user-info profile-user-info-striped">
                                    <div class="profile-info-row">
                                        <div class="profile-info-name"><b>Current Contact</b> </div>

                                        <div class="profile-info-value">
                                            <span class="editable editable-click"><Link to={userData.isWarehouse == 0 && "/contact/dashboard/" + data.id}>{data.fullName} ({data.id})</Link></span>
                                        </div>
                                    </div>

                                    <div class="profile-info-row">
                                        <div class="profile-info-name"> Address </div>

                                        <div class="profile-info-value">
                                            <i class="fa fa-map-marker light-orange bigger-110"></i>
                                            <span > {data.address}</span>
                                        </div>
                                    </div>

                                    <div class="profile-info-row">
                                        <div class="profile-info-name">Phone</div>

                                        <div class="profile-info-value">
                                            <span >{data.phone}</span>
                                        </div>
                                    </div>

                                    <div class="profile-info-row">
                                        <div class="profile-info-name">Email</div>

                                        <div class="profile-info-value">
                                            <span >{data.email ? data.email : "NA"}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }

            <div id="newcontact-modal" class="modal fade" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                            <h3 class="smaller lighter blue no-margin">Add Contact</h3>
                        </div>

                        <div class="modal-body">
                            <AddContact isModelView={true} />
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}
