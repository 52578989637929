import React, { useContext, useEffect, useState } from 'react'
import { EmptyData } from '../../../commonLib/EmptyData'
import { Spinner } from '../../../commonLib/Spinner'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export function MobileChart({ cash = [], bank = [], loading, month, year }) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [cashLbl, setCashLbl] = useState([])
    const [bankLbl, setBankLbl] = useState([])

    const [lables, setLables] = useState([])

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'For ' + months[month - 1] + ", " + year,
            },
        },
    };

    useEffect(() => {
        let lblCash = [];
        let lblBank = [];
        let lblAll = [];
        if (cash && bank) {
            cash.forEach(element => {
                let d = new Date(element.date)
                lblAll = [...lblAll, String(d.getDate()).padStart(2, '0')]
                lblCash = [...lblCash, [String(d.getDate()).padStart(2, '0') + '/' + String(d.getMonth() + 1).padStart(2, '0') + '/' + String(d.getFullYear()).padStart(2, '0'), element.cr]]
            });

            bank.forEach(element => {
                let d = new Date(element.date)
                lblAll = [...lblAll, String(d.getDate()).padStart(2, '0')]
                lblBank = [...lblBank, [String(d.getDate()).padStart(2, '0') + '/' + String(d.getMonth() + 1).padStart(2, '0') + '/' + String(d.getFullYear()).padStart(2, '0'), element.cr]]
            });
            let unique = [...new Set(lblAll)]
            unique.sort()

            let uniqueNew = []
            unique.forEach(element => {
                uniqueNew = [...uniqueNew, element + '/' + String(month).padStart(2, '0') + '/' + String(year).padStart(2, '0')]
            });

            let lblCashNew = []
            uniqueNew.forEach(u => {
                var flag = false
                for (let index = 0; index < lblCash.length; index++) {
                    if (u == lblCash[index][0]) {
                        lblCashNew = [...lblCashNew, [lblCash[index][0], lblCash[index][1]]]
                        flag = false
                        break
                    }
                    else {
                        flag = true
                    }
                }

                if (flag) {
                    lblCashNew = [...lblCashNew, [u, 0]]
                }
            });

            let lblBankNew = []
            uniqueNew.forEach(u => {
                var flag = false
                for (let index = 0; index < lblBank.length; index++) {
                    if (u == lblBank[index][0]) {
                        lblBankNew = [...lblBankNew, [lblBank[index][0], lblBank[index][1]]]
                        flag = false
                        break
                    }
                    else {
                        flag = true
                    }
                }

                if (flag) {
                    lblBankNew = [...lblBankNew, [u, 0]]
                }
            });

            setLables(uniqueNew)

            setCashLbl(lblCashNew)
            setBankLbl(lblBankNew)
        }
    }, [cash, bank])

    const data = {
        lables,
        datasets: [
            {
                label: 'Cash ₹',
                data: cashLbl.map((e) => e),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Bank ₹',
                data: bankLbl.map((e) => e),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    return (
        <>
            {loading ?
                <Spinner />
                :
                data.lables && data.lables.length > 0 ?
                    <article style={{ height: "60vh" }}>
                        <Line height={60} options={options} data={data} />
                    </article>
                    :
                    <EmptyData>
                        <h1>No Data</h1>
                    </EmptyData>
            }
        </>
    )
}


