import React from 'react'
import { PrintBranding } from './PrintBranding';
import { getPageMargins } from '../../config/GlobalSettings';
import { ProductionUnitViewS } from '../Global/Elements/ProductionUnitViewS';
import { GetEStockTypeName } from '../../enumCollections/EStockType';
import { GetEBoolName } from '../../enumCollections/EBool';
import { ProductViewS } from '../Global/Elements/ProductViewS';
import { ProcessingViewS } from '../Global/Elements/ProcessingViewS';
import { ContactViewS } from '../Global/Elements/ContactViewS';
import { WarehouseViewS } from '../Global/Elements/WarehouseViewS';

export const ProcessingDistributionPrint = React.forwardRef((props, ref) => {
    const data = props.printData
    return (
        <div ref={ref}>
            <style type="text/css">
                {getPageMargins()}
            </style>
            {
                data &&
                <PrintBranding printName="Processing Distribution" date={data.onDate} id={data.id}>
                    {
                        data &&
                        <div className="row">
                            <div className="col-xs-12">

                                <table style={{ width: "100%" }} className="table-bordered-dark">
                                    <tbody>
                                        <tr >
                                            <td>Contact</td>
                                            <td>
                                                <ContactViewS item={data.contact} uid={"CX1"} />
                                            </td>
                                            <td>
                                                <span className="pull-right">
                                                    <WarehouseViewS item={data.warehouse} />
                                                </span>
                                            </td>
                                        </tr>
                                        <tr >
                                            <td>Processing</td>
                                            <td colSpan={2}>
                                                <ProcessingViewS item={data.processing} />
                                            </td>
                                        </tr>
                                        <tr >
                                            <td>Product</td>
                                            <td colSpan={2}>
                                                <ProductViewS item={data.product} uid={"PPX1"} />

                                            </td>
                                        </tr>
                                        <tr >
                                            <td>Quantity</td>
                                            <td colSpan={2}>{data.quantity} {data.unit}</td>
                                        </tr>
                                        <tr>
                                            <td>Production</td>
                                            <td colSpan={2}>
                                                {data.isProductionUnit == 1 ? (data.productionUnit ?
                                                    <ProductionUnitViewS item={data.productionUnit} />
                                                    : "NA") : <span class="label label-sm label-danger">{GetEBoolName(data.isProductionUnit)}</span>}
                                            </td>
                                        </tr>

                                        <tr >
                                            <td>For Stock</td>
                                            <td colSpan={2}>
                                                {GetEStockTypeName(data.forStock)}
                                            </td>
                                        </tr>

                                        <tr >
                                            <td>Note</td>
                                            <td colSpan={2}>
                                                {data.note ? data.note : "NA"}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    }
                </PrintBranding>
            }
        </div>
    )
})