import React, { useState } from "react"

export const idContext = React.createContext()

const Id = (props) => {
    const [id, setId] = useState(0);
    return (
        <idContext.Provider value={[id, setId]}>
            {props.children}
        </idContext.Provider>
    )
}

export default Id;