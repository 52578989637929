const GetELedgerAssetName = (id) => {
    switch (id) {
        case 6: { return "Cash" }
        case 44: { return "Bank" }
    }
}

const GetELedgerAsset = {
    ELedgerAsset: [
        { Id: 6, Name: "Cash" },
        { Id: 44, Name: "Bank" }
    ]
}

export { GetELedgerAsset, GetELedgerAssetName }