import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Footer } from '../../base/Footer'
import { EmptyData } from '../../commonLib/EmptyData'

export function Error404() {
    const history = useHistory()
    return (
        <div className="page-content">
            <div className="row">
                <div className="col-xs-12">
                    <div className="row animated fadeIn">
                        <div class="col-md-12">
                            <div class="well">
                                <h1 class="grey lighter smaller">
                                    <span class="blue bigger-125">
                                        <i class="ace-icon fa fa-sitemap"></i> 404
                                    </span>
                                    &nbsp;Page Not Found / Or Not Authorize User
                                </h1>

                                <hr />
                                <h3 class="lighter smaller">We looked everywhere but we couldn't find it!</h3>

                                <div>
                                    <form class="form-search">
                                        <span class="input-icon align-middle">
                                            <i class="ace-icon fa fa-search"></i>

                                            <input type="text" class="search-query" placeholder="Give it a search..." />
                                        </span>
                                        <button class="btn btn-sm" type="button">Go!</button>
                                    </form>

                                    <div class="space"></div>
                                    <h4 class="smaller">Try one of the following:</h4>

                                    <ul class="list-unstyled spaced inline bigger-110 margin-15">
                                        <li>
                                            <i class="ace-icon fa fa-hand-o-right blue"></i>
                                            Re-check the url for typos
                                        </li>

                                        <li>
                                            <i class="ace-icon fa fa-hand-o-right blue"></i>
                                            Read the faq
                                        </li>

                                        <li>
                                            <i class="ace-icon fa fa-hand-o-right blue"></i>
                                            Tell us about it
                                        </li>
                                    </ul>
                                </div>
                                <hr />
                                <div className="space"></div>
                                <div className="center">
                                    <a onClick={() => history.goBack()} className="btn btn-grey"><i class="ace-icon fa fa-arrow-left"></i> Back</a>
                                    <Link to="/login" className="btn btn-primary"><i class="ace-icon fa fa-home"></i> Home</Link>
                                </div>
                                <br />
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
