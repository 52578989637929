import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import * as productReturnCrud from '../../_crud/productReturnCrud';
import AppToaster from '../../commonLib/AppToaster';
import { Container } from '../../base/Container';
import { LoadingButton } from '../../commonLib/LoadingButton';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { ProductSelectorView } from '../Global/SelectorView/ProductSelectorView';
import GetCurrentDateForSearch from '../../commonLib/dateFormat';
import DatePicker from "react-datepicker";
import * as productionUnitCrud from '../../_crud/productionUnitCrud';
import * as productCrud from '../../_crud/productCrud';
import { useParams } from 'react-router-dom';
import { ProductContactStockSelector } from '../Global/ProductContactStockSelector';
import { ProductContactStockSelectorView } from '../Global/SelectorView/ProductContactStockSelectorView';
import GetLoginInfo from '../../auth/_userContext/GetLoginInfo';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

export function AddProductionUnitProductReturnContact() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const history = useHistory()
    const [product, setProduct] = useState([])
    const [selectedProductData, setSelectedProductData] = useState(undefined);
    const { id, productionUnitId } = useParams()
    const userData = GetLoginInfo()

    const [selecteIds, setSelecteIds] = useState({
        contactId: id,
        productionUnitId: productionUnitId,
    })

    const initialValues = {
        onDate: new Date(GetCurrentDateForSearch(0)),
        contactId: id,
        productionUnitId: productionUnitId,
        productId: "",
        freshQuantity: 0,
        isFreshQuantityToMain: 1,
        defectiveQuantity: 0,
        rejectedQuantity: 0,
        unit: "",
        note: "",
        isWarehouse: userData.isWarehouse,
        warehouseId: userData.warehouseId,
    }

    const Schema = Yup.object().shape({
        onDate: Yup.string().required("On Date is required."),
        contactId: Yup.string().required("Contact is required."),
        productionUnitId: Yup.string().required("Production Unit is required."),
        productId: Yup.string().required("Product is required."),
        freshQuantity: Yup.string().required("Fresh Quantity is required."),
        isFreshQuantityToMain: Yup.string().required("Is Fresh Quantity To Main is required."),
        defectiveQuantity: Yup.string().required("Defective Quantity is required."),
        rejectedQuantity: Yup.string().required("Rejected Quantity is required."),
        unit: Yup.string().required("Unit is required."),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    function setQuantity() {
        if (selectedProductData) {
            if ((formik.values.freshQuantity + formik.values.defectiveQuantity + formik.values.rejectedQuantity) > selectedProductData.stock) {
                formik.setValues({ ...formik.values, freshQuantity: 0, defectiveQuantity: 0, rejectedQuantity: 0 })
            }
            else if ((formik.values.freshQuantity + formik.values.defectiveQuantity + formik.values.rejectedQuantity) < 0) {
                formik.setValues({ ...formik.values, freshQuantity: 0, defectiveQuantity: 0, rejectedQuantity: 0 })
            }
        }
    }

    useEffect(() => {
        setQuantity()
    }, [formik.values.freshQuantity, formik.values.defectiveQuantity, formik.values.rejectedQuantity]);

    useEffect(() => {
        if (selectedProductData) {
            formik.setValues({ ...formik.values, productId: selectedProductData.product.id, unit: selectedProductData.product.unit })
        }
    }, [selectedProductData])

    const Save = (value) => {
        if ((value.freshQuantity + value.defectiveQuantity + value.rejectedQuantity) > 0) {
            setIsLoadingMini(true);
            setIsLoadingLocal(true)
            productReturnCrud.Add(value)
                .then(res => {
                    if (res.data.result.succeeded) {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false);
                        AppToaster('Added successfully.', 'success')
                        history.goBack()
                    }
                    else {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                })
                .catch(error => {
                    setIsLoadingMini(false);
                    setIsLoadingLocal(false);
                    AppToaster(error, 'error')
                })
        }
        else {
            AppToaster("Quantity should greater then zero.", 'error')
        }
    }

    return (
        <>
            <Container title="Add Product Return" isBack="true" isContact="true" isProduction="true">
                <form onSubmit={formik.handleSubmit} class="form-horizontal">
                    <div className="row">

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Date</label>

                                    <span class="input-icon" style={{ width: "100%" }}>
                                        <DatePicker placeholder="onDate" name="onDate" className="form-control input-date-padding" selected={formik.values.onDate} onChange={(date) => formik.setValues({ ...formik.values, onDate: date })} />
                                        <i class="ace-icon fa fa-calendar blue"></i>
                                    </span>
                                    {
                                        formik.touched.onDate &&
                                            formik.errors.onDate ? (
                                            <label className="text-danger" >
                                                {formik.errors.onDate}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Product</label>
                                    <ProductContactStockSelector searchtext="" setSelectedData={setSelectedProductData} ids={selecteIds} />
                                    {
                                        formik.touched.productId &&
                                            formik.errors.productId ? (
                                            <label className="text-danger" >
                                                {formik.errors.productId}
                                            </label>
                                        ) : null
                                    }
                                    <ProductContactStockSelectorView data={selectedProductData} dataSetter={setSelectedProductData} isDataSetter={userData.isWarehouse == 1 ? true : false} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {selectedProductData &&
                        <div className="row">
                            <div className="col-md-4">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <label class="control-label no-padding-right">Fresh- {formik.values.unit}</label>

                                        <input className="form-control" placeholder="Fresh Quantity" type="number" name="freshQuantity" {...formik.getFieldProps("freshQuantity")} />
                                        {
                                            formik.touched.freshQuantity &&
                                                formik.errors.freshQuantity ? (
                                                <label className="text-danger" >
                                                    {formik.errors.freshQuantity}
                                                </label>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <label class="control-label no-padding-right">Rejected- {formik.values.unit}</label>

                                        <input className="form-control" placeholder="Rejected Quantity" type="number" name="rejectedQuantity" {...formik.getFieldProps("rejectedQuantity")} />
                                        {
                                            formik.touched.rejectedQuantity &&
                                                formik.errors.rejectedQuantity ? (
                                                <label className="text-danger" >
                                                    {formik.errors.rejectedQuantity}
                                                </label>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <label class="control-label no-padding-right">Defective- {formik.values.unit}</label>

                                        <input className="form-control" placeholder="Defective Quantity" type="number" name="defectiveQuantity" {...formik.getFieldProps("defectiveQuantity")} />
                                        {
                                            formik.touched.defectiveQuantity &&
                                                formik.errors.defectiveQuantity ? (
                                                <label className="text-danger" >
                                                    {formik.errors.defectiveQuantity}
                                                </label>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    }
                    <div className="row">
                        <div className="col-md-3">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Is Fresh Quantity To Main</label>

                                    <div>
                                        <input onChange={() => formik.setValues({ ...formik.values, isFreshQuantityToMain: (formik.values.isFreshQuantityToMain == 1 ? 0 : 1) })} checked={formik.values.isFreshQuantityToMain == 1} type="checkbox" class="ace ace-switch ace-switch-5" />
                                        <span class="lbl middle"></span>
                                    </div>
                                    {
                                        formik.touched.isFreshQuantityToMain &&
                                            formik.errors.isFreshQuantityToMain ? (
                                            <label className="text-danger" >
                                                {formik.errors.isFreshQuantityToMain}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-9">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Note</label>

                                    <TextBoxTranslater className="form-control" placeholder="Note" type="text" name="note" value={formik.values.note} onChange={(e) => formik.setValues({ ...formik.values, note: e.target.value })} />
                                    {
                                        formik.touched.note &&
                                            formik.errors.note ? (
                                            <label className="text-danger" >
                                                {formik.errors.note}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="clearfix form-actions">
                        <div class="pull-right">
                            {isLoadingLocal ?
                                <LoadingButton />
                                :
                                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Add
                                </button>}
                        </div>
                    </div>
                </form>
            </Container >
        </>
    )
}
