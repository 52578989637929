import GetLoginInfo from "../../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../../initApi/createAxios";
import { OrgId } from "../../config/GlobalSettings";

export async function GetAll() {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Warehouse/GetAll")
    }
}

export async function GetById(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Warehouse/GetById?Id=" + id)
    }
}
export async function GetByProductId(id, warehouseId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Warehouse/GetByProductId?ProductId=" + id + "&WarehouseId=" + warehouseId)
    }
}
export async function GetByProductForAllWarehouse(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Warehouse/GetByProductForAllWarehouse?ProductId=" + id)
    }
}
export async function Add(param) {
    const loginData = GetLoginInfo();

    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Warehouse/Add", param)
    }
}
export async function Edit(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Warehouse/Edit", param)
    }
}
export async function Remove(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Warehouse/Remove?Id=" + id)
    }
}
export async function GetAllProduct(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Warehouse/GetAllProduct", param)
    }
}