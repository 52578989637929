import React, { useEffect, useState } from 'react'
import { useReactToPrint } from 'react-to-print';

export function PrintStarterDash({ printRef, text = "", color = "info" }) {
    const [printCount, setPrintCount] = useState(0)

    useEffect(() => {
        if (printCount > 0) {
            handleProcessingDistribution()
        }
    }, [printCount])
    const handleProcessingDistribution = useReactToPrint({
        content: () => printRef.current,
    });
    const initPrint = () => { setPrintCount(printCount + 1) }
    return (
        <button onClick={initPrint} className={"btn btn-xs btn-" + color + " bigger-120"} ><i class="ace-icon fa fa-print"></i>{text}</button>
    )
}
