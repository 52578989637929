import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetAll(productProcessingReceiving) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingReceiving/GetAll", productProcessingReceiving)
    }
}

export async function GetByProduct(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductProcessingReceiving/GetByProduct?PageNo=" + param.pageNumber + "&SearchTerm=" + param.searchText + "&PageSize=" + param.pageSize + "&ProductId=" + param.productId  +  "&FromDate=" + param.fromDate + "&ToDate=" + param.toDate)
    }
}
export async function GetByProcessing(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductProcessingReceiving/GetByProcessing?PageNo=" + param.pageNumber + "&SearchTerm=" + param.searchText + "&PageSize=" + param.pageSize + "&ProcessingId=" + param.processingId  +  "&FromDate=" + param.fromDate + "&ToDate=" + param.toDate)
    }
}
export async function GetByContact(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingReceiving/GetByContact",param)
    }
}
export async function GetByProductionUnit(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductProcessingReceiving/GetByProductionUnit?PageNo=" + param.pageNumber + "&SearchTerm=" + param.searchText + "&PageSize=" + param.pageSize + "&ProductionUnitId=" + param.productionUnitId  +  "&FromDate=" + param.fromDate + "&ToDate=" + param.toDate)
    }
}
export async function GetByProcessingWageId(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductProcessingReceiving/GetByProcessingWageId?PageNo=" + param.pageNumber + "&SearchTerm=" + param.searchText + "&PageSize=" + param.pageSize + "&ProcessingWageId=" + param.ProcessingWageId  +  "&FromDate=" + param.fromDate + "&ToDate=" + param.toDate)
    }
}
export async function GetByVoucher(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductProcessingReceiving/GetByVoucher?PageNo=" + param.pageNumber + "&SearchTerm=" + param.searchText + "&PageSize=" + param.pageSize + "&VoucherId=" + param.voucherId  +  "&FromDate=" + param.fromDate + "&ToDate=" + param.toDate)
    }
}
export async function GetById(Id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductProcessingReceiving/GetById?Id=" + Id)
    }
}
export async function Add(productProcessingReceiving) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingReceiving/Add", productProcessingReceiving)
    }
}
export async function Remove(Id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductProcessingReceiving/Remove?Id=" + Id)
    }
}