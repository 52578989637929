import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { GetEProcessingModeName } from '../enumCollections/EProcessingMode'
import { GetEProductionCategoryTypeName } from '../enumCollections/EProductionCategoryType'
import { dashboardContext } from '../pages/Global/dashboardContext'
import { ContactView } from '../pages/Global/Elements/ContactView'
import { ProcessingView } from '../pages/Global/Elements/ProcessingView'
import { ProductionUnitView } from '../pages/Global/Elements/ProductionUnitView'
import { ProductView } from '../pages/Global/Elements/ProductView'

export function DashboardTitle({ isContact = false,
    isProduction = false,
    isProduct = false,
    isProcessing = false,
    isOrder = false,
    isVoucher = false }) {
    const [dashboardData, setDashboardData] = useContext(dashboardContext)

    return (
        <div class="clearfix">
            {isContact && dashboardData.contactdata &&
                <div class="grid2 gridseparator">
                    <Link to={"/contact/dashboard/" + dashboardData.contactdata.id} style={{ color: "#393939", textDecoration: "none" }}>
                        <ContactView item={dashboardData.contactdata} uid={"CDB1"} />
                    </Link>
                    <small>
                        <i class="ace-icon fa fa-angle-double-right" style={{ fontSize: "large", color:"#438eb9" }}></i>
                    </small>
                </div>
            }
            {
                isProduction && dashboardData.productiondata &&
                <div class="grid2 gridseparator">
                    <Link to={"/productionunit/dashboard/" + dashboardData.productiondata.id} style={{ color: "#393939", textDecoration: "none" }}>
                        <ProductionUnitView item={dashboardData.productiondata} />
                    </Link>
                    <small>
                        <i class="ace-icon fa fa-angle-double-right" style={{ fontSize: "large", color:"#438eb9" }}></i>
                    </small>
                </div>
            }
            {
                isProduct && dashboardData.productdata &&
                <div class="grid2 gridseparator">
                    <Link to={"/product/dashboard/" + dashboardData.productdata.id} style={{ color: "#393939", textDecoration: "none" }}>
                        <ProductView item={dashboardData.productdata} uid={"PPB1"} />
                    </Link>
                    <small>
                        <i class="ace-icon fa fa-angle-double-right" style={{ fontSize: "large", color:"#438eb9" }}></i>
                    </small>
                </div>
            }
            {
                isProcessing && dashboardData.processingdata &&
                <div class="grid2 gridseparator">
                    <Link to={"/processing/dashboard/" + dashboardData.processingdata.id} style={{ color: "#393939", textDecoration: "none" }}>
                        <ProcessingView item={dashboardData.processingdata} />
                    </Link>
                    <small>
                        <i class="ace-icon fa fa-angle-double-right" style={{ fontSize: "large", color:"#438eb9" }}></i>
                    </small>
                </div>
            }
            {
                isOrder && dashboardData.orderdata &&
                <div class="grid2 gridseparator">
                    <span class="bigger-175 blue">25</span>

                    <br />
                    Followers
                </div>
            }
            {
                isVoucher && dashboardData.voucherdata &&
                <div class="grid2 gridseparator">
                    <span class="bigger-175 blue">25</span>

                    <br />
                    Followers
                </div>
            }
        </div >
    )
}
