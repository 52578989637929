import { useFormik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import * as initOrderForProductionCrud from '../../_crud/initOrderForProductionCrud';
import * as productionConfigurationCrud from '../../_crud/productionConfigurationCrud';
import * as productConsumptionCrud from '../../_crud/productConsumptionCrud';
import AppToaster from './../../commonLib/AppToaster';
import { Container } from './../../base/Container';
import { LoadingButton } from './../../commonLib/LoadingButton';
import { loadingMiniContext } from './../../commonLib/lodingMiniContext';
import { ProductSelectorView } from '../Global/SelectorView/ProductSelectorView';
import GetCurrentDateForSearch from './../../commonLib/dateFormat';
import DatePicker from "react-datepicker";
import { useParams } from 'react-router-dom';
import { dashboardContext } from '../Global/dashboardContext';
import * as productCrud from '../../_crud/productCrud';
import { GetEInitPriority } from '../../enumCollections/EInitPriority'
import GetLoginInfo from '../../auth/_userContext/GetLoginInfo';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';
import Multiselect from 'multiselect-react-dropdown'

export function AddInitOrderForProductionContact() {
  const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
  const [isLoadingLocal, setIsLoadingLocal] = useState(false)
  const history = useHistory();
  const [selectedProductData, setSelectedProductData] = useState(undefined);
  const [selectedProductProductionData, setSelectedProductProductionData] = useState(undefined);
  const [product, setProduct] = useState([])
  const [productOptions, setProductOptions] = useState([])
  const [productSelectOptions, setProductSelectedOptions] = useState([])
  const [productProduction, setProductProduction] = useState([])
  const { id, productionUnitId } = useParams()
  const [dashboardData, setDashboardData] = useContext(dashboardContext)
  const [productionConfiguration, setProductionConfiguration] = useState([])
  const userData = GetLoginInfo()

  const initialValues = {
    productId: "",
    productionConfigurationId: dashboardData.productiondata ? (dashboardData.productiondata.productionConfigurationId ? dashboardData.productiondata.productionConfigurationId : "") : "",
    quantity: 1,
    onDate: new Date(GetCurrentDateForSearch(0)),
    dueDate: new Date(GetCurrentDateForSearch(0)),
    status: 1,
    priority: 1,
    unit: "",
    details: dashboardData.productiondata ? dashboardData.productiondata.details : "",
    allotedForContactId: id,
    forProductionUnitId: productionUnitId,
    forProductId: "",
    isWarehouse: userData.isWarehouse,
    warehouseId: userData.warehouseId,
  }

  const Schema = Yup.object().shape({
    //productId: Yup.string().required("Product is required."),
    quantity: Yup.string().required("Quantity is required."),
    onDate: Yup.string().required("On Date is required."),
    dueDate: Yup.string().required("Due Date is required."),
    status: Yup.string().required("Status is required."),
    priority: Yup.string().required("Priority is required."),
    unit: Yup.string().required("Unit is required.")
  })

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values) => {
      Save(values);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });

  function setQuantity() {
    if (selectedProductData) {
      if (formik.values.quantity < 0) {
        formik.setValues({ ...formik.values, quantity: 0 })
      }
    }
  }
  useEffect(() => {
    getProductConfiguration()
    getProductionUintProducts()
  }, []);

  useEffect(() => {
    if (product && product.length > 0) {
      let items = []
      let selecteditems = []
      product.forEach(element => {
        items = [...items, { name: (element.consumeProduct.productName.toUpperCase() + " (" + element.consumeProduct.id + ")"), id: element.consumeProduct.id }]
        if (element.isPerUnitConsume == 0) {
          selecteditems = [...selecteditems, { name: (element.consumeProduct.productName.toUpperCase() + " (" + element.consumeProduct.id + ")"), id: element.consumeProduct.id }]
        }
      });
      setProductSelectedOptions(selecteditems)
      setProductOptions(items)
    }
  }, [product]);

  useEffect(() => {
    setQuantity()
  }, [formik.values.forStock, formik.values.quantity]);

  // useEffect(() => {
  //   if (selectedProductData) {
  //     formik.setValues({ ...formik.values, productId: selectedProductData.id, unit: selectedProductData.unit, quantity: 0 })
  //   }
  //   else {
  //     formik.setValues({ ...formik.values, productId: "" })
  //   }
  // }, [selectedProductData])

  // const OnProductChange = (e) => {
  //   const { value } = e.target;
  //   if (value) {
  //     product.forEach(element => {
  //       if (element.consumeProduct.id == value) {
  //         {
  //           setSelectedProductData(element.consumeProduct)
  //         }
  //       }
  //     });
  //   }
  //   else {
  //     setSelectedProductData(undefined)
  //   }
  // }

  const Save = (value) => {
    if (value.quantity > 0) {
      if (productSelectOptions && productSelectOptions.length > 0) {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        productSelectOptions.forEach(element => {
          initOrderForProductionCrud.Add({ ...value, productId: element.id, productionConfigurationId: (value.productionConfigurationId == "" ? undefined : value.productionConfigurationId) })
            .then(res => {
              if (res.data.result.succeeded) {
                AppToaster('Added successfully.', 'success')
              }
              else {
                AppToaster("Server Error /Invalid Try.", 'error')
              }
            })
            .catch(error => {
              AppToaster(error, 'error')
            })
        });
        setIsLoadingMini(false)
        setIsLoadingLocal(false);
        history.goBack()
      }
      else {
        AppToaster("No pruduct has been selected.", 'error')
      }
    }
    else {
      AppToaster("Quantity should greater then zero.", 'error')
    }
  }
  function getProductConfiguration() {
    setIsLoadingMini(true);
    productionConfigurationCrud.GetAll()
      .then(res => {
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            setProductionConfiguration(hdata);
          }
          else {
            setProductionConfiguration([]);
            AppToaster("Server Error /Invalid Try.", 'error')
          }
        }
        else {
          setProductionConfiguration([]);
        }
        setIsLoadingMini(false);
      })
      .catch(error => {
        setIsLoadingMini(false);
        AppToaster(error, 'error')
      })
  }
  function getProducts(forproduct) {
    setIsLoadingMini(true);
    productConsumptionCrud.GetByProductId(forproduct)
      .then(res => {
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            setProduct(hdata);
          }
          else {
            setProduct([]);
            AppToaster("Server Error /Invalid Try.", 'error')
          }
        }
        else {
          setProduct([]);
        }
        setIsLoadingMini(false);
      })
      .catch(error => {
        setIsLoadingMini(false);
        AppToaster(error, 'error')
      })
  }

  useEffect(() => {
    if (selectedProductProductionData) {
      formik.setValues({ ...formik.values, forProductId: selectedProductProductionData.id, unit: selectedProductProductionData.unit })
      getProducts(selectedProductProductionData.id)
    }
    else {
      formik.setValues({ ...formik.values, forProductId: "", productId: "" })
    }
  }, [selectedProductProductionData])

  const OnProductionProductChange = (e) => {
    const { value } = e.target;
    if (value) {
      productProduction.forEach(element => {
        if (element.id == value) {
          {
            setSelectedProductProductionData(element)
          }
        }
      });
    }
    else {
      //setSelectedProductData([])
      setProductSelectedOptions([])
      setProduct([])
      //resetProductValues()
      setSelectedProductProductionData(undefined)
    }
  }


  function getProductionUintProducts() {
    setIsLoadingMini(true);
    productCrud.GetByProductionUnitActive(productionUnitId)
      .then(res => {
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            setProductProduction(hdata);
          }
          else {
            setProductProduction([]);
            AppToaster("Server Error /Invalid Try.", 'error')
          }
        }
        else {
          setProductProduction([]);
        }
        setIsLoadingMini(false);
      })
      .catch(error => {
        setIsLoadingMini(false);
        AppToaster(error, 'error')
      })
  }

  function onProductSelect(selectedList, selectedItem) {
    setProductSelectedOptions(selectedList)
  }

  function onProductRemove(selectedList, removedItem) {
    setProductSelectedOptions(selectedList)
  }

  return (
    <Container title="Add Init Order" isBack="true" isContact="true" isProduction="true">
      <form onSubmit={formik.handleSubmit} class="form-horizontal">
        <div className="row">
          <div className="col-md-4">
            <div class="form-group">
              <div class="col-xs-12">
                <label class="control-label no-padding-right">For</label>
                <select className="form-control" name="productId" value={formik.values.forProductId} onChange={OnProductionProductChange}>
                  <option value="" >Select...</option>
                  {
                    productProduction.map((data, index) =>
                      <option key={data.id} value={data.id}>{data.productName.toUpperCase()} ({data.id})</option>
                    )
                  }
                </select>
                <ProductSelectorView data={selectedProductProductionData} dataSetter={setSelectedProductData} isDataSetter={userData.isWarehouse == 1 ? true : false} />
                {
                  formik.touched.productId &&
                    formik.errors.productId ? (
                    <label className="text-danger" >
                      {formik.errors.productId}
                    </label>
                  ) : null
                }
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div class="form-group">
              <div class="col-xs-12">
                <label class="control-label no-padding-right">Product</label>
                <Multiselect
                  options={productOptions}
                  selectedValues={productSelectOptions}
                  onSelect={onProductSelect}
                  onRemove={onProductRemove}
                  displayValue="name"
                  style={{
                    chips: {
                      background: '#75B3D7'
                    }
                  }}
                //showCheckbox
                />
                {/* <select className="form-control" name="givenProductId" value={formik.values.productId} onChange={OnProductChange}>
                  <option value="" >Select...</option>
                  {
                    product.map((data, index) =>
                      <option key={data.consumeProduct.id} value={data.consumeProduct.id}>{data.consumeProduct.productName.toUpperCase()} ({data.consumeProduct.id})</option>
                    )
                  }
                </select>
                <ProductSelectorView data={selectedProductData} dataSetter={setSelectedProductData} isDataSetter={userData.isWarehouse == 1 ? true : false} />
                {
                  formik.touched.givenProductId &&
                    formik.errors.givenProductId ? (
                    <label className="text-danger" >
                      {formik.errors.givenProductId}
                    </label>
                  ) : null
                } */}
              </div>
            </div>
          </div>


          <div className="col-md-4">
            <div class="form-group">
              <div class="col-xs-12">
                <label class="control-label no-padding-right"> Production Configuration</label>
                <select className="form-control" name="productionConfigurationId" value={formik.values.productionConfigurationId} onChange={(e) => formik.setValues({ ...formik.values, productionConfigurationId: e.target.value })}>
                  <option value="" >Select...</option>
                  {
                    productionConfiguration.map((data, index) =>
                      <option key={data.id} value={data.id}>{data.name.toUpperCase()}</option>
                    )
                  }
                </select>
                {
                  formik.touched.productionConfigurationId &&
                    formik.errors.productionConfigurationId ? (
                    <label className="text-danger" >
                      {formik.errors.productionConfigurationId}
                    </label>
                  ) : null
                }
              </div>
            </div>
          </div>

        </div>
        <div className="row">
          <div className="col-md-4">
            <div class="form-group">
              <div class="col-xs-12">
                <label class="control-label no-padding-right"> On Date</label>
                <span class="input-icon" style={{ width: "100%" }}>
                  <DatePicker placeholder="onDate" name="onDate" className="form-control input-date-padding" selected={formik.values.onDate} onChange={(date) => formik.setValues({ ...formik.values, onDate: date })} />
                  <i class="ace-icon fa fa-calendar blue"></i>
                </span>
                {
                  formik.touched.onDate &&
                    formik.errors.onDate ? (
                    <label className="text-danger" >
                      {formik.errors.onDate}
                    </label>
                  ) : null
                }
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div class="form-group">
              <div class="col-xs-12">
                <label class="control-label no-padding-right"> Due Date</label>
                <span class="input-icon" style={{ width: "100%" }}>
                  <DatePicker placeholder="dueDate" name="dueDate" className="form-control input-date-padding" selected={formik.values.dueDate} onChange={(date) => formik.setValues({ ...formik.values, dueDate: date })} />
                  <i class="ace-icon fa fa-calendar blue"></i>
                </span>
                {
                  formik.touched.dueDate &&
                    formik.errors.dueDate ? (
                    <label className="text-danger" >
                      {formik.errors.dueDate}
                    </label>
                  ) : null
                }
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div class="form-group">
              <div class="col-xs-12">
                <label class="control-label no-padding-right">Quantity- {formik.values.unit}</label>
                <input className="form-control" type="number" placeholder="Quantity" name="quantity" {...formik.getFieldProps("quantity")} />
                {
                  formik.touched.quantity &&
                    formik.errors.quantity ? (
                    <label className="text-danger" >
                      {formik.errors.quantity}
                    </label>
                  ) : null
                }
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div class="form-group">
              <div class="col-xs-12">
                <label class="control-label no-padding-right"> Priority</label>
                <select className="form-control" name="priority" value={formik.values.priority} onChange={(e) => formik.setValues({ ...formik.values, priority: e.target.value })}>
                  {
                    GetEInitPriority.EInitPriority.map((data, index) =>
                      <option key={data.Id} value={data.Id}>{data.Name}</option>
                    )
                  }
                </select>
                {
                  formik.touched.priority &&
                    formik.errors.priority ? (
                    <label className="text-danger" >
                      {formik.errors.priority}
                    </label>
                  ) : null
                }
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div class="form-group">
              <div class="col-xs-12">
                <label class="control-label no-padding-right"> Details</label>
                <TextBoxTranslater className="form-control" type="text" placeholder="Details" name="details" value={formik.values.details} onChange={(e) => formik.setValues({ ...formik.values, details: e.target.value })} />
                {
                  formik.touched.details &&
                    formik.errors.details ? (
                    <label className="text-danger" >
                      {formik.errors.details}
                    </label>
                  ) : null
                }
              </div>
            </div>
          </div>
        </div>


        <div class="clearfix form-actions">
          <div class="pull-right">
            {isLoadingLocal ?
              <LoadingButton />
              :
              <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                <i class="ace-icon fa fa-check bigger-110"></i>
                Add
              </button>}
          </div>
        </div>

      </form>
    </Container >
  )
}
