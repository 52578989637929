import React, { useContext, useState } from 'react'
import AppToaster from '../../commonLib/AppToaster';
import *as productWageCrud from '../../_crud/productWageCrud'
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { Container } from '../../base/Container';
import { LoadingButton } from '../../commonLib/LoadingButton';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import DatePicker from "react-datepicker";
import GetCurrentDateForSearch from '../../commonLib/dateFormat';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';


export function AddCategoryWage() {
    const history = useHistory()
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const { id, cn } = useParams()

    const initialValues = {
        wage: {
            productId: 0,
            status: 0,
            wage: "",
            applyFromDate: new Date(GetCurrentDateForSearch(0)),
            note: ""
        },
        categoryId: id
    }


    const Schema = Yup.object().shape({
        // wage: Yup.string().required("Wage is required."),
        // applyFromDate: Yup.string().required("Date is required.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });
    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        productWageCrud.AddNormalByCategoryForAll(value)
            .then(res => {
                setIsLoadingMini(false)
                setIsLoadingLocal(false);
                AppToaster('Added successfully.', 'success')
                history.push('/product/categories')

            }).catch(error => {
                setIsLoadingMini(false)
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    return (
        <>
            <Container title="Add Product Category Wage" isBack="true">
                <form onSubmit={formik.handleSubmit} class="form-horizontal">
                    <div className="row">
                        <div className="col-md-12">
                        <h3>For {cn}</h3>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Date</label>
                                    <span class="input-icon" style={{ width: "100%" }}>
                                        <DatePicker placeholder="applyFromDate" name="applyFromDate" className="form-control input-date-padding" selected={formik.values.wage.applyFromDate} onChange={(e) => formik.setValues({ ...formik.values, wage: { ...formik.values.wage, applyFromDate: e.target.value } })} />
                                        <i class="ace-icon fa fa-calendar blue"></i>
                                    </span>
                                    {
                                        formik.touched.applyFromDate &&
                                            formik.errors.applyFromDate ? (
                                            <label className="text-danger" >
                                                {formik.errors.applyFromDate}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Wage ₹</label>
                                    <input className="form-control" placeholder="wage" type="number" value={formik.values.wage.wage} onChange={(e) => formik.setValues({ ...formik.values, wage: { ...formik.values.wage, wage: e.target.value } })} />
                                    {
                                        formik.touched.wage &&
                                            formik.errors.wage ? (
                                            <label className="text-danger" >
                                                {formik.errors.wage}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-12">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right"> Note</label>
                                    <TextBoxTranslater className="form-control" placeholder="Note" type="text" value={formik.values.wage.note} onChange={(e) => formik.setValues({ ...formik.values, wage: { ...formik.values.wage, note: e.target.value } })} />
                                    {
                                        formik.touched.note &&
                                            formik.errors.note ? (
                                            <label className="text-danger" >
                                                {formik.errors.note}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="clearfix form-actions">
                        <div class="pull-right">
                            {isLoadingLocal ?
                                <LoadingButton />
                                :
                                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Add
                                </button>}
                        </div>
                    </div>
                </form>
            </Container >
        </>
    )
}
