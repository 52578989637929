import React, { useState } from 'react'

export const loadingContext = React.createContext();

const Loading = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    return (
        <loadingContext.Provider value={[isLoading, setIsLoading]}>
            {props.children}
        </loadingContext.Provider>
    );
}

export default Loading;
