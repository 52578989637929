import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import * as Yup from 'yup'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { useFormik } from 'formik';
import *as warehouseCrud from '../../_crud/warehouse/warehouseCrud'
import { LoadingButton } from '../../commonLib/LoadingButton';
import { useHistory, useParams } from 'react-router-dom';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

const initialValues = {
  id: undefined,
  warehouseCode: "",
  name: "",
  city: "",
  address: "",
  details: ""
}

export function EditWarehouse() {
  const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
  const [isLoadingLocal, setIsLoadingLocal] = useState(false)
  const { id } = useParams()
  const history = useHistory()

  const Schema = Yup.object().shape({
    name: Yup.string().min(3, "Name must between 3 to 50 characters").max(50, "Category Name must between 3 to 50 characters").required("Name is required."),
    city: Yup.string().required("Name is required."),
    address: Yup.string().required("Name is required."),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values) => {
      Save(values);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });
  const Save = (value) => {
    setIsLoadingMini(true);
    setIsLoadingLocal(true);
    warehouseCrud.Edit(value)
      .then(res => {
        if (res.data.succeeded) {
          setIsLoadingMini(false);
          setIsLoadingLocal(false);
          AppToaster('Updated successfully.', 'success')
          history.goBack()
        }
        else {
          setIsLoadingMini(false)
          setIsLoadingLocal(false);
          AppToaster("Server Error /Invalid Try.", 'error')
        }
      })
      .catch(error => {
        setIsLoadingMini(false);
        setIsLoadingLocal(false);
        AppToaster(error, 'error')
      })
  }
  useEffect(() => {
    getWarehouse()
  }, []);


  function getWarehouse() {
    setIsLoadingLocal(true);
    warehouseCrud.GetById(id)
      .then(res => {
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            formik.setValues({
              id: hdata.id,
              warehouseCode: hdata.warehouseCode,
              name: hdata.name,
              city: hdata.city,
              address: hdata.address,
              details: hdata.details
            })

          }
          else {
            AppToaster("Server Error /Invalid Try.", 'error')
          }
        }
        else {
        }
        setIsLoadingLocal(false);
      })
      .catch(error => {
        setIsLoadingLocal(false);
        AppToaster(error, 'error')
      })
  }

  return (
    <Container title="Edit Warehouse" isBack="true">
      <form onSubmit={formik.handleSubmit} class="form-horizontal">
        <div className="row">
          <div className="col-md-2">
            <div class="form-group">
              <div class="col-xs-12">
                <label class="control-label no-padding-right">Code</label>
                <input disabled className="form-control" type="text" placeholder="Warehouse Code" name="warehouseCode" {...formik.getFieldProps("warehouseCode")} />
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div class="form-group">
              <div class="col-xs-12">
                <label class="control-label no-padding-right">Name</label>
                <TextBoxTranslater className="form-control" type="text" placeholder="Warehouse Name" name="name" value={formik.values.name} onChange={(e) => formik.setValues({ ...formik.values, name: e.target.value })} />
                {
                  formik.touched.name &&
                    formik.errors.name ? (
                    <label className="text-danger" >
                      {formik.errors.name}
                    </label>
                  ) : null
                }
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div class="form-group">
              <div class="col-xs-12">
                <label class="control-label no-padding-right">City</label>
                <TextBoxTranslater className="form-control" type="text" placeholder="City" name="city" value={formik.values.city} onChange={(e) => formik.setValues({ ...formik.values, city: e.target.value })} />
                {
                  formik.touched.city &&
                    formik.errors.city ? (
                    <label className="text-danger" >
                      {formik.errors.city}
                    </label>
                  ) : null
                }
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div class="form-group">
              <div class="col-xs-12">
                <label class="control-label no-padding-right">Address</label>
                <TextBoxTranslater className="form-control" type="text" placeholder="Address" name="address" value={formik.values.address} onChange={(e) => formik.setValues({ ...formik.values, address: e.target.value })} />
                {
                  formik.touched.address &&
                    formik.errors.address ? (
                    <label className="text-danger" >
                      {formik.errors.address}
                    </label>
                  ) : null
                }
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div class="form-group">
              <div class="col-xs-12">
                <label class="control-label no-padding-right">Details</label>
                <TextBoxTranslater className="form-control" type="text" placeholder="Details" name="details" value={formik.values.details} onChange={(e) => formik.setValues({ ...formik.values, details: e.target.value })} />
                {
                  formik.touched.details &&
                    formik.errors.details ? (
                    <label className="text-danger" >
                      {formik.errors.details}
                    </label>
                  ) : null
                }
              </div>
            </div>
          </div>
        </div>

        <div class="clearfix form-actions">
          <div class="pull-right">
            {isLoadingLocal ?
              <LoadingButton />
              :
              <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                <i class="ace-icon fa fa-check bigger-110"></i>
                Update
              </button>}
          </div>
        </div>

      </form>
    </Container >
  )
}
