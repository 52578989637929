import React, { useEffect, useState } from 'react'
import Moment from 'react-moment';
import AppToaster from '../../commonLib/AppToaster';
import { EmptyData } from '../../commonLib/EmptyData';
import { PagingLight } from '../../commonLib/PagingLight';
import { Spinner } from '../../commonLib/Spinner';
import { DEFAULT_USER_IMG, ERROR_IMG } from '../../config/GlobalSettings';
import { GetEHistoryActionName } from '../../enumCollections/EHistoryAction';
import { BASE_URL } from '../../initApi/apiUrls';
import * as historyCrud from '../../_crud/historyCrud'
import ScrollArea from 'react-scrollbar'
import { GetEHistoryTypeName } from '../../enumCollections/EHistoryType';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

export function TimelineHistory({ id, type, text, pageSize = 10, height = 350 }) {
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [historyData, setHistoryData] = useState([])
    const [LastPage, setLastPage] = useState(1)
    const [pagingData, setPagingData] = useState()

    const initHistory = {
        PageNo: 1,
        PageSize: pageSize,
        HistoryType: type,
        RefId: id,
    }
    const initialValues = {
        historyText: "",
        historyType: type,
        refId: id,
        action: 2,
    }
    const Schema = Yup.object().shape({
        historyText: Yup.string().required("Note is required.")
        //.matches(/^[aA-zZ\s\d]+$/, " Only alphabets, digits, space and underscore are allowed for this field "),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    const Save = (value) => {
        setIsLoadingLocal(true)
        historyCrud.Add({ ...value, historyText: ("<b>" + GetEHistoryTypeName(type) + " Custom Note:</b> <i class='ace-icon fa fa-thumb-tack orange bigger-100'></i> " + value.historyText) })
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingLocal(false);
                    AppToaster('Custom Note Added successfully.', 'success')
                    formik.setValues(initialValues)
                    doRefresh()
                }
                else {
                    setIsLoadingLocal(false);
                    AppToaster('Network error.', 'error')
                }
            }).catch(error => {
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    const [historySearch, setHistorySearch] = useState(initHistory)

    useEffect(() => {
        GetTimeline()
    }, [historySearch.PageNo]);

    function GetTimeline() {
        setIsLoadingLocal(true)
        historyCrud.GetHistoryByTypeAndRefId(historySearch)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setHistoryData(hdata)
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                    }
                    else {
                        AppToaster("Network Error.", 'error')
                    }
                }
                else {
                }
                setIsLoadingLocal(false);
            })
            .catch(error => {
                alert(error)
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    const OnPageChange = (e) => {
        const { value } = e.target
        setHistorySearch({ ...historySearch, PageNo: value });
    }

    const GetActionColor = (action) => {
        switch (action) {
            case 1: return "label label-success label-sm"
            case 2: return "label label-info label-sm"
            case 3: return "label label-danger label-sm"
        }
    }
    const doRefresh = () => {
        GetTimeline()
    }
    return (
        <div className="widget-box">
            <div class="widget-header widget-header-flat widget-header-small">
                <h5 class="widget-title">
                    {text}
                </h5>
                <div class="widget-toolbar">
                    <a onClick={doRefresh} title="Reload">
                        <i class="ace-icon fa fa-refresh"></i>
                    </a>
                </div>
            </div>
            <PagingLight
                pagingData={pagingData}
                LastPage={LastPage}
                CurrentPage={historySearch.PageNo}
                OnPageChange={OnPageChange} >

                {isLoadingLocal ?
                    <Spinner />
                    :
                    <ScrollArea
                        speed={0.8}
                        style={{ height: height }}
                        smoothScrolling={true}
                        horizontal={false}
                    >

                        <div class="timeline-container">
                            <div class="timeline-items">
                                <div class="timeline-item clearfix">
                                    <div class="timeline-info">
                                    </div>

                                    <div class="widget-box transparent">
                                        <div class="widget-body">
                                            <div class="widget-main" style={{ marginTop: "8px", paddingRight: "5px", paddingBottom: "0", paddingTop: "0" }}>
                                                <form onSubmit={formik.handleSubmit} class="form-horizontal">

                                                    <div class="form-group">
                                                        <TextBoxTranslater className="form-control" type="text" placeholder="Type note and press enter" name="historyText" value={formik.values.historyText} onChange={(e) => formik.setValues({ ...formik.values, historyText: e.target.value })} />

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {historyData && historyData.length > 0 ?
                                historyData.map((item, index) =>
                                    <div class="timeline-items">
                                        <div class="timeline-item clearfix">
                                            <div class="timeline-info">
                                                <img width="40px" height="40px" style={{ objectFit: "cover" }} src={item.createdByUser.isDP == 1 ? (BASE_URL + "/" + item.createdByUser.dpUrl) : DEFAULT_USER_IMG}
                                                    onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
                                                {/* <img alt="Susan't Avatar" src="assets/images/avatars/avatar1.png" /> */}
                                                <span class={GetActionColor(item.action)}>{GetEHistoryActionName(item.action)}</span>
                                            </div>

                                            <div class="widget-box transparent">
                                                <div class="widget-body">
                                                    <div class="widget-main">
                                                        <div class="clearfix">
                                                            <div class="pull-left" dangerouslySetInnerHTML={{ __html: item.historyText }} />
                                                            <div class="pull-right">
                                                                <i class="ace-icon fa fa-clock-o bigger-110"></i> <b><Moment format="DD-MMM-YYYY hh:mm A">{item.createdOn}</Moment></b>
                                                                <br />
                                                                {item.createdByUser.fullName} ({item.createdByUser.userName})
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                :
                                <EmptyData>
                                    No history found.
                                    <br />
                                    <br />
                                </EmptyData>
                            }
                        </div>

                    </ScrollArea>
                }
            </PagingLight>
        </div>
    )
}
