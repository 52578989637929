import React, { useContext, useState } from 'react'
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { GetEBoolName } from '../../../enumCollections/EBool';
import { ContactView } from '../../Global/Elements/ContactView';
import { ProcessingView } from '../../Global/Elements/ProcessingView';
import { ProductionUnitView } from '../../Global/Elements/ProductionUnitView';
import { ProductView } from '../../Global/Elements/ProductView';
import { UserView } from '../../Global/Elements/UserView';


export function ProcessingReceivingItemView({ processingReceiving }) {

    return (
        <>
            <div className="widget-box">
                <div class="widget-header widget-header-flat widget-header-small">
                    <h5 class="widget-title">
                        Details
                    </h5>
                </div>
                {
                    processingReceiving &&
                    <div className="row">
                        <div class="profile-user-info profile-user-info-striped">
                            <div class="profile-info-row">
                                <div class="profile-info-name"> Receiving Id</div>

                                <div class="profile-info-value">
                                    <span ><b>{processingReceiving.id}</b></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> On Date</div>

                                <div class="profile-info-value">
                                    <span ><b><Moment format="DD-MMM-YYYY">{processingReceiving.onDate}</Moment></b></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Contact </div>

                                <div class="profile-info-value">
                                    <Link to={"/contact/dashboard/" + processingReceiving.contactId} >
                                        <ContactView item={processingReceiving.contact} uid={"GC1"} />
                                    </Link>
                                </div>
                            </div>


                            <div class="profile-info-row">
                                <div class="profile-info-name"> Processing </div>

                                <div class="profile-info-value">
                                    <Link to={"/processing/dashboard/" + processingReceiving.processingId} >
                                        <ProcessingView item={processingReceiving.processing} />
                                    </Link>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Production </div>

                                <div class="profile-info-value">
                                    {processingReceiving.isProductionUnit == 1 ? (processingReceiving.productionUnit ? <Link to={"/productionunit/dashboard/" + processingReceiving.productionUnitId} >
                                        <ProductionUnitView item={processingReceiving.productionUnit} />
                                    </Link> : "NA") : <span class="label label-sm label-danger">{GetEBoolName(processingReceiving.isProductionUnit)}</span>}
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Product </div>

                                <div class="profile-info-value">
                                    <Link to={"/product/dashboard/" + processingReceiving.product.id} >
                                        <ProductView item={processingReceiving.product} />
                                    </Link>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Wage/Unit </div>

                                <div class="profile-info-value">
                                    <span >₹{Number(processingReceiving.processingWage.wage).toFixed(2)}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Fresh </div>

                                <div class="profile-info-value">
                                    <span >{processingReceiving.freshQuantity} {processingReceiving.unit}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Defective </div>

                                <div class="profile-info-value">
                                    <span >{processingReceiving.defectiveQuantity} {processingReceiving.unit}</span>
                                </div>
                            </div>


                            <div class="profile-info-row">
                                <div class="profile-info-name"> Rejected </div>

                                <div class="profile-info-value">
                                    <span >{processingReceiving.rejectedQuantity} {processingReceiving.unit}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Vouchered </div>

                                <div class="profile-info-value">
                                    <span> {processingReceiving.isVouchered ? <Link to={"/processingvoucher/dashboard/" + processingReceiving.voucherId} >{processingReceiving.voucherId}</Link> : <span class="label label-sm label-danger">{GetEBoolName(processingReceiving.isVouchered)}</span>}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Note </div>

                                <div class="profile-info-value">
                                    <span >{processingReceiving.note}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Created By </div>
                                <div class="profile-info-value">
                                    <span><UserView item={processingReceiving.createdByUser} uid={"GD1"} createdOn={processingReceiving.createdOn} /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

