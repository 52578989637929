import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

export function MyFab() {
    const [Checked, setChecked] = useState(false)

    return (
        <div class="fab-wrapper">
            <input id="fabCheckbox" checked={Checked} type="checkbox" class="fab-checkbox" />
            <label class="fab" onClick={() => { Checked ? setChecked(false) : setChecked(true) }} style={{ cursor: "pointer", fontWeight: "unset", fontSize: "unset", display: "unset", maxWidth: "unset", marginBottom: "unset" }} for="fabCheckbox">
                <span class="fab-dots fab-dots-1"></span>
                <span class="fab-dots fab-dots-2"></span>
                <span class="fab-dots fab-dots-3"></span>
                {/* <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i> */}
            </label>
            <div class={Checked ? "fab-wheel" : "fab-Condition"}  >
                <Link onClick={() => { setChecked(false) }} to="/product/calculator" class="fab-action fab-action-1" title='Price Calculator' style={{ background: "#d6487e", cursor: "pointer" }}>
                    <i class="fa fa-calculator bigger-140"></i>
                </Link>
                <Link onClick={() => { setChecked(false) }} to="/contact/add" title="Add Contact" class="fab-action fab-action-2" style={{ background: "#6fb3e0", cursor: "pointer" }}>
                    <i class="fa fa-group bigger-140"></i>
                </Link>
                <Link onClick={() => { setChecked(false) }} to="/product/add" title="Add Product" class="fab-action fab-action-3" style={{ background: "#ffb752", cursor: "pointer" }}>
                    <i class="fa fa-cubes bigger-140"></i>
                </Link>
                <a onClick={() => { setChecked(false) }} class="fab-action fab-action-4" title='Video Tutorials' style={{ background: "#87b87f", cursor: "pointer" }}
                    href="https://www.youtube.com/playlist?list=PLFqp5yCewzQrI0KgXGt0nLpy46Ongz78H" target="_blank">
                    <i class="fa fa-youtube bigger-140"></i>
                </a>
            </div>
        </div >
    )
}
