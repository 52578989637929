import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { Spinner } from "../../../commonLib/Spinner";

// export const data = [
//     [
//         "Date",
//         "Cash Flow ₹",
//         "Bank Flow ₹",
//     ],
//     ["1/12/2022", 37.8, 80.8],
//     [2, 30.9, 69.5],
//     [3, 25.4, 57],
//     [4, 11.7, 18.8],
//     [5, 11.9, 17.6],
//     [6, 8.8, 13.6],
//     [7, 7.6, 12.3],
//     [8, 12.3, 29.2],
//     [9, 16.9, 42.9],
//     [10, 12.8, 30.9],
//     [11, 5.3, 7.9],
//     [12, 6.6, 8.4],
//     [13, 4.8, 6.3],
//     [14, 4.2, 6.2],
// ];

export function BaithkaLineChart({ cash = [], bank = [], loading = false, height = "300px", month, year }) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [lables, setLables] = useState([])
    const options = {
        colors: ['#62c58b', '#438EB9'],
        animation: {
            startup: true,
            easing: 'linear'
        },
        legend: {
            position: 'none',
            //alignment: 'center',
            //orientation: 'vertical'
        },
        responsive: true,
    };
    useEffect(() => {
        let lblAll = [[
            "",
            "Cash Flow ₹",
            "Bank Flow ₹",
        ]];
        if ((cash && cash.length > 0) && (bank && bank.length > 0) && (cash.length == bank.length)) {
            cash.forEach((element, index) => {
                let d = new Date(element.date)
                let elementBank = bank[index];
                lblAll = [...lblAll, [String(d.getDate()).padStart(2, '0') + '/' + String(d.getMonth() + 1).padStart(2, '0') + '/' + String(d.getFullYear()).padStart(2, '0'), element.cr, elementBank.cr]]
            });
            //alert(JSON.stringify(lblAll))
            setLables(lblAll)
        }
    }, [cash, bank])

    return (
        <>
            {loading ?
                <Spinner />
                :
                <div style={{ textAlign: "center", width: "100%" }}>
                    <span><b>{'For ' + months[month - 1] + ", " + year}</b></span><br />
                    <i className="fa fa-square" style={{ color: "#62c58b" }}></i> Cash
                    <i className="fa fa-square" style={{ color: "#438EB9", marginLeft: "8px" }}></i> Bank
                    <Chart
                        chartType="Line"
                        width="100%"
                        height={height}
                        data={lables}
                        options={options}
                    />
                </div>
            }
        </>
    )
}