import React from 'react'
import Moment from 'react-moment'
import ReactTooltip from 'react-tooltip'
import { ImgLazyload } from '../../../commonLib/ImgLazyload'
import { DEFAULT_USER_IMG, ERROR_IMG } from '../../../config/GlobalSettings'
import { GetEStatusName } from '../../../enumCollections/EStatus'
import { BASE_URL } from '../../../initApi/apiUrls'

export function UserView({ item, uid, createdOn }) {
    return (
        item &&
        <>
            <a data-tip data-for={uid}>
                <ImgLazyload height="35px" width="35px" className="img-round pull-left" src={item.isDP == 1 ? BASE_URL + "/" + item.dpUrl : DEFAULT_USER_IMG}
                    onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
            </a>
            <ReactTooltip delayShow={500} arrowColor="gray" borderColor="#9b9b9b" className="opaque" id={uid} type="light">
                <div style={{ width: "100%", textAlign: "center" }}>
                    <ImgLazyload height="200px" width="100%" className="img-round-tooltip" src={item.isDP == 1 ? BASE_URL + "/" + item.dpUrl : DEFAULT_USER_IMG}
                        onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
                </div>
                <div style={{ padding: "10px" }}>
                    <b>Created Date: <Moment format="DD-MMM-YYYY HH:mm">{createdOn}</Moment></b>
                    <br />Name: {item.fullName} ({item.userName})
                    <br />Phone: {item.phoneNumber}
                    <br />Email: {item.email}
                    <br />Status <span class={item.status == 1 ? "label label-sm label-success" : "label label-sm label-danger"}>{GetEStatusName(item.status)}</span>
                </div>
            </ReactTooltip>
        </>
    )
}
