import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Container } from '../../base/Container'
import AppToaster from '../../commonLib/AppToaster'
import { Spinner } from '../../commonLib/Spinner'
import *as sareeDrapingCrud from '../../_crud/sareeDrapingCrud'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext'
import Moment from 'react-moment';
import 'moment-timezone';
import { EmptyData } from '../../commonLib/EmptyData'
import { UserView } from '../Global/Elements/UserView'
import { DrapingViewRow } from '../Global/Elements/DrapingViewRow'
import { MyAlert } from '../../commonLib/MyAlert'
import { PagingEmpty } from '../../commonLib/PagingEmpty'
import { organizationContext } from '../Organization/organizationContext'
import { DrapingListPrint } from '../Prints/DrapingListPrint'
import { PrintStarterDash } from '../Prints/PrintStaterDash'
import { getIsPrint } from '../../config/GlobalSettings'

export function CatalogueDashboard() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [organization, setOrganization] = useContext(organizationContext);
    const [drapingCatalogue, setDrapingCatalogue] = useState()
    const [drapingCatalogueItems, setDrapingCatalogueItems] = useState([])
    const { id } = useParams()

    useEffect(() => {
        getData()
    }, [])

    function getData() {
        setIsLoadingMini(true);
        sareeDrapingCrud.GetCatalogueById(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let idata = res.data.catalogueItems
                        setDrapingCatalogue(hdata);
                        setDrapingCatalogueItems(idata)
                        setIsLoadingMini(false)
                    }
                    else {
                        setDrapingCatalogue();
                        setIsLoadingMini(false)
                        setDrapingCatalogue([]);
                    }
                }
                else {
                    setDrapingCatalogue();
                    setIsLoadingMini(false)
                    setDrapingCatalogue([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        sareeDrapingCrud.Remove(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    AppToaster('Deleted successfully.', 'success')
                    getData()
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by some catalogue, could not delete.', 'error')
                }

            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }


    function doRefresh(event) {
        getData()
    }

    const printRef = useRef();
    return (
        <>
            <Container title="Drapings Catalogue Details" isBack={true} doRefresh={doRefresh}>
                <>
                    {getIsPrint && <div style={{ display: "none" }}>
                        {drapingCatalogue &&
                            <DrapingListPrint catalogue={drapingCatalogue} catalogueItems={drapingCatalogueItems} organization={organization} ref={printRef} />
                        }
                    </div>}

                    <div class="btn-group">
                        <Link to={"/catalogue/edit/" + id} class="btn btn-xs btn-success bigger-120" style={{ marginRight: "5px" }}>
                            <i class="ace-icon fa fa-pencil bigger-110"></i>
                            Edit
                        </Link>

                        {getIsPrint && <PrintStarterDash printRef={printRef} />}
                    </div>

                    <div class="hr dotted"></div>

                    {drapingCatalogue &&
                        <div class="form-horizontal" >
                            <div className="row">
                                <div className="col-md-11">
                                    <h1>{drapingCatalogue.catalogueName} <small>({drapingCatalogue.catalogueCode})</small></h1>
                                    <h4 className='lighter'>{drapingCatalogue.note}</h4>
                                    <br />
                                </div>
                            </div>
                        </div>
                    }

                    <PagingEmpty text={"Total " + drapingCatalogueItems.length + " items."}>
                        {isLoadingMini ?
                            <Spinner />
                            :
                            drapingCatalogueItems && drapingCatalogueItems.length > 0 ?
                                <div className="tables-scroll">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "43px" }}></th>
                                                <th>#Id</th>
                                                <th></th>
                                                <th>Drapings</th>
                                                <th>Code</th>
                                                <th>Name</th>
                                                <th>Note</th>
                                                <th>On Date</th>
                                                <th style={{ width: "43px" }}>By</th>
                                                <th style={{ width: "43px" }}>By</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {drapingCatalogueItems.map((item, index) =>
                                                <tr key={item.id}>
                                                    <td>
                                                        <div class="btn-group">
                                                            <Link className="btn btn-xs btn-info" to={"/draping/dashboard/" + item.sareeDraping.id} title="View dashboard" ><i class="ace-icon fa fa-tachometer"></i></Link>
                                                        </div>
                                                    </td>
                                                    <td>{index + 1}</td>
                                                    <td>{item.id}</td>

                                                    <td>
                                                        <div style={{ width: "88px" }}>
                                                            <DrapingViewRow url={item.sareeDraping.drapingFrontUrl} uid={"IMG1" + item.sareeDraping.id} />
                                                            <DrapingViewRow url={item.sareeDraping.drapingBackUrl} uid={"IMG2" + item.sareeDraping.id} />
                                                        </div>
                                                    </td>

                                                    <td>{item.sareeDraping.itemCode}</td>
                                                    <td>
                                                        <div style={{ width: "200px" }}>{item.sareeDraping.itemName}
                                                        </div>
                                                    </td>
                                                    <td><div style={{ width: "150px" }}>{item.sareeDraping.note}</div></td>
                                                    <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.createdOn}</Moment></div></td>
                                                    <td>
                                                        <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                    </td>
                                                    <td>
                                                        <div style={{ width: "73px" }}>
                                                            <div class="btn-group">

                                                                <Link to={"/draping/edit/" + item.sareeDraping.id} class="btn btn-xs btn-success">
                                                                    <i class="ace-icon fa fa-pencil bigger-120"></i>
                                                                </Link>

                                                                <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                                    <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                                </MyAlert>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <EmptyData>
                                    No records found.
                                    <br />
                                    <br />
                                </EmptyData>
                        }
                    </PagingEmpty>
                </>
            </Container >
        </>
    )
}
