const GetERechargeStatusName = (id) => {
    switch (id) {
        case 1: { return "Failed" }
        case 2: { return "Success" }
        case 0: { return "Pending" }
    }
}

const GetERechargeStatus = {
    ERechargeStatus: [
        { Id: 1, Name: "Failed" },
        { Id: 2, Name: "Success" },
        { Id: 0, Name: "Pending" }
    ]
}

export { GetERechargeStatusName, GetERechargeStatus }