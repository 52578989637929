import React, { useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useEffect } from 'react';
ChartJS.register(ArcElement, Tooltip, Legend);

export function ContactCategoryReportMobile({ contactData }) {
    const [labels, setLabels] = useState([])
    const [data, setData] = useState([])

    useEffect(() => {
        if (contactData && contactData.length > 0) {
            let lbl = []
            let dt = []
            contactData.forEach(element => {
                let cat = element.productionCategory ? (element.productionCategory.productionCatName) : "N/A - "
                lbl = [...lbl, cat]
                dt = [...dt, element.count]
            });
            setLabels([...lbl])
            setData([...dt])
        }
    }, [contactData])

    const chartdata = {
        labels: labels,
        datasets: [
            {
                label: '# of Contacts',
                data: data,
                backgroundColor: [
                    '#e74c3c',
                    '#5bc0de',
                    '#f0ad4e',
                    '#07bc0c',
                    '#3498db',
                    '#d54c7e',
                    '#A069C3',
                ],
                borderColor: [
                    'gray',
                ],
                borderWidth: 1,
            },
        ],
    };
    return (
        <Pie data={chartdata} />
    )
}