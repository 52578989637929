import React, { useContext, useEffect, useState } from 'react'
import { EmptyData } from '../../../commonLib/EmptyData';
import { MyAlert } from '../../../commonLib/MyAlert';
import { PagingEmpty } from '../../../commonLib/PagingEmpty'
import { GetEGatePassProductsType, GetEGatePassProductsTypeName } from '../../../enumCollections/EGatePassProductsType';
import { ProductView } from '../../Global/Elements/ProductView';
import * as warehouseTransferCrud from '../../../_crud/warehouse/warehouseTransferCrud'
import AppToaster from '../../../commonLib/AppToaster';
import { ContactView } from '../../Global/Elements/ContactView';
import Moment from 'react-moment';
import { UserView } from '../../Global/Elements/UserView';
import ProductSelector from '../../Global/ProductSelector';
import { ProductSelectorView } from '../../Global/SelectorView/ProductSelectorView';
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import { Link, useParams } from 'react-router-dom';
import { WarehouseView } from '../../Global/Elements/WarehouseView';
import GetLoginInfo from '../../../auth/_userContext/GetLoginInfo';

export function WarehouseTransferItemView({ warehouseTransfer, warehouseTransferProduct, warehouseTransferProductCount, doRefresh, isPrint = false }) {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [selectedProductData, setSelectedProductData] = useState(undefined);
    const [empty, setEmpty] = useState(false);
    const { id } = useParams()
    const [itemButton, setItemButton] = useState(false);
    const userData = GetLoginInfo()

    const initItems = {
        warehouseTransferId: id,
        productId: "",
        productData: undefined,
        rawStock: 0,
        mainStock: 0,
        defectiveStock: 0,
        quantity: 0,
        type: "0",
        unit: ""
    }
    const [warehouseItem, setWarehouseItem] = useState(initItems);

    useEffect(() => {
        setEmpty(false)
    }, [empty]);

    useEffect(() => {
        if (warehouseItem.productId && warehouseItem.quantity > 0)
            setItemButton(true)
        else
            setItemButton(false)

    }, [warehouseItem]);

    useEffect(() => {
        if (selectedProductData) {
            setWarehouseItem({ ...warehouseItem, productId: selectedProductData.id, unit: selectedProductData.unit, quantity: 0, productData: selectedProductData })
        }
    }, [selectedProductData]);

    const AddItem = () => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        warehouseTransferCrud.AddWarehouseTransferProduct(warehouseItem)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster('Added successfully.', 'success')
                    setEmpty(true)
                    doRefresh()
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        warehouseTransferCrud.RemoveWarehouseTransferProduct(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    doRefresh()
                    AppToaster('Deleted successfully.', 'success')
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by someone,could not delete.', 'error')
                }
            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }

    function setQuantity() {
        if (selectedProductData) {
            switch (warehouseItem.type) {
                case "0":
                    {
                        if (warehouseItem.quantity > selectedProductData.mainStock) {
                            setWarehouseItem({ ...warehouseItem, quantity: selectedProductData.mainStock })
                        }
                        else if (warehouseItem.quantity < 0) {
                            setWarehouseItem({ ...warehouseItem, quantity: 0 })
                        }
                        else {
                            setWarehouseItem({ ...warehouseItem, mainStock: warehouseItem.quantity, rawStock: 0, defectiveStock: 0 })
                        }
                        break;
                    }
                case "1":
                    {
                        if (warehouseItem.quantity > selectedProductData.defectiveStock) {
                            setWarehouseItem({ ...warehouseItem, quantity: selectedProductData.defectiveStock })
                        }
                        else if (warehouseItem.quantity < 0) {
                            setWarehouseItem({ ...warehouseItem, quantity: 0 })
                        }
                        else {
                            setWarehouseItem({ ...warehouseItem, mainStock: 0, rawStock: 0, defectiveStock: warehouseItem.quantity })
                        }
                        break;
                    }
                case "2":
                    {
                        setWarehouseItem({ ...warehouseItem, quantity: 0, mainStock: 0, rawStock: 0, defectiveStock: 0 })
                        break;
                    }
                case "3":
                    {
                        if (warehouseItem.quantity > selectedProductData.rawStock) {
                            setWarehouseItem({ ...warehouseItem, quantity: selectedProductData.rawStock })
                        }
                        else if (warehouseItem.quantity < 0) {
                            setWarehouseItem({ ...warehouseItem, quantity: 0 })
                        }
                        else {
                            setWarehouseItem({ ...warehouseItem, mainStock: 0, rawStock: warehouseItem.quantity, defectiveStock: 0 })
                        }
                        break;
                    }
            }
        }

    }

    useEffect(() => {
        setQuantity()
    }, [warehouseItem.type, warehouseItem.quantity]);
    return (
        <>
            <div className="widget-box">
                <div class="widget-header widget-header-flat widget-header-small">
                    <h5 class="widget-title">
                        Details
                    </h5>
                </div>
                {
                    warehouseTransfer &&
                    <div className="row">
                        <div class="profile-user-info profile-user-info-striped">
                            <div class="profile-info-row">
                                <div class="profile-info-name"> Transfer No.</div>

                                <div class="profile-info-value">
                                    <span ><b>{warehouseTransfer.id}</b></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> On Date</div>

                                <div class="profile-info-value">
                                    <span ><b><Moment format="DD-MMM-YYYY">{warehouseTransfer.onDate}</Moment></b></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Contact </div>

                                <div class="profile-info-value">
                                    <Link to={"/contact/dashboard/" + warehouseTransfer.contactId}>
                                        <ContactView item={warehouseTransfer.contact} uid={"GC1"} />
                                    </Link>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name"> Total </div>

                                <div class="profile-info-value">
                                    <span><b>{warehouseTransferProductCount.count}</b> Items with <b>{warehouseTransferProductCount.totalQuantity}</b> quantity.</span>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name"> From Branch </div>

                                <div class="profile-info-value">
                                    <WarehouseView item={warehouseTransfer.warehouseFrom} uid={"WHD1"} />
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name"> To Branch </div>

                                <div class="profile-info-value">
                                    <WarehouseView item={warehouseTransfer.warehouseTo} uid={"WHD2"} />
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name"> Note </div>

                                <div class="profile-info-value">
                                    <span>{warehouseTransfer.note ? warehouseTransfer.note : "NA"}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Created By </div>

                                <div class="profile-info-value">
                                    <span><UserView item={warehouseTransfer.createdByUser} uid={"GD1"} createdOn={warehouseTransfer.createdOn} /></span>
                                </div>
                            </div>


                        </div>
                    </div>
                }
            </div>

            <br />
            <div className="widget-box">
                <div class="widget-header widget-header-flat widget-header-small">
                    <h5 class="widget-title">
                        Transfered Items
                    </h5>
                </div>
                {!isPrint &&
                    <>
                        <div className="row" >
                            <div class="col-xs-12">

                                <div className="col-md-5">
                                    <div class="form-group">
                                        <label class="control-label no-padding-right">Product</label>
                                        <ProductSelector setEmpty={empty} searchtext="" setSelectedData={setSelectedProductData} isWarehouse={userData.isWarehouse == 0 ? false : true} />
                                    </div>
                                    <ProductSelectorView data={selectedProductData} />
                                </div>

                                <div className="col-md-2">
                                    <div class="form-group">
                                        <label class="control-label no-padding-right"> Quantity {warehouseItem.unit && "- " + warehouseItem.unit}</label>
                                        <input className="form-control" type="number" placeholder="Quantity" value={warehouseItem.quantity} onChange={(e) => setWarehouseItem({ ...warehouseItem, quantity: e.target.value })} />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div class="form-group">
                                        <label class="ontrol-label no-padding-right">Type</label>
                                        <select className="form-control" value={warehouseItem.type} onChange={(e) => setWarehouseItem({ ...warehouseItem, type: e.target.value })}>
                                            {
                                                GetEGatePassProductsType.EGatePassProductsType.map((data, index) =>
                                                    <option key={data.Id} value={data.Id}>{data.Name}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div class="form-group">
                                        <label class="control-label no-padding-right">&nbsp;</label>
                                        <div class="col-xs-12">
                                            {isLoadingLocal ?
                                                <button class="btn btn-xs btn-success" type="submit" expand="block" disabled>
                                                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i> Wait...
                                                </button>
                                                :
                                                <button class="btn btn-xs btn-success" type="submit" expand="block" onClick={AddItem} disabled={!itemButton}>
                                                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i> Add
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                    </>
                }

                <div className="row">
                    <div className="col-xs-12">
                        <PagingEmpty text={"Total " + warehouseTransferProduct.length + " items."}>
                            {warehouseTransferProduct && warehouseTransferProduct.length > 0 ?
                                <div className="tables-scroll">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Product</th>
                                                <th>Main</th>
                                                <th>Raw</th>
                                                <th>Defective</th>
                                                {!isPrint && <th style={{ width: "43px" }}>Action</th>}
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {warehouseTransferProduct.map((item, index) =>
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <Link to={"/product/dashboard/" + item.productId}>
                                                            <ProductView item={item.product} uid={"PP" + index} />
                                                        </Link>
                                                    </td>
                                                    <td className={item.mainStock != 0 && "bolder"}>{item.mainStock != 0 ? <>{item.mainStock} {item.product.unit}</> : "-"}</td>
                                                    <td className={item.rawStock != 0 && "bolder"}>{item.rawStock != 0 ? <>{item.rawStock} {item.product.unit}</> : "-"}</td>
                                                    <td className={item.defectiveStock != 0 && "bolder"}>{item.defectiveStock != 0 ? <>{item.defectiveStock} {item.product.unit}</> : "-"}</td>
                                                    {!isPrint &&
                                                        <td>
                                                            <div class="btn-group">
                                                                <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                                    <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                                </MyAlert>
                                                            </div>
                                                        </td>
                                                    }
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>

                                :
                                <EmptyData>
                                    No Item Added.
                                    <br />
                                    <br />
                                </EmptyData>
                            }
                        </PagingEmpty>
                    </div>
                </div>
            </div>
        </>
    )
}
