import GetLoginInfo from "../../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../../initApi/createAxios";
import { OrgId } from "../../config/GlobalSettings";

export async function VoucherAdd(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/SalaryVoucher/VoucherAdd", param)
    }
}
export async function VoucherRemove(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/SalaryVoucher/VoucherRemove?Id=" + id)
    }
}
export async function GetVoucherById(voucherId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Vouchers/SalaryVoucher/GetVoucherById?VoucherId=" + voucherId)
    }
}
export async function AddVoucherPayment(VoucherPayments) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/SalaryVoucher/AddVoucherPayment", VoucherPayments)
    }
}
export async function RemoveVoucherPayment(paymentId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/SalaryVoucher/RemoveVoucherPayment?PaymentId=" + paymentId)
    }
}
export async function AddAdvancePayment(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/SalaryVoucher/AddAdvancePayment?ContactId=" + param.contactId + "&VoucherId=" + param.voucherId + "&Amount=" + param.amount)
    }
}