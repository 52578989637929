import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Container } from '../../../base/Container'
import AppToaster from '../../../commonLib/AppToaster'
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext'
import { Spinner } from '../../../commonLib/Spinner'
import * as productProcessingReceivingCrud from '../../../_crud/productProcessingReceivingCrud'
import { GetEBoolName } from '../../../enumCollections/EBool'
import { GetEStockTypeName } from '../../../enumCollections/EStockType'
import { PAGING_PAGE_SIZE, getIsPrint } from '../../../config/GlobalSettings'
import { Paging } from '../../../commonLib/Paging'
import Moment from 'react-moment';
import 'moment-timezone';
import { EmptyData } from '../../../commonLib/EmptyData'
import DatePicker from "react-datepicker";
import GetCurrentDateForSearch from '../../../commonLib/dateFormat'
import { MyAlert } from '../../../commonLib/MyAlert'
import { UserView } from '../../Global/Elements/UserView'
import { ContactView } from '../../Global/Elements/ContactView'
import { ProductView } from '../../Global/Elements/ProductView'
import { ProductionUnitView } from '../../Global/Elements/ProductionUnitView'
import { ProcessingView } from '../../Global/Elements/ProcessingView'
import { ProcessingReceivingPrint } from '../../Prints/ProcessingReceivingPrint'
import { PrintStarter } from '../../Prints/PrintStarter'
import { WarehouseView } from '../../Global/Elements/WarehouseView'
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater'

const initValues = {
    Search: {
        SearchTerm: "",
        PageNo: 1,
        PageSize: PAGING_PAGE_SIZE
    },
    Range: {
        fromDate: new Date(GetCurrentDateForSearch(-30)),
        toDate: new Date(GetCurrentDateForSearch(0))
    }
}

export function ManageProcessingReceiving() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [search, setSearch] = useState(initValues)
    const [processingReceiving, setProcessingReceiving] = useState([])
    const [LastPage, setLastPage] = useState(1)
    const [pagingData, setPagingData] = useState()

    useEffect(() => {
        getData()
    }, [search.Search.PageNo])

    const OnPageChange = (e) => {
        const { value } = e.target
        setSearch({ ...search, Search: { ...search.Search, PageNo: value } });
    }

    function onSearch() {
        getData()
    }

    function getData() {
        setIsLoadingMini(true);
        productProcessingReceivingCrud.GetAll(search)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setProcessingReceiving(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoadingMini(false)
                        setProcessingReceiving([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoadingMini(false)
                    setProcessingReceiving([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        setSearch({ ...search, Search: { ...search.Search, PageNo: 1 } })
        getData()
        setTimeout(() => {
            event.detail.complete();
        }, 1000);
    }

    function SearchChange(e) {
        const value = e.target.value
        setSearch({ ...search, Search: { ...search.Search, SearchTerm: value } })
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        productProcessingReceivingCrud.Remove(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    AppToaster('Deleted successfully.', 'success')
                    getData()
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by someone,could not delete.', 'error')
                }

            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }
    const [printData, setPrintData] = useState()
    const printRef = useRef();

    return (
        <>
            {getIsPrint && <div style={{ display: "none" }}>
                <ProcessingReceivingPrint printData={printData} ref={printRef} />
            </div>}
            <Container title="Processing Receiving" doRefresh={doRefresh}>
                
                <div class="form-horizontal" >
                    <div className="row">
                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker selectsStart startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.fromDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, fromDate: date } })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker selectsEnd startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.toDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, toDate: date } })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-7">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <TextBoxTranslater style={{paddingLeft: "24px"}} value={search.Search.SearchTerm} onChange={SearchChange} className="form-control" type="text" placeholder="Search Processing Receiving.." />
                                    <i class="ace-icon fa fa-search blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-1">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <button onClick={onSearch} to="/productdistribution/add" class="btn btn-sm btn-info pull-right">
                                    <i class="ace-icon fa fa-search bigger-110"></i>
                                    Run
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <Paging
                    pagingData={pagingData}
                    LastPage={LastPage}
                    CurrentPage={search.Search.PageNo}
                    OnPageChange={OnPageChange} >
                    {isLoadingMini ?
                        <Spinner />
                        :
                        processingReceiving && processingReceiving.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            {getIsPrint && <th></th>}
                                            <th>Branch</th>
                                            <th>#Id</th>
                                            <th>On Date</th>
                                            <th>Contact</th>
                                            <th>Processing</th>
                                            <th>Product</th>
                                            <th>Wage/unit</th>
                                            <th>Fresh</th>
                                            <th>To</th>
                                            <th>Defective</th>
                                            <th>Rejected</th>
                                            <th>Total</th>
                                            <th>Production</th>
                                            <th>Vouchered</th>
                                            <th>Note</th>
                                            <th>By</th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {processingReceiving.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link className="btn btn-xs btn-info" to={"/processingreceiving/dashboard/" + item.id} title="View dashboard" ><i class="ace-icon fa fa-tachometer"></i></Link>
                                                    </div>
                                                </td>
                                                {getIsPrint && <td>
                                                    <div class="btn-group">
                                                        <PrintStarter printRef={printRef} setPrintData={setPrintData} item={item} />
                                                    </div>
                                                </td>}
                                                <td><WarehouseView item={item.warehouse} uid={"WH" + index} /></td>
                                                <td>{item.id}</td>
                                                <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.onDate}</Moment></div></td>
                                                <td>
                                                    <Link to={"/contact/dashboard/" + item.contactId} >
                                                        <ContactView item={item.contact} uid={"C" + index} />
                                                    </Link>
                                                </td>
                                                <td>
                                                    <Link to={"/processing/dashboard/" + item.processingId} >
                                                        <ProcessingView item={item.processing} />
                                                    </Link>
                                                </td>

                                                <td>
                                                    <Link to={"/product/dashboard/" + item.productId} >
                                                        <ProductView item={item.product} uid={"PP" + index} />
                                                    </Link>
                                                </td>
                                                <td>₹{Number(item.processingWage.wage).toFixed(2)}</td>
                                                <td>{item.freshQuantity} {item.unit}</td>
                                                <td><span class={item.forStock == 1 ? "label label-sm label-primary" : "label label-sm label-success"}>{GetEStockTypeName(item.forStock)}</span></td>
                                                <td>{item.defectiveQuantity} {item.unit}</td>
                                                <td>{item.rejectedQuantity} {item.unit}</td>
                                                <td className="bolder">{Number(item.freshQuantity +item.rejectedQuantity + item.defectiveQuantity).toFixed(2)} {item.unit}</td>
                                                <td>
                                                    {item.isProductionUnit == 1 ? (item.productionUnit ? <Link to={"/productionunit/dashboard/" + item.productionUnitId} >
                                                        <ProductionUnitView item={item.productionUnit} />
                                                    </Link> : "NA") : <span class="label label-sm label-danger">{GetEBoolName(item.isProductionUnit)}</span>}
                                                </td>
                                                <td>{item.isVouchered ? <Link to={"/processingvoucher/dashboard/" + item.voucherId} >{item.voucherId}</Link> : <span class="label label-sm label-danger">{GetEBoolName(item.isVouchered)}</span>}</td>

                                                <td><div style={{ width: "200px" }}>{item.note}</div></td>
                                                {/* <td><span class={item.paymentStatus == 1 ? "label label-sm label-warning" : "label label-sm label-info"}>{GetEPaymentStatusName(item.paymentStatus)}</span></td> */}

                                                <td>
                                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                </td>
                                                <td>
                                                    <div class="btn-group">

                                                        <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                            <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                        </MyAlert>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </Paging>
            </Container >
        </>
    )
}
