import React, { useContext, useState } from 'react'
import AppToaster from '../../commonLib/AppToaster';
import *as warehouseCrud from '../../_crud/warehouse/warehouseCrud'
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { Container } from '../../base/Container';
import { LoadingButton } from '../../commonLib/LoadingButton';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { useEffect } from 'react';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

const initialValues = {
  warehouseCode: "",
  name: "",
  city: "",
  address: "",
  details: ""
}
export function AddWarehouse() {
  const history = useHistory()
  const [isLoadingLocal, setIsLoadingLocal] = useState(false)
  const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)

  const Schema = Yup.object().shape({
    warehouseCode: Yup.string().min(2, "Code must between 2 to 4 characters").max(4, "Code must between 2 to 4 characters").required("Code is required.")
      .matches(/^[A-Z0-9]+$/, " Only alphabets, space and underscore are allowed for this field "),
    name: Yup.string().min(3, "Name must between 3 to 50 characters").max(50, "Category Name must between 3 to 50 characters").required("Name is required."),
    city: Yup.string().required("Name is required."),
    address: Yup.string().required("Name is required."),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values) => {
      Save(values);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });

  useEffect(() => {
    if (formik.values.warehouseCode) {
      formik.setValues({ ...formik.values, warehouseCode: formik.values.warehouseCode.toLocaleUpperCase() })
    }
  }, [formik.values.warehouseCode]);

  const Save = (value) => {
    setIsLoadingMini(true);
    setIsLoadingLocal(true)
    warehouseCrud.Add(value)
      .then(res => {
        setIsLoadingMini(false)
        setIsLoadingLocal(false);
        if (res.data.succeeded) {
          AppToaster('Added successfully.', 'success')
          history.push('/warehouse')
        }
        else {
          AppToaster(res.data.message, 'error')
        }

      }).catch(error => {
        setIsLoadingMini(false)
        setIsLoadingLocal(false);
        AppToaster(error, 'error')
      })
  }

  return (
    <>
      <Container title="Add Product Category" isBack="true">
        <form onSubmit={formik.handleSubmit} class="form-horizontal">
          <div className="row">
            <div className="col-md-2">
              <div class="form-group">
                <div class="col-xs-12">
                  <label class="control-label no-padding-right">Code</label>
                  <input className="form-control" type="text" placeholder="Warehouse Code" name="warehouseCode" {...formik.getFieldProps("warehouseCode")} />
                  {
                    formik.touched.warehouseCode &&
                      formik.errors.warehouseCode ? (
                      <label className="text-danger" >
                        {formik.errors.warehouseCode}
                      </label>
                    ) : null
                  }
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div class="form-group">
                <div class="col-xs-12">
                  <label class="control-label no-padding-right">Name</label>
                  <TextBoxTranslater className="form-control" type="text" placeholder="Warehouse Name" name="name" value={formik.values.name} onChange={(e) => formik.setValues({ ...formik.values, name: e.target.value })} />
                  {
                    formik.touched.name &&
                      formik.errors.name ? (
                      <label className="text-danger" >
                        {formik.errors.name}
                      </label>
                    ) : null
                  }
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div class="form-group">
                <div class="col-xs-12">
                  <label class="control-label no-padding-right">City</label>
                  <TextBoxTranslater className="form-control" type="text" placeholder="City" name="city" value={formik.values.city} onChange={(e) => formik.setValues({ ...formik.values, city: e.target.value })} />
                  {
                    formik.touched.city &&
                      formik.errors.city ? (
                      <label className="text-danger" >
                        {formik.errors.city}
                      </label>
                    ) : null
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div class="form-group">
                <div class="col-xs-12">
                  <label class="control-label no-padding-right">Address</label>
                  <TextBoxTranslater className="form-control" type="text" placeholder="Address" name="address" value={formik.values.address} onChange={(e) => formik.setValues({ ...formik.values, address: e.target.value })} />
                  {
                    formik.touched.address &&
                      formik.errors.address ? (
                      <label className="text-danger" >
                        {formik.errors.address}
                      </label>
                    ) : null
                  }
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div class="form-group">
                <div class="col-xs-12">
                  <label class="control-label no-padding-right">Details</label>
                  <TextBoxTranslater className="form-control" type="text" placeholder="Details" name="details" value={formik.values.details} onChange={(e) => formik.setValues({ ...formik.values, details: e.target.value })} />
                  {
                    formik.touched.details &&
                      formik.errors.details ? (
                      <label className="text-danger" >
                        {formik.errors.details}
                      </label>
                    ) : null
                  }
                </div>
              </div>
            </div>
          </div>

          <div class="clearfix form-actions">
            <div class="pull-right">
              {isLoadingLocal ?
                <LoadingButton />
                :
                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                  <i class="ace-icon fa fa-check bigger-110"></i>
                  Add
                </button>}
            </div>
          </div>
        </form>
      </Container >
    </>
  )
}
