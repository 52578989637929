import React from 'react'
import SyncLoader from "react-spinners/SyncLoader";

export function Spinner({ color = "#438EB9", size = 20 }) {
    return (
        <div>
            <h3 className="text-center" style={{ margin: "100px" }}>
                <SyncLoader t color={color} loading={true} size={size} />
                <br/>
            </h3>
        </div>
    )
}
