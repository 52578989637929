import React from 'react'
import { Link, useParams } from 'react-router-dom'

export function TopBar() {
    const { id } = useParams()
    return (
        <>
            <button class="btn btn-primary navbar-toggle collapsed MyTopMenu" type="button" data-toggle="collapse" data-target="#sidebarInner" aria-expanded="false">
                <i class="ace-icon fa fa-th-list align-top bigger-125"></i> Menu

            </button>
            {/* <div id="sidebarInner" class="sidebar h-sidebar navbar-collapse collapse ace-save-state menu-min"> */}
            <div id="sidebarInner" style={{ position: "inherit" }} class="sidebar h-sidebar navbar-collapse collapse ace-save-state">
                <ul class="nav nav-list">
                    <li class="open hover">
                        <a class="dropdown-toggle">
                            <i class="menu-icon fa fa-inr text-primary"></i>
                            <span class="menu-text"> Voucher </span>

                            <b class="arrow fa fa-angle-down"></b>
                        </a>

                        <b class="arrow"></b>

                        <ul class="submenu">

                            <li class="hover">
                                <Link to={"/contact/dashboard/" + id + "/processingvoucher"} >
                                    <i class="ace-icon fa fa-inr bigger-110"></i> Processing Voucher
                                </Link>

                                <b class="arrow"></b>
                            </li>

                            <li class="hover">
                                <Link to={"/contact/dashboard/" + id + "/productionvoucher"} >
                                    <i class="ace-icon fa fa-inr bigger-110"></i> Production Voucher
                                </Link>

                                <b class="arrow"></b>
                            </li>

                            <li class="hover">
                                <Link to={"/contact/dashboard/" + id + "/advancevoucher"} >
                                    <i class="ace-icon fa fa-inr bigger-110"></i> Advance Voucher
                                </Link>

                                <b class="arrow"></b>
                            </li>
                            <li class="hover">
                                <Link to={"/contact/dashboard/" + id + "/salaryvoucher"}>
                                    <i class="ace-icon fa fa-inr bigger-110"></i> Salary Voucher
                                </Link>

                                <b class="arrow"></b>
                            </li>

                            <li class="hover">
                                <Link to={"/contact/dashboard/" + id + "/expensevoucher"}>
                                    <i class="ace-icon fa fa-inr bigger-110"></i> Expense Voucher
                                </Link>

                                <b class="arrow"></b>
                            </li>

                        </ul>
                    </li>

                    <li class="hover">
                        <Link to={"/contact/dashboard/" + id + "/productionunit"}>
                            <i class="menu-icon fa fa-building text-danger"></i>
                            <span class="menu-text"> Production Unit </span>
                        </Link>

                        <b class="arrow"></b>
                    </li>

                    <li class="open hover">
                        <a class="dropdown-toggle">
                            <i class="menu-icon fa fa-cube text-danger"></i>
                            <span class="menu-text"> Product </span>

                            <b class="arrow fa fa-angle-down"></b>
                        </a>

                        <b class="arrow"></b>

                        <ul class="submenu">

                            <li class="hover">
                                <Link to={"/contact/dashboard/" + id + "/productallotment"}>
                                    <i class="ace-icon fa fa-cube text-danger"></i> My Products
                                </Link>

                                <b class="arrow"></b>
                            </li>

                            <li class="hover">
                                <Link to={"/contact/dashboard/" + id + "/productstock"}>
                                    <i class="ace-icon fa fa-cube text-danger"></i> Given Products Stock
                                </Link>

                                <b class="arrow"></b>
                            </li>

                        </ul>
                    </li>

                    <li class="open hover">
                        <a class="dropdown-toggle">
                            <i class="menu-icon fa fa-recycle text-success"></i>
                            <span class="menu-text"> Processing </span>

                            <b class="arrow fa fa-angle-down"></b>
                        </a>

                        <b class="arrow"></b>

                        <ul class="submenu">

                            <li class="hover">
                                <Link to={"/contact/dashboard/" + id + "/processingdistribution"} >
                                    <i class="ace-icon fa fa-arrow-up"></i> Distribution
                                </Link>

                                <b class="arrow"></b>
                            </li>

                            <li class="hover">
                                <Link to={"/contact/dashboard/" + id + "/processingreceiving"} >
                                    <i class="ace-icon fa fa-arrow-down"></i> Receiving
                                </Link>

                                <b class="arrow"></b>
                            </li>

                            <li class="hover">
                                <Link to={"/contact/dashboard/" + id + "/processingreturn"} >
                                    <i class="ace-icon fa fa-arrow-left"></i> Return
                                </Link>

                                <b class="arrow"></b>
                            </li>

                            <li class="hover">
                                <Link to={"/contact/dashboard/" + id + "/processingstock"} >
                                    <i class="ace-icon fa fa-recycle"></i> Given Processing Stock
                                </Link>

                                <b class="arrow"></b>
                            </li>
                            <li class="hover">
                                <Link style={{ backgroundColor: "#87b87f", color: "white" }} to={"/contact/dashboard/" + id + "/processingAllotment"}>
                                    <i class="ace-icon fa fa-arrow-right bigger-110"></i> New Allotment
                                </Link>

                                <b class="arrow"></b>
                            </li>

                        </ul>
                    </li>
                    <li class="hover">
                        <Link to={"/contact/dashboard/" + id + "/orders"}>
                            <i class="menu-icon fa fa-level-up text-warning"></i>
                            <span class="menu-text"> Orders </span>
                        </Link>

                        <b class="arrow"></b>
                    </li>
                    <li class="hover">
                        <Link to={"/contact/dashboard/" + id + "/defectmanagement"}>
                            <i class="menu-icon fa fa-superpowers text-info"></i>
                            <span class="menu-text"> Defect Management </span>
                        </Link>

                        <b class="arrow"></b>
                    </li>
                    <li class="hover">
                        <Link to={"/contact/dashboard/" + id + "/productdesign"}>
                            <i class="menu-icon fa fa-shopping-basket text-orange"></i>
                            <span class="menu-text"> Bewara as Designer </span>
                        </Link>

                        <b class="arrow"></b>
                    </li>
                    <li class="hover">
                        <Link to={"/contact/dashboard/" + id + "/resetpassword"}>
                            <i class="menu-icon fa fa-key purple"></i>
                            <span class="menu-text"> Reset Password </span>
                        </Link>

                        <b class="arrow"></b>
                    </li>

                    <li class="open hover">
                        <a class="dropdown-toggle">
                            <i class="menu-icon fa fa-camera pink"></i>
                            <span class="menu-text"> Photo & Doc </span>

                            <b class="arrow fa fa-angle-down"></b>
                        </a>

                        <b class="arrow"></b>

                        <ul class="submenu">

                            <li class="hover">
                                <Link to={"/contact/dashboard/" + id + "/photo"}>
                                    <i class="ace-icon fa fa-camera"></i> Upload Photo
                                </Link>

                                <b class="arrow"></b>
                            </li>

                            <li class="hover">
                                <Link to={"/contact/dashboard/" + id + "/docs"}>
                                    <i class="ace-icon fa fa-camera"></i> Upload Document
                                </Link>

                                <b class="arrow"></b>
                            </li>

                        </ul>
                    </li>



                    <li class="hover">
                        <Link to={"/contact/edit/" + id}>
                            <i class="menu-icon fa fa-pencil text-info"></i>
                            <span class="menu-text"> Edit Contact </span>
                        </Link>

                        <b class="arrow"></b>
                    </li>
                </ul>
            </div>
        </>
    )
}
