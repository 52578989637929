import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Container } from '../../../base/Container';
import AppToaster from '../../../commonLib/AppToaster';
import { LoadingButton } from '../../../commonLib/LoadingButton';
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater';
import { GetETransactionMode } from '../../../enumCollections/ETransactionMode';
import * as advanceVoucherCrud from '../../../_crud/vouchers/advanceVoucherCrud';


export function AddAdvanceVoucherPayment() {
    const history = useHistory();
    const { id } = useParams()
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)


    const initialValues = {
        voucherId: id,
        amount: "",
        receivedDate: "",
        refId: "",
        note: "",
        fiscalYear: "",
        transactionMode: 3,
        transactionDetails: "",
        fromLedgerId: "",
        toLedgerId: "",
        otherCharges: 24
    }

    const Schema = Yup.object().shape({
        voucherId: Yup.string().required("Voucher Id is required."),
        amount: Yup.string().required("Amount is required."),
        receivedDate: Yup.string().required("Received Date is required."),
        fiscalYear: Yup.string().required("Fiscal Year is required."),
        fromLedgerId: Yup.string().required("From Ledger Id is required.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        advanceVoucherCrud.AddVoucherPayment(value)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster('Added successfully.', 'success')
                    history.goBack()
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    return (
        <Container title="Add Advance Voucher Payment" isBack="true">
            <form onSubmit={formik.handleSubmit} class="form-horizontal">
                <div className="row">
                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Voucher</label>
                                <input className="form-control" type="number" placeholder="Voucher" name="voucherId" {...formik.getFieldProps("voucherId")} />
                                {
                                    formik.touched.voucherId &&
                                        formik.errors.voucherId ? (
                                        <label className="text-danger" >
                                            {formik.errors.voucherId}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="ontrol-label no-padding-right">Ref Id</label>
                                <input className="form-control" type="text" placeholder="Ref Id" name="refId" {...formik.getFieldProps("refId")} />
                                {
                                    formik.touched.refId &&
                                        formik.errors.refId ? (
                                        <label className="text-danger" >
                                            {formik.errors.refId}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Amount</label>
                                <input className="form-control" placeholder="Amount" type="number" name="amount" {...formik.getFieldProps("amount")} />
                                {
                                    formik.touched.amount &&
                                        formik.errors.amount ? (
                                        <label className="text-danger" >
                                            {formik.errors.amount}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Transaction Details</label>
                                <TextBoxTranslater className="form-control" placeholder="Transaction Details" type="text" name="transactionDetails" value={formik.values.transactionDetails} onChange={(e) => formik.setValues({ ...formik.values, transactionDetails: e.target.value })} />
                                {
                                    formik.touched.transactionDetails &&
                                        formik.errors.transactionDetails ? (
                                        <label className="text-danger" >
                                            {formik.errors.transactionDetails}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Received Date</label>
                                <input className="form-control" placeholder="Received Date" type="date" name="receivedDate" {...formik.getFieldProps("receivedDate")} />
                                {
                                    formik.touched.receivedDate &&
                                        formik.errors.receivedDate ? (
                                        <label className="text-danger" >
                                            {formik.errors.receivedDate}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Fiscal Year</label>
                                <input className="form-control" placeholder="Fiscal Year" type="date" name="fiscalYear" {...formik.getFieldProps("fiscalYear")} />
                                {
                                    formik.touched.fiscalYear &&
                                        formik.errors.fiscalYear ? (
                                        <label className="text-danger" >
                                            {formik.errors.fiscalYear}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">From Ledger</label>
                                <input className="form-control" type="number" placeholder=" From Ledger" name="fromLedgerId" {...formik.getFieldProps("fromLedgerId")} />
                                {
                                    formik.touched.fromLedgerId &&
                                        formik.errors.fromLedgerId ? (
                                        <label className="text-danger" >
                                            {formik.errors.fromLedgerId}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">To Ledger</label>
                                <input className="form-control" type="number" placeholder="To Ledger" name="toLedgerId" {...formik.getFieldProps("toLedgerId")} />
                                {
                                    formik.touched.toLedgerId &&
                                        formik.errors.toLedgerId ? (
                                        <label className="text-danger" >
                                            {formik.errors.toLedgerId}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Transaction Mode</label>
                                <select className="form-control" name="transactionMode" value={formik.values.transactionMode} onChange={(e) => formik.setValues({ ...formik.values, transactionMode: e.target.value })}>
                                    {
                                        GetETransactionMode.ETransactionMode.map((data, index) =>
                                            <option value={data.Id}>{data.Name}</option>
                                        )
                                    }
                                </select>
                                {
                                    formik.touched.transactionMode &&
                                        formik.errors.transactionMode ? (
                                        <label className="text-danger" >
                                            {formik.errors.transactionMode}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Other Charges</label>
                                <input className="form-control" type="number" placeholder=" Other Charges" name="otherCharges" {...formik.getFieldProps("otherCharges")} />
                                {
                                    formik.touched.otherCharges &&
                                        formik.errors.otherCharges ? (
                                        <label className="text-danger" >
                                            {formik.errors.otherCharges}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className="col-md-12">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Note</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="Note" name="note" value={formik.values.note} onChange={(e) => formik.setValues({ ...formik.values, note: e.target.value })} />
                                {
                                    formik.touched.note &&
                                        formik.errors.note ? (
                                        <label className="text-danger" >
                                            {formik.errors.note}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div class="clearfix form-actions">
                    <div class="pull-right">
                        {isLoadingLocal ?
                            <LoadingButton />
                            :
                            <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                <i class="ace-icon fa fa-check bigger-110"></i>
                                Add
                            </button>}
                    </div>
                </div>

            </form>
        </Container >
    )
}
