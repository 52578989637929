import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetAll(productReceiving) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductReceiving/GetAll", productReceiving)
    }
}

export async function GetByPaymentStatus(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductReceiving/GetByPaymentStatus?PageNo=" + param.pageNumber + "&SearchTerm=" + param.searchText + "&PageSize=" + param.pageSize + "&Status=" + param.SearchValue + "&FromDate=" + param.fromDate + "&ToDate=" + param.toDate)
    }
}
export async function GetByProduct(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductReceiving/GetByProduct?PageNo=" + param.pageNumber + "&SearchTerm=" + param.searchText + "&PageSize=" + param.pageSize + "&ProductId=" + param.productId + "&FromDate=" + param.fromDate + "&ToDate=" + param.toDate)
    }
}
export async function GetByContact(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductReceiving/GetByContact?PageNo=" + param.pageNumber + "&SearchTerm=" + param.searchText + "&PageSize=" + param.pageSize + "&ContactId=" + param.contactId + "&FromDate=" + param.fromDate + "&ToDate=" + param.toDate)
    }
}
export async function GetByProductionUnit(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductReceiving/GetByProductionUnit?PageNo=" + param.pageNumber + "&SearchTerm=" + param.searchText + "&PageSize=" + param.pageSize + "&ProductionUnitId=" + param.productionUnitId + "&FromDate=" + param.fromDate + "&ToDate=" + param.toDate)
    }
}
export async function GetByProductionUnitContact(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductReceiving/GetByProductionUnitContact", param)
    }
}
export async function GetByProductWageId(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductReceiving/GetByProductWageId?PageNo=" + param.pageNumber + "&SearchTerm=" + param.searchText + "&PageSize=" + param.pageSize + "&ProductWageId=" + param.productWageId + "&FromDate=" + param.fromDate + "&ToDate=" + param.toDate)
    }
}
export async function GetByVoucher(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductReceiving/GetByVoucher?PageNo=" + param.pageNumber + "&SearchTerm=" + param.searchText + "&PageSize=" + param.pageSize + "&VoucherId=" + param.voucherId + "&FromDate=" + param.fromDate + "&ToDate=" + param.toDate)
    }
}
export async function GetById(Id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductReceiving/GetById?Id=" + Id)
    }
}
export async function Add(ProductReceiving) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductReceiving/Add", ProductReceiving)
    }
}
export async function Remove(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductReceiving/Remove?Id=" + id)
    }
}