import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { PagingEmpty } from '../../commonLib/PagingEmpty';
import { Spinner } from '../../commonLib/Spinner';
import *as productCategoryCrud from '../../_crud/productCategoryCrud'
import 'moment-timezone';
import { EmptyData } from '../../commonLib/EmptyData';
import { MyAlert } from '../../commonLib/MyAlert';
import { dashboardContext } from '../Global/dashboardContext';

export function ManageCategories() {
  const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
  const [productCategories, setProductCategories] = useState([])
  const [dashboardData, setDashboardData] = useContext(dashboardContext)

  useEffect(() => {
    getData()
  }, []);

  function getData() {
    setIsLoadingMini(true);
    productCategoryCrud.GetAll()
      .then(res => {
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            setProductCategories(hdata);
          }
          else {
            setProductCategories([]);
            AppToaster("Server Error /Invalid Try.", 'error')
          }
        }
        else {
          setProductCategories([]);
        }
        setIsLoadingMini(false);
      })
      .catch(error => {
        setIsLoadingMini(false);
        AppToaster(error, 'error')
      })
  }

  function doRefresh(event) {
    getData()
    setTimeout(() => {
      event.detail.complete();
    }, 1000);
  }

  const DeleteItem = (id) => {
    setIsLoadingMini(true)
    productCategoryCrud.Remove(id)
      .then(res => {
        if (res.data.succeeded) {
          setIsLoadingMini(false)
          AppToaster('Deleted successfully.', 'success')
          getData()
        }
        else {
          setIsLoadingMini(false)
          AppToaster('Using by someone,could not delete.', 'error')
        }

      }).catch(error => {
        setIsLoadingMini(false)
        AppToaster('Network error', 'error')
      })
  }


  return (
    <>
      <Container title="Product Categories" doRefresh={doRefresh} isBack="true">
        <Link to="/product/categories/add" class="btn btn-sm btn-success">
          <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
          New
        </Link>
        <div class="hr dotted"></div>
        <PagingEmpty text={"Total " + productCategories.length + " items."}>
          {isLoadingMini ?
            <Spinner />
            :
            productCategories && productCategories.length > 0 ?
              <div className="tables-scroll">
                <table className="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th style={{ width: "43px" }}>#Id</th>
                      <th>Name</th>
                      <th>Default Wage</th>
                      <th>Note</th>
                      <th style={{ width: "43px" }}></th>
                      <th style={{ width: "83px" }}></th>
                    </tr>
                  </thead>

                  <tbody>
                    {productCategories.map((item, index) =>
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item.catName} </td>
                        <td><b>₹ {item.defaultWage}</b> </td>
                        <td>{item.note}</td>
                        <td>
                          <Link to={"/product/wage/" + item.id + "/" + item.catName} class="btn btn-xs btn-success">
                            <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                            Wage
                          </Link>
                        </td>
                        <td>
                          <div style={{ width: "73px" }}>
                            <Link to={"/product/categories/edit/" + item.id} class="btn btn-xs btn-success">
                              <i class="ace-icon fa fa-pencil bigger-120"></i>
                            </Link>&nbsp;
                            <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                              <i class="ace-icon fa fa-trash-o bigger-120"></i>
                            </MyAlert>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              :
              <EmptyData>
                No records found.
                <br />
                <br />
              </EmptyData>
          }
        </PagingEmpty>
      </Container >
    </>
  )
}
