import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Container } from '../../base/Container'
import AppToaster from '../../commonLib/AppToaster'
import { Paging } from '../../commonLib/Paging'
import { Spinner } from '../../commonLib/Spinner'
import *as sareeDrapingCrud from '../../_crud/sareeDrapingCrud'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext'
import Moment from 'react-moment';
import 'moment-timezone';
import { EmptyData } from '../../commonLib/EmptyData'
import { UserView } from '../Global/Elements/UserView'
import GetLoginInfo from '../../auth/_userContext/GetLoginInfo'
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater'
import { MyAlert } from '../../commonLib/MyAlert'

const initValues = {
    SearchTerm: "",
    pageNo: 1,
    pageSize: 10
}

export function SareeDrapingCatalogue() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [search, setSearch] = useState(initValues)
    const [drapingCatalogue, setDrapingCatalogue] = useState([])
    const [LastPage, setLastPage] = useState(1)
    const [pagingData, setPagingData] = useState()
    const userData = GetLoginInfo()

    useEffect(() => {
        onSearch()
    }, [search.pageNo])

    const OnPageChange = (e) => {
        const { value } = e.target
        setSearch({ ...search, pageNo: value });
    }

    function onSearch() {
        getData()
    }

    function getData() {
        setIsLoadingMini(true);
        sareeDrapingCrud.GetAllCatalogue(search)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setDrapingCatalogue(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoadingMini(false)
                        setDrapingCatalogue([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoadingMini(false)
                    setDrapingCatalogue([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        setSearch({ ...search, pageNo: 1 })
        onSearch()
        setTimeout(() => {
            event.detail.complete();
        }, 1000);
    }

    function SearchChange(e) {
        const value = e.target.value
        setSearch({ ...search, SearchTerm: value })
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        sareeDrapingCrud.RemoveCatalogue(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    AppToaster('Deleted successfully.', 'success')
                    getData()
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by some catalogue, could not delete.', 'error')
                }

            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }


    return (
        <>
            <Container title="Cataglogues" doRefresh={doRefresh}>
                <div class="form-horizontal" >
                    <div className="row">
                        <div className="col-md-11">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <TextBoxTranslater style={{ paddingLeft: "24px" }} value={search.SearchTerm} onChange={SearchChange} className="form-control" type="text" placeholder="Search Cataglogue.." />
                                    <i class="ace-icon fa fa-search blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-1">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <button onClick={onSearch} class="btn btn-sm btn-info pull-right">
                                    <i class="ace-icon fa fa-search bigger-110"></i>
                                    Run
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

                <Paging
                    pagingData={pagingData}
                    LastPage={LastPage}
                    CurrentPage={search.pageNo}
                    OnPageChange={OnPageChange} >
                    {isLoadingMini ?
                        <Spinner />
                        :
                        drapingCatalogue && drapingCatalogue.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "43px" }}></th>
                                            <th>#Id</th>
                                            <th>Code</th>
                                            <th>Name</th>
                                            <th>Note</th>
                                            <th>On Date</th>
                                            <th style={{ width: "43px" }}>By</th>
                                            <th style={{ width: "43px" }}></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {drapingCatalogue.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link className="btn btn-xs btn-info" to={"/catalogue/dashboard/" + item.id} title="View dashboard" ><i class="ace-icon fa fa-tachometer"></i></Link>
                                                    </div>
                                                </td>
                                                <td>{item.id}</td>

                                                <td>{item.catalogueCode}</td>
                                                <td>
                                                    <div style={{ minWidth: "200px" }}>
                                                        {item.catalogueName}
                                                    </div>
                                                </td>
                                                <td><div style={{ minWidth: "350px", overflow: "hidden", display: "-webkit-box", webkitLineClamp: "1", webkitBoxOrient: "vertical" }}>{item.note}</div></td>
                                                <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.createdOn}</Moment></div></td>
                                                <td>
                                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                </td>
                                                <td>
                                                    <div style={{ width: "73px" }}>
                                                        <div class="btn-group">

                                                            <Link to={"/catalogue/edit/" + item.id} class="btn btn-xs btn-success">
                                                                <i class="ace-icon fa fa-pencil bigger-120"></i>
                                                            </Link>

                                                            <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                                <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                            </MyAlert>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </Paging>
            </Container >
        </>
    )
}
