import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Container } from '../../../base/Container';
import { loadingContext } from '../../../commonLib/_lodingContext';
import { TimelineHistory } from '../../Global/TimelineHistory';
import * as bankTransactionCrud from '../../../_crud/cheque/bankTransactionCrud'
import Moment from 'react-moment';
import { ContactView } from '../../Global/Elements/ContactView';
import { StatusView } from '../../../commonLib/StatusView';
import { GetEChequeStatus, GetEChequeStatusName } from '../../../enumCollections/EChequeStatus';
import { UserView } from '../../Global/Elements/UserView';
import { GetEAccountTypeName } from '../../../enumCollections/EAccountType';
import { BASE_URL } from '../../../initApi/apiUrls';
import AppToaster from '../../../commonLib/AppToaster';
import { LoadingButton } from '../../../commonLib/LoadingButton';
import { ImageUploader } from '../../../commonLib/ImageUploader';
import { PrintBankTransaction } from '../../Prints/PrintBankTransaction';
import { PrintStarterDash } from '../../Prints/PrintStaterDash';
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater';
import { getIsPrint } from '../../../config/GlobalSettings';


export function TransactionDashboard() {
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [transaction, setTransaction] = useState()
    const [status, setStatus] = useState(0)
    const [note, setNote] = useState("")
    const { id } = useParams()

    function doRefresh() {
        getTransaction()
    }

    useEffect(() => {
        getTransaction()
    }, []);

    function getTransaction() {
        setIsLoading(true);
        bankTransactionCrud.GetById(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setTransaction(hdata)
                        setStatus(hdata.status)
                        setPrintData(hdata)
                    }
                    else {
                    }
                }
                else {
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
            })
    }

    //Photo
    const [photo, setPhoto] = useState("")
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const SetPhoto = (image) => {
        setPhoto(image)
    }
    const SavePhoto = () => {
        setIsLoadingLocal(true);
        const formData = new FormData()

        formData.append('id', id)
        formData.append('file', photo)
        bankTransactionCrud.UploadPhoto(formData)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingLocal(false);
                    AppToaster('Updated successfully.', 'success')
                }
                else {
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }

            })
            .catch(error => {
                alert("image size is too large.")
                AppToaster(error, 'error')

                setIsLoadingLocal(false);
            })
    }

    const UpdateStatus = () => {
        setIsLoadingLocal(true)
        bankTransactionCrud.ChangeStatus({ Id: transaction.id, Note: note, Status: status })
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingLocal(false);
                    setNote("")
                    AppToaster('Updated successfully.', 'success')
                    doRefresh()
                }
                else {
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }
    const [printData, setPrintData] = useState()
    const printRef = useRef();

    return (
        <>
            <div style={{ display: "none" }}>
                <PrintBankTransaction printData={printData} ref={printRef} />
            </div>
            <Container title={"Transaction Dashboard"} doRefresh={doRefresh} isBack="true">
                {getIsPrint && transaction &&
                    <div class="btn-group">
                        <PrintStarterDash printRef={printRef} setPrintData={setPrintData} />
                    </div>
                }
                {getIsPrint && <div class="hr dotted"></div>}
                <div className="row">
                    <div className="col-lg-8">
                        <div className="widget-box">
                            <div class="widget-header widget-header-flat widget-header-small">
                                <h5 class="widget-title">
                                    Details
                                </h5>
                            </div>
                            {
                                transaction &&
                                <div className="row">
                                    <div class="profile-user-info profile-user-info-striped">
                                        <div class="profile-info-row">
                                            <div class="profile-info-name"> Transcation Id</div>

                                            <div class="profile-info-value">
                                                <span ><b>{transaction.id}</b></span>
                                            </div>
                                        </div>
                                        <div class="profile-info-row">
                                            <div class="profile-info-name"> For Contact </div>

                                            <div class="profile-info-value">
                                                <Link to={"/contact/dashboard/" + transaction.contact.id}>
                                                    <ContactView item={transaction.contact} uid={"CXV1"} />
                                                </Link>
                                            </div>
                                        </div>
                                        <div class="profile-info-row">
                                            <div class="profile-info-name">Phone</div>

                                            <div class="profile-info-value">
                                                <span >{transaction.contact.phone}</span>
                                            </div>
                                        </div>
                                        <div class="profile-info-row">
                                            <div class="profile-info-name">Address</div>

                                            <div class="profile-info-value">
                                                <span >{transaction.contact.address}</span>
                                            </div>
                                        </div>
                                        <div class="profile-info-row">
                                            <div class="profile-info-name">Email</div>

                                            <div class="profile-info-value">
                                                <span >{transaction.contact.email ? transaction.contact.email : "NA"}</span>
                                            </div>
                                        </div>
                                        <div class="profile-info-row">
                                            <div class="profile-info-name"> For Bank </div>

                                            <div class="profile-info-value">
                                                <span >
                                                    {transaction.bank.accountHolderName} ({transaction.bank.accountNo} - {GetEAccountTypeName(transaction.bank.accountType)})
                                                    <br />
                                                    IFSC: {transaction.bank.ifscCode}, Branch: {transaction.bank.branch}, {transaction.bank.city}, {transaction.bank.state}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="profile-info-row">
                                            <div class="profile-info-name"> Cheque Date</div>

                                            <div class="profile-info-value">
                                                <Moment format="DD-MMM-YYYY">{transaction.chequeDate}</Moment>
                                            </div>
                                        </div>
                                        <div class="profile-info-row">
                                            <div class="profile-info-name"> Cheque/Trans No </div>

                                            <div class="profile-info-value">
                                                <span ><b>{transaction.chequeNo}</b></span>
                                            </div>
                                        </div>
                                        <div class="profile-info-row">
                                            <div class="profile-info-name"> Cheque/Trans Given By </div>

                                            <div class="profile-info-value">
                                                <span >{transaction.chequeGivenByName}</span>
                                            </div>
                                        </div>
                                        <div class="profile-info-row">
                                            <div class="profile-info-name"> Cheque/Trans Bank </div>

                                            <div class="profile-info-value">
                                                <span >{transaction.chequeBankName}</span>
                                            </div>
                                        </div>
                                        <div class="profile-info-row">
                                            <div class="profile-info-name"> Cheque/Trans City </div>

                                            <div class="profile-info-value">
                                                <span >{transaction.chequeCity}</span>
                                            </div>
                                        </div>
                                        <div class="profile-info-row">
                                            <div class="profile-info-name">Amount</div>
                                            <div class="profile-info-value">
                                                <span ><b>₹{Number(transaction.chequeAmount).toFixed(2)}</b></span>
                                            </div>
                                        </div>
                                        <div class="profile-info-row">
                                            <div class="profile-info-name"> Note </div>

                                            <div class="profile-info-value">
                                                <span >{transaction.note ? transaction.note : "NA"}</span>
                                            </div>
                                        </div>
                                        <div class="profile-info-row">
                                            <div class="profile-info-name">Status </div>

                                            <div class="profile-info-value">
                                                <span><StatusView status={transaction.status}>{GetEChequeStatusName(transaction.status)}</StatusView></span>
                                            </div>
                                        </div>
                                        <div class="profile-info-row">
                                            <div class="profile-info-name"> Created By </div>

                                            <div class="profile-info-value">
                                                <span><UserView item={transaction.createdByUser} uid={"GD1"} createdOn={transaction.createdOn} /></span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            }
                        </div>


                        <br />
                        <div className="widget-box">
                            <div class="widget-header widget-header-flat widget-header-small">
                                <h5 class="widget-title">
                                    Cheque Photo
                                </h5>
                            </div>
                            <div className="row">

                                <div className="col-md-12">
                                    <div class="form-group">
                                        <div class="col-xs-12">
                                            <ImageUploader initImage={transaction && transaction.isPhoto == 1 ? (BASE_URL + "/" + transaction.photoUrl) : undefined} SetImage={SetPhoto} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div class="form-group">
                                        <div class="col-xs-12">
                                            {isLoadingLocal ?
                                                <LoadingButton />
                                                :
                                                <button class="btn btn-info" type="submit" onClick={SavePhoto} disabled={!photo}>
                                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                                    Upload
                                                </button>}
                                        </div>
                                        <small> &nbsp;</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="widget-box">
                            <div class="widget-header widget-header-flat widget-header-small">
                                <h5 class="widget-title">
                                    Change Status
                                </h5>
                            </div>
                            <div className="row">

                                <div className="col-xs-12">
                                    <div class="form-group">
                                        <div class="col-xs-12">
                                            <label class="control-label no-padding-right"> Select Status</label>
                                            <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
                                                {
                                                    GetEChequeStatus.EChequeStatus.map((data, index) =>
                                                        <option key={data.Id} value={data.Id}>{data.Name}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-12">
                                    <div class="form-group">
                                        <div class="col-xs-12">
                                            <label class="control-label no-padding-right"> Note</label>
                                            <TextBoxTranslater className="form-control" type="text" placeholder="Note" value={note} onChange={(e) => setNote(e.target.value)} />
                                            <small>*Note is required.</small>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-12">
                                    <div class="form-group">
                                        <div class="col-xs-12">
                                            <br />
                                            {isLoadingLocal ?
                                                <LoadingButton />
                                                :
                                                <button class="btn btn-info" type="submit" onClick={UpdateStatus} disabled={!note}>
                                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                                    Update
                                                </button>}
                                        </div>
                                        <small> &nbsp;</small>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <br />
                        <TimelineHistory id={id} type={10} pageSize={10} text="Transaction Timeline" />
                    </div>

                </div>
            </Container>
        </>
    )
}
