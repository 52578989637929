import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from "yup";
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import * as applicationUserCrud from '../../_crud/applicationUserCrud';
import { LoadingButton } from './../../commonLib/LoadingButton'
import { GetEStatus } from '../../enumCollections/EStatus';
import { GetEConnectedIdType } from '../../enumCollections/EConnectedIdType';
import * as warehouseCrud from '../../_crud/warehouse/warehouseCrud'
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';
// const initialValues = {
//   password: "",
//   user: {
//     userName: "",
//     fullName: "",
//     phoneNumber: "",
//     email: "",
//     status: 1,
//     isDp: 0,
//     connectedIdType: 102
//   }
// }
const initialValues = {
  userName: "",
  password: "",
  fullName: "",
  phoneNumber: "",
  email: "",
  status: 1,
  isDp: 0,
  connectedIdType: 102,
  isWarehouse: 0,
  warehouseId: 0,
}
export function AddUsers() {
  const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
  const [isLoadingLocal, setIsLoadingLocal] = useState(false)
  const history = useHistory();
  const [warehouse, setWarehouse] = useState([])

  const Schema = Yup.object().shape({
    userName: Yup.string().required("User Name is required.")
      .matches(/^[aA-zZ]+$/, " Only alphabets and underscore are allowed for this field "),
    status: Yup.string().required("Status is required."),
    connectedIdType: Yup.string().required("Connected Type is required."),
    password: Yup.string().required("Password is required.").matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
    ),
    fullName: Yup.string().min(3, "Full Name must be between 3 to 30 characters").max(30, "Full Name must be between 3 to 30 characters").required("Full Name is required.")
      .matches(/^[aA-zZ\s]+$/, " Only alphabets, space and underscore are allowed for this field "),
    email: Yup.string().email("Invalid email."),
    warehouseId: Yup.string().required("Warehouse is required."),
    phoneNumber: Yup.string().required("Phone Number is required.").matches(
      /^([\d]{10})$/g,
      "Invalid phone number"
    ),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values) => {
      Save(values);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });

  useEffect(() => {
    getWarehouse()
  }, [])

  useEffect(() => {
    if (formik.values.connectedIdType != 102) {
      formik.setValues({ ...formik.values, isWarehouse: 0, warehouseId: 0 })
    }
  }, [formik.values.connectedIdType])

  const Save = (value) => {
    setIsLoadingMini(true);
    setIsLoadingLocal(true)
    applicationUserCrud.Add({
      password: value.password,
      user: {
        userName: value.userName,
        fullName: value.fullName,
        phoneNumber: value.phoneNumber,
        email: value.email,
        status: value.status,
        isDp: value.isDp,
        connectedIdType: value.connectedIdType,
        isWarehouse: value.isWarehouse,
        warehouseId: value.warehouseId,
      }
    })
      .then(res => {
        if (res.data.succeeded) {
          setIsLoadingMini(false)
          setIsLoadingLocal(false);
          AppToaster('Added successfully.', 'success')
          history.goBack()
        }
        else {
          setIsLoadingMini(false)
          setIsLoadingLocal(false);
          AppToaster("Server Error /Invalid Try.", 'error')
        }
      })
      .catch(error => {
        setIsLoadingMini(false);
        setIsLoadingLocal(false);
        AppToaster(error, 'error')
      })
  }
  function getWarehouse() {
    setIsLoadingLocal(true);
    warehouseCrud.GetAll()
      .then(res => {
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            setWarehouse(hdata);
            //alert(JSON.stringify(hdata))
          }
          else {
            setWarehouse([]);
            AppToaster("Server Error /Invalid Try.", 'error')
          }
        }
        else {
          setWarehouse([]);
        }
        setIsLoadingLocal(false);
      })
      .catch(error => {
        setIsLoadingLocal(false);
        AppToaster(error, 'error')
      })
  }
  return (
    <Container title="Add User" isBack="true">
      <form onSubmit={formik.handleSubmit} class="form-horizontal">
        <div className="row">

          <div className="col-md-4">
            <div class="form-group">
              <div class="col-xs-12">
                <label class="control-label no-padding-right">Full Name</label>
                <TextBoxTranslater className="form-control" type="text" placeholder="Full Name" name="fullName" value={formik.values.fullName} onChange={(e) => formik.setValues({ ...formik.values, fullName: e.target.value })} />
                {
                  formik.touched.fullName &&
                    formik.errors.fullName ? (
                    <label className="text-danger" >
                      {formik.errors.fullName}
                    </label>
                  ) : null
                }
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div class="form-group">
              <div class="col-xs-12">
                <label class="control-label no-padding-right">User Name</label>
                <input className="form-control" type="text" placeholder="User Name" name="userName" {...formik.getFieldProps("userName")} />
                {
                  formik.touched.userName &&
                    formik.errors.userName ? (
                    <label className="text-danger" >
                      {formik.errors.userName}
                    </label>
                  ) : null
                }
              </div>
            </div>
          </div>


          <div className="col-md-4">
            <div class="form-group">
              <div class="col-xs-12">
                <label class="control-label no-padding-right">Password</label>
                <input className="form-control" type="password" placeholder="Password" name="password" {...formik.getFieldProps("password")} />
                {
                  formik.touched.password &&
                    formik.errors.password ? (
                    <label className="text-danger" >
                      {formik.errors.password}
                    </label>
                  ) : null
                }
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div class="form-group">
              <div class="col-xs-12">
                <label class="control-label no-padding-right"> Phone Number</label>
                <input className="form-control" type="text" placeholder="Phone Number" name="phoneNumber" {...formik.getFieldProps("phoneNumber")} />
                {
                  formik.touched.phoneNumber &&
                    formik.errors.phoneNumber ? (
                    <label className="text-danger" >
                      {formik.errors.phoneNumber}
                    </label>
                  ) : null
                }
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div class="form-group">
              <div class="col-xs-12">
                <label class="control-label no-padding-right"> Email</label>
                <input className="form-control" type="email" placeholder="Email" name="email" {...formik.getFieldProps("email")} />
                {
                  formik.touched.email &&
                    formik.errors.email ? (
                    <label className="text-danger" >
                      {formik.errors.email}
                    </label>
                  ) : null
                }
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div class="form-group">
              <div class="col-xs-12">
                <label class="control-label no-padding-right">Status</label>
                <select className="form-control" name="status" value={formik.values.status} onChange={(e) => formik.setValues({ ...formik.values, status: e.target.value })}>
                  {
                    GetEStatus.EStatus.map((data, index) =>
                      <option key={data.Id} value={data.Id}>{data.Name}</option>
                    )
                  }
                </select>
                {
                  formik.touched.status &&
                    formik.errors.status ? (
                    <label className="text-danger" >
                      {formik.errors.status}
                    </label>
                  ) : null
                }
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div class="form-group">
              <div class="col-xs-12">
                <label class="control-label no-padding-right"> Connected Type</label>
                <select className="form-control" name="connectedIdType" value={formik.values.connectedIdType} onChange={(e) => formik.setValues({ ...formik.values, connectedIdType: e.target.value })}>
                  {
                    GetEConnectedIdType.EConnectedIdType.map((data, index) =>
                      <option key={data.Id} value={data.Id}>{data.Name}</option>
                    )
                  }
                </select>
                {
                  formik.touched.connectedIdType &&
                    formik.errors.connectedIdType ? (
                    <label className="text-danger" >
                      {formik.errors.connectedIdType}
                    </label>
                  ) : null
                }
              </div>
            </div>
          </div>
        </div>
        {formik.values.connectedIdType == 102 &&
          <div className="row">
            <div className="col-md-6">
              <div class="form-group">
                <div class="col-xs-12">
                  <label class="control-label no-padding-right">Is Warehouse</label>
                  <div>
                    <input onChange={() => formik.setValues({ ...formik.values, isWarehouse: (formik.values.isWarehouse == 1 ? 0 : 1), warehouseId: (formik.values.isWarehouse == 0 ? "" : 0) })} checked={formik.values.isWarehouse == 1} type="checkbox" class="ace ace-switch ace-switch-5" />
                    <span class="lbl middle"></span>
                  </div>
                  {
                    formik.touched.isWarehouse &&
                      formik.errors.isWarehouse ? (
                      <label className="text-danger" >
                        {formik.errors.isWarehouse}
                      </label>
                    ) : null
                  }
                </div>
              </div>
            </div>

            {formik.values.isWarehouse == 1 &&

              <div className="col-md-6">
                <div class="form-group">
                  <div class="col-xs-12">
                    <label class="control-label no-padding-right">Warehouse</label>
                    <select className="form-control" name="warehouseId" value={formik.values.warehouseId} onChange={(e) => formik.setValues({ ...formik.values, warehouseId: e.target.value })}>
                      <option value="" >Select...</option>
                      {
                        warehouse.map((data, index) =>
                          <option key={data.id} value={data.id}>{data.name.toUpperCase()} ({data.warehouseCode.toUpperCase()})</option>
                        )
                      }
                    </select>
                    {
                      formik.touched.warehouseId &&
                        formik.errors.warehouseId ? (
                        <label className="text-danger" >
                          {formik.errors.warehouseId}
                        </label>
                      ) : null
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        }
        <div class="clearfix form-actions">
          <div class="pull-right">
            {isLoadingLocal ?
              <LoadingButton />
              :
              <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                <i class="ace-icon fa fa-check bigger-110"></i>
                Add
              </button>}
          </div>
        </div>

      </form>
    </Container >
  )
}
