import React, { useState, useEffect } from "react";
import { getCanvas } from "react-lasso-select";
import mergeImages from 'merge-images';
import rotate from 'base64-rotate'
import { swirl } from "./ImageEditFunction";
import { PoseBg } from "./PoseBg";

export function Saree3Pose({ img1, img2, bg, setIsCompleted, setDrapResult, status }) {

    const [isReadySari1, setIsReadySari1] = useState(false);
    const [isReadySari2, setIsReadySari2] = useState(false);
    const [isReadySari3, setIsReadySari3] = useState(false);
    const [isReadySari4, setIsReadySari4] = useState(false);
    const [isReadySari5, setIsReadySari5] = useState(false);
    const [isReadySari6, setIsReadySari6] = useState(false);
    const [isReadySari7, setIsReadySari7] = useState(false);
    const [isReadySari8, setIsReadySari8] = useState(false);

    //#region Saree1
    const MergeSaree = () => {
        try {
            let cbg = PoseBg(parseInt(bg))
            //alert(cbg)
            mergeImages([
                { src: cbg, x: 0, y: 0 },
                { src: saree1, x: 573, y: 2472 },
                { src: saree2, x: 873, y: 2506 },
                { src: saree3, x: 898, y: 2433 },
                { src: saree8, x: 992, y: 745 },
                { src: saree7, x: 800, y: 731 },
                { src: saree6, x: 423, y: 770 },
                { src: saree5, x: 423, y: 1211 },
                { src: saree4, x: 130, y: 1102 },
                { src: './assets/images/Draper/new/6.png', x: 0, y: 0 },
                { src: './assets/images/Draper/new/6abody.png', x: 0, y: 0 },
            ])
                .then(b64 => {
                    setDrapResult(b64)
                    //alert("sareedrap")
                    setIsCompleted("completed")
                }).catch(e => {
                    setDrapResult()
                    setIsCompleted("failed")
                    alert(e)
                });
        } catch (e) {
            setDrapResult()
            setIsCompleted("failed")
            alert(e)
        }
    }
    ///Saree item 1
    const [saree1, setSaree1] = useState("");
    const GetSaree1 = () => {
        setIsReadySari1(false);
        swirl(img1, 200, 1250, 600, -0.5).then(bul => {
            rotate(bul, 9).then(newsrc => {
                let initPP = "157,1470 160,1506 157,1539 157,1566 163,1581 178,1569 190,1600 232,1612 280,1624 335,1630 374,1636 395,1633 419,1633 452,1633 458,1584 467,1539 480,1461 474,1388 296,1415"
                    .split(" ")
                    .map((c) => c.split(",").map(Number))
                    .map(([x, y]) => ({ x, y }));
                getCanvas(newsrc, initPP, (err, canvas) => {
                    if (!err) {
                        setSaree1(canvas.toDataURL());
                        setIsReadySari1(true)
                    }
                });
            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///Saree item 2
    const [saree2, setSaree2] = useState("");
    const GetSaree2 = () => {
        setIsReadySari2(false)
        rotate(img1, 10).then(newsrc => {
            let initPP = "39,1576 43,1485 43,1427 158,1391 170,1427 170,1458 170,1488 170,1500 170,1509 161,1528 140,1558 118,1582 115,1585 109,1588 100,1591 85,1585 67,1579 55,1576"
                .split(" ")
                .map((c) => c.split(",").map(Number))
                .map(([x, y]) => ({ x, y }));

            getCanvas(newsrc, initPP, (err, canvas) => {
                if (!err) {
                    setSaree2(canvas.toDataURL());
                    setIsReadySari2(true)
                }
            });
        }).catch(err => {
        })
    }
    ///Saree item 3
    const [saree3, setSaree3] = useState("");
    const GetSaree3 = () => {
        setIsReadySari3(false)
        swirl(img1, 200, 1250, 600, -0.5).then(bul => {
            rotate(bul, 179).then(newsrc1 => {
                rotate(newsrc1, 118).then(newsrc => {
                    let initPP = "1505,3094 1468,2883 1592,2818 1602,2872 1608,2916 1608,2953 1608,2975 1602,2997 1575,3029 1538,3088 1527,3094 1521,3094"
                        .split(" ")
                        .map((c) => c.split(",").map(Number))
                        .map(([x, y]) => ({ x, y }));

                    getCanvas(newsrc, initPP, (err, canvas) => {
                        if (!err) {
                            setSaree3(canvas.toDataURL());
                            setIsReadySari3(true)
                        }
                    });
                }).catch(err => {
                })
            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///Saree item 4
    const [saree4, setSaree4] = useState("");
    const GetSaree4 = () => {
        setIsReadySari4(false)
        swirl(img1, 2540, 500, 550, -1).then(bul => {
            rotate(bul, 72).then(newsrc => {
                let initPP = "864,3134 563,2241 468,1935 411,1758 406,1734 411,1715 430,1710 463,1706 482,1701 496,1706 520,1682 544,1682 568,1677 592,1667 621,1653 654,1639 697,1615 711,1610 721,1653 740,1715 797,1882 869,2083 974,2317 1055,2480 1093,2542 1151,2609 1213,2661 1303,2709 1408,2742 1518,2747 1623,2718 1709,2695 1747,2690 1790,2685 1719,2738 1633,2800 1571,2847 1466,2914 1313,2991 1208,3039 1079,3082 940,3120"
                    .split(" ")
                    .map((c) => c.split(",").map(Number))
                    .map(([x, y]) => ({ x, y }));

                getCanvas(newsrc, initPP, (err, canvas) => {
                    if (!err) {
                        setSaree4(canvas.toDataURL());
                        setIsReadySari4(true)
                    }
                });
            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///Saree item 5
    const [saree5, setSaree5] = useState("");
    const GetSaree5 = () => {
        setIsReadySari5(false)
        swirl(img1, 1940, 900, 550, -1).then(bul => {
            rotate(bul, 45).then(newsrc => {
                let initPP = "2449,2458 2262,2443 2032,2409 1870,2216 1724,1942 1621,1631 1550,1382 1600,1354 1640,1357 1746,1379 1920,1413 2104,1453 2269,1488 2418,1519 2558,1534 2627,1531 2679,1553 2735,1603 2776,1643 2819,1693 2882,1755 2950,1827 3019,1889 3065,1942 3100,1982 3040,2023 2928,2110 2763,2234 2567,2378"
                    .split(" ")
                    .map((c) => c.split(",").map(Number))
                    .map(([x, y]) => ({ x, y }));

                getCanvas(newsrc, initPP, (err, canvas) => {
                    if (!err) {
                        setSaree5(canvas.toDataURL());
                        setIsReadySari5(true)
                    }
                });

            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///Saree item 6
    const [saree6, setSaree6] = useState("");
    const GetSaree6 = () => {
        setIsReadySari6(false)
        swirl(img1, 1940, 600, 550, -1).then(bul => {
            rotate(bul, 31).then(newsrc => {
                let initPP = "2735,1177 2619,1189 2390,1183 2187,1144 1832,1051 1703,996 1661,964 1645,880 1664,857 1667,832 1671,809 1677,780 1690,745 1709,696 1725,661 1738,625 1742,606 1748,590 1758,574 1771,558 1800,529 1832,525 1970,745 2064,748 2119,787 2277,880 2503,1012 2648,1102 2667,1115"
                    .split(" ")
                    .map((c) => c.split(",").map(Number))
                    .map(([x, y]) => ({ x, y }));

                getCanvas(newsrc, initPP, (err, canvas) => {
                    if (!err) {
                        setSaree6(canvas.toDataURL());
                        setIsReadySari6(true)
                    }
                });
            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///Saree item 7
    const [saree7, setSaree7] = useState("");
    const GetSaree7 = () => {
        setIsReadySari7(false)
        let initPP = "160,1421 160,1354 192,1354 208,1350 224,1341 240,1328 259,1309 272,1293 285,1264 294,1226 304,1190 304,1146 336,1152 371,1162 374,1210 384,1274 390,1318 384,1347 378,1370 378,1379 381,1389 374,1430 374,1475 368,1501 349,1533"
            .split(" ")
            .map((c) => c.split(",").map(Number))
            .map(([x, y]) => ({ x, y }));
        getCanvas(img2, initPP, (err, canvas) => {
            if (!err) {
                setSaree7(canvas.toDataURL());
                setIsReadySari7(true)
            }
        });
    }
    ///Saree item 8
    const [saree8, setSaree8] = useState("");
    const GetSaree8 = () => {
        setIsReadySari8(false)
        rotate(img2, 179).then(newsrc => {
            rotate(newsrc, 140).then(newsrc => {
                let initPP = "1583,1725 1569,1688 1569,1620 1560,1551 1565,1523 1592,1528 1620,1551 1647,1583 1711,1665 1730,1688 1707,1716 1665,1743 1629,1766 1620,1766 1610,1753"
                    .split(" ")
                    .map((c) => c.split(",").map(Number))
                    .map(([x, y]) => ({ x, y }));

                getCanvas(newsrc, initPP, (err, canvas) => {
                    if (!err) {
                        setSaree8(canvas.toDataURL());
                        setIsReadySari8(true)
                    }
                });
            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///////////////////////////////////////////
    //#endregion
    useEffect(() => {
        if (isReadySari1 && isReadySari2 && isReadySari3 && isReadySari4 && isReadySari5 && isReadySari6
            && isReadySari7 && isReadySari8) {
            MergeSaree()
            setIsCompleted("Merging Images")
            setIsReadySari1(false)
            setIsReadySari2(false)
            setIsReadySari3(false)
            setIsReadySari4(false)
            setIsReadySari5(false)
            setIsReadySari6(false)
            setIsReadySari7(false)
            setIsReadySari8(false)
        }
    }, [isReadySari1, isReadySari2, isReadySari3, isReadySari4, isReadySari5, isReadySari6
        , isReadySari7, isReadySari8])

    useEffect(() => {
        if (img1 && img2 && status == "init") {
            //alert("allimageloaded")
            setIsCompleted("Processing")
            setDrapResult()
            GetSaree1()
            GetSaree2()
            GetSaree3()
            GetSaree4()
            GetSaree5()
            GetSaree6()
            GetSaree7()
            GetSaree8()
        }
    }, [img1, img2, status])

    return (
        <></>
    )
}
