import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Container } from '../../base/Container';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import *as productionUnitCrud from '../../_crud/productionUnitCrud'
import *as productProcessingDistributionCrud from '../../_crud/productProcessingDistributionCrud'
import *as contactProcessingAllotmentCrud from '../../_crud/contactProcessingAllotmentCrud'
import { ProductSelectorView } from '../Global/SelectorView/ProductSelectorView';
import { ProductionSelectorView } from '../Global/SelectorView/ProductionSelectorView';
import ProductSelector from '../Global/ProductSelector'
import DatePicker from "react-datepicker";
import { GetEStockType } from '../../enumCollections/EStockType'
import { LoadingButton } from '../../commonLib/LoadingButton';
import AppToaster from '../../commonLib/AppToaster';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import GetCurrentDateForSearch from '../../commonLib/dateFormat';
import GetLoginInfo from '../../auth/_userContext/GetLoginInfo';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

export function AddProcessingDistributionContact() {
    const history = useHistory();
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [processing, setProcessing] = useState([])
    const [production, setProduction] = useState([])
    const [selectedProductData, setSelectedProductData] = useState(undefined);
    const [selectedProductionData, setSelectedProductionData] = useState(undefined);
    const { id } = useParams()
    const userData = GetLoginInfo()

    const initialValues = {
        productId: "",
        contactId: id,
        processingId: "",
        isProductionUnit: 0,
        productionUnitId: "",
        forStock: "1",
        quantity: "",
        unit: "",
        onDate: new Date(GetCurrentDateForSearch(0)),
        note: "",
        isWarehouse: userData.isWarehouse,
        warehouseId: userData.warehouseId,
    }

    const Schema = Yup.object().shape({
        productId: Yup.string().required("Product is required."),
        contactId: Yup.string().required("Contact is required."),
        processingId: Yup.string().required("Processing is required."),
        isProductionUnit: Yup.string().required("Is Production Unit is required."),
        productionUnitId: Yup.string().required("Production Unit is required."),
        forStock: Yup.string().required("For Stock is required."),
        quantity: Yup.string().required("Quantity is required."),
        unit: Yup.string().required("Unit is required."),
        onDate: Yup.string().required("On Date is required.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    function setQuantity() {
        if (selectedProductData) {
            switch (formik.values.forStock) {
                case "1":
                    {
                        if (formik.values.quantity > selectedProductData.rawStock) {
                            formik.setValues({ ...formik.values, quantity: selectedProductData.rawStock })
                        }
                        else if (formik.values.quantity < 0) {
                            formik.setValues({ ...formik.values, quantity: 1 })
                        }
                        break;
                    }
                case "2":
                    {
                        if (formik.values.quantity > selectedProductData.mainStock) {
                            formik.setValues({ ...formik.values, quantity: selectedProductData.mainStock })
                        }
                        else if (formik.values.quantity < 0) {
                            formik.setValues({ ...formik.values, quantity: 1 })
                        }
                        break;
                    }
                case "3":
                    {
                        if (formik.values.quantity > selectedProductData.defectiveStock) {
                            formik.setValues({ ...formik.values, quantity: selectedProductData.defectiveStock })
                        }
                        else if (formik.values.quantity < 0) {
                            formik.setValues({ ...formik.values, quantity: 1 })
                        }
                        break;
                    }
                case "4":
                    {
                        if (formik.values.quantity > selectedProductData.rejectedStock) {
                            formik.setValues({ ...formik.values, quantity: selectedProductData.rejectedStock })
                        }
                        else if (formik.values.quantity < 0) {
                            formik.setValues({ ...formik.values, quantity: 1 })
                        }
                        break;
                    }
            }
        }

    }
    useEffect(() => {
        setQuantity()
    }, [formik.values.forStock, formik.values.quantity]);

    useEffect(() => {

        getProcessing(id)
        getProduction(id)
    }, []);

    useEffect(() => {
        if (selectedProductData) {
            formik.setValues({ ...formik.values, productId: selectedProductData.id, unit: selectedProductData.unit, quantity: 0 })
        }

    }, [selectedProductData]);


    useEffect(() => {
        if (selectedProductionData) {
            formik.setValues({ ...formik.values, productionUnitId: selectedProductionData.id })
        }
    }, [selectedProductionData])

    const OnProductionChange = (e) => {
        const { value } = e.target;
        if (value) {
            production.forEach(element => {
                if (element.id == value) {
                    {
                        setSelectedProductionData(element)
                    }
                }
            });
        }
        else {
            setSelectedProductionData(undefined)
        }
    }

    const Save = (value) => {
        if (value.quantity > 0) {
            setIsLoadingLocal(true)
            productProcessingDistributionCrud.Add(value)
                .then(res => {
                    if (res.data.result.succeeded) {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false);
                        AppToaster('Added successfully.', 'success')
                        formik.setValues({ ...formik.values, quantity: 0 })
                        //history.goBack()
                    }
                    else {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                })
                .catch(error => {
                    setIsLoadingMini(false);
                    setIsLoadingLocal(false);
                    AppToaster(error, 'error')
                })
        }
        else {
            AppToaster("Quantity should greater then zero.", 'error')
        }
    }

    function getProcessing(contactId) {
        setIsLoadingMini(true);
        contactProcessingAllotmentCrud.GetByContactDdl(contactId)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProcessing(hdata);
                    }
                    else {
                        setProcessing([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProcessing([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function getProduction(contactId) {
        setIsLoadingMini(true);
        productionUnitCrud.GetByContactId(contactId)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProduction(hdata);
                    }
                    else {
                        setProduction([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProduction([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    const OnProcessingChange = (e) => {
        const { value } = e.target;
        let ispunit = 0;

        if (value) {
            processing.forEach(element => {
                if (element.processing.id == value) {
                    {
                        if (element.processing.mode == 1)
                            ispunit = 1
                        else
                            ispunit = 0
                    }
                }
            });
        }
        formik.setValues({ ...formik.values, processingId: value, isProductionUnit: ispunit, productionUnitId: (ispunit == 0 ? 1 : "") })
    }

    return (
        <>
            <Container title="Add Processing Distribution" isBack="true" isContact="true">
                <form onSubmit={formik.handleSubmit} class="form-horizontal">
                    <div className="row">

                        <div className="col-md-8">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class=" control-label no-padding-right">Processing</label>
                                    <select className="form-control" name="processingId" value={formik.values.processingId} onChange={OnProcessingChange}>
                                        <option value="" >Select...</option>
                                        {
                                            processing.map((data, index) =>
                                                <option key={data.processing.id} value={data.processing.id}>{data.processing.name.toUpperCase()} ({data.processing.id})</option>
                                            )
                                        }
                                    </select>
                                    {
                                        formik.touched.processingId &&
                                            formik.errors.processingId ? (
                                            <label className="text-danger" >
                                                {formik.errors.processingId}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Date</label>
                                    <span class="input-icon" style={{ width: "100%" }}>
                                        <DatePicker placeholder="onDate" name="onDate" className="form-control input-date-padding" selected={formik.values.onDate} onChange={(date) => formik.setValues({ ...formik.values, onDate: date })} />
                                        <i class="ace-icon fa fa-calendar blue"></i>
                                    </span>
                                    {
                                        formik.touched.onDate &&
                                            formik.errors.onDate ? (
                                            <label className="text-danger" >
                                                {formik.errors.onDate}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {formik.values.isProductionUnit == 1 &&
                            <div className="col-md-6">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <label class="control-label no-padding-right">Production</label>
                                        <select className="form-control" name="productionUnitId" value={formik.values.productionUnitId} onChange={OnProductionChange}>
                                            <option value="" >Select...</option>
                                            {
                                                production.map((data, index) =>
                                                    <option key={data.id} value={data.id}>{data.productionUnitName.toUpperCase()} ({data.id})</option>
                                                )
                                            }
                                        </select>
                                        <ProductionSelectorView data={selectedProductionData} />
                                        {
                                            formik.touched.productionUnitId &&
                                                formik.errors.productionUnitId ? (
                                                <label className="text-danger" >
                                                    {formik.errors.productionUnitId}
                                                </label>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-md-6">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Product</label>
                                    <ProductSelector searchtext="" setSelectedData={setSelectedProductData} isWarehouse={userData.isWarehouse == 1 ? true : false}/>
                                    {
                                        formik.touched.productId &&
                                            formik.errors.productId ? (
                                            <label className="text-danger" >
                                                {formik.errors.productId}
                                            </label>
                                        ) : null
                                    }
                                </div>
                                <ProductSelectorView data={selectedProductData} />
                            </div>
                        </div>
                    </div>

                    {selectedProductData &&
                        <div className="row">
                            <div className="col-md-6">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <label class="control-label no-padding-right">Quantity - {formik.values.unit}</label>
                                        <input className="form-control" type="number" placeholder="Quantity" name="quantity" {...formik.getFieldProps("quantity")} />
                                        {
                                            formik.touched.quantity &&
                                                formik.errors.quantity ? (
                                                <label className="text-danger" >
                                                    {formik.errors.quantity}
                                                </label>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <label class="control-label no-padding-right">For Stock</label>
                                        <select className="form-control" name="forStock" value={formik.values.forStock} onChange={(e) => formik.setValues({ ...formik.values, forStock: e.target.value })}>
                                            {
                                                GetEStockType.EStockType.map((data, index) =>
                                                    <option key={data.Id} value={data.Id}>{data.Name}</option>
                                                )
                                            }
                                        </select>
                                        {
                                            formik.touched.forStock &&
                                                formik.errors.forStock ? (
                                                <label className="text-danger" >
                                                    {formik.errors.forStock}
                                                </label>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="row">
                        <div className="col-xs-12">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Note</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Note" name="note" value={formik.values.note} onChange={(e) => formik.setValues({ ...formik.values, note: e.target.value })} />
                                    {
                                        formik.touched.note &&
                                            formik.errors.note ? (
                                            <label className="text-danger" >
                                                {formik.errors.note}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="clearfix form-actions">
                        <div class="pull-right">
                            {isLoadingLocal ?
                                <LoadingButton />
                                :
                                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Add
                                </button>}
                        </div>
                    </div>
                </form>
            </Container >
        </>
    )
}

