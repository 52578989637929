import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Container } from '../../base/Container'
import AppToaster from '../../commonLib/AppToaster'
import { Paging } from '../../commonLib/Paging'
import { Spinner } from '../../commonLib/Spinner'
import *as sareeDrapingCrud from '../../_crud/sareeDrapingCrud'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext'
import Moment from 'react-moment';
import 'moment-timezone';
import { EmptyData } from '../../commonLib/EmptyData'
import { UserView } from '../Global/Elements/UserView'
import GetLoginInfo from '../../auth/_userContext/GetLoginInfo'
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater'
import { DrapingViewRow } from '../Global/Elements/DrapingViewRow'
import { MyAlert } from '../../commonLib/MyAlert'
import ScrollArea from 'react-scrollbar'
import { useFormik } from 'formik'
import * as Yup from "yup";
import { DrapingBalance } from './DrapingBalance'
import { PAGING_PAGE_SIZE } from '../../config/GlobalSettings'

const initValues = {
    SearchTerm: "",
    pageNo: 1,
    pageSize: PAGING_PAGE_SIZE
}

const initialValues = {
    catalogueId: undefined,
    catalogueName: "",
    catalogueCode: "",
    note: ""
}

export function ManageSareeDraping() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [search, setSearch] = useState(initValues)
    const [drapingCatalogue, setDrapingCatalogue] = useState([])
    const [catalogueItems, setCatalogueItems] = useState([])
    const [LastPage, setLastPage] = useState(1)
    const [pagingData, setPagingData] = useState()
    const userData = GetLoginInfo()
    const history = useHistory();

    const Schema = Yup.object().shape({
        catalogueName: Yup.string().min(3, "Name must between 3 to 50 characters").max(50, "Name must between 3 to 50 characters").required("Name is required."),
        catalogueCode: Yup.string().min(1, "Code must between 1 to 15 characters").max(15, "Code must between 1 to 15 characters").required("Code is required."),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            SaveCatalogue(values);
        },
        onReset: (values) => {
        },
    });

    useEffect(() => {
        onSearch()
    }, [search.pageNo])

    const OnPageChange = (e) => {
        const { value } = e.target
        setSearch({ ...search, pageNo: value });
    }

    function onSearch() {
        getData()
    }

    const SaveCatalogue = (value) => {
        setIsLoadingLocal(true)

        let param = { Catalogue: { ...value }, Items: [...catalogueItems] }
        sareeDrapingCrud.AddCatalogue(param)
            .then(res => {
                if (res.data.result.succeeded) {
                    setIsLoadingLocal(false);
                    AppToaster('Added successfully.', 'success')
                    history.push("/catalogue/dashboard/" + res.data.data.id)
                }
                else {
                    setIsLoadingLocal(false);
                    //AppToaster("Server Error /Invalid Try.", 'error')
                    AppToaster(res.data.result.message, 'error')
                }
            })
            .catch(error => {
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    const UpdateCatalogue = () => {
        setIsLoadingLocal(true)

        let param = { catalogueId: formik.values.catalogueId, Items: [...catalogueItems] }
        sareeDrapingCrud.AddItemsExistingCatalogue(param)
            .then(res => {
                if (res.data.result.succeeded) {
                    setIsLoadingLocal(false);
                    AppToaster('Updated successfully.', 'success')
                    history.push("/catalogue/dashboard/" + res.data.data.id)
                }
                else {
                    setIsLoadingLocal(false);
                    //AppToaster("Server Error /Invalid Try.", 'error')
                    AppToaster(res.data.result.message, 'error')
                }
            })
            .catch(error => {
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    function getData() {
        setIsLoadingMini(true);
        sareeDrapingCrud.GetAll(search)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setDrapingCatalogue(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoadingMini(false)
                        setDrapingCatalogue([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoadingMini(false)
                    setDrapingCatalogue([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                    //AppToaster("Server Error /Invalid Try." + res.data.result.message, 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        sareeDrapingCrud.Remove(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    AppToaster('Deleted successfully.', 'success')
                    getData()
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by some catalogue, could not delete.', 'error')
                }

            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }


    function doRefresh(event) {
        setSearch({ ...search, pageNo: 1 })
        onSearch()
        setTimeout(() => {
            event.detail.complete();
        }, 1000);
    }

    function SearchChange(e) {
        const value = e.target.value
        setSearch({ ...search, SearchTerm: value })
    }


    const AddItemCat = (drapingItem) => {

        let flag = false;
        catalogueItems.forEach(element => {
            if (element.sareeDrapingId == drapingItem.id) {
                flag = true;
                return;
            }
        });
        if (flag) {
            AppToaster("Selected draping is already in the list.", "error")
        }
        else {
            setCatalogueItems([
                ...catalogueItems,
                {
                    calalogueId: 0,
                    sareeDrapingId: drapingItem.id,
                    draping: drapingItem
                }
            ])
            AppToaster("Item added.", "success")
        }
    }
    const DeleteItemCat = (xid) => {
        const sitem = catalogueItems
        sitem.forEach((item, index) => {
            if (item.sareeDrapingId == xid) {
                sitem.splice(index, 1)
            }
        })
        setCatalogueItems([...sitem])
        AppToaster("Item removed.", 'error')
    }


    return (
        <>
            <Container title="Saree Drapings" doRefresh={doRefresh}>
                <Link to="/draping/new" class="btn btn-sm btn-success ">
                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                    New
                </Link>
                <div class="hr dotted"></div>
                <div className='col-md-8' style={{ marginLeft: "-12px" }}>

                    <div class="form-horizontal" >
                        <div className="row">
                            <div className="col-md-11">
                                <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                    <span class="input-icon" style={{ width: "100%" }}>
                                        <TextBoxTranslater style={{ paddingLeft: "24px" }} value={search.SearchTerm} onChange={SearchChange} className="form-control" type="text" placeholder="Search Drapings.." />
                                        <i class="ace-icon fa fa-search blue"></i>
                                    </span>
                                </div>
                            </div>

                            <div className="col-md-1">
                                <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                    <button onClick={onSearch} class="btn btn-sm btn-info pull-right">
                                        <i class="ace-icon fa fa-search bigger-110"></i>
                                        Run
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <DrapingBalance />

                    <Paging
                        pagingData={pagingData}
                        LastPage={LastPage}
                        CurrentPage={search.pageNo}
                        OnPageChange={OnPageChange} >
                        {isLoadingMini ?
                            <Spinner />
                            :
                            drapingCatalogue && drapingCatalogue.length > 0 ?
                                <div className="tables-scroll">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "43px" }}></th>
                                                <th>#Id</th>
                                                <th></th>
                                                <th>Drapings</th>
                                                <th>Code</th>
                                                <th>Name</th>
                                                <th>Note</th>
                                                <th>On Date</th>
                                                <th style={{ width: "43px" }}>By</th>
                                                <th style={{ width: "43px" }}></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {drapingCatalogue.map((item, index) =>
                                                <tr key={item.id}>
                                                    <td>
                                                        <div class="btn-group">
                                                            <Link className="btn btn-xs btn-info" to={"/draping/dashboard/" + item.id} title="View dashboard" ><i class="ace-icon fa fa-tachometer"></i></Link>
                                                        </div>
                                                    </td>
                                                    <td>{item.id}</td>
                                                    <td>
                                                        <div class="btn-group">
                                                            <button onClick={() => AddItemCat(item)} className='btn btn-xs' title='Sent to catalague items'><i class="ace-icon fa fa-arrow-right"></i></button>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div style={{ width: "88px" }}>
                                                            <DrapingViewRow url={item.drapingFrontUrl} uid={"IMG1" + item.id} />
                                                            <DrapingViewRow url={item.drapingBackUrl} uid={"IMG2" + item.id} />
                                                        </div>
                                                    </td>

                                                    <td>{item.itemCode}</td>
                                                    <td>
                                                        <div style={{ minWidth: "200px" }}>{item.itemName}
                                                        </div>
                                                    </td>
                                                    <td><div style={{ minWidth: "150px" }}>{item.note}</div></td>
                                                    <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.createdOn}</Moment></div></td>
                                                    <td>
                                                        <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                    </td>
                                                    <td>
                                                        <div style={{ width: "73px" }}>
                                                            <div class="btn-group">

                                                                <Link to={"/draping/edit/" + item.id} class="btn btn-xs btn-success">
                                                                    <i class="ace-icon fa fa-pencil bigger-120"></i>
                                                                </Link>

                                                                <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                                    <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                                </MyAlert>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <EmptyData>
                                    No records found.
                                    <br />
                                    <br />
                                </EmptyData>
                        }
                    </Paging>
                </div>

                <div className='col-md-4' style={{ marginLeft: "-12px" }}>
                    <div className="widget-box">
                        <div class="widget-header widget-header-flat widget-header-small">
                            <h5 class="widget-title">
                                Catalogue Items ({catalogueItems.length} draping(s) selected)
                            </h5>
                        </div>
                        <ScrollArea
                            speed={0.8}
                            style={{ height: "300px" }}
                            smoothScrolling={true}
                            horizontal={false}
                        >
                            {catalogueItems && catalogueItems.length > 0 ?
                                <div className="tables-scroll">
                                    <table class="table table-bordered table-striped">
                                        <thead class="thin-border-bottom">
                                            <tr>
                                                <th></th>
                                                <th>
                                                    Id
                                                </th>

                                                <th>
                                                    Draping
                                                </th>
                                                <th>
                                                    Code
                                                </th>
                                                <th>
                                                    Name
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {catalogueItems.map((item, index) =>
                                                <tr key={item.id}>
                                                    <td>
                                                        <div style={{ width: "43px" }}>
                                                            <div class="btn-group">
                                                                <MyAlert confirmBtnText="Yes, delete it!" title="Delete from catalogue items" onConfirm={DeleteItemCat} param={item.sareeDrapingId}>
                                                                    <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                                </MyAlert>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{item.draping.id}</td>
                                                    <td>
                                                        <div style={{ width: "88px" }}>
                                                            <DrapingViewRow url={item.draping.drapingFrontUrl} uid={"IMGX1" + item.draping.id} />
                                                            <DrapingViewRow url={item.draping.drapingBackUrl} uid={"IMGX2" + item.draping.id} />
                                                        </div>
                                                    </td>

                                                    <td>{item.draping.itemCode}</td>
                                                    <td>
                                                        <div style={{ width: "200px" }}>{item.draping.itemName}
                                                        </div>
                                                    </td>


                                                </tr>
                                            )}

                                        </tbody>
                                    </table>
                                </div>
                                :
                                <EmptyData>
                                    <br />
                                    <br />
                                    No item selected <br />for catalogue.
                                    <br />
                                    <br />
                                </EmptyData>
                            }

                        </ScrollArea>
                    </div>

                    <div class="widget-box">
                        <div class="widget-header">
                            <h5 class="widget-title lighter smaller">
                                <i class="ace-icon fa fa-universal-access"></i>Catalogue
                            </h5>

                            <div class="widget-toolbar no-border">
                                <ul class="nav nav-tabs" id="recent-tab">
                                    <li class="active">
                                        <a data-toggle="tab" href="#new-tab" aria-expanded="true">New Catalogue</a>
                                    </li>

                                    <li class="">
                                        <a data-toggle="tab" href="#edit-tab" aria-expanded="false">Add To Existing</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="widget-body">
                            <div class="widget-main padding-4">
                                <div class="tab-content padding-8">
                                    <div id="new-tab" class="tab-pane active">

                                        <form onSubmit={formik.handleSubmit} class="form-horizontal">
                                            <div className="row">

                                                <div className="col-md-7">
                                                    <div class="form-group">
                                                        <div class="col-xs-12">
                                                            <label class="control-label no-padding-right">Catalogue Name</label>
                                                            <TextBoxTranslater className="form-control" type="text" placeholder="Catalogue Name" name="catalogueName" value={formik.values.catalogueName} onChange={(e) => formik.setValues({ ...formik.values, catalogueName: e.target.value })} />
                                                            {
                                                                formik.touched.catalogueName &&
                                                                    formik.errors.catalogueName ? (
                                                                    <label className="text-danger" >
                                                                        {formik.errors.catalogueName}
                                                                    </label>
                                                                ) : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div class="form-group">
                                                        <div class="col-xs-12">
                                                            <label class="control-label no-padding-right">Catalogue Code</label>
                                                            <TextBoxTranslater className="form-control" type="text" placeholder="Catalogue Code" name="catalogueCode" value={formik.values.catalogueCode} onChange={(e) => formik.setValues({ ...formik.values, catalogueCode: e.target.value })} />
                                                            {
                                                                formik.touched.catalogueCode &&
                                                                    formik.errors.catalogueCode ? (
                                                                    <label className="text-danger" >
                                                                        {formik.errors.catalogueCode}
                                                                    </label>
                                                                ) : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-xs-12">
                                                    <div class="form-group">
                                                        <div class="col-xs-12">
                                                            <label class="control-label no-padding-right">Description</label>
                                                            <TextBoxTranslater className="form-control" type="text" placeholder="Description" name="note" value={formik.values.note} onChange={(e) => formik.setValues({ ...formik.values, note: e.target.value })} />
                                                            {
                                                                formik.touched.note &&
                                                                    formik.errors.note ? (
                                                                    <label className="text-danger" >
                                                                        {formik.errors.note}
                                                                    </label>
                                                                ) : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="clearfix form-actions">
                                                <div class="pull-right">
                                                    {isLoadingLocal ?
                                                        <button class="btn btn-info" expand="block" disabled>
                                                            <i class="ace-icon fa fa-check bigger-110"></i>
                                                            Processing...
                                                        </button>

                                                        :
                                                        <>
                                                            <button class="btn btn-info" disabled={!formik.isValid || catalogueItems.length < 1}>
                                                                <i class="ace-icon fa fa-check bigger-110"></i>
                                                                Create Catalogue
                                                            </button>
                                                        </>
                                                    }
                                                </div>
                                            </div>

                                        </form>

                                    </div>

                                    <div id="edit-tab" class="tab-pane">
                                        <div class="form-horizontal">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div class="form-group">
                                                        <div class="col-xs-12">
                                                            <label class="control-label no-padding-right">Catalogue Id</label>
                                                            <input className="form-control" type="text" placeholder="Catalogue Id" name="catalogueId" value={formik.values.catalogueId} onChange={(e) => formik.setValues({ ...formik.values, catalogueId: e.target.value })} />
                                                            {
                                                                formik.touched.catalogueId &&
                                                                    formik.errors.catalogueId ? (
                                                                    <label className="text-danger" >
                                                                        {formik.errors.catalogueId}
                                                                    </label>
                                                                ) : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="clearfix form-actions">
                                                <div class="pull-right">
                                                    {isLoadingLocal ?
                                                        <button class="btn btn-info" expand="block" disabled>
                                                            <i class="ace-icon fa fa-check bigger-110"></i>
                                                            Processing...
                                                        </button>

                                                        :
                                                        <>
                                                            <button onClick={UpdateCatalogue} class="btn btn-info" disabled={formik.values.catalogueId == 0 || !formik.values.catalogueId || catalogueItems.length < 1}>
                                                                <i class="ace-icon fa fa-check bigger-110"></i>
                                                                Update Catalogue
                                                            </button>
                                                        </>
                                                    }
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container >
        </>
    )
}
