import React, { useContext, useEffect, useState } from 'react'
import Autocomplete from "react-autocomplete";
import './../Global/AutoComplete.css';
import * as contactCrud from '../../_crud/contactCrud'
import useDebounce from './useDebounce';
import { BASE_URL } from '../../initApi/apiUrls';
import { DEFAULT_IMG, DEFAULT_USER_IMG, ERROR_IMG } from '../../config/GlobalSettings';
import GetLoginInfo from '../../auth/_userContext/GetLoginInfo';
import { WarehouseViewS } from './Elements/WarehouseViewS';

export default function ContactSelector({ searchtext, setSelectedData, isWarehouse = false }) {
    const [isLocalLoading, setIsLocalLoading] = useState(false)
    const [isSelected, setIsSelected] = useState(false)
    const [searchTerm, setSearchTerm] = useState(searchtext);
    const [searchDebounce, setSearchDebounce] = useDebounce(searchtext, 500);
    const [data, setData] = useState([]);
    const userData = GetLoginInfo()

    useEffect(() => {
        setSearchDebounce(searchTerm)
    }, [searchTerm]);
    useEffect(() => {
        if (!isSelected) {
            if (isWarehouse)
                getDataWarehouse()
            else
                getData()
        }
        else {
            setIsSelected(false)
        }
    }, [searchDebounce]);

    function getData() {
        setIsLocalLoading(true);
        let a;
        a = searchTerm;
        if (!searchTerm) { a = '' }
        contactCrud.GetAll({ searchTerm: a, pageNo: 1, pageSize: 10 })
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setData(hdata);
                        setIsLocalLoading(false)
                    }
                    else {
                        setIsLocalLoading(false)
                        setData([]);
                    }
                }
                else {
                    setIsLocalLoading(false)
                    setData([]);
                }
            })
            .catch(error => {
                setIsLocalLoading(false);
            })
    }
    function getDataWarehouse() {
        setIsLocalLoading(true);
        let a;
        a = searchTerm;
        if (!searchTerm) { a = '' }
        contactCrud.GetAllWarehouse({ Search: { searchTerm: a, pageNo: 1, pageSize: 10 }, WarehouseId: userData.warehouseId })
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setData(hdata);
                        setIsLocalLoading(false)
                    }
                    else {
                        setIsLocalLoading(false)
                        setData([]);
                    }
                }
                else {
                    setIsLocalLoading(false)
                    setData([]);
                }
            })
            .catch(error => {
                setIsLocalLoading(false);
            })
    }

    function selectId(e) {
        setSearchTerm(e.split('|')[0])
        let sid = e.split('|')[1]
        setIsSelected(true)
        data.forEach(d => {
            if (d.id == sid) {
                setSelectedData(d);
            }
        });
    }
    return (
        <span class="input-icon" style={{ width: "100%" }}>
            <div className="autocomplete-wrapper">
                <Autocomplete
                    inputProps={{ placeholder: "Type to search contact" }}
                    value={searchTerm}
                    items={data}
                    getItemValue={item => item.fullName + "|" + item.id}
                    renderMenu={item => (
                        <div className="dropdown">
                            {item}
                        </div>
                    )}
                    renderItem={(item, isHighlighted) =>
                        <div class={isHighlighted ? "profile-activity clearfix MyItem-selected-item" : "profile-activity clearfix"} style={{ cursor: "pointer" }}>
                            <div>
                                <img style={{ objectFit: "cover" }} height="40px" width="40px" className="pull-left" src={item.isPhoto == 1 ? (BASE_URL + "/" + item.photoUrl) : DEFAULT_USER_IMG}
                                    onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
                                <a class="user">{item.fullName}</a> <small>({item.productionCategory ? item.productionCategory.productionCatName + "-" : ""}{item.refId ? item.refId : item.id})</small>
                                <br /><small><WarehouseViewS isOnlyCode={true} item={item.warehouse} /><span> | {item.address}</span></small>
                            </div>
                        </div>
                    }
                    onChange={(event, val) => setSearchTerm(val)}
                    onSelect={val => selectId(val)}

                />
            </div>
            {isLocalLoading ?
                <i class="ace-icon fa fa-spinner fa-spin blue"></i>
                :
                <i class="ace-icon fa fa-group blue"></i>
            }
        </span>
    )
}
