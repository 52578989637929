export const DEFAULT_DP = '../Images/Defaults/dp.jpg';
//Server
export const BASE_URL_API = 'https://api.app.baithka.com/api/';
export const BASE_URL = "https://api.app.baithka.com";
export const IsUat = false;
export const IsMobile = false;

//Uat
// export const BASE_URL_API = 'https://uat-api.app.baithka.com/api/';
// export const BASE_URL = "https://uat-api.app.baithka.com";
// export const IsUat = true;
// export const IsMobile = true;

//Local server
// export const BASE_URL_API = 'https://localhost:44377/api/';
// export const BASE_URL = "https://localhost:44377";
// export const IsUat = true;
// export const IsMobile = true;

//Mobile App
// export const BASE_URL_API = 'https://api.app.baithka.com/api/';
// export const BASE_URL = "https://api.app.baithka.com";
// export const IsUat = false;
// export const IsMobile = true;