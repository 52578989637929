import React, { useState, useEffect } from "react";
import { getCanvas } from "react-lasso-select";
import mergeImages from 'merge-images';
import rotate from 'base64-rotate'
import { swirl, bulgePinch } from "./ImageEditFunction";
import { PoseBg } from "./PoseBg";

export function Saree2Pose({ img1, img2, bg, setIsCompleted, setDrapResult, status }) {

    const [isReadySari1, setIsReadySari1] = useState(false);
    const [isReadySari2, setIsReadySari2] = useState(false);
    const [isReadySari3, setIsReadySari3] = useState(false);
    const [isReadySari4, setIsReadySari4] = useState(false);
    const [isReadySari5, setIsReadySari5] = useState(false);
    const [isReadySari6, setIsReadySari6] = useState(false);
    const [isReadySari7, setIsReadySari7] = useState(false);
    const [isReadySari8, setIsReadySari8] = useState(false);
    const [isReadySari9, setIsReadySari9] = useState(false);
    const [isReadySari10, setIsReadySari10] = useState(false);

    //#region Saree1
    const MergeSaree = () => {
        try {
            let cbg = PoseBg(parseInt(bg))
            //alert(cbg)
            mergeImages([
                { src: cbg, x: 0, y: 0 },
                { src: saree1, x: 110, y: 1738 },
                { src: saree2, x: 358, y: 1856 },
                { src: saree3, x: 480, y: 2244 },
                { src: saree4, x: 230, y: 1086 },

                { src: saree10, x: 173, y: 523 },
                { src: saree9, x: 172, y: 551 },

                { src: saree8, x: 602, y: 755 },
                { src: saree5, x: 289, y: 545 },
                { src: saree7, x: 625, y: 787 },
                { src: saree6, x: 413, y: 1030 },

                { src: './assets/images/Draper/new/4.png', x: 0, y: 0 },
                { src: './assets/images/Draper/new/4abody.png', x: 0, y: 0 },
            ])
                .then(b64 => {
                    setDrapResult(b64)
                    //alert("sareedrap")
                    setIsCompleted("completed")
                }).catch(e => {
                    setDrapResult()
                    setIsCompleted("failed")
                    alert(e)
                });
        } catch (e) {
            setDrapResult()
            setIsCompleted("failed")
            alert(e)
        }
    }
    ///Saree item 1
    const [saree1, setSaree1] = useState("");
    const GetSaree1 = () => {
        setIsReadySari1(false);
        bulgePinch(img1, 350, 1500, 206, -0.50).then(newsrc1 => {
            swirl(newsrc1, 350, 1300, 500, -0.7).then(swirl1 => {
                rotate(swirl1, 38).then(newsrc => {
                    let initPP = "265,1427 294,1366 313,1325 335,1267 345,1251 351,1216 361,1178 373,1121 386,1060 396,1028 402,993 648,575 670,533 693,575 693,744 677,856 658,1002 638,1152 619,1328 613,1456 600,1529 546,1619 511,1638 501,1635 479,1622 453,1603 418,1583 386,1564 370,1552 345,1526 319,1500 287,1459"
                        .split(" ")
                        .map((c) => c.split(",").map(Number))
                        .map(([x, y]) => ({ x, y }));
                    getCanvas(newsrc, initPP, (err, canvas) => {
                        if (!err) {
                            setSaree1(canvas.toDataURL());
                            setIsReadySari1(true)
                        }
                    });
                }).catch(err => {
                })
            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///Saree item 2
    const [saree2, setSaree2] = useState("");
    const GetSaree2 = () => {
        setIsReadySari2(false)
        rotate(img1, 10).then(newsrc => {
            let initPP = "240,1524 176,1564 176,1594 143,1594 112,1594 82,1588 58,1585 46,1582 67,1543 79,1503 103,1464 109,1449 109,1418 112,1370 121,1306 131,1239 137,1187 146,1108 158,1002 173,911 194,783 206,695 216,656 234,598 258,790 285,1020 279,1102 267,1254 258,1394"
                .split(" ")
                .map((c) => c.split(",").map(Number))
                .map(([x, y]) => ({ x, y }));

            getCanvas(newsrc, initPP, (err, canvas) => {
                if (!err) {
                    setSaree2(canvas.toDataURL());
                    setIsReadySari2(true)
                }
            });
        }).catch(err => {
        })
    }
    ///Saree item 3
    const [saree3, setSaree3] = useState("");
    const GetSaree3 = () => {
        setIsReadySari3(false)
        swirl(img1, 150, 1300, 500, -0.7).then(newsrc => {
            let initPP = "146,1589 157,1544 174,1491 188,1443 199,1404 213,1370 210,1345 210,1306 213,1269 216,1211 221,1140 224,1093 227,1037 230,1009 272,1034 308,1202 367,1499 364,1555 347,1566 336,1569 314,1564 302,1575 291,1580 277,1583 272,1583 255,1597 244,1600 221,1597 196,1597 174,1597 165,1597"
                .split(" ")
                .map((c) => c.split(",").map(Number))
                .map(([x, y]) => ({ x, y }));

            getCanvas(newsrc, initPP, (err, canvas) => {
                if (!err) {
                    setSaree3(canvas.toDataURL());
                    setIsReadySari3(true)
                }
            });
        }).catch(err => {
        })
    }
    ///Saree item 4
    const [saree4, setSaree4] = useState("");
    const GetSaree4 = () => {
        setIsReadySari4(false)
        bulgePinch(img1, 200, 1300, 600, -0.40).then(bul => {
            rotate(bul, 179).then(newsrc1 => {
                rotate(newsrc1, 119).then(newsrc => {
                    let initPP = "1474,3142 1489,3105 1496,3081 1520,3035 1557,2986 1605,2910 1666,2821 1700,2766 1734,2696 1752,2662 1779,2620 1782,2556 1785,2482 1767,2351 1727,2320 1673,2229 1599,2098 1526,2015 1508,1982 1502,2049 1492,2082 1489,2113 1486,2152 1480,2207 1477,2256 1477,2296 1474,2348 1468,2415 1465,2482 1465,2546 1465,2556 1480,2586 1477,2595 1477,2626 1477,2653 1480,2684 1477,2699 1474,2723 1474,2751 1477,2803 1480,2849 1486,2867 1480,2907 1477,2992 1477,3056 1477,3108 1474,3126"
                        .split(" ")
                        .map((c) => c.split(",").map(Number))
                        .map(([x, y]) => ({ x, y }));

                    getCanvas(newsrc, initPP, (err, canvas) => {
                        if (!err) {
                            setSaree4(canvas.toDataURL());
                            setIsReadySari4(true)
                        }
                    });
                }).catch(err => {
                })
            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///Saree item 5
    const [saree5, setSaree5] = useState("");
    const GetSaree5 = () => {
        setIsReadySari5(false)
        swirl(img1, 200, 250, 600, -0.5).then(bul => {
            rotate(bul, 179).then(newsrc1 => {
                rotate(newsrc1, 162).then(newsrc => {
                    let initPP = "400,1335 333,1338 291,1262 246,1178 230,1108 237,1005 259,945 275,954 272,919 275,903 285,877 320,855 355,832 403,816 477,784 586,743 653,711 682,695 694,692 714,695 736,704 762,720 778,740 794,765 797,781 803,832 810,900 816,945 787,1018 752,1063 669,1159 595,1220 467,1294"
                        .split(" ")
                        .map((c) => c.split(",").map(Number))
                        .map(([x, y]) => ({ x, y }));

                    getCanvas(newsrc, initPP, (err, canvas) => {
                        if (!err) {
                            setSaree5(canvas.toDataURL());
                            setIsReadySari5(true)
                        }
                    });

                }).catch(err => {
                })
            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///Saree item 6
    const [saree6, setSaree6] = useState("");
    const GetSaree6 = () => {
        setIsReadySari6(false)
        //bulgePinch(img1, 2700, 1500, 222, -1.1).then(bul => {
        swirl(img1, 2550, 1250, 384, 0.8).then(swr => {
            swirl(swr, 800, 1200, 1084, 0.6).then(swr1 => {
                rotate(swr1, 80).then(newsrc => {
                    let initPP = "666,2983 609,2947 579,2929 556,2905 529,2882 502,2855 481,2840 455,2792 443,2736 428,2674 422,2638 413,2596 401,2543 398,2501 386,2445 374,2379 354,2296 348,2237 333,2133 321,2047 312,1964 306,1901 306,1842 306,1773 312,1705 315,1655 312,1598 309,1557 300,1503 288,1444 267,1366 244,1292 229,1236 214,1194 208,1191 205,1170 247,1153 276,1135 309,1117 357,1099 386,1090 413,1069 443,1063 478,1072 514,1105 544,1144 579,1203 603,1274 624,1355 636,1441 657,1652 666,1711 671,1768 683,1842 704,1910 728,2017 761,2142 775,2192 799,2270 826,2371 844,2433 903,2623 915,2656 945,2691 1007,2739 972,2769 850,2855 752,2929"
                        .split(" ")
                        .map((c) => c.split(",").map(Number))
                        .map(([x, y]) => ({ x, y }));

                    getCanvas(newsrc, initPP, (err, canvas) => {
                        if (!err) {
                            setSaree6(canvas.toDataURL());
                            setIsReadySari6(true)
                        }
                    });
                }).catch(err => {
                })
            }).catch(err => {
            })
        }).catch(err => {
        })
        // }).catch(err => {
        // })
    }
    ///Saree item 7
    const [saree7, setSaree7] = useState("");
    const GetSaree7 = () => {
        setIsReadySari7(false)
        swirl(img1, 1690, 400, 1400, -1).then(swr => {
            swirl(swr, 2600, 1600, 1084, 0.6).then(swr1 => {
                rotate(swr1, 40).then(newsrc => {
                    let initPP = "2312,2629 2233,2553 2129,2331 2026,1962 1982,1676 1947,1410 1895,1140 1819,989 1748,945 1764,929 1803,921 1823,913 1855,886 1879,858 1899,822 1931,774 1950,743 1970,715 1998,687 2006,715 2046,786 2086,850 2145,949 2229,1088 2332,1239 2451,1390 2503,1453 2586,1529 2646,1577 2729,1628 2773,1656 2852,1688 2940,1723 2995,1747 3023,1767 3047,1787 3007,1839 2971,1886 2928,1942 2876,2013 2832,2073 2741,2192 2677,2275 2606,2367 2542,2438 2487,2494 2407,2557 2340,2609"
                        .split(" ")
                        .map((c) => c.split(",").map(Number))
                        .map(([x, y]) => ({ x, y }));
                    getCanvas(newsrc, initPP, (err, canvas) => {
                        if (!err) {
                            setSaree7(canvas.toDataURL());
                            setIsReadySari7(true)
                        }
                    });
                }).catch(err => {
                })
            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///Saree item 8
    const [saree8, setSaree8] = useState("");
    const GetSaree8 = () => {
        setIsReadySari8(false)
        rotate(img1, 40).then(newsrc => {
            let initPP = "1185,923 1128,830 1060,725 1023,663 981,574 961,537 887,642 840,720 767,798 710,856 809,913 955,965 1075,1002 1154,986"
                .split(" ")
                .map((c) => c.split(",").map(Number))
                .map(([x, y]) => ({ x, y }));

            getCanvas(newsrc, initPP, (err, canvas) => {
                if (!err) {
                    setSaree8(canvas.toDataURL());
                    setIsReadySari8(true)
                }
            });
        }).catch(err => {
        })
    }
    ///Saree item 9
    const [saree9, setSaree9] = useState("");
    const GetSaree9 = () => {
        setIsReadySari9(false)
        swirl(img2, 200, 1500, 222, -0.7).then(swr => {
            rotate(swr, 20).then(newsrc => {
                let initPP = "201,1555 172,1542 131,1526 90,1518 62,1501 45,1497 37,1501 49,1469 53,1456 66,1427 82,1395 103,1354 123,1321 144,1300 172,1321 238,1403 271,1456 242,1514"
                    .split(" ")
                    .map((c) => c.split(",").map(Number))
                    .map(([x, y]) => ({ x, y }));

                getCanvas(newsrc, initPP, (err, canvas) => {
                    if (!err) {
                        setSaree9(canvas.toDataURL());
                        setIsReadySari9(true)
                    }
                });
            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///Saree item 10
    const [saree10, setSaree10] = useState("");
    const GetSaree10 = () => {
        setIsReadySari10(false)
        rotate(img2, 35).then(newsrc => {
            let initPP = "254,1328 218,1302 205,1284 205,1275 214,1271 232,1217 259,1168 290,1097 308,1074 330,1057 357,1043 450,1123 473,1146 481,1164 513,1186 526,1213 455,1230 415,1235 357,1217 267,1253 250,1288"
                .split(" ")
                .map((c) => c.split(",").map(Number))
                .map(([x, y]) => ({ x, y }));

            getCanvas(newsrc, initPP, (err, canvas) => {
                if (!err) {
                    setSaree10(canvas.toDataURL());
                    setIsReadySari10(true)
                }
            });
        }).catch(err => {
        })

    }
    ///////////////////////////////////////////
    //#endregion
    useEffect(() => {
        if (isReadySari1 && isReadySari2 && isReadySari3 && isReadySari4 && isReadySari5 && isReadySari6
            && isReadySari7 && isReadySari8 && isReadySari9 && isReadySari10) {
            MergeSaree()
            //alert("MargeCall")
            setIsCompleted("Merging Images")
            setIsReadySari1(false)
            setIsReadySari2(false)
            setIsReadySari3(false)
            setIsReadySari4(false)
            setIsReadySari5(false)
            setIsReadySari6(false)
            setIsReadySari7(false)
            setIsReadySari8(false)
            setIsReadySari9(false)
            setIsReadySari10(false)
        }
    }, [isReadySari1, isReadySari2, isReadySari3, isReadySari4, isReadySari5, isReadySari6
        , isReadySari7, isReadySari8, isReadySari9, isReadySari10])

    useEffect(() => {
        if (img1 && img2 && status == "init") {
            setIsCompleted("Processing")
            setDrapResult()
            GetSaree1()
            GetSaree2()
            GetSaree3()
            GetSaree4()
            GetSaree5()
            GetSaree6()
            GetSaree7()
            GetSaree8()
            GetSaree9()
            GetSaree10()
        }
    }, [img1, img2, status])

    return (
        <></>
    )
}
