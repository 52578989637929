import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import { GetEProductionUnitOwnership } from '../../enumCollections/EProductionUnitOwnership'
import * as productionUnitCrud from '../../_crud/productionUnitCrud'
import * as productionCategoryCrud from '../../_crud/productionCategoryCrud'
import * as productionConfigurationCrud from '../../_crud/productionConfigurationCrud'
import * as Yup from 'yup'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { useFormik } from 'formik';
import { LoadingButton } from '../../commonLib/LoadingButton';
import { loadingContext } from '../../commonLib/_lodingContext';
import { dashboardContext } from '../Global/dashboardContext';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';


export function EditProductionUnit() {

    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [productionCategory, setProductionCategory] = useState([])
    const { id } = useParams();
    const [productionConfiguration, setProductionConfiguration] = useState([])
    const [dashboardData, setDashboardData] = useContext(dashboardContext)

    const initialValues = {
        id: undefined,
        productionConfigurationId: dashboardData.productiondata ? (dashboardData.productiondata.productionConfigurationId ? dashboardData.productiondata.productionConfigurationId : "") : "",
        productionCatId: 0,
        ownerShip: undefined,
        details: undefined,
        isMultiProduct: undefined,
        unitName: undefined
    }
    const Schema = Yup.object().shape({
        productionCatId: Yup.string().required("Category is required."),
        ownerShip: Yup.string().required("OwnerShip is required."),
        isMultiProduct: Yup.string().required("Multi Product is required."),
        unitName: Yup.string().min(3, "Unit Name must between 3 to 20 characters").max(20, "Unit Name must between 3 to 20 characters").required("Unit Name is required."),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });
    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true);
        productionUnitCrud.Edit(value)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false);
                    setIsLoadingLocal(false);
                    AppToaster('Updated successfully.', 'success')
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    useEffect(() => {
        getCategory()
        getProductionUnit()
        getProductConfiguration()
    }, []);

    function getCategory() {
        setIsLoadingMini(true);
        productionCategoryCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProductionCategory(hdata);
                    }
                    else {
                        setProductionCategory([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProductionCategory([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function getProductConfiguration() {
        setIsLoadingMini(true);
        productionConfigurationCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProductionConfiguration(hdata)
                    }
                    else {
                        setProductionConfiguration([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProductionConfiguration([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }
    function getProductionUnit() {
        setIsLoading(true);
        productionUnitCrud.GetById(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        formik.setValues({
                            id: hdata.id,
                            productionCatId: hdata.productionCatId,
                            ownerShip: hdata.ownerShip,
                            details: hdata.details,
                            isMultiProduct: hdata.isMultiProduct,
                            unitName: hdata.productionUnitName,
                            productionConfigurationId: hdata.productionConfigurationId
                        })
                    }
                    else {
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                AppToaster(error, 'error')
            })
    }

    return (
        <Container title="Edit Production Unit" isBack="true" isProduction="true" isContact="true">
            <form onSubmit={formik.handleSubmit} class="form-horizontal">
                <div className="row">
                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Production Category</label>
                                <select className="form-control" name="productionCatId" value={formik.values.productionCatId} onChange={(e) => formik.setValues({ ...formik.values, productionCatId: e.target.value })}>
                                    <option value="" >Select...</option>
                                    {
                                        productionCategory.map((data, index) =>
                                            <option key={data.id} value={data.id}>{data.productionCatName.toUpperCase()}</option>
                                        )
                                    }
                                </select>
                                {
                                    formik.touched.productionCatId &&
                                        formik.errors.productionCatId ? (
                                        <label className="text-danger" >
                                            {formik.errors.productionCatId}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Ownership</label>
                                <select className="form-control" name="ownerShip" value={formik.values.ownerShip} onChange={(e) => formik.setValues({ ...formik.values, ownerShip: e.target.value })}>
                                    {
                                        GetEProductionUnitOwnership.EProductionUnitOwnership.map((data, index) =>
                                            <option key={data.Id} value={data.Id}>{data.Name}</option>
                                        )
                                    }
                                </select>
                                {
                                    formik.touched.ownerShip &&
                                        formik.errors.ownerShip ? (
                                        <label className="text-danger" >
                                            {formik.errors.ownerShip}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right"> Production Name</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="Unit Name" name="unitName" value={formik.values.unitName} onChange={(e) => formik.setValues({ ...formik.values, unitName: e.target.value })} />
                                {
                                    formik.touched.unitName &&
                                        formik.errors.unitName ? (
                                        <label className="text-danger" >
                                            {formik.errors.unitName}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Details</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="Details" name="details" value={formik.values.details} onChange={(e) => formik.setValues({ ...formik.values, details: e.target.value })} />
                                {
                                    formik.touched.details &&
                                        formik.errors.details ? (
                                        <label className="text-danger" >
                                            {formik.errors.details}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right"> Production Configuration</label>
                                <select className="form-control" name="productionConfigurationId" value={formik.values.productionConfigurationId} onChange={(e) => formik.setValues({ ...formik.values, productionConfigurationId: e.target.value })}>
                                    <option value="" >Select...</option>
                                    {
                                        productionConfiguration.map((data, index) =>
                                            <option key={data.id} value={data.id}>{data.name.toUpperCase()}</option>
                                        )
                                    }
                                </select>
                                {
                                    formik.touched.productionConfigurationId &&
                                        formik.errors.productionConfigurationId ? (
                                        <label className="text-danger" >
                                            {formik.errors.productionConfigurationId}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Is Multi Product</label>
                                <div>
                                    <input onChange={() => formik.setValues({ ...formik.values, isMultiProduct: (formik.values.isMultiProduct == 1 ? 0 : 1) })} checked={formik.values.isMultiProduct == 1} type="checkbox" class="ace ace-switch ace-switch-5" />
                                    <span class="lbl middle"></span>
                                </div>
                                {
                                    formik.touched.isMultiProduct &&
                                        formik.errors.isMultiProduct ? (
                                        <label className="text-danger" >
                                            {formik.errors.isMultiProduct}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div class="clearfix form-actions">
                    <div class="pull-right">
                        {isLoadingLocal ?
                            <LoadingButton />
                            :
                            <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                <i class="ace-icon fa fa-check bigger-110"></i>
                                Update
                            </button>}
                    </div>
                </div>

            </form>
        </Container >
    )
}
