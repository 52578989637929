import React, { useContext, useEffect, useState } from 'react'
import { EmptyData } from '../../commonLib/EmptyData';
import { MyAlert } from '../../commonLib/MyAlert';
import { PagingEmpty } from '../../commonLib/PagingEmpty'
import { GetEGatePassProductsType, GetEGatePassProductsTypeName } from '../../enumCollections/EGatePassProductsType';
import { ProductView } from '../Global/Elements/ProductView';
import * as gatePassCrud from '../../_crud/gatePassCrud'
import AppToaster from '../../commonLib/AppToaster';
import { ContactView } from '../Global/Elements/ContactView';
import Moment from 'react-moment';
import { UserView } from '../Global/Elements/UserView';
import ProductSelector from '../Global/ProductSelector';
import { ProductSelectorView } from '../Global/SelectorView/ProductSelectorView';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { Link, useParams } from 'react-router-dom';
import { WarehouseView } from '../Global/Elements/WarehouseView';

export function GatePassItemView({ gatePass, gatePassProduct, gatePassProductCount, doRefresh, isPrint = false }) {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [selectedProductData, setSelectedProductData] = useState(undefined);
    const [empty, setEmpty] = useState(false);
    const { id } = useParams()
    const [itemButton, setItemButton] = useState(false);

    const initItems = {
        gatePassId: id,
        productId: "",
        productData: undefined,
        quantity: 0,
        type: "0",
        unit: ""
    }
    const [gatePassItem, setGatePassItem] = useState(initItems);

    useEffect(() => {
        setEmpty(false)
    }, [empty]);

    useEffect(() => {
        if (gatePassItem.productId && gatePassItem.quantity > 0)
            setItemButton(true)
        else
            setItemButton(false)

    }, [gatePassItem]);

    useEffect(() => {
        if (selectedProductData) {
            setGatePassItem({ ...gatePassItem, productId: selectedProductData.id, unit: selectedProductData.unit, quantity: 0, productData: selectedProductData })
        }
    }, [selectedProductData]);

    const AddItem = () => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        gatePassCrud.AddGatePassProduct(gatePassItem)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster('Added successfully.', 'success')
                    setEmpty(true)
                    doRefresh()
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        gatePassCrud.RemoveGatePassProduct(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    doRefresh()
                    AppToaster('Deleted successfully.', 'success')
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by someone,could not delete.', 'error')
                }
            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }

    function setQuantity() {
        if (selectedProductData) {
            switch (gatePassItem.type) {
                case "0":
                    {
                        if (gatePassItem.quantity > selectedProductData.mainStock) {
                            setGatePassItem({ ...gatePassItem, quantity: selectedProductData.mainStock })
                        }
                        else if (gatePassItem.quantity < 0) {
                            setGatePassItem({ ...gatePassItem, quantity: 0 })
                        }
                        break;
                    }
                case "1":
                    {
                        if (gatePassItem.quantity > selectedProductData.defectiveStock) {
                            setGatePassItem({ ...gatePassItem, quantity: selectedProductData.defectiveStock })
                        }
                        else if (gatePassItem.quantity < 0) {
                            setGatePassItem({ ...gatePassItem, quantity: 0 })
                        }
                        break;
                    }
                case "2":
                    {
                        if (gatePassItem.quantity > selectedProductData.rejectedStock) {
                            setGatePassItem({ ...gatePassItem, quantity: selectedProductData.rejectedStock })
                        }
                        else if (gatePassItem.quantity < 0) {
                            setGatePassItem({ ...gatePassItem, quantity: 0 })
                        }
                        break;
                    }
                case "3":
                    {
                        if (gatePassItem.quantity > selectedProductData.rawStock) {
                            setGatePassItem({ ...gatePassItem, quantity: selectedProductData.rawStock })
                        }
                        else if (gatePassItem.quantity < 0) {
                            setGatePassItem({ ...gatePassItem, quantity: 0 })
                        }
                        break;
                    }
            }
        }

    }

    useEffect(() => {
        setQuantity()
    }, [gatePassItem.type, gatePassItem.quantity]);

    return (
        <>
            <div className="widget-box">
                <div class="widget-header widget-header-flat widget-header-small">
                    <h5 class="widget-title">
                        Details
                    </h5>
                </div>
                {
                    gatePass &&
                    <div className="row">
                        <div class="profile-user-info profile-user-info-striped">
                            <div class="profile-info-row">
                                <div class="profile-info-name"> GatePass No.</div>

                                <div class="profile-info-value">
                                    <span ><b>{gatePass.id}</b></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> On Date</div>

                                <div class="profile-info-value">
                                    <span ><b><Moment format="DD-MMM-YYYY">{gatePass.onDate}</Moment></b></span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Alloted Contact </div>

                                <div class="profile-info-value">
                                    <Link to={"/contact/dashboard/" + gatePass.refContactId}>
                                        <ContactView item={gatePass.contact} uid={"GC1"} />
                                    </Link>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name"> Total </div>

                                <div class="profile-info-value">
                                    <span><b>{gatePassProductCount.count}</b> Items with <b>{gatePassProductCount.totalQuantity}</b> quantity.</span>
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name"> Branch </div>

                                <div class="profile-info-value">
                                    <WarehouseView item={gatePass.warehouse} uid={"WHD1"} />
                                </div>
                            </div>
                            <div class="profile-info-row">
                                <div class="profile-info-name"> Note </div>

                                <div class="profile-info-value">
                                    <span>{gatePass.note ? gatePass.note : "NA"}</span>
                                </div>
                            </div>

                            <div class="profile-info-row">
                                <div class="profile-info-name"> Created By </div>

                                <div class="profile-info-value">
                                    <span><UserView item={gatePass.createdByUser} uid={"GD1"} createdOn={gatePass.createdOn} /></span>
                                </div>
                            </div>


                        </div>
                    </div>
                }
            </div>

            <br />
            <div className="widget-box">
                <div class="widget-header widget-header-flat widget-header-small">
                    <h5 class="widget-title">
                        Gatepass Items
                    </h5>
                </div>
                {!isPrint &&
                    <>
                        <div className="row" >
                            <div class="col-xs-12">

                                <div className="col-md-5">
                                    <div class="form-group">
                                        <label class="control-label no-padding-right">Product</label>
                                        <ProductSelector setEmpty={empty} searchtext="" setSelectedData={setSelectedProductData} />
                                    </div>
                                    <ProductSelectorView data={selectedProductData} />
                                </div>

                                <div className="col-md-2">
                                    <div class="form-group">
                                        <label class="control-label no-padding-right"> Quantity {gatePassItem.unit && "- " + gatePassItem.unit}</label>
                                        <input className="form-control" type="number" placeholder="Quantity" value={gatePassItem.quantity} onChange={(e) => setGatePassItem({ ...gatePassItem, quantity: e.target.value })} />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div class="form-group">
                                        <label class="ontrol-label no-padding-right">Type</label>
                                        <select className="form-control" value={gatePassItem.type} onChange={(e) => setGatePassItem({ ...gatePassItem, type: e.target.value })}>
                                            {
                                                GetEGatePassProductsType.EGatePassProductsType.map((data, index) =>
                                                    <option key={data.Id} value={data.Id}>{data.Name}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div class="form-group">
                                        <label class="control-label no-padding-right">&nbsp;</label>
                                        <div class="col-xs-12">
                                            {isLoadingLocal ?
                                                <button class="btn btn-xs btn-success" type="submit" expand="block" disabled>
                                                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i> Wait...
                                                </button>
                                                :
                                                <button class="btn btn-xs btn-success" type="submit" expand="block" onClick={AddItem} disabled={!itemButton}>
                                                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i> Add
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                    </>
                }

                <div className="row">
                    <div className="col-xs-12">
                        <PagingEmpty text={"Total " + gatePassProduct.length + " items."}>
                            {gatePassProduct && gatePassProduct.length > 0 ?
                                <div className="tables-scroll">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Product</th>
                                                <th>Quantity</th>
                                                <th>Type</th>
                                                {!isPrint && <th>Action</th>}
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {gatePassProduct.map((item, index) =>
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <Link to={"/product/dashboard/" + item.productId}>
                                                            <ProductView item={item.product} uid={"PP" + index} />
                                                        </Link>
                                                    </td>
                                                    <td>{item.quantity} {item.unit}</td>
                                                    <td>{GetEGatePassProductsTypeName(parseInt(item.type))}</td>
                                                    {!isPrint &&
                                                        <td>
                                                            <div class="btn-group">
                                                                <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                                    <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                                </MyAlert>
                                                            </div>
                                                        </td>
                                                    }
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>

                                :
                                <EmptyData>
                                    No Item Added.
                                    <br />
                                    <br />
                                </EmptyData>
                            }
                        </PagingEmpty>
                    </div>
                </div>
            </div>
        </>
    )
}
