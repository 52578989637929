import { createAxios } from "../initApi/createAxios";

export async function CreateNewOrgGlobalRequest(param, orgid) {
    return await createAxios(orgid).post("Register/CreateNewOrgGlobalRequest", param)
}
export async function CreateNewOrgGlobal(param, orgid) {
    return await createAxios(orgid).post("Register/CreateNewOrgGlobal", param)
}
export async function MobileOtpGenerate(param, orgid) {
    return await createAxios(orgid).post("Register/MobileOtpGenerate", param)
}
export async function MobileOtpVerify(param, orgid) {
    return await createAxios(orgid).post("Register/MobileOtpVerify", param)
}