import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetByProcessingId(processingid) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProcessingWage/GetByProcessingId?ProcessingId=" + processingid)
    }
}

export async function AddNormal(processingWage) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProcessingWage/AddNormal", processingWage)
    }
}

export async function AddReverse(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProcessingWage/AddReverse", param)
    }
}

export async function Remove(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProcessingWage/Remove?Id=" + id)
    }
}
