const GetEBoolName = (id) => {
    switch (id) {
        case 1: { return "Yes" }
        case 0: { return "No" }
    }
}

const GetEBool = {
    EBool: [
        { Id: 1, Name: "Yes" },
        { Id: 0, Name: "No" },
    ]
}

export { GetEBoolName, GetEBool }