import React, { useState, useEffect } from "react";
import { getCanvas } from "react-lasso-select";
import mergeImages from 'merge-images';
import rotate from 'base64-rotate'
import { flip, swirl, bulgePinch, vibrance, resizeImage } from "./ImageEditFunction";
import { PoseBg } from "./PoseBg";

export function Saree1Pose({ img1, img2, bg, setIsCompleted, setDrapResult, status }) {

    const [isReadySari1, setIsReadySari1] = useState(false);
    const [isReadySari2, setIsReadySari2] = useState(false);
    const [isReadySari3, setIsReadySari3] = useState(false);
    const [isReadySari4, setIsReadySari4] = useState(false);
    const [isReadySari5, setIsReadySari5] = useState(false);
    const [isReadySari6, setIsReadySari6] = useState(false);
    const [isReadySari7, setIsReadySari7] = useState(false);
    const [isReadySari8, setIsReadySari8] = useState(false);
    const [isReadySari9, setIsReadySari9] = useState(false);
    const [isReadySari10, setIsReadySari10] = useState(false);
    const [isReadySari11, setIsReadySari11] = useState(false);

    //#region Saree1
    const MergeSaree = () => {
        try {
            let cbg = PoseBg(parseInt(bg))
            //alert(cbg)
            mergeImages([
                { src: cbg, x: 0, y: 0 },
                { src: saree2, x: 937, y: 2750 },
                { src: saree3, x: 970, y: 2790 },
                { src: saree1, x: 771, y: 2500 },
                { src: saree6, x: 1220, y: 2454 },
                { src: saree5, x: 1191, y: 2127 },
                { src: saree4, x: 1157, y: 2315 },
                { src: saree7, x: 93, y: 1127 },
                { src: saree11, x: 963, y: 750 },
                { src: saree8, x: 889, y: 1308 },
                { src: saree9, x: 927, y: 745 },
                { src: saree10, x: 903, y: 760 },
                { src: './assets/images/Draper/new/3.png', x: 0, y: 0 },
                { src: './assets/images/Draper/new/3abody.png', x: 0, y: 0 },
            ])
                .then(b64 => {
                    setDrapResult(b64)
                    //alert("sareedrap")
                    setIsCompleted("completed")
                }).catch(e => {
                    setDrapResult()
                    setIsCompleted("failed")
                    alert(e)
                });
        } catch (e) {
            setDrapResult()
            setIsCompleted("failed")
            alert(e)
        }
    }
    ///Saree item 1
    const [saree1, setSaree1] = useState("");
    const GetSaree1 = () => {
        setIsReadySari1(false);
        rotate(img1, 30).then(newsrc => {
            let initPP = "35,1370 55,1406 87,1428 132,1461 171,1483 219,1509 290,1538 368,1574 413,1593 432,1603 448,1557 471,1493 497,1422 513,1377 522,1306 551,1209 555,1171 303,1190 168,1203 155,1241 126,1267 68,1329"
                .split(" ")
                .map((c) => c.split(",").map(Number))
                .map(([x, y]) => ({ x, y }));
            getCanvas(newsrc, initPP, (err, canvas) => {
                if (!err) {
                    setSaree1(canvas.toDataURL());
                    setIsReadySari1(true)
                }
            });
        }).catch(err => {
        })
    }
    ///Saree item 2
    const [saree2, setSaree2] = useState("");
    const GetSaree2 = () => {
        setIsReadySari2(false)
        rotate(img1, 20).then(newsrc => {
            let initPP = "86,1393 67,1505 86,1517 86,1530 99,1536 137,1393"
                .split(" ")
                .map((c) => c.split(",").map(Number))
                .map(([x, y]) => ({ x, y }));

            getCanvas(newsrc, initPP, (err, canvas) => {
                if (!err) {
                    setSaree2(canvas.toDataURL());
                    setIsReadySari2(true)
                }
            });
        }).catch(err => {
        })
    }
    ///Saree item 3
    const [saree3, setSaree3] = useState("");
    const GetSaree3 = () => {
        setIsReadySari3(false)
        rotate(img1, 8).then(newsrc => {
            let initPP = "78,1472 54,1534 45,1567 45,1591 84,1594 168,1603 228,1609 231,1582"
                .split(" ")
                .map((c) => c.split(",").map(Number))
                .map(([x, y]) => ({ x, y }));

            getCanvas(newsrc, initPP, (err, canvas) => {
                if (!err) {
                    setSaree3(canvas.toDataURL());
                    setIsReadySari3(true)
                }
            });
        }).catch(err => {
        })
    }
    ///Saree item 4
    const [saree4, setSaree4] = useState("");
    const GetSaree4 = () => {
        setIsReadySari4(false)
        rotate(img1, 20).then(newsrc => {
            let initPP = "219,1018 226,951 340,929 273,1059 257,1136 235,1253 229,1288 216,1326 181,1466 153,1552 124,1549 114,1546 137,1479 159,1403 181,1349 213,1263 232,1161 251,1091 264,1047"
                .split(" ")
                .map((c) => c.split(",").map(Number))
                .map(([x, y]) => ({ x, y }));

            getCanvas(newsrc, initPP, (err, canvas) => {
                if (!err) {
                    setSaree4(canvas.toDataURL());
                    setIsReadySari4(true)
                }
            });
        }).catch(err => {
        })
    }
    ///Saree item 5
    const [saree5, setSaree5] = useState("");
    const GetSaree5 = () => {
        setIsReadySari5(false)
        rotate(img1, 18).then(newsrc2 => {
            let initPP = "253,804 310,987 302,1065 277,1167 253,1277 233,1375 184,1559 180,1579 204,1587 224,1546 233,1538 253,1453 269,1387 359,934 404,771"
                .split(" ")
                .map((c) => c.split(",").map(Number))
                .map(([x, y]) => ({ x, y }));

            getCanvas(newsrc2, initPP, (err, canvas) => {
                if (!err) {
                    setSaree5(canvas.toDataURL());
                    setIsReadySari5(true)
                }
            });

        }).catch(err => {
        })
    }
    ///Saree item 6
    const [saree6, setSaree6] = useState("");
    const GetSaree6 = () => {
        setIsReadySari6(false)
        swirl(img1, 500, 1550, 200, -1.1).then(newsrc1 => {
            swirl(newsrc1, 500, 1350, 200, -1.1).then(newsrc2 => {
                rotate(newsrc2, 179).then(newsrc3 => {
                    rotate(newsrc3, 165).then(newsrc => {
                        let initPP = "499,2303 502,2274 505,2240 518,2199 528,2154 537,2113 547,2063 553,2022 562,1968 565,1943 581,1892 654,1892 692,1886 771,1883 802,1883 802,1943 809,1993 809,2079 809,2145 806,2173 783,2199 758,2221 717,2240 667,2262 613,2274 543,2293"
                            .split(" ")
                            .map((c) => c.split(",").map(Number))
                            .map(([x, y]) => ({ x, y }));

                        getCanvas(newsrc, initPP, (err, canvas) => {
                            if (!err) {
                                setSaree6(canvas.toDataURL());
                                setIsReadySari6(true)
                            }
                        });
                    }).catch(err => {
                    })
                }).catch(err => {
                })
            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///Saree item 7
    const [saree7, setSaree7] = useState("");
    const GetSaree7 = () => {
        setIsReadySari7(false)
        bulgePinch(img1, 2144, 130, 500, -0.60).then(newsrc3 => {
            swirl(newsrc3, 2500, 686, 556, 0.6).then(newsrc4 => {
                flip(newsrc4, 2800, 1600).then(newsrc1 => {
                    rotate(newsrc1, 179).then(newsrc2 => {
                        rotate(newsrc2, 150).then(newsrc => {
                            let initPP = "105,1431 157,1385 249,1340 373,1307 507,1254 553,1225 648,1182 753,1087 838,1009 949,927 1041,845 1058,875 1048,1002 992,1140 982,1294 989,1405 1008,1720 1002,1913 989,2181 927,2231 900,2250 913,2263 864,2325 845,2293 805,2253 753,2227 684,2188 652,2142 609,2090 576,2027 524,1929 488,1837 445,1762 406,1674 380,1667 334,1628 272,1602 232,1582 210,1549 157,1494 115,1451"
                                .split(" ")
                                .map((c) => c.split(",").map(Number))
                                .map(([x, y]) => ({ x, y }));
                            getCanvas(newsrc, initPP, (err, canvas) => {
                                if (!err) {
                                    setSaree7(canvas.toDataURL());
                                    setIsReadySari7(true)
                                }
                            });
                        }).catch(err => {
                        })
                    }).catch(err => {
                    })
                }).catch(err => {
                })
            }).catch(err => {
            })
        }).catch(err => {
        })
    }
    ///Saree item 8
    const [saree8, setSaree8] = useState("");
    const GetSaree8 = () => {
        setIsReadySari8(false)
        swirl(img1, 786, 1500, 500, -1.2).then(newsrc => {
            let initPP = "76,1572 90,1527 118,1468 132,1423 146,1379 146,1342 146,1297 146,1239 146,1196 146,1135 146,1079 146,1040 143,1012 132,961 132,939 140,897 132,846 129,813 132,782 120,726 118,675 118,566 123,474 137,398 196,350 291,339 440,381 557,350 655,333 717,328 756,336 759,381 762,462 767,488 773,532 776,572 781,622 787,664 787,709 781,748 776,810 767,905 750,1042 736,1143 725,1213 722,1261 725,1317 725,1384 731,1465 731,1527 708,1530 686,1530 638,1524 610,1519 585,1519 557,1524 518,1536 487,1544 459,1552 412,1561 356,1569 297,1580 235,1589 174,1594 143,1592 112,1589"
                .split(" ")
                .map((c) => c.split(",").map(Number))
                .map(([x, y]) => ({ x, y }));

            getCanvas(newsrc, initPP, (err, canvas) => {
                if (!err) {
                    setSaree8(canvas.toDataURL());
                    setIsReadySari8(true)
                }
            });
        }).catch(err => {
        })
    }
    ///Saree item 9
    const [saree9, setSaree9] = useState("");
    const GetSaree9 = () => {
        setIsReadySari9(false)
        bulgePinch(img1, 360, 380, 330, -0.4).then(bulge => {
            swirl(bulge, 116, 0, 500, -1.2).then(swirl1 => {
                rotate(swirl1, 179).then(newsrc2 => {
                    rotate(newsrc2, 158).then(newsrc => {
                        let initPP = "1021,1351 907,1406 885,1426 820,1429 794,1465 729,1461 680,1507 654,1546 599,1562 586,1594 525,1617 457,1620 424,1656 382,1662 382,1614 386,1523 389,1448 402,1387 418,1335 447,1286 467,1248 470,1215 476,1186 480,1157 480,1147 480,1131 480,1121 483,1102 476,1069 473,1043 470,1024 470,992 476,959 496,933 512,914 538,894 573,875 606,859 648,842 687,826 729,807 765,794 816,774 846,761 872,749 888,749 917,758 946,774 962,791 978,813 991,849 1011,901 1014,920 1017,946 1014,975 1014,1001 1014,1030 1017,1066 1017,1095 1014,1128 1014,1150 1027,1176 1034,1192 1034,1215 1030,1231 1034,1248 1027,1270 1027,1299 1027,1341"
                            .split(" ")
                            .map((c) => c.split(",").map(Number))
                            .map(([x, y]) => ({ x, y }));

                        getCanvas(newsrc, initPP, (err, canvas) => {
                            if (!err) {
                                setSaree9(canvas.toDataURL());
                                setIsReadySari9(true)
                            }
                        });
                    }).catch(err => {
                    })
                }).catch(err => {
                })
            }).catch(err => {
            })
        }).catch(err => {
        })

    }
    ///Saree item 10
    const [saree10, setSaree10] = useState("");
    const GetSaree10 = () => {
        setIsReadySari10(false)
        resizeImage(img2, 1000, 1000).then(resize => {
            swirl(resize, 200, 900, 222, -0.7).then(swr => {
                rotate(swr, 20).then(newsrc => {
                    let initPP = "173,740 185,771 192,803 196,842 194,894 190,932 178,968 178,995 174,1003 165,983 145,967 109,951 82,944 68,947 78,902 89,861 105,817 121,780 137,759 153,748"
                        .split(" ")
                        .map((c) => c.split(",").map(Number))
                        .map(([x, y]) => ({ x, y }));

                    getCanvas(newsrc, initPP, (err, canvas) => {
                        if (!err) {
                            setSaree10(canvas.toDataURL());
                            setIsReadySari10(true)
                        }
                    });
                }).catch(err => {
                })
            }).catch(err => {
            })
        }).catch(err => {
        })

    }
    ///Saree item 11
    const [saree11, setSaree11] = useState("");
    const GetSaree11 = () => {
        setIsReadySari11(false)
        resizeImage(img2, 1000, 1000).then(resize => {
            swirl(resize, 200, 900, 222, -0.7).then(newsrc => {
                let initPP = "162,854 144,803 96,703 102,687 112,670 129,658 156,645 166,645 178,643 196,640 208,646 230,670 262,703 296,735 307,745 268,767 200,817"
                    .split(" ")
                    .map((c) => c.split(",").map(Number))
                    .map(([x, y]) => ({ x, y }));

                getCanvas(newsrc, initPP, (err, canvas) => {
                    if (!err) {
                        setSaree11(canvas.toDataURL());
                        setIsReadySari11(true)
                    }
                });
            }).catch(err => {
            })
        }).catch(err => {
        })

    }
    ///////////////////////////////////////////
    //#endregion
    useEffect(() => {
        if (isReadySari1 && isReadySari2 && isReadySari3 && isReadySari4 && isReadySari5 && isReadySari6
            && isReadySari7 && isReadySari8 && isReadySari9 && isReadySari10 && isReadySari11) {
            MergeSaree()
            //alert("MargeCall")
            setIsCompleted("Merging Images")
            setIsReadySari1(false)
            setIsReadySari2(false)
            setIsReadySari3(false)
            setIsReadySari4(false)
            setIsReadySari5(false)
            setIsReadySari6(false)
            setIsReadySari7(false)
            setIsReadySari8(false)
            setIsReadySari9(false)
            setIsReadySari10(false)
            setIsReadySari11(false)
        }
    }, [isReadySari1, isReadySari2, isReadySari3, isReadySari4, isReadySari5, isReadySari6
        , isReadySari7, isReadySari8, isReadySari9, isReadySari10, isReadySari11])

    useEffect(() => {
        if (img1 && img2 && status == "init") {
            setIsCompleted("Processing")
            setDrapResult()
            GetSaree1()
            GetSaree2()
            GetSaree3()
            GetSaree4()
            GetSaree5()
            GetSaree6()
            GetSaree7()
            GetSaree8()
            GetSaree9()
            GetSaree10()
            GetSaree11()
        }
    }, [img1, img2, status])

    return (
        <></>
    )
}
