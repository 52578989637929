import React from 'react'
import { EmptyData } from '../../commonLib/EmptyData';
import { getPageMargins } from '../../config/GlobalSettings';
import { GetEGatePassProductsTypeName } from '../../enumCollections/EGatePassProductsType';
import { ContactViewS } from '../Global/Elements/ContactViewS';
import { ProductViewS } from '../Global/Elements/ProductViewS';
import { WarehouseViewS } from '../Global/Elements/WarehouseViewS';
import { PrintBranding } from './PrintBranding';

export const GatePassPrint = React.forwardRef((props, ref) => {
    const { gatePass, gatePassProduct, gatePassProductCount } = props
    return (
        <div ref={ref}>
            <style type="text/css">
                {getPageMargins()}
            </style>
            {
                gatePass && gatePassProduct &&
                <PrintBranding printName="GatePass" date={gatePass.onDate} id={gatePass.id}>
                    {
                        gatePass && gatePassProduct &&
                        <div className="row">
                            <div className="col-xs-12">

                                <table style={{ width: "100%" }} className="table-bordered-dark">
                                    <tbody>
                                        <tr >
                                            <td>Alloted Contact</td>
                                            <td>
                                                <ContactViewS item={gatePass.contact} uid={"GC1"} />

                                            </td>
                                            <td>
                                                <span className="pull-right">
                                                    <WarehouseViewS item={gatePass.warehouse} />
                                                </span>
                                            </td>
                                        </tr>
                                        <tr >
                                            <td>Total</td>
                                            <td colSpan={2}>
                                                <b>{gatePassProductCount.count}</b> Items with <b>{gatePassProductCount.totalQuantity}</b> quantity.
                                            </td>
                                        </tr>
                                        <tr >
                                            <td>Note</td>
                                            <td colSpan={2}>{gatePass.note ? gatePass.note : "NA"}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    }
                    <br />

                    <div className="row">
                        <div className="col-xs-12">
                            <h5 className="text-center"> GatePass Product</h5>
                            {gatePassProduct && gatePassProduct.length > 0 ?
                                <table style={{ width: "100%" }} className="table-bordered-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Product</th>
                                            <th>Quantity</th>
                                            <th>Type</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {gatePassProduct.map((item, index) =>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <ProductViewS item={item.product} uid={"PP" + index} />
                                                </td>
                                                <td>{item.quantity} {item.unit}</td>
                                                <td>{GetEGatePassProductsTypeName(parseInt(item.type))}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                :
                                <EmptyData>
                                    No Item Added.
                                    <br />
                                    <br />
                                </EmptyData>
                            }
                        </div>
                    </div>
                </PrintBranding>
            }
        </div>
    )
})