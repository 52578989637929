const GetEInitPriorityName = (id) => {
    switch (id) {
        case 1: { return "Normal" }
        case 2: { return "Urgent" }
    }
}

const GetEInitPriority = {
    EInitPriority: [
        { Id: 1, Name: "Normal" },
        { Id: 2, Name: "Urgent" },
    ]
}

export { GetEInitPriorityName, GetEInitPriority }