import React, { useContext, useEffect, useRef, useState } from 'react'
import { EmptyData } from '../../commonLib/EmptyData';
import { MyAlert } from '../../commonLib/MyAlert';
import { Paging } from '../../commonLib/Paging';
import { UserView } from '../Global/Elements/UserView';
import { GetEBoolName } from '../../enumCollections/EBool'
import { ProductView } from '../Global/Elements/ProductView';
import { Link, useParams } from 'react-router-dom'
import { ProductionUnitView } from '../Global/Elements/ProductionUnitView';
import { ContactView } from '../Global/Elements/ContactView';
import Moment from 'react-moment';
import { Spinner } from '../../commonLib/Spinner';
import DatePicker from "react-datepicker";
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import *as productReturnCrud from '../../_crud/productReturnCrud'
import GetCurrentDateForSearch from '../../commonLib/dateFormat';
import { PAGING_PAGE_SIZE, getIsPrint } from '../../config/GlobalSettings';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { PrintStarter } from '../Prints/PrintStarter';
import { ProductReturnPrint } from './../Prints/ProductReturnPrint';
import { WarehouseView } from '../Global/Elements/WarehouseView';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

export function ManageProductionUnitProductReturnContact() {
    const [productReturn, setproductReturn] = useState([])
    const [LastPage, setLastPage] = useState(1)
    const [pagingData, setPagingData] = useState()
    const { id, productionUnitId } = useParams()
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)

    const initValues = {
        Search: {
            SearchTerm: "",
            PageNo: 1,
            PageSize: PAGING_PAGE_SIZE
        },
        productionUnitId: productionUnitId,
        contactId: id,
        Range: {
            fromDate: new Date(GetCurrentDateForSearch(-30)),
            toDate: new Date(GetCurrentDateForSearch(0))
        }
    }

    const [search, setSearch] = useState(initValues)

    useEffect(() => {
        getData()
    }, [search.Search.PageNo])

    const OnPageChange = (e) => {
        const { value } = e.target
        setSearch({ ...search, Search: { ...search.Search, PageNo: value } });
    }

    function OnSearch() {
        getData()
    }

    function getData() {
        setIsLoadingMini(true);
        productReturnCrud.GetByProductionUnitContact(search)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setproductReturn(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoadingMini(false)
                        setproductReturn([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoadingMini(false)
                    setproductReturn([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        setSearch({ ...search, Search: { ...search.Search, PageNo: 1 } })
        getData()
        setTimeout(() => {
            event.detail.complete();
        }, 1000);
    }

    function SearchChange(e) {
        const value = e.target.value
        setSearch({ ...search, Search: { ...search.Search, SearchTerm: value } })
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        productReturnCrud.Remove(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    AppToaster('Deleted successfully.', 'success')
                    getData()
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by someone,could not delete.', 'error')
                }

            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }
    const [printData, setPrintData] = useState()
    const printRef = useRef();

    return (
        <>
            {getIsPrint && <div style={{ display: "none" }}>
                <ProductReturnPrint printData={printData} ref={printRef} />
            </div>}
            <Container title="Product Return" doRefresh={doRefresh} isBack="true" isContact="true" isProduction="true">
                <Link to={"/contact/dashboard/" + id + "/productionunit/productreturn/" + productionUnitId + "/add"} class="btn btn-sm btn-success">
                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                    New
                </Link>
                <div class="hr dotted"></div>
                <div class="form-horizontal" >
                    <div className="row">
                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker selectsStart startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.fromDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, fromDate: date } })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker selectsEnd startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.toDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, toDate: date } })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-7">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <TextBoxTranslater style={{paddingLeft: "24px"}} value={search.Search.SearchTerm} onChange={SearchChange} className="form-control" type="text" id="form-field-icon-1" placeholder="Search Product Receiving.." />
                                    <i class="ace-icon fa fa-search blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-1">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <button onClick={OnSearch} to="/productreceiving/add" class="btn btn-sm btn-info pull-right">
                                    <i class="ace-icon fa fa-search bigger-110"></i>
                                    Run
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <Paging
                    pagingData={pagingData}
                    LastPage={LastPage}
                    CurrentPage={search.Search.PageNo}
                    OnPageChange={OnPageChange} >
                    {isLoadingMini ?
                        <Spinner />
                        :
                        productReturn && productReturn.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            {getIsPrint && <th></th>}
                                            <th>Branch</th>
                                            <th>#Id</th>
                                            <th>Date</th>
                                            <th>Product</th>
                                            <th>Fresh</th>
                                            <th>ToMain</th>
                                            <th>Defective</th>
                                            <th>Rejected</th>
                                            <th>Total</th>
                                            <th>Note</th>
                                            <th>By</th>
                                            <th style={{ width: "43 px" }}></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {productReturn.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link className="btn btn-xs btn-info" to={"/productreturn/dashboard/" + item.id} title="View dashboard" ><i class="ace-icon fa fa-tachometer"></i></Link>
                                                    </div>
                                                </td>
                                                {getIsPrint && <td>
                                                    <div class="btn-group">
                                                        <PrintStarter printRef={printRef} setPrintData={setPrintData} item={item} />
                                                    </div>
                                                </td>}
                                                <td><WarehouseView item={item.warehouse} uid={"WH" + index} /></td>
                                                <td>{item.id}</td>
                                                <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.onDate}</Moment></div></td>
                                                <td>
                                                    <Link to={"/product/dashboard/" + item.productId}>
                                                        <ProductView item={item.product} uid={"PP" + index} />
                                                    </Link>
                                                </td>
                                                <td>{item.freshQuantity} {item.unit}</td>
                                                <td><span class={item.isFreshQuantityToMain == 1 ? "label label-sm label-success" : "label label-sm label-danger"}>{GetEBoolName(item.isFreshQuantityToMain)}</span></td>
                                                <td>{item.defectiveQuantity} {item.unit}</td>
                                                <td>{item.rejectedQuantity} {item.unit}</td>
                                                <td className="bolder">{Number(item.freshQuantity +item.rejectedQuantity + item.defectiveQuantity).toFixed(2)} {item.unit}</td>
                                                <td><div style={{ width: "200px" }}>{item.note}</div></td>
                                                <td>
                                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                            <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                        </MyAlert>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </Paging>
            </Container >
        </>
    )
}

