const GetEVoucherTypeName = (id) => {
    switch (id) {
        case 0: { return "Opening Balance" }
        case 1: { return "Advance Voucher" }
        case 2: { return "Salary Voucher" }
        case 3: { return "Processing Wage Voucher" }
        case 4: { return "Production Wage Voucher" }
        case 5: { return "Cash Bank Cash" }
        case 6: { return "Expense Voucher" }

    }
}

const GetEVoucherType = {
    EVoucherType: [
        { Id: 0, Name: "Opening Balance" },
        { Id: 1, Name: "Advance Voucher" },
        { Id: 2, Name: "Salary Voucher" },
        { Id: 3, Name: "Processing Wage Voucher" },
        { Id: 4, Name: "Production Wage Voucher" },
        { Id: 5, Name: "Cash Bank Cash" },
        { Id: 6, Name: "Expense Voucher" }
    ]
}

export { GetEVoucherTypeName, GetEVoucherType }