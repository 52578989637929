const GetEBalanceTypeName = (id) => {
    switch (id) {
        case 1: { return "Debit" }
        case 2: { return "Credit" }
    }
}

const GetEBalanceType = {
    EBalanceType: [
        { Id: 1, Name: "Debit" },
        { Id: 2, Name: "Credit" },
    ]
}

export { GetEBalanceTypeName, GetEBalanceType }