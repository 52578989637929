const GetELedgerGroupName = (id) => {
    switch (id) {
        case 1: { return "Revenue" }
        case 2: { return "Expense" }
        case 10: { return "Equity" }
        case 4: { return "Libility" }
        case 5: { return "Asset" }
        case 6: { return "Non Revenue" }
    }
}

const GetELedgerGroup = {
    ELedgerGroup: [
        { Id: 1, Name: "Revenue" },
        { Id: 2, Name: "Expense" },
        { Id: 10, Name: "Equity" },
        { Id: 4, Name: "Libility" },
        { Id: 5, Name: "Asset" },
        { Id: 6, Name: "Non Revenue" }
    ]
}

export { GetELedgerGroupName, GetELedgerGroup }