import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import AppToaster from '../../commonLib/AppToaster';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import * as ledgerCrud from '../../_crud/ledgerCrud'
import { GetEBoolName } from '../../enumCollections/EBool';
import { GetELedgerGroupName } from '../../enumCollections/ELedgerGroup';
import { GetELedgerLinkNameName } from '../../enumCollections/ELedgerLinkName';
import { GetELedgerTypeName } from '../../enumCollections/ELedgerType';
import { Spinner } from '../../commonLib/Spinner';
import { Container } from '../../base/Container';
import { PagingEmpty } from '../../commonLib/PagingEmpty';
import { EmptyData } from '../../commonLib/EmptyData';
import { UserView } from '../Global/Elements/UserView';
import { MyAlert } from '../../commonLib/MyAlert';

export function ManageLedger() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [ledger, setLedger] = useState([])

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoadingMini(true);
        ledgerCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let pdata = []
                        hdata.forEach(element => {
                            if (element.isFixed != 1)
                                pdata = [...pdata, element]
                        });
                        setLedger(pdata);
                    }
                    else {
                        setLedger([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setLedger([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        getData()
        setTimeout(() => {
            event.detail.complete();
        }, 1000);
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        ledgerCrud.Remove(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    AppToaster('Deleted successfully.', 'success')
                    getData()
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by someone,could not delete.', 'error')
                }

            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }
    return (
        <>
            <Container title="Ledger" doRefresh={doRefresh}>
                <Link to="/ledger/add" class="btn btn-sm btn-success ">
                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                    Add Expense Ledger
                </Link>
                <div class="hr dotted"></div>
                <PagingEmpty text={"Total " + ledger.length + " items."}>
                    {isLoadingMini ?
                        <Spinner />
                        :
                        ledger && ledger.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Ledger Name</th>
                                            <th>Linked Id</th>
                                            <th>Link Name</th>
                                            <th>Type</th>
                                            <th>Group</th>
                                            <th>Is Fixed</th>
                                            <th>Is Display</th>
                                            <th>Note</th>
                                            <th style={{ width: "50px" }}>By</th>
                                            <th style={{ width: "40px" }}></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {ledger.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>{index + 1}</td>
                                                <td>{item.ledgerName.toUpperCase()}</td>
                                                <td>{item.linkedId}</td>
                                                <td>{GetELedgerLinkNameName(item.linkName)}</td>
                                                <td> {GetELedgerTypeName(item.type)}</td>
                                                <td> {GetELedgerGroupName(item.group)}</td>
                                                <td><span class={item.isFixed == 1 ? "label label-sm label-success" : "label label-sm label-danger"}>{GetEBoolName(item.isFixed)}</span></td>
                                                <td><span class={item.isToDisplay == 1 ? "label label-sm label-success" : "label label-sm label-danger"}>{GetEBoolName(item.isToDisplay)}</span></td>
                                                <td><div style={{ width: "150px" }}>{item.note}</div></td>
                                                <td>
                                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        {item.isFixed == 0 &&
                                                            <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                                <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                            </MyAlert>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </PagingEmpty>
            </Container >
        </>
    )
}
