import Keys from "./Keys";
var CryptoJS = require("crypto-js");

const GetLoginInfo = () => {
    try {
        let data;
        data = localStorage.getItem('u');
        var bytes = CryptoJS.AES.decrypt(data, Keys);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    } catch (error) {
        return null;
    }
}
export default GetLoginInfo