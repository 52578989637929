import React, { useContext, useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import { LoadingButton } from '../../commonLib/LoadingButton';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { BASE_URL } from '../../initApi/apiUrls';
import { dashboardContext } from './../Global/dashboardContext'
import * as contactCrud from '../../_crud/contactCrud';
import { DocumentUploader } from '../../commonLib/DocumentUploader';
import { ImageUploader } from '../../commonLib/ImageUploader';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

export function ContactDocument() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [dashboardData, setDashboardData] = useContext(dashboardContext)
    const history = useHistory();
    const { id } = useParams()
    const [docDetails, setDocDetails] = useState({ documentName: dashboardData.contactdata.documentName, documentIdNo: dashboardData.contactdata.documentIdNo })
    const [docs, SetDocs] = useState("")

    useEffect(() => {
        if (!dashboardData.contactdata)
            history.push("/contact/dashboard/" + id)
    }, []);

    const SetPhoto = (image) => {
        SetDocs(image)
    }
    const SavePhoto = () => {
        if(docDetails.documentIdNo && docDetails.documentName){
        setIsLoadingMini(true);
        setIsLoadingLocal(true);
        const formData = new FormData()
        formData.append('id', id)
        formData.append('file', docs)
        formData.append('documentName', docDetails.documentName)
        formData.append('documentIdNo', docDetails.documentIdNo)
        contactCrud.UploadDocument(formData)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false);
                    setIsLoadingLocal(false);
                    AppToaster('Updated successfully.', 'success')
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }

            })
            .catch(error => {
                alert("image size is too large.")
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
        }
        else{
            AppToaster("Document name and Document no both are required.", 'error')

        }
    }
    return (
        <Container title="Upload Doucment (ID Proof)" isBack="true" isContact="true">

            <div className="row">
                <div className="col-md-2">
                </div>


                <div className="col-md-7">
                    <div class="form-group">
                        <div class="col-sm-12">
                            <label class="control-label no-padding-right"> Document Name</label>
                            <br />
                            <TextBoxTranslater value={docDetails.documentName} type="text" onChange={(e) => setDocDetails({ ...docDetails, documentName: e.target.value })} />
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-12">
                            <label class="control-label no-padding-right"> Document No</label>
                            <br />
                            <input value={docDetails.documentIdNo} type="text" onChange={(e) => setDocDetails({ ...docDetails, documentIdNo: e.target.value })} />
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="col-xs-12">
                            <br />
                            <label class="control-label no-padding-right"> Upload Document</label>
                            <ImageUploader initImage={dashboardData.contactdata && dashboardData.contactdata.isDocument == 1 ? (BASE_URL + "/" + dashboardData.contactdata.documentUrl) : undefined} SetImage={SetPhoto} />
                        </div>
                    </div>
                </div>
            </div>

            <div class="clearfix form-actions">
                <div class="pull-right">
                    {isLoadingLocal ?
                        <LoadingButton />
                        :
                        <button class="btn btn-info" type="submit" expand="block" onClick={SavePhoto} disabled={!docs}>
                            <i class="ace-icon fa fa-check bigger-110"></i>
                            Upload
                        </button>}
                </div>
            </div>

        </Container >
    )
}
