import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from "yup";
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import * as contactCrud from '../../_crud/contactCrud';
import * as locStatesCrud from '../../_crud/locStatesCrud'
import { LoadingButton } from './../../commonLib/LoadingButton'
import { GetEStatus } from '../../enumCollections/EStatus';
import * as productionCategoryCrud from '../../_crud/productionCategoryCrud'
import * as warehouseCrud from '../../_crud/warehouse/warehouseCrud'
import GetLoginInfo from '../../auth/_userContext/GetLoginInfo';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';



export function AddContact({ isModelView = false }) {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const history = useHistory();
    const [locStates, setLocStates] = useState([])
    const [productionCategories, setProductionCategories] = useState([])
    const [warehouse, setWarehouse] = useState([])
    const userData = GetLoginInfo()

    const initialValues = {
        fullName: "",
        address: "",
        phone: "",
        email: "",
        note: "",
        isDocument: 0,
        city: "",
        state: "",
        pinCode: "",
        refId: "",
        status: 1,
        bankName: undefined,
        bankAcountNo: undefined,
        bankBaranch: undefined,
        ifscCode: undefined,
        productionCatId: undefined,
        isWarehouse: userData.isWarehouse,
        warehouseId: userData.warehouseId ? userData.warehouseId : 0,
        isAddProductionUnit: 0,
    }

    const Schema = Yup.object().shape({
        fullName: Yup.string().min(3, " Full Name must between 3 to 30 characters").max(30, " Full Name must between 3 to 30 characters").required(" Full Name is required."),
        //.matches(/^[aA-zZ\s]+$/, " Only alphabets, space and underscore are allowed for this field "),
        address: Yup.string().required("Address is required."),
        phone: Yup.string().required("Phone is required.").matches(
            /^([\d]{10})$/g,
            "Invalid phone number"
        ),
        city: Yup.string().required("City is required."),
        state: Yup.string().required("State is required."),
        pinCode: Yup.string().required("PinCode is required."),
        email: Yup.string().email("Email is not valid."),
        productionCatId: Yup.string().required("Category is required."),
        warehouseId: Yup.string().required("Warehouse is required."),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    useEffect(() => {
        getLocStates()
        getCategories()
        getWarehouse()
    }, []);


    const Save = (value) => {
        if (isModelView)
            AppToaster('Adding please wait...', 'info')

        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        let isProd = (value.isAddProductionUnit == 1 && value.productionCatId) ? true : false;
        if (value.isAddProductionUnit == 0)
            isProd = true;
        if (isProd) {
            contactCrud.Add(value)
                .then(res => {
                    if (res.data.result.succeeded) {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false);
                        AppToaster('Added successfully.', 'success')
                        if (!isModelView) {
                            let hdata = res.data.data;
                            history.push("/contact/dashboard/" + hdata.id)
                        }
                    }
                    else {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false);
                        AppToaster(res.data.result.message, 'error')
                    }
                })
                .catch(error => {
                    alert(error)
                    setIsLoadingMini(false);
                    setIsLoadingLocal(false);
                    AppToaster(error, 'error')
                })
        }
        else {
            setIsLoadingMini(false);
            setIsLoadingLocal(false);
            AppToaster("Please select category if Add production unit is selected", 'error')
        }
    }

    function getLocStates() {
        setIsLoadingMini(true);
        locStatesCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setLocStates(hdata);
                    }
                    else {
                        setLocStates([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setLocStates([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }
    function getCategories() {
        setIsLoadingMini(true);
        productionCategoryCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProductionCategories(hdata);
                    }
                    else {
                        setProductionCategories([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProductionCategories([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }
    function getWarehouse() {
        setIsLoadingMini(true);
        warehouseCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setWarehouse(hdata);
                        //alert(JSON.stringify(hdata))
                    }
                    else {
                        setWarehouse([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setWarehouse([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }
    return (
        <Container title="Add Contact" isBack="true" isModelView={isModelView}>
            <form onSubmit={formik.handleSubmit} class="form-horizontal" autocomplete="off">
                <div className="row">
                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right"> Full Name</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="Full Name" name="fullName" value={formik.values.fullName} onChange={(e) => formik.setValues({ ...formik.values, fullName: e.target.value })} />
                                {
                                    formik.touched.fullName &&
                                        formik.errors.fullName ? (
                                        <label className="text-danger" >
                                            {formik.errors.fullName}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right"> Phone</label>
                                <input className="form-control" type="text" placeholder="Phone" name="phone" {...formik.getFieldProps("phone")} />
                                {
                                    formik.touched.phone &&
                                        formik.errors.phone ? (
                                        <label className="text-danger" >
                                            {formik.errors.phone}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right"> Email</label>
                                <input className="form-control" type="email" placeholder="Email" name="email" {...formik.getFieldProps("email")} />
                                {
                                    formik.touched.email &&
                                        formik.errors.email ? (
                                        <label className="text-danger" >
                                            {formik.errors.email}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right"> City</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="City" name="city" value={formik.values.city} onChange={(e) => formik.setValues({ ...formik.values, city: e.target.value })} />
                                {
                                    formik.touched.city &&
                                        formik.errors.city ? (
                                        <label className="text-danger" >
                                            {formik.errors.city}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right"> State</label>
                                <select className="form-control" name="state" value={formik.values.state} onChange={(e) => formik.setValues({ ...formik.values, state: e.target.value })}>
                                    <option value="" >Select...</option>
                                    {
                                        locStates.map((data, index) =>
                                            <option key={data.id} value={data.name}>{data.name}</option>
                                        )
                                    }
                                </select>
                                {
                                    formik.touched.state &&
                                        formik.errors.state ? (
                                        <label className="text-danger" >
                                            {formik.errors.state}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right"> Pin Code</label>
                                <input className="form-control" type="text" placeholder="Pin Code" name="pinCode" {...formik.getFieldProps("pinCode")} />
                                {
                                    formik.touched.pinCode &&
                                        formik.errors.pinCode ? (
                                        <label className="text-danger" >
                                            {formik.errors.pinCode}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right"> Address</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="Address" name="address" value={formik.values.address} onChange={(e) => formik.setValues({ ...formik.values, address: e.target.value })} />
                                {
                                    formik.touched.address &&
                                        formik.errors.address ? (
                                        <label className="text-danger" >
                                            {formik.errors.address}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Ref Id</label>
                                <input className="form-control" type="text" placeholder="Ref Id" name="refId" {...formik.getFieldProps("refId")} />
                                {
                                    formik.touched.refId &&
                                        formik.errors.refId ? (
                                        <label className="text-danger" >
                                            {formik.errors.refId}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Status</label>
                                <select className="form-control" name="status" value={formik.values.status} onChange={(e) => formik.setValues({ ...formik.values, status: e.target.value })}>
                                    {
                                        GetEStatus.EStatus.map((data, index) =>
                                            <option key={data.Id} value={data.Id}>{data.Name}</option>
                                        )
                                    }
                                </select>
                                {
                                    formik.touched.status &&
                                        formik.errors.status ? (
                                        <label className="text-danger" >
                                            {formik.errors.status}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Category</label>
                                <select className="form-control" name="productionCatId" value={formik.values.productionCatId} onChange={(e) => formik.setValues({ ...formik.values, productionCatId: e.target.value })}>
                                    <option value="" >Select...</option>
                                    {
                                        productionCategories.map((data, index) =>
                                            <option key={data.id} value={data.id}>{data.productionCatName.toUpperCase()}</option>
                                        )
                                    }
                                </select>
                                {
                                    formik.touched.productionCatId &&
                                        formik.errors.productionCatId ? (
                                        <label className="text-danger" >
                                            {formik.errors.productionCatId}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">With Production Unit</label>
                                <div>
                                    <input onChange={() => formik.setValues({ ...formik.values, isAddProductionUnit: (formik.values.isAddProductionUnit == 1 ? 0 : 1)})} checked={formik.values.isAddProductionUnit == 1} type="checkbox" class="ace ace-switch ace-switch-5" />
                                    <span class="lbl middle"></span>
                                </div>
                                {
                                    formik.touched.isAddProductionUnit &&
                                        formik.errors.isAddProductionUnit ? (
                                        <label className="text-danger" >
                                            {formik.errors.isAddProductionUnit}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                    {userData.isWarehouse == 0 &&
                        <>
                            <div className="col-md-2">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <label class="control-label no-padding-right">Is Warehouse</label>
                                        <div>
                                            <input onChange={() => formik.setValues({ ...formik.values, isWarehouse: (formik.values.isWarehouse == 1 ? 0 : 1), warehouseId: (formik.values.isWarehouse == 0 ? "" : 0) })} checked={formik.values.isWarehouse == 1} type="checkbox" class="ace ace-switch ace-switch-5" />
                                            <span class="lbl middle"></span>
                                        </div>
                                        {
                                            formik.touched.isWarehouse &&
                                                formik.errors.isWarehouse ? (
                                                <label className="text-danger" >
                                                    {formik.errors.isWarehouse}
                                                </label>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>

                            {formik.values.isWarehouse == 1 &&

                                <div className="col-md-4">
                                    <div class="form-group">
                                        <div class="col-xs-12">
                                            <label class="control-label no-padding-right">Warehouse</label>
                                            <select className="form-control" name="warehouseId" value={formik.values.warehouseId} onChange={(e) => formik.setValues({ ...formik.values, warehouseId: e.target.value })}>
                                                <option value="" >Select...</option>
                                                {
                                                    warehouse.map((data, index) =>
                                                        <option key={data.id} value={data.id}>{data.name.toUpperCase()} ({data.warehouseCode.toUpperCase()})</option>
                                                    )
                                                }
                                            </select>
                                            {
                                                formik.touched.warehouseId &&
                                                    formik.errors.warehouseId ? (
                                                    <label className="text-danger" >
                                                        {formik.errors.warehouseId}
                                                    </label>
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>

                <div className="row">
                    <div className="col-md-3">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="ontrol-label no-padding-right"> Bank Name</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="Bank Name" name="bankName" value={formik.values.bankName} onChange={(e) => formik.setValues({ ...formik.values, bankName: e.target.value })} />
                                {
                                    formik.touched.bankName &&
                                        formik.errors.bankName ? (
                                        <label className="text-danger" >
                                            {formik.errors.bankName}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Account No.</label>
                                <input className="form-control" type="text" placeholder="Account No" name="bankAcountNo" {...formik.getFieldProps("bankAcountNo")} />
                                {
                                    formik.touched.bankAcountNo &&
                                        formik.errors.bankAcountNo ? (
                                        <label className="text-danger" >
                                            {formik.errors.bankAcountNo}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Bank Branch</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="Bank Branch" name="bankBaranch" value={formik.values.bankBaranch} onChange={(e) => formik.setValues({ ...formik.values, bankBaranch: e.target.value })} />
                                {
                                    formik.touched.bankBaranch &&
                                        formik.errors.bankBaranch ? (
                                        <label className="text-danger" >
                                            {formik.errors.bankBaranch}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Ifsc code</label>
                                <input className="form-control" type="text" placeholder="Ifsc code" name="ifscCode" {...formik.getFieldProps("ifscCode")} />
                                {
                                    formik.touched.ifscCode &&
                                        formik.errors.ifscCode ? (
                                        <label className="text-danger" >
                                            {formik.errors.ifscCode}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="ontrol-label no-padding-right"> Note</label>
                                <TextBoxTranslater className="form-control" type="text" placeholder="Note" name="note" value={formik.values.note} onChange={(e) => formik.setValues({ ...formik.values, note: e.target.value })} />
                                {
                                    formik.touched.note &&
                                        formik.errors.note ? (
                                        <label className="text-danger" >
                                            {formik.errors.note}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div class="clearfix form-actions">
                    <div class="pull-right">
                        {isLoadingLocal ?
                            <LoadingButton />
                            :
                            isModelView ?
                                <button data-dismiss="modal" class="btn btn-info" disabled={!formik.isValid} onClick={formik.handleSubmit} >
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Add
                                </button>
                                :
                                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Add
                                </button>
                        }
                    </div>
                </div>

            </form>
        </Container >
    )
}
