import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Container } from '../../base/Container'
import AppToaster from '../../commonLib/AppToaster'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext'
import { PagingEmpty } from '../../commonLib/PagingEmpty'
import { Spinner } from '../../commonLib/Spinner'
import { dashboardContext } from '../Global/dashboardContext'
import * as productionUnitCrud from '../../_crud/productionUnitCrud'
import { GetEProductionUnitOwnershipName } from '../../enumCollections/EProductionUnitOwnership'
import { GetEBoolName } from '../../enumCollections/EBool';
import { GetEProductionCategoryTypeName } from '../../enumCollections/EProductionCategoryType';
import { UserView } from '../Global/Elements/UserView'
import { EmptyData } from '../../commonLib/EmptyData'
import { PrintStarter } from '../Prints/PrintStarter'
import { PrintProductionUnitPassbook } from '../Prints/PrintProductionUnitPassbook'
import { getIsPrint } from '../../config/GlobalSettings'

export function ManageProductionUnitContact() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [productionUnit, setProductionUnit] = useState([])
    const { id } = useParams()
    const [dashboardData, setDashboardData] = useContext(dashboardContext)

    useEffect(() => {
        getData()
    }, []);


    function getData() {
        setIsLoadingMini(true);
        productionUnitCrud.GetByContactId(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProductionUnit(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setIsLoadingMini(false)
                        setProductionUnit([]);
                    }
                }
                else {
                    setIsLoadingMini(false)
                    setProductionUnit([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }


    function doRefresh(event) {
        getData()
    }
    const [printData, setPrintData] = useState()
    const printRef = useRef();
    return (
        <>
            {getIsPrint && <div style={{ display: "none" }}>
                <PrintProductionUnitPassbook printData={printData} ref={printRef} />
            </div>}
            <Container title="Production Unit" doRefresh={doRefresh} isBack="true" isContact="true">

                <Link style={{ marginRight: "5px" }} to={"/contact/dashboard/" + id + "/productionunit/add"} class="btn btn-sm btn-info">
                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                    New
                </Link>

                <Link style={{ marginRight: "5px" }} to={"/contact/dashboard/" + id + "/productionunitallotment/add"} class="btn btn-sm btn-warning ">
                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                    Allot Existing Production
                </Link>
                <div class="hr dotted"></div>
                <PagingEmpty text={"Total " + productionUnit.length + " items."}>
                    {isLoadingMini ?
                        <Spinner />
                        :
                        productionUnit && productionUnit.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "43px" }}></th>
                                            {getIsPrint && <th style={{ width: "43px" }}></th>}
                                            <th>#Id</th>
                                            <th>Name</th>
                                            <th>Category</th>
                                            <th>Type</th>
                                            <th>Details</th>
                                            <th>Is Multi Product</th>
                                            <th>OwnerShip</th>
                                            <th style={{ width: "43px" }}></th>
                                            <th style={{ width: "43px" }}></th>
                                            <th style={{ width: "43px" }}></th>
                                            <th style={{ width: "43px" }}></th>
                                            <th style={{ width: "43px" }}></th>
                                            <th style={{ width: "43px" }}></th>
                                            <th style={{ width: "43px" }}></th>
                                            <th style={{ width: "43px" }}>By</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {productionUnit.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link className="btn btn-xs btn-info" to={"/productionunit/dashboard/" + item.id} title="View dashboard" ><i class="ace-icon fa fa-tachometer"></i></Link>
                                                    </div>
                                                </td>
                                                {getIsPrint && <td>
                                                    <div class="btn-group" title="Print Passbook Front">
                                                        <PrintStarter printRef={printRef} setPrintData={setPrintData} item={item} />
                                                    </div>
                                                </td>}
                                                <td>{item.id}</td>
                                                <td>{item.productionUnitName}</td>
                                                <td>{item.productionCategory.productionCatName}</td>
                                                <td><span class={item.productionCategory.type == 1 ? "label label-sm label-info" : "label label-sm label-warning"}>{GetEProductionCategoryTypeName(item.productionCategory.type)}</span></td>
                                                <td><div style={{ width: "150px" }}>{item.details}</div></td>
                                                <td><span class={item.isMultiProduct == 1 ? "label label-sm label-success" : "label label-sm label-danger"}>{GetEBoolName(item.isMultiProduct)}</span></td>
                                                <td><span class={item.ownerShip == 1 ? "label label-sm label-info" : "label label-sm label-warning"}>{GetEProductionUnitOwnershipName(item.ownerShip)}</span></td>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link to={item.productDesign && "/product/design/" + item.productDesign.productId + "/dashboard/" + item.productDesign.id} title="View Production Design" class="btn btn-xs btn-yellow" disabled={!item.productDesign}>
                                                            <i class="ace-icon fa fa-shopping-basket bigger-120"></i>
                                                        </Link>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link to={"/contact/dashboard/" + id + "/productionunit/lots/" + item.id} onClick={() => setDashboardData({ ...dashboardData, productiondata: item })} title="View Production Lots" class="btn btn-xs btn-pink">
                                                            <i class="ace-icon fa fa-code-fork bigger-120"></i>
                                                        </Link>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link to={"/contact/dashboard/" + id + "/productionunit/orders/" + item.id + "/initorderforproduction"} onClick={() => setDashboardData({ ...dashboardData, productiondata: item })} title="Init Orders" class="btn btn-xs btn-warning">
                                                            <i class="ace-icon fa fa-level-up bigger-120"></i>
                                                        </Link>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link to={"/contact/dashboard/" + id + "/productionunit/orders/" + item.id} onClick={() => setDashboardData({ ...dashboardData, productiondata: item })} title="Orders" class="btn btn-xs btn-info">
                                                            <i class="ace-icon fa fa-level-up bigger-120"></i>
                                                        </Link>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link to={"/contact/dashboard/" + id + "/productionunit/productdistribution/" + item.id} onClick={() => setDashboardData({ ...dashboardData, productiondata: item })} title="Product Distribution" class="btn btn-xs btn-info">
                                                            <i class="ace-icon fa fa-cube"></i><i class="ace-icon fa fa-arrow-up"></i>
                                                        </Link>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link to={"/contact/dashboard/" + id + "/productionunit/productreceiving/" + item.id} onClick={() => setDashboardData({ ...dashboardData, productiondata: item })} title="Product Receiving" class="btn btn-xs btn-success">
                                                            <i class="ace-icon fa fa-cube"></i><i class="ace-icon fa fa-arrow-down"></i>
                                                        </Link>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link to={"/contact/dashboard/" + id + "/productionunit/productreturn/" + item.id} onClick={() => setDashboardData({ ...dashboardData, productiondata: item })} title="Product Return" class="btn btn-xs btn-warning">
                                                            <i class="ace-icon fa fa-cube"></i><i class="ace-icon fa fa-arrow-left"></i>
                                                        </Link>
                                                    </div>
                                                </td>
                                                <td>
                                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </PagingEmpty >
            </Container >
        </>
    )
}
