import React from 'react'
import { useContext } from 'react';
import AppToaster from '../../commonLib/AppToaster';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import * as applicationUserCrud from '../../_crud/applicationUserCrud'
import { saveAs } from 'file-saver'
import { GetCurrentDateTime } from '../../commonLib/dateFormat';
import { Container } from '../../base/Container';

export function DonloadMediaFiles() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)

    const Download = () => {
        setIsLoadingMini(true);
        applicationUserCrud.DownloadBackup()
            .then(res => {
                saveAs(res.data, 'MediaBackup-' + GetCurrentDateTime() + '.zip')
                setIsLoadingMini(false)
            })
            .catch(error => {
                setIsLoadingMini(false);
                alert(error)
                //AppToaster(JSON.stringify(error), 'error')
            })
    }
    return (
        <Container title="Backup & Download">
            <div class="clearfix">
                <div className="row">
                    <div className="col-xs-12 center">
                        <br />
                        <br />
                        <span>
                            Note: Download contains Contact Photos, Contact Documents,<br />Producation Unit Document, Product Photo, and Cheque Transaction Photo.
                        </span>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <button onClick={Download} class="btn btn-lg btn-success">
                            <i class="ace-icon fa fa-download"></i>
                            Download Files
                        </button>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <hr />
                    </div>
                </div>
            </div>
        </Container>
    )
}
