import { useFormik } from 'formik'
import React, { useContext, useState } from 'react'
import AppToaster from './../../../commonLib/AppToaster'
import *as Yup from "yup"
import * as productionConfigurationCrud from './../../../_crud/productionConfigurationCrud'
import { useHistory, useParams } from 'react-router-dom'
import { Container } from './../../../base/Container'
import { LoadingButton } from './../../../commonLib/LoadingButton'
import { loadingMiniContext } from './../../../commonLib/lodingMiniContext'
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater'

const initialValues = {
  name: "",
  description: ""
}
export function AddProductionConfiguration() {
  const [isLoadingLocal, setIsLoadingLocal] = useState(false)
  const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
  const history = useHistory()
  const { id } = useParams()

  const Schema = Yup.object().shape({
    name: Yup.string().required("Configuration Name is required."),
    description: Yup.string().required("Description is required.")
  })

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values) => {
      Save(values);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });
  const Save = (value) => {
    setIsLoadingMini(true);
    setIsLoadingLocal(true)
    productionConfigurationCrud.Add(value)
      .then(res => {
        if (res.data.result.succeeded) {
          setIsLoadingMini(false)
          setIsLoadingLocal(false);
          AppToaster('Added successfully.', 'success')
          history.push("/productionunit/configuration/dashboard/" + res.data.data.id)
        }
        else {
          setIsLoadingMini(false)
          setIsLoadingLocal(false);
          AppToaster("Server Error /Invalid Try.", 'error')
        }
      })
      .catch(error => {
        setIsLoadingMini(false);
        setIsLoadingLocal(false);
        AppToaster(error, 'error')
      })
  }
  return (
    <>
      <Container title="Add Configuration" isBack="true">
        <form onSubmit={formik.handleSubmit} class="form-horizontal">
          <div className="row">
            <div className="col-md-6">
              <div class="form-group">
                <div class="col-xs-12">
                  <label class="control-label no-padding-right">Name</label>
                  <TextBoxTranslater className="form-control" type="text" placeholder="Configuration Name" name="name" value={formik.values.name} onChange={(e) => formik.setValues({ ...formik.values, name: e.target.value })} />
                  {
                    formik.touched.name &&
                      formik.errors.name ? (
                      <label className="text-danger" >
                        {formik.errors.name}
                      </label>
                    ) : null
                  }
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div class="form-group">
                <div class="col-xs-12">
                  <label class="control-label no-padding-right">Description</label>
                  <TextBoxTranslater className="form-control" type="text" placeholder="Description" value={formik.values.description} onChange={(e) => formik.setValues({ ...formik.values, description: e.target.value })} name="description" />
                  {
                    formik.touched.description &&
                      formik.errors.description ? (
                      <label className="text-danger" >
                        {formik.errors.description}
                      </label>
                    ) : null
                  }
                </div>
              </div>
            </div>
          </div>

          <div class="clearfix form-actions">
            <div class="pull-right">
              {isLoadingLocal ?
                <LoadingButton />
                :
                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                  <i class="ace-icon fa fa-check bigger-110"></i>
                  Add
                </button>}
            </div>
          </div>
        </form>
      </Container >
    </>
  )
}
