import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { Spinner } from '../../commonLib/Spinner';
import * as productionCategoryCrud from '../../_crud/productionCategoryCrud'
import { GetEProductionCategoryTypeName } from '../../enumCollections/EProductionCategoryType'
import { PagingEmpty } from '../../commonLib/PagingEmpty';
import { EmptyData } from '../../commonLib/EmptyData';
import { MyAlert } from '../../commonLib/MyAlert';

export function ManageCategory() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [productionCategory, setProductionCategory] = useState([])

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoadingMini(true);
        productionCategoryCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProductionCategory(hdata);
                    }
                    else {
                        setProductionCategory([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProductionCategory([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        getData()
        setTimeout(() => {
            event.detail.complete();
        }, 1000);
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        productionCategoryCrud.Remove(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    AppToaster('Deleted successfully.', 'success')
                    getData()
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster('Using by someone, could not delete.', 'error')
                }

            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network errror', 'error')
            })
    }

    return (
        <>
            <Container title="Production Category" doRefresh={doRefresh} isBack="true">
                <Link to="/productionunit/category/add" class="btn btn-sm btn-success">
                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                    New
                </Link>
                <div class="hr dotted"></div>
                <PagingEmpty text={"Total " + productionCategory.length + " items."}>
                    {isLoadingMini ?
                        <Spinner />
                        :
                        productionCategory && productionCategory.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "43px" }}>#Id</th>
                                            <th>Name</th>
                                            <th>Type</th>
                                            <th style={{ width: "83px" }}></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {productionCategory.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>{index + 1}</td>
                                                <td>{item.productionCatName}</td>
                                                <td><span class={item.type == 1 ? "label label-sm label-info" : "label label-sm label-warning"}>{GetEProductionCategoryTypeName(item.type)}</span></td>
                                                <td>
                                                    <div class="btn-group">
                                                        <Link to={"/productionunit/category/edit/" + item.id} class="btn btn-xs btn-success">
                                                            <i class="ace-icon fa fa-pencil bigger-120"></i>
                                                        </Link>&nbsp;
                                                        <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                            <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                        </MyAlert>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </PagingEmpty>
            </Container >
        </>
    )
}
