import React, { useContext } from 'react'
import { useState } from 'react';
import {
    ProSidebar, Menu, MenuItem, SubMenu,
    SidebarHeader, SidebarFooter, SidebarContent
} from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { Logout } from '../auth/Logout';
import { ADMINISTRATOR, OPERATION } from '../auth/RouteRoles';
import GetLoginInfo from '../auth/_userContext/GetLoginInfo';
import { DEFAULT_USER_IMG, ERROR_IMG } from '../config/GlobalSettings';
import { BASE_URL, IsMobile } from '../initApi/apiUrls';
import { organizationContext } from '../pages/Organization/organizationContext';
const initialsForSubMenus = {
    sub1: false,
    sub2: false,
    sub3: false,
    sub4: false,
    sub5: false,
    sub6: false,
    sub7: false,
    sub8: false,
    sub9: false,
    sub10: false,
}
export function MobileSidebar({ handleToggleSidebar, toggled }) {
    const [organization, setOrganization] = useContext(organizationContext);
    const [subMenuIsOpen, setSubMenuIsOpen] = useState(initialsForSubMenus)
    const userData = GetLoginInfo()
    const OnMenuClick = () => {
        handleToggleSidebar()
    }

    const onSubMenuOpenChange = (val, sunId) => {
        let initSub = { ...initialsForSubMenus, [sunId]: val }
        setSubMenuIsOpen(initSub)
    }

    return (
        <>
            <ProSidebar
                toggled={toggled}
                breakPoint="xxl"
                onToggle={handleToggleSidebar}
                style={{ marginTop: "0px", zIndex: "10009" }}>

                <SidebarHeader>
                    <div
                        style={{
                            padding: '18px',
                            //textTransform: 'uppercase',
                            //fontWeight: 'bold',
                            fontSize: 15,
                            //letterSpacing: '1px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            backgroundColor: "#438EB9",
                            color: "white"
                        }}
                    >
                        <div className="row">
                            <div className="col-xs-3" style={{ padding: "0px" }}>
                                <img className="img-round" style={{ margin: "0px" }} height="60px" width="60px" src={userData.isDP == 1 ? BASE_URL + "/" + userData.dpUrl : DEFAULT_USER_IMG}
                                    onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }}
                                />
                            </div>
                            <div className="col-xs-9">
                                <button type="button" class="close pull-right" style={{ fontSize: "28px" }} onClick={OnMenuClick}>×</button>
                                <span>
                                    <small>Welcome,</small>
                                    <br />
                                    <b>{userData.fullName}</b>
                                    <br />
                                    <small>({userData.userName})</small>
                                </span>
                            </div>
                        </div>
                    </div>
                </SidebarHeader>
                <SidebarContent>
                    <Menu>
                        <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-tachometer blue"></i>}>
                            <Link to="/home"> Dashboard </Link>
                        </MenuItem>
                        <SubMenu onOpenChange={(e) => onSubMenuOpenChange(!subMenuIsOpen.sub1, "sub1")} open={subMenuIsOpen.sub1} title="Reports" icon={<i class="menu-icon fa fa-line-chart red"></i>}>
                            <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-group"></i>}><Link to="/lastactivecontacts">Last Active Contacts</Link></MenuItem>
                            {userData.role == ADMINISTRATOR &&
                                <>
                                    <SubMenu title="Transactions" icon={<i class="menu-icon fa fa-inr"></i>}>
                                        <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-inr"></i>}><Link to="/cashflow">Cash & Bank Flow</Link></MenuItem>
                                        <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-inr"></i>}><Link to="/ledgersummary">Ledger Summary</Link></MenuItem>
                                        <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-inr"></i>}><Link to="/banktransfer">Bank Transfer</Link></MenuItem>
                                        <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-inr"></i>}><Link to="/advancecontactlist">Advance By Contact</Link></MenuItem>
                                        <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-inr"></i>}><Link to="/defectscontactlist">Defects By Contact</Link></MenuItem>
                                        <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-inr"></i>}><Link to="/defectmanagement">Defect Management</Link></MenuItem>
                                    </SubMenu>
                                    <SubMenu title="Product" icon={<i class="menu-icon fa fa-cube"></i>}>
                                        <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-cube pink"></i>}><Link to="/report/productdailyReoprt">Daily Report</Link></MenuItem>
                                        <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-arrow-circle-o-up blue"></i>}><Link to="/productdistribution">Distribution</Link></MenuItem>
                                        <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-arrow-circle-o-down green"></i>}><Link to="/productreceiving">Receiving</Link></MenuItem>
                                        <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-arrow-circle-o-left red"></i>}><Link to="/productreturn">Return</Link></MenuItem>
                                        <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-shopping-basket orange"></i>}><Link to="/report/productdesign">Design (Bewara)</Link></MenuItem>
                                        <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-cubes green"></i>}><Link title="Active Products(Category)" to="/report/activeproducts">Active Products(Category)</Link></MenuItem>
                                        <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-cube green"></i>}><Link title="Active Products" to="/report/activeproduct">Active Products</Link></MenuItem>
                                    </SubMenu>
                                    <SubMenu title="Processing" icon={<i class="menu-icon fa fa-recycle"></i>}>
                                        <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-recycle pink"></i>}><Link to="/report/processingdailyReoprt">Daily Report</Link></MenuItem>
                                        <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-arrow-circle-o-up blue"></i>}><Link to="/processingdistribution">Distribution</Link></MenuItem>
                                        <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-arrow-circle-o-down green"></i>}><Link to="/processingreceiving">Receiving</Link></MenuItem>
                                        <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-arrow-circle-o-left red"></i>}><Link to="/processingreturn">Return</Link></MenuItem>
                                    </SubMenu>
                                </>
                            }
                            <SubMenu title="Stocks" icon={<i class="menu-icon fa fa-stack-overflow"></i>}>
                                <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-recycle"></i>}><Link to="/processingcontactstock">Processing Contact</Link></MenuItem>
                                <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-cube"></i>}><Link to="/productstock">Products Contact</Link></MenuItem>
                                <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-code-fork "></i>}><Link to="/productreceivinglot">All Lots</Link></MenuItem>
                            </SubMenu>
                        </SubMenu>

                        <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-ticket green"></i>}>
                            <Link to="/gatepass">GatePass</Link>
                        </MenuItem>
                        <SubMenu onOpenChange={(e) => onSubMenuOpenChange(!subMenuIsOpen.sub2, "sub2")} open={subMenuIsOpen.sub2} title="Order" icon={<i class="menu-icon fa fa-level-up orange"></i>}>
                            <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-caret-right"></i>}><Link to="/order">Orders</Link></MenuItem>
                            <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-caret-right"></i>}><Link to="/stockorder">Stock Orders</Link></MenuItem>
                            <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-caret-right"></i>}><Link to="/initorderforproduction">Init Orders</Link></MenuItem>
                            <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-caret-right"></i>}><Link to="/orderpeocessingprint">Orders Processing Print</Link></MenuItem>
                        </SubMenu>
                        <SubMenu onOpenChange={(e) => onSubMenuOpenChange(!subMenuIsOpen.sub3, "sub3")} open={subMenuIsOpen.sub3} title="Vouchers" icon={<i class="menu-icon fa fa-inr purple"></i>}>
                            <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-caret-right"></i>}><Link to="/processingvoucher">Processing Voucher</Link></MenuItem>
                            <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-caret-right"></i>}><Link to="/productionvoucher">Production Voucher</Link></MenuItem>
                            <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-caret-right"></i>}><Link to="/advancevoucher">Advance Voucher</Link></MenuItem>
                            <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-caret-right"></i>}><Link to="/salaryvoucher">Salary Voucher</Link></MenuItem>
                            <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-caret-right"></i>}><Link to="/expensevoucher">Expense Voucher</Link></MenuItem>
                            <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-caret-right"></i>}><Link to="/cashbankcashvoucher">Cash Bank Voucher</Link></MenuItem>
                            {userData.role == ADMINISTRATOR &&
                                <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-caret-right"></i>}><Link to="/openingbalancevoucher">Opening Balance</Link></MenuItem>
                            }
                        </SubMenu>





                        {organization && organization.isWithWarehouse == 1 &&
                            < SubMenu onOpenChange={(e) => onSubMenuOpenChange(!subMenuIsOpen.sub9, "sub9")} open={subMenuIsOpen.sub9} title="Warehouse" icon={<i class="menu-icon fa fa-industry red"></i>}>
                                <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-exchange"></i>}><Link to="/warehousetransfer">{userData.isWarehouse == 1 ? userData.warehouseCode : "MAIN"} Transfers</Link></MenuItem>
                                {userData.role == ADMINISTRATOR &&
                                    <>
                                        <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-database"></i>}><Link to="/allwarehousetransfer">All Warehouse Transfers</Link></MenuItem>
                                        <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-industry"></i>}><Link to="/warehouse">Warehouse Manager</Link></MenuItem>
                                    </>
                                }
                            </SubMenu>
                        }

                        {userData.isWarehouse == 0 &&
                            <SubMenu onOpenChange={(e) => onSubMenuOpenChange(!subMenuIsOpen.sub10, "sub10")} open={subMenuIsOpen.sub10} title="Saree Draping" icon={<img height="22px" src='/images/sareeIco.png' />}>
                                <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-magic"></i>}><Link to="/draping">My DrapinGo</Link></MenuItem>
                                <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-universal-access"></i>}><Link to="/catalogue">Catalogues</Link></MenuItem>
                            </SubMenu>
                        }

                        <SubMenu onOpenChange={(e) => onSubMenuOpenChange(!subMenuIsOpen.sub6, "sub6")} open={subMenuIsOpen.sub6} title="Masters" icon={<i class="menu-icon fa fa-hashtag green"></i>}>
                            <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-group"></i>}><Link to="/contact">Contact</Link></MenuItem>
                            <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-cubes"></i>}><Link to="/product">Products</Link></MenuItem>
                            <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-building"></i>}><Link to="/productionunit">Production Unit</Link></MenuItem>
                            {userData.role == ADMINISTRATOR &&
                                <>
                                    <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-recycle"></i>}><Link to="/processing">Processing</Link></MenuItem>
                                    <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-money"></i>}><Link to="/ledger">Ledger</Link></MenuItem>
                                </>
                            }
                        </SubMenu>
                        {userData.isWarehouse == 0 &&
                            <SubMenu onOpenChange={(e) => onSubMenuOpenChange(!subMenuIsOpen.sub4, "sub4")} open={subMenuIsOpen.sub4} title="Cheque Management" icon={<i class="menu-icon fa fa-money pink"></i>}>
                                {userData.role == ADMINISTRATOR &&
                                    <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-pie-chart"></i>}><Link to="/bankreports">Reports</Link></MenuItem>
                                }
                                <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-exchange"></i>}><Link to="/transaction">Transaction</Link></MenuItem>
                                {userData.role == ADMINISTRATOR &&
                                    <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-university"></i>}><Link to="/bank">Manange Bank Account</Link></MenuItem>
                                }
                            </SubMenu>
                        }
                        {userData.role == ADMINISTRATOR &&
                            <SubMenu onOpenChange={(e) => onSubMenuOpenChange(!subMenuIsOpen.sub7, "sub7")} open={subMenuIsOpen.sub7} title="Histories" icon={<i class="menu-icon fa fa-history orange"></i>}>
                                <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-search"></i>}><Link to="/history">View/ Find</Link></MenuItem>
                                <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-trash"></i>}><Link to="/removehistory">Delete Range</Link></MenuItem>
                                <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-braille "></i>}><Link to="/loginlogs">Login Logs</Link></MenuItem>
                                <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-search"></i>}><Link to="/rechargehistory">Recharge History</Link></MenuItem>
                            </SubMenu>
                        }
                        {userData.role == ADMINISTRATOR &&
                            <SubMenu onOpenChange={(e) => onSubMenuOpenChange(!subMenuIsOpen.sub8, "sub8")} open={subMenuIsOpen.sub8} title="Settings" icon={<i class="menu-icon fa fa-cogs pink"></i>}>
                                <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-university"></i>}><Link to="/organization/profile">Organization</Link></MenuItem>
                                <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon glyphicon glyphicon-user"></i>}><Link to="/users">Users</Link></MenuItem>
                                <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-inr"></i>}><Link to="/fiscalyearclosing">Fiscal Year Closing</Link></MenuItem>
                                <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-key"></i>}><Link to="/location">Location</Link></MenuItem>
                                {!IsMobile && <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-download"></i>}><Link to="/downloadbackup">Backup & Downloads</Link></MenuItem>}
                            </SubMenu>
                        }
                        <div class="hr"></div>
                        {userData.role == ADMINISTRATOR &&
                            <MenuItem onClick={OnMenuClick} icon={<img height="12px" src='/images/recharge.gif' />}>
                                <Link to="/rechargeplans">Recharge Plans</Link>
                            </MenuItem>
                        }
                        <MenuItem onClick={OnMenuClick} icon={<i class="ace-icon fa fa-user blue"></i>}>
                            <Link to="/profile/dp">Profile</Link>
                        </MenuItem>
                        <MenuItem onClick={OnMenuClick} icon={<i class="ace-icon fa fa-key purple"></i>}>
                            <Link to="/changepassword">Change Password</Link>
                        </MenuItem>
                        <MenuItem onClick={OnMenuClick} icon={<i class="ace-icon fa fa-youtube red"></i>}>
                            <a href='https://www.youtube.com/playlist?list=PLFqp5yCewzQrI0KgXGt0nLpy46Ongz78H' target='_blank'>Video Tutorials &nbsp;&nbsp;<i class="ace-icon fa fa-external-link"></i></a>
                        </MenuItem>
                        <MenuItem onClick={OnMenuClick} icon={<i class="ace-icon fa fa-power-off red"></i>}>
                            <Logout />
                        </MenuItem>
                    </Menu>
                </SidebarContent>

                <SidebarFooter style={{ textAlign: 'center' }}>
                    <div
                        className="sidebar-btn-wrapper"
                        style={{
                            padding: '20px 24px',
                        }}
                    >
                        <p>
                            <span style={{ verticalAlign: "bottom" }}>Powered&nbsp;By </span><a href="https://cubicle8.com" target="_blank">
                                <img style={{ marginBottom: "3px" }} height="32px" src="images/cubiclelogo.gif" />
                            </a>
                        </p>
                    </div>
                </SidebarFooter>
            </ProSidebar>
        </>
    )
}
