import React, { useState } from 'react'
import GetLanguage from './GetLanguage';

export const languageContext = React.createContext();

const Language = (props) => {
    const lang = GetLanguage()
    const [language, setLanguage] = useState(lang ? lang : "en");
    return (
        <languageContext.Provider value={[language, setLanguage]}>
            {props.children}
        </languageContext.Provider>
    );
}

export default Language;
