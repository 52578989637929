const GetELedgerLinkNameName = (id) => {
    switch (id) {
        case 1: { return "Global" }
        case 2: { return "Bank" }
    }
}

const GetELedgerLinkName = {
    ELedgerLinkName: [
        { Id: 1, Name: "Global" },
        { Id: 2, Name: "Bank" },
    ]
}

export { GetELedgerLinkNameName, GetELedgerLinkName }