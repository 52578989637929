const PoseBg = (id) => {
    switch (id) {
        case 1: { return "./assets/images/Draper/new/bgwhite.png" }
        case 2: { return "./assets/images/Draper/new/bggray.png" }
        case 3: { return "./assets/images/Draper/new/bgblue.png" }
        case 4: { return "./assets/images/Draper/new/bgmaroon.png" }
        case 5: { return "./assets/images/Draper/new/bgrainbow.png" }
        case 6: { return "./assets/images/Draper/new/bgrainbowx.png" }
        case 7: { return "./assets/images/Draper/new/bgabstract.png" }
        case 8: { return "./assets/images/Draper/new/bgtransparent.png" }
        case 9: { return "./assets/images/Draper/new/bgmatteblack.png" }
        default: { return "./assets/images/Draper/new/bgtransparent.png"; }
    }
}

const GetPoseBg = {
    PoseBg: [
        { Id: 1, Name: "White" },
        { Id: 2, Name: "Gray" },
        { Id: 3, Name: "Blue" },
        { Id: 4, Name: "Maroon" },
        { Id: 5, Name: "Rainbow" },
        { Id: 6, Name: "Rainbowx" },
        { Id: 7, Name: "Abstract" },
        { Id: 8, Name: "Transparent" },
        { Id: 9, Name: "Matte Black" }
    ]
}

export { PoseBg, GetPoseBg }