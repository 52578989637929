import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup'
import { Container } from '../../../base/Container';
import AppToaster from '../../../commonLib/AppToaster';
import { LoadingButton } from '../../../commonLib/LoadingButton';
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import * as salaryVoucherCrud from '../../../_crud/vouchers/salaryVoucherCrud'
import ContactSelector from '../../Global/ContactSelector';
import { ContactSelectorView } from '../../Global/SelectorView/ContactSelectorView';
import DatePicker from "react-datepicker";
import GetCurrentDateForSearch from '../../../commonLib/dateFormat';
import GetLoginInfo from '../../../auth/_userContext/GetLoginInfo';

const initialValues = {
    contactId: "",
    ammount: 0,
    voucherDate: new Date(GetCurrentDateForSearch(0)),
}

export function AddSalaryVoucher() {
    const history = useHistory();
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [selectedContactData, setSelectedContactData] = useState(undefined);
    const userData = GetLoginInfo()

    const Schema = Yup.object().shape({
        contactId: Yup.string().required("Contact Id is required."),
        ammount: Yup.string().required("Ammount is required."),
        voucherDate: Yup.string().required("Date is required."),
    })

    useEffect(() => {
        if (selectedContactData) {
            formik.setValues({ ...formik.values, contactId: selectedContactData.id })
        }
    }, [selectedContactData]);


    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });
    const Save = (value) => {
        if (value.ammount > 0) {
            setIsLoadingMini(true);
            setIsLoadingLocal(true)
            salaryVoucherCrud.VoucherAdd(value)
                .then(res => {
                    if (res.data.result.succeeded) {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false);
                        AppToaster('Added successfully.', 'success')
                        history.push("/salaryvoucher/dashboard/" + res.data.data.voucher.id)

                    }
                    else {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                })
                .catch(error => {
                    setIsLoadingMini(false);
                    setIsLoadingLocal(false);
                    AppToaster(error, 'error')
                })
        }
        else {
            AppToaster("Amount should greater then zero.", 'error')
        }
    }

    return (
        <>
            <Container title="Add Salary Voucher" isBack="true">
                <form onSubmit={formik.handleSubmit} class="form-horizontal">
                    <div className="row">
                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Contact</label>
                                    <ContactSelector searchtext="" setSelectedData={setSelectedContactData} isWarehouse={userData.isWarehouse == 0 ? false : true}/>
                                    {
                                        formik.touched.contactId &&
                                            formik.errors.contactId ? (
                                            <label className="text-danger" >
                                                {formik.errors.contactId}
                                            </label>
                                        ) : null
                                    }
                                </div>
                                <ContactSelectorView data={selectedContactData} />
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Voucher Date</label>
                                    <span class="input-icon" style={{ width: "100%" }}>
                                        <DatePicker placeholder="Voucher Date" name="voucherDate" className="form-control input-date-padding" selected={formik.values.voucherDate} onChange={(e) => formik.setValues({ ...formik.values, voucherDate: e })} />
                                        <i class="ace-icon fa fa-calendar blue"></i>
                                    </span>
                                    {
                                        formik.touched.voucherDate &&
                                            formik.errors.voucherDate ? (
                                            <label className="text-danger" >
                                                {formik.errors.voucherDate}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>


                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Amount</label>
                                    <input className="form-control" type="number" placeholder="Amount" name="ammount" {...formik.getFieldProps("ammount")} />
                                    {
                                        formik.touched.ammount &&
                                            formik.errors.ammount ? (
                                            <label className="text-danger" >
                                                {formik.errors.ammount}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="clearfix form-actions">
                        <div class="pull-right">
                            {isLoadingLocal ?
                                <LoadingButton />
                                :
                                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Add
                                </button>}
                        </div>
                    </div>
                </form>
            </Container >
        </>
    )
}
