import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import LoginRedirect from './LoginRedirect';
import { authContext } from './_userContext/authContext';
import ClearLoginInfo from './_userContext/ClearLoginInfo';

export function Logout() {

    const [isLogin, setIsLogin] = useContext(authContext)
    const history = useHistory();

    const logout = () => {
        ClearLoginInfo();
        setIsLogin(false);
        history.push("/login")
    }
    return (
        <>
            <a className="red" onClick={logout} style={{ cursor: "pointer" }}>
                <b>Logout</b>
            </a>
        </>

    );
}