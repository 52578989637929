const GetEPaymentStatusName = (id) => {
    switch (id) {
        case 0: { return "Pending" }
        case 1: { return "Paid" }
        case 2: { return "Partial" }
    }
}

const GetEPaymentStatus = {
    EPaymentStatus: [
        { Id: 0, Name: "Pending" },
        { Id: 2, Name: "Partial" },
        { Id: 1, Name: "Paid" },
    ]
}

export { GetEPaymentStatusName, GetEPaymentStatus }