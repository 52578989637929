import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetAll(rechargeHistory) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("RechargeHistory/GetAll", rechargeHistory)
    }
}

//Recharge
export async function GetRechargePlanInfo(Slab) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("RechargeHistory/GetRechargePlanInfo?Slab=" + Slab)
    }
}
export async function InitRecharge(Slab) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("RechargeHistory/InitRecharge?Slab=" + Slab)
    }
}
export async function AfterRecharge(rechargeID, paymentId, signature) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("RechargeHistory/AfterRecharge?rechargeID=" + rechargeID + "&paymentId=" + paymentId + "&signature=" + signature)
    }
}
export async function FailedRecharge(rechargeID, paymentId, note) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("RechargeHistory/FailedRecharge?rechargeID=" + rechargeID + "&paymentId=" + paymentId + "&note=" + note)
    }
}


//Recharge Draping
export async function GetRechargeDrapingPlanInfo(Count) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("RechargeHistory/GetRechargeDrapingPlanInfo?Count=" + Count)
    }
}
export async function InitRechargeDraping(Count) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("RechargeHistory/InitRechargeDraping?Count=" + Count)
    }
}
export async function AfterRechargeDraping(rechargeID, paymentId, signature) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("RechargeHistory/AfterRechargeDraping?rechargeID=" + rechargeID + "&paymentId=" + paymentId + "&signature=" + signature)
    }
}
export async function FailedRechargeDraping(rechargeID, paymentId, note) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("RechargeHistory/FailedRechargeDraping?rechargeID=" + rechargeID + "&paymentId=" + paymentId + "&note=" + note)
    }
}


//Upgrade Enterprise
export async function GetUpgradeEnterprisePlanInfo() {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("RechargeHistory/GetUpgradeEnterprisePlanInfo")
    }
}
export async function InitUpgradeEnterprise() {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("RechargeHistory/InitUpgradeEnterprise")
    }
}
export async function AfterUpgradeEnterprise(rechargeID, paymentId, signature) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("RechargeHistory/AfterUpgradeEnterprise?rechargeID=" + rechargeID + "&paymentId=" + paymentId + "&signature=" + signature)
    }
}
export async function FailedUpgradeEnterprise(rechargeID, paymentId, note) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("RechargeHistory/FailedUpgradeEnterprise?rechargeID=" + rechargeID + "&paymentId=" + paymentId + "&note=" + note)
    }
}



//Recharge
export async function GetIncreaseSpacePlanInfo(IncreaseGB) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("RechargeHistory/GetIncreaseSpacePlanInfo?IncreaseGB=" + IncreaseGB)
    }
}
export async function InitIncreaseSpace(IncreaseGB) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("RechargeHistory/InitIncreaseSpace?IncreaseGB=" + IncreaseGB)
    }
}
export async function AfterIncreaseSpace(rechargeID, paymentId, signature) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("RechargeHistory/AfterIncreaseSpace?rechargeID=" + rechargeID + "&paymentId=" + paymentId + "&signature=" + signature)
    }
}
export async function FailedIncreaseSpace(rechargeID, paymentId, note) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("RechargeHistory/FailedIncreaseSpace?rechargeID=" + rechargeID + "&paymentId=" + paymentId + "&note=" + note)
    }
}