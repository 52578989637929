import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import AppToaster from '../../commonLib/AppToaster';
import * as productionUnitProductAllotmentCrud from '../../_crud/productionUnitProductAllotmentCrud'
import { Container } from '../../base/Container';
import { GetEStatus, GetEStatusName } from '../../enumCollections/EStatus';
import { Spinner } from '../../commonLib/Spinner';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { loadingContext } from '../../commonLib/_lodingContext';
import 'moment-timezone';
import { PagingEmpty } from '../../commonLib/PagingEmpty';
import { EmptyData } from '../../commonLib/EmptyData';
import { UserView } from '../Global/Elements/UserView';
import { ProductView } from '../Global/Elements/ProductView';

const initEdit = {
    id: 0,
    isEdit: false,
    value: undefined
}

export function ManageProductAllotment() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [productAllotment, setProductAllotment] = useState([])
    const [edit, setEdit] = useState(initEdit)
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const { id } = useParams();

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoadingMini(true);
        productionUnitProductAllotmentCrud.GetAll(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProductAllotment(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setIsLoadingMini(false)
                        setProductAllotment([]);
                    }
                }
                else {
                    setIsLoadingMini(false)
                    setProductAllotment([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        getData()
        setTimeout(() => {
            event.detail.complete();
        }, 1000);
    }

    const Update = () => {
        setIsLoading(true);
        productionUnitProductAllotmentCrud.UpdateStatus({ id: edit.value.id, status: edit.value.status })
            .then(res => {
                if (res.data.succeeded) {
                    setEdit(initEdit)
                    doRefresh()
                    AppToaster('Updated successfully.', 'success')
                    setIsLoading(false);
                }
                else {
                    AppToaster("Server Error /Invalid Try.", 'error')
                    setIsLoading(false);
                }

            }).catch(error => {
                setEdit(initEdit)
                AppToaster(error, 'error')
                setIsLoading(false);
            })
    }

    return (
        <>
            <Container title="Product Allotment" doRefresh={doRefresh} isBack="true" isProduction="true" isContact="true">
                <Link to={"/productionunit/dashboard/" + id + "/productallotment/add"} class="btn btn-sm btn-success ">
                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                    New
                </Link>
                <div class="hr dotted"></div>
                <PagingEmpty text={"Total " + productAllotment.length + " items."}>
                    {isLoadingMini ?
                        <Spinner />
                        :
                        productAllotment && productAllotment.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "43px" }}>#Id</th>
                                            <th>Product</th>
                                            <th style={{ minWidth: "90px" }}>Status</th>
                                            <th style={{ width: "43px" }}>By</th>
                                            <th style={{ width: "43px" }}></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {productAllotment.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>{item.id}</td>
                                                <td>
                                                    <Link to={"/product/dashboard/" + item.productId}>
                                                        <ProductView item={item.product} uid={"PP2" + index} />
                                                    </Link>
                                                </td>
                                                <td>{edit.isEdit && item.id == edit.id ?
                                                    <select className="form-control" name="status" value={edit.value.status} onChange={(e) => setEdit({ ...edit, value: { ...edit.value, status: e.target.value } })}>
                                                        {
                                                            GetEStatus.EStatus.map((data, index) =>
                                                                <option value={data.Id}>{data.Name}</option>
                                                            )
                                                        }
                                                    </select>
                                                    :
                                                    <span class={item.status == 1 ? "label label-sm label-success" : "label label-sm label-danger"}>{GetEStatusName(item.status)}</span>
                                                }
                                                </td>
                                                <td>
                                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        {edit.isEdit && item.id == edit.id ?
                                                            isLoading ?
                                                                <button disabled class="btn btn-xs btn-info">
                                                                    <i class="ace-icon fa fa-pencil bigger-120"></i> Updating...
                                                                </button>
                                                                :
                                                                <>
                                                                    <button onClick={Update} class="btn btn-xs btn-info">
                                                                        <i class="ace-icon fa fa-pencil bigger-120"></i> Update
                                                                    </button>
                                                                    <button onClick={() => setEdit(initEdit)} class="btn btn-xs btn-default">
                                                                        Cancel
                                                                    </button>
                                                                </>
                                                            :
                                                            <button onClick={() => setEdit({ id: item.id, value: item, isEdit: true })} class="btn btn-xs btn-success">
                                                                <i class="ace-icon fa fa-pencil bigger-120"></i>
                                                            </button>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </PagingEmpty>
            </Container >
        </>
    )
}
