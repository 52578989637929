import React from 'react'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import GetLoginInfo from '../../../auth/_userContext/GetLoginInfo'

export function WarehouseView({ item, uid }) {
    const userData = GetLoginInfo()

    return (
        <>
            <a data-tip data-for={uid}
                class={item ? "label label-sm label-pink" : "label label-sm label-info"}
                style={{
                    //fontWeight: "bold",
                    // textShadow: "1px 2px 2px #434343"
                }}>
                <i class="menu-icon fa fa-industry"></i> {item ? item.warehouseCode : "MAIN"}
            </a>
            <ReactTooltip arrowColor="black" borderColor="#9b9b9b" className="opaque" id={uid} type="dark">
                <div style={{ padding: "10px" }}>
                    {item ?
                        <>
                            <b>{item.name}</b>
                            <br />Address: {item.address}
                            <br />City: {item.city}
                        </>
                        : <div className='bolder' style={{textAlign:"center"}}>Main Warehouse</div>
                    }
                </div>
            </ReactTooltip>
        </>
    )
}
