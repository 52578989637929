import React from 'react'
import { getPageMargins } from '../../config/GlobalSettings'
import { ContactViewS } from '../Global/Elements/ContactViewS'
import { PrintBranding } from './PrintBranding'

export const ExpenseVoucherPrint = React.forwardRef((props, ref) => {
    const data = props.printData
    return (
        <div ref={ref}>
            <style type="text/css">
                {getPageMargins()}
            </style>
            {
                data &&
                <PrintBranding printName="Expense Voucher" date={data.voucherDate} id={data.id}>
                    {
                        data &&
                        <div className="row">
                            <div className="col-xs-12">

                                <table style={{ width: "100%" }} className="table-bordered-dark">
                                    <tbody>
                                        <tr >
                                            <td>Contact</td>
                                            <td>
                                                <ContactViewS item={data.contact} uid={"CX1"} />
                                            </td>
                                        </tr>
                                        <tr >
                                            <td>Amount</td>
                                            <td><b> ₹ {data.totalAmount} </b></td>
                                        </tr>
                                        <tr >
                                            <td>Details</td>
                                            <td><div dangerouslySetInnerHTML={{ __html: data.linkedDetails }} /></td>
                                        </tr>
                                        <tr >
                                            <td>Note</td>
                                            <td>{data.note ? data.note : "NA"}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    }
                </PrintBranding>
            }
        </div>
    )
})