import React from 'react'
import { Link } from 'react-router-dom'
import { EmptyData } from '../../commonLib/EmptyData'
import { GetEBoolName } from '../../enumCollections/EBool'
import { ContactView } from '../Global/Elements/ContactView'
import { ProductionUnitView } from '../Global/Elements/ProductionUnitView'
import { ProductView } from '../Global/Elements/ProductView'
import { UserView } from '../Global/Elements/UserView'
import Moment from 'react-moment';
import { GetEOrderStatusName } from '../../enumCollections/EOrderStatus'
import { StatusView } from '../../commonLib/StatusView';

export function OrderSelector({ data, setOrderOnSelect, selectedId }) {
    return (
        data && data.length > 0 ?
            <div className="tables-scroll">
                <table className="table table-hover table-bordered">
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>#Id</th>
                            <th>On Date</th>
                            <th>Alloted To</th>
                            <th>To Production</th>
                            <th>Product</th>
                            <th>Quantity</th>
                            <th>For Production</th>
                            <th>Status</th>
                            <th>By</th>
                        </tr>
                    </thead>


                    <tbody>
                        {data.map((item, index) =>
                            <tr key={item.id}>
                                <td>
                                    <div class="btn-group">
                                        <a className={selectedId == item.id ? "btn btn-xs btn-success" : "btn btn-xs btn-info"} onClick={() => { selectedId == item.id ? setOrderOnSelect(undefined) : setOrderOnSelect(item) }} >{selectedId == item.id ? "Selected" : "Select"}</a>
                                    </div>
                                </td>
                                <td>
                                    <div class="btn-group">
                                        <Link className="btn btn-xs btn-info" to={"/order/dashboard/" + item.id} title="View dashboard" ><i class="ace-icon fa fa-tachometer"></i></Link>
                                    </div>
                                </td>
                                <td>{item.id}</td>
                                <td><Moment format="DD-MMM-YYYY">{item.onDate}</Moment></td>
                                <td>
                                    <Link to={"/contact/dashboard/" + item.allotedToContactId}>
                                        <ContactView item={item.toContact} uid={"C" + index} />
                                    </Link>
                                </td>
                                <td>
                                    <Link to={"/productionunit/dashboard/" + item.productionUnitId}>
                                        <ProductionUnitView item={item.toProductionUnit} />
                                    </Link>
                                </td>
                                <td><Link to={"/product/dashboard/" + item.product.id}>
                                    <ProductView item={item.product} uid={"PP" + index} />
                                </Link></td>
                                <td>{item.quantity} {item.unit}</td>

                                <td>{item.isForProductionUnit == 1 ? (item.forProductionUnitData ? <Link to={"/productionunit/dashboard/" + item.productionUnitId}>
                                    <ProductionUnitView item={item.forProductionUnitData} />
                                </Link> : "NA") : <span class="label label-sm label-danger">{GetEBoolName(item.isForProductionUnit)}</span>}</td>
                                <td><StatusView status={item.status}>{GetEOrderStatusName(item.status)}</StatusView></td>
                                <td>
                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            :
            <EmptyData>
                No order found.
                <br />
                <br />
            </EmptyData>
    )
}
