import React, { useContext } from 'react'
import { loadingMiniContext } from './lodingMiniContext'
import { loadingContext } from './_lodingContext'
import BeatLoader from "react-spinners/BeatLoader";

export function GlobalSpinner({ color = "white", size = 7 }) {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoading, setIsLoading] = useContext(loadingContext)
    return (
            <BeatLoader t color={color} loading={(isLoadingMini || isLoading)} size={size} />
    )
} 
