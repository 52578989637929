import React, { useContext, useEffect, useState } from 'react'
import Autocomplete from "react-autocomplete";
import './../Global/AutoComplete.css';
import * as contactCrud from '../../_crud/contactCrud'
import useDebounce from './useDebounce';
import { Link, useHistory } from 'react-router-dom';
import { DEFAULT_IMG, DEFAULT_USER_IMG, ERROR_IMG } from '../../config/GlobalSettings';
import { BASE_URL } from '../../initApi/apiUrls';
import GetLoginInfo from '../../auth/_userContext/GetLoginInfo';
import { WarehouseViewS } from './Elements/WarehouseViewS';

export function ContactSearch({ searchtext }) {
    const [isLocalLoading, setIsLocalLoading] = useState(false)
    const [searchTerm, setSearchTerm] = useState(searchtext);
    const [searchDebounce, setSearchDebounce] = useDebounce(searchtext, 500);
    const [data, setData] = useState([]);
    const history = useHistory()
    const userData = GetLoginInfo()

    useEffect(() => {
        setSearchDebounce(searchTerm)
    }, [searchTerm]);
    useEffect(() => {
        if (userData.isWarehouse == 1)
            getDataWarehouse()
        else
            getData()
    }, [searchDebounce]);

    function getData() {
        setIsLocalLoading(true);
        let a;
        a = searchTerm;
        if (!searchTerm) { a = '' }
        contactCrud.GetAll({ searchTerm: a, pageNo: 1, pageSize: 4 })
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setData(hdata);
                        setIsLocalLoading(false)
                    }
                    else {
                        setIsLocalLoading(false)
                        setData([]);
                    }
                }
                else {
                    setIsLocalLoading(false)
                    setData([]);
                }
            })
            .catch(error => {
                setIsLocalLoading(false);
            })
    }

    function getDataWarehouse() {
        setIsLocalLoading(true);
        let a;
        a = searchTerm;
        if (!searchTerm) { a = '' }
        contactCrud.GetAllWarehouse({ Search: { searchTerm: a, pageNo: 1, pageSize: 4 }, WarehouseId: userData.warehouseId })
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setData(hdata);
                        setIsLocalLoading(false)
                    }
                    else {
                        setIsLocalLoading(false)
                        setData([]);
                    }
                }
                else {
                    setIsLocalLoading(false)
                    setData([]);
                }
            })
            .catch(error => {
                setIsLocalLoading(false);
            })
    }

    function selectId(e) {
        let sid = e.split('|')[1]
        setSearchTerm("")
        // const win = window.open("/#/contact/dashboard/" + sid, "_blank");
        // win.focus();
        history.replace("/contact/dashboard/" + sid);
        history.push('/loading');
        history.goBack();

    }
    return (
        <>
            <div class="nav-search" id="nav-search">
                <div class="form-search">
                    <span class="input-icon MySearchIcon" style={{ width: "100%" }}>
                        <div className="autocomplete-wrapper">
                            <Autocomplete
                                inputProps={{ placeholder: "Type to search contact", className: "nav-search-input", id: "nav-search-input", autocomplete: "off" }}
                                //renderInput={(props) => { return <input {...props} /> }}
                                value={searchTerm}
                                items={data}
                                getItemValue={item => item.fullName + "|" + item.id}
                                renderMenu={item => (
                                    <div className="dropdown" style={{ maxHeight: "none" }}>
                                        {item}
                                        <div class="profile-activity clearfix" style={{ cursor: "pointer" }}>
                                            <div class="center">
                                                <Link to="/contact/add">
                                                    <i class="ace-icon fa fa-plus green"></i> Add Contact</Link>
                                                    &nbsp;&nbsp;|&nbsp;&nbsp;
                                                <Link to="/product/add">
                                                    <i class="ace-icon fa fa-plus green"></i> Add Product</Link>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                renderItem={(item, isHighlighted) =>
                                    <div class={isHighlighted ? "profile-activity clearfix MyItem-selected-item" : "profile-activity clearfix"} style={{ cursor: "pointer" }}>
                                        <div>
                                            <img style={{ objectFit: "cover" }} height="40px" width="40px" className="pull-left" src={item.isPhoto == 1 ? (BASE_URL + "/" + item.photoUrl) : DEFAULT_USER_IMG}
                                                onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
                                            <a class="user">{item.fullName}</a> <small>({item.productionCategory ? item.productionCategory.productionCatName + "-" : ""}{item.refId ? item.refId : item.id})</small>
                                            <br /><small><WarehouseViewS isOnlyCode={true} item={item.warehouse} /><span> | {item.address}</span></small>
                                        </div>
                                    </div>

                                }
                                onChange={(event, val) => setSearchTerm(val)}
                                onSelect={val => selectId(val)}
                            />
                        </div>
                        {isLocalLoading ?
                            <i class="ace-icon fa fa-spinner fa-spin blue"></i>
                            :
                            <i class="ace-icon fa fa-group blue "></i>
                        }
                    </span>
                </div>
            </div>
        </>
    )
}