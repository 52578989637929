import React, { useEffect, useState } from 'react'
import { ERROR_IMG } from '../config/GlobalSettings';
import Resizer from "react-image-file-resizer";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const defaultImg = "images/default.png"

export function ImageUploader({ SetImage, initImage, refreshInitImage, Width = 800, Height = 800 }) {
    const [value, setValue] = useState(initImage ? initImage : defaultImg);

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                Width,
                Height,
                "png",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    useEffect(() => {
        setValue(initImage ? initImage : defaultImg)
    }, [refreshInitImage])

    const showPreview = (e) => {
        if (e.target.files && e.target.files[0]) {
            let imageFile = e.target.files[0]
            const reader = new FileReader()
            reader.onload = (x) => {
                setValue(x.target.result)
            }
            //reader.readAsDataURL(imageFile)
            // SetImage(imageFile)
            resizeFile(imageFile).then((data) => {
                reader.readAsDataURL(data)
                SetImage(data)
            }).catch((error) => {
                alert(error)
            })
        } else {
            setValue(defaultImg)
            SetImage("")
        }
    }

    return (
        <div className="card">
            <Zoom>
                <img src={value} width="100%" className="card-img-top" onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
            </Zoom>
            <div className="card-body center">
                <input type="file" accept="image/*" className="form-control" onChange={showPreview} />
            </div>
        </div>
    )
}