const GetEGatePassProductsTypeName = (id) => {
    switch (id) {
        case 0: { return "Fresh" }
        case 1: { return "Defective" }
        case 2: { return "Rejected" }
        case 3: { return "Raw" }
    }
}

const GetEGatePassProductsType = {
    EGatePassProductsType: [
        { Id: 0, Name: "Fresh" },
        { Id: 3, Name: "Raw" },
        { Id: 1, Name: "Defective" },
        { Id: 2, Name: "Rejected" }
    ]
}

export { GetEGatePassProductsTypeName, GetEGatePassProductsType }