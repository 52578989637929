import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../../../base/Container'
import AppToaster from '../../../commonLib/AppToaster'
import * as productionWageVoucherCrud from '../../../_crud/vouchers/productionWageVoucherCrud'
import { useFormik } from 'formik'
import * as Yup from "yup"
import { Link, useHistory } from 'react-router-dom'
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext'
import { LoadingButton } from '../../../commonLib/LoadingButton'
import ContactSelector from '../../Global/ContactSelector'
import { ContactSelectorView } from '../../Global/SelectorView/ContactSelectorView';
import GetCurrentDateForSearch from '../../../commonLib/dateFormat';
import DatePicker from "react-datepicker";
import * as productionUnitCrud from '../../../_crud/productionUnitCrud'
import { EmptyData } from '../../../commonLib/EmptyData'
import { UserView } from '../../Global/Elements/UserView'
import { GetEBoolName } from '../../../enumCollections/EBool'
import { ProductionUnitView } from '../../Global/Elements/ProductionUnitView'
import { ProcessingView } from '../../Global/Elements/ProcessingView'
import { PagingEmpty } from '../../../commonLib/PagingEmpty'
import { ProductView } from '../../Global/Elements/ProductView'
import Moment from 'react-moment'
import { Spinner } from '../../../commonLib/Spinner'
import GetLoginInfo from '../../../auth/_userContext/GetLoginInfo'
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater'

// const initialValues = {
//     Voucher: {
//         linkedId: "",
//         contactId: "",
//         voucherType: 4,
//         linkedIdType: 0,
//         paymentStatus: 0,
//         totalAmount: 0,
//         voucherDate: new Date(GetCurrentDateForSearch(0)),
//         note: ""
//     },
//     productionUnitId: "",
//     defectiveDeductionPercent: 100
// }

const initialValues = {
    linkedId: "",
    contactId: "",
    voucherType: 4,
    linkedIdType: 0,
    paymentStatus: 0,
    totalAmount: 0,
    voucherDate: new Date(GetCurrentDateForSearch(0)),
    note: "",
    productionUnitId: "",
    defectiveDeductionPercent: 0,
    isCustomAmount: 0,
    customAmount: 0
}

export function AddProductionVoucher() {
    const history = useHistory();
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [selectedContactData, setSelectedContactData] = useState(undefined)
    const [production, setProduction] = useState([])
    const [productionReceiving, setProductionReceving] = useState()
    const [count, setCount] = useState({ weight: 0 })
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const userData = GetLoginInfo()

    const Schema = Yup.object().shape({
        //ledgerId: Yup.string().required("From is required."),
        linkedId: Yup.string().required("To is required."),
        contactId: Yup.string().required("Contact is required."),
        totalAmount: Yup.string().required("Amount is required."),
        voucherDate: Yup.string().required("Voucher Date is required."),
        productionUnitId: Yup.string().required("Production is required."),
        defectiveDeductionPercent: Yup.string().required("Defective Given Percent is required."),
        customAmount: Yup.string().required("Amount is required."),
    })
    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    useEffect(() => {
        if (selectedContactData) {
            formik.setValues({ ...formik.values, contactId: selectedContactData.id, linkedId: selectedContactData.id })
            getProduction(selectedContactData.id)
        }
    }, [selectedContactData]);

    useEffect(() => {
        if (formik.values.contactId && formik.values.productionUnitId) {
            getProductionReceivingVoucher()
        }
    }, [formik.values.contactId, formik.values.productionUnitId]);

    useEffect(() => {
        if (productionReceiving && productionReceiving.data.length > 0) {
            let wt = 0
            productionReceiving.data.forEach(element => {
                wt = wt + element.receivedWeight
            });
            setCount({ weight: wt })
            formik.setValues({ ...formik.values, customAmount: productionReceiving.totalWage, totalAmount: productionReceiving.totalWage })
        }
    }, [productionReceiving]);

    const Save = (value) => {
        if (productionReceiving && productionReceiving.data.length > 0) {
            setIsLoadingMini(true);
            setIsLoadingLocal(true)
            productionWageVoucherCrud.VoucherAdd({
                Voucher: {
                    linkedId: value.linkedId,
                    contactId: value.contactId,
                    voucherType: value.voucherType,
                    linkedIdType: value.linkedIdType,
                    paymentStatus: value.paymentStatus,
                    totalAmount: value.totalAmount,
                    voucherDate: value.voucherDate,
                    note: value.note
                },
                productionUnitId: value.productionUnitId,
                defectiveDeductionPercent: value.defectiveDeductionPercent,
                isCustomAmount: value.isCustomAmount,
                customAmount: value.customAmount
            })
                .then(res => {
                    if (res.data.result.succeeded) {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false);
                        AppToaster('Added successfully.', 'success')
                        history.push("/productionvoucher/dashboard/" + res.data.data.voucher.id)
                    }
                    else {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                })
                .catch(error => {
                    setIsLoadingMini(false);
                    setIsLoadingLocal(false);
                    AppToaster(error, 'error')
                })
        }
        else {
            AppToaster("There are no receivings found to create voucher.", 'error')
        }

    }

    function getProduction(contactid) {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        productionUnitCrud.GetByContactId(contactid)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProduction(hdata);
                    }
                    else {
                        setProduction([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProduction([]);
                }
                setIsLoadingMini(false);
                setIsLoadingLocal(false)
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false)
                AppToaster(error, 'error')
            })
    }


    function getProductionReceivingVoucher() {
        setIsLoadingMini(true);
        productionWageVoucherCrud.GetRecevingsToVouchered({ contactid: formik.values.contactId, productionUnitId: formik.values.productionUnitId })
            .then(res => {
                if (res.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data
                        setProductionReceving(hdata);
                    }
                    else {
                        setProductionReceving();
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProductionReceving();
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }


    return (
        <>
            <Container title="Add Production Wage Voucher" isBack="true">
                <form onSubmit={formik.handleSubmit} class="form-horizontal">
                    <div className="row">
                        <div className="col-md-6">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Contact</label>
                                    <ContactSelector searchtext="" setSelectedData={setSelectedContactData} isWarehouse={userData.isWarehouse == 0 ? false : true} />
                                    {
                                        formik.touched.contactId &&
                                            formik.errors.contactId ? (
                                            <label className="text-danger" >
                                                {formik.errors.contactId}
                                            </label>
                                        ) : null
                                    }
                                </div>
                                <ContactSelectorView data={selectedContactData} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class=" control-label no-padding-right">Production</label>
                                    <select className="form-control" name="productionUnitId" value={formik.values.productionUnitId} onChange={(e) => formik.setValues({ ...formik.values, productionUnitId: e.target.value })}>
                                        <option value="" >Select...</option>
                                        {
                                            production.map((data, index) =>
                                                <option key={data.id} value={data.id}>{data.productionUnitName.toUpperCase()} ({data.id})</option>
                                            )
                                        }
                                    </select>
                                    {
                                        formik.touched.productionUnitId &&
                                            formik.errors.productionUnitId ? (
                                            <label className="text-danger" >
                                                {formik.errors.productionUnitId}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Voucher Date</label>
                                    <span class="input-icon" style={{ width: "100%" }}>
                                        <DatePicker placeholder="Voucher Date" name="voucherDate" className="form-control input-date-padding" selected={formik.values.voucherDate} onChange={(e) => formik.setValues({ ...formik.values, voucherDate: e })} />
                                        <i class="ace-icon fa fa-calendar blue"></i>
                                    </span>
                                    {
                                        formik.touched.voucherDate &&
                                            formik.errors.voucherDate ? (
                                            <label className="text-danger" >
                                                {formik.errors.voucherDate}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-3">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Defective Given %</label>
                                    <input className="form-control" placeholder="Defective Given %" min="0" max="100" type="number" value={formik.values.defectiveDeductionPercent} onChange={(e) => formik.setValues({ ...formik.values, defectiveDeductionPercent: e.target.value })} />
                                    {
                                        formik.touched.defectiveDeductionPercent &&
                                            formik.errors.defectiveDeductionPercent ? (
                                            <label className="text-danger" >
                                                {formik.errors.defectiveDeductionPercent}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-9">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Note</label>
                                    <TextBoxTranslater className="form-control" placeholder="Note" type="text" value={formik.values.note} onChange={(e) => formik.setValues({ ...formik.values, note: e.target.value })} />
                                    {
                                        formik.touched.note &&
                                            formik.errors.note ? (
                                            <label className="text-danger" >
                                                {formik.errors.note}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-2">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Is Custom Amount</label>
                                    <div>
                                        <input onChange={() => formik.setValues({ ...formik.values, isCustomAmount: (formik.values.isCustomAmount == 1 ? 0 : 1), customAmount: (formik.values.isCustomAmount == 0 ? 0 : formik.values.totalAmount) })} checked={formik.values.isCustomAmount == 1} type="checkbox" class="ace ace-switch ace-switch-5" />
                                        <span class="lbl middle"></span>
                                    </div>
                                    {
                                        formik.touched.isCustomAmount &&
                                            formik.errors.isCustomAmount ? (
                                            <label className="text-danger" >
                                                {formik.errors.isCustomAmount}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                        {formik.values.isCustomAmount == 1 &&
                            <div className="col-md-4">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <label class="control-label no-padding-right">Custom Amount</label>
                                        <input className="form-control" placeholder="Custom Amount" type="number" value={formik.values.customAmount} onChange={(e) => formik.setValues({ ...formik.values, customAmount: e.target.value })} />
                                        {
                                            formik.touched.customAmount &&
                                                formik.errors.customAmount ? (
                                                <label className="text-danger" >
                                                    {formik.errors.customAmount}
                                                </label>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <div class="clearfix form-actions">
                        <div class="pull-right">
                            {isLoadingLocal ?
                                <LoadingButton />
                                :
                                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Add
                                </button>}
                        </div>
                    </div>

                </form>

                {
                    productionReceiving &&
                    <>
                        <div className="widget-box">
                            <div class="widget-header widget-header-flat widget-header-small">
                                <h5 class="widget-title">
                                    Recevings for production vouchers
                                </h5>
                                <div class="widget-toolbar no-border">
                                    <button class="btn btn-minier btn-primary" onClick={getProductionReceivingVoucher}>
                                        <i class="ace-icon fa fa-refresh"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                    <div class="clearfix">
                                        <div class="grid4">
                                            <span>
                                                <h5 class="bigger green">
                                                    Total Fresh Wage: <b>₹ {Number(productionReceiving.totalWage).toFixed(2)}</b>
                                                </h5>
                                            </span>
                                        </div>
                                        <div class="grid4">
                                            <span>
                                                <h5 class="bigger green">
                                                    Fresh Quantity: <b>{productionReceiving.totalFresh}</b></h5>
                                            </span>
                                        </div>

                                        <div class="grid4">
                                            <span>
                                                <h5 class="bigger orange">
                                                    Defective Quantity: <b>{productionReceiving.totalDefective}</b></h5>
                                            </span>
                                        </div>

                                        <div class="grid4">
                                            <span>
                                                <h5 class="bigger red">
                                                    Rejected Quantity: <b>{productionReceiving.totalRegected}</b></h5>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-xs-12">
                                    <PagingEmpty text={"Total " + productionReceiving.data.length + " items."}>
                                        {isLoadingMini ?
                                            <Spinner />
                                            :
                                            productionReceiving && productionReceiving.data.length > 0 ?
                                                <div className="tables-scroll">
                                                    <table className="table table-hover table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>#Id</th>
                                                                <th>On Date</th>
                                                                <th>Production</th>
                                                                <th>Product</th>
                                                                <th>Wage/unit</th>
                                                                <th>Fresh</th>
                                                                <th>Defective</th>
                                                                <th>Rejected</th>
                                                                <th>Defective Wage</th>
                                                                {/* <th>Production</th> */}
                                                                <th>Weight</th>
                                                                <th>Note</th>
                                                                <th>By</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {productionReceiving.data.map((item, index) =>
                                                                <tr key={item.id}>
                                                                    <td>{item.id}</td>
                                                                    <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.onDate}</Moment></div></td>
                                                                    <td>
                                                                        <Link to={"/productionunit/dashboard/" + item.productionUnitId}>
                                                                            <ProductionUnitView item={item.productionUnit} />
                                                                        </Link>
                                                                    </td>
                                                                    <td>
                                                                        <Link to={"/product/dashboard/" + item.productId}>
                                                                            <ProductView item={item.product} uid={"PP" + index} />
                                                                        </Link>
                                                                    </td>
                                                                    <td>₹ {Number(item.productWage.wage).toFixed(2)}</td>
                                                                    <td>{item.freshQuantity} {item.unit}</td>
                                                                    <td>{item.defectiveQuantity} {item.unit}</td>
                                                                    <td>{item.rejectedQuantity} {item.unit}</td>
                                                                    <td>{item.defectiveWagePercent}%</td>
                                                                    {/* <td>
                                                                        {item.isProductionUnit == 1 ? (item.productionUnit ? <Link to={"/productionunit/dashboard/" + item.productionUnitId}>
                                                                            <ProductionUnitView item={item.productionUnit} />
                                                                        </Link> : "NA") : <span class="label label-sm label-danger">{GetEBoolName(item.isProductionUnit)}</span>}
                                                                    </td> */}
                                                                    <td>{item.receivedWeight} KG</td>
                                                                    <td><div style={{ width: "150px" }}>{item.note}</div></td>

                                                                    <td>
                                                                        <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            <tr className='active'>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td className='bolder'>{count.weight} KG</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                :
                                                <EmptyData>
                                                    No records found.
                                                    <br />
                                                    <br />
                                                </EmptyData>

                                        }
                                        <br />
                                    </PagingEmpty>
                                </div>
                            </div>
                        </div>
                    </>
                }

            </Container >
        </>
    )
}

