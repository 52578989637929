import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetAll() {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Ledger/GetAll")
    }
}

export async function GetByLedgerGroup(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Ledger/GetByLedgerGroup?ELedgerType=" + param.searchValue + "&ELedgerGroup=" + param.searchValue)
    }
}

export async function GetByLedgerType(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Ledger/GetByLedgerType?ELedgerType=" + param.searchValue + "&ELedgerGroup=" + param.searchValue)
    }
}

export async function GetByRevenueLedgerAndType(type) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Ledger/GetByRevenueLedgerAndType?model=" + type)
    }
}

export async function GetByLedgerGroupAndType(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Ledger/GetByLedgerGroupAndType?Group=" + param.searchValue + "&Type=" + param.searchValue)
    }
}

export async function GetById(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Ledger/GetById?Id=" +id)
    }
}

export async function Add(ledger) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Ledger/Add", ledger)
    }
}

export async function Edit(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Ledger/Edit?Id=" + param.id + "&LedgerName=" + param.searchValue)
    }
}

export async function Remove(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Ledger/Remove?Id=" +id)
    }
}
