import GetLoginInfo from "../../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../../initApi/createAxios";
import { OrgId } from "../../config/GlobalSettings";

export async function VoucherAdd(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/ProcessingWageVoucher/VoucherAdd", param)
    }
}

export async function VoucherRemove(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/ProcessingWageVoucher/VoucherRemove?Id=" + id)
    }
}

export async function GetVoucherById(voucherid) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Vouchers/ProcessingWageVoucher/GetVoucherById?VoucherId=" + voucherid)
    }
}

export async function GetRecevingsToVouchered(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/ProcessingWageVoucher/GetRecevingsToVouchered", param)
    }
}

export async function AddVoucherPayment(voucherPayments) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/ProcessingWageVoucher/AddVoucherPayment", voucherPayments)
    }
}

export async function RemoveVoucherPayment(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/ProcessingWageVoucher/RemoveVoucherPayment?PaymentId=" + id)
    }
}

export async function SattleVoucher(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/ProcessingWageVoucher/SattleVoucher", param)
    }
}

export async function RoundOffVoucher(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/ProcessingWageVoucher/RoundOffVoucher?VoucherId=" + param.voucherid + "&CreatedBy=" + param.searchValue)
    }
}