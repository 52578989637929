import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken, createAxiosWithTokenFile } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetAll(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductionUnit/GetAll", param)
    }
}

export async function GetByOwnership(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("GetByOwnership/GetByProductId?PageNo=" + param.pageNumber + "&PageSize=" + param.pageSize + "&SearchTerm=" + param.searchText + "&ProductionUnitOwnership=" + param.searchValue + "&FromDate=" + param.fromDate + "&ToDate=" + param.toDate)
    }
}

export async function GetByProductionCategoryId(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductionUnit/GetByProductionCategoryId?PageNo=" + param.pageNumber + "&PageSize=" + param.pageSize + "&SearchTerm=" + param.searchText + "&ProductionCatId=" + param.productionCatId + "&FromDate=" + param.fromDate + "&ToDate=" + param.toDate)
    }
}


export async function GetByContactId(contactId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductionUnit/GetByContactId?ContactId=" + contactId)
    }
}

export async function GetById(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductionUnit/GetById?Id=" + id)
    }
}

export async function Add(productionUnit) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductionUnit/Add", productionUnit)
    }
}
export async function Edit(productionUnitdata) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductionUnit/Edit", productionUnitdata)
    }
}

export async function ChangeContact(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductionUnit/ChangeContact?ProducationUnitId=" + param.producationUnitId + "&ContactId=" + param.contactId)
    }
}

export async function Remove(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductionUnit/Remove?Id=" + id)
    }
}

export async function GetProductionLotsByContact(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductionUnit/GetProductionLotsByContact", param)
    }
}

export async function UploadDocument(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithTokenFile(loginData.token, OrgId()).post("ProductionUnit/UploadDocument", param)
    }
}