import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { MyAlert } from '../../commonLib/MyAlert';
import { loadingContext } from '../../commonLib/_lodingContext';
import * as orderCrud from '../../_crud/orderCrud'
import * as productionUnitCrud from '../../_crud/productionUnitCrud'
import * as contactProcessingAllotmentCrud from '../../_crud/contactProcessingAllotmentCrud'
import { OrderItemView } from './OrderItemView';
import { TimelineHistory } from '../Global/TimelineHistory';
import { EmptyData } from '../../commonLib/EmptyData';
import { PagingEmpty } from '../../commonLib/PagingEmpty';
import ContactSelector from '../Global/ContactSelector';
import { ContactSelectorView } from '../Global/SelectorView/ContactSelectorView';
import { ProductionSelectorView } from '../Global/SelectorView/ProductionSelectorView';
import { PrintStarterDash } from './../Prints/PrintStaterDash';
import { OrderContactPrint } from './../Prints/OrderContactPrint';
import { OrderProcessingPrint } from '../Prints/OrderProcessingPrint';
import { getIsPrint } from '../../config/GlobalSettings';


export function OrderDashboard() {
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [weight, setWeight] = useState("")
    const [order, setOrder] = useState()
    const [orderPrintData, setOrderPrintData] = useState()
    const [configs, setConfigs] = useState([])
    const [processing, setProcessing] = useState([])
    const [production, setProduction] = useState([])
    const [selectedContactData, setSelectedContactData] = useState(undefined);
    const [selectedProductionData, setSelectedProductionData] = useState(undefined);
    const { id } = useParams()

    const init = {
        id: id,
        contactId: "",
        processingId: "",
        isProductionUnit: 0,
        productionUnitId: ""
    }
    const [toProcessing, setToProcessing] = useState(init)
    function doRefresh(event) {
        getOrder()
        setTimeout(() => {
            event.target.complete();
        }, 1000);
    }
    useEffect(() => {
        if (selectedContactData) {
            setToProcessing({ ...toProcessing, contactId: selectedContactData.id })
            getProcessing(selectedContactData.id)
            getProduction(selectedContactData.id)
        }
        if (selectedProductionData) {
            setToProcessing({ ...toProcessing, productionUnitId: selectedProductionData.id })
        }

    }, [selectedContactData, selectedProductionData]);

    useEffect(() => {
        getOrder()
    }, []);

    function getOrder() {
        setIsLoading(true);
        orderCrud.GetById(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let hconfig = res.data.config
                        let hproduct = res.data.product
                        setOrderPrintData({ order: hdata, config: hconfig, product: hproduct })
                        setOrder(hdata)
                        setConfigs(hconfig)
                    }
                    else {
                    }
                }
                else {
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
            })
    }

    const OnProductionChange = (e) => {
        const { value } = e.target;
        if (value) {
            production.forEach(element => {
                if (element.id == value) {
                    {
                        setSelectedProductionData(element)
                    }
                }
            });
        }
        else {
            setSelectedProductionData(undefined)
        }
    }
    const ChangeStatus = (id) => {
        setIsLoadingMini(true);
        let param = { id: id, weight: (weight ? weight : 0) }
        orderCrud.ChangeStatus(param)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false);
                    AppToaster('Updated successfully.', 'success')
                    getOrder()
                }
                else {
                    setIsLoadingMini(false);
                    AppToaster(res.data.message, 'error')
                }

            }).catch(error => {
                setIsLoadingMini(false);
                AppToaster('Network error', 'error')
            })
    }

    const ChangeStatusToPocessing = () => {
        setIsLoadingMini(true);
        orderCrud.ChangeStatusToPocessing(toProcessing)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false);
                    AppToaster('Updated successfully.', 'success')
                    getOrder()
                }
                else {
                    setIsLoadingMini(false);
                    AppToaster(res.data.message, 'error')
                }

            }).catch(error => {
                setIsLoadingMini(false);
                AppToaster('Please select all fields.', 'error')
            })
    }
    function getProcessing(contactid) {
        setIsLoadingMini(true);
        contactProcessingAllotmentCrud.GetByContactDdl(contactid)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProcessing(hdata);
                    }
                    else {
                        setProcessing([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProcessing([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function getProduction(contactid) {
        setIsLoadingMini(true);
        productionUnitCrud.GetByContactId(contactid)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProduction(hdata);
                    }
                    else {
                        setProduction([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProduction([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    const OnProcessingChange = (e) => {
        const { value } = e.target;
        let ispunit = 0;

        if (value) {
            processing.forEach(element => {
                if (element.processing.id == value) {
                    {
                        if (element.processing.mode == 1)
                            ispunit = 1
                        else
                            ispunit = 0
                    }
                }
            });
        }
        setToProcessing({ ...toProcessing, processingId: value, isProductionUnit: ispunit, productionUnitId: (ispunit == 0 ? 1 : "") })
    }

    const printRef = useRef();
    const printRefProcessing = useRef();

    return (
        <>
            {getIsPrint && <div style={{ display: "none" }}>
                <OrderContactPrint printData={orderPrintData && orderPrintData} ref={printRef} />
                <OrderProcessingPrint printData={orderPrintData && orderPrintData} ref={printRefProcessing} />
            </div>}
            <Container title={"Order Dashboard"} doRefresh={doRefresh} isBack="true">
                {order ?
                    <>
                        <div className="row">
                            <div className="col-md-1">
                                <Link style={{ marginRight: "5px" }} to={"/order/edit/" + id} class="btn btn-sm btn-info ">
                                    <i class="ace-icon fa fa-pencil bigger-110"></i>
                                    Edit
                                </Link>
                            </div>
                            {order && (order.status != 1 && order.status != 3 && order.status != 4) &&
                                <>
                                    {order.isForProductionUnit == 1 &&
                                        <div className="col-md-2">
                                            <input style={{ marginRight: "5px" }} className="form-control" placeholder="Weight"
                                                type="number" value={weight} onChange={(e) => setWeight(e.target.value)} />
                                        </div>
                                    }
                                    <div className="col-md-2">
                                        <MyAlert confirmBtnText="Yes, change status!" title="Change Status" isFormated={false} onConfirm={ChangeStatus} param={order.id}>
                                            {isLoadingMini ?
                                                <button style={{ marginRight: "5px" }} class="btn btn-sm btn-success " disabled>
                                                    <i class="ace-icon fa fa-random bigger-110"></i>
                                                    Changing Please wait..
                                                </button>
                                                :
                                                <button style={{ marginRight: "5px" }} class="btn btn-sm btn-success ">
                                                    <i class="ace-icon fa fa-random bigger-110"></i>
                                                    {order.isForProductionUnit == 1 ? "Set to Supplied" : "Set to Completed"}
                                                </button>
                                            }
                                        </MyAlert>
                                    </div>

                                </>
                            }

                            {order && (order.status == 3) &&
                                <div className="col-md-3">
                                    {isLoadingMini ?
                                        <button style={{ marginRight: "5px", marginLeft:"0px!important" }} class="btn btn-sm btn-purple " disabled>
                                            <i class="ace-icon fa fa-random bigger-110"></i>
                                            Changing Please wait..
                                        </button>
                                        :
                                        <button href="#orderdash-modal" data-toggle="modal" style={{ marginRight: "5px", marginLeft:"0px!important" }} class="btn btn-sm btn-purple ">
                                            <i class="ace-icon fa fa-random bigger-110"></i>
                                            Send to Processing
                                        </button>
                                    }
                                </div>
                            }

                            {getIsPrint && orderPrintData &&
                                <div className="col-md-3">
                                    <div class="btn-group">
                                        <PrintStarterDash text="Passbook" printRef={printRef} />
                                    </div>
                                    <div class="btn-group">
                                        <PrintStarterDash color="pink" text="Processing" printRef={printRefProcessing} />
                                    </div>
                                </div>
                            }
                        </div>
                        <div class="hr dotted"></div>

                        <div className="row">
                            <div className="col-lg-8">
                                <OrderItemView order={order} />

                                {order && order.productionConfiguration &&
                                    <>
                                        <br />
                                        {order &&
                                            <div className="widget-box">
                                                <div class="widget-header widget-header-flat widget-header-small">
                                                    <h5 class="widget-title">
                                                        Configuration: {order.productionConfiguration.name}
                                                    </h5>
                                                </div>
                                                <div className="row">
                                                    <div class="profile-user-info profile-user-info-striped">
                                                        <PagingEmpty text={"Total " + configs.length + " items."}>
                                                            {configs && configs.length > 0 ?
                                                                <div className="tables-scroll">
                                                                    <table className="table table-hover table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>#</th>
                                                                                <th>Inner Color</th>
                                                                                <th>Outer Color</th>
                                                                                <th>Produced Color</th>
                                                                                <th>Optional Outer Color</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            {configs.map((item, index) =>
                                                                                <tr key={item.id}>
                                                                                    <td>{item.serialNo}</td>
                                                                                    <td>{item.innerColor} </td>
                                                                                    <td>{item.outerColor}</td>
                                                                                    <td>{item.producedColor}</td>
                                                                                    <td>{item.optionalOuterColor}</td>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                                :
                                                                <EmptyData>
                                                                    No Item Added.
                                                                    <br />
                                                                    <br />
                                                                </EmptyData>
                                                            }
                                                        </PagingEmpty>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                            <div className="col-lg-4">
                                <TimelineHistory id={id} type={6} pageSize={10} text="Order Timeline" />
                            </div>
                        </div>

                        <div id="orderdash-modal" class="modal fade" tabindex="-1">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                                        <h3 class="smaller lighter blue no-margin">Send To Processing</h3>
                                    </div>

                                    <div class="modal-body">
                                        <div className="row">
                                            <div className="col-xs-12">
                                                <div class="form-group">
                                                    <div class="col-xs-12">
                                                        <label class="control-label no-padding-right">Contact</label>
                                                        <ContactSelector searchtext="" setSelectedData={setSelectedContactData} />
                                                    </div>
                                                    <ContactSelectorView data={selectedContactData} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div class="form-group">
                                                    <div class="col-xs-12">
                                                        <label class=" control-label no-padding-right">Processing</label>
                                                        <select className="form-control" name="processingId" value={toProcessing.processingId} onChange={OnProcessingChange}>
                                                            <option value="" >Select...</option>
                                                            {
                                                                processing.map((data, index) =>
                                                                    <option key={data.processing.id} value={data.processing.id}>{data.processing.name.toUpperCase()} ({data.processing.id})</option>
                                                                )
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            {toProcessing.isProductionUnit == 1 &&
                                                <div className="col-md-6">
                                                    <div class="form-group">
                                                        <div class="col-xs-12">
                                                            <label class="control-label no-padding-right">Production</label>
                                                            <select className="form-control" name="productionUnitId" value={toProcessing.productionUnitId} onChange={OnProductionChange}>
                                                                <option value="" >Select...</option>
                                                                {
                                                                    production.map((data, index) =>
                                                                        <option key={data.id} value={data.id}>{data.productionUnitName.toUpperCase()} ({data.id})</option>
                                                                    )
                                                                }
                                                            </select>
                                                            <ProductionSelectorView data={selectedProductionData} />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div class="modal-footer">
                                        <button data-dismiss="modal" class="btn btn-info" onClick={ChangeStatusToPocessing}>
                                            <i class="ace-icon fa fa-check bigger-110"></i>
                                            Send to Processing
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <EmptyData>
                        <br />
                        <br />
                        Invalid Order, No Order Found.
                        <br />
                        <br />
                        <br />
                        <br />
                        <div className="hr"></div>
                    </EmptyData>
                }
            </Container>
        </>
    )
}
