import React, { useContext, useState } from 'react'
import AppToaster from '../../commonLib/AppToaster';
import *as Yup from "yup"
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import * as productConfigCrud from '../../_crud/productConfigCrud'
import { Container } from '../../base/Container';
import { LoadingButton } from '../../commonLib/LoadingButton';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

export function AddConfiguration() {
    const history = useHistory();
    const { id } = useParams()
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)

    const initialValues = {
        productId: id,
        configName: "",
        value: "",
        type: ""
    }

    const Schema = Yup.object().shape({
        productId: Yup.string().required("Product is required."),
        configName: Yup.string().min(3, "Config Name should between 3 to 50 characters").max(50, "Config Name should between 3 to 50 characters").required("Config Name is required."),
        value: Yup.string().required("Value is required."),
        type: Yup.string().required("Type is required.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });
    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        productConfigCrud.Add(value)
            .then(res => {
                if (res.data.result.succeeded) {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster('Added successfully.', 'success')
                    history.goBack()
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    return (
        <>
            <Container title="Add Configuration " isBack="true" isProduct="true">
                <form onSubmit={formik.handleSubmit} class="form-horizontal">
                    <div className="row">
                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="ontrol-label no-padding-right">Config Name</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Config Name" name="configName" value={formik.values.configName} onChange={(e) => formik.setValues({ ...formik.values, configName: e.target.value })} />
                                    {
                                        formik.touched.configName &&
                                            formik.errors.configName ? (
                                            <label className="text-danger" >
                                                {formik.errors.configName}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Type</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Type" name="type" value={formik.values.type} onChange={(e) => formik.setValues({ ...formik.values, type: e.target.value })} />
                                    {
                                        formik.touched.type &&
                                            formik.errors.type ? (
                                            <label className="text-danger" >
                                                {formik.errors.type}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Value</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Value" name="value" value={formik.values.value} onChange={(e) => formik.setValues({ ...formik.values, value: e.target.value })} />
                                    {
                                        formik.touched.value &&
                                            formik.errors.value ? (
                                            <label className="text-danger" >
                                                {formik.errors.value}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="clearfix form-actions">
                        <div class="pull-right">
                            {isLoadingLocal ?
                                <LoadingButton />
                                :
                                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Add
                                </button>}
                        </div>
                    </div>

                </form>
            </Container >
        </>
    )
}
