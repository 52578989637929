import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetAll(gatePass) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("GatePass/GetAll", gatePass)
    }
}
export async function GetAllByWarehouse(gatePass) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("GatePass/GetAllByWarehouse", gatePass)
    }
}
export async function GetByProduct(queryParams) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("GatePass/GetByProduct?PageNo=" + queryParams.pageNumber + "&PageSize=" + queryParams.pageSize + "&SearchTerm=" + queryParams.searchText + "&ProductId=" + queryParams.productId + "&FromDate=" + queryParams.fromDate + "&ToDate=" + queryParams.toDate)
    }
}

export async function GetByContact(queryParams) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("GatePass/GetByContact?PageNo=" + queryParams.pageNumber + "&PageSize=" + queryParams.pageSize + "&SearchTerm=" + queryParams.searchText + "&ContactId=" + queryParams.contactId + "&FromDate=" + queryParams.fromDate + "&ToDate=" + queryParams.toDate)
    }
}


export async function GetById(Id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("GatePass/GetById?Id=" + Id)
    }
}

export async function Add(gatePass) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("GatePass/Add", gatePass)
    }
}


export async function AddGatePassProduct(gatePass) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("GatePass/AddGatePassProduct", gatePass)
        
    }
}

export async function RemoveGatePassProduct(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("GatePass/RemoveGatePassProduct?Id=" + id )
    }
}


