import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from "yup";
import GetLoginInfo from '../../auth/_userContext/GetLoginInfo';
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import * as applicationUserCrud from '../../_crud/applicationUserCrud';
import { LoadingButton } from './../../commonLib/LoadingButton'


export default function ChangePassword() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const history = useHistory();
    const userData = GetLoginInfo()

    const initialValues = {
        userId: userData.id,
        oldPass: "",
        newPass: "",
        confirmPassword: ""
    }

    const Schema = Yup.object().shape({
        oldPass: Yup.string().required("Old Password is required."),
        newPass: Yup.string()
            .required('Please Enter your new password')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
            ),
        confirmPassword: Yup.string()
            .required('Please Enter your confirm password')
            .oneOf([Yup.ref("newPass"), null], "Passwords must match"),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        applicationUserCrud.ChangePassword(value)
            .then(res => {

                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster('Password Changed.', 'success')
                    formik.setValues(initialValues)
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    return (
        <Container title="Change Password">
            <form onSubmit={formik.handleSubmit} class="form-horizontal">
                <div class="form-group">
                    <label class="col-md-3 control-label no-padding-right">Old Password</label>
                    <div class="col-md-5">
                        <input className="form-control" type="password" placeholder="Old Password" name="oldPass" {...formik.getFieldProps("oldPass")} />
                        {
                            formik.touched.oldPass &&
                                formik.errors.oldPass ? (
                                <label className="text-danger" >
                                    {formik.errors.oldPass}
                                </label>
                            ) : null
                        }
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-md-3 control-label no-padding-right">New Password</label>
                    <div class="col-md-5">
                        <input className="form-control" type="password" placeholder="New Password" name="newPass" {...formik.getFieldProps("newPass")} />
                        {
                            formik.touched.newPass &&
                                formik.errors.newPass ? (
                                <label className="text-danger" >
                                    {formik.errors.newPass}
                                </label>
                            ) : null
                        }
                    </div>
                </div>

                <div class="form-group">
                    <label class=" col-md-3 control-label no-padding-right">Confirm Password</label>
                    <div class="col-md-5">
                        <input className="form-control" type="password" placeholder="Confirm Password" name="confirmPassword" {...formik.getFieldProps("confirmPassword")} />
                        {
                            formik.touched.confirmPassword &&
                                formik.errors.confirmPassword ? (
                                <label className="text-danger" >
                                    {formik.errors.confirmPassword}
                                </label>
                            ) : null
                        }
                    </div>
                </div>

                <div class="clearfix form-actions">
                    <div class="pull-right">
                        {isLoadingLocal ?
                            <LoadingButton />
                            :
                            <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                <i class="ace-icon fa fa-check bigger-110"></i>
                                Change
                            </button>}
                    </div>
                </div>

            </form>
        </Container >
    )
}

