import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetAll(order) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Order/GetAll", order)
    }
}
export async function GetAllPendings(order) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Order/GetAllPendings", order)
    }
}
export async function GetAllWarehouse(order) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Order/GetAllWarehouse", order)
    }
}
export async function GetAllPendingsWarehouse(order) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Order/GetAllPendingsWarehouse", order)
    }
}

export async function GetByProductId(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Order/GetByProductId", param)
    }
}

export async function GetForProductionUnitId(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Order/GetForProductionUnitId", param)
    }
}


export async function GetForProductionUnitIdContact(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Order/GetForProductionUnitIdContact", param)
    }
}

export async function GetForProductionUnitIdContactProduct(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Order/GetForProductionUnitIdContactProduct", param)
    }
}


export async function GetAllotedForContactSupplied(AllotedForContactId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Order/GetAllotedForContactSupplied?AllotedForContactId=" + AllotedForContactId)
    }
}

export async function GetAllotedForContactId(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Order/GetAllotedForContactId", param)
    }
}

export async function GetAllotedToContactId(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Order/GetAllotedToContactId", param)
    }
}

export async function GetToProductionUnitId(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Order/GetToProductionUnitId", param)
    }
}

export async function GetByStatus(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Order/GetByStatus", param)
    }
}

export async function GetById(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Order/GetById?Id=" + id)
    }
}

export async function Add(order) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Order/Add", order)
    }
}
export async function Edit(orderdata) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Order/Edit", orderdata)
    }
}

export async function Remove(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Order/Remove?Id=" + id)
    }
}

export async function ChangeStatus(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Order/ChangeStatus",param)
    }
}

export async function ChangeStatusToPocessing(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Order/ChangeStatusToPocessing",param)
    }
}



export async function GetAllotedToContactIdPendings(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Order/GetAllotedToContactIdPendings", param)
    }
}

export async function GetForProductionUnitIdContactPendings(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Order/GetForProductionUnitIdContactPendings", param)
    }
}