
const GetLanguage = () => {
    try {
        let data;
        data = localStorage.getItem('lang');
        return data;
    } catch (error) {
        return "en";
    }
}
export default GetLanguage