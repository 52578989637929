import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Container } from '../../base/Container';
import { dashboardContext } from '../Global/dashboardContext';
import * as productionUnitCrud from '../../_crud/productionUnitCrud'
import { loadingContext } from '../../commonLib/_lodingContext';
import { ContactProfile } from '../Contacts/ContactProfile';
import { GetEStatusName } from '../../enumCollections/EStatus';
import { GetEProductionCategoryTypeName } from '../../enumCollections/EProductionCategoryType';
import { GetEProductionUnitOwnershipName } from '../../enumCollections/EProductionUnitOwnership';
import { GetEBoolName } from '../../enumCollections/EBool';
import { ContactView } from '../Global/Elements/ContactView';
import { UserView } from '../Global/Elements/UserView';
import { EmptyData } from '../../commonLib/EmptyData';
import { PagingEmpty } from '../../commonLib/PagingEmpty';


export function ProductionUnitDashboard() {
    const { id } = useParams()
    const [dashboardData, setDashboardData] = useContext(dashboardContext)
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [productiondata, setProductiondata] = useState()
    const [productionConfig, setProductionConfig] = useState([])

    function doRefresh() {
        getProductionUnit()
    }

    useEffect(() => {
        getProductionUnit()
    }, []);

    function getProductionUnit() {
        setIsLoading(true);
        productionUnitCrud.GetById(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let hconfig = res.data.config
                        setDashboardData({ ...dashboardData, productiondata: hdata, contactdata: hdata.contact })
                        setProductiondata(hdata)
                        setProductionConfig(hconfig)
                    }
                    else {
                    }
                }
                else {
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
            })
    }

    return (
        <Container title="Production Unit Dashboard" doRefresh={doRefresh} isBack="true" isProduction="true" isContact="true">
            <Link style={{ marginRight: "5px" }} to={"/productionunit/dashboard/" + id + "/productallotment"} class="btn btn-sm btn-success">
                <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                Product Allotment
            </Link>

            <Link style={{ marginRight: "5px" }} to={"/productionunit/dashboard/" + id + "/changecontact"} class="btn btn-sm btn-warning">
                <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                Contact Allotment
            </Link>

            <Link style={{ marginRight: "5px" }} to={"/productionunit/edit/" + id} class="btn btn-sm btn-info">
                <i class="ace-icon fa fa-pencil bigger-110"></i>
                Edit
            </Link>

            <Link style={{ marginRight: "5px" }} to={"/productionunit/dashboard/" + id + "/document"} class="btn btn-sm btn-pink">
                <i class="ace-icon fa fa-pencil bigger-110"></i>
                Document
            </Link>
            {productiondata && productiondata.productDesign &&
                <Link style={{ marginRight: "5px" }} to={productiondata.productDesign && "/product/design/" + productiondata.productDesign.productId + "/dashboard/" + productiondata.productDesign.id} class="btn btn-sm btn-yellow">
                    <i class="ace-icon fa fa-shopping-basket bigger-110"></i>
                    Design (Bewara)
                </Link>
            }
            <div class="hr dotted"></div>

            <div class="clearfix">
                <br />
                <div className="row">
                    <div class="col-sm-2"></div>
                    {productiondata &&
                        <div class="col-sm-8">
                            <div className="widget-box">
                                <div class="widget-header widget-header-flat widget-header-small">
                                    <h5 class="widget-title">
                                        Details
                                    </h5>
                                </div>
                                <div className="row">
                                    <div class="profile-user-info profile-user-info-striped">
                                        <div class="profile-info-row">
                                            <div class="profile-info-name"> Name </div>

                                            <div class="profile-info-value">
                                                <span class="editable" id="country">{productiondata.productionUnitName}</span>
                                            </div>
                                        </div>
                                        <div class="profile-info-row">
                                            <div class="profile-info-name"> Category </div>

                                            <div class="profile-info-value">
                                                <span class="editable" id="city">{productiondata.productionCategory.productionCatName} ({GetEProductionCategoryTypeName(productiondata.productionCategory.type)})</span>
                                            </div>
                                        </div>


                                        <div class="profile-info-row">
                                            <div class="profile-info-name"> OwnerShip </div>

                                            <div class="profile-info-value">
                                                <span >{GetEProductionUnitOwnershipName(productiondata.ownerShip)} </span>
                                            </div>
                                        </div>
                                        <div class="profile-info-row">
                                            <div class="profile-info-name"> Multi Product </div>

                                            <div class="profile-info-value">
                                                <span >{GetEBoolName(productiondata.isMultiProduct)}</span>
                                            </div>
                                        </div>

                                        <div class="profile-info-row">
                                            <div class="profile-info-name"> Contact</div>

                                            <div class="profile-info-value">
                                                <span >
                                                    <Link to={"/contact/dashboard/" + productiondata.contact.id}>
                                                        <ContactView item={productiondata.contact} uid={"CX"} />
                                                    </Link>
                                                </span>
                                            </div>
                                        </div>
                                        {productiondata.productDesign &&
                                            <div class="profile-info-row">
                                                <div class="profile-info-name">Design (Bewara)</div>

                                                <div class="profile-info-value">
                                                    <span >{productiondata.productDesign.name}</span>
                                                    {productiondata.productDesign.note && <small><br />{productiondata.productDesign.note}</small>}
                                                </div>
                                            </div>
                                        }
                                        <div class="profile-info-row">
                                            <div class="profile-info-name"> Details</div>

                                            <div class="profile-info-value">
                                                <span >{productiondata.details}</span>
                                            </div>
                                        </div>


                                        <div class="profile-info-row">
                                            <div class="profile-info-name"> By </div>

                                            <div class="profile-info-value">
                                                <span>
                                                    <UserView item={productiondata.createdByUser} uid={"DX"} createdOn={productiondata.createdOn} />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>}
                </div>
                {productiondata && productiondata.productionConfiguration &&
                    <>
                        <br />
                        <div className="row">
                            <div class="col-sm-2"></div>
                            {productiondata &&
                                <div class="col-sm-8">
                                    <div className="widget-box">
                                        <div class="widget-header widget-header-flat widget-header-small">
                                            <h5 class="widget-title">
                                                Configuration: {productiondata.productionConfiguration.name}
                                            </h5>
                                        </div>
                                        <div className="row">
                                            <div class="profile-user-info profile-user-info-striped">
                                                <PagingEmpty text={"Total " + productionConfig.length + " items."}>
                                                    {productionConfig && productionConfig.length > 0 ?
                                                        <div className="tables-scroll">
                                                            <table className="table table-hover table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Inner Color</th>
                                                                        <th>Outer Color</th>
                                                                        <th>Produced Color</th>
                                                                        <th>Optional Outer Color</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {productionConfig.map((item, index) =>
                                                                        <tr key={item.id}>
                                                                            <td>{item.serialNo}</td>
                                                                            <td>{item.innerColor} </td>
                                                                            <td>{item.outerColor}</td>
                                                                            <td>{item.producedColor}</td>
                                                                            <td>{item.optionalOuterColor}</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        :
                                                        <EmptyData>
                                                            No Item Added.
                                                            <br />
                                                            <br />
                                                        </EmptyData>
                                                    }
                                                </PagingEmpty>
                                            </div>
                                        </div>
                                    </div>


                                </div>}
                        </div>
                    </>
                }
            </div>

        </Container>
    )
}
