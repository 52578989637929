import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetAll() {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductCategory/GetAll")
    }
}

export async function GetById(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("ProductCategory/GetById?Id=" + id)
    }
}

export async function Add(productCategory) {
    const loginData = GetLoginInfo();

    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductCategory/Add", productCategory)
    }
}
export async function Edit(productCategory) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductCategory/Edit",productCategory)
    }
}
export async function Remove(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("ProductCategory/Remove?Id="+id)
    }
}