import React from 'react'
import { DEFAULT_IMG, getPageMargins } from '../../config/GlobalSettings'
import { GetEChequeStatusName } from '../../enumCollections/EChequeStatus'
import { BASE_URL } from '../../initApi/apiUrls'
import { ContactViewS } from '../Global/Elements/ContactViewS'
import { PrintBranding } from './PrintBranding'

export const PrintBankTransaction = React.forwardRef((props, ref) => {
    const data = props.printData
    return (
        <div ref={ref}>
            <style type="text/css">
                {getPageMargins()}
            </style>
            {
                data &&
                <PrintBranding printName="Bank Transaction" date={data.chequeDate} id={data.id}>
                    {
                        data &&
                        <div className="row">
                            <div className="col-xs-12">

                                <table style={{ width: "100%" }} className="table-bordered-dark">
                                    <tbody>
                                        <tr >
                                            <td>For Contact</td>
                                            <td>
                                                <ContactViewS item={data.contact} uid={"CX1"} />
                                            </td>
                                        </tr>
                                        <tr >
                                            <td>Phone</td>
                                            <td><b> ₹ {data.contact.phone} </b></td>
                                        </tr>
                                        <tr >
                                            <td>Address</td>
                                            <td>{data.contact.address}</td>
                                        </tr>
                                        <tr >
                                            <td>Email</td>
                                            <td>{data.contact.email ? data.contact.email : "NA"}</td>
                                        </tr>
                                        <tr >
                                            <td>For Bank</td>
                                            <td>{data.bank.bankName}</td>
                                        </tr>
                                        <tr >
                                            <td>Cheque/Transaction No</td>
                                            <td>{data.chequeNo}</td>
                                        </tr>
                                        <tr >
                                            <td>Cheque/Transaction Given By</td>
                                            <td>{data.chequeGivenByName}</td>
                                        </tr>
                                        <tr >
                                            <td>Cheque/Transaction Bank</td>
                                            <td>{data.chequeBankName}</td>
                                        </tr>
                                        <tr >
                                            <td>Cheque/Transaction City</td>
                                            <td>{data.chequeCity}</td>
                                        </tr>
                                        <tr >
                                            <td>Amount</td>
                                            <td><b> ₹ {Number(data.chequeAmount).toFixed(2)}</b></td>
                                        </tr>
                                        <tr >
                                            <td>Note</td>
                                            <td>{data.note ? data.note : "NA"}</td>
                                        </tr>
                                        <tr >
                                            <td>Status</td>
                                            <td>{GetEChequeStatusName(data.status)}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                           {  data.isPhoto == 1 &&
                            <div class="col-xs-12">
                                <img width="100%" src={data && data.isPhoto == 1 ? (BASE_URL + "/" + data.photoUrl) : DEFAULT_IMG} />
                            </div>}
                        </div>
                    }
                </PrintBranding>
            }
        </div>
    )
})