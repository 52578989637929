import React, { useContext, useEffect, useState } from 'react'
import { EmptyData } from '../../commonLib/EmptyData'
import { Paging } from '../../commonLib/Paging'
import { Spinner } from '../../commonLib/Spinner'
import { DEFAULT_USER_IMG, ERROR_IMG, PAGING_PAGE_SIZE } from '../../config/GlobalSettings'
import { GetEStatusName } from '../../enumCollections/EStatus'
import { BASE_URL } from '../../initApi/apiUrls'
import * as loginLogsCrud from '../../_crud/loginLogsCrud'
import Moment from 'react-moment';
import ReactTooltip from 'react-tooltip';
import { Container } from '../../base/Container'
import AppToaster from '../../commonLib/AppToaster'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext'
import { useParams } from 'react-router-dom'
import GetCurrentDateForSearch from '../../commonLib/dateFormat'
import DatePicker from "react-datepicker";

const initValues = {
    Search: {
        searchTerm: "",
        pageNo: 1,
        pageSize: PAGING_PAGE_SIZE
    },
    Range: {
        fromDate: new Date(GetCurrentDateForSearch(-30)),
        toDate: new Date(GetCurrentDateForSearch(0))
    }
}
export function LoginLogsByUser() {

    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [search, setSearch] = useState(initValues)
    const [log, setLog] = useState([])
    const [LastPage, setLastPage] = useState(1)
    const [pagingData, setPagingData] = useState()
    const { id } = useParams()

    const OnPageChange = (e) => {
        const { value } = e.target
        setSearch({ ...search, Search: { ...search.Search, pageNo: value } });
        getData({
            Search: { searchTerm: search.Search.searchTerm, pageNo: value, pageSize: search.Search.pageSize },
            Range: {
                fromDate: search.Range.fromDate,
                toDate: search.Range.toDate
            }
        })
    }
    useEffect(() => {
        getData(
            {
                Search: { searchTerm: "", pageNo: 1, pageSize: PAGING_PAGE_SIZE },
                Range: {
                    fromDate: search.Range.fromDate,
                    toDate: search.Range.toDate
                }
            })

    }, [])

    function onSearch() {
        getData({
            Search: { searchTerm: search.Search.searchTerm, pageNo: 1, pageSize: search.Search.pageSize },
            Range: {
                fromDate: search.Range.fromDate,
                toDate: search.Range.toDate
            }
        })
    }

    function getData(param) {
        setIsLoadingMini(true);
        loginLogsCrud.GetByUser({ ...param, userName: id })
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setLog(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoadingMini(false)
                        setLog([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoadingMini(false)
                    setLog([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        setSearch({ ...search, Search: { ...search.Search, pageNo: 1 } })
        getData(
            {
                Search: { searchTerm: "", pageNo: 1, pageSize: PAGING_PAGE_SIZE },
                Range: {
                    fromDate: search.Range.fromDate,
                    toDate: search.Range.toDate
                }
            })
        setTimeout(() => {
            event.target.complete();
        }, 1000);
    }

    return (
        <Container title=" User Login Logs" doRefresh={doRefresh} isBack="true">
            <h3>{id}</h3>
            <div class="hr dotted"></div>
            <div class="form-horizontal" >
                <div className="row">
                    <div className="col-md-2">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <span class="input-icon" style={{ width: "100%" }}>
                                <DatePicker selectsStart startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.fromDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, fromDate: date } })} />
                                <i class="ace-icon fa fa-calendar blue"></i>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <span class="input-icon" style={{ width: "100%" }}>
                                <DatePicker selectsEnd startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.toDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, toDate: date } })} />
                                <i class="ace-icon fa fa-calendar blue"></i>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <span class="input-icon" style={{ width: "100%" }}>
                                <input value={search.Search.searchTerm} onChange={(e) => setSearch({ ...search, Search: { ...search.Search, searchTerm: e.target.value } })} className="form-control" type="text" id="form-field-icon-1" placeholder="Search eg. id, phone etc" />
                                <i class="ace-icon fa fa-search blue"></i>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <button onClick={onSearch} class="btn btn-sm btn-info pull-right">
                                <i class="ace-icon fa fa-search bigger-110"></i>
                                Run
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Paging
                pagingData={pagingData}
                LastPage={LastPage}
                CurrentPage={search.Search.pageNo}
                OnPageChange={OnPageChange} >

                {isLoadingMini ?
                    <Spinner />
                    :
                    log && log.length > 0 ?
                        <div className="tables-scroll">
                            <table className="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th style={{ width: "50px" }}></th>
                                        <th style={{ width: "130px" }}>On Date</th>
                                        <th>Full Name</th>
                                        <th>Phone</th>
                                        <th>Email</th>
                                        <th>IP</th>
                                        <th>City</th>
                                        <th>State</th>
                                        <th>Country</th>
                                        <th>Lat</th>
                                        <th>Long</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        log.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>{item.id}</td>
                                                <td>
                                                    <a data-tip data-for={"P" + index}>
                                                        <img height="35px" width="35px" className="img-round pull-left" src={item.user.isDP == 1 ? BASE_URL + "/" + item.user.dpUrl : DEFAULT_USER_IMG}
                                                            onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
                                                    </a>
                                                    <ReactTooltip delayShow={500} arrowColor="gray" borderColor="#9b9b9b" className="opaque" id={"P" + index} type="light">
                                                        <img height="200px" width="200px" className="img-round-tooltip" src={item.user.isDP == 1 ? BASE_URL + "/" + item.user.dpUrl : DEFAULT_USER_IMG}
                                                            onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
                                                    </ReactTooltip>
                                                </td>
                                                <td className="bolder">
                                                    <div style={{ width: "130px" }}><Moment format="DD-MMM-YYYY hh:mm A">{item.date}</Moment></div>
                                                </td>
                                                <td><div style={{ width: "130px" }}>{item.user.fullName}</div></td>
                                                <td>{item.user.phoneNumber}</td>
                                                <td>{item.user.email}</td>
                                                <td className="bolder">{item.ip}</td>
                                                <td>{item.city}</td>
                                                <td><div style={{ width: "130px" }}>{item.state}</div></td>
                                                <td>{item.country}</td>
                                                <td>{item.lat}</td>
                                                <td>{item.long}</td>


                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                        :
                        <EmptyData>
                            No records found.
                            <br />
                            <br />
                        </EmptyData>
                }
            </Paging>
        </Container >
    )
}

