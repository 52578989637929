import React from 'react'
import { GetEProductionCategoryTypeName } from '../../../enumCollections/EProductionCategoryType'

export function ProductionUnitView({ item }) {
    return (
        item &&
        <div class="infobox infobox-gray" style={{ border: "none", backgroundColor: "transparent", padding: "0", height: "auto" }}>

            {/* <div class="infobox-icon" style={{ verticalAlign: "super" }}>
                <i class="ace-icon fa fa-building"></i>
            </div> */}

            <div class="infobox-data">
                <span class="infobox-data-number" style={{ fontSize: "13px", margin: "0" }}>
                    {item.productionUnitName}
                </span>
                <div class="infobox-content">
                    <span class={item.productionCategory.type == 1 ? "label label-sm label-info" : "label label-sm label-warning"}>{GetEProductionCategoryTypeName(item.productionCategory.type)}</span>
                    <small><span className="label label-sm label-default arrowed-right">{item.productionCategory.productionCatName}</span></small>
                </div>
            </div>
        </div>
    )
}
