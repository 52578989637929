import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import { Link, useParams } from 'react-router-dom'
import { EmptyData } from '../../commonLib/EmptyData'
import { Spinner } from '../../commonLib/Spinner'
import { GetEBoolName } from '../../enumCollections/EBool'
import { GetEOrderStatusName } from '../../enumCollections/EOrderStatus'
import * as orderCrud from '../../_crud/orderCrud'
import { ProductionUnitView } from '../Global/Elements/ProductionUnitView'
import { ProductView } from '../Global/Elements/ProductView'
import { PagingEmpty } from './../../commonLib/PagingEmpty'

export default function ContactOrderSupplied() {
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [orders, setOrders] = useState([])
    const { id } = useParams()
    useEffect(() => {
        GetData()
    }, []);

    function GetData() {
        setIsLoadingLocal(true)
        orderCrud.GetAllotedForContactSupplied(id)
            .then(res => {
                if (res.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setOrders(hdata)
                    }
                    else {
                    }
                }
                else {
                }
                setIsLoadingLocal(false)
            })
            .catch(error => {
                setIsLoadingLocal(false)
            })
    }
    return (
        <>
            <div className="widget-box">
                <div class="widget-header widget-header-flat widget-header-small">
                    <h5 class="widget-title">
                        Orders waiting to collect
                    </h5>
                    <div class="widget-toolbar">
                        <a onClick={GetData} title="Reload">
                            <i class="ace-icon fa fa-refresh"></i>
                        </a>
                    </div>
                </div>
                <PagingEmpty text={"Total " + orders.length + " orders."}>
                    {
                        isLoadingLocal ?
                            <Spinner />
                            :
                            orders && orders.length > 0 ?
                                <div className="tables-scroll">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Product</th>
                                                <th>Quantity</th>
                                                <th>For Production</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {orders.map((item, index) =>
                                                <tr key={item.id}>
                                                    <td>
                                                        <div class="btn-group">
                                                            <Link className="btn btn-xs btn-info" to={"/order/dashboard/" + item.id} title="View dashboard" ><i class="ace-icon fa fa-tachometer"></i></Link>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {item.productId ? <Link to={"/product/dashboard/" + item.productId}>
                                                            <ProductView item={item.product} uid={"PP" + index} />
                                                        </Link> : "NA"}
                                                    </td>
                                                    <td>{item.quantity} {item.unit}</td>
                                                    <td>
                                                        {item.isForProductionUnit == 1 ? (item.forProductionUnitData ? <Link to={"/productionunit/dashboard/" + item.forProductionUnitData.id}><ProductionUnitView item={item.forProductionUnitData} />
                                                        </Link> : "NA") : <span class="label label-sm label-danger">{GetEBoolName(item.isForProductionUnit)}</span>}
                                                    </td>
                                                    <td><span class={item.status == 1 ? "label label-sm label-primary" : "label label-sm label-warning"}>{GetEOrderStatusName(item.status)}</span></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <EmptyData>
                                    No orders waiting to collect.
                                    <br />
                                    <br />
                                </EmptyData>
                    }
                </PagingEmpty>
            </div>
        </>
    )
}
