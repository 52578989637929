import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import AppToaster from '../../commonLib/AppToaster';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import * as productWageCrud from '../../_crud/productWageCrud';
import { Spinner } from '../../commonLib/Spinner';
import { Container } from '../../base/Container';
import { GetEWageStatusName } from '../../enumCollections/EWageStatus';
import { PagingEmpty } from '../../commonLib/PagingEmpty';
import Moment from 'react-moment';
import 'moment-timezone';
import { EmptyData } from '../../commonLib/EmptyData';
import { MyAlert } from '../../commonLib/MyAlert';
import { UserView } from '../Global/Elements/UserView';
import { loadingContext } from '../../commonLib/_lodingContext';



export function ManageProductWage() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [productWage, setProductWage] = useState([])
    const { id } = useParams()
    const [isLoading, setIsLoading] = useContext(loadingContext)

    const initEdit = {
        wageId: "",
        isEdit: false,
        value: undefined
    }
    const [edit, setEdit] = useState(initEdit)

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoadingMini(true);
        productWageCrud.GetByProductId(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProductWage(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setIsLoadingMini(false)
                        setProductWage([]);
                    }
                }
                else {
                    setIsLoadingMini(false)
                    setProductWage([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        getData()
        setTimeout(() => {
            event.detail.complete();
        }, 1000);
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        productWageCrud.Remove(id)
            .then(res => {
                setIsLoadingMini(false)
                AppToaster('Deleted successfully.', 'success')
                getData()

            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }

    const Update = () => {
        setIsLoading(true);
        productWageCrud.AddReverse({ wageId: edit.wageId, newWage: edit.value })
            .then(res => {
                if (res.data.succeeded) {
                    setEdit(initEdit)
                    doRefresh()
                    AppToaster('Added successfully.', 'success')
                    setIsLoading(false);
                }
                else {
                    AppToaster("Server Error /Invalid Try.", 'error')
                    setIsLoading(false);
                }

            }).catch(error => {
                setEdit(initEdit)
                AppToaster(error, 'error')
                setIsLoading(false);
            })
    }

    return (
        <>
            <Container title="Product Wage" doRefresh={doRefresh} isBack="true" isProduct="true">
                <Link to={"/product/dashboard/" + id + "/wage/add"} class="btn btn-sm btn-success ">
                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                    New
                </Link>
                <div class="hr dotted"></div>
                <PagingEmpty text={"Total " + productWage.length + " items."}>
                    {isLoadingMini ?
                        <Spinner />
                        :
                        productWage && productWage.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "43px" }}>#Id</th>
                                            <th>Apply From</th>
                                            <th>Wage</th>
                                            <th>Reverse Detail</th>
                                            <th>Note</th>
                                            <th></th>
                                            <th>Status</th>
                                            <th style={{ width: "43px" }}>By</th>
                                            <th style={{ width: "43px" }}></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {productWage.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>{item.id}</td>
                                                <td><div style={{ width: "80px" }}><Moment format="DD-MMM-YYYY">{item.applyFromDate}</Moment></div></td>
                                                <td><b>₹ {item.wage}</b></td>
                                                <td>{item.status == 1 ? "NA" : <>Date: <Moment format="DD-MMM-YYYY">{item.reverseDate}</Moment> Id: {item.reverseWithId}</>}</td>
                                                <td><div style={{ width: "200px" }}>{item.note}</div></td>

                                                <td>
                                                    {item.status == 1 &&
                                                        <div class="btn-group">
                                                            {edit.isEdit && item.id == edit.wageId ?
                                                                isLoading ?
                                                                    <button disabled class="btn btn-xs btn-info">
                                                                        Waiting...
                                                                    </button>
                                                                    :
                                                                    <>
                                                                        <label>New Wage</label>
                                                                        <input className="form-control" type="number" placeholder="Wage" name="wage" value={edit.value} onChange={(e) => setEdit({ ...edit, value: e.target.value })} />

                                                                        <button onClick={Update} class="btn btn-xs btn-info">
                                                                            Reverse it
                                                                        </button>
                                                                        <button onClick={() => setEdit(initEdit)} class="btn btn-xs btn-default">
                                                                            Cancel
                                                                        </button>
                                                                    </>
                                                                :
                                                                <button onClick={() => setEdit({ wageId: item.id, value: 0, isEdit: true })} class="btn btn-xs btn-success">
                                                                    Reverse
                                                                </button>
                                                            }
                                                        </div>
                                                    }
                                                </td>

                                                <td><span class={item.status == 1 ? "label label-sm label-success" : "label label-sm label-danger"}>{GetEWageStatusName(item.status)}</span></td>

                                                <td>
                                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                </td>
                                                <td>
                                                    {item.status == 1 &&
                                                        <div class="btn-group">
                                                            <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                                <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                            </MyAlert>
                                                        </div>
                                                    }
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </PagingEmpty>
            </Container >

        </>
    )
}
