import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../../../base/Container';
import AppToaster from '../../../commonLib/AppToaster';
import * as productContactStockCrud from '../../../_crud/productContactStockCrud'
import { Paging } from '../../../commonLib/Paging';
import { Link, useParams } from 'react-router-dom';
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import { Spinner } from '../../../commonLib/Spinner';
import 'moment-timezone';
import { EmptyData } from '../../../commonLib/EmptyData';
import { ProductionUnitView } from '../../Global/Elements/ProductionUnitView';
import { ProductView } from '../../Global/Elements/ProductView';
import { PAGING_PAGE_SIZE } from '../../../config/GlobalSettings';
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater';

const initValues = {
    searchTerm: "",
    pageNo: 1,
    pageSize: PAGING_PAGE_SIZE
}

export function ManageProductContactStock() {

    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [search, setSearch] = useState(initValues)
    const [products, setProducts] = useState([])
    const [LastPage, setLastPage] = useState(1)
    const [pagingData, setPagingData] = useState()
    const { id } = useParams()

    const OnPageChange = (e) => {
        const { value } = e.target
        setSearch({ ...search, pageNo: value });
        getData({
            searchTerm: search.searchTerm,
            pageNo: value,
            pageSize: search.pageSize
        })
    }
    useEffect(() => {
        getData({ searchTerm: search.searchTerm, pageNo: 1, pageSize: search.pageSize })
    }, [])


    function onSearch() {
        getData({ searchTerm: search.searchTerm, pageNo: 1, pageSize: search.pageSize })
    }
    function getData(searchparam) {
        setIsLoadingMini(true);
        productContactStockCrud.GetByContact({
            Search: {
                SearchTerm: searchparam.searchTerm,
                PageNo: searchparam.pageNo,
                PageSize: searchparam.pageSize
            },
            ContactId: id
        }).then(res => {
            if (res.data.data) {
                if (res.data.result.succeeded) {
                    let hdata = res.data.data
                    let paging = res.data.paging
                    setPagingData(paging)
                    setLastPage(paging.pageCount)
                    setProducts(hdata);
                    setIsLoadingMini(false)
                }
                else {
                    setLastPage(1)
                    setIsLoadingMini(false)
                    setProducts([]);
                }
            }
            else {
                setLastPage(1)
                setIsLoadingMini(false)
                setProducts([]);
                AppToaster("Server Error /Invalid Try.", 'error')
            }
        })
            .catch(error => {
                setLastPage(1)
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        setSearch({ ...search, pageNo: 1 })
        getData({ searchTerm: search.searchTerm, pageNo: 1, pageSize: search.pageSize })
        setTimeout(() => {
            event.target.complete();
        }, 1000);
    }

    return (
        <Container title="Given Products Stock" doRefresh={doRefresh} isBack="true" isContact="true">
            <div class="form-horizontal" >
                <div className="row">
                    <div className="col-md-11">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <span class="input-icon" style={{ width: "100%" }}>
                                <TextBoxTranslater style={{paddingLeft: "24px"}} value={search.searchTerm} onChange={(e) => setSearch({ ...search, searchTerm: e.target.value })} className="form-control" type="text" placeholder="Search Product" />
                                <i class="ace-icon fa fa-search blue"></i>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <button onClick={onSearch} to="/product/add" class="btn btn-sm btn-info pull-right">
                                <i class="ace-icon fa fa-search bigger-110"></i>
                                Run
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Paging
                pagingData={pagingData}
                LastPage={LastPage}
                CurrentPage={search.pageNo}
                OnPageChange={OnPageChange} >
                {isLoadingMini ?
                    <Spinner />
                    :
                    products && products.length > 0 ?
                        <div className="tables-scroll">
                            <table className="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>#Id</th>
                                        <th>Production</th>
                                        <th>Product</th>
                                        <th>Availabe</th>
                                        <th>Used</th>
                                        <th>Total Given</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {products.map((item, index) =>
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <Link to={"/productionunit/dashboard/" + item.productionUnitId}>
                                                    <ProductionUnitView item={item.productionUnit} />
                                                </Link>
                                            </td>
                                            <td>
                                                <Link to={"/product/dashboard/" + item.productId}>
                                                    <ProductView item={item.product} uid={"PP" + index} />
                                                </Link>
                                            </td>
                                            <td>{Number(item.stock).toFixed(2)} {item.unit}</td>
                                            <td>{Number(item.globalStock - item.stock).toFixed(2)} {item.unit}</td>
                                            <td>{Number(item.globalStock).toFixed(2)} {item.unit}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        :
                        <EmptyData>
                            No records found.
                            <br />
                            <br />
                        </EmptyData>
                }
            </Paging>
        </Container >
    )
}
