import React from 'react'
import Moment from 'react-moment'
import QRCode from 'react-qr-code'
import { getPageMargins } from '../../config/GlobalSettings'
import { ContactViewS } from '../Global/Elements/ContactViewS'
import { ProductViewS } from '../Global/Elements/ProductViewS'

export const OrderProcessingPrint = React.forwardRef((props, ref) => {
    const data = props.printData
    return (
        <div ref={ref}>
            <style type="text/css">
                {getPageMargins()}
            </style>
            {
                data &&
                <div className="row" style={{ fontSize: "9px" }}>
                    {data.order.productionConfiguration &&
                        <div className="col-xs-7">
                            <p className="bolder">{data.order.productionConfiguration.name} {data.order.processingDistribution && <>({data.order.processingDistribution.processing.name})</>}</p>
                            {data.config && data.config.length > 0 &&
                                <table style={{ width: "100%" }} className="table-bordered-dark">
                                    <thead>
                                        <tr>
                                            <th style={{ padding: "0px", border: 0, borderBottom: "1px solid rgb(0, 0, 0)" }}>Sr.No</th>
                                            <th style={{ padding: "0px", border: 0, borderBottom: "1px solid rgb(0, 0, 0)" }}>Inner (Tana)</th>
                                            <th style={{ padding: "0px", border: 0, borderBottom: "1px solid rgb(0, 0, 0)" }} className="text-right">Other</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.config.map((item, index) =>
                                            <tr>
                                                <td style={{ padding: "0px", border: 0, borderBottom: "1px solid rgb(0, 0, 0)" }}>{item.serialNo}</td>
                                                <td style={{ padding: "0px", border: 0, borderBottom: "1px solid rgb(0, 0, 0)" }}>{item.innerColor}</td>
                                                <td style={{ padding: "0px", border: 0, borderBottom: "1px solid rgb(0, 0, 0)" }} className="text-right">{item.optionalOuterColor ? item.optionalOuterColor : "-"}</td>
                                            </tr>
                                        )}

                                        {data.order.details && <tr><td colspan="3" style={{ padding: "0px", border: 0, borderBottom: "1px solid rgb(0, 0, 0)" }}>{data.order.details}</td></tr>}
                                        {data.order.designItems && data.order.designItems.length > 0 &&
                                            <>
                                                {data.order.designItems.map((d, i) =>
                                                    <tr >
                                                        <td style={{ padding: "0px", border: 0, borderBottom: "1px solid rgb(0, 0, 0)" }}><b>{d.name}</b></td>
                                                        <td colspan="2" style={{ padding: "0px", border: 0, borderBottom: "1px solid rgb(0, 0, 0)" }}>
                                                            {d.value} - {d.text && <small>{d.text}</small>}
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        }
                                    </tbody>
                                </table>
                            }

                        </div>
                    }
                    <div className="col-xs-5">
                        <table style={{ width: "100%" }} className="">
                            <tbody>
                                {data.product &&
                                    <tr className="text-center" >
                                        <td colspan="2" style={{ borderBottom: "1px solid rgb(0, 0, 0)" }}>
                                            {data.order.processingDistribution &&
                                                <div style={{ borderBottom: "1px solid rgb(0, 0, 0)", marginBottom: "-5px" }}>
                                                    <ContactViewS item={data.order.processingDistribution.contact} />
                                                </div>}
                                            <br />
                                            <QRCode size="50" value={data.order.id} />
                                            <div style={{ marginBottom: "0px" }}>
                                                <ContactViewS item={data.order.contact} />
                                            </div>
                                        </td>
                                    </tr>}
                                <tr >
                                    <td>Order No</td>
                                    <td className="text-right"># {data.order.id}</td>
                                </tr>
                                <tr >
                                    <td>On Date</td>
                                    <td className="text-right"><Moment format="DD-MMM-YYYY">{data.order.onDate}</Moment></td>
                                </tr>
                                <tr >
                                    <td>Due Date</td>
                                    <td className="text-right"><Moment format="DD-MMM-YYYY">{data.order.dueDate}</Moment></td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="footer-inner">
                            <div class="footer-content text-right">
                                <p style={{ marginTop: "3px" }}>
                                    <span style={{ verticalAlign: "bottom", fontSize: "8px" }}>Powered&nbsp;By </span>
                                    <img style={{ marginBottom: "3px" }} height="12px" src="images/logo-small-dark.png" />
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            }
        </div >
    )
})