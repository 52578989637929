import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Container } from '../../../base/Container';
import { AdvanceItemView } from './AdvanceItemView';
import { TimelineHistory } from '../../Global/TimelineHistory';
import * as advanceVoucherCrud from '../../../_crud/vouchers/advanceVoucherCrud'
import { loadingContext } from '../../../commonLib/_lodingContext';
import { AdvanceView } from '../../Global/Elements/AdvanceView';
import { PrintStarterDash } from '../../Prints/PrintStaterDash';
import { AdvanceVoucherPrint } from '../../Prints/AdvanceVoucherPrint';
import { getIsPrint } from '../../../config/GlobalSettings';

export function AdvanceVoucherDashBoard() {
    const { id } = useParams()
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [advanceVoucher, setAdvanceVoucher] = useState()
    const [voucherPayment, setVoucherPayment] = useState([])

    function doRefresh() {
        getAdvanceVoucher()
    }

    useEffect(() => {
        getAdvanceVoucher()
    }, []);

    function getAdvanceVoucher() {
        setIsLoading(true);
        advanceVoucherCrud.GetVoucherById(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let hpayment = res.data.data.payments
                        //alert(JSON.stringify(hpayment))
                        setAdvanceVoucher(hdata)
                        setVoucherPayment(hpayment)
                    }
                    else {
                    }
                }
                else {
                }
                setIsLoading(false);
            })
            .catch(error => {
                alert(error)
                setIsLoading(false);
            })
    }
    const printRef = useRef();

    return (
        <>
            {getIsPrint && <div style={{ display: "none" }}>
                <AdvanceVoucherPrint paid={advanceVoucher && advanceVoucher.paid} pending={advanceVoucher && advanceVoucher.pending} advanceVoucher={advanceVoucher && advanceVoucher.voucher} voucherPayment={voucherPayment} ref={printRef} />
            </div>}
            <Container title={"Advance Voucher"} doRefresh={doRefresh} isBack="true">

                {getIsPrint && advanceVoucher && voucherPayment &&
                    <div class="btn-group">
                        <PrintStarterDash printRef={printRef} />
                    </div>
                }
                <div class="hr dotted"></div>

                <div className="row">
                    <div className="col-lg-8">
                        <AdvanceItemView paid={advanceVoucher && advanceVoucher.paid} pending={advanceVoucher && advanceVoucher.pending} advanceVoucher={advanceVoucher && advanceVoucher.voucher} voucherPayment={voucherPayment} doRefresh={getAdvanceVoucher} />
                    </div>

                    <div className="col-lg-4">
                        {advanceVoucher && <AdvanceView contactid={advanceVoucher.voucher.contact.id} />}
                        <br />
                        <TimelineHistory id={id} type={1} pageSize={10} text="Advance Voucher Timeline" />
                    </div>

                </div>
            </Container>
        </>
    )
}
