import GetLoginInfo from "../../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken } from "../../initApi/createAxios";
import { OrgId } from "../../config/GlobalSettings";

export async function VoucherAdd(expenseVoucher) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/ExpenseVoucher/VoucherAdd", expenseVoucher)
    }
}

export async function VoucherRemove(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/ExpenseVoucher/VoucherRemove?Id=" + id)
    }
}

export async function GetVoucherById(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Vouchers/ExpenseVoucher/GetVoucherById?Id=" + id)
    }
}


export async function AddVoucherPayment(expenseVoucher) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Vouchers/ExpenseVoucher/AddVoucherPayment", expenseVoucher)
    }
}

