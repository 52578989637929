import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Container } from '../../../base/Container';
import AppToaster from '../../../commonLib/AppToaster';
import { EmptyData } from '../../../commonLib/EmptyData';
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import { PagingEmpty } from '../../../commonLib/PagingEmpty';
import { Spinner } from '../../../commonLib/Spinner';
import { UserView } from '../../Global/Elements/UserView';
import * as fiscalYearClosingCrud from './../../../_crud/fiscalYearClosingCrud'
import Moment from 'react-moment'

export function ManageFiscalYearClosing() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [fiscalYearClosing, setFiscalYearClosing] = useState([])

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoadingMini(true);
        fiscalYearClosingCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setFiscalYearClosing(hdata);
                    }
                    else {
                        setFiscalYearClosing([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setFiscalYearClosing([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        getData()
        setTimeout(() => {
            event.detail.complete();
        }, 1000);
    }

    return (
        <>
            <Container title="Fiscal Year Closing" doRefresh={doRefresh}>
                <Link to="/fiscalyearclosing/add" class="btn btn-sm btn-success">
                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                    Close Fiscal Year
                </Link>
                <div class="hr dotted"></div>
                <PagingEmpty text={"Total " + fiscalYearClosing.length + " items."}>
                    {isLoadingMini ?
                        <Spinner />
                        :
                        fiscalYearClosing && fiscalYearClosing.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Fiscal Year</th>
                                            <th>Closed on Date</th>
                                            <th>Note</th>
                                            <th style={{ width: "43px" }}>By</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {fiscalYearClosing.map((item, index) =>
                                            <tr key={item.id}>

                                                <td>{index + 1}</td>
                                                <td className='bolder'>{item.fiscalYear}</td>
                                                <td><Moment format="DD-MMM-YYYY HH:mm">{item.createdOn}</Moment></td>
                                                <td><div style={{ width: "350px" }}>{item.note}</div></td>
                                                <td>
                                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </PagingEmpty>
            </Container >
        </>
    )
}

