import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Container } from '../../base/Container'
import AppToaster from '../../commonLib/AppToaster'
import GetCurrentDateForSearch from '../../commonLib/dateFormat'
import { EmptyData } from '../../commonLib/EmptyData'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext'
import { Paging } from '../../commonLib/Paging'
import { Spinner } from '../../commonLib/Spinner'
import { PAGING_PAGE_SIZE } from '../../config/GlobalSettings'
import *as productReceivingLotCrud from '../../_crud/productReceivingLotCrud'
import { ContactView } from '../Global/Elements/ContactView'
import { ProductionUnitView } from '../Global/Elements/ProductionUnitView'
import { ProductView } from '../Global/Elements/ProductView'
import DatePicker from "react-datepicker";
import { GetEProductReceivingLotStatus, GetEProductReceivingLotStatusName } from '../../enumCollections/EProductReceivingLotStatus'
import GetLoginInfo from '../../auth/_userContext/GetLoginInfo'
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater'

const initValues = {
    Search: {
        searchTerm: "",
        pageNo: 1,
        pageSize: PAGING_PAGE_SIZE
    },
    Status: "All",
    Range: {
        fromDate: new Date(GetCurrentDateForSearch(-30)),
        toDate: new Date(GetCurrentDateForSearch(0))
    }
}

export function ManageProductReceivingLot() {
    const [isLoading, setIsLoading] = useContext(loadingMiniContext)
    const [search, setSearch] = useState(initValues)
    const [productLot, setProductLot] = useState([])
    const [LastPage, setLastPage] = useState(1)
    const [searchType, setSearchType] = useState(0)
    const [pagingData, setPagingData] = useState()
    const userData = GetLoginInfo()

    useEffect(() => {
        OnSearch()
    }, [search.Search.pageNo])


    const OnPageChange = (e) => {
        const { value } = e.target
        setSearch({ ...search, Search: { ...search.Search, pageNo: value } });
    }

    function OnSearch() {
        if (userData.isWarehouse == 0) {
            if (search.Status == "All")
                getProductLot()
            else
                getProductLotStatus()
        }
        else {
            if (search.Status == "All")
                getProductLotWarehouse()
            else
                getProductLotStatusWarehouse()
        }
    }

    function getProductLot() {
        setIsLoading(true);
        productReceivingLotCrud.GetAll(search)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setProductLot(hdata);
                        setIsLoading(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoading(false)
                        setProductLot([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoading(false)
                    setProductLot([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoading(false);
                AppToaster(error, 'error')
            })
    }
    function getProductLotStatus() {
        setIsLoading(true);
        productReceivingLotCrud.GetByStatus(search)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setProductLot(hdata);
                        setIsLoading(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoading(false)
                        setProductLot([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoading(false)
                    setProductLot([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoading(false);
                AppToaster(error, 'error')
            })
    }

    function getProductLotWarehouse() {
        setIsLoading(true);
        productReceivingLotCrud.GetAllWarehouse({ ...search, WarehouseId: userData.warehouseId })
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setProductLot(hdata);
                        setIsLoading(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoading(false)
                        setProductLot([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoading(false)
                    setProductLot([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoading(false);
                AppToaster(error, 'error')
            })
    }
    function getProductLotStatusWarehouse() {
        setIsLoading(true);
        productReceivingLotCrud.GetByStatusWarehouse({ ...search, WarehouseId: userData.warehouseId })
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setProductLot(hdata);
                        setIsLoading(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoading(false)
                        setProductLot([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoading(false)
                    setProductLot([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoading(false);
                AppToaster(error, 'error')
            })
    }

    function SearchChange(e) {
        const value = e.target.value
        setSearch({ ...search, Search: { ...search.Search, searchTerm: value } })
    }

    function doRefresh() {
        OnSearch()
    }

    return (
        <>
            <Container title="Product Receiving Lots" doRefresh={doRefresh}>
                <div class="hr dotted"></div>
                <div class="form-horizontal" >
                    <div className="row">
                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker selectsStart startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.fromDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, fromDate: date } })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker selectsEnd startDate={search.Range.fromDate} endDate={search.Range.toDate} className="form-control input-date-padding" selected={search.Range.toDate} onChange={(date) => setSearch({ ...search, Range: { ...search.Range, toDate: date } })} />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <select className="form-control" name="status" value={search.Status} onChange={(e) => setSearch({ ...search, Status: e.target.value })}>
                                        <option value="All">All</option>
                                        {
                                            GetEProductReceivingLotStatus.EProductReceivingLotStatus.map((data, index) =>
                                                <option value={data.Id}>{data.Name}</option>
                                            )
                                        }
                                    </select>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-5">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <TextBoxTranslater style={{paddingLeft: "24px"}} value={search.Search.searchTerm} onChange={SearchChange} className="form-control" type="text" placeholder="Search Product Receiving Lots..." />
                                    <i class="ace-icon fa fa-search blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-1">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <button onClick={OnSearch} class="btn btn-sm btn-info pull-right">
                                    <i class="ace-icon fa fa-search bigger-110"></i>
                                    Run
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <Paging
                    pagingData={pagingData}
                    LastPage={LastPage}
                    CurrentPage={search.Search.pageNo}
                    OnPageChange={OnPageChange} >

                    {isLoading ?
                        <Spinner />
                        :
                        productLot && productLot.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "43px" }}>#Id</th>
                                            <th>Contact</th>
                                            <th>Production</th>
                                            <th>Product</th>
                                            <th>Fresh</th>
                                            <th>Defective</th>
                                            <th>Rejected</th>
                                            <th>Total</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>


                                    <tbody>
                                        {productLot.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>{item.id}</td>
                                                <td>
                                                    <Link to={"/contact/dashboard/" + item.contactId}>
                                                        <ContactView item={item.contact} uid={"C" + index} />
                                                    </Link>
                                                </td>
                                                <td>
                                                    <Link to={"/productionunit/dashboard/" + item.productionUnitId}>
                                                        <ProductionUnitView item={item.productionUnit} />
                                                    </Link>
                                                </td>
                                                <td>
                                                    <Link to={"/product/dashboard/" + item.productId}>
                                                        <ProductView item={item.product} uid={"PP" + index} />
                                                    </Link>
                                                </td>
                                                <td className="green">{Number(item.freshQuantity).toFixed(2)} {item.unit}</td>
                                                <td>{Number(item.defectiveQuantity).toFixed(2)} {item.unit}</td>
                                                <td className="red">{Number(item.rejectedQuantity).toFixed(2)} {item.unit}</td>
                                                <td className="bolder">{Number(item.freshQuantity + item.defectiveQuantity + item.rejectedQuantity).toFixed(2)} {item.unit}</td>
                                                <td><span class={item.forStock == 1 ? "label label-sm label-danger" : "label label-sm label-success"}>{GetEProductReceivingLotStatusName(item.status)}</span></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </Paging>
            </Container >
        </>
    )
}
