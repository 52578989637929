import React from 'react'
import ReactTooltip from 'react-tooltip'
import { ImgLazyload } from '../../../commonLib/ImgLazyload'
import { ERROR_IMG } from '../../../config/GlobalSettings'
import { BASE_URL } from '../../../initApi/apiUrls'

export function DrapingViewRow({ url, uid }) {

    const GetSmall = (url) => {
        let u = url.split("/Drapings/");

        return u[0] + "/Drapings/small" + u[1];
    }

    return (
        <>
            <a data-tip data-for={uid}>
                <ImgLazyload height="35px" width="35px" className="img-round pull-left" src={url ? BASE_URL + "/" + GetSmall(url) : ERROR_IMG}
                    onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
            </a>
            <ReactTooltip delayShow={500} arrowColor="gray" borderColor="#9b9b9b" className="opaque" id={uid} type="light">
                <div style={{ width: "100%", textAlign: "center" }}>
                    <ImgLazyload height="100%" width="200px" className="img-round-tooltip" src={url ? BASE_URL + "/" + GetSmall(url) : ERROR_IMG}
                        onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }} />
                </div>
            </ReactTooltip>
        </>
    )
}
