import React, { useContext, useEffect, useState } from 'react'
import * as fiscalYearClosingCrud from './../../../_crud/fiscalYearClosingCrud'
import { LoadingButton } from '../../../commonLib/LoadingButton';
import * as Yup from "yup";
import { Container } from '../../../base/Container';
import AppToaster from '../../../commonLib/AppToaster';
import { useFormik } from 'formik';
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import { Spinner } from '../../../commonLib/Spinner';
import { GetEVoucherLinkedIdTypeName } from '../../../enumCollections/EVoucherLinkedIdType';
import { GetELedgerName } from '../../../enumCollections/ELedger';
import { EmptyData } from '../../../commonLib/EmptyData';
import { PagingEmpty } from '../../../commonLib/PagingEmpty';
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater';
import { useHistory } from 'react-router-dom';

const initialValues = {
    password: "",
    note: ""
}

export function AddFiscalYearClosing() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [fiscalYearTransaction, setFiscalYearTransaction] = useState([])
    const history = useHistory();

    const Schema = Yup.object().shape({
        password: Yup.string().required("Password is required."),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    useEffect(() => {
        getClosingTransactions()
    }, [])

    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        fiscalYearClosingCrud.Add(value)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster('Added successfully.', 'success')
                    history.goBack()
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    //AppToaster(res.data.message, 'error')
                    AppToaster("Current Fiscal year was already closed.", 'error')
                    history.goBack()
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    function getClosingTransactions() {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        fiscalYearClosingCrud.GetClosingTransactions()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setFiscalYearTransaction(hdata);
                    }
                    else {
                        setFiscalYearTransaction([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setFiscalYearTransaction([]);
                }
                setIsLoadingMini(false);
                setIsLoadingLocal(false)
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false)
                AppToaster(error, 'error')
            })
    }

    return (
        <>
            <Container title="Close Fiscal Year" isBack="true">
                <form onSubmit={formik.handleSubmit} class="form-horizontal">
                    <div className="row">
                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right"> Password <small>(administrator's password only)</small></label>
                                    <input className="form-control" type="password" placeholder="Password" name="password" {...formik.getFieldProps("password")} />
                                    {
                                        formik.touched.password &&
                                            formik.errors.password ? (
                                            <label className="text-danger" >
                                                {formik.errors.password}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-12">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Note</label>
                                    <TextBoxTranslater className="form-control" placeholder="Note" type="text" value={formik.values.note} onChange={(e) => formik.setValues({ ...formik.values, note: e.target.value })} />
                                    {
                                        formik.touched.note &&
                                            formik.errors.note ? (
                                            <label className="text-danger" >
                                                {formik.errors.note}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="clearfix form-actions">
                        <div class="pull-right">
                            {isLoadingLocal ?
                                <LoadingButton />
                                :
                                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Close Fiscal Year
                                </button>}
                        </div>
                    </div>
                </form>

                {
                    fiscalYearTransaction &&
                    <>
                        <div className="widget-box">
                            <div class="widget-header widget-header-flat widget-header-small">
                                <h5 class="widget-title">
                                    Details for closing
                                </h5>
                                <div class="widget-toolbar no-border">
                                    <button class="btn btn-minier btn-primary" onClick={getClosingTransactions}>
                                        <i class="ace-icon fa fa-refresh"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                    <PagingEmpty text={"Total " + fiscalYearTransaction.length + " items."}>
                                        {isLoadingMini ?
                                            <Spinner />
                                            :
                                            fiscalYearTransaction && fiscalYearTransaction.length > 0 ?
                                                <div className="tables-scroll">
                                                    <table className="table table-hover table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>#Id</th>
                                                                <th>Ledger</th>
                                                                <th>Linked Type</th>
                                                                <th>Linked Id</th>
                                                                <th>Cr</th>
                                                                <th>Dr</th>
                                                                <th>Balance</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {fiscalYearTransaction.map((item, index) =>
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{GetELedgerName(item.ledgerId)}</td>
                                                                    <td>{GetEVoucherLinkedIdTypeName(item.linkedIdType)} </td>
                                                                    <td>{item.linkedIdType == 2 ? GetELedgerName(parseInt(item.linkedId)) : item.linkedId}</td>
                                                                    <td>₹ {item.cr} </td>
                                                                    <td>₹ {item.dr} </td>
                                                                    <td>₹ {item.cr - item.dr < 0 ? <b className="green">{item.dr - item.cr} Dr</b> : <b className="blue">{item.cr - item.dr} Cr</b>} </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                :
                                                <EmptyData>
                                                    No records found.
                                                    <br />
                                                    <br />
                                                </EmptyData>

                                        }
                                        <br />
                                    </PagingEmpty>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </Container >
        </>
    )
}
