import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken, createAxiosWithTokenFile } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function GetAllForSelect() {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Product/GetAllForSelect")
    }
}
export async function GetAll(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Product/GetAll", param)
    }
}

export async function GetByWageType(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Product/GetByWageType?PageNo=" + param.pageNumber + "&SearchTerm=" + param.searchText + "&PageSize=" + param.pageSize + "&WageType=" + param.searchValue + "&FromDate=" + param.fromDate + "&ToDate=" + param.toDate)
    }
}

export async function GetByProductCatId(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Product/GetByProductCatId?PageNo=" + param.pageNumber + "&SearchTerm=" + param.searchText + "&PageSize=" + param.pageSize + "&ProductCatId=" + param.productCatId + "&FromDate=" + param.fromDate + "&ToDate=" + param.toDate)
    }
}

export async function GetByProductionUnit(productionUnitId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Product/GetByProductionUnit?ProductionUnitId=" + productionUnitId)
    }
}
export async function GetByProductionUnitActive(productionUnitId) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Product/GetByProductionUnitActive?ProductionUnitId=" + productionUnitId)
    }
}
export async function GetById(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Product/GetById?Id=" + id)
    }
}

export async function Add(product) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Product/Add", product)

    }
}

export async function Edit(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Product/Edit", param)
    }
}

export async function Remove(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Product/Remove?Id=" + id)
    }
}

export async function UploadPhoto(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithTokenFile(loginData.token, OrgId()).post("Product/UploadPhoto", param)
    }
}

export async function GetProductCalculatorData(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Product/GetProductCalculatorData?Id=" + id)
    }
}