import React, { useContext, useEffect, useState } from 'react'
import Moment from 'react-moment'
import { Link, useParams } from 'react-router-dom'
import { Container } from '../../../../base/Container'
import AppToaster from '../../../../commonLib/AppToaster'
import GetCurrentDateForSearch from '../../../../commonLib/dateFormat'
import { EmptyData } from '../../../../commonLib/EmptyData'
import { loadingMiniContext } from '../../../../commonLib/lodingMiniContext'
import { MyAlert } from '../../../../commonLib/MyAlert'
import { Paging } from '../../../../commonLib/Paging'
import { Spinner } from '../../../../commonLib/Spinner'
import { PAGING_PAGE_SIZE } from '../../../../config/GlobalSettings'
import * as voucherCrud from '../../../../_crud/voucherCrud'
import * as expenseVoucherCrud from '../../../../_crud/vouchers/expenseVoucherCrud'
import { UserView } from '../../../Global/Elements/UserView'
import DatePicker from "react-datepicker";
import { TextBoxTranslater } from '../../../../commonLib/TextBoxTranslater'

export function ManageContactExpenseVoucher() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [expenseVouchers, setExpenseVouchers] = useState([])
    const [LastPage, setLastPage] = useState(1)
    const [pagingData, setPagingData] = useState()
    const { id } = useParams()

    const initValues = {
        searchTerm: "",
        pageNo: 1,
        pageSize: PAGING_PAGE_SIZE,
        fromDate: new Date(GetCurrentDateForSearch(-30)),
        toDate: new Date(GetCurrentDateForSearch(0)),
        type: 6,
        contactId: id
    }
    const [search, setSearch] = useState(initValues)

    useEffect(() => {
        getData()
    }, [search.pageNo])

    const OnPageChange = (e) => {
        const { value } = e.target
        setSearch({ ...search, pageNo: value });
    }

    function onSearch() {
        getData()
    }

    function getData() {
        setIsLoadingMini(true);
        voucherCrud.GetVoucherByTypeAndContact(search)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setExpenseVouchers(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoadingMini(false)
                        setExpenseVouchers([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoadingMini(false)
                    setExpenseVouchers([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        setSearch({ ...search, pageNo: 1 })
        getData()
        setTimeout(() => {
            event.target.complete();
        }, 1000);
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        expenseVoucherCrud.VoucherRemove(id)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false)
                    AppToaster('Deleted successfully.', 'success')
                    getData()
                }
                else {
                    setIsLoadingMini(false)
                    AppToaster("Server Error /Invalid Try.", 'error')
                }

            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }

    return (
        <Container title="Expense Voucher" doRefresh={doRefresh} isBack="true" isContact="true">
            <Link to={"/contact/dashboard/" + id + "/expensevoucher/add"} class="btn btn-sm btn-success">
                <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                New
            </Link>
            <div class="hr dotted"></div>

            <div class="form-horizontal">
                <div className="row">
                    <div className="col-md-2">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <span class="input-icon" style={{ width: "100%" }}>
                                <DatePicker selectsStart startDate={search.fromDate} endDate={search.toDate} className="form-control input-date-padding" selected={search.fromDate} onChange={(date) => setSearch({ ...search, fromDate: date })} />
                                <i class="ace-icon fa fa-calendar blue"></i>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <span class="input-icon" style={{ width: "100%" }}>
                                <DatePicker selectsEnd startDate={search.fromDate} endDate={search.toDate} className="form-control input-date-padding" selected={search.toDate} onChange={(date) => setSearch({ ...search, toDate: date })} />
                                <i class="ace-icon fa fa-calendar blue"></i>
                            </span>
                        </div>
                    </div>

                    <div className="col-md-7">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <span class="input-icon" style={{ width: "100%" }}>
                                <TextBoxTranslater style={{paddingLeft: "24px"}} value={search.searchTerm} onChange={(e) => setSearch({ ...search, searchTerm: e.target.value })} className="form-control" type="text" id="form-field-icon-1" placeholder="Search eg. id etc" />
                                <i class="ace-icon fa fa-search blue"></i>
                            </span>
                        </div>
                    </div>

                    <div className="col-md-1">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <button onClick={onSearch} to="/cashbankcashvoucher/add" class="btn btn-sm btn-info pull-right">
                                <i class="ace-icon fa fa-search bigger-110"></i>
                                Run
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <Paging
                pagingData={pagingData}
                LastPage={LastPage}
                CurrentPage={search.pageNo}
                OnPageChange={OnPageChange} >

                {isLoadingMini ?
                    <Spinner />
                    :
                    expenseVouchers && expenseVouchers.length > 0 ?
                        <div className="tables-scroll">
                            <table className="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th style={{ width: "20px" }}>Voucher Id</th>
                                        <th>Voucher Date</th>
                                        <th>Details</th>
                                        <th>Amount</th>
                                        <th>Note</th>
                                        <th>By</th>
                                        <th style={{ width: "43px" }}></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {expenseVouchers.map((item, index) =>
                                        <tr key={item.id}>

                                            <td>{item.id}</td>
                                            <td><Moment format="DD-MMM-YYYY">{item.voucherDate}</Moment></td>
                                            <td><div dangerouslySetInnerHTML={{ __html: item.linkedDetails }} /></td>
                                            <td> <b> ₹ {Number(item.totalAmount).toFixed(2)} </b></td>
                                            <td>{item.note}</td>
                                            <td>
                                                <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                            </td>
                                            <td>
                                                <div class="btn-group">
                                                    <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                        <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                    </MyAlert>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        :
                        <EmptyData>
                            No records found.
                            <br />
                            <br />
                        </EmptyData>
                }
            </Paging>
        </Container >
    )
}
