import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import * as Yup from "yup";
import { Container } from '../../base/Container';
import AppToaster from '../../commonLib/AppToaster';
import { loadingContext } from '../../commonLib/_lodingContext';
import * as productCrud from '../../_crud/productCrud';
import * as productCategoryCrud from '../../_crud/productCategoryCrud';
import { loadingMiniContext } from '../../commonLib/lodingMiniContext';
import { GetEWageType } from '../../enumCollections/EWageType';
import { useParams } from 'react-router-dom';
import { LoadingButton } from '../../commonLib/LoadingButton';
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater';

const initialValues = {
    id: undefined,
    productName: undefined,
    productCatId: 0,
    isProduction: undefined,
    unit: undefined,
    wageType: undefined,
    details: undefined,
    cost: undefined,
}

export function EditProduct() {
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [productCategories, setProductCategories] = useState([])
    const { id } = useParams()

    const Schema = Yup.object().shape({
        productName: Yup.string().min(3, "Product Name must between 3 to 50 characters").max(50, "Product Name must between 3 to 50 characters").required("Product Name is required."),
        productCatId: Yup.string().required("Category is required."),
        isProduction: Yup.string().required("IsProduction is required."),
        unit: Yup.string().required("Unit is required."),
        wageType: Yup.string().required("Wage Type is required."),
        cost: Yup.string().required("Set zero if not needed.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });
    const Save = (value) => {
        setIsLoadingMini(true);
        setIsLoadingLocal(true);
        productCrud.Edit(value)
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingMini(false);
                    setIsLoadingLocal(false);
                    AppToaster('Updated successfully.', 'success')
                }
                else {
                    setIsLoadingMini(false)
                    setIsLoadingLocal(false);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }
    useEffect(() => {
        getCategories()
        getProduct()
    }, []);

    function getCategories() {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        setIsLoading(true);
        productCategoryCrud.GetAll()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProductCategories(hdata);
                    }
                    else {
                        setProductCategories([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProductCategories([]);
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                AppToaster(error, 'error')
            })
    }

    function getProduct() {
        setIsLoadingMini(true);
        setIsLoadingLocal(true)
        setIsLoading(true);
        productCrud.GetById(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        formik.setValues({
                            id: hdata.id,
                            productName: hdata.productName,
                            productCatId: hdata.productCatId,
                            isProduction: hdata.isProduction,
                            unit: hdata.unit,
                            wageType: hdata.wageType,
                            details: hdata.details,
                            cost: hdata.cost,
                        })
                    }
                    else {
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                }
                setIsLoadingMini(false)
                setIsLoadingLocal(false);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoadingMini(false)
                setIsLoadingLocal(false);
                setIsLoading(false);
                AppToaster(error, 'error')
            })
    }

    return (
        <>
            <Container title="Edit Product" isBack="true" isProduct="true">
                <form onSubmit={formik.handleSubmit} class="form-horizontal">
                    <div className="row">
                        <div className="col-md-6">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right"> Product</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Product Name" name="productName" value={formik.values.productName} onChange={(e) => formik.setValues({ ...formik.values, productName: e.target.value })} />
                                    {
                                        formik.touched.productName &&
                                            formik.errors.productName ? (
                                            <label className="text-danger" >
                                                {formik.errors.productName}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Unit</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Unit" name="unit" value={formik.values.unit} onChange={(e) => formik.setValues({ ...formik.values, unit: e.target.value })} />
                                    {
                                        formik.touched.unit &&
                                            formik.errors.unit ? (
                                            <label className="text-danger" >
                                                {formik.errors.unit}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Category</label>
                                    <select className="form-control" name="productCatId" value={formik.values.productCatId} onChange={(e) => formik.setValues({ ...formik.values, productCatId: e.target.value })}>
                                        <option value="" >Select...</option>
                                        {
                                            productCategories.map((data, index) =>
                                                <option key={data.id} value={data.id}>{data.catName.toUpperCase()}</option>
                                            )
                                        }
                                    </select>
                                    {
                                        formik.touched.productCatId &&
                                            formik.errors.productCatId ? (
                                            <label className="text-danger" >
                                                {formik.errors.productCatId}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="ontrol-label no-padding-right">Wage Type</label>
                                    <select className="form-control" name="wageType" value={formik.values.wageType} onChange={(e) => formik.setValues({ ...formik.values, wageType: e.target.value })}>
                                        {
                                            GetEWageType.EWageType.map((data, index) =>
                                                <option key={data.Id} value={data.Id}>{data.Name}</option>
                                            )
                                        }
                                    </select>
                                    {
                                        formik.touched.wageType &&
                                            formik.errors.wageType ? (
                                            <label className="text-danger" >
                                                {formik.errors.wageType}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="ontrol-label no-padding-right">Cost Price</label>
                                    <input className="form-control" placeholder="Cost Price" type="number" name="cost" {...formik.getFieldProps("cost")} />
                                    {
                                        formik.touched.cost &&
                                            formik.errors.cost ? (
                                            <label className="text-danger" >
                                                {formik.errors.cost}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-12">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Details</label>
                                    <TextBoxTranslater className="form-control" type="text" placeholder="Details" name="details" value={formik.values.details} onChange={(e) => formik.setValues({ ...formik.values, details: e.target.value })} />
                                    {
                                        formik.touched.details &&
                                            formik.errors.details ? (
                                            <label className="text-danger" >
                                                {formik.errors.details}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-md-12">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Is Production</label>
                                    <div>
                                        <input onChange={() => formik.setValues({ ...formik.values, isProduction: (formik.values.isProduction == 1 ? 0 : 1) })} checked={formik.values.isProduction == 1} type="checkbox" class="ace ace-switch ace-switch-5" />
                                        <span class="lbl middle"></span>
                                    </div>
                                    {
                                        formik.touched.isProduction &&
                                            formik.errors.isProduction ? (
                                            <label className="text-danger" >
                                                {formik.errors.isProduction}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="clearfix form-actions">
                        <div class="pull-right">
                            {isLoadingLocal ?
                                <LoadingButton />
                                :
                                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Update
                                </button>}
                        </div>
                    </div>

                </form>
            </Container >
        </>
    )
}
