import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { Container } from '../../base/Container'
import AppToaster from '../../commonLib/AppToaster'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext'
import { PagingEmpty } from '../../commonLib/PagingEmpty'
import { Spinner } from '../../commonLib/Spinner'
import * as productConsumptionCrud from '../../_crud/productConsumptionCrud'
import 'moment-timezone';
import { EmptyData } from '../../commonLib/EmptyData'
import { MyAlert } from '../../commonLib/MyAlert'
import { UserView } from '../Global/Elements/UserView'
import { GetEBoolName } from '../../enumCollections/EBool';
import { ProductView } from '../Global/Elements/ProductView'

export function ManageConsumption() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [consumption, setConsumption] = useState([])
    const { id } = useParams()

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoadingMini(true);
        productConsumptionCrud.GetByProductId(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setConsumption(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setIsLoadingMini(false)
                        setConsumption([]);
                    }
                }
                else {
                    setIsLoadingMini(false)
                    setConsumption([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        getData()
        setTimeout(() => {
            event.detail.complete();
        }, 1000);
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        productConsumptionCrud.Remove(id)
            .then(res => {
                setIsLoadingMini(false)
                AppToaster('Deleted successfully.', 'success')
                getData()

            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }

    return (
        <>
            <Container title="Product Consumption " doRefresh={doRefresh} isBack="true" isProduct="true">
                <Link to={"/product/dashboard/" + id + "/consumption/add"} class="btn btn-sm btn-success">
                    <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                    New
                </Link>
                <div class="hr dotted"></div>
                <PagingEmpty text={"Total " + consumption.length + " items."}>

                    {isLoadingMini ?
                        <Spinner />
                        :
                        consumption && consumption.length > 0 ?
                            <div className="tables-scroll">
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "43px" }}>#Id</th>
                                            <th>Name</th>
                                            <th>It will consume </th>
                                            <th>Per Unit Consume</th>
                                            <th style={{ width: "43px" }}>By</th>
                                            <th style={{ width: "43px" }}></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {consumption.map((item, index) =>
                                            <tr key={item.id}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <Link to={"/product/dashboard/" + item.consumeProductId}>
                                                        <ProductView item={item.consumeProduct} uid={"PP" + index} />
                                                    </Link>
                                                </td>
                                                <td><b>{item.consumptionQuantity} {item.unit}{item.isPerUnitConsume == 1 && <>/{item.product.unit}</>}</b></td>
                                                <td><span class={item.isPerUnitConsume == 1 ? "label label-sm label-success" : "label label-sm label-danger"}>{GetEBoolName(item.isPerUnitConsume)}</span></td>
                                                <td>
                                                    <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                            <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                        </MyAlert>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <EmptyData>
                                No records found.
                                <br />
                                <br />
                            </EmptyData>
                    }
                </PagingEmpty>
            </Container >
        </>
    )
}
