import React, { useContext, useEffect, useState } from 'react'
import { UserView } from '../Global/Elements/UserView'
import { StatusView } from '../../commonLib/StatusView'
import { EmptyData } from '../../commonLib/EmptyData'
import { Paging } from '../../commonLib/Paging'
import { Spinner } from '../../commonLib/Spinner'
import DatePicker from "react-datepicker";
import { GetEHistoryType, GetEHistoryTypeName } from '../../enumCollections/EHistoryType'
import { GetEHistoryActionName } from '../../enumCollections/EHistoryAction'
import { Container } from '../../base/Container'
import AppToaster from '../../commonLib/AppToaster'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext'
import GetCurrentDateForSearch from '../../commonLib/dateFormat'
import { PAGING_PAGE_SIZE } from '../../config/GlobalSettings'
import * as historyCrud from '../../_crud/historyCrud'
import { Link } from 'react-router-dom'
import { ContactView } from '../Global/Elements/ContactView'
import { TextBoxTranslater } from '../../commonLib/TextBoxTranslater'

const initValues = {
    searchTerm: "",
    pageNo: 1,
    historyType: 1,
    pageSize: PAGING_PAGE_SIZE,
    fromDate: new Date(GetCurrentDateForSearch(-30)),
    toDate: new Date(GetCurrentDateForSearch(0))
}

export function ManageHistory() {
    const [isLoading, setIsLoading] = useContext(loadingMiniContext)
    const [search, setSearch] = useState(initValues)
    const [history, setHistory] = useState([])
    const [LastPage, setLastPage] = useState(1)
    const [pagingData, setPagingData] = useState()

    useEffect(() => {
        getHistory()
    }, [search.pageNo])


    const OnPageChange = (e) => {
        const { value } = e.target
        setSearch({ ...search, pageNo: value });
    }

    function OnSearch() {
        getHistory()
    }

    function getHistory() {
        setIsLoading(true);
        historyCrud.GetHistoryBetweenDateAndHistoryType(search)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        let paging = res.data.paging
                        setPagingData(paging)
                        setLastPage(paging.pageCount)
                        setHistory(hdata);
                        setIsLoading(false)
                    }
                    else {
                        setLastPage(1)
                        setIsLoading(false)
                        setHistory([]);
                    }
                }
                else {
                    setLastPage(1)
                    setIsLoading(false)
                    setHistory([]);
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setLastPage(1)
                setIsLoading(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh() {
        setSearch({ ...search, pageNo: 1 })
        getHistory()
    }

    function SearchChange(e) {
        const value = e.target.value
        setSearch({ ...search, searchTerm: value })
    }

    return (
        <Container title="History" doRefresh={doRefresh}>
            <div class="form-horizontal" >
                <div className="row">
                    <div className="col-md-2">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <span class="input-icon" style={{ width: "100%" }}>
                                <DatePicker selectsStart startDate={search.fromDate} endDate={search.toDate} className="form-control input-date-padding" selected={search.fromDate} onChange={(date) => setSearch({ ...search, fromDate: date })} />
                                <i class="ace-icon fa fa-calendar blue"></i>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <span class="input-icon" style={{ width: "100%" }}>
                                <DatePicker selectsEnd startDate={search.fromDate} endDate={search.toDate} className="form-control input-date-padding" selected={search.toDate} onChange={(date) => setSearch({ ...search, toDate: date })} />
                                <i class="ace-icon fa fa-calendar blue"></i>
                            </span>
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <span class="input-icon" style={{ width: "100%" }}>
                                <select className="form-control" name="status" value={search.historyType} onChange={(e) => setSearch({ ...search, historyType: e.target.value })}>
                                    {
                                        GetEHistoryType.EHistoryType.map((data, index) =>
                                            <option value={data.Id}>{data.Name}</option>
                                        )
                                    }
                                </select>
                            </span>
                        </div>
                    </div>

                    <div className="col-md-5">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <span class="input-icon" style={{ width: "100%" }}>
                                <TextBoxTranslater style={{paddingLeft: "24px"}} value={search.searchTerm} onChange={SearchChange} className="form-control" type="text" placeholder="Search History..." />
                                <i class="ace-icon fa fa-search blue"></i>
                            </span>
                        </div>
                    </div>

                    <div className="col-md-1">
                        <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                            <button onClick={OnSearch} class="btn btn-sm btn-info pull-right">
                                <i class="ace-icon fa fa-search bigger-110"></i>
                                Run
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Paging
                pagingData={pagingData}
                LastPage={LastPage}
                CurrentPage={search.pageNo}
                OnPageChange={OnPageChange} >

                {isLoading ?
                    <Spinner />
                    :
                    history && history.length > 0 ?
                        <div className="tables-scroll">
                            <table className="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>#Id</th>
                                        <th>Type</th>
                                        <th>History Text</th>
                                        <th>Action</th>
                                        <th>Ref</th>
                                        <th>Contact</th>
                                        <th>By</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {history.map((item, index) =>
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td><StatusView status={item.historyType}>{GetEHistoryTypeName(item.historyType)}</StatusView></td>
                                            <td><div style={{ minWidth: "250px" }} class="pull-left" dangerouslySetInnerHTML={{ __html: item.historyText }} /></td>
                                            <td><span class={item.action == 1 ? "label label-sm label-warning" : "label label-sm label-danger"}>{GetEHistoryActionName(item.action)}</span></td>
                                            <td>{item.refId}</td>
                                            <td>
                                                {
                                                    item.contactId ?
                                                        <Link to={"/contact/dashboard/" + item.contactId}>
                                                            <ContactView item={item.contact} uid={"C" + index} />
                                                        </Link>
                                                        : "NA"
                                                }
                                            </td>
                                            <td>
                                                <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        :
                        <EmptyData>
                            No records found.
                            <br />
                            <br />
                        </EmptyData>
                }
            </Paging>
        </Container >
    )
}

