import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Container } from '../../../base/Container';
import AppToaster from '../../../commonLib/AppToaster';
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import * as productProcessingReturnCrud from '../../../_crud/productProcessingReturnCrud'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import * as productionUnitCrud from '../../../_crud/productionUnitCrud';
import { LoadingButton } from '../../../commonLib/LoadingButton';
import ContactSelector from '../../Global/ContactSelector';
import * as contactProcessingAllotmentCrud from '../../../_crud/contactProcessingAllotmentCrud';
import DatePicker from "react-datepicker";
import { ContactSelectorView } from '../../Global/SelectorView/ContactSelectorView';
import GetCurrentDateForSearch from '../../../commonLib/dateFormat';
import { ProductProcessingStockSelector } from '../../Global/ProductProcessingStockSelector';
import { ProductProcessingStockSelectorView } from '../../Global/SelectorView/ProductProcessingStockSelectorView';
import { ProductionSelectorView } from '../../Global/SelectorView/ProductionSelectorView';
import GetLoginInfo from '../../../auth/_userContext/GetLoginInfo';
import { TextBoxTranslater } from '../../../commonLib/TextBoxTranslater';

export function AddProcessingReturn() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const history = useHistory()
    const [selectedContactData, setSelectedContactData] = useState(undefined);
    const [selectedProductData, setSelectedProductData] = useState(undefined);
    const [selectedProductionData, setSelectedProductionData] = useState(undefined);
    const [processing, setProcessing] = useState([])
    const [production, setProduction] = useState([])
    const [selecteIds, setSelecteIds] = useState({
        contactId: "",
        processingId: "",
        isProductionUnit: 1,
        productionUnitId: "",
    })
    const userData = GetLoginInfo()
    
    const initialValues = {
        onDate: new Date(GetCurrentDateForSearch(0)),
        productId: "",
        contactId: "",
        processingId: "",
        isProductionUnit: 0,
        productionUnitId: "",
        forStock: 1,
        freshQuantity: 0,
        defectiveQuantity: 0,
        rejectedQuantity: 0,
        unit: "",
        note: "",
        isWarehouse: userData.isWarehouse,
        warehouseId: userData.warehouseId,
    }

    const Schema = Yup.object().shape({
        onDate: Yup.string().required("On Date is required."),
        productId: Yup.string().required("Product is required."),
        contactId: Yup.string().required("Contact is required."),
        processingId: Yup.string().required("Processing is required."),
        isProductionUnit: Yup.string().required("Is Production Unit is required."),
        forStock: Yup.string().required("For Stock is required."),
        freshQuantity: Yup.string().required("Fresh Quantity is required."),
        defectiveQuantity: Yup.string().required("Defective Quantity is required."),
        rejectedQuantity: Yup.string().required("Rejected Quantity is required."),
        unit: Yup.string().required("Unit is required.")
    })
    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    function setQuantity() {
        if (selectedProductData) {
            if ((formik.values.freshQuantity + formik.values.defectiveQuantity + formik.values.rejectedQuantity) > selectedProductData.stock) {
                formik.setValues({ ...formik.values, freshQuantity: 0, defectiveQuantity: 0, rejectedQuantity: 0 })
            }
            else if ((formik.values.freshQuantity + formik.values.defectiveQuantity + formik.values.rejectedQuantity) < 0) {
                formik.setValues({ ...formik.values, freshQuantity: 0, defectiveQuantity: 0, rejectedQuantity: 0 })
            }
        }
    }

    useEffect(() => {
        setQuantity()
    }, [formik.values.freshQuantity, formik.values.defectiveQuantity, formik.values.rejectedQuantity]);

    useEffect(() => {
        if (selectedContactData) {
            formik.setValues(
                {
                    ...formik.values,
                    productId: "",
                    contactId: selectedContactData.id,
                    processingId: "",
                    isProductionUnit: 0,
                    productionUnitId: "",
                    forStock: 1,
                    freshQuantity: 0,
                    defectiveQuantity: 0,
                    rejectedQuantity: 0,
                    unit: "",
                    note: ""
                })
            getProcessing(selectedContactData.id)
            getProduction(selectedContactData.id)
        }
    }, [selectedContactData]);

    useEffect(() => {
        if (selectedProductData) {
            formik.setValues({ ...formik.values, productId: selectedProductData.product.id, unit: selectedProductData.product.unit })
        }
    }, [selectedProductData]);

    useEffect(() => {
        setSelectedProductData(undefined)
        formik.setValues({ ...formik.values, productId: "" })
    }, [formik.values.processingId, formik.values.productionUnitId, formik.values.contactId]);

    useEffect(() => {
        setSelecteIds({
            contactId: formik.values.contactId,
            processingId: formik.values.processingId,
            isProductionUnit: formik.values.isProductionUnit,
            productionUnitId: formik.values.productionUnitId,
        })
    }, [formik.values]);

    useEffect(() => {
        if (selectedProductionData) {
            formik.setValues({ ...formik.values, productionUnitId: selectedProductionData.id })
        }
    }, [selectedProductionData])

    const OnProductionChange = (e) => {
        const { value } = e.target;
        if (value) {
            production.forEach(element => {
                if (element.id == value) {
                    {
                        setSelectedProductionData(element)
                    }
                }
            });
        }
        else {
            setSelectedProductionData(undefined)
        }
    }

    const Save = (value) => {
        if ((value.freshQuantity + value.defectiveQuantity + value.rejectedQuantity) > 0) {
            setIsLoadingMini(true);
            setIsLoadingLocal(true)
            productProcessingReturnCrud.Add(value)
                .then(res => {
                    if (res.data.result.succeeded) {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false);
                        AppToaster('Added successfully.', 'success')
                        history.goBack()
                    }
                    else {
                        setIsLoadingMini(false)
                        setIsLoadingLocal(false);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                })
                .catch(error => {
                    setIsLoadingMini(false);
                    setIsLoadingLocal(false);
                    AppToaster(error, 'error')
                })
        }
        else {
            AppToaster("Quantity should greater then zero.", 'error')
        }
    }

    function getProduction(contactid) {
        setIsLoadingMini(true);
        productionUnitCrud.GetByContactId(contactid)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProduction(hdata);
                    }
                    else {
                        setProduction([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProduction([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function getProcessing(contactid) {
        setIsLoadingMini(true);
        contactProcessingAllotmentCrud.GetByContactDdl(contactid)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProcessing(hdata);
                    }
                    else {
                        setProcessing([]);
                        AppToaster("Server Error /Invalid Try.", 'error')
                    }
                }
                else {
                    setProcessing([]);
                }
                setIsLoadingMini(false);
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    const OnProcessingChange = (e) => {
        const { value } = e.target;
        let ispunit = 0;

        if (value) {
            processing.forEach(element => {
                if (element.processing.id == value) {
                    {
                        if (element.processing.mode == 1)
                            ispunit = 1
                        else
                            ispunit = 0
                    }
                }
            });
        }
        formik.setValues({ ...formik.values, processingId: value, isProductionUnit: ispunit, productionUnitId: (ispunit == 0 ? 1 : "") })
    }


    return (
        <>
            <Container title="Add Processing Return" isBack="true">
                <form onSubmit={formik.handleSubmit} class="form-horizontal">
                    <div className="row">
                        <div className="col-md-8">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Contact</label>
                                    <ContactSelector searchtext="" setSelectedData={setSelectedContactData} />
                                    {
                                        formik.touched.contactId &&
                                            formik.errors.contactId ? (
                                            <label className="text-danger" >
                                                {formik.errors.contactId}
                                            </label>
                                        ) : null
                                    }
                                </div>
                                <ContactSelectorView data={selectedContactData} />
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Date</label>
                                    <span class="input-icon" style={{ width: "100%" }}>
                                        <DatePicker placeholder="onDate" name="onDate" className="form-control input-date-padding" selected={formik.values.onDate} onChange={(date) => formik.setValues({ ...formik.values, onDate: date })} />
                                        <i class="ace-icon fa fa-calendar blue"></i>
                                    </span>
                                    {
                                        formik.touched.onDate &&
                                            formik.errors.onDate ? (
                                            <label className="text-danger" >
                                                {formik.errors.onDate}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Processing</label>
                                    <select className="form-control" name="processingId" value={formik.values.processingId} onChange={OnProcessingChange}>
                                        <option value="" >Select...</option>
                                        {
                                            processing.map((data, index) =>
                                                <option key={data.processing.id} value={data.processing.id}>{data.processing.name.toUpperCase()} ({data.processing.id})</option>
                                            )
                                        }
                                    </select>
                                    {
                                        formik.touched.processingId &&
                                            formik.errors.processingId ? (
                                            <label className="text-danger" >
                                                {formik.errors.processingId}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        {formik.values.isProductionUnit == 1 &&
                            <div className="col-md-4">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <label class="control-label no-padding-right">Production</label>
                                        <select className="form-control" name="productionUnitId" value={formik.values.productionUnitId} onChange={OnProductionChange}>
                                            <option value="" >Select...</option>
                                            {
                                                production.map((data, index) =>
                                                    <option key={data.id} value={data.id}>{data.productionUnitName.toUpperCase()} ({data.id})</option>
                                                )
                                            }
                                        </select>
                                        <ProductionSelectorView data={selectedProductionData} />
                                        {
                                            formik.touched.productionUnitId &&
                                                formik.errors.productionUnitId ? (
                                                <label className="text-danger" >
                                                    {formik.errors.productionUnitId}
                                                </label>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="col-md-4">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right"> Product</label>
                                    <ProductProcessingStockSelector searchtext="" setSelectedData={setSelectedProductData} ids={selecteIds} />
                                    {
                                        formik.touched.productId &&
                                            formik.errors.productId ? (
                                            <label className="text-danger" >
                                                {formik.errors.productId}
                                            </label>
                                        ) : null
                                    }
                                </div>
                                <ProductProcessingStockSelectorView data={selectedProductData} />
                            </div>
                        </div>
                    </div>

                    {selectedProductData &&
                        <div className="row">
                            <div className="col-md-4">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <label class="control-label no-padding-right">Fresh - {formik.values.unit}</label>
                                        <input className="form-control" placeholder="Fresh Quantity" type="number" name="freshQuantity" {...formik.getFieldProps("freshQuantity")} />
                                        {
                                            formik.touched.freshQuantity &&
                                                formik.errors.freshQuantity ? (
                                                <label className="text-danger" >
                                                    {formik.errors.freshQuantity}
                                                </label>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <label class="control-label no-padding-right">Defective - {formik.values.unit}</label>
                                        <input className="form-control" placeholder="Defective Quantity" type="number" name="defectiveQuantity" {...formik.getFieldProps("defectiveQuantity")} />
                                        {
                                            formik.touched.defectiveQuantity &&
                                                formik.errors.defectiveQuantity ? (
                                                <label className="text-danger" >
                                                    {formik.errors.defectiveQuantity}
                                                </label>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <label class="control-label no-padding-right">Rejected - {formik.values.unit}</label>
                                        <input className="form-control" placeholder="Rejected Quantity" type="number" name="rejectedQuantity" {...formik.getFieldProps("rejectedQuantity")} />
                                        {
                                            formik.touched.rejectedQuantity &&
                                                formik.errors.rejectedQuantity ? (
                                                <label className="text-danger" >
                                                    {formik.errors.rejectedQuantity}
                                                </label>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-md-3">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Is Fresh Quantity To Main</label>
                                    <div>
                                        <input onChange={() => formik.setValues({ ...formik.values, forStock: (formik.values.forStock == 1 ? 2 : 1) })} checked={formik.values.forStock == 2} type="checkbox" class="ace ace-switch ace-switch-5" />
                                        <span class="lbl middle"></span>
                                    </div>
                                    {
                                        formik.touched.forStock &&
                                            formik.errors.forStock ? (
                                            <label className="text-danger" >
                                                {formik.errors.forStock}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-9">
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="control-label no-padding-right">Note</label>
                                    <TextBoxTranslater className="form-control" placeholder="Note" type="text" name="note" value={formik.values.note} onChange={(e) => formik.setValues({ ...formik.values, note: e.target.value })} />
                                    {
                                        formik.touched.note &&
                                            formik.errors.note ? (
                                            <label className="text-danger" >
                                                {formik.errors.note}
                                            </label>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="clearfix form-actions">
                        <div class="pull-right">
                            {isLoadingLocal ?
                                <LoadingButton />
                                :
                                <button class="btn btn-info" type="submit" expand="block" disabled={!formik.isValid}>
                                    <i class="ace-icon fa fa-check bigger-110"></i>
                                    Add
                                </button>}
                        </div>
                    </div>

                </form>
            </Container >
        </>
    )
}
