import React, { useContext, useEffect, useState } from 'react'
import * as reportCrud from '../../../_crud/reportCrud'
import { EmptyData } from '../../../commonLib/EmptyData'
import { Container } from '../../../base/Container'
import { PagingEmpty } from '../../../commonLib/PagingEmpty'
import { Spinner } from '../../../commonLib/Spinner'
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext'
import Moment from 'react-moment'
import DatePicker from "react-datepicker";
import { BaithkaLineChart } from './BaithkaLineChart'
import { IsMobile } from '../../../initApi/apiUrls'
import { MobileChart } from './MobileChart'

export function CashBankFlow() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [startDate, setStartDate] = useState(new Date());
    const [cash, setCash] = useState([])
    const [bank, setBank] = useState([])

    useEffect(() => {
        GetFlow()
    }, []);

    function GetFlow() {
        setIsLoadingMini(true);
        reportCrud.GetCurrentMonthCashBankFlow((startDate.getMonth() + 1), startDate.getFullYear())
            .then(res => {
                if (res.data) {
                    if (res.data.result.succeeded) {
                        let hcash = res.data.cash
                        let hbank = res.data.bank
                        setCash(hcash);
                        setBank(hbank);
                        setIsLoadingMini(false)
                    }
                    else {
                        setIsLoadingMini(false)
                        setCash([]);
                        setBank([]);
                    }
                }
                else {
                    setIsLoadingMini(false)
                    setCash([]);
                    setBank([]);
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
            })
    }

    function doRefresh() {
        GetFlow()
    }

    return (
        <>
            <Container title="Cash and Bank Flow" doRefresh={doRefresh}>

                <div class="form-horizontal" >
                    <div className="row">

                        <div className="col-md-2">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <span class="input-icon" style={{ width: "100%" }}>
                                    <DatePicker className="form-control input-date-padding"
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        dateFormat="MM/yyyy"
                                        showMonthYearPicker
                                    />
                                    <i class="ace-icon fa fa-calendar blue"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-1">
                            <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
                                <button onClick={doRefresh} to="/order/add" class="btn btn-sm btn-info pull-right">
                                    <i class="ace-icon fa fa-search bigger-110"></i>
                                    Run
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <>
                    <div className="widget-box">
                        <div class="widget-header widget-header-flat widget-header-small">
                            <h5 class="widget-title">
                                Chart Cash Bank Flow
                            </h5>
                            <div class="widget-toolbar">
                                <a onClick={doRefresh} title="Reload">
                                    <i class="ace-icon fa fa-refresh"></i>
                                </a>
                            </div>
                        </div>
                        {IsMobile ?
                            <div className="row">
                                <div className="col-md-12" style={{ width: "100%" }}>
                                    <MobileChart month={startDate.getMonth() + 1} year={startDate.getFullYear()} loading={isLoadingMini} cash={cash} bank={bank} />
                                </div>
                            </div>
                            :
                            <div className="row" style={{ margin: "8px" }}>
                                <div className="col-md-12" style={{ width: "100%" }}>
                                    <BaithkaLineChart loading={isLoadingMini} cash={cash} bank={bank} month={startDate.getMonth() + 1} year={startDate.getFullYear()} />
                                </div>
                            </div>
                        }
                        <div className="row">
                            <br />
                            <div className="col-xs-12">
                                <div class="tabbable">
                                    <ul class="nav nav-tabs" id="myTab" style={{ zIndex: "0" }}>
                                        <li class="active">
                                            <a data-toggle="tab" href="#cash">
                                                Cash Flow
                                            </a>
                                        </li>

                                        <li>
                                            <a data-toggle="tab" href="#bank">
                                                Bank Flow
                                            </a>
                                        </li>

                                    </ul>

                                    <div class="tab-content">
                                        <div id="cash" class="tab-pane fade in active">
                                            <PagingEmpty text={"Total " + cash.length + " items."}>
                                                {isLoadingMini ?
                                                    <Spinner />
                                                    :
                                                    cash && cash.length > 0 ?
                                                        <div className="tables-scroll">
                                                            <table className="table table-hover table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Date</th>
                                                                        <th>Cr</th>
                                                                        <th>Dr</th>
                                                                        <th>Balance</th>

                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {cash.map((item, index) =>
                                                                        <tr key={item.id}>
                                                                            <td>{index + 1}</td>
                                                                            <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.date}</Moment></div></td>
                                                                            <td> {Number(item.cr).toFixed(2)}</td>
                                                                            <td> {Number(item.dr).toFixed(2)}</td>
                                                                            <td> {item.cr - item.dr < 0 ? <b className="green">{Number(item.dr - item.cr).toFixed(2)} Dr</b> : <b className="blue">{Number(item.cr - item.dr).toFixed(2)} Cr</b>}</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        :
                                                        <EmptyData>
                                                            No records found.
                                                            <br />
                                                            <br />
                                                        </EmptyData>
                                                }
                                            </PagingEmpty>
                                        </div>

                                        <div id="bank" class="tab-pane fade">
                                            <PagingEmpty text={"Total " + bank.length + " items."}>
                                                {isLoadingMini ?
                                                    <Spinner />
                                                    :
                                                    bank && bank.length > 0 ?
                                                        <div className="tables-scroll">
                                                            <table className="table table-hover table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Date</th>
                                                                        <th>Cr</th>
                                                                        <th>Dr</th>
                                                                        <th>Balance</th>

                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {bank.map((item, index) =>
                                                                        <tr key={item.id}>
                                                                            <td>{index + 1}</td>
                                                                            <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.date}</Moment></div></td>
                                                                            <td> {Number(item.cr).toFixed(2)}</td>
                                                                            <td> {Number(item.dr).toFixed(2)}</td>
                                                                            <td> {item.cr - item.dr < 0 ? <b className="green">{Number(item.dr - item.cr).toFixed(2)} Dr</b> : <b className="blue">{Number(item.cr - item.dr).toFixed(2)} Cr</b>}</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        :
                                                        <EmptyData>
                                                            No records found.
                                                            <br />
                                                            <br />
                                                        </EmptyData>
                                                }
                                            </PagingEmpty>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </Container >
        </>
    )
}





















// import React, { useContext, useEffect, useState } from 'react'
// import * as reportCrud from '../../../_crud/reportCrud'
// import { EmptyData } from '../../../commonLib/EmptyData'
// import { Container } from '../../../base/Container'
// import { PagingEmpty } from '../../../commonLib/PagingEmpty'
// import { Spinner } from '../../../commonLib/Spinner'
// import { loadingMiniContext } from '../../../commonLib/lodingMiniContext'
// import Moment from 'react-moment'
// import DatePicker from "react-datepicker";
// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     PointElement,
//     LineElement,
//     Title,
//     Tooltip,
//     Legend,
// } from 'chart.js';
// import { Line } from 'react-chartjs-2';
// ChartJS.register(
//     CategoryScale,
//     LinearScale,
//     PointElement,
//     LineElement,
//     Title,
//     Tooltip,
//     Legend
// );

// export function CashBankFlow() {
//     const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
//     const [startDate, setStartDate] = useState(new Date());
//     const [cash, setCash] = useState([])
//     const [bank, setBank] = useState([])

//     const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
//     const [cashLbl, setCashLbl] = useState([])
//     const [bankLbl, setBankLbl] = useState([])

//     const [lables, setLables] = useState([])

//     const options = {
//         responsive: true,
//         maintainAspectRatio: false,
//         plugins: {
//             legend: {
//                 position: 'top',
//             },
//             title: {
//                 display: true,
//                 text: 'For ' + months[startDate.getMonth() + 1] + ", " + startDate.getFullYear(),
//             },
//         },
//     };

//     useEffect(() => {
//         let lblCash = [];
//         let lblBank = [];
//         let lblAll = [];
//         if (cash && bank) {
//             cash.forEach(element => {
//                 let d = new Date(element.date)
//                 lblAll = [...lblAll, String(d.getDate()).padStart(2, '0')]
//                 lblCash = [...lblCash, [String(d.getDate()).padStart(2, '0') + '/' + String(d.getMonth() + 1).padStart(2, '0') + '/' + String(d.getFullYear()).padStart(2, '0'), element.cr]]
//             });

//             bank.forEach(element => {
//                 let d = new Date(element.date)
//                 lblAll = [...lblAll, String(d.getDate()).padStart(2, '0')]
//                 lblBank = [...lblBank, [String(d.getDate()).padStart(2, '0') + '/' + String(d.getMonth() + 1).padStart(2, '0') + '/' + String(d.getFullYear()).padStart(2, '0'), element.cr]]
//             });
//             let unique = [...new Set(lblAll)]
//             unique.sort()

//             let uniqueNew = []
//             unique.forEach(element => {
//                 uniqueNew = [...uniqueNew, element + '/' + String(startDate.getMonth() + 1).padStart(2, '0') + '/' + String(startDate.getFullYear()).padStart(2, '0')]
//             });

//             let lblCashNew = []
//             uniqueNew.forEach(u => {
//                 var flag = false
//                 for (let index = 0; index < lblCash.length; index++) {
//                     if (u == lblCash[index][0]) {
//                         lblCashNew = [...lblCashNew, [lblCash[index][0], lblCash[index][1]]]
//                         flag = false
//                         break
//                     }
//                     else {
//                         flag = true
//                     }
//                 }

//                 if (flag) {
//                     lblCashNew = [...lblCashNew, [u, 0]]
//                 }
//             });

//             let lblBankNew = []
//             uniqueNew.forEach(u => {
//                 var flag = false
//                 for (let index = 0; index < lblBank.length; index++) {
//                     if (u == lblBank[index][0]) {
//                         lblBankNew = [...lblBankNew, [lblBank[index][0], lblBank[index][1]]]
//                         flag = false
//                         break
//                     }
//                     else {
//                         flag = true
//                     }
//                 }

//                 if (flag) {
//                     lblBankNew = [...lblBankNew, [u, 0]]
//                 }
//             });

//             setLables(uniqueNew)

//             setCashLbl(lblCashNew)
//             setBankLbl(lblBankNew)
//         }
//     }, [cash, bank])

//     const data = {
//         lables,
//         datasets: [
//             {
//                 label: 'Cash ₹',
//                 data: cashLbl.map((e) => e),
//                 borderColor: 'rgb(255, 99, 132)',
//                 backgroundColor: 'rgba(255, 99, 132, 0.5)',
//             },
//             {
//                 label: 'Bank ₹',
//                 data: bankLbl.map((e) => e),
//                 borderColor: 'rgb(53, 162, 235)',
//                 backgroundColor: 'rgba(53, 162, 235, 0.5)',
//             },
//         ],
//     };


//     useEffect(() => {
//         GetFlow()
//     }, []);

//     function GetFlow() {
//         setIsLoadingMini(true);
//         reportCrud.GetCurrentMonthCashBankFlow((startDate.getMonth() + 1), startDate.getFullYear())
//             .then(res => {
//                 if (res.data) {
//                     if (res.data.result.succeeded) {
//                         let hcash = res.data.cash
//                         let hbank = res.data.bank
//                         setCash(hcash);
//                         setBank(hbank);
//                         setIsLoadingMini(false)
//                     }
//                     else {
//                         setIsLoadingMini(false)
//                         setCash([]);
//                         setBank([]);
//                     }
//                 }
//                 else {
//                     setIsLoadingMini(false)
//                     setCash([]);
//                     setBank([]);
//                 }
//             })
//             .catch(error => {
//                 setIsLoadingMini(false);
//             })
//     }

//     function doRefresh() {
//         GetFlow()
//     }

//     return (
//         <>
//             <Container title="Cash and Bank Flow" doRefresh={doRefresh}>

//                 <div class="form-horizontal" >
//                     <div className="row">

//                         <div className="col-md-2">
//                             <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
//                                 <span class="input-icon" style={{ width: "100%" }}>
//                                     <DatePicker className="form-control input-date-padding"
//                                         selected={startDate}
//                                         onChange={(date) => setStartDate(date)}
//                                         dateFormat="MM/yyyy"
//                                         showMonthYearPicker
//                                     />
//                                     <i class="ace-icon fa fa-calendar blue"></i>
//                                 </span>
//                             </div>
//                         </div>

//                         <div className="col-md-1">
//                             <div class="form-group" style={{ marginLeft: "0", marginRight: "0" }}>
//                                 <button onClick={doRefresh} to="/order/add" class="btn btn-sm btn-info pull-right">
//                                     <i class="ace-icon fa fa-search bigger-110"></i>
//                                     Run
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <>
//                     <div className="widget-box">
//                         <div class="widget-header widget-header-flat widget-header-small">
//                             <h5 class="widget-title">
//                                 Chart Cash Bank Flow
//                             </h5>
//                             <div class="widget-toolbar">
//                                 <a onClick={doRefresh} title="Reload">
//                                     <i class="ace-icon fa fa-refresh"></i>
//                                 </a>
//                             </div>
//                         </div>
//                         <div className="row">
//                             {isLoadingMini ?
//                                 <Spinner />
//                                 :
//                                 data.lables && data.lables.length > 0 ?
//                                     <div class="profile-user-info profile-user-info-striped">
//                                         <article style={{ height: "60vh" }}>
//                                             <Line height={60} options={options} data={data} />
//                                         </article>
//                                     </div>
//                                     :
//                                     <EmptyData>
//                                         <h1>No Data</h1>
//                                     </EmptyData>
//                             }

//                             <br />
//                             <div className="col-xs-12">
//                                 <div class="tabbable">
//                                     <ul class="nav nav-tabs" id="myTab" style={{ zIndex: "0" }}>
//                                         <li class="active">
//                                             <a data-toggle="tab" href="#cash">
//                                                 Cash Flow
//                                             </a>
//                                         </li>

//                                         <li>
//                                             <a data-toggle="tab" href="#bank">
//                                                 Bank Flow
//                                             </a>
//                                         </li>

//                                     </ul>

//                                     <div class="tab-content">
//                                         <div id="cash" class="tab-pane fade in active">
//                                             <PagingEmpty text={"Total " + cash.length + " items."}>
//                                                 {isLoadingMini ?
//                                                     <Spinner />
//                                                     :
//                                                     cash && cash.length > 0 ?
//                                                         <div className="tables-scroll">
//                                                             <table className="table table-hover table-bordered">
//                                                                 <thead>
//                                                                     <tr>
//                                                                         <th>#</th>
//                                                                         <th>Date</th>
//                                                                         <th>Cr</th>
//                                                                         <th>Dr</th>
//                                                                         <th>Balance</th>

//                                                                     </tr>
//                                                                 </thead>

//                                                                 <tbody>
//                                                                     {cash.map((item, index) =>
//                                                                         <tr key={item.id}>
//                                                                             <td>{index + 1}</td>
//                                                                             <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.date}</Moment></div></td>
//                                                                             <td> {Number(item.cr).toFixed(2)}</td>
//                                                                             <td> {Number(item.dr).toFixed(2)}</td>
//                                                                             <td> {item.cr - item.dr < 0 ? <b className="green">{Number(item.dr - item.cr).toFixed(2)} Dr</b> : <b className="blue">{Number(item.cr - item.dr).toFixed(2)} Cr</b>}</td>
//                                                                         </tr>
//                                                                     )}
//                                                                 </tbody>
//                                                             </table>
//                                                         </div>

//                                                         :
//                                                         <EmptyData>
//                                                             No records found.
//                                                             <br />
//                                                             <br />
//                                                         </EmptyData>
//                                                 }
//                                             </PagingEmpty>
//                                         </div>

//                                         <div id="bank" class="tab-pane fade">
//                                             <PagingEmpty text={"Total " + bank.length + " items."}>
//                                                 {isLoadingMini ?
//                                                     <Spinner />
//                                                     :
//                                                     bank && bank.length > 0 ?
//                                                         <div className="tables-scroll">
//                                                             <table className="table table-hover table-bordered">
//                                                                 <thead>
//                                                                     <tr>
//                                                                         <th>#</th>
//                                                                         <th>Date</th>
//                                                                         <th>Cr</th>
//                                                                         <th>Dr</th>
//                                                                         <th>Balance</th>

//                                                                     </tr>
//                                                                 </thead>

//                                                                 <tbody>
//                                                                     {bank.map((item, index) =>
//                                                                         <tr key={item.id}>
//                                                                             <td>{index + 1}</td>
//                                                                             <td><div style={{ width: "70px" }}><Moment format="DD-MMM-YYYY">{item.date}</Moment></div></td>
//                                                                             <td> {Number(item.cr).toFixed(2)}</td>
//                                                                             <td> {Number(item.dr).toFixed(2)}</td>
//                                                                             <td> {item.cr - item.dr < 0 ? <b className="green">{Number(item.dr - item.cr).toFixed(2)} Dr</b> : <b className="blue">{Number(item.cr - item.dr).toFixed(2)} Cr</b>}</td>
//                                                                         </tr>
//                                                                     )}
//                                                                 </tbody>
//                                                             </table>
//                                                         </div>

//                                                         :
//                                                         <EmptyData>
//                                                             No records found.
//                                                             <br />
//                                                             <br />
//                                                         </EmptyData>
//                                                 }
//                                             </PagingEmpty>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </>




//             </Container >
//         </>
//     )
// }
