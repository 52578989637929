const GetEChequeStatusName = (id) => {
    switch (id) {
        case 1: { return "Pending" }
        case 2: { return "Submitted To Bank" }
        case 3: { return "Cleared" }
        case 4: { return "Not Cleared" }
        case 5: { return "Returned By Bank" }
        case 6: { return "Returned To Contact" }
        case 7: { return "Rejected" }
        case 8: { return "Withdraw" }
        case 9: { return "Deposit" }
    }
}

const GetEChequeStatus = {
    EChequeStatus: [
        { Id: 1, Name: "Pending" },
        { Id: 2, Name: "Submitted To Bank" },
        { Id: 3, Name: "Cleared" },
        { Id: 4, Name: "Not Cleared" },
        { Id: 5, Name: "Returned By Bank" },
        { Id: 6, Name: "Returned To Contact" },
        { Id: 7, Name: "Rejected" },
        { Id: 8, Name: "Withdraw" },
        { Id: 9, Name: "Deposit" }
    ]
}

export { GetEChequeStatusName, GetEChequeStatus }