import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../../../base/Container';
import AppToaster from '../../../commonLib/AppToaster';
import * as productProcessingAllotmentCrud from '../../../_crud/productProcessingAllotmentCrud'
import * as contactCrud from '../../../_crud/contactCrud'
import { Link } from 'react-router-dom';
import { GetEStatus, GetEStatusName } from '../../../enumCollections/EStatus';
import { useParams } from 'react-router-dom';
import { loadingMiniContext } from '../../../commonLib/lodingMiniContext';
import { Spinner } from '../../../commonLib/Spinner';
import { loadingContext } from '../../../commonLib/_lodingContext';
import { PagingEmpty } from '../../../commonLib/PagingEmpty';
import 'moment-timezone';
import { EmptyData } from '../../../commonLib/EmptyData';
import { UserView } from '../../Global/Elements/UserView';
import { ProcessingView } from '../../Global/Elements/ProcessingView';
import { MyAlert } from '../../../commonLib/MyAlert';

export function ManageProductProcessingAllotment() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [processingAllotment, setProcessingAllotment] = useState([])
    const { id } = useParams()

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoadingMini(true);
        productProcessingAllotmentCrud.GetByProductId(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setProcessingAllotment(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setIsLoadingMini(false)
                        setProcessingAllotment([]);
                    }
                }
                else {
                    setIsLoadingMini(false)
                    setProcessingAllotment([]);
                    AppToaster("Network Error", 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    function doRefresh(event) {
        getData()
    }

    const DeleteItem = (id) => {
        setIsLoadingMini(true)
        productProcessingAllotmentCrud.Remove(id)
            .then(res => {
                setIsLoadingMini(false)
                AppToaster('Deleted successfully.', 'success')
                getData()

            }).catch(error => {
                setIsLoadingMini(false)
                AppToaster('Network error', 'error')
            })
    }


    return (
        <Container title="Product Processing Allotment" doRefresh={doRefresh} isBack="true" isProduct="true">
            <Link to={"/product/dashboard/" + id + "/processingallotment/add"} class="btn btn-sm btn-success">
                <i class="ace-icon glyphicon glyphicon-plus bigger-110"></i>
                New
            </Link>
            <div class="hr dotted"></div>
            <PagingEmpty text={"Total " + processingAllotment.length + " items."}>
                {isLoadingMini ?
                    <Spinner />
                    :
                    processingAllotment && processingAllotment.length > 0 ?
                        <div className="tables-scroll">
                            <table className="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th style={{ width: "43px" }}>#</th>
                                        <th>Processing</th>
                                        <th style={{ width: "43px" }}></th>
                                        <th style={{ width: "43px" }}>By</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {processingAllotment.map((item, index) =>
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td><Link to={"/processing/dashboard/" + item.processingId} >
                                                <ProcessingView item={item.processing} />
                                            </Link></td>
                                            <td>
                                                <div class="btn-group">
                                                    <MyAlert confirmBtnText="Yes, delete it!" title="Delete" onConfirm={DeleteItem} param={item.id}>
                                                        <i class="ace-icon fa fa-trash-o bigger-120"></i>
                                                    </MyAlert>
                                                </div>
                                            </td>

                                            <td>
                                                <UserView item={item.createdByUser} uid={"D" + index} createdOn={item.createdOn} />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        :
                        <EmptyData>
                            No records found.
                            <br />
                            <br />
                        </EmptyData>
                }
            </PagingEmpty>
        </Container >
    )
}
