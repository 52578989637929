const GetEProductReceivingLotStatusName = (id) => {
    switch (id) {
        case 0: { return "Closed" }
        case 1: { return "Open" }
    }
}

const GetEProductReceivingLotStatus = {
    EProductReceivingLotStatus: [
        { Id: 0, Name: "Closed" },
        { Id: 1, Name: "Open" }
    ]
}

export { GetEProductReceivingLotStatusName, GetEProductReceivingLotStatus }